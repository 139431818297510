import styled from 'styled-components';

export const OAContainer = styled.div`
  height: 88vh;
  width: 70vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline: 15px;
`;

export const OATitle = styled.div`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #131336;
`;

export const OAHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SWOTContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2vh;
`;

export const TableHead = styled.th`
  background-color: #eef5ff;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  max-width: 50px;
`;

export const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
`;

export const TableData = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  max-width: 50px;
`;

export const SwotSymbol = styled.div`
  width: 84px;
  height: 130px;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 130px;
  line-height: 100%;
  text-align: center;
  color: #dee7fc;
  margin-left: auto;
  margin-right: auto;
`;

export const PageContainer = styled.div`
  height: 84vh;
  padding: 40px 0 40px 40px;
`;
