import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { withRouter } from 'react-router';

import {
  loadHypotheses,
  editHypothesis,
  addHypothesis,
  addClientProfile,
  editClientProfile,
} from '../../ducks';

import { currentTeamIdSelector } from '../../../common/ducks';
import AddPersonInfoForm from '../forms/AddPersonInfoForm';
import ModalComponent from '../../../common/components/ModalComponent';

const AddPersonInfoModal = (props) => {
  const {
    isModalOpen,
    teamId,
    isEditMode,
    loadHypotheses,
    closeModal,
    entityId,
    loadHypothesis,
    setIsModalOpen,
    editClientProfile,
    global,
    hypothesis,
    addClientProfile,
  } = props;

  useEffect(() => {
    if (teamId) {
      loadHypotheses(teamId);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses]);

  const handleModalClose = () =>
    global ? closeModal() : setIsModalOpen(false);

  const onSubmit = async (values) => {
    if (!hypothesis.clientProfile) {
      const newValues = { ...values };
      delete newValues.photo;
      const addPromise = addClientProfile(newValues, values.photo, entityId);
      addPromise.then(() => {
        loadHypothesis(entityId);
        handleModalClose();
      });
      return addPromise;
    }

    const newValues = { ...hypothesis.clientProfile, ...values };
    if (!values.photo) {
      newValues.profilePicture = '';
    }

    delete newValues.photo;
    const editPromise = editClientProfile(
      newValues,
      values.photo,
      hypothesis.clientProfile._id
    );
    editPromise.then((body) => {
      loadHypothesis(entityId);
      handleModalClose();
    });
    return editPromise;
  };

  const handleCloseModal = () => {
    handleModalClose();
  };

  return (
    <ModalComponent
      open={isModalOpen}
      title="Actualizeaza detalii profil"
      handleClose={handleCloseModal}
    >
      <AddPersonInfoForm
        global={global}
        onSubmit={onSubmit}
        isEditMode={isEditMode}
        defaultValue={hypothesis.clientProfile}
      />
    </ModalComponent>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.hypotheses.isEditing,
  isCreating: state.hypotheses.isCreating,
  teamId: currentTeamIdSelector(state.common.userTeams),
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {
  addHypothesis,
  loadHypotheses,
  editHypothesis,
  addClientProfile,
  editClientProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPersonInfoModal)
);
