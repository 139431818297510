import { isNil } from 'lodash-es';
import { baseURL } from '../core/services/environment';

const SORT = 'sort';
const FILTER = 'filters';

export function replaceRouteParams(route, params) {
  let formattedRoute = route;

  Object.keys(params)
    .filter((key) => !isNil(params[key]))
    .forEach((key) => {
      formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
    });

  return formattedRoute;
}

function addQueryParams(queryParams) {
  const queryString = [];

  Object.keys(queryParams)
    .filter((key) => !isNil(queryParams[key]))
    .forEach((key) => {
      if ([SORT, FILTER].includes(key)) {
        const stringVal = JSON.stringify(queryParams[key]);
        return queryString.push(`${key}=${stringVal}`);
      }

      queryString.push(`${key}=${queryParams[key]}`);
    });

  return queryString.join('&');
}

export function generateApiRoute({ endpoint, queryParams, params }) {
  let formattedRoute = `${baseURL}${endpoint}`;

  if (params) {
    formattedRoute = replaceRouteParams(formattedRoute, params);
  }

  if (queryParams && Object.keys(queryParams).length) {
    return `${formattedRoute}?${addQueryParams(queryParams)}`;
  }

  return formattedRoute;
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw new Error();
  }
}

export async function responseParser(response) {
  const contentType = response?.headers.get('Content-Type');

  if (
    ((contentType && contentType.match(/application\/(ld\+)?json/)) || {}).input
  ) {
    return (await response.json()) || {};
  }

  return response;
}

export function errorParse(error) {
  if (!error || !error?.response) {
    return Promise.reject(new Error('Unknown error'));
  }
  //
  return responseParser(error.response).then((errorResponse) => {
    const rejectedError = { ...errorResponse, status: error.status };
    return Promise.reject(rejectedError);
  });
}
const HEADERS = {
  'Content-Type': 'application/json',
};
export function getHeaders(token, isMultipart = false) {
  const headers = isMultipart ? {} : HEADERS;

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  }

  return headers;
}
