import React, { useEffect, useState } from 'react';
import { H1 } from '../../common/components/styled/ReusableTextComponents';
import { Box } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import { getAllChapters, getChapterById } from '../../requests/chapters';
import { getArticleById } from '../../requests/articles';
import { useLocation } from 'react-router-dom';
import RichTextContainer from '../../common/components/RichTextContainer';
import { ArrowForward } from '../../common/assets/icons';
import { useHistory } from 'react-router';

const getIdFromUrl = (param) => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(param);
};
const ResourceComponent = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'articles';
  const isArticleTab = tabFromQuery === 'articles';
  const isChapterTab = tabFromQuery === 'chapters';

  const resourceId = getIdFromUrl('id');

  const [previousSubchapter, setPreviousSubchapter] = useState({});
  const [nextSubchapter, setNextSubchapter] = useState({});

  const [{ result: subChapter = {} }, fetchChapterById] =
    useApi(getChapterById);

  const [{ result: article = {} }, fetchArticleById] = useApi(getArticleById);

  const [{ result: chaptersListResult = [] }, fetchAllChapters] =
    useApi(getAllChapters);

  const handleGetChapterById = async () => {
    const payload = { params: { chapterId: resourceId } };
    fetchChapterById(payload);
  };

  const handleGetArticleById = async () => {
    const payload = { params: { articleId: resourceId } };
    fetchArticleById(payload);
  };

  const handleChaptersFetch = async () => {
    const payload = { isVisible: true };
    await fetchAllChapters(payload);
  };

  const handleFetchResoruce = () => {
    if (!resourceId) return;
    if (isArticleTab) {
      handleGetArticleById();
    }

    if (isChapterTab) {
      handleGetChapterById();
    }
  };

  const handleNavigationSubchapters = () => {
    const currentChapterId = subChapter.chapterId;
    const currentChapterIndex = chaptersListResult.findIndex(
      (chapter) => chapter._id === currentChapterId
    );

    const currentChapter = chaptersListResult[currentChapterIndex];
    const subChapterIndex = currentChapter.subchapters.findIndex(
      (sChapter) => sChapter._id === subChapter._id
    );

    let previousSubchapter = {};
    let nextSubchapter = {};

    // Handle previous subchapter
    if (subChapterIndex > 0) {
      previousSubchapter = currentChapter.subchapters[subChapterIndex - 1];
    } else if (currentChapterIndex > 0) {
      let prevChapterIndex = currentChapterIndex;
      do {
        prevChapterIndex--;
        const prevChapter = chaptersListResult[prevChapterIndex];
        if (prevChapter.subchapters.length > 0) {
          previousSubchapter =
            prevChapter.subchapters[prevChapter.subchapters.length - 1];
          break;
        }
      } while (prevChapterIndex > 0);
    }

    // Handle next subchapter
    if (subChapterIndex < currentChapter.subchapters.length - 1) {
      nextSubchapter = currentChapter.subchapters[subChapterIndex + 1];
    } else {
      let nextChapterIndex = currentChapterIndex;
      while (nextChapterIndex < chaptersListResult.length - 1) {
        nextChapterIndex++;
        const nextChapter = chaptersListResult[nextChapterIndex];
        if (nextChapter.subchapters.length > 0) {
          nextSubchapter = nextChapter.subchapters[0];
          break;
        }
      }
    }

    setPreviousSubchapter(previousSubchapter);
    setNextSubchapter(nextSubchapter);
  };

  const addIdToUrl = (key, id) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(key, id);
    history.push(currentUrl.pathname + currentUrl.search);
  };

  const handleNavigationClick = (subchapter) => {
    addIdToUrl('id', subchapter._id);
    addIdToUrl('chapterId', subchapter.chapterId);
  };

  useEffect(() => {
    handleFetchResoruce();
  }, [resourceId]);

  useEffect(() => {
    if (isChapterTab) {
      handleChaptersFetch();
    }
  }, []);

  useEffect(() => {
    if (!!subChapter._id && !!chaptersListResult.length) {
      handleNavigationSubchapters();
    }
  }, [subChapter, chaptersListResult]);

  const resource = tabFromQuery === 'chapters' ? subChapter : article;

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          padding: '40px 0',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ borderBottom: '2px solid #EFF3FE', margin: '10px 80px' }}>
          <H1>{resource.title}</H1>
        </Box>
        <Box
          className="scrollbar"
          sx={{
            padding: '20px 80px 0 80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            overflowY: 'scroll',
          }}
        >
          <Box sx={{ justifySelf: 'flex-start' }}>
            <RichTextContainer text={resource.description} />
          </Box>

          <Box
            sx={{
              justifySelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              position: 'sticky',
              bottom: 0,
              paddingTop: '20px',
              backgroundColor: '#fff',
            }}
          >
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'space-between',*/}
            {/*    height: '48px',*/}
            {/*    backgroundColor: '#EFF3FE',*/}
            {/*    paddingLeft: '10px',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <P>Ti-a fost de ajutor această informație?</P>*/}
            {/*  <Box>*/}
            {/*    <button>Da</button>*/}
            {/*    <button>Nu</button>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {isChapterTab &&
                previousSubchapter &&
                !!Object.keys(previousSubchapter).length && (
                  <Box
                    onClick={() => handleNavigationClick(previousSubchapter)}
                    sx={{
                      marginRight: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      border: '1px solid #74A3FE',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#EFF3FE',
                      },
                    }}
                  >
                    <Box>
                      <ArrowForward />
                    </Box>
                    <Box>{previousSubchapter.title}</Box>
                  </Box>
                )}
              {isChapterTab &&
                nextSubchapter &&
                !!Object.keys(nextSubchapter).length && (
                  <Box
                    onClick={() => handleNavigationClick(nextSubchapter)}
                    sx={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      border: '1px solid #74A3FE',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#fff',
                      },
                    }}
                  >
                    <Box>{nextSubchapter.title}</Box>
                    <Box sx={{ transform: 'rotate(180deg)' }}>
                      <ArrowForward />
                    </Box>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResourceComponent;
