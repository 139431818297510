import { Box } from '@mui/material';
import React from 'react';
import {
  MemberRowContainer,
  MembersCounter,
  MemberTypeContainer,
} from './styled/MemberTypes';
import { SpaceBetweenBox } from '../../common/components/styled/ReusablePositioningComponents';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';

import { AddButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import { IdeeaMemberAvatar } from '../../userProfile/components/styled/UserProfileComponentStyle';

const EmptyState = ({ placeholder }) => (
  <Box>
    <P faded light marginTop="10px">
      <i>{placeholder}</i>
    </P>
  </Box>
);

const MemberContainer = ({
  title,
  placeholder,
  userList = [],
  children,
  openModal,
}) => (
  <MemberTypeContainer>
    <SpaceBetweenBox>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <H2>{title}</H2>
        <MembersCounter>{userList.length}</MembersCounter>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
        <AddButtonComponent onClick={openModal} />
      </Box>
    </SpaceBetweenBox>

    {userList.length ? (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {userList.map((user) => (
          <MemberRowContainer key={user.id}>
            <IdeeaMemberAvatar src={user?.profilePicture} variant="circle">
              {user?.firstName?.substring(0, 2)}
            </IdeeaMemberAvatar>
            <Box sx={{ marginLeft: '10px' }}>
              <P>
                {user?.isInvited
                  ? 'Cont invitat'
                  : user.firstName + user.lastName}
              </P>
              <Box sx={{ display: 'flex' }}>
                <P>{user?.equity ? 'Co-echipier' : 'Asociat'}</P>
                <P marginLeft="5px">Equity: {user?.equity || 0}% </P>
                <P marginLeft="5px" faded light>
                  {user?.role}
                </P>
              </Box>
            </Box>
          </MemberRowContainer>
        ))}
      </Box>
    ) : (
      <EmptyState placeholder={placeholder} />
    )}
  </MemberTypeContainer>
);

export default MemberContainer;
