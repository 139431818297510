import styled from 'styled-components';

export const SideGuideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border: ${(props) => `0.5px solid ${props.theme.accentLight}`};
  border-radius: 10px;
`;

export const SideGuideHeader = styled.div`
  display: flex;
  height: 5vh;
  align-items: center;
  margin-top: 1vh;
  font-size: 12px;
  padding: 10px;
`;
