import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Thermometer } from '../../assets/icons/Thermometer.svg';
import { NavigationBarLink, PositionText } from '../styled';
import { ReactComponent as UsersIcon } from '../../assets/icons/Avatar_Cohorta-2.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/icons/Navigation_Resurse.svg';

const AdminNavigationMenu = () => (
  <>
    <li className="side-nav-item">
      <OverlayTrigger
        trigger={['hover', 'hover']}
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Tooltip id="button-tooltip" className="tooltip-tooltip">
            Users
          </Tooltip>
        }
      >
        <NavigationBarLink
          activeClassName="activeNavItem"
          to="/admin/users"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: '8px 30px',
          }}
        >
          <UsersIcon />
        </NavigationBarLink>
      </OverlayTrigger>
    </li>
    <li className="side-nav-item">
      <NavigationBarLink
        activeClassName="activeNavItem"
        to="/admin/mrl?tab=mrlSteps"
        className="side-sub-nav-link side-nav-link"
        style={{
          padding: '8px 30px',
        }}
      >
        <Thermometer
          fill="white"
          style={{
            width: '20px',
            height: '20px',
          }}
        />
        <PositionText>Ghid MRL</PositionText>
      </NavigationBarLink>
    </li>
    <li className="side-nav-item">
      <OverlayTrigger
        trigger={['hover', 'hover']}
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Tooltip id="button-tooltip" className="tooltip-tooltip">
            Resurse
          </Tooltip>
        }
      >
        <NavigationBarLink
          activeClassName="activeNavItem"
          to="/admin/resources"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: '8px 30px',
          }}
        >
          <ResourcesIcon />
        </NavigationBarLink>
      </OverlayTrigger>
    </li>

    <li className="side-nav-item">
      <OverlayTrigger
        trigger={['hover', 'hover']}
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Tooltip id="button-tooltip" className="tooltip-tooltip">
            Formular
          </Tooltip>
        }
      >
        <NavigationBarLink
          activeClassName="activeNavItem"
          to="/admin/contact-form"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: '8px 30px',
          }}
        >
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            color="white"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '0.1vw',
            }}
          />
        </NavigationBarLink>
      </OverlayTrigger>
    </li>
  </>
);

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(AdminNavigationMenu));
