import React from 'react';
import ModalComponent from './ModalComponent';

const DeleteArticlesModal = ({ isModalOpen, handleClose, children, title }) => (
  <ModalComponent open={isModalOpen} handleClose={handleClose} title={title}>
    {children}
  </ModalComponent>
);

export default DeleteArticlesModal;
