/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const AvatarImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) =>
    props.width
      ? props.width
      : props.large
      ? '300px'
      : props.medium
      ? '16.9vh'
      : '50px'};
  width: ${(props) =>
    props.height
      ? props.height
      : props.large
      ? '300px'
      : props.medium
      ? '18vh'
      : '50px'};
  background: url(${(props) => props.backgroundUrl}) 0 no-repeat;
  background-size: contain;
`;
export const AvatarImagePlaceholder = styled.div`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.blueAccent};
  box-sizing: border-box;
  width: ${(props) => props.width || '150px'};
  height: ${(props) => props.height || '150px'};
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AvatarIconPlaceholder = styled.span`
  color: ${(props) => props.theme.blueAccent};
`;

export const ImageIcon = styled.img`
  vertical-align: middle;
  width: 150px;
  height: 150px;
`;
