import update from 'immutability-helper';

import {
  findConfirmationToken as doFindConfirmationAccountToken,
  resendConfirmationToken as doResendConfirmationToken,
} from '../services/account';

// Actions
const START_LOAD = 'account-confirmation/START_LOAD';
const COMPLETE_LOAD = 'account-confirmation/COMPLETE_LOAD';
const FAIL_LOAD = 'account-confirmation/FAIL_LOAD';

const START_RESEND_EMAIL = 'account-confirmation/START_RESEND_EMAIL';
const COMPLETE_RESEND_EMAIL = 'account-confirmation/COMPLETE_RESEND_EMAIL';
const FAIL_RESEND_EMAIL = 'account-confirmation/FAIL_RESEND_EMAIL';

// Initial state
const initialState = {
  isLoading: false,
  token: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          token: action.token,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          token: action.token,
        },
      });

    case START_RESEND_EMAIL:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_RESEND_EMAIL:
      return update(state, {
        $merge: {
          isLoading: false,
          token: action.token,
        },
      });

    case FAIL_RESEND_EMAIL:
      return update(state, {
        $merge: {
          isLoading: false,
          token: undefined,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (token) => ({
  type: COMPLETE_LOAD,
  token,
});

const failLoad = (token) => ({
  type: FAIL_LOAD,
  token,
});

const startResendEmail = () => ({
  type: START_RESEND_EMAIL,
});

const completeResendEmail = (token) => ({
  type: COMPLETE_RESEND_EMAIL,
  token,
});

const failResendEmail = () => ({
  type: FAIL_RESEND_EMAIL,
});

export const findAccountConfirmationToken = (token) => (dispatch) => {
  dispatch(startLoad());
  return doFindConfirmationAccountToken(token)
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((err) => {
      dispatch(failLoad(err.response.data[0].type));
    });
};

export const resendConfirmationToken = (token) => (dispatch) => {
  dispatch(startResendEmail());
  return doResendConfirmationToken(token)
    .then((res) => {
      dispatch(completeResendEmail(res.data));
    })
    .catch((err) => {
      dispatch(failResendEmail(err.response.data[0].type));
    });
};
