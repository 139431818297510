import styled from 'styled-components';
import { Box } from '@mui/material';

const getModalSize = (size) => {
  switch (size) {
    case 'xs':
      return '240px';
    case 'sm':
      return '440px';
    case 'md':
      return '640px';
    case 'l':
      return '740px';
    case 'lg':
      return '840px';
    case 'xl':
      return '1040px';
    default:
      return '440px';
  }
};

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 240px;
  max-height: 90vh;
  overflow-y: auto;
  ${(props) => props.size && `max-width: ${getModalSize(props.size)};`}
`;
