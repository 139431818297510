import { Box, IconButton } from '@mui/material';
import React from 'react';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';
import { MarginContainer } from '../../mrl/components/styled/MrlStyled';
import {
  AddButtonComponent,
  SortButton,
} from '../../opportunityAnalysis/components/Buttons';
import { CharacteristicParagraph } from './styled/HypothesisDetailsPage';
import { TrashIconOutlined } from '../../common/assets/icons';
import { ClampedText } from '../../common/components/ClampedText';

const CharacteristicsSection = ({
  id,
  onScroll,
  characteristics,
  title,
  IconComponent,
  onAddClick,
  onSortClick,
  sortItems,
  deleteCharacteristic,
  paddingLeft,
  paddingRight,
  placeholder,
}) => (
  <Box
    className="scrollbar"
    sx={{
      border: '1px solid #B1CCFE',
      backgroundColor: 'white',
      width: '50%',
      height: '100%',
      borderRadius: '10px',
      marginLeft: '20px',
      paddingRight: '3px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        justifyContent: 'space-between',
        ...(paddingLeft && { paddingLeft }),
        ...(paddingRight && { paddingRight }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {IconComponent && <IconComponent />}
        <H2 marginLeft="10px"> {title}</H2>
      </Box>
      <MarginContainer display="flex">
        <SortButton margin="0 5px 0 0" onClick={onSortClick} />
        <AddButtonComponent onClick={() => onAddClick()} />
      </MarginContainer>
    </Box>

    {!characteristics.length && (
      <Box
        sx={{
          padding: '15px',
          ...(paddingLeft && { paddingLeft }),
          ...(paddingRight && { paddingRight }),
        }}
      >
        <P faded>
          <i> {placeholder}</i>
        </P>
      </Box>
    )}

    {!!characteristics.length && (
      <Box
        onScroll={onScroll}
        id={id}
        className="scrollbar"
        sx={{
          overflowY: 'scroll',
          height: 'calc(100% - 53px)',
          padding: '0px 10px',
        }}
      >
        {sortItems(characteristics).map((item, index) => (
          <CharacteristicParagraph
            id={`${id}-element-${index}`}
            className={`element-${id}`}
            key={item._id}
          >
            <ClampedText lineClamp={1} text={item.description} />
            {deleteCharacteristic && (
              <IconButton
                onClick={() => {
                  deleteCharacteristic(item._id);
                }}
              >
                <TrashIconOutlined fill="red" />
              </IconButton>
            )}
          </CharacteristicParagraph>
        ))}
      </Box>
    )}
  </Box>
);

export default CharacteristicsSection;
