import UserProfileIcon from '../common/assets/icons/accountProfile.svg';
import UserSettingsIcon from '../common/assets/icons/accountSettings.svg';
import UserSubscriptionsIcon from '../common/assets/icons/accountSubscriptions.svg';
import UserPaymentsIcon from '../common/assets/icons/accountPayments.svg';
import UserConditionsIcon from '../common/assets/icons/accountConditions.svg';
import DownloadIcon from '../common/assets/icons/download.svg';

export const LEGAL_CUSTOMER_TYPE = 'legal';
export const INDIVIDUAL_CUSTOMER_TYPE = 'individual';

export const PROFILE_PAGE_TABS = [
  {
    key: 'profile',
    label: 'Profil',
    icon: UserProfileIcon,
  },
  {
    key: 'settings',
    label: 'Setari',
    icon: UserSettingsIcon,
  },
  {
    key: 'subscriptions',
    label: 'Abonamente',
    icon: UserSubscriptionsIcon,
  },
  {
    key: 'payments',
    label: 'Plati',
    icon: UserPaymentsIcon,
  },
  {
    key: 'conditions',
    label: 'Conditii',
    icon: UserConditionsIcon,
  },
];

export const PAYMENTS_TABLE_HEADERS = [
  { name: 'Data', key: 'date' },
  { name: 'Plan', key: 'plan' },
  { name: 'Suma', key: 'amount' },
  { name: 'Status', key: 'status' },
  { name: '', key: 'actions', cellIcon: DownloadIcon },
];
