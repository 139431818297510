import Cookie from 'js-cookie';
import { map, size, get, find, orderBy } from 'lodash-es';
import { http } from '../../core/services/http';

export const loadUserTeams = (userId) =>
  http.get(`/user/${userId}/get-all-teams`).then(({ data }) => {
    let userTeams;
    const selectedTeam = get(
      Cookie.getJSON(`selectedTeam-${userId}`),
      'teamId'
    );
    if (!selectedTeam) {
      if (size(data)) {
        Cookie.set(`selectedTeam-${userId}`, { teamId: data[0]._id });
        userTeams = map(data, (team, index) =>
          index === 0 ? { ...team, selected: true } : team
        );
      }
      return userTeams;
    }
    if (!find(data, { _id: selectedTeam })) {
      Cookie.remove(`selectedTeam-${userId}`);
      return size(data) > 0
        ? map(data, (team, index) =>
            index === 0 ? { ...team, selected: true } : team
          )
        : undefined;
    }
    userTeams = map(data, (team) =>
      team._id === selectedTeam ? { ...team, selected: true } : team
    );

    const teams = orderBy(userTeams, ['createdAt'], ['desc']);

    return teams;
  });

export const setCurrentUserTeam = (userId, teamId) => {
  Cookie.set(`selectedTeam-${userId}`, { teamId });
};
