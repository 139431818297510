import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPasswordReset, resetRequestPasswordReset } from '../../ducks';
import { AccountPage, AccountPanel } from '../styled';
import { RequestPasswordResetForm } from '../forms';
import { DocumentTitle } from '../../../common/components';
import { WhiteCard } from '../../../common/components/styled';

const RequestPasswordResetPage = ({
  resetRequestPasswordReset,
  isRequestingPasswordReset,
  requestPasswordReset,
}) => {
  useEffect(() => () => resetRequestPasswordReset());

  const onSubmit = ({ email }) => requestPasswordReset(email);

  return (
    <>
      <DocumentTitle>Resetare parolă</DocumentTitle>
      <AccountPage>
        <AccountPanel size="small" isLoading={isRequestingPasswordReset}>
          <WhiteCard padding="30px" height="190px" borderRadius="10px">
            <RequestPasswordResetForm handleSubmit={onSubmit} />
          </WhiteCard>
        </AccountPanel>
      </AccountPage>
    </>
  );
};

RequestPasswordResetPage.propTypes = {
  isRequestingPasswordReset: PropTypes.bool.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  resetRequestPasswordReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => state.account.requestPasswordReset;

const mapDispatchToProps = {
  requestPasswordReset,
  resetRequestPasswordReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordResetPage);
