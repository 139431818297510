import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleCounterDiv } from '../../common/components/styled';
import { H1 } from '../../common/components/styled/ReusableTextComponents';
import { ProfileMenu } from '../../userProfile/components/styled/ProfilePage';
import {
  StyledNavLink,
  StyledProfileNav,
} from '../../userProfile/components/styled/ProfileTabItem';
import { MY_IDEEA_TABS } from '../../opportunityAnalysis/constants';

import { ProfileTabIcon } from '../../common/components/styled/LinkIcon';

import { PlaceholderComponent } from '../../opportunityAnalysis/components/pages/PlaceholderComponent';
import { MyIdeaPageContainer } from './styled/StyledIdeea';
import IdeaDescriptionPage from './IdeaDescriptionPage';

const IdeeaPage = () => {
  const userTeams =
    useSelector((state) => state.common.userTeams.userTeams) || [];
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'description';
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  const history = useHistory();

  const currentTeam = userTeams.find((team) => team.selected);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/idea?tab=${tab}`);
  };

  const pageComponents = {
    description: {
      component: <IdeaDescriptionPage currentTeam={currentTeam} />,
    },
    details: {
      component: <PlaceholderComponent />,
    },
    mrl: {
      component: <PlaceholderComponent />,
    },
    profile: {
      component: <PlaceholderComponent />,
    },
  };

  return (
    <>
      <TitleCounterDiv smallMargin>
        <H1>Ideea mea</H1>
      </TitleCounterDiv>

      <ProfileMenu>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {MY_IDEEA_TABS.map((tab, index) => (
            <Nav.Item key={tab.key} style={{ position: 'relative' }}>
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === MY_IDEEA_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabIcon icon={tab.icon} />
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <MyIdeaPageContainer fullBorder={activeTab !== 'description'}>
        {pageComponents[activeTab].component}
      </MyIdeaPageContainer>
    </>
  );
};

export default IdeeaPage;
