import React, { useState } from 'react';
import { H3, P } from '../../common/components/styled/ReusableTextComponents';
import { Box, InputAdornment, TextField } from '@mui/material';
import { PersonSearch, SearchIcon } from '../../common/assets/icons';
import RichTextContainer from '../../common/components/RichTextContainer';

const ArticlesSideGuide = ({ articlesListResult, handleArticleClick }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterByTitle = (arrayOfObjects, searchTerm) =>
    arrayOfObjects.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const filteredResults = articlesListResult?.length
    ? filterByTitle(articlesListResult, searchTerm)
    : [];

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <PersonSearch />
        <P>Articole</P>
      </Box>
      <Box
        className="scrollbar"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '5px',
          overflowY: 'scroll',
          height: '100%',
          paddingRight: '7px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '15px',
          }}
        >
          <TextField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Cauta articole"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '6px',
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
        >
          {!!filteredResults?.length &&
            filteredResults.map((article) => (
              <Box
                onClick={() => handleArticleClick(article._id)}
                key={article._id}
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid #74A3FE',
                  cursor: 'pointer',
                }}
              >
                <H3>{article.title}</H3>
                <Box
                  sx={{
                    maxHeight: '110px',
                    overflow: 'hidden',
                    position: 'relative',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '40px',
                      background:
                        'linear-gradient(to bottom, transparent, white 90%)',
                    },
                  }}
                >
                  <RichTextContainer text={article.description} />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default ArticlesSideGuide;
