import React from 'react';
import { Box, Grid } from '@mui/material';
import { DetailIcon } from '../../common/components/styled';
import { HYPOTHESIS_IMPORTANCE_DETAILS } from '../constants/hypotheses';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';

const HypothesesDescription = ({ hypothesis }) => {
  const importanceDetails =
    HYPOTHESIS_IMPORTANCE_DETAILS[hypothesis.importance];

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginBottom: '30px',
      }}
    >
      <Box>
        <H2>Descriere</H2>
      </Box>
      <Box sx={{ minHeight: '140px' }}>
        <P>{hypothesis.description}</P>
      </Box>
      <Box>
        {importanceDetails && (
          <div style={{ display: 'flex', gap: '10px' }}>
            <P>Importanta:</P>
            <Box style={{ display: 'flex' }}>
              <DetailIcon icon={importanceDetails.icon} />
              <span style={{ marginLeft: '4px' }}>
                {importanceDetails.label}
              </span>
            </Box>
          </div>
        )}
      </Box>
    </Grid>
  );
};

export default HypothesesDescription;
