import React, { useEffect, useState } from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NavigationBarResolver from './NavigationBarResolver';
import { PageContainer, PageBody, PageContentWrapper } from './styled';

import {
  closeModal,
  getModalOpenStateSelector,
  openModal,
  currentTeamIdSelector,
} from '../ducks';
import DocumentTitle from './DocumentTitle';
import matchRoute from '../../utils/services/matchRoute';
import { Mrl } from '../../mrl/components';
import VideoModal from './VideoModal';
import { PageMiddlePoint } from '../../onboarding/styled/onboardingDashboardBricks';
import { loadProfile } from '../../userProfile/ducks';
import SideGuide from '../../resources/components/SideGuide';

const Page = ({
  children,
  isLoading,
  role,
  isMenuOpen,
  location,
  loadProfile,
}) => {
  const [isMrlContainerOpen, setIsMrlContainerOpen] = useState(true);
  useEffect(() => {
    loadProfile();
  }, []);
  const SHOW_MRL_GUIDE_KEY = 'SHOW_MRL_GUIDE';
  const ADMIN_ROLE = 'ADMIN';

  const urlsToHideMrl = [
    { pathname: '/admin/mrl', search: '?tab=articles' },
    { pathname: '/admin/mrl/article/form', search: '' },
    { pathname: '/admin/mrl', search: '?tab=chapters' },
    { pathname: '/admin/mrl/course/form', search: '' },
    { pathname: '/resources', search: '' },
  ];

  const isMatchingUrl = (urls) =>
    urls.some(
      (url) =>
        window.location.pathname === url.pathname &&
        window.location.search.includes(url.search)
    );

  const renderMrl = () => {
    const positiveConditions = [
      () => localStorage.getItem(SHOW_MRL_GUIDE_KEY) === 'true',
      () => !localStorage.getItem(SHOW_MRL_GUIDE_KEY),
      () => role === ADMIN_ROLE,
    ];

    const negativeConditions = [() => isMatchingUrl(urlsToHideMrl)];

    if (negativeConditions.some((condition) => condition())) {
      return false;
    }

    return positiveConditions.some((condition) => condition());
  };

  const urlsToShowResourcesGuide = [
    { pathname: '/resources', search: '' },
    { pathname: '/admin/mrl', search: '?tab=chapters' },
  ];

  const isMrlGuideVisible = renderMrl();
  // const isResourcesGuideVisible = window.location.pathname === '/resources';
  const isResourcesGuideVisible = isMatchingUrl(urlsToShowResourcesGuide);
  const calculateWidth = (isVisible, isOpen) => {
    if (isVisible) {
      return isOpen ? '78%' : '93%';
    }
    return '100%';
  };

  return (
    <PageContainer>
      <DocumentTitle>Launchpad</DocumentTitle>
      <NavigationBarResolver />
      {!isLoading && (
        <PageBody
          open={isMenuOpen}
          isCanvasPage={matchRoute(location.pathname, '/canvas')}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'block',
                width: calculateWidth(isMrlGuideVisible, isMrlContainerOpen),
              }}
            >
              <PageContentWrapper
                open={isMenuOpen}
                isCanvasPage={matchRoute(location.pathname, '/canvas')}
              >
                {children}
              </PageContentWrapper>
            </div>

            {isMrlGuideVisible && (
              <div
                style={{
                  width: isMrlContainerOpen ? '22%' : '5%',
                  paddingLeft: '20px',
                  maxWidth: '22%',
                  height: '95vh',
                  float: 'right',
                }}
              >
                <Mrl
                  isMrlContainerOpen={isMrlContainerOpen}
                  setIsMrlContainerOpen={setIsMrlContainerOpen}
                />
              </div>
            )}

            {isResourcesGuideVisible && (
              <div
                style={{
                  width: isMrlContainerOpen ? '22%' : '5%',
                  paddingLeft: '20px',
                  maxWidth: '22%',
                  height: '95vh',
                  float: 'right',
                }}
              >
                <SideGuide />
              </div>
            )}
          </div>
          <VideoModal />
        </PageBody>
      )}
      <PageMiddlePoint id="page-middle" />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.userTeams.isLoading,
  isCreatingExperiment: state.experiments.isCreating,
  isHypothesisModalOpen: getModalOpenStateSelector(state.common, 'hypothesis'),
  isExperimentModalOpen: getModalOpenStateSelector(state.common, 'experiment'),
  isExperimentDetailModalOpen: getModalOpenStateSelector(
    state.common,
    'experimentDetail'
  ),
  isHypothesisModaDetaillOpen: getModalOpenStateSelector(
    state.common,
    'hypothesisDetail'
  ),
  role: get(state.account.login, 'user.role'),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  closeModal,
  loadProfile,
  openModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
