import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import Box from '@mui/material/Box';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';

import { get } from 'lodash-es';
import DatePicker from 'react-datepicker';
import { ReactComponent as TrashIcon } from '../../../common/assets/icons/Button_Delete_S.svg';
import { ProfileImageUploader } from '../../../common/components';
import {
  InputImgModalDiv,
  InputImgText,
  InputImgTextIcon,
  InputImgProfile,
} from '../../../common/components/styled/Inputs';

import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';

const RenderProfilePicture = (props) => {
  const { values, setFieldValue } = props;

  const [photo, setPhoto] = useState();

  const onFilesSelected = (files) => {
    setFieldValue('photo', files);
    setPhoto(URL.createObjectURL(files[0]));
  };

  const handleDeleteProfileImage = () => {
    setFieldValue('photo', '');
    setFieldValue('profilePicture', '');
    setPhoto(null);
  };

  return (
    <Grid item xs={12}>
      <InputImgProfile>
        <InputImgModalDiv>
          <ProfileImageUploader
            upload
            label="Imagine de profil"
            onFilesSelected={onFilesSelected}
            imageUrl={photo || values.profilePicture}
            width="40px"
            height="40px"
          />
        </InputImgModalDiv>
        <InputImgText>Incarca poza</InputImgText>
        <InputImgTextIcon>
          <IconButton onClick={handleDeleteProfileImage}>
            <TrashIcon />
          </IconButton>
        </InputImgTextIcon>
      </InputImgProfile>
    </Grid>
  );
};

const RenderTextField = (props) => {
  const { label, placeholder, type = 'text', md = 6 } = props;
  const [field, meta] = useField(props);
  return (
    <Grid item xs={12} md={md}>
      <TextField
        {...field}
        fullWidth
        label={label}
        type={type}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
      />
    </Grid>
  );
};

const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  const [meta] = useField(props);
  const fieldValue = get(values, name);

  // eslint-disable-next-line react/display-name
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      inputRef={ref}
      value={value}
      label={label}
      placeholder={placeholder}
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} />
          </InputAdornment>
        ),
      }}
    />
  ));

  return (
    <Grid item xs={12} md={6} id={controlId}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={fieldValue}
        onChange={(date) => setFieldValue(name, date)}
        customInput={<CustomInput />}
      />
    </Grid>
  );
};

const RenderTextAreaField = (props) => {
  const { label, placeholder } = props;
  const [field, meta] = useField(props);
  return (
    <Grid item xs={12}>
      <TextField
        {...field}
        fullWidth
        multiline
        rows={4}
        label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
      />
    </Grid>
  );
};

const RenderForm = ({
  handleSubmit,
  submitCount,
  values,
  errors,
  initialValues,
  setFieldValue,
  isValid,
  role,
  ...rest
}) => (
  <form noValidate>
    <Grid container spacing={2}>
      <RenderProfilePicture
        name="photo"
        values={values}
        setFieldValue={setFieldValue}
      />
      <RenderTextField
        name="firstName"
        label="Nume*"
        placeholder="Numele"
        md={12}
      />
      <RenderTextField
        name="lastName"
        label="Prenume*"
        placeholder="Prenumele"
        md={12}
      />
      <RenderTextAreaField
        name="about"
        label="Câteva cuvinte despre tine*"
        placeholder="Care sunt pasiunile tale? Ce interese ai? Expertiza și experiență dobândita? Cunoștințe?"
      />
      <RenderDateField
        name="birthDate"
        label="Data nașterii"
        placeholder="zz/ll/aaaa"
        values={values}
        setFieldValue={setFieldValue}
      />
      <RenderTextField
        name="address"
        label="Localitatea"
        placeholder="Localitatea"
      />
      <RenderTextField name="phone" label="Telefon" md={12} />
      <RenderTextField
        name="linkedIn"
        label="Profil LinkedIn"
        placeholder="URL LinkedIn"
        md={12}
      />
    </Grid>
    <Box mt={3} display="flex" justifyContent="flex-start">
      <PrimaryButton
        type="submit"
        onClick={handleSubmit}
        icon={SaveIconOutlined}
      >
        Salvează
      </PrimaryButton>
    </Box>
  </form>
);

const ProfileForm = ({ onSubmit, userProfile, role }) => {
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    firstName: Yup.string().required('Câmp obligatoriu'),
    lastName: Yup.string().required('Câmp obligatoriu'),
    birthDate: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    profilePicture: Yup.string(),
    photo: Yup.mixed(),
    linkedIn: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    about: Yup.string().required('Câmp obligatoriu'),
  });

  const initialValues = {
    profilePicture: userProfile.profilePicture
      ? userProfile.profilePicture
      : '',
    firstName: userProfile.firstName ? userProfile.firstName : '',
    lastName: userProfile.lastName ? userProfile.lastName : '',
    birthDate: userProfile.birthDate ? new Date(userProfile.birthDate) : '',
    address: userProfile.address ? userProfile.address : '',
    phone: userProfile.phone ? userProfile.phone : '',
    linkedIn: userProfile.linkedIn ? userProfile.linkedIn : '',
    about: userProfile.about ? userProfile.about : '',
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} role={role} />}
    </Formik>
  );
};

export default ProfileForm;
