import React, { useEffect, useState } from 'react';

import RegistrationPillQuestion from './RegistrationPillQuestion';
import { P } from '../../common/components/styled/ReusableTextComponents';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import {
  RenderRoundedSelectField,
  RenderRoundedTextField,
} from '../../opportunityAnalysis/components/forms/TrendsForm';
import { useApi } from '../../hooks/useApi';
import { postMyIdeaMentor, postMyIdeeaMember } from '../../requests/myIdeea';
import { getAllTeams } from '../../requests/teams';

const ChooseNumberOfMembers = ({
  currentStepQuestionData,
  handleClickAnswer,
  selectedAnswers,
  userProfile,
}) => {
  const [, runPostMember] = useApi(postMyIdeeaMember);
  const [, runPostMentor] = useApi(postMyIdeaMentor);
  const [canSubmit, setCanSubmit] = useState(false);
  const [{ result: userTeams }, runGetAllTeams] = useApi(getAllTeams, []);

  const handleSubmitInvite = async (values, resetForm) => {
    const [firstTeam] = userTeams;

    if (values?.role === 'mentor') {
      try {
        await runPostMentor({
          params: {
            teamId: firstTeam?._id,
          },
          data: {
            email: values.email,
          },
        });
        resetForm();
      } catch (err) {
        resetForm();
      }
    } else {
      try {
        await runPostMember({
          params: {
            teamId: firstTeam?._id,
          },
          data: {
            email: values.email,
            equity: values?.equity || 1,
            role: ['hipster'],
            lastname: values?.preName,
            firstName: values?.name,
          },
        });

        resetForm();
      } catch (err) {
        resetForm();
      }
    }
  };

  useEffect(() => {
    runGetAllTeams({
      params: {
        userId: userProfile?.userId?._id,
      },
    });
  }, []);

  const initialValues = {
    name: '',
    preName: '',
    email: '',
    role: 'founder',
  };
  const validateForm = (values) => {
    const errors = {};

    if (!values?.name) {
      errors.name = 'Câmp obligatoriu';
    }

    if (!values?.preName) {
      errors.description = 'Câmp obligatoriu';
    }

    if (!values?.email) {
      errors.email = 'Camp obligatoriu';
    }

    if (!values.role) {
      errors.role = 'Camp obligatoriu';
    }

    if (!Object.keys(errors).length) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }

    return errors;
  };

  return (
    <div>
      <RegistrationPillQuestion
        handleClickAnswer={handleClickAnswer}
        currentStepQuestionData={currentStepQuestionData}
        selectedAnswers={selectedAnswers}
      />

      <Box sx={{ marginTop: '30px' }}>
        <P marginBottom="20px">Invita membrii sa se alature echipei</P>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Formik initialValues={initialValues} validate={validateForm}>
            {({ handleSubmit, values, resetForm }) => (
              <form
                style={{ width: '100%' }}
                onSubmit={handleSubmit}
                noValidate
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RenderRoundedTextField id="name" label="nume" />
                  <RenderRoundedTextField id="preName" label="prenume" />
                  <RenderRoundedTextField id="email" label="email" />
                  <RenderRoundedSelectField
                    id="role"
                    label="alege rol"
                    options={[
                      { label: 'fondator', value: 'founder' },
                      { label: 'expert', value: 'mentor' },
                    ]}
                  />
                  <Box
                    onClick={() => handleSubmitInvite(values, resetForm)}
                    sx={{
                      padding: '5px',
                      backgroundColor: canSubmit ? '#2A5AB7' : '#efeff4',
                      color: canSubmit ? '#efeff4' : '#2A5AB7',
                      borderRadius: '50%',
                      minWidth: '35px',
                      minHeight: '35px',
                      width: '35px',
                      height: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: canSubmit ? 'pointer' : '',
                    }}
                  >
                    >
                  </Box>
                </div>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default ChooseNumberOfMembers;
