import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  editHypothesisEmpathyMap,
  editHypothesisEmpathyMapItem,
} from '../../ducks';
import { EmpathyMapForm } from '../forms';
import { ReactComponent as ToDoIcon } from '../../../common/assets/icons/to-do.svg';
import { ReactComponent as EarningsIcon } from '../../../common/assets/icons/castiguri.svg';
import { ReactComponent as PainsIcon } from '../../../common/assets/icons/dureri.svg';

import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const EmpathyMapModal = (props) => {
  const {
    hypothesisId,
    isModalOpen,
    setIsModalOpen,
    isEditing,
    editHypothesisEmpathyMap,
    editHypothesisEmpathyMapItem,
    name,
    item,
    isEditMode,
    formLabel,
    modalLabel,
    iconsFormLabel,
  } = props;

  const getIcon = (name) => {
    switch (name) {
      case 'pains':
        return <PainsIcon style={{ marginRight: '5px' }} />;
      case 'gains':
        return <EarningsIcon style={{ marginRight: '5px' }} />;
      case 'earnings':
        return <ToDoIcon style={{ marginRight: '5px' }} />;
      default:
        return '';
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const onSubmit = (values) =>
    isEditMode
      ? editHypothesisEmpathyMapItem(hypothesisId, values, name)
          .then(() => setIsModalOpen(false))
          .catch(() => setIsModalOpen(false))
      : editHypothesisEmpathyMap(hypothesisId, values, name)
          .then(() => setIsModalOpen(false))
          .catch(() => setIsModalOpen(false));

  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">
            {' '}
            {getIcon(name)} Adauga {modalLabel}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <EmpathyMapForm
          onSubmit={onSubmit}
          name={name}
          item={item}
          formLabel={formLabel}
          iconsFormLabel={iconsFormLabel}
        />
      </Modal.Body>

      <Loading isLoading={isEditing} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.hypotheses.isEditing,
});

const mapDispatchToProps = {
  editHypothesisEmpathyMap,
  editHypothesisEmpathyMapItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpathyMapModal);
