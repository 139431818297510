import { combineReducers } from 'redux';
import { reducer as loginReducer } from './login';
import { reducer as requestPasswordResetReducer } from './requestPasswordReset';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as registerReducer } from './register';
import { reducer as accountConfirmationReducer } from './accountConfirmationToken';

export {
  login,
  facebookLogin,
  logout,
  resetLogin,
  isAdminSelector,
  isMentorSelector,
  isAcceleratorSelector,
} from './login';
export { register, checkEmailRegistered, resetRegister } from './register';
export {
  requestPasswordReset,
  resetRequestPasswordReset,
} from './requestPasswordReset';
export { resetPassword, resetResetPassword } from './resetPassword';
export {
  findAccountConfirmationToken,
  resendConfirmationToken,
} from './accountConfirmationToken';

export const reducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  requestPasswordReset: requestPasswordResetReducer,
  resetPassword: resetPasswordReducer,
  accountConfirmation: accountConfirmationReducer,
});
