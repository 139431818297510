import React, { useEffect, useState } from 'react';

import { Box, Popper } from '@mui/material';
import { connect } from 'react-redux';
import { PageContainer } from '../styled/ContextAnalysis';
import {
  H2,
  P,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';

import {
  getCompetitorMetrics,
  postNewCompetitor,
  postCompetitorMetric,
  postNewCriteria,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
} from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { CompetitionChart } from '../Competition/CompetitionChart';
import { CompetitionTable } from '../Competition/CompetitionTable';
import {
  formatChartData,
  generateFullCompetitionRows,
} from '../Competition/helpers';
import { ChartButtonComponent } from '../Buttons';
import { ChartFilters } from '../Competition/ChartFilters';
import { TrashIconOutlined } from '../../../common/assets/icons';

const CompetitionPage = ({
  getCompetitorMetrics,
  teamId,
  postNewCompetitor,
  postNewCriteria,
  teamCompetitorMetrics,
  postCompetitorMetric,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
}) => {
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const chartValues = [...rows].splice(1, 5);

  const formattedChartData = formatChartData(
    chartValues,
    teamCompetitorMetrics
  );
  const [selectedFilters, setSelectedFilters] = useState([]);

  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'simple-popper' : undefined;
  const handleClickFilters = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleCheck = (event) => {
    selectedFilters.includes(event.target.value)
      ? setSelectedFilters(
          selectedFilters.filter((filter) => filter !== event.target.value)
        )
      : setSelectedFilters([...selectedFilters, event.target.value]);
  };

  useEffect(() => {
    if (teamId) {
      getCompetitorMetrics(teamId);
    }
  }, [getCompetitorMetrics, teamId]);

  useEffect(() => {
    if (teamCompetitorMetrics) {
      const rows = generateFullCompetitionRows(teamCompetitorMetrics);
      setRows(rows);
    }
  }, [teamCompetitorMetrics]);

  const handleNewCompetitor = (competitorName) => {
    postNewCompetitor({ name: competitorName }, teamId);
  };
  const handleEditCompetitor = (competitorName, competitorId) => {
    editCompetitor({ name: competitorName }, teamId, competitorId);
  };

  const handleAddCriteria = (criteriaName) => {
    postNewCriteria({ name: criteriaName }, teamId);
  };

  const handleEditCriteria = (criteriaName, criteriaId) => {
    editCriteria({ name: criteriaName }, teamId, criteriaId);
  };

  const handleDeleteCriteria = (criteriaId) => {
    deleteCriteria(criteriaId, teamId);
  };

  const handleDeleteCompetitor = (competitorId) => {
    deleteCompetitor(competitorId, teamId);
  };

  const handleCellUpdate = (cellData) => {
    const { competitorId, criteriaId, value } = cellData;
    postCompetitorMetric({ competitorId, criteriaId, value }, teamId);
  };

  return (
    <PageContainer className="scrollbar">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <H2> Grafic competitie </H2>
          <P marginTop="10px" faded>
            Completeaza criteriile cu datele competitorilor...
          </P>
        </Box>

        <div>
          <ChartButtonComponent
            handleClick={handleClickFilters}
            selectedFilters={selectedFilters}
          />

          <Popper
            id={popperId}
            open={popperOpen}
            anchorEl={anchorEl}
            sx={{ zIndex: '100' }}
            placement="bottom-start"
          >
            <ChartFilters
              handleCheck={handleCheck}
              selectedFilters={selectedFilters}
              formattedChartData={formattedChartData}
              competitorMetrics={teamCompetitorMetrics}
            />
          </Popper>
        </div>
      </Box>
      <CompetitionChart
        teamCompetitorMetrics={teamCompetitorMetrics}
        chartValues={formattedChartData}
        selectedFilters={selectedFilters}
      />
      <Box sx={{ marginTop: '50px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <H2> Analiza competitorilor </H2>
          <SecondaryButton inverted icon={TrashIconOutlined} iconColor="red">
            Goleste tabelul
          </SecondaryButton>
        </Box>
        <P marginTop="10px" faded>
          Adaugă noi criterii făcând click pe butonul „+” din partea de jos a
          secțiunii.
        </P>
      </Box>
      <CompetitionTable
        postNewCompetitor={handleNewCompetitor}
        rows={rows}
        handleCellUpdate={handleCellUpdate}
        handleEditCompetitor={handleEditCompetitor}
        handleAddCriteria={handleAddCriteria}
        handleEditCriteria={handleEditCriteria}
        handleDeleteCriteria={handleDeleteCriteria}
        handleDeleteCompetitor={handleDeleteCompetitor}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  teamCompetitorMetrics: state.contextAnalysis.teamCompetitorMetrics,
});

const mapDispatchToProps = {
  getCompetitorMetrics,
  postNewCompetitor,
  postCompetitorMetric,
  postNewCriteria,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionPage);
