import React from 'react';

// Components
import ModalComponent from '../../../common/components/ModalComponent';
import ConclusionForm from '../forms/ConclusionForm';

const AddConclusionModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmitConclusion,
  teamConclusions,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={setIsModalOpen}
    title="Concluzie analiză context"
  >
    <ConclusionForm
      teamConclusions={teamConclusions}
      handleSubmitConclusion={handleSubmitConclusion}
    />
  </ModalComponent>
);

export default AddConclusionModal;
