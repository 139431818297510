import { Box, Grid } from '@mui/material';
import React from 'react';
import {
  H2,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { FilterIcon } from '../../common/assets/icons';
import DualOptionsSelect from '../../common/components/DualOptionSelect';
import { AddButtonComponent } from '../../opportunityAnalysis/components/Buttons';

const ArticlePageHeaderSection = ({
  hasFilters,
  headerTitle,
  handleFiltersModalStatus,
  filtersNumber,
  toggleValue,
  handleToggleValue,
  onAddClick,
}) => (
  <Grid
    container
    justifyContent="space-between"
    sx={{
      borderBottom: '1px solid #74A3FE',
      paddingBottom: '10px',
      gap: '10px',
      alignItems: 'flex-end',
      marginTop: '20px',
      width: '70%',
    }}
  >
    <H2>{headerTitle}</H2>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
      {hasFilters && (
        <PrimaryButton
          onClick={handleFiltersModalStatus}
          icon={FilterIcon}
          inverted
        >
          {`Filtre (${filtersNumber})`}
        </PrimaryButton>
      )}
      <DualOptionsSelect
        toggleValue={toggleValue}
        handleToggleValue={handleToggleValue}
      />
      <AddButtonComponent onClick={onAddClick} />
    </Box>
  </Grid>
);

export default ArticlePageHeaderSection;
