import React from 'react';
import ModalComponent from '../../../common/components/ModalComponent';
import SwotForm from '../forms/SwotForm';

export const SwotModal = (props) => {
  const { isModalOpen, setIsModalOpen, swotType, selectedSwotElement } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title="Adauga un element SWOT"
    >
      <SwotForm
        handleCloseModal={handleCloseModal}
        swotType={swotType}
        selectedSwotElement={selectedSwotElement}
      />
    </ModalComponent>
  );
};
