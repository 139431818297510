import { runPostApiRequest } from '../api/Api';
import { POST_CHAT_GPT_CONVERSATION } from '../common/constants/apiRoutes';

const openAiSerializer = (data) => {
  const { choices } = data;
  const { message } = choices[0];
  return message;
};

export function postOpenAiConversation({ payload = {} }) {
  return runPostApiRequest({
    endpoint: POST_CHAT_GPT_CONVERSATION,
    payload,
    onFailMessage: 'Eroare la conectarea cu asistentul virtual.',
    serializer: openAiSerializer,
  });
}
