import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { UpwardsTrend } from '../../common/assets/icons';
import { P } from '../../common/components/styled/ReusableTextComponents';

export const TrendFilters = ({ handleCheck, selectedFilters }) => (
  <Box
    sx={{
      background: '#fff',
      padding: '15px',
      border: '1px solid #74A3FE',
      borderRadius: '4px',
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => handleCheck(true)}
          checked={selectedFilters.includes(true)}
        />
      }
      label={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UpwardsTrend fill="green" />
          <P marginLeft="10px">Favorabil</P>
        </Box>
      }
    />
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => handleCheck(false)}
          checked={selectedFilters.includes(false)}
        />
      }
      label={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ transform: 'scaleY(-1)' }}>
            <UpwardsTrend fill="red" />
          </Box>
          <P marginLeft="10px">Nefavorabil</P>
        </Box>
      }
    />
  </Box>
);
