import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter, map } from 'lodash-es';
import {
  DeleteCommentButton,
  Heading2,
  Heading4Regular,
} from '../../../common/components/styled';
import { PageContainer } from '../styled/HypothesisDetailsPage';
import HypothesesDescription from '../HypothesesDescription';
import HypothesesSegments from '../HypothesesSegments';
import HypothesesIndicators from '../HypothesisIndicators';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { PencilIcon, TrashIconOutlined } from '../../../common/assets/icons';
import { deleteHypothesis, editHypothesis, loadHypothesis } from '../../ducks';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
} from '../../../mentors/components/styled';
import {
  createTargetIndicators,
  deleteTargetIndicator,
  editTargetIndicator,
  getAllTargetIndicators,
} from '../../../targetIndicators/ducks/targetIndicators';
import { currentTeamIdSelector } from '../../../common/ducks';
import AddHypothesisModal from '../modals/AddHypothesisModal';

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere ipoteză</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea ipotezei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HypothesisDescriptionPage = ({ canvasData, segmentsHypothesis }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses);
  const firstName = useSelector((state) => state.userProfile.firstName);
  const lastName = useSelector((state) => state.userProfile.lastName);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const targetIndicators = useSelector(
    (state) => state.targetIndicators.targetIndicators || []
  );
  const [isHypothesisModalOpen, setIsHypothesisModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const handleDeleteTargetIndicators = async () => {
    await Promise.all(
      targetIndicators.map((targetIndicator) =>
        dispatch(deleteTargetIndicator(targetIndicator._id))
      )
    );
  };

  const handleDeleteHypothesis = async () => {
    const response = await dispatch(deleteHypothesis(hypothesis._id));
    if (response.status === 200) {
      await handleDeleteTargetIndicators();
      history.push('/canvas');
    }
  };

  const areSegmentsAvailable = hypothesis.columnBMC !== 'customerSegments';

  const segmentHypothesis = filter(hypotheses, {
    columnBMC: 'customerSegments',
  });
  const segmentOptions = map(segmentHypothesis, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
    color: `${el.color}`,
  }));

  const handleCloseModal = () => {
    setIsHypothesisModalOpen(false);
  };

  const getHypothesisFormValues = () => {
    const { title, description, columnBMC, importance, segment } = hypothesis;

    const targetIndicatorFields = {};

    for (let i = 0; i < 3; i++) {
      const targetIndicator = targetIndicators && targetIndicators[i];

      targetIndicatorFields[`targetIndicatorType${i + 1}`] = targetIndicator
        ? targetIndicator.targetIndicatorType
        : '';

      targetIndicatorFields[`targetIndicatorValue${i + 1}`] = targetIndicator
        ? targetIndicator.value
        : '';
    }

    return {
      title,
      description,
      columnBMC,
      importance,
      segment,
      ...targetIndicatorFields,
    };
  };

  const handleTargetIndicators = async (values) => {
    const handleDelete = async (id) =>
      await dispatch(deleteTargetIndicator(id));

    const handleCreate = async (indicatorInfo) => {
      const createIndicatorPayload = {
        targetIndicators: [indicatorInfo],
        hypothesisId: hypothesis._id,
      };
      await dispatch(createTargetIndicators(createIndicatorPayload));
    };

    const handleEdit = async (id, indicatorInfo) =>
      await dispatch(editTargetIndicator(id, indicatorInfo));

    for (let i = 1; i <= values.targetIndicatorsNumber; i++) {
      const {
        [`targetIndicatorType${i}`]: targetIndicatorType,
        [`targetIndicatorValue${i}`]: targetIndicatorValue,
      } = values;
      const targetIndicatorId = targetIndicators[i - 1]?._id;

      const indicatorInfo = {
        targetIndicatorType,
        value: targetIndicatorValue,
      };

      const isInfoIncomplete = Object.values(indicatorInfo).some(
        (value) => value === ''
      );

      if (!targetIndicatorId && isInfoIncomplete) {
        continue;
      }

      if (targetIndicatorId && isInfoIncomplete) {
        await handleDelete(targetIndicatorId);
        continue;
      }

      if (!targetIndicatorId && !isInfoIncomplete) {
        await handleCreate(indicatorInfo);
        continue;
      }

      if (targetIndicatorId && !isInfoIncomplete) {
        await handleEdit(targetIndicatorId, indicatorInfo);
      }
    }

    if (values.targetIndicatorsNumber < targetIndicators.length) {
      for (
        let i = values.targetIndicatorsNumber;
        i < targetIndicators.length;
        i++
      ) {
        await handleDelete(targetIndicators[i]._id);
      }
    }
  };

  const onEditHypothesis = async (values) => {
    const createdBy = `${firstName} ${lastName} `;
    const payload = {
      title: values.title,
      createdBy,
      description: values.description,
      columnBMC: values.columnBMC,
      importance: values.importance,
      segment: values.segment,
      hypothesisCategory: hypothesis.hypothesisCategory,
    };

    setIsHypothesisModalOpen(false);
    await dispatch(editHypothesis(hypothesis._id, payload));
    await dispatch(loadHypothesis(hypothesis._id));
    await handleTargetIndicators(values);
    await dispatch(getAllTargetIndicators(teamId, hypothesis._id));
  };

  useEffect(() => {
    dispatch(loadHypothesis(hypothesis._id));
    dispatch(getAllTargetIndicators(teamId, hypothesis._id));
  }, []);
  return (
    <PageContainer className="scrollbar">
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
          padding: '20px',
          paddingBottom: 0,
          width: '100%',
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: areSegmentsAvailable ? '1fr 1fr' : '1fr',
          }}
        >
          <HypothesesDescription
            hypothesis={hypothesis}
            sx={{
              gridColumn: '1/2',
              gridRow: '1/2',
            }}
          />
          {areSegmentsAvailable ? (
            <>
              <Box
                sx={{
                  gridColumn: '2/3',
                  gridRow: '1/3',
                  marginLeft: '50px',
                }}
              >
                <HypothesesSegments
                  segmentsHypothesis={segmentsHypothesis}
                  canvasData={canvasData}
                />
              </Box>
              <HypothesesIndicators
                targetIndicators={targetIndicators}
                sx={{
                  gridColumn: '1/2',
                  gridRow: '2/3',
                }}
              />
            </>
          ) : (
            <Box sx={{ gridColumn: '2/3', gridRow: '1/3', marginLeft: '50px' }}>
              <HypothesesIndicators targetIndicators={targetIndicators} />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '0 20px',
        }}
      >
        <hr style={{ width: '100%', border: '0.2px solid #B1CCFE' }} />
        <Box sx={{ display: 'flex' }}>
          <PrimaryButton
            onClick={() => setIsHypothesisModalOpen(true)}
            inverted
            icon={PencilIcon}
            marginRight="10px"
          >
            Editeaza
          </PrimaryButton>
          <SecondaryButton
            onClick={() => setOpenAlertMessage(true)}
            icon={TrashIconOutlined}
            inverted
          >
            Sterge ipoteza
          </SecondaryButton>
        </Box>
      </Box>
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteHypothesis}
      />
      {/* <AddHypothesisModalLegacy */}
      {/*  isModalOpen={isHypothesisModalOpen} */}
      {/*  setIsModalOpen={setIsHypothesisModalOpen} */}
      {/*  isEditMode */}
      {/*  entityId={hypothesis._id} */}
      {/*  hypothesis={hypothesis} */}
      {/*  loadHypothesis={() => dispatch(loadHypothesis)} */}
      {/* /> */}
      <AddHypothesisModal
        isModalOpen={isHypothesisModalOpen}
        setIsModalOpen={setIsHypothesisModalOpen}
        segmentOptions={segmentOptions}
        handleClose={handleCloseModal}
        handleSubmitHypothesis={onEditHypothesis}
        hypothesisFormValues={getHypothesisFormValues()}
        isEdit
      />
    </PageContainer>
  );
};

export default HypothesisDescriptionPage;
