import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { login, resetLogin } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel } from '../styled';
import { LoginForm } from '../forms';
import { WhiteCard, Title } from '../../../common/components/styled';
import { ReactComponent as LoginImage } from '../../../common/assets/icons/Login-Image.svg';
import PlatformInformationsCounter from '../../../applicationStatistics/components/PlatformInformationsCounter';
import { useHistory } from 'react-router-dom';

const LoginPage = (props) => {
  const { isLoggingIn, isLoginFailed, resetLogin, login } = props;
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const invitedUserToken = queryParams.get('redirectToAccept') || false;

  useEffect(() => () => resetLogin(), [resetLogin]);

  const onSubmit = ({ email, password }) => {
    let redirect = '/';

    if (invitedUserToken) {
      redirect = `/join?invitedUser=${invitedUserToken}`;
    }

    login(email, password, redirect, history);
  };

  return (
    <>
      <DocumentTitle>Login</DocumentTitle>
      <AccountPage>
        <AccountPanel size="small" isLoading={isLoggingIn}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              flexDirection: 'column',
            }}
          >
            <LoginImage style={{ marginBottom: '30px' }} />

            <Title
              style={{
                maxWidth: '100%',
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
              navigationTitle
            >
              Bine ai venit pe LaunchPad, locul unde ideile se transformă în
              afaceri de succes!
            </Title>
          </div>

          <PlatformInformationsCounter />

          <WhiteCard
            padding="30px"
            borderRadius="10px"
            width="560px"
            noOverflow
          >
            <LoginForm isLoginFailed={isLoginFailed} onSubmit={onSubmit} />
          </WhiteCard>
        </AccountPanel>
      </AccountPage>
    </>
  );
};

LoginPage.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  isLoginFailed: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.admin.users.users,
  isLoggingIn: state.account.login.isLoggingIn,
  isLoginFailed: state.account.login.isLoginFailed,
  login: state.account.login.login,
  resetLogin: state.account.login.resetLogin,
});

const mapDispatchToProps = {
  login,
  resetLogin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
