import React from 'react';

import UpTrendIcon from '../common/assets/icons/upTrend.svg';
import UpComingIcon from '../common/assets/icons/upComming.svg';
import SearchPersonIcon from '../common/assets/icons/searchPerson.svg';
import PaperClipIcon from '../common/assets/icons/paperClip.svg';
import FactoryIcon from '../common/assets/icons/factory.svg';
import GridBoxesIcon from '../common/assets/icons/gridBoxes.svg';
import EarthIcon from '../common/assets/icons/earthIcon.svg';
import GroupOfPeopleIcon from '../common/assets/icons/groupOfPeople.svg';
import ThermometerIcon from '../common/assets/icons/ThermometerOutline.svg';

import {
  AccountCircle,
  Description,
  Paperclip,
  Science,
  TrendingUp,
  UpcommingIcon,
  ContentPaste, Help,
  FactoryIcon as FactoryIconSVG,
} from '../common/assets/icons';

const industryTypeRivalry = 'rivalry';
const industryTypeEntryBarriers = 'entryBarriers';
const industryTypeProviders = 'providers';
const industryTypeClients = 'clients';
const industryTypeSubtitledProducts = 'subtitledProducts';

export const INDUSTRY_TYPES = [
  industryTypeRivalry,
  industryTypeEntryBarriers,
  industryTypeProviders,
  industryTypeClients,
  industryTypeSubtitledProducts,
];

export const INDUSTRY_TYPES_LABELS = {
  [industryTypeRivalry]: {
    title: 'Rivalitate',
    description:
      'Cât de intensă este rivalitatea în industrie? Care este numărul și profilul competitorilor? Adaugă informații care să te ajute sa-ti faci o idee despre intensitatea rivalitatii în industrie făcând click pe butonul „+”.',
  },
  [industryTypeEntryBarriers]: {
    title: 'Bariera de intrare',
    description:
      'Care sunt barierele de intrare pe piață pentru viitori competitori? Adaugă informații care să te ajute sa-ti faci o idee despre cât de ușor este pentru un competitor să intre în această industrie făcând click pe butonul „+”.',
  },
  [industryTypeProviders]: {
    title: 'Furnizori',
    description:
      ' Cât de mare putere de negociere și influență au furnizorii în această industrie? Care este numărul și profilul acestora? Adaugă informații care să te ajute sa-ti faci o idee despre puterea competitorilor în industrie făcând click pe butonul „+”.',
  },
  [industryTypeClients]: {
    title: 'Clienti',
    description:
      'Cât de mult influențează clienții forțele industriei? Câtă putere de negociere au când vine vorba de stabilirea prețurilor? Adaugă informații care să te ajute sa-ti faci o idee modul în care clienții influențează industria făcând click pe butonul „+”.',
  },
  [industryTypeSubtitledProducts]: {
    title: 'Produse substituibile',
    description:
      'Ce alte soluții substitute sau complementare au clienții la dispoziție? Cum este poziționată soluția ta în raport cu acestea? Adaugă informații care să te ajute sa-ti faci o idee despre soluțiile subsitute sau complementare existente făcând click pe butonul „+”.',
  },
};

const veryPositiveIndustryImpact = 'veryPositive';
const positiveIndustryImpact = 'positive';
const negativeIndustryImpact = 'negative';
const veryNegativeIndustryImpact = 'veryNegative';

export const INDUSTRY_IMPACT_OPTIONS = [
  { value: veryPositiveIndustryImpact, label: 'Foarte pozitiv ++' },
  { value: positiveIndustryImpact, label: 'Pozitiv +' },
  { value: negativeIndustryImpact, label: 'Negativ -' },
  { value: veryNegativeIndustryImpact, label: 'Foarte negativ --' },
];

export const INDUSTRY_ADORNMENTS = {
  [veryPositiveIndustryImpact]: '++',
  [positiveIndustryImpact]: '+',
  [negativeIndustryImpact]: '-',
  [veryNegativeIndustryImpact]: '--',
};

export const DETAILS_IMPACT_OPTIONS = [
  { value: 4, label: '4 - Interes mare' },
  { value: 3, label: '3 - Interes mediu' },
  { value: 2, label: '2 - Interes scăzut' },
  { value: 1, label: '1 - Interes inexistent' },
];

export const CONTEXT_ANALYSIS_TABS = [
  {
    key: 'trends',
    label: 'Trenduri',
    icon: UpTrendIcon,
    id: 'context-analysis-tab-trends',
  },
  {
    key: 'industry',
    label: 'Industrie',
    icon: FactoryIcon,
    id: 'context-analysis-tab-industry',
  },
  {
    key: 'competition',
    label: 'Competitie',
    icon: SearchPersonIcon,
    id: 'context-analysis-tab-competition',
  },
  {
    key: 'swot',
    label: 'SWOT',
    icon: GridBoxesIcon,
    id: 'context-analysis-tab-swot',
  },
  {
    key: 'details',
    label: 'Detalii',
    icon: PaperClipIcon,
    id: 'context-analysis-tab-details',
  },
  {
    key: 'conclusions',
    label: 'Concluzie',
    icon: UpComingIcon,
    id: 'context-analysis-tab-conclusions',
  },
];

export const RESOURCES_TABS = [
  {
    key: 'articles',
    label: 'Articole',
    icon: <TrendingUp />,
  },
  {
    key: 'patterns',
    label: 'Sabloane',
    icon: <FactoryIconSVG />,
  },
  {
    key: 'chapters',
    label: 'Cursuri',
    icon: <UpcommingIcon />,
  },
];

export const MY_IDEEA_TABS = [
  {
    key: 'description',
    label: 'Descriere si echipa',
    icon: GroupOfPeopleIcon,
  },
  {
    key: 'details',
    label: 'Detalii',
    icon: PaperClipIcon,
  },
  {
    key: 'mrl',
    label: 'Ghid MRL',
    icon: ThermometerIcon,
  },
  {
    key: 'profile',
    label: 'Profil Public',
    icon: EarthIcon,
  },
];

export const BOARDS_TABS = [
  {
    key: 'activities',
    label: 'Activitati',
    icon: <Science />,
  },
  {
    key: 'tasks',
    label: 'Taskuri',
    icon: <ContentPaste/>,
  },
  {
    key: 'hypothesis',
    label: 'Ipoteze',
    icon: <Help/>,
  },
  {
    key: 'details',
    label: 'Detalii',
    icon: <Paperclip />,
  },
  {
    key: 'indicators',
    label: 'Indicatori',
    icon: <TrendingUp />,
  },
  {
    key: 'conclusions',
    label: 'Concluzii',
    icon: <UpcommingIcon/>,
  },
]

export const HYPOTHESES_TABS = [
  {
    key: 'description',
    label: 'Descriere',
    icon: <Description />,
  },
  {
    key: 'profile',
    label: 'Profil',
    icon: <AccountCircle />,
  },
  {
    key: 'activities',
    label: 'Activitati',
    icon: <Science />,
  },
  {
    key: 'details',
    label: 'Detalii',
    icon: <Paperclip />,
  },
];

const swotTypeStrengths = 'strengths';
const swotTypeEntryWeaknesses = 'weaknesses';
const swotTypeOpportunities = 'opportunities';
const swotTypeThreats = 'threats';
export const SWOT_TYPES = {
  strengths: {
    value: swotTypeStrengths,
    label: 'Puncte forte',
    drawerName: 'Strength',
  },
  weaknesses: {
    value: swotTypeEntryWeaknesses,
    label: 'Puncte slabe',
    drawerName: 'Weakness',
  },
  opportunities: {
    value: swotTypeOpportunities,
    label: 'Oportunități',
    drawerName: 'Opportunity',
  },
  threats: {
    value: swotTypeThreats,
    label: 'Amenințări',
    drawerName: 'Threat',
  },
};

export const SWOT_DROPDOWN_OPTIONS = [
  { value: swotTypeStrengths, label: 'Strength' },
  { value: swotTypeEntryWeaknesses, label: 'Weaknesses' },
  { value: swotTypeOpportunities, label: 'Oportunities' },
  { value: swotTypeThreats, label: 'Threats' },
];

export const CHART_LEGEND_NUMBERS = [5, 4, 3, 2, 1, 0];
export const CHART_COLORS = [
  'rgba(52, 152, 219, 0.7)',
  'rgba(46, 204, 113, 0.7)',
  'rgba(241, 196, 15, 0.7)',
  'rgba(231, 76, 60, 0.7)',
  'rgba(155, 89, 182, 0.7)',
];

export const CHART_LINE_STROKES = ['10 10', '8 8', '6 6', '4 4', '2 2'];
