import React from 'react';
import { MrlGuideIcon, PersonSearch } from '../common/assets/icons';

export const MRL_TABS = [
  {
    key: 'mrlSteps',
    label: 'Etape ghid',
    icon: <MrlGuideIcon />,
  },
  {
    key: 'articles',
    label: 'Articole',
    icon: <PersonSearch />,
  },
  {
    key: 'chapters',
    label: 'Cursuri',
    icon: <MrlGuideIcon />,
  },
];
