import React, { useState, useRef, useCallback } from 'react';
import {
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Popover,
  Overlay,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { map, filter, size, get } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import CohortIcon from '../../../common/assets/icons/Avatar_Cohorta.svg';
import {
  DeleteCommentButton,
  Loading,
  AddCommentButton,
  CounterDiv,
  CounterText,
  Heading2,
  Heading4Regular,
  Heading3Semibold,
  Title,
  TitleCounterDiv,
  EditButton,
  EditButtonHover,
  RowDiv,
  ColDiv,
  AvatarContainer,
  DeleteButtonSmall,
} from '../../../common/components/styled';

import { ReactComponent as MoneyIcon } from '../../../common/assets/icons/money.svg';
import { ReactComponent as RoundIcon } from '../../../common/assets/icons/round.svg';
import { ReactComponent as CalendarIcon } from '../../../common/assets/icons/calendar.svg';
import { ReactComponent as PartIcon } from '../../../common/assets/icons/part.svg';

import EditDescriptionModal from '../modals/EditDescriptionModal';

import { MembersDiv } from '../styled';
import { AvatarMember, ProfileImageUploader } from '../../../common/components';
import { loadUserTeams } from '../../../common/ducks';

import {
  editTeam,
  getTeam,
  uploadTeamImage,
  removeMember,
  editEquityMember,
  leaveCohort,
  mentorLeave,
} from '../../ducks';
import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';
import {
  AddTeamDetailsModal,
  CreateTeamModal,
  JoinCohortModal,
} from '../modals';

import {
  TitleButtonsDiv,
  ImageIconRoundDiv,
} from '../../../mentors/components/styled';

import {
  CustomContainer,
  CustomParagraph,
  HeaderContainer,
  InvestitionContainer,
  InvestitionElement,
  InvestitionLabel,
  InvestitionValue,
  ProgressBarContainer,
  ProgressElement,
  TeamProfileContainer,
  WhiteCard,
  WhiteCardTitle,
} from '../styled/TeamProfile';
import { theme } from '../../../core/styles';
import { ImageIconRound } from '../../../common/components/styled/NavigationBar';
import { useHistory } from 'react-router-dom';

const TeamProfilePage = (props) => {
  const {
    isLoading,
    team,
    getTeam,
    isMentor,
    isAccelerator,
    isEditing,
    uploadTeamImage,
    removeMember,
    userId,
    editEquityMember,
    leaveCohort,
    loadUserTeams,
    mentorLeave,
    editTeam,
  } = props;

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [joinCohortModalOpen, setJoinCohortModalOpen] = useState(false);

  const [copySuccess, setCopySuccess] = useState('Copy');
  const teamCodeRef = useRef(null);

  const [show, setShow] = useState(false);

  const addUserPopoverRef = useRef(null);
  const [section, setSection] = useState(undefined);
  const [isHoveredTeam, setIsHoveredTeam] = useState(false);

  const [isHoveredInvestment, setIsHoveredInvestment] = useState(false);
  const [isEditDescriptionModalOpen, setIsEditDescriptionModalOpen] =
    useState(false);
  const [isHoveredDescription, setIsHoveredDescription] = useState(false);
  const [fundToEdit, setFundToEdit] = useState();
  const { push } = useHistory();

  const handleInviteMembers = (event) => {
    event.stopPropagation();
    setShow(!show);
  };

  const copyToClipBoard = (e) => {
    teamCodeRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => setShow(false), 1000);
  };
  const members = useCallback(
    (type) =>
      filter(
        team.members,
        (member) =>
          member.profile &&
          member.profile.userId &&
          member.profile.userId.role === type
      ),
    [team.members]
  );

  const handleHookSet = (type, section) => {
    switch (type) {
      case 'details':
        setIsDetailsModalOpen(true);
        setSection(section);
        break;

      case 'create':
        setIsCreateTeamModalOpen(true);
        break;
      case 'cohort':
        setJoinCohortModalOpen(true);
        break;

      case 'description':
        setIsEditDescriptionModalOpen(true);
        break;

      default:
        setIsCreateTeamModalOpen(false);
        setIsDetailsModalOpen(false);
    }
  };

  const handleMentorButton = () => push('/mentors');
  const handleLeaveCohort = async () => {
    await leaveCohort(team._id);
    await loadUserTeams(userId);
    await getTeam(team._id);
  };

  const onFilesSelected = (files) => {
    uploadTeamImage(team._id, files[0]);
  };

  const handleRemoveMember = async (userId) => {
    await removeMember(userId, team._id);
    await getTeam(team._id);
  };

  const handleMentorLeave = async (userId) => {
    await mentorLeave(userId, team._id);
    await loadUserTeams(userId);
  };

  const handleEditEquityMember = async (userId, values) => {
    await editEquityMember(userId, team._id, values);
    await getTeam(team._id);
  };

  const handleDeletefoundsDestination = async (item) => {
    const foundsDestination = team.foundsDestination.filter(
      (element) => element !== item
    );

    await editTeam(team._id, { ...team, foundsDestination }, userId);
  };

  return (
    <div>
      <TitleButtonsDiv marginBottom="5px">
        <Title>Ideea mea</Title>
      </TitleButtonsDiv>
      <TeamProfileContainer className="scrollbar" width="50vh">
        <CustomContainer display="flex">
          <div>
            <WhiteCard noScrollBar height="20vh">
              <CustomContainer display="flex">
                <CustomContainer width="18vh" height="10vh">
                  <ProfileImageUploader
                    upload
                    onFilesSelected={onFilesSelected}
                    imageUrl={team.logo}
                    isTeamProfile
                    disabled={isMentor || isAccelerator}
                  />
                </CustomContainer>
                <CustomContainer marginLeft="1vw">
                  <HeaderContainer width="11.5vw">
                    <WhiteCardTitle>{team.name}</WhiteCardTitle>
                    {!isMentor &&
                      (isHoveredTeam ? (
                        <EditButtonHover
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          onMouseLeave={() => setIsHoveredTeam(false)}
                          onClick={() => handleHookSet('create')}
                        />
                      ) : (
                        <EditButton
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          onMouseEnter={() => setIsHoveredTeam(true)}
                          onClick={() => handleHookSet('create')}
                        />
                      ))}
                  </HeaderContainer>
                  <CustomContainer>
                    <CustomParagraph color={theme.accentLight} lineHeight="0vh">
                      {team.domain}
                    </CustomParagraph>
                    <CustomParagraph lineHeight="0.5vh">
                      {team.dateOfEstablishment &&
                        moment(team.dateOfEstablishment).format('DD/MM/YYYY')}
                    </CustomParagraph>
                    <CustomParagraph lineHeight="2vh" marginTop="-0.5vh">
                      {team.headquarters}
                    </CustomParagraph>
                    <CustomParagraph lineHeight="0vh" marginTop="-0.5vh">
                      {team.cui}
                    </CustomParagraph>
                  </CustomContainer>
                </CustomContainer>
              </CustomContainer>
            </WhiteCard>
            <WhiteCard noScrollBar height="9vh">
              <CustomContainer
                display="flex"
                justifyContent="space-between"
                marginBottom="-1vh"
              >
                <WhiteCardTitle>MRL </WhiteCardTitle>
                <div>
                  <p>1/15</p>
                </div>
              </CustomContainer>
              <ProgressBarContainer>
                <ProgressElement width={`${(4 / 15) * 18}vw`} />
              </ProgressBarContainer>
            </WhiteCard>
          </div>

          <div>
            <WhiteCard className="scrollbar" height="30vh" width="44.5vw">
              <HeaderContainer>
                <WhiteCardTitle>Descriere</WhiteCardTitle>
                {!isMentor &&
                  (isHoveredDescription ? (
                    <EditButtonHover
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                      onMouseLeave={() => setIsHoveredDescription(false)}
                      onClick={() => handleHookSet('description')}
                    />
                  ) : (
                    <EditButton
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                      onMouseEnter={() => setIsHoveredDescription(true)}
                      onClick={() => handleHookSet('description')}
                    />
                  ))}
              </HeaderContainer>
              <p style={{ fontFamily: 'Open Sans', fontSize: '14px' }}>
                {team.description}
              </p>
            </WhiteCard>
          </div>
        </CustomContainer>

        <CustomContainer display="flex">
          <div>
            <WhiteCard className="scrollbar" height="38vh">
              <TitleButtonsDiv
                marginBottom="13px"
                style={{ position: 'relative' }}
                ref={addUserPopoverRef}
              >
                <TitleCounterDiv noMargin>
                  <Heading2 fontSize="16px">Membrii</Heading2>
                  <CounterDiv>
                    <CounterText>{size(members('FOUNDER'))}</CounterText>
                  </CounterDiv>
                </TitleCounterDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton onClick={handleInviteMembers} />
                )}
                <Overlay
                  show={show}
                  target={addUserPopoverRef.current}
                  placement="bottom"
                  container={addUserPopoverRef.current}
                >
                  <Popover
                    id="popover-basic"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Popover.Content>
                      <p>
                        Copiază și trimite codul ideii persoanelor (fondatori,
                        parteneri sau mentori) pe care dorești să le inviți în
                        echipa de lucru pentru ideea ta.
                      </p>
                      <Row>
                        <Col>
                          <div style={{ display: 'flex', maxWidth: '200px' }}>
                            <Form.Control
                              ref={teamCodeRef}
                              value={team.teamCode}
                              readOnly
                              style={{ textOverflow: 'ellipsis' }}
                            />
                            <OverlayTrigger
                              trigger={['hover', 'hover']}
                              placement="right"
                              overlay={<Tooltip>{copySuccess}</Tooltip>}
                            >
                              <Button
                                style={{ marginLeft: '5px', padding: '8px' }}
                                onClick={(e) => copyToClipBoard(e)}
                                variant="outline-primary"
                              >
                                <FontAwesomeIcon icon={faCopy} />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </Col>
                      </Row>
                    </Popover.Content>
                  </Popover>
                </Overlay>
              </TitleButtonsDiv>
              <MembersDiv>
                {map(members('FOUNDER'), (member, index) => (
                  <AvatarMember
                    user={member}
                    key={`member${index}`}
                    handleRemoveMember={handleRemoveMember}
                    userId={userId}
                    createdBy={team.createdBy}
                    handleEditEquityMember={handleEditEquityMember}
                    color="#15BB89"
                  />
                ))}
              </MembersDiv>
            </WhiteCard>
          </div>
          <div>
            <WhiteCard height="38vh">
              <TitleButtonsDiv marginBottom="13px">
                <TitleCounterDiv noMargin>
                  <Heading2 fontSize="16px">Mentori</Heading2>
                  <CounterDiv>
                    <CounterText>{size(members('MENTOR'))}</CounterText>
                  </CounterDiv>
                </TitleCounterDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton onClick={handleMentorButton} />
                )}
              </TitleButtonsDiv>
              <MembersDiv className="scrollbar">
                {map(members('MENTOR'), (mentor, index) => (
                  <AvatarMember
                    key={`mentor${index}`}
                    handleRemoveMember={handleRemoveMember}
                    handleMentorLeave={handleMentorLeave}
                    user={mentor}
                    userId={userId}
                    createdBy={team.createdBy}
                    isMentor
                    color="#FB791C"
                  />
                ))}
              </MembersDiv>
            </WhiteCard>
          </div>
          <div>
            <WhiteCard className="scrollbar" height="38vh">
              <TitleButtonsDiv marginBottom="22px">
                <Heading2 fontSize="16px">Cohorta</Heading2>
                {!isMentor && !isAccelerator && !team.cohortId && (
                  <AddCommentButton onClick={() => handleHookSet('cohort')} />
                )}
              </TitleButtonsDiv>
              <AvatarContainer>
                <RowDiv>
                  <ColDiv width="15%">
                    <ImageIconRoundDiv>
                      <ImageIconRound src={CohortIcon} />
                    </ImageIconRoundDiv>
                  </ColDiv>
                  <ColDiv width="80%">
                    <TitleButtonsDiv>
                      <Heading3Semibold>
                        {team.cohortId === null
                          ? 'Cohorta publica'
                          : `${get(team, 'cohortId.name')}`}
                      </Heading3Semibold>

                      {userId === team.createdBy && !isMentor && (
                        <DeleteButtonSmall onClick={handleLeaveCohort} />
                      )}
                    </TitleButtonsDiv>
                    <div style={{ display: 'flex', alignItems: 'center' }} />
                  </ColDiv>
                </RowDiv>
              </AvatarContainer>
            </WhiteCard>
          </div>
        </CustomContainer>
        <div>
          <CustomContainer display="flex">
            <div>
              <WhiteCard height="30vh">
                <HeaderContainer>
                  <WhiteCardTitle>Investiție</WhiteCardTitle>
                  {!isMentor &&
                    (isHoveredInvestment ? (
                      <EditButtonHover
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                        onMouseLeave={() => setIsHoveredInvestment(false)}
                        onClick={() => handleHookSet('details', 'investment')}
                      />
                    ) : (
                      <EditButton
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                        onMouseEnter={() => setIsHoveredInvestment(true)}
                        onClick={() => handleHookSet('create')}
                      />
                    ))}
                </HeaderContainer>
                <div>
                  <InvestitionElement>
                    <MoneyIcon />{' '}
                    <InvestitionLabel>Investiție căutată:</InvestitionLabel>
                    <InvestitionValue>
                      {team.investmentSought
                        ? `$${team.investmentSought}`
                        : 'Suma'}
                    </InvestitionValue>
                  </InvestitionElement>
                  <InvestitionElement>
                    <PartIcon />
                    <InvestitionLabel>
                      Părți sociale/acțiuni oferite:
                    </InvestitionLabel>
                    <InvestitionValue>
                      {team.equity ? `${team.equity}%` : 'Procent'}
                    </InvestitionValue>
                  </InvestitionElement>
                  <InvestitionElement>
                    <RoundIcon />
                    <InvestitionLabel>Rundă finanțare:</InvestitionLabel>
                    <InvestitionValue>
                      ​{team.round ? team.round : 'Numarul rundei'}
                    </InvestitionValue>
                  </InvestitionElement>
                  <InvestitionElement>
                    <CalendarIcon />
                    <InvestitionLabel>Termen:</InvestitionLabel>
                    <InvestitionValue>
                      {team.deadline
                        ? moment(team.deadline).format('DD/MM/YYYY')
                        : 'zz/ll/aaaa'}
                    </InvestitionValue>
                  </InvestitionElement>
                </div>
              </WhiteCard>
            </div>

            <WhiteCard
              height="30vh"
              width="44.5vw"
              minWidth="44.5vw"
              className="scrollbar"
            >
              <TitleButtonsDiv marginBottom="13px">
                <TitleCounterDiv noMargin>
                  <Heading2 fontSize="16px">Destinația fondurilor</Heading2>
                </TitleCounterDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton
                    onClick={() => handleHookSet('details', 'funds')}
                  />
                )}
              </TitleButtonsDiv>
              {map(team.foundsDestination, (elem, index) => (
                <InvestitionContainer>
                  <Heading4Regular key={index}>
                    <strong>{index + 1} </strong>
                    {elem}
                  </Heading4Regular>
                  <div>
                    <DeleteCommentButton
                      commentComponent
                      marginRight="5px"
                      onClick={() => {
                        handleDeletefoundsDestination(elem);
                      }}
                    />
                    {!isMentor &&
                      (isHoveredTeam ? (
                        <EditButtonHover
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          onMouseLeave={() => setIsHoveredTeam(false)}
                          onClick={() => {
                            setFundToEdit(index);
                            handleHookSet('details', 'edit_fund');
                          }}
                        />
                      ) : (
                        <EditButton
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          onMouseEnter={() => {
                            setFundToEdit(index);
                            setIsHoveredTeam(true);
                          }}
                          onClick={() => handleHookSet('details', 'edit_fund')}
                        />
                      ))}
                  </div>
                </InvestitionContainer>
              ))}
            </WhiteCard>
          </CustomContainer>
        </div>
      </TeamProfileContainer>
      <AddTeamDetailsModal
        isModalOpen={isDetailsModalOpen}
        setIsModalOpen={setIsDetailsModalOpen}
        section={section}
        valueToEdit={fundToEdit}
        setSection={setSection}
      />

      <CreateTeamModal
        isModalOpen={isCreateTeamModalOpen}
        setIsModalOpen={setIsCreateTeamModalOpen}
        isLoading={isEditing}
        isEditMode
        team={team}
      />
      <JoinCohortModal
        isModalOpen={joinCohortModalOpen}
        setIsModalOpen={setJoinCohortModalOpen}
        isLoading={isEditing}
        userId={userId}
      />

      <EditDescriptionModal
        isModalOpen={isEditDescriptionModalOpen}
        setIsModalOpen={setIsEditDescriptionModalOpen}
        isLoading={isEditing}
        team={team}
        isEditMode
        userId={userId}
      />
      <Loading isLoading={isEditing || isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.team.team,
  isLoading: state.team.isLoading,
  isEditing: state.team.isEditing,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  userId: get(state.account.login, 'user._id'),
});

const mapDispatchToProps = {
  editTeam,
  getTeam,
  uploadTeamImage,
  removeMember,
  editEquityMember,
  leaveCohort,
  loadUserTeams,
  mentorLeave,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamProfilePage)
);
