import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  editHypothesis,
  addHypothesis,
  addClientProfileCharacteristics,
} from '../../ducks';

import AddCharacteristicsForm from '../forms/AddCharacteristicsForm';
import ModalComponent from '../../../common/components/ModalComponent';

const AddCharacteristicsModal = (props) => {
  const {
    isModalOpen,
    isEditMode,
    type,
    entityId,
    setIsModalOpen,
    hypothesis,
    addClientProfileCharacteristics,
  } = props;

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const onSubmit = (values) => {
    const newValues = {
      type,
      description: values.description,
    };

    addClientProfileCharacteristics(newValues, entityId);
    handleModalClose();
  };

  return (
    <ModalComponent
      open={isModalOpen}
      title={isEditMode ? 'Editeaza caracteristica' : 'Adauga o caracteristica'}
      handleClose={handleModalClose}
    >
      <AddCharacteristicsForm
        onSubmit={onSubmit}
        isEditMode={isEditMode}
        hypothesis={hypothesis}
      />
    </ModalComponent>
  );
};

const mapDispatchToProps = {
  addHypothesis,
  editHypothesis,
  addClientProfileCharacteristics,
};

export default withRouter(
  connect(null, mapDispatchToProps)(AddCharacteristicsModal)
);
