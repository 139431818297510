import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
} from '@mui/material';
import {
  AccordionExpandIcon,
  AddIcon,
  DeleteIcon,
  DragIndicator,
  EditIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import Subchapter from './Subchapter';
import { useApi } from '../../hooks/useApi';
import { deleteChapterById } from '../../requests/chapters';
import { useHistory } from 'react-router';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import GenericDeleteModal from '../../common/components/GenericDeleteModal';

const ChapterAccordion = ({
  title = '',
  subchapters = [],
  createNewSubchapter,
  chapterId,
  order,
  fetchChapters,
  chaptersProvided,
  isVisible,
  index,
  isDragDiabled,
}) => {
  const history = useHistory();

  const [isExpanded, setExpanded] = useState(false);
  const [isDeleteChapterModalOpen, setIsDeleteChapterModalOpen] =
    useState(false);
  const [chapterIdToDelete, setChapterIdToDelete] = useState(null);
  const chapterColor = isVisible ? '#74A3FE' : '#DEE7FC';

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const editChapter = (chapterId, order) => {
    history.push(
      `/admin/mrl/course/form?chapterId=${chapterId}&order=${order}&isEdit=true`
    );
  };

  const [, deleteChapter] = useApi(deleteChapterById);

  const handleDeleteChapter = (event, chapterId) => {
    event.stopPropagation();
    setIsDeleteChapterModalOpen(true);
    setChapterIdToDelete(chapterId);
  };

  const onDeleteChapter = async () => {
    const payload = {
      params: { chapterId: chapterIdToDelete },
    };

    await deleteChapter(payload);
    await fetchChapters();
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      sx={{
        border: `1px solid ${chapterColor}`,
        // width: '70%',
        minHeight: '64px',
        borderRadius: '6px',
        padding: '0 20px',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<AccordionExpandIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '64px',
        }}
        {...chaptersProvided.dragHandleProps}
      >
        <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          {!isDragDiabled && <DragIndicator fill={chapterColor} />}
          <P faded={!isVisible}>{title}</P>
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto', mr: '10px' }}>
          <IconButton onClick={() => editChapter(chapterId, order)}>
            <EditIcon width="16" height="18" />
          </IconButton>
          <IconButton
            onClick={(event) => handleDeleteChapter(event, chapterId)}
          >
            <DeleteIcon width="16" height="18" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <Droppable droppableId={`subchapters-${chapterId}`} type="SUBCHAPTER">
        {(subchaptersProvided) => (
          <AccordionDetails
            sx={{
              borderTop: '1px solid #DEE7FC',
              padding: '20px 10px',
            }}
            ref={subchaptersProvided.innerRef}
            {...subchaptersProvided.droppableProps}
          >
            <H3>Subcapitole</H3>
            <Box sx={{ marginTop: '10px' }}>
              {!subchapters.length ? (
                <P faded>
                  Adauga un subcapitol facand click pe butonul de mai jos...
                </P>
              ) : (
                subchapters.map((subchapter, index) => (
                  <Draggable
                    key={subchapter._id}
                    draggableId={subchapter._id}
                    index={index}
                  >
                    {(subchaptersProvided) => (
                      <Box
                        ref={subchaptersProvided.innerRef}
                        {...subchaptersProvided.draggableProps}
                        {...subchaptersProvided.dragHandleProps}
                        id={subchapter._id}
                      >
                        <Subchapter
                          key={index}
                          data={subchapter}
                          editChapter={() =>
                            editChapter(subchapter._id, subchapter.order)
                          }
                          deleteChapter={(event) =>
                            handleDeleteChapter(event, subchapter._id)
                          }
                        />
                      </Box>
                    )}
                  </Draggable>
                ))
              )}
              {subchaptersProvided.placeholder}
            </Box>
            <Box sx={{ marginTop: '15px' }}>
              <PrimaryButton onClick={createNewSubchapter} icon={AddIcon}>
                Adauga un subcapitol
              </PrimaryButton>
            </Box>
          </AccordionDetails>
        )}
      </Droppable>
      <GenericDeleteModal
        isModalOpen={isDeleteChapterModalOpen}
        handleClose={() => setIsDeleteChapterModalOpen(false)}
        title="Confirmare stergere"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '25px',
          }}
        >
          <P>Sunteti sigur ca doriti stergerea capitolului?</P>
          <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
            <PrimaryButton
              onClick={() => setIsDeleteChapterModalOpen(false)}
              inverted
              marginRight="10px"
            >
              Renunta
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                onDeleteChapter();
                setIsDeleteChapterModalOpen(false);
                setChapterIdToDelete(null);
              }}
              icon={TrashIconOutlined}
              inverted
            >
              Sterge
            </SecondaryButton>
          </Box>
        </Box>
      </GenericDeleteModal>
    </Accordion>
  );
};

export default ChapterAccordion;
