import styled, { css } from 'styled-components';

export const IRLContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 18px 20px 15px;
  width: 100%;
  max-height: 98px;
  height: 100%;
`;

export const IRLTitleRow = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

export const IRLRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IRLBubble = styled.div`
  font-size: 14px;
  line-height: 16px;
  border-radius: 50%;
  color: #fff;
  background-color: ${(props) => props.theme.borderGray};
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  max-width: 30px;
  max-height: 30px;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    border-bottom: 2px solid #cccccc;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    height: 9px;
    position: absolute;
    width: 58px;
    left: ${(props) => (props.index >= 10 ? '30px' : '28px')};
  }

  &:last-of-type {
    &::after {
      width: 0;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(innerProps) => innerProps.theme.success};
    `}
`;
