import React from 'react';
import * as Yup from 'yup';
import { isObject } from 'lodash-es';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';

const AddCharacteristicsForm = (props) => {
  const { onSubmit, isEditMode, isEdit } = props;

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    description: Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    description: '',
  };

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomTextField
            id="description"
            label="Descriere"
            multiline
            rows={3}
            autoFocus
          />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {isEdit ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  defaultColumnBMC:
    isObject(state.common.modal.props) &&
    state.common.modal.props.initialValues.columnBMC,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCharacteristicsForm);
