const resizeElement = (id, cssProperty, conditions, values) => {
  const currentElement = document.getElementById(id);
  const imageContainerElement = document.getElementById('middle-container');

  if (currentElement && imageContainerElement) {
    const { top, bottom } = currentElement.getBoundingClientRect();
    const imgTop = imageContainerElement.getBoundingClientRect().top;
    const imgBottom = imageContainerElement.getBoundingClientRect().bottom;

    let propertyValue = values[values.length - 1]; // Default to the last value in the array

    conditions.forEach((condition, index) => {
      if (condition(top, bottom, imgTop, imgBottom)) {
        propertyValue = values[index];
      }
    });

    currentElement.style[cssProperty] = propertyValue;
  }
};

const checkResizeUnified = (id, cssProperty, conditions, values) => {
  const container = document.getElementById(id);
  if (!container) return;

  for (const element of container.querySelectorAll(`.element-${id}`)) {
    resizeElement(element.id, cssProperty, conditions, values);
  }
};

const checkResizeWho = (id) => {
  const conditions = [
    (top, _1, imgTop, _2) => top > imgTop,
    (top, bottom, imgTop, _) => top < imgTop && bottom > imgTop,
  ];
  checkResizeUnified(id, 'width', conditions, [
    'calc(100% - 120px)',
    'calc(100% - 85px)',
    '100%',
  ]);
};

const checkResizeTask = (id) => {
  const conditions = [
    (top, _1, imgTop, _2) => top > imgTop,
    (top, bottom, imgTop, _) => top < imgTop && bottom > imgTop,
  ];
  checkResizeUnified(id, 'marginLeft', conditions, ['120px', '85px', '0vw']);
};

const checkResizePain = (id) => {
  const conditions = [
    (top, bottom, _, imgBottom) => bottom < imgBottom,
    (top, bottom, _, imgBottom) => top < imgBottom && bottom > imgBottom,
  ];
  const values = ['calc(100% - 120px)', 'calc(100% - 120px)', '100%'];
  checkResizeUnified(id, 'width', conditions, values);
};

const checkResizeWin = (id) => {
  const conditions = [
    (top, bottom, _, imgBottom) => bottom < imgBottom,
    (top, bottom, _, imgBottom) => top < imgBottom && bottom > imgBottom,
  ];
  const values = ['120px', '120px', '0vw'];
  checkResizeUnified(id, 'marginLeft', conditions, values);
};

export { checkResizeWho, checkResizeTask, checkResizePain, checkResizeWin };
