import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypotheses } from '../../hypotheses/ducks';
import { Resolver } from '../../core/components';
import { Loading } from '../../common/components/styled';
import { DocumentTitle } from '../../common/components';
import { currentTeamIdSelector } from '../../common/ducks';
import KPIPage from './KPIPage';

const KPIPageResolver = (props) => {
  const { loadHypotheses, teamId } = props;

  const loadDependencies = () =>
    teamId ? Promise.all([loadHypotheses(teamId)]) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Indicatori</DocumentTitle>
      <Resolver
        successComponent={KPIPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  loadHypotheses,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KPIPageResolver)
);
