import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  findAccountConfirmationToken,
  resendConfirmationToken,
} from '../../ducks';
import { AccountPage } from '../styled';
import { SubmitButton, WhiteCard } from '../../../common/components/styled';
import { MarginContainer } from '../../../mrl/components/styled/MrlStyled';
import { useHistory } from 'react-router-dom';

function ConfirmAccountPage(props) {
  const {
    tokenJson,
    findAccountConfirmationToken,
    resendConfirmationToken,
    match: {
      params: { token },
    },
  } = props;
  const history = useHistory();

  useEffect(() => {
    findAccountConfirmationToken(token);
  }, []);

  const resendTokenHandler = () => {
    resendConfirmationToken(token);
    history.push('/');
  };

  const renderNotFound = () => (
    <MarginContainer>
      Ne pare rau, cererea ta de confirmare a contului nu mai este valabila.
      Pentru a retrimite email-ul de confirmare, apasa pe butonul de mai jos
      <MarginContainer display="flex" justifyContent="center" marginTop="1vh">
        <SubmitButton onClick={resendTokenHandler}>
          Retrimite email
        </SubmitButton>
      </MarginContainer>
    </MarginContainer>
  );

  const renderAlreadyConfirmed = () => (
    <MarginContainer>
      Contul tau a fost deja activat!
      <MarginContainer display="flex" justifyContent="center" marginTop="1vh">
        <SubmitButton
          onClick={() => {
            history.push('/');
          }}
        >
          Spre pagina de login!
        </SubmitButton>
      </MarginContainer>
    </MarginContainer>
  );

  const renderSuccess = () => (
    <MarginContainer>
      Contul tau a fost activat cu success!
      <MarginContainer display="flex" justifyContent="center" marginTop="1vh">
        <SubmitButton
          onClick={() => {
            history.push('/');
          }}
        >
          Spre pagina de login!
        </SubmitButton>
      </MarginContainer>
    </MarginContainer>
  );

  return (
    <>
      <AccountPage>
        <WhiteCard noOverflow padding="30px" borderRadius="10px">
          {tokenJson.token === 'NOT_FOUND'
            ? renderNotFound()
            : tokenJson.token === 'ALREADY_CONFIRMED'
            ? renderAlreadyConfirmed()
            : renderSuccess()}
        </WhiteCard>
      </AccountPage>
    </>
  );
}

const mapStateToProps = (state) => ({
  tokenJson: state.account.accountConfirmation,
});

const mapDispatchToProps = {
  findAccountConfirmationToken,
  resendConfirmationToken,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountPage)
);
