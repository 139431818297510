import { useField } from 'formik';
import { Box, Button, Grid } from '@mui/material';
import React from 'react';

const RenderOptionsGroupCustomButton = ({
  active,
  customStyles = {},
  label,
  ...props
}) => {
  const styles = {
    borderRadius: '4px',
    background: active ? 'rgba(116, 163, 254, 0.2)' : 'transparent',
    width: '42px',
    height: '42px',
    border: '1px solid #74A3FE',
    margin: '0 5px',
    ...customStyles,
  };

  return (
    <Button style={styles} {...props}>
      {label}
    </Button>
  );
};

const CustomMultipleOptionsGroup = ({ id, label, customStyles, md = 12 }) => {
  const [field, meta] = useField(id);
  const { value, onChange } = field;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Grid item xs={12} md={md}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #74A3FE',
          color: '#74A3FE',
          borderRadius: '4px',
          padding: '18.5px 14px',
          marginBottom: '15px',
          ...customStyles,
        }}
      >
        <div>{label}</div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RenderOptionsGroupCustomButton
            active={value === 'high'}
            onClick={() =>
              handleChange({ target: { name: id, value: 'high' } })
            }
            customStyles={{
              minWidth: '60px',
              margin: '0 0 0 5px',
              border: 'none',
            }}
            label="Mare"
          />
          <RenderOptionsGroupCustomButton
            active={value === 'medium'}
            onClick={() =>
              handleChange({ target: { name: id, value: 'medium' } })
            }
            customStyles={{
              minWidth: '60px',
              margin: '0 0 0 5px',
              border: 'none',
            }}
            label="Medie"
          />
          <RenderOptionsGroupCustomButton
            active={value === 'low'}
            onClick={() => handleChange({ target: { name: id, value: 'low' } })}
            customStyles={{
              minWidth: '60px',
              margin: '0 0 0 5px',
              border: 'none',
            }}
            label="Mica"
          />
        </Box>
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </Box>
    </Grid>
  );
};

export default CustomMultipleOptionsGroup;
