import { Box } from '@mui/material';
import React from 'react';
import { PillQuestionAnswer } from './styledComponents/registrationForm';

const RegistrationPillQuestion = ({
  currentStepQuestionData,
  handleClickAnswer,
  selectedAnswers,
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '16px',
    }}
  >
    {currentStepQuestionData.answerOptions.map((option) => (
      <PillQuestionAnswer
        selected={selectedAnswers.includes(option.value)}
        onClick={() => handleClickAnswer(option.value, option?.onSelectMessage)}
        key={option.value}
      >
        {option?.label || option?.value}
      </PillQuestionAnswer>
    ))}
  </Box>
);

export default RegistrationPillQuestion;
