const experimentTypeClientProfile = 'clientProfile';
const experimentTypeSignificantProblems = 'significantProblems';
const experimentTypeValueProposition = 'valueProposition';
const experimentTypeMinValuableSolution = 'minValuableSolution';
const experimentTypeMinSourceOfIncome = 'sourceOfIncome';
const experimentTypeCustomerAcquisitionChannels = 'customerAcquisitionChannels';
const experimentTypeBusinessModel = 'businessModel';

const trustDataSmall = 'small';
const trustDataMedium = 'medium';
const trustDataHigh = 'high';

export const TASK_STATUSESS = {
  done: 'done',
  inProgress: 'inProgress',
  inPreparation: 'inPreparation',
};

export const statusNegative = 'negative';
export const statusPositive = 'positive';

export const ACTIVITY_CONCLUSION_TRUST_OPTIONS = [
  {
    value: trustDataSmall,
    label: 'Mica',
  },
  {
    value: trustDataMedium,
    label: 'Medie',
  },
  {
    value: trustDataHigh,
    label: 'Mare',
  },
];

export const ACTIVITY_CONCLUSION_SEGMENT_OPTIONS = [
  {
    value: experimentTypeClientProfile,
    label: 'Profilul clientului',
  },
  {
    value: experimentTypeSignificantProblems,
    label: 'Problema semnificativa',
  },
  {
    value: experimentTypeValueProposition,
    label: 'Pozitia de valoare',
  },
  {
    value: experimentTypeMinValuableSolution,
    label: 'Minimum Viable Solution',
  },
  {
    value: experimentTypeMinSourceOfIncome,
    label: 'Surse de venituri',
  },
  {
    value: experimentTypeCustomerAcquisitionChannels,
    label: 'Canale de achizitie clienti',
  },
  {
    value: experimentTypeBusinessModel,
    label: 'Modelul de business',
  },
];

export const TASK_STATUS_OPTIONS = [
  { value: 'inPreparation', label: 'În pregătire' },
  { value: 'inProgress', label: 'În implementare' },
  { value: 'done', label: 'Finalizat' },
];

export const TASK_STATUS_COLOR = {
  inPreparation: 'red',
  inProgress: 'orange',
  done: 'green',
};
