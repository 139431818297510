import { runDeleteApiRequest, runGetApiRequest } from '../api/Api';
import {
  DELETE_TREND_BY_ID,
  GET_ALL_TRENDS,
} from '../common/constants/apiRoutes';

export function getAllTrends({ payload = {} }) {
  return runGetApiRequest({
    endpoint: GET_ALL_TRENDS,
    params: payload.params,
    queryParams: payload.queryParams,
    onFailMessage: 'Something went wrong while fetching trends',
  });
}

export function deleteTrendById({ payload = {} }) {
  return runDeleteApiRequest({
    endpoint: DELETE_TREND_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while deleting trend',
  });
}
