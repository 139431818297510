import styled from 'styled-components';
import { Nav } from 'react-bootstrap';

export const StyledNavLink = styled(Nav.Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 20px;

  background-color: ${(props) =>
    props['data-isactive'] ? 'white !important' : '#EFF3FE'};
  border-color: #74a3fe !important;
  border-width: ${(props) =>
    props['data-isactive'] ? '1px 1px 1px 1px !important' : '0!important'};
  border-bottom-color: ${(props) =>
    props['data-isactive'] ? 'white !important' : '#74a3fe !important'};

  border-radius: ${(props) => {
    if (props['data-isactive']) {
      return '10px 10px 0px 0px !important';
    }
    if (props['data-isfirst']) {
      return '10px 0px 0px 0px !important';
    }
    if (props['data-islast']) {
      return '0px 10px 0px 0px !important';
    }
    return '0 !important';
  }};

  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #2a5ab7 !important;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    z-index: 1;
  }

  & div {
    opacity: ${(props) => (props['data-isactive'] ? '1 !important' : '0.5')};
  }

  &:hover {
    background-color: #dee7fc;
  }
`;

export const StyledProfileNav = styled(Nav)`
  border-bottom: none !important;
  z-index: 1;
  background-color: #eff3fe;
  border-radius: 10px;
`;

export const ProfilePageContainer = styled.div`
  border: 1px solid #74a3fe;
  border-radius: ${(props) => (props.fullBorder ? '10px' : '0 10px 10px 10px')};
  max-width: 100%;
  height: ${(props) => props.height || '84.5vh'};
`;
