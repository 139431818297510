import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ProfileForm } from '../forms';
import { loadProfile, editProfile } from '../../ducks';

import ReusableModal from '../../../common/components/ModalComponent';

const ProfileModal = (props) => {
  const { loadProfile, isModalOpen, setIsModalOpen, editProfile, userProfile } =
    props;
  const onSubmit = async (values) => {
    const auxValues = { ...values };
    delete auxValues.photo;
    await editProfile(auxValues, values.photo);
    setIsModalOpen(false);
    if (values.photo) {
      window.location.reload();
    }
    await loadProfile();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ReusableModal
      title="Profilul meu"
      open={isModalOpen}
      handleClose={handleCloseModal}
    >
      <ProfileForm onSubmit={onSubmit} userProfile={userProfile} />
    </ReusableModal>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.userProfile.isEditing,
  userProfile: state.userProfile.userProfile,
});

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileModal)
);
