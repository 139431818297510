import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

// Styled
import { Switch } from '@mui/material';
import {
  SubscriptionBox,
  StyledTd,
  TitleTd,
  SubscriptionsTableContainer,
  SubscriptionButton,
} from './styled/SubscriptionProfileStyle';
import {
  Header2Paragraph,
  Header3Paragraph,
  P,
} from '../../common/components/styled/ReusableTextComponents';

// Constants and utils
import { useApi } from '../../hooks/useApi';
import {
  startCheckoutSession,
  cancelUserSubscription,
  updateUserSubscription,
} from '../../requests/payments';

const SubscriptionProfile = ({
  subscriptionProducts = {},
  nextPaymentData = {},
}) => {
  const [isYearlyActive, setIsYearlyActive] = useState(true);
  const activeSubscriptionChoices =
    subscriptionProducts[isYearlyActive ? 'yearly' : 'monthly'] || [];
  const [, runCheckoutSession] = useApi(startCheckoutSession);
  const [, runCancelSubscription] = useApi(cancelUserSubscription);
  const [, runUpdateSubscription] = useApi(updateUserSubscription);
  const currentSubscription =
    useSelector(
      (state) => state?.userProfile?.userProfile?.userId?.subscription
    ) || {};
  const currentSubscriptionPrice =
    (currentSubscription?.amountDecimal || 0) / 100;

  const handleToggle = () => {
    setIsYearlyActive(!isYearlyActive);
  };

  const handleUpgrade = async (item) => {
    let redirect;

    if (nextPaymentData) {
      await runUpdateSubscription({
        data: { planId: item.priceId },
      });
    } else {
      const checkoutSessionResponse = await runCheckoutSession({
        data: { planId: item.priceId },
      });

      const { redirectUrL } = checkoutSessionResponse;
      redirect = redirectUrL;
    }

    if (!redirect) {
      return;
    }

    window.location.href = redirect;
  };

  const handleCancelSubscription = async () => {
    if (!currentSubscriptionPrice) {
      return;
    }

    await runCancelSubscription();
    window.location.reload();
  };

  return (
    <SubscriptionsTableContainer className="scrollbar">
      <Table>
        <thead>
          <tr>
            <th>
              <Header2Paragraph>Modalitati de abonament</Header2Paragraph>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <P faded={!isYearlyActive}>Anual</P>
                <Switch checked={!isYearlyActive} onClick={handleToggle} />
                <P faded={isYearlyActive}>Lunar</P>
              </div>
            </th>
            <th>
              <SubscriptionBox>
                <P>1. Explorator</P>
                <P>Gratis</P>
                <SubscriptionButton onClick={handleCancelSubscription}>
                  {currentSubscriptionPrice
                    ? 'Anuleaza abonament'
                    : 'Plan curent'}
                </SubscriptionButton>
              </SubscriptionBox>
            </th>

            {activeSubscriptionChoices.map((product, index) => (
              <th
                key={`${product.name}-${index}`}
                onClick={() => handleUpgrade(product)}
              >
                <SubscriptionBox>
                  <P>
                    {`${index + 2}.`} {product.name}
                  </P>
                  <P>
                    {product.price} lei / {product.recurrence}
                  </P>
                  <SubscriptionButton
                    disabled={currentSubscriptionPrice === product.price}
                  >
                    {currentSubscriptionPrice === product.price
                      ? 'Plan curent'
                      : 'Upgrade'}
                  </SubscriptionButton>
                </SubscriptionBox>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <Header2Paragraph style={{ marginTop: '80px' }}>
              Idei și echipa
            </Header2Paragraph>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Idei</Header3Paragraph>
              <P>Numărul maxim de idei la care poți să lucrezi.</P>
            </TitleTd>
            <StyledTd>
              <P>1 idee</P>
            </StyledTd>
            <StyledTd>
              <P>Nelimitat</P>
            </StyledTd>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Colaborare</Header3Paragraph>
              <P>
                Numărul maxim de co-echipieri cu care poți să lucrezi pe o idee.
              </P>
            </TitleTd>
            <StyledTd>
              <P>1 membru / idee</P>
            </StyledTd>
            <StyledTd>
              <P>6 membrii / idee</P>
            </StyledTd>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Mentorat</Header3Paragraph>
              <P>
                Numărul maxim de mentori pe care poți să-i inviți să se alăture
                echipei tale.
              </P>
            </TitleTd>
            <StyledTd />
            <StyledTd>
              <P>3 mentor / idee</P>
            </StyledTd>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>
                Participare in programe de accelerare
              </Header3Paragraph>
              <P>
                Numărul maxim al programelor de incubare sau accelerare la care
                te poți înscrie simultan cu una sau mai multe idei.
              </P>
            </TitleTd>
            <StyledTd />
            <StyledTd>
              <P>1 program</P>
            </StyledTd>
          </tr>
          <tr>
            <Header2Paragraph style={{ marginTop: '80px' }}>
              Instumente de bază
            </Header2Paragraph>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Business Model Canvas© </Header3Paragraph>
              <P>
                Captează ipotezele cele mai importante și relevante legate de
                ideea ta. Vezi legăturile dintre ipoteze, importanța acestora
                precum și stadiul lor. Stabilește experimente, adăuga detalii și
                determină care sunt următorii pașii.
              </P>
            </TitleTd>
            <StyledTd>
              <P>1 idee</P>
            </StyledTd>
            <StyledTd>
              <P>Nelimitat</P>
            </StyledTd>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Analiza contextului </Header3Paragraph>
              <P>
                Numărul maxim de co-echipieri cu care poți să lucrezi pe o idee.
              </P>
            </TitleTd>
            <StyledTd>
              <P>1 membru / idee</P>
            </StyledTd>
            <StyledTd>
              <P>6 membrii / idee</P>
            </StyledTd>
          </tr>
          <tr>
            <TitleTd>
              <Header3Paragraph>Mentorat</Header3Paragraph>
              <P>
                Numărul maxim de mentori pe care poți să-i inviți să se alăture
                echipei tale.
              </P>
            </TitleTd>
            <StyledTd />
            <StyledTd>
              <P>3 mentori / idee</P>
            </StyledTd>
          </tr>
        </tbody>
      </Table>
    </SubscriptionsTableContainer>
  );
};

const mapStateToProps = (state) => ({
  subscriptionProducts: state.userProfile.allSubscriptionProducts,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionProfile);
