import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import { DeleteButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import { formatDate } from '../../common/functions/dateHelpers';
import {
  activityTypeOptions,
  categoryOptions,
  problemSeverityOptions,
} from '../../opportunityAnalysis/components/forms/DetailsForm';
import { deleteDetailById, getAllDetails } from '../../requests/details';

const DetailsComponent = () => {

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: details = [] }, fetchDetails] = useApi(getAllDetails);

  const [, deleteDetail] = useApi(deleteDetailById);

  const handleToggleAddHypothesisModal = () => {};
  const handleSelectedActivity = () => {};

  const handleFetchDetails = () => {
    const payload = {
      params: { teamId },
    };
    fetchDetails(payload);
  };

  const handleDeleteDetail = async (id) => {
    const payload = {
      params: { detailId: id },
    };
    await deleteDetail(payload);
    await handleFetchDetails();
  };

  const searchLabel = (value, collection) =>
    collection.find((item) => item.value === value)?.label || '-';

  const determineLevelOfInterest = (activityType, levelOfInterest) =>
    levelOfInterest || '-';

  const determineContactDisponibility = (
    activityType,
    contactDisponibility
  ) => {
    if (!contactDisponibility) {
      return '-';
    }
    return contactDisponibility === true ? 'Da' : 'Nu';
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: 'Denumire', key: 'description' },
    { type: 'string', content: 'Activitate', key: 'activity' },
    { type: 'string', content: 'Categorie', key: 'category' },
    { type: 'string', content: 'Severitate', key: 'problemSeverity' },
    { type: 'string', content: 'Cumparare', key: 'levelOfInterest' },
    { type: 'string', content: 'Contact', key: 'contactDisponibility' },
    { type: 'string', content: 'Data', key: 'createdAt' },
    // { type: 'string', content: 'BMC', key: 'columnBMC' },
    // { type: 'string', content: 'Importanta', key: 'importance' },
    // { type: 'string', content: 'Cost', key: 'cost' }, ??????
    // {
    //   type: 'component',
    //   content: <Box>icon</Box>,
    //   hideSortIcon: true,
    //   key: 'ceva'
    // },
    // { type: 'string', content: 'Concluzie', key: 'conclusion' },
    ...(details.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    details.map(
      (
        {
          _id,
          description,
          activityType,
          problemSeverity,
          category,
          createdAt,
          levelOfInterest,
          contactDisponibility,
          userId,
          section,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        description,
        activityType: searchLabel(activityType, activityTypeOptions),
        category: searchLabel(category, categoryOptions),
        problemSeverity: problemSeverity
          ? searchLabel(+problemSeverity, problemSeverityOptions)
          : '-',
        levelOfInterest: determineLevelOfInterest(
          activityType,
          levelOfInterest
        ),
        contactDisponibility: determineContactDisponibility(
          activityType,
          contactDisponibility
        ),
        createdAt: formatDate(createdAt),
        // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
        action: (
          <DeleteButtonComponent
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteDetail(_id);
            }}
          />
        ),
      })
    );

  useEffect(() => {
    if (teamId) {
      handleFetchDetails();
    }
  }, []);
  return (
    <PageContainer className="scrollbar">
      {details && !!details.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            // handleSelectedRow={handleSelectedActivity}
          />
        </Grid>
      ) : (
        <GenericEmptyTable
          textColor="#74A3FE"
          headers={createTableHeaderCells()}
          detailMessage="Niciun detaliu nu a fost adaugat..."
        />
      )}
    </PageContainer>
  );
};

export default DetailsComponent;
