import { Accordion, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  P,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import {
  AddIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '../../../common/assets/icons';
import React, { useState } from 'react';
import { SpaceBetweenBox } from '../../../common/components/styled/ReusablePositioningComponents';
import RichTextContainer from '../../../common/components/RichTextContainer';
import { statusPositive } from '../../constants/activities';

const DrawerConclusions = ({
  setIsAddConclusionModalOpen,
  conclusions = [],
}) => {
  const [accordionOpen, setAccordionOpen] = useState(true);

  const [renderedConclusions, setRenderedConclusions] = useState(
    conclusions.map((el) => ({ ...el, opened: false }))
  );

  const toggleTasksAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  const openConcluionModal = () => {
    setIsAddConclusionModalOpen(true);
  };

  const toggleConclusionAcordeon = (conclusionId) => {
    const newState = [...renderedConclusions];
    const targetedConc = newState.find((el) => el._id === conclusionId);
    targetedConc.opened = !targetedConc.opened;

    setRenderedConclusions(newState);
  };

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Accordion
        expanded={accordionOpen}
        sx={{
          boxShadow: 'none',
          '&:before': { height: '0px' },
        }}
      >
        <Box sx={{ marginTop: '20px' }}>
          <Box
            onClick={toggleTasksAccordion}
            sx={{ display: 'flex', cursor: 'pointer' }}
          >
            {accordionOpen ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
            <P marginLeft="10px" marginBottom="10px">
              Concluzie
            </P>
          </Box>
        </Box>

        {renderedConclusions.length > 0 &&
          renderedConclusions.map((conclusion) => (
            <Accordion
              key={conclusion._id}
              expanded={conclusion.opened}
              sx={{
                boxShadow: 'none',
                '&:before': { height: '0px' },
              }}
            >
              <SpaceBetweenBox sx={{ marginBottom: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ marginRight: '10px' }}>
                    {conclusion?.status === statusPositive ? (
                      <ThumbsUpIcon fill="#15BB89" />
                    ) : (
                      <ThumbsDownIcon fill="red" />
                    )}
                  </Box>
                  <RichTextContainer text={conclusion.title} />
                </Box>
                <Box
                  onClick={() => toggleConclusionAcordeon(conclusion._id)}
                  sx={{ cursor: 'pointer' }}
                >
                  {conclusion.opened ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </Box>
              </SpaceBetweenBox>

              <Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <P marginRight="5px">Sumar concluzie:</P>
                    <RichTextContainer text={conclusion.title} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <P marginRight="5px">Observatii detaliate:</P>
                    <RichTextContainer text={conclusion.description} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <P marginRight="5px">Increderea in date:</P>
                    <P>{conclusion.dataTrust}</P>
                  </Box>
                </Box>
              </Box>
            </Accordion>
          ))}
      </Accordion>

      <PrimaryButton
        onClick={() => setIsAddConclusionModalOpen(true)}
        marginTop="10px"
        icon={AddIcon}
      >
        Adauga o concluzie
      </PrimaryButton>
    </Box>
  );
};

export default DrawerConclusions;
