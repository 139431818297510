import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { Input, Heading2, SubmitButton, ButtonDiv, SaveIcon } from '../styled';

const RenderNameField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        height="100px"
        as={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderNameField
        name="comment"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Comentariu"
        type="textarea"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        {' '}
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddCommentForm = (props) => {
  const { onSubmit, comment, isEditModeComment } = props;
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    comment: Yup.string().required('Câmp obligatoriu'),
  });

  const initialValues = {
    comment: comment || '',
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm {...props} isEditModeComment={isEditModeComment} />
      )}
    </Formik>
  );
};

export default AddCommentForm;
