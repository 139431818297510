import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { get } from 'lodash-es';
import { AddResolutionFormExperiment } from '../forms';
import { Title, DeleteButtonSmall } from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddExperimentResolutionModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    entityId,
    onSubmitResolution,
    isExperiment,
    parent,
    resolution,
    loadData,
    firstName,
    lastName,
    isEditingResolution,
  } = props;

  const handleSubmit = async (values) => {
    const personResponsible = `${firstName} ${lastName} `;
    const promise = onSubmitResolution({
      ...values.resolution,
      experimentId: entityId,
      personResponsible,
      actionRequired: get(values.resolution.actionRequired, 'value'),
      dataReliability: get(values.resolution.dataReliability, 'value'),
      outcome: values.resolution.outcome || 'inProgress',
      problemOutcome: values.resolution.problemOutcome || 'inProgress',
      profileOutcome: values.resolution.profileOutcome || 'inProgress',
      propositionOutcome: values.resolution.propositionOutcome || 'inProgress',
    });
    promise.then(() => {
      setIsModalOpen(false);
      loadData(parent.hypothesisId[0]);
    });

    return promise;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const experimentText = isEditingResolution
    ? 'Editează concluzia experimentului'
    : 'Concluzie experiment';

  const hypothesisText = isEditingResolution
    ? 'Editează rezoluția ipotezei'
    : 'Adaugă o rezoluție la ipoteză';
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {isExperiment ? experimentText : hypothesisText}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} black />
        </TitleButtonsDiv>

        <AddResolutionFormExperiment
          onSubmit={handleSubmit}
          resolution={parent ? parent.resolution : resolution}
        />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddExperimentResolutionModal)
);
