import update from 'immutability-helper';

import { getPlatformInformations as doLoadPlatformInformations } from '../services/appStats';

// Actions
const START_LOAD = 'platformInfos/START_LOAD';
const COMPLETE_LOAD = 'platformInfos/COMPLETE_LOAD';
const FAIL_LOAD = 'platformInfos/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  plaftormInformations: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          plaftormInformations: action.plaftormInformations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          plaftormInformations: undefined,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (plaftormInformations) => ({
  type: COMPLETE_LOAD,
  plaftormInformations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPlatformInformations = () => (dispatch) => {
  dispatch(startLoad());
  return doLoadPlatformInformations()
    .then((res) => dispatch(completeLoad(res)))
    .catch(() => dispatch(failLoad()));
};
