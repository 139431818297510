import Box from '@mui/material/Box';
import React from 'react';
import { ProfileAvatar } from '../../userProfile/components/styled/UserProfileComponentStyle';
import { MyIdeaTag, MyIdeaTitle } from './styled/StyledIdeea';
import {
  H2,
  P,
  PrimaryButton,
  SubText,
} from '../../common/components/styled/ReusableTextComponents';
import { formatDate } from '../../common/functions/dateHelpers';
import { EditIcon, ExternalLink } from '../../common/assets/icons';
import { theme } from '../../core/styles';

const RenderText = ({ text, placeholder, prefix = '' }) => {
  if (text) {
    return <P marginTop="4px">{`${prefix} ${text}`}</P>;
  }
  return (
    <P marginTop="4px" faded>
      {placeholder}
    </P>
  );
};

const IdeaDetailsSection = ({
  tags,
  domain,
  headquarters,
  dateOfEstablishment,
  description,
  cui,
  name,
  setIsAddIdeaModalOpen,
  teamCode,
}) => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ProfileAvatar src="" variant="square">
        sa
      </ProfileAvatar>
      <MyIdeaTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <H2>{name}</H2>
          <SubText faded>Idee publica</SubText>
        </Box>

        <Box sx={{ display: 'flex' }}>
          {tags.map((tag) => (
            <MyIdeaTag key={tag}>{tag}</MyIdeaTag>
          ))}
        </Box>
      </MyIdeaTitle>
    </Box>
    <Box>
      <H2 marginTop="20px">Detalii generale</H2>
      <P marginTop="20px" faded>
        Descriere
      </P>
      <RenderText
        text={description}
        placeholder="Fara descriere"
        prefix="Descriere: "
      />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
      <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <P faded> Data infiintarii</P>
        <RenderText
          text={formatDate(dateOfEstablishment)}
          placeholder="Fara data a infiintarii"
          prefix="Data infiintarii:"
        />
      </Box>
      <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <P faded> CUI</P>
        <RenderText prefix="CUI:" text={cui} placeholder="Fara CUI" />
      </Box>
    </Box>
    <Box>
      <P marginTop="20px" faded>
        Adresa
      </P>
      <RenderText
        text={headquarters}
        prefix="Adresa:"
        placeholder="Fara adresa"
      />
    </Box>
    <Box>
      <P marginTop="20px" faded>
        Website
      </P>
      {domain ? (
        <Box sx={{ display: 'flex' }}>
          <a href={domain} target="_blank" rel="noreferrer">
            <P marginRight="10px">{domain}</P>
          </a>
          <ExternalLink fill={theme.accentLight} />
        </Box>
      ) : (
        <P faded> Fara website </P>
      )}

      <Box>
        <P marginTop="20px" faded>
          Cod echipa
        </P>
        <P>{teamCode} </P>
      </Box>
    </Box>
    <Box>
      <PrimaryButton
        inverted
        marginTop="20px"
        onClick={() => setIsAddIdeaModalOpen(true)}
        icon={EditIcon}
      >
        Edit
      </PrimaryButton>
    </Box>
  </>
);

export default IdeaDetailsSection;
