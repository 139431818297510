import update from 'immutability-helper';
import { push } from 'react-router-redux';
import { map, identity, filter, groupBy, includes } from 'lodash-es';
import { createSelector } from 'reselect';
import {
  loadHypotheses as doLoadHypotheses,
  addHypothesis as doAddHypothesis,
  editHypothesis as doEditHypothesis,
  addHypothesisComment as doAddHypothesisComment,
  loadHypothesis as doLoadHypothesis,
  editHypothesisComment as doEditHypothesisComment,
  deleteHypothesis as doDeleteHypothesis,
  deleteHypothesisComment as doDeleteHypothesisComment,
  editHypothesisEmpathyMap as doEditHypothesisEmpathyMap,
  editHypothesisEmpathyMapItem as doEditHypothesisEmpathyMapItem,
  addHypothesisCommentReply as doAddHypothesisCommentReply,
  deleteHypothesisCommentReply as doDeleteHypothesisCommentReply,
  editHypothesisCommentReply as doEditHypothesisCommentReply,
  editHypothesesOrder as doEditHypothesesOrder,
  addClientProfile as doAddClientProfile,
  editClientProfile as doEditClientProfile,
  addClientProfileCharacteristics as doAddClientProfileCharacteristics,
  uploadClientImageProfile as doUploadClientImageProfile,
  editCharcteristicsOrder as doEditCharcteristicsOrder,
  httpDeleteCharacteristicsElement as doDeleteCharacteristicsElement,
  httpAddHypothesisComment as doHttpAddHypothesisComment,
  httpAddHypothesisCommentReply as doHttpAddHypothesisCommentReply,
  httpDeleteHypothesisComment as doHttpDeleteHypothesisComment,
  httpEditHypothesisComment as doHttpEditHypothesisComment,
  httpDeleteHypothesisCommentReply as doHttpDeleteHypothesisCommentReply,
  httpEditHypothesisCommentReply as doHttpEditHypothesisCommentReply,
  httpGetAllComments as doHttpGetAllComments,
} from '../services/hypotheses';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';
import { BMC_OPTIONS } from '../../canvas/constants';
import { editDetail as doEditDetail } from '../../opportunityAnalysis/services/contextAnalysis';

// Actions
const START_LOAD = 'hypotheses/START_LOAD';
const COMPLETE_LOAD = 'hypotheses/COMPLETE_LOAD';
const FAIL_LOAD = 'hypotheses/FAIL_LOAD';

const START_LOAD_HYPOTHESIS = 'hypothesis/START_LOAD_HYPOTHESIS';
const COMPLETE_LOAD_HYPOTHESIS = 'hypothesis/COMPLETE_LOAD_HYPOTHESIS';
const FAIL_LOAD_HYPOTHESIS = 'hypothesis/FAIL_LOAD_HYPOTHESIS';

const START_ADD = 'hypotheses/START_ADD';
const COMPLETE_ADD = 'hypotheses/COMPLETE_ADD';
const FAIL_ADD = 'hypotheses/FAIL_ADD';

const START_EDIT = 'hypothesis/START_EDIT';
const COMPLETE_EDIT = 'hypothesis/COMPLETE_EDIT';
const FAIL_EDIT = 'hypothesis/FAIL_EDIT';

const START_EDIT_ORDER = 'hypothesis/START_EDIT_ORDER';
const COMPLETE_EDIT_ORDER = 'hypothesis/COMPLETE_EDIT_ORDER';
const FAIL_EDIT_ORDER = 'hypothesis/FAIL_EDIT_ORDER';

const START_EDIT_EMPATHY_MAP = 'hypothesis/START_EDIT_EMPATHY_MAP';
const COMPLETE_EDIT_EMPATHY_MAP = 'hypothesis/COMPLETE_EDIT_EMPATHY_MAP';
const FAIL_EDIT_EMPATHY_MAP = 'hypothesis/FAIL_EDIT_EMPATHY_MAP';

const START_DELETE = 'hypothesis/START_DELETE';
const COMPLETE_DELETE = 'hypothesis/COMPLETE_DELETE';
const FAIL_DELETE = 'hypothesis/FAIL_DELETE';

const START_ADD_COMMENT = 'hypotheses/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'hypotheses/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'hypotheses/FAIL_ADD_COMMENT';

const START_EDIT_COMMENT = 'hypotheses/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'hypotheses/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'hypotheses/FAIL_EDIT_COMMENT';

const START_DELETE_COMMENT = 'hypotheses/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'hypotheses/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'hypotheses/FAIL_DELETE_COMMENT';

const START_ADD_REPLY = 'hypotheses/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'hypotheses/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'hypotheses/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'hypotheses/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'hypotheses/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'hypotheses/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'hypotheses/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'hypotheses/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'hypotheses/FAIL_DELETE_REPLY';

const START_ADD_CLIENT_PROFILE = 'hypotheses/START_ADD_CLIENT_PROFILE';
const COMPLETE_ADD_CLIENT_PROFILE = 'hypotheses/COMPLETE_ADD_CLIENT_PROFILE';
const FAIL_ADD_CLIENT_PROFILE = 'hypotheses/FAIL_ADD_CLIENT_PROFILE';

const START_EDIT_CLIENT_PROFILE = 'hypotheses/START_EDIT_CLIENT_PROFILE';
const COMPLETE_EDIT_CLIENT_PROFILE = 'hypotheses/COMPLETE_EDIT_CLIENT_PROFILE';
const FAIL_EDIT_CLIENT_PROFILE = 'hypotheses/FAIL_EDIT_CLIENT_PROFILE';

const START_ADD_CLIENT_PROFILE_CHARCTERISTICS =
  'hypotheses/START_ADD_CLIENT_PROFILE_CHARCTERISTICS';
const COMPLETE_ADD_CLIENT_PROFILE_CHARCTERISTICS =
  'hypotheses/COMPLETE_ADD_CLIENT_PROFILE_CHARCTERISTICS';
const FAIL_ADD_CLIENT_PROFILE_CHARCTERISTICS =
  'hypotheses/FAIL_ADD_CLIENT_PROFILE_CHARCTERISTICS';

const START_EDIT_PROFILE_CHARCTERISTICS_ORDER =
  'hypotheses/START_EDIT_PROFILE_CHARCTERISTICS_ORDER';
const COMPLETE_EDIT_PROFILE_CHARCTERISTICS_ORDER =
  'hypotheses/COMPLETE_EDIT_PROFILE_CHARCTERISTICS_ORDER';
const FAIL_EDIT_PROFILE_CHARCTERISTICS_ORDER =
  'hypotheses/FAIL_EDIT_PROFILE_CHARCTERISTICS_ORDER';

const START_DELETE_PROFILE_CHARCTERISTICS_ELEMENT =
  'hypotheses/START_DELETE_PROFILE_CHARCTERISTICS_ELEMENT';
const COMPLETE_DELETE_PROFILE_CHARCTERISTICS_ELEMENT =
  'hypotheses/COMPLETE_DELETE_PROFILE_CHARCTERISTICS_ELEMENT';
const FAIL_DELETE_PROFILE_CHARCTERISTICS_ELEMENT =
  'hypotheses/FAIL_DELETE_PROFILE_CHARCTERISTICS_ELEMENT';

const START_ADD_HYPOTHESIS_COMMENT = 'hypotheses/START_ADD_HYPOTHESIS_COMMENT';
const COMPLETE_ADD_HYPOTHESIS_COMMENT =
  'hypotheses/COMPLETE_ADD_HYPOTHESIS_COMMENT';
const FAIL_ADD_HYPOTHESIS_COMMENT = 'hypotheses/FAIL_ADD_HYPOTHESIS_COMMENT';

const START_ADD_HYPOTHESIS_REPLY = 'hypotheses/START_ADD_HYPOTHESIS_REPLY';
const COMPLETE_ADD_HYPOTHESIS_REPLY =
  'hypotheses/COMPLETE_ADD_HYPOTHESIS_REPLY';
const FAIL_ADD_HYPOTHESIS_REPLY = 'hypotheses/FAIL_ADD_HYPOTHESIS_REPLY';

const START_DELETE_HYPOTHESIS_COMMENT =
  'hypotheses/START_DELETE_HYPOTHESIS_COMMENT';
const COMPLETE_DELETE_HYPOTHESIS_COMMENT =
  'hypotheses/COMPLETE_DELETE_HYPOTHESIS_COMMENT';
const FAIL_DELETE_HYPOTHESIS_COMMENT =
  'hypotheses/FAIL_DELETE_HYPOTHESIS_COMMENT';

const RESET = 'hypotheses/RESET';

// Initial state
const initialState = {
  isCreating: false,
  isEditing: false,
  isEditingOrder: false,
  isDeleting: false,
  isLoadingHypothesis: false,
  isLoadingHypotheses: false,
  hypothesis: undefined,
  hypotheses: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoadingHypotheses: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoadingHypotheses: false,
          hypotheses: action.hypotheses,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoadingHypotheses: false,
          hypotheses: undefined,
        },
      });

    case START_LOAD_HYPOTHESIS:
      return update(state, { $merge: { isLoadingHypothesis: true } });

    case COMPLETE_LOAD_HYPOTHESIS:
      return update(state, {
        $merge: {
          isLoadingHypothesis: false,
          hypothesis: action.hypothesis,
        },
      });

    case FAIL_LOAD_HYPOTHESIS:
      return update(state, {
        $merge: {
          isLoadingHypothesis: false,
          hypothesis: undefined,
        },
      });

    case START_ADD:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_ORDER:
      return update(state, { $merge: { isEditingOrder: true } });

    case COMPLETE_EDIT_ORDER:
      return update(state, { $merge: { isEditingOrder: false } });

    case FAIL_EDIT_ORDER:
      return update(state, { $merge: { isEditingOrder: false } });

    case START_DELETE:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case START_EDIT_EMPATHY_MAP:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_EMPATHY_MAP:
      return update(state, {
        $merge: {
          isEditing: false,
          hypothesis: action.hypothesis,
        },
      });

    case FAIL_EDIT_EMPATHY_MAP:
      return update(state, { $merge: { isEditing: false } });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD_CLIENT_PROFILE:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_CLIENT_PROFILE:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_CLIENT_PROFILE:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT_CLIENT_PROFILE:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_CLIENT_PROFILE:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_CLIENT_PROFILE:
      return update(state, { $merge: { isEditing: false } });

    case START_ADD_CLIENT_PROFILE_CHARCTERISTICS:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_CLIENT_PROFILE_CHARCTERISTICS:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_CLIENT_PROFILE_CHARCTERISTICS:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT_PROFILE_CHARCTERISTICS_ORDER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_PROFILE_CHARCTERISTICS_ORDER:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_PROFILE_CHARCTERISTICS_ORDER:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_PROFILE_CHARCTERISTICS_ELEMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_DELETE_PROFILE_CHARCTERISTICS_ELEMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_DELETE_PROFILE_CHARCTERISTICS_ELEMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_ADD_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_ADD_HYPOTHESIS_REPLY:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_HYPOTHESIS_REPLY:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_HYPOTHESIS_REPLY:
      return update(state, { $merge: { isCreating: false } });

    case START_DELETE_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_DELETE_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_DELETE_HYPOTHESIS_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (hypotheses) => ({
  type: COMPLETE_LOAD,
  hypotheses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadHypothesis = () => ({
  type: START_LOAD_HYPOTHESIS,
});

const completeLoadHypothesis = (hypothesis) => ({
  type: COMPLETE_LOAD_HYPOTHESIS,
  hypothesis,
});

const failLoadHypothesis = () => ({
  type: FAIL_LOAD_HYPOTHESIS,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startAddClientProfile = () => ({
  type: START_ADD_CLIENT_PROFILE,
});

const completeAddClientProfile = () => ({
  type: COMPLETE_ADD_CLIENT_PROFILE,
});

const failAddClientProfile = () => ({
  type: FAIL_ADD_CLIENT_PROFILE,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startEditOrder = () => ({
  type: START_EDIT,
});

const completeEditOrder = () => ({
  type: COMPLETE_EDIT,
});

const failEditOrder = () => ({
  type: FAIL_EDIT,
});

const startEditEmpathyMap = () => ({
  type: START_EDIT_EMPATHY_MAP,
});

const completeEditEmpathyMap = (hypothesis) => ({
  type: COMPLETE_EDIT_EMPATHY_MAP,
  hypothesis,
});

const failEditEmpathyMap = () => ({
  type: FAIL_EDIT_EMPATHY_MAP,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

const startEditClientProfile = () => ({
  type: START_EDIT_CLIENT_PROFILE,
});

const completeEditClientProfile = () => ({
  type: COMPLETE_EDIT_CLIENT_PROFILE,
});

const failEditClientProfile = () => ({
  type: FAIL_EDIT_CLIENT_PROFILE,
});

const startAddClientProfileCharacteristics = () => ({
  type: START_ADD_CLIENT_PROFILE_CHARCTERISTICS,
});

const completeAddClientProfileCharacteristics = () => ({
  type: COMPLETE_ADD_CLIENT_PROFILE_CHARCTERISTICS,
});

const failAddClientProfileCharacteristics = () => ({
  type: FAIL_ADD_CLIENT_PROFILE_CHARCTERISTICS,
});

const startEditCharcteristicsOrder = () => ({
  type: START_EDIT_PROFILE_CHARCTERISTICS_ORDER,
});

const completeEditCharcteristicsOrder = () => ({
  type: COMPLETE_EDIT_PROFILE_CHARCTERISTICS_ORDER,
});

const failEditCharcteristicsOrder = () => ({
  type: FAIL_EDIT_PROFILE_CHARCTERISTICS_ORDER,
});

const startDeleteCharacteristicElement = () => ({
  type: START_DELETE_PROFILE_CHARCTERISTICS_ELEMENT,
});
const completeDeleteCharacteristicElement = () => ({
  type: COMPLETE_DELETE_PROFILE_CHARCTERISTICS_ELEMENT,
});
const failDeleteCharacteristicElement = () => ({
  type: FAIL_DELETE_PROFILE_CHARCTERISTICS_ELEMENT,
});

const startAddHypothesisComment = () => ({
  type: START_ADD_HYPOTHESIS_COMMENT,
});

const completeAddHypothesisComment = () => ({
  type: COMPLETE_ADD_HYPOTHESIS_COMMENT,
});

const failAddHypothesisComment = () => ({
  type: FAIL_ADD_HYPOTHESIS_COMMENT,
});

const startAddHypothesisReply = () => ({
  type: START_ADD_HYPOTHESIS_REPLY,
});

const completeAddHypothesisReply = () => ({
  type: COMPLETE_ADD_HYPOTHESIS_REPLY,
});

const failAddHypothesisReply = () => ({
  type: FAIL_ADD_HYPOTHESIS_REPLY,
});

const startDeleteHypothesisComment = () => ({
  type: START_DELETE_HYPOTHESIS_COMMENT,
});

const completeDeleteHypothesisComment = () => ({
  type: COMPLETE_DELETE_HYPOTHESIS_COMMENT,
});

const failDeleteHypothesisComment = () => ({
  type: FAIL_DELETE_HYPOTHESIS_COMMENT,
});

export const loadHypotheses = (teamId) => (dispatch) => {
  dispatch(startLoad());
  return doLoadHypotheses(teamId)
    .then((res) => dispatch(completeLoad(res)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoad());
    });
};

// export const loadHypothesis = (hypothesisId) => (dispatch) => {
//   dispatch(startLoadHypothesis());
//   const editHypothesisPromise = doLoadHypothesis(hypothesisId);
//   editHypothesisPromise
//     .then((res) => {
//       dispatch(completeLoadHypothesis(res.data));
//     })
//     .catch((error) => {
//       dispatch(notifyError(error.response.data));
//       dispatch(failLoadHypothesis());
//       if (error.response.status === 404) dispatch(push('/canvas'));
//     });
//   return editHypothesisPromise;
// };

export const loadHypothesis = (hypothesisId) => async (dispatch) => {
  try {
    dispatch(startLoadHypothesis());
    const res = await doLoadHypothesis(hypothesisId);
    dispatch(completeLoadHypothesis(res.data));
  } catch (error) {
    dispatch(notifyError(error.response.data));
    dispatch(failLoadHypothesis());
    if (error.response && error.response.status === 404) {
      dispatch(push('/canvas'));
    }
  }
};

// export const editHypothesis = (values, entityId, parent, personResponsible) => (dispatch) => {
//     dispatch(startEdit());
//     const editHypothesisPromise = doEditHypothesis(
//       values,
//       entityId,
//       parent,
//       personResponsible
//     );
//     editHypothesisPromise
//       .then(() => {
//         dispatch(completeEdit());
//         dispatch(notifySuccess('Ipoteza a fost editata cu succes'));
//       })
//       .catch((error) => {
//         dispatch(notifyError(error.response.data));
//         dispatch(failEdit());
//       });
//     return editHypothesisPromise;
//   };

// export const editHypothesis = (values, entityId, parent, personResponsible) => async (dispatch) => {
//   try {
//     dispatch(startEdit());
//
//     await doEditHypothesis(values, entityId, parent, personResponsible);
//
//     dispatch(completeEdit());
//     dispatch(notifySuccess('Ipoteza a fost editata cu succes'));
//   } catch (error) {
//     dispatch(notifyError(error.response.data));
//     dispatch(failEdit());
//   }
// };

export const editHypothesis = (hypothesisId, values) => async (dispatch) => {
  dispatch(startEdit());
  try {
    await doEditHypothesis(hypothesisId, values);
    dispatch(completeEdit());
    dispatch(notifySuccess('Ipoteza a fost editata cu succes'));
  } catch (error) {
    dispatch(notifyError(error.response.data));
    dispatch(failEdit());
  }
};

export const addHypothesis = (values) => async (dispatch) => {
  try {
    dispatch(startAdd());
    const response = await doAddHypothesis(values);
    dispatch(completeAdd());
    dispatch(notifySuccess('Ipoteza a fost adăugata cu succes'));
    return response;
  } catch (error) {
    dispatch(notifyError(error.response.data));
    dispatch(failAdd());
  }
};

// export const addHypothesis = (values, teamId, createdBy) => (dispatch) => {
//   dispatch(startAdd());
//   const addHypothesisPromise = doAddHypothesis(values, teamId, createdBy);
//   addHypothesisPromise
//     .then(() => {
//       dispatch(completeAdd());
//       dispatch(notifySuccess('Ipoteza a fost adăugata cu succes'));
//     })
//     .catch((error) => {
//       dispatch(notifyError(error.response.data));
//       dispatch(failAdd());
//     });
//   return addHypothesisPromise;
// };

export const editHypothesesOrder = (hypotheses) => (dispatch) => {
  dispatch(startEditOrder());
  const editHypothesisPromise = doEditHypothesesOrder(hypotheses);
  editHypothesisPromise
    .then(() => {
      dispatch(completeEditOrder());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditOrder());
    });
  return editHypothesisPromise;
};

export const editHypothesisEmpathyMap =
  (hypothesisId, empathymap, name) => (dispatch) => {
    dispatch(startEditEmpathyMap());
    const editHypothesisEmpathyMapPromise = doEditHypothesisEmpathyMap(
      hypothesisId,
      empathymap,
      name
    );
    editHypothesisEmpathyMapPromise
      .then((res) => {
        dispatch(completeEditEmpathyMap(res.data));
        dispatch(notifySuccess('Empathy Map-ul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditEmpathyMap());
      });

    return editHypothesisEmpathyMapPromise;
  };

export const editHypothesisEmpathyMapItem =
  (hypothesisId, item, name) => (dispatch) => {
    dispatch(startEditEmpathyMap());
    const editHypothesisEmpathyMapItemPromise = doEditHypothesisEmpathyMapItem(
      hypothesisId,
      item,
      name
    );
    editHypothesisEmpathyMapItemPromise
      .then((res) => {
        dispatch(completeEditEmpathyMap(res.data));
        dispatch(notifySuccess('Empathy Map-ul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditEmpathyMap());
      });

    return editHypothesisEmpathyMapItemPromise;
  };

export const deleteHypothesis = (hypothesisId) => async (dispatch) => {
  let response;
  try {
    dispatch(startDelete());
    response = await doDeleteHypothesis(hypothesisId);
    dispatch(completeDelete());
    dispatch(notifySuccess('Ipoteza a fost stearsa cu succes'));
  } catch (error) {
    dispatch(notifyError(error.response.data));
    dispatch(failDelete());
  }
  return response;
};

export const addHypothesisComment = (values, hypothesisId) => (dispatch) => {
  dispatch(startAddComment());
  const addHypothesisCommentPromise = doAddHypothesisComment(
    values,
    hypothesisId
  );
  addHypothesisCommentPromise
    .then(() => {
      dispatch(completeAddComment());
      dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddComment());
    });
  return addHypothesisCommentPromise;
};

export const deleteHypothesisComment = (hypothesisId) => (dispatch) => {
  dispatch(startDeleteComment());
  const deleteHypothesisCommentPromise =
    doDeleteHypothesisComment(hypothesisId);
  deleteHypothesisCommentPromise
    .then(() => {
      dispatch(completeDeleteComment());
      dispatch(notifySuccess('Comentariul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDeleteComment());
    });
  return deleteHypothesisCommentPromise;
};

export const editHypothesisComment =
  (values, hypothesisCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editHypothesisCommentPromise = doEditHypothesisComment(
      values,
      hypothesisCommentId
    );
    editHypothesisCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editHypothesisCommentPromise;
  };

const groupHypotheses = (state) => {
  const BMCColumns = map(BMC_OPTIONS, (option) => option.value);
  return groupBy(
    filter(
      state.hypotheses,
      (hypothesis) => !!includes(BMCColumns, hypothesis.columnBMC)
    ),
    'columnBMC'
  );
};

export const getBMCData = createSelector(groupHypotheses, identity);

export const addHypothesisCommentReply =
  (values, hypothesisCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addHypothesisCommentReplyPromise = doAddHypothesisCommentReply(
      values,
      hypothesisCommentId
    );
    addHypothesisCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addHypothesisCommentReplyPromise;
  };

export const editHypothesisCommentReply =
  (values, hypothesisCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editHypothesisCommentReplyPromise = doEditHypothesisCommentReply(
      values,
      hypothesisCommentId,
      replyId
    );
    editHypothesisCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editHypothesisCommentReplyPromise;
  };

export const deleteHypothesisCommentReply =
  (hypothesisCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteHypothesisCommentReplyPromise = doDeleteHypothesisCommentReply(
      hypothesisCommentId,
      replyId
    );
    deleteHypothesisCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteHypothesisCommentReplyPromise;
  };

export const addClientProfile = (values, photo, hypothesisId) => (dispatch) => {
  dispatch(startAddClientProfile());

  return doAddClientProfile(hypothesisId, values)
    .then((res) => {
      const clientId = res.data._id;
      if (photo) {
        return doUploadClientImageProfile(clientId, photo).then(() => {
          dispatch(completeAddClientProfile());
          dispatch(notifySuccess('Secțiunea a fost modificată cu success'));
          return res;
        });
      } else {
        dispatch(completeAddClientProfile());
        dispatch(notifySuccess('Secțiunea a fost modificată cu success'));
        return res;
      }
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddClientProfile());
      throw error;
    });
};

export const editClientProfile =
  (values, photo, hypothesisId) => (dispatch) => {
    dispatch(startEditClientProfile());

    const editClientProfilePromise = doEditClientProfile(hypothesisId, values);
    let imageUploadPromise;

    if (photo) {
      imageUploadPromise = doUploadClientImageProfile(values._id, photo);
    } else {
      imageUploadPromise = Promise.resolve();
    }

    // the combined promise makes sure the image is also updated when the upload is done
    const combinedPromise = Promise.all([
      editClientProfilePromise,
      imageUploadPromise,
    ]);

    combinedPromise
      .then(() => {
        dispatch(completeEditClientProfile());
        dispatch(notifySuccess('Secțiunea a fost modificată cu success'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditClientProfile());
      });

    return combinedPromise;
  };

export const addClientProfileCharacteristics =
  (values, hypothesisId) => (dispatch) => {
    dispatch(startAddClientProfileCharacteristics());
    const editClientProfilePromise = doAddClientProfileCharacteristics(
      hypothesisId,
      values
    );

    editClientProfilePromise
      .then(() => {
        dispatch(completeAddClientProfileCharacteristics());
        dispatch(loadHypothesis(hypothesisId));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddClientProfileCharacteristics());
      });
    return editClientProfilePromise;
  };

export const editCharcteristicsOrder = (values) => (dispatch) => {
  dispatch(startEditCharcteristicsOrder());
  const editClientProfilePromise = doEditCharcteristicsOrder(values);
  editClientProfilePromise
    .then(() => {
      dispatch(completeEditCharcteristicsOrder());
      dispatch(notifySuccess('Secțiunea a fost modificată cu success'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditCharcteristicsOrder());
    });
  return editClientProfilePromise;
};

export const deleteCharacteristicElement =
  (elementId, hypothesisId) => (dispatch) => {
    dispatch(startDeleteCharacteristicElement());
    const editClientProfilePromise = doDeleteCharacteristicsElement(elementId);
    editClientProfilePromise
      .then(() => {
        dispatch(completeDeleteCharacteristicElement());
        dispatch(loadHypothesis(hypothesisId));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteCharacteristicElement());
      });
    return editClientProfilePromise;
  };

export const postHypothesisComment = (values, hypothesisId) => (dispatch) => {
  dispatch(startAddHypothesisComment());
  const postHypothesisCommentPromise = doHttpAddHypothesisComment(
    values,
    hypothesisId
  );
  postHypothesisCommentPromise
    .then(() => {
      dispatch(loadHypothesis(hypothesisId));

      dispatch(completeAddHypothesisComment());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddHypothesisComment());
    });
};

export const postHypothesisCommentReply =
  (values, hypothesisCommentId, hypothesisId) => (dispatch) => {
    dispatch(startAddHypothesisReply());
    const postHypothesisCommentReplyPromise = doHttpAddHypothesisCommentReply(
      values,
      hypothesisCommentId
    );
    postHypothesisCommentReplyPromise
      .then(() => {
        dispatch(loadHypothesis(hypothesisId));

        dispatch(completeAddHypothesisReply());
      })
      .catch((error) => {
        dispatch(failAddHypothesisReply());
      });
  };

export const deleteHypothesisCommentById =
  (hypothesisCommentId, hypothesisId) => (dispatch) => {
    dispatch(startDeleteHypothesisComment());
    const deleteHypothesisCommentPromise =
      doHttpDeleteHypothesisComment(hypothesisCommentId);
    deleteHypothesisCommentPromise
      .then(() => {
        dispatch(loadHypothesis(hypothesisId));

        dispatch(completeDeleteHypothesisComment());
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteHypothesisComment());
      });
  };

export const editHypothesisCommentById =
  (values, hypothesisCommentId, hypothesisId) => (dispatch) => {
    const editHypothesisCommentPromise = doHttpEditHypothesisComment(
      values,
      hypothesisCommentId
    );
    editHypothesisCommentPromise
      .then(() => {
        dispatch(loadHypothesis(hypothesisId));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
      });
  };

export const deleteHypothesisCommentReplyById =
  ({ hypothesisCommentId, replyId, hypothesisId }) =>
  (dispatch) => {
    const deleteHypothesisCommentReplyPromise =
      doHttpDeleteHypothesisCommentReply(hypothesisCommentId, replyId);
    deleteHypothesisCommentReplyPromise
      .then(() => {
        dispatch(loadHypothesis(hypothesisId));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
      });
  };

export const editHypothesisCommentReplyById =
  ({ comment, hypothesisCommentId, replyId, hypothesisId }) =>
  (dispatch) => {
    const editHypothesisCommentReplyPromise = doHttpEditHypothesisCommentReply(
      comment,
      hypothesisCommentId,
      replyId
    );
    editHypothesisCommentReplyPromise
      .then(() => {
        dispatch(loadHypothesis(hypothesisId));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
      });
  };

export const getAllComments = () => (dispatch) => {
  const getAllCommentsPromise = doHttpGetAllComments();
  getAllCommentsPromise
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
};
