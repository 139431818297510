import styled from 'styled-components';
import { Avatar } from '@mui/material';

export const ProfileContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: 82vh;
  padding: 40px;
`;

export const ProfileAvatar = styled(Avatar)`
  border-radius: 10px !important;
  background-color: #98a6ad;
  width: 100px !important;
  height: 100px !important;
`;

export const IdeeaMemberAvatar = styled(Avatar)`
  background-color: #98a6ad;
  width: ${(props) => `${props.size || 40}px !important;`};
  height: ${(props) => `${props.size || 40}px !important;`};
  border-radius: 50%;
`;

export const NameAndRole = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-left: 10px;
`;

export const MyIdeasScroll = styled.div`
  height: 73vh;
  margin-top: 30px;
  background-color: white;
`;

export const ProfileInfoRole = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #9441fe;
  text-align: center;
  border: 1px solid #9441fe;
  border-radius: 5px;
  margin-top: 15px;
  width: max-content;
  padding: 3px 5px;
`;

export const MyIdeasCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74a3fe;
  border: 1px solid #74a3fe;
  border-radius: 5px;
  margin-left: 5px;
  padding: 3px 10px;
  width: 35px;
  height: 35px;
`;

export const IdeeaBoxDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 21px;
  border: 1px solid #74a3fe;
  border-radius: 10px;
  flex: none;
  order: 0;
  margin-bottom: 20px;
  flex-grow: 0;
`;

export const IdeaProfileBoxEdit = styled.div`
  margin: auto 10px auto auto;
`;
