import ModalComponent from '../../common/components/ModalComponent';
import InviteUserToIdeaForm from './InviteUserToIdeaForm';
import JoinCohortForm from './JoinCohortForm';
import ManageTeamEquityForm from './ManageTeamEquityForm';
import React from 'react';

const AddUserToIdeaModal = ({
  isMentor = false,
  isOpen = false,
  handleClose,
  runUpdateTeamMembers,
  runUpdateTeamMentors,
  teamId,
}) => (
  <ModalComponent
    handleClose={handleClose}
    open={isOpen}
    title={isMentor ? 'Invita Mentor' : 'Invita Membru'}
  >
    <InviteUserToIdeaForm
      isMentor={isMentor}
      teamId={teamId}
      runUpdateTeamMembers={runUpdateTeamMembers}
      runUpdateTeamMentors={runUpdateTeamMentors}
      handleClose={handleClose}
    />
  </ModalComponent>
);

const JoinCohortModal = ({ isOpen = false, handleClose, teamId }) => (
  <ModalComponent
    open={isOpen}
    handleClose={handleClose}
    title="Alatura-te unei cohorte"
  >
    <JoinCohortForm teamId={teamId} handleClose={handleClose} />
  </ModalComponent>
);

const ManageTeamModal = ({ handleClose, isOpen, members }) => (
  <ModalComponent
    open={isOpen}
    handleClose={handleClose}
    title="Editeaza structura asociatilor"
  >
    <ManageTeamEquityForm members={members} />
  </ModalComponent>
);

export { AddUserToIdeaModal, JoinCohortModal, ManageTeamModal };
