import { useField } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';

const CustomRadioGroup = ({ id, label, options }) => {
  const [field, meta] = useField(id);
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: '1px solid #74A3FE',
        borderRadius: '4px',
        color: '#74A3FE',
        padding: '10px',
        marginBottom: '15px',
      }}
    >
      <FormControl component="fieldset" error={meta.touched && !!meta.error}>
        <FormLabel sx={{ color: '#74A3FE' }} component="legend">
          {label}
        </FormLabel>
        <RadioGroup {...field} id={id}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.label}
              sx={{ marginBottom: 0 }}
            />
          ))}
        </RadioGroup>
        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default CustomRadioGroup;
