import { Formik, useField } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import { postNewIndustry, editIndustryById } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { INDUSTRY_IMPACT_OPTIONS } from '../../constants';

const RenderTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Grid>
  );
};

const RenderRadioGroup = ({ id, label, options }) => {
  const [field] = useField(id);
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: '1px solid #74A3FE',
        borderRadius: '4px',
        color: '#74A3FE',
        padding: '10px',
      }}
    >
      <FormControl component="fieldset">
        <FormLabel sx={{ color: '#74A3FE' }} component="legend">
          {label}
        </FormLabel>
        <RadioGroup {...field} id={id}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

const IndustryForm = ({
  selectedIndustry,
  postNewIndustry,
  teamId,
  industryType,
  editIndustryById,
  handleCloseModal,
}) => {
  const schema = Yup.object().shape({
    title: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    influence: Yup.string().required('Câmp obligatoriu'),
  });

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      industryType,
    };

    if (selectedIndustry) {
      editIndustryById(selectedIndustry._id, payload, teamId);
    } else {
      postNewIndustry(payload, teamId);
    }

    handleCloseModal();
  };

  const initialValues = selectedIndustry
    ? {
        title: selectedIndustry.title,
        description: selectedIndustry.description,
        influence: selectedIndustry.influence,
      }
    : {
        title: '',
        description: '',
        influence: INDUSTRY_IMPACT_OPTIONS[0].value,
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderTextField id="title" label="Titlu" />
          <RenderTextField
            id="description"
            label="Descriere"
            multiline
            rows={4}
          />
          <RenderRadioGroup
            id="influence"
            label="Ce influență are acest factor asupra industriei?"
            options={INDUSTRY_IMPACT_OPTIONS}
          />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewIndustry,
  editIndustryById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryForm);
