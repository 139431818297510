import React from 'react';
import ModalComponent from '../../common/components/ModalComponent';
import ArticlesFilterForm from './ArticlesFilterForm';

const ArticlesFilterModal = ({
  isModalOpen,
  handleSubmit,
  handleClose,
  filters,
  categories,
}) => (
  <ModalComponent
    size="xs"
    open={isModalOpen}
    handleClose={handleClose}
    hideHeader
  >
    <ArticlesFilterForm
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      filters={filters}
      categories={categories}
    />
  </ModalComponent>
);

export default ArticlesFilterModal;
