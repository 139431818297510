import styled from 'styled-components';

export const MrlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border: ${(props) => `0.5px solid ${props.theme.accentLight}`};
  border-radius: 10px;
`;

export const MrlHeader = styled.div`
  display: flex;
  height: 5vh;
  align-items: center;
  margin-top: 1vh;
  font-size: 12px;
  padding: 10px;
`;

export const ProgressBarContainer = styled.div`
  width: 85%;
  height: 18px;
  background-color: white;
  border-radius: 6px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #74a3fe;
  position: relative;
  z-index: 5;
`;

export const ProgressElement = styled.div`
  height: 17px;
  position: absolute;
  top: 0;
  padding: 1px;
  width: ${(props) => (props.width ? props.width : '50%')};
  background-color: #b1ccfe;
  border-radius: 5px 0 0 5px;
  z-index: 4;
`;

export const ProgressCounter = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  float: right;
  margin-right: 10px;
  z-index: 100;
  font-size: 12px;
  font-family: 'Open Sans', serif;
  font-weight: 600;
  color: #2a5ab7;
`;

export const StepNumberContainer = styled.div`
  display: flex;
  border: 1px solid #74a3fe;
  background: white;
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 24px;
  height: 24px;
  font-weight: 600;
  border-radius: 50%;
  background-color: ${(props) => (props.isCompleted ? 'white' : 'white')};
  border: 1px solid #b1ccfe;
  color: ${(props) => (props.isCompleted ? '#74A3FE' : '#74A3FE')};
`;

export const StepTitle = styled.h4`
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  margin-left: 2vh;
  font-weight: ${({ isCompleted }) => (isCompleted ? 'bold' : '')};
  font-size: 12px;
`;

export const MrlStepContainer = styled.div`
  height: 78vh;
  margin-top: 2vh;
  margin-left: 1.5vw;
  list-style-position: inside;
  overflow-y: ${({ disableScroll }) => (disableScroll ? 'auto' : 'hidden')};
  overflow-x: hidden;
  margin-right: 0.5vw;
  padding-right: 0.5vw;

  & p {
    min-height: 16px;
  }
`;

export const StepHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MrlContainerClosed = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 4vw;
  display: flex;
  border: ${(props) => `0.5px solid ${props.theme.accentLight}`};
  justify-content: center;
`;

export const CloseMrlContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border-radius: 8px;
  cursor: pointer;
  margin: 1vh 0;
  transform: translate(-50%, 0);
  border: 1px solid #74a3fe;
`;

export const MrlElementContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 2vh;
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
`;

export const OpenMrlContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  cursor: pointer;
`;

export const MarginContainer = styled.div`
  display: ${({ display }) => display && display};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  width: ${({ width }) => width && width};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
  z-index: ${({ zIndex }) => zIndex && zIndex};
  color: ${({ color }) => color && color};
  height: ${({ height }) => height && height};
  position: ${({ position }) => position && position};
  font-size: ${({ fontSize }) => fontSize && fontSize};
`;

export const MrlTaskContainer = styled(MarginContainer)`
  color: #2a5ab7;

  & p > a {
    text-decoration: underline;
  }

  & ul > li {
    margin-bottom: 8px;
    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      margin-right: 0;
      content: '\\2022  ';
      font-weight: bold;
    }
  }
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-family: 'Open Sans', serif;
  color: ${({ color }) => color && color};
  font-size: ${({ fontSize }) => fontSize || '12px'};
`;

export const MrlTitleContainer = styled.div`
  color: ${(props) => props.theme.black};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-left: 10px;
`;

export const MrlSelectedStepContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: ${(props) =>
    props.isOpen && `0.5px solid ${props.theme.accentLight}`};
  border-radius: ${({ isOpen }) => isOpen && '5px'};
  height: 50px;
`;

export const StepTitleContainer = styled.div`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${(props) => (props.color ? props.color : props.theme.black)};
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  max-height: ${({ maxHeight }) => maxHeight && maxHeight};
  font-family: 'Open Sans', serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 10vw;
`;

export const EllipsisContainer = styled.div`
  text-overflow: ellipsis;
  font-family: 'Open Sans', serif;
  overflow: hidden;
  white-space: nowrap;
  width: ${({ width }) => width && width};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${(props) => (props.color ? props.color : props.theme.black)};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  line-height: 1.5;
`;

export const ExpandButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1vh;
`;

export const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Open Sans', serif;
  font-weight: 400;
  color: #2a5ab7;
  padding-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
`;

export const TaskCircle = styled.div`
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ isCompleted }) => (isCompleted ? 'green' : 'white')};
`;

export const ExempleButtonContainer = styled.div`
  display: flex;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  padding: 5px 10px;
  width: 7.5vw;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
  cursor: pointer;
`;

export const HtmlContainer = styled.span`
  & p {
    margin-bottom: 2px;
  }

  & p > a {
    text-decoration: underline;
  }

  & ol {
    margin-left: 10px;
  }

  & ul > li {
    margin-bottom: 8px;
    margin-left: 10px;

    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      margin-right: 0;
      content: '\\2022  ';
      font-weight: bold;
    }
  }
`;

export const TransitionTaskContainer = styled.div`
  width: 100%;
  margin: ${({ isOpen }) => (!isOpen ? '0 0 0 0' : '1vh 0 0 0')};
  opacity: ${({ isOpen }) => (!isOpen ? 0 : 1)};
  transition: opacity 0.3s, height 0.3s;
  color: #2a5ab7;

  & p > a {
    text-decoration: underline;
  }

  & ul > li {
    margin-bottom: 8px;
    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      margin-right: 0;
      content: '\\2022  ';
      font-weight: bold;
    }
  }
`;

export const ListButtonContainer = styled.div`
  display: flex;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  padding: 1px 5px;
  cursor: pointer;
  margin-right: 1vw;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.black};
`;

export const ButtonText = styled.div`
  max-width: 5.5vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
