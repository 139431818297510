import React, { useState, useRef } from 'react';
import { ClickAwayListener, Popper } from '@mui/material';

const InteractiveTooltip = ({ children, content, placement = 'right-end' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'interactive-popper' : undefined;

  const handleToggle = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event?.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const child = React.cloneElement(React.Children.only(children), {
    onClick: handleToggle,
    ref: buttonRef,
  });

  return (
    <React.Fragment>
      {child}
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <Popper
          open={popperOpen}
          anchorEl={anchorEl}
          id={popperId}
          onClose={handleClose}
          placement={placement}
        >
          {typeof content === 'function'
            ? content({ close: handleClose })
            : React.isValidElement(content)
            ? React.cloneElement(content, { close: handleClose })
            : content}
        </Popper>
      </ClickAwayListener>
    </React.Fragment>
  );
};

export default InteractiveTooltip;
