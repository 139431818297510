import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { useField } from 'formik';

export const RenderCounterCustomButton = ({
  active,
  label,
  onClick,
  customStyles,
  disabled = false,
  ...props
}) => {
  const styles = {
    borderRadius: '4px',
    background: active ? 'rgba(116, 163, 254, 0.2)' : 'transparent',
    width: '42px',
    height: '42px',
    border: disabled ? '1px solid #D9D9D9' : '1px solid #74A3FE',
    margin: '0 5px',
    color: disabled ? '#D9D9D9' : 'inherit',
    ...customStyles,
  };

  return (
    <Button disabled={disabled} style={styles} onClick={onClick} {...props}>
      {label}
    </Button>
  );
};

const CustomCounter = ({
  id,
  label,
  customStyles = {},
  md = 12,
  maxValue = 3,
  minValue = 1,
}) => {
  const [field, meta, helpers] = useField(id);
  const { value } = field;
  const { setValue } = helpers;

  const isIncrementEnabled = value < maxValue;
  const isDecrementEnabled = value > minValue;

  const increment = () => {
    if (isIncrementEnabled) {
      setValue(value + 1);
    }
  };

  const decrement = () => {
    if (isDecrementEnabled) {
      setValue(value - 1);
    }
  };

  return (
    <Grid item xs={12} md={md}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #74A3FE',
          color: '#74A3FE',
          borderRadius: '4px',
          padding: '18.5px 14px',
          marginBottom: '15px',
          ...customStyles,
        }}
      >
        <div>{label}</div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RenderCounterCustomButton
            onClick={decrement}
            label="-"
            customStyles={{ minWidth: '24px', width: '24px', height: '24px' }}
            disabled={!isDecrementEnabled}
          />
          <div style={{ margin: '0 10px' }}>{value}</div>
          <RenderCounterCustomButton
            onClick={increment}
            label="+"
            customStyles={{ minWidth: '24px', width: '24px', height: '24px' }}
            disabled={!isIncrementEnabled}
          />
        </Box>
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </Box>
    </Grid>
  );
};

export default CustomCounter;
