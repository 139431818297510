import { STATUS } from 'react-joyride';
import {
  BMC_ONBOARDING_STEPS,
  CONTEXT_ANALYSIS_ONBOARDING_STEPS,
  MRL_ONBOARDING_STEPS,
  ONBOARDING_PATH_NAMES,
  PLATFORM_FUNDAMENTALS_ONBOARDING_STEPS,
} from './constants';
import { debounce } from 'lodash-es';

const platformFundamentalsCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  runUpdateTourProgress,
}) => {
  const { action, index, status, lifecycle } = data;

  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (index === 0) {
      setIsTourActive(false);
      push('/profile?tab=profile');
      setCurrentStep(1);
      debounce(
        () =>
          runUpdateTourProgress({
            stepData: {
              step: 1,
              code: ONBOARDING_PATH_NAMES.platformFundamentals,
            },
          }),
        100
      );

      runUpdateTourProgress({
        stepData: {
          step: 1,
          code: ONBOARDING_PATH_NAMES.platformFundamentals,
        },
      }),
        setTimeout(() => {
          setIsTourActive(true);
        }, 1000);
      return;
    }

    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);

      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.platformFundamentals,
        },
      });
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

const tabsMapping = {
  0: 'trends',
  5: 'industry',
  7: 'competition',
  9: 'swot',
  11: 'details',
  13: 'conclusions',
};

const contextAnalysisCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  runUpdateTourProgress,
}) => {
  const { action, index, status, lifecycle } = data;

  const handleNavigation = (index, tab) => {
    setIsTourActive(false);
    push(`/opportunity-analysis?tab=${tab}`);
    setTimeout(
      () => {
        setIsTourActive(true);
      },
      index === 0 ? 1000 : 500
    );
  };

  switch (action) {
    case 'close':
      setIsTourActive(false);
      stopTour();
      break;
    case 'next':
      if (lifecycle === 'complete' && action === 'next') {
        setCurrentStep(index + 1);

        runUpdateTourProgress({
          stepData: {
            step: index + 1,
            code: ONBOARDING_PATH_NAMES.contextAnalysis,
          },
        });
      }
      if (tabsMapping[index]) {
        handleNavigation(index, tabsMapping[index]);
      }
      break;
    default:
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setIsTourActive(false);
        stopTour();
      }
  }
};

const mrlCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  runUpdateTourProgress,
}) => {
  const { action, index, status, lifecycle } = data;
  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);
      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.mrl,
        },
      });
    }

    if (index === 3) {
      setIsTourActive(false);
      push('profile?tab=settings');

      setTimeout(() => {
        setIsTourActive(true);
      }, 1000);
      return;
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

const bmcCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  runUpdateTourProgress,
  stopTour,
}) => {
  const { action, index, status, lifecycle } = data;
  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);
      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.bmc,
        },
      });
    }

    if (index === 1) {
      setIsTourActive(false);
      push('/canvas');

      setTimeout(() => {
        setIsTourActive(true);
      }, 1000);
    }

    if (index === 3) {
      setIsTourActive(false);

      setTimeout(() => {
        setCurrentStep(4);
        setIsTourActive(true);
      }, 1000);
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

export const PLATFORM_FUNDAMENTALS = {
  steps: PLATFORM_FUNDAMENTALS_ONBOARDING_STEPS,
  callback: platformFundamentalsCallback,
};

export const CONTEXT_ANALYSIS = {
  steps: CONTEXT_ANALYSIS_ONBOARDING_STEPS,
  callback: contextAnalysisCallback,
};

export const MRL = {
  steps: MRL_ONBOARDING_STEPS,
  callback: mrlCallback,
};

const BMC = {
  steps: BMC_ONBOARDING_STEPS,
  callback: bmcCallback,
};

export const PLATFORM_TOURS = {
  'platform-fundamentals': PLATFORM_FUNDAMENTALS,
  'context-analysis': CONTEXT_ANALYSIS,
  mrl: MRL,
  bmc: BMC,
};
