import React from 'react';

// PLATFORM FUNDAMENTALS
import { TourImageComponent } from './components/TourStarters';

import rocketImage from '../common/assets/img/rocket_launching.png';
import landedRocketImage from '../common/assets/img/landed_rocket.png';
import telescopeImage from '../common/assets/img/telescope.png';
import MrlImage from '../common/assets/img/mrl_image.png';
import BmcImage from '../common/assets/img/bmc_image.png';
import HypothesisOnboardingRouter from './components/HypothesisOnboardingRouter';

export const ONBOARDING_PATH_NAMES = {
  contextAnalysis: 'context-analysis',
  platformFundamentals: 'platform-fundamentals',
  mrl: 'mrl',
  bmc: 'bmc',
};

export const PLATFORM_FUNDAMENTALS_ONBOARDING_STEPS = [
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="Bine ai venit pe LaunchPad, prename!"
        text={[
          'Multumim ca ai ales Launchpad pentru transformarea ideilor tale in potentiale afaceri de succes. Acum, hai sa te punem rapid la curent cu functionalitatile de baza ale platformei.',
        ]}
        image={rocketImage}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#add-ideea-button',
    content:
      'Aici poti adauga o idee. Mentioneaza cateva informatii generale despre aceasta. Nu-ti face griji legate de calitatea informatiilor, le vei putea edita oricand doresti.',
    disableBeacon: true,
    title: 'Adauga o idee',
  },
  {
    target: '#navbar-tools',
    content:
      'Aici gasesti principalele instrumente de lucru pe care le ai la dispozitie.',
    disableBeacon: true,
    title: 'Instrumente de lucru',
  },
  {
    target: '#mrl-container',
    content:
      'Parcurge pas cu pas recomandarile noastre pentru a accelera procesul de la idee la afacere cu riscuri cat mai reduse. Afla care este primul pas pe care trebue sa-l faci.',
    disableBeacon: true,
    title: 'Ghidul MRL',
    placement: 'left',
  },
  {
    target: '#notifications-bell',
    content: 'Aici gasesti toate conversatiile cu ceilalti membrii ai echipei.',
    disableBeacon: true,
    title: 'Notificari si comentarii',
  },
  {
    target: '#feedback-form',
    content:
      'Spune-ne ce parere ai de LaunchPad si transmite-ne sugestiile tale prin intermediul formularului de contact',
    disableBeacon: true,
    title: 'Parerea ta conteaza',
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="Felicitări prename pentru finalizarea primului nivel"
        text={[
          'Ai pilotat cu succes primul nivel al ghidului de familiarizare cu platforma. Acum ai cunoștințele de bază pentru utilizarea eficientă a platformei.',
          'În următorul nivel vei descoperi cum să faci o analiză a contextului actual.',
        ]}
        image={landedRocketImage}
      />
    ),
    disableBeacon: true,
  },
];

// Context Analysis
export const CONTEXT_ANALYSIS_ONBOARDING_STEPS = [
  {
    disableBeacon: true,
    target: '#context-analysis-1',
    content:
      'Analizeaza cei mai importanti factori care pot avea o influenta pozitiva sau negativa asupra ideii tale.',
    title: 'Elementele analizei contextului',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-2',
    content:
      'Analizeaza cei mai importanti factori care pot avea o influenta pozitiva sau negativa asupra ideii tale.',
    title: 'Elementele analizei contextului',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-trends',
    content:
      'Incepe analiza contextului identificand trenduri globale ce tin de factori politici, economici, sociali, tehnologici, legislativi sau de mediu.',
    title: 'Trendurile',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-4',
    content: 'Apasa pe butonul evidentiat pentru a adauga un nou trend.',
    title: 'Adauga un trend',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-industry',
    content:
      'Identifica factorii si fortele care caracterizeaza industria in care urmeaza sa activezi.',
    title: 'Industria',
  },
  {
    disableBeacon: true,
    target: '#industry-type-rivalry',
    content: 'Apasa pe butonul evidentiat pentru a adauga o noua informatie.',
    title: 'Adauga o informatie',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-competition',
    content:
      'Analizeaza cei mai importanti 5 competitori din prisma celor mai relevante criterii pentru a avea succes in industrie.',
    title: 'Competitia',
  },
  {
    disableBeacon: true,
    target: '#cell-00',
    content: 'Apasa pe butonul evidentiat pentru a adauga un nou competitor.',
    title: 'Adauga un competitor',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-swot',
    content:
      'Identifica puntele forte, punctele slave oportunitatile si amenintarile la adresa ideii tale.',
    title: 'SWOT',
  },
  {
    disableBeacon: true,
    target: '#strengths',
    content: 'Apasa pe butonul evidentiat pentru a adauga un nou punct forte.',
    title: 'Adauga un punct forte',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-details',
    content:
      'Adauga in aceasta sectiune rapoarte, analize, studii sau orice alte documente relevante pentru analiza contextului si referintele viitoare.',
    title: 'Detalii',
  },
  {
    disableBeacon: true,
    target: '#add-details-button',
    content: 'Apasa pe butonul evidentiat pentru a adauga un nou detaliu.',
    title: 'Adauga un detaliu',
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-conclusions',
    content:
      'Adauga o sinteza a tuturor informatiilor pe care le-ai obtinut si determina daca contextul iti este favorabil sau nu. ',
    title: 'Concluzie',
  },
  {
    disableBeacon: true,
    target: '#conclusionButton',
    content: 'Apasa pe butonul evidentiat pentru a adauga o noua concluzie.',
    title: 'Adauga o concluzie',
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="Felicitări prename pentru parcurgerea secțiunii Analiza Contextului"
        text={[
          'Ai finalizat cu succes al doilea nivel al ghidului de familiarizare cu platforma. Acum ai cunoștințele de bază pentru a face o analiză a contextului relevantă.',
          'În următorul nivel vei descoperi cum să dezvolți un model de business inovativ.',
        ]}
        image={telescopeImage}
      />
    ),
    disableBeacon: true,
  },
];

// MRL
export const MRL_ONBOARDING_STEPS = [
  {
    target: '#mrl-size-controll',
    content:
      'Metodologia și procesul nostru validat cu mii de antreprenori și afaceri pe care l-ai făcut și pașii ce urmează să-i faci',
    disableBeacon: true,
    title: 'Ghidul MRL',
  },
  {
    target: '#mrl-0',
    content:
      'Fiecare etapă îți va oferi informațiile și pașii pe care trebuie să te concentrezi pentru a fi super rapid/ă în procesul de validare a ipotezei.',
    disableBeacon: true,
    title: 'Etapele cheie',
  },
  {
    target: '#mrl-size-controll',
    content: 'Ghidul este mereu la un click distanță.',
    disableBeacon: true,
    title: '',
  },
  {
    target: '#mrl-switch',
    content: 'Ascunde sau afișează permanent ghidul MRL.',
    disableBeacon: true,
    title: 'Ascunderea sau afișarea permanentă',
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="Felicitări prename pentru parcurgerea ghidului MRL"
        text={[
          'Ai finalizat cu succes al patrulea nivel al ghidului de familiarizare cu platforma. Acum că știi care sunt pașii pe care poți să-i urmezi poți să treci la treabă.',
          'În următorul nivel vei descoperi cum să folosești bordurile pentru urmări și prioritiza activitățile și sarcinile ideii voastre.',
        ]}
        image={MrlImage}
      />
    ),
    disableBeacon: true,
  },
];

// BUSINESS MODEL CANVAS

export const BMC_ONBOARDING_STEPS = [
  {
    target: '#mrl-nav',
    content:
      'Planifică și organizează ipotezele și activitățile cele mai importante care contribuie la găsirea modelului de business ideal al ideii tale.',
    disableBeacon: true,
    title: 'Business Model Canvas (BMC)',
  },
  {
    target: '#team-dropdown',
    content: 'Alege rapid ideile pentru care dorești să vezi informațiile.',
    disableBeacon: true,
    title: 'Ideile mele',
  },
  {
    target: '#bmc-time-controlls',
    content:
      'Vezi povestea ideii tale și a modelului tău de business în cadre săptămânale',
    disableBeacon: true,
    title: 'Povestea ideii tale',
  },
  {
    target: '#button-add-customerSegments',
    content: <HypothesisOnboardingRouter />,
    disableBeacon: true,
  },
  {
    target: '#hypothesis-profile',
    content: 'Alege un segment de clienți și navighează până la tabul Profil.',
    disableBeacon: true,
    title: 'Profilul clientului ideal',
  },
  {
    target: '#middle-container',
    content:
      'Fă lucrurile personale. Adaugă un nume, imagine și cuvânt cheie legat de profilul segmentului',
    disableBeacon: true,
    title: 'Persona',
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="Felicitări prename pentru parcurgerea instrumentului business model canvas"
        text={[
          'Ai finalizat cu succes al treilea nivel al ghidului de familiarizare cu platforma. Acum ai cunoștințele de bază pentru a porni în căutarea unui model de business inovativ și de succes.',
          'În următorul nivel vei descoperi cum să folosești ghidul MRL pentru a lua cele mai bune decizii și a știi pe ce să te concentrezi.',
        ]}
        image={BmcImage}
      />
    ),
    disableBeacon: true,
  },
];

export const joyRideStyleOverride = {
  tooltipContent: {
    color: 'black',
    textAlign: 'start',
    padding: '10px 0',
    fontSize: '14px',
  },
  tooltipTitle: {
    color: '#2A5AB7',
    textAlign: 'start',
    fontSize: '16px',
    fontWeight: '600',
    padding: '10px 0',
  },
  buttonNext: {
    background: '#74A3FE',
  },
};
