import React, { useState } from 'react';
import { Accordion, Box, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  P,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { AddIcon, PaperClipIcon } from '../../../common/assets/icons';
import RichTextContainer from '../../../common/components/RichTextContainer';
import { ClampedRichText } from '../../../common/components/ClampedRichText';

const DrawerTasks = ({
  tasks,
  handleToggleTask,
  setIsAddTaskModalOpen,
  setPreviewedTask,
  setTaskDetailModalId,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  const toggleTasksAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  const openModal = () => {
    setIsAddTaskModalOpen(true);
  };

  const handleClickTask = (task) => {
    setPreviewedTask(task);
  };

  return (
    <Box>
      <Accordion
        expanded={accordionOpen}
        sx={{
          boxShadow: 'none',
          '&:before': { height: '0px' },
        }}
      >
        <Box sx={{ marginTop: '20px' }}>
          <Box
            onClick={toggleTasksAccordion}
            sx={{ display: 'flex', cursor: 'pointer' }}
          >
            {accordionOpen ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
            <P marginLeft="10px">Taskuri</P>
          </Box>
        </Box>
        {tasks.length > 0 &&
          tasks?.map((task) => (
            <Box key={task._id}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    onClick={() => handleToggleTask(task._id, task?.status)}
                    checked={task?.status === 'done'}
                  />
                  <P
                    className="cursor-pointer"
                    onClick={() => handleClickTask(task)}
                  >
                    {task.title}
                  </P>
                </Box>
                <Box
                  onClick={() => setTaskDetailModalId(task?._id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <AddIcon fill="#74A3FE" width="15" height="15" />
                </Box>
              </Box>

              {task?.details &&
                task?.details.map((detail) => (
                  <Box
                    className="display-flex align-items-center"
                    key={detail?._id}
                    sx={{ marginLeft: '40px' }}
                  >
                    <PaperClipIcon fill="#74A3FE" />
                    <P marginLeft="5px">
                      <ClampedRichText
                        lineClamp={1}
                        text={detail?.description}
                      />
                    </P>
                  </Box>
                ))}
            </Box>
          ))}
      </Accordion>

      <PrimaryButton onClick={openModal} marginTop="10px" icon={AddIcon}>
        Adauga un task
      </PrimaryButton>
    </Box>
  );
};
export default DrawerTasks;
