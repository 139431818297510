import styled from 'styled-components';
import { Box } from '@mui/material';

export const RegistrationPageContainer = styled.div`
  padding: 60px 50px;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: white;
`;

export const RegistrationFormLeftPanel = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

export const RegistrationFormRightPanel = styled.div`
  width: 500px;
  top: 50%;
  transform: translate(0, -50%);
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
  z-index: 0;
`;

export const RegistrationFormImageBox = styled(Box)`
  width: 500px;
  height: 600px;
  background-size: cover;
  background-position: center;
`;

export const QuestionBigBoxAnswer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background-color: ${(props) =>
    props.selected ? props.theme.blueAccent : '#efeff4'};
  color: ${(props) =>
    props.selected ? '#efeff4' : props.theme.blueAccent} !important;
  margin-right: 10px;
  border-radius: 20px;
  width: 250px;
  height: 150px;
  text-align: center;
  cursor: pointer;
`;

export const PillQuestionAnswer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: ${(props) =>
    props.selected ? props.theme.blueAccent : '#efeff4'};
  color: ${(props) => (props.selected ? '#efeff4' : props.theme.blueAccent)};
  border-radius: 40px;
  width: 100%;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: ${(props) => props.theme.blueAccent};
    color: #efeff4;
  }
`;
