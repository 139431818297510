import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  H2,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import { ArrowLeft, SaveIconOutlined } from '../../../common/assets/icons';
import CustomSwitch from '../../../common/components/forms/formComponents/CusomSwitch';
import RenderRichText from '../../../common/components/RichEditor';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import { addArticle, editArticle, getArticleById } from '../../ducks/articles';
import { getAllCategories } from '../../ducks/categories';
import { CHAPTER_TOOLBAR_CONFIG } from '../../../common/constants/RichTextEditorConstants';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string(),
  description: Yup.string(),
  category: Yup.string(),
  isHidden: Yup.boolean(),
});

const EditArticle = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const query = new URLSearchParams(history.location.search);
  const articleId = query.get('articleId');
  const isEdit = !!articleId;

  const [isLoading, setIsLoading] = useState(true);

  const categories =
    useSelector((state) => state.admin.categories.categories) || [];
  const article = useSelector((state) => state.admin.articles.article) || {};

  const categoryOptions = categories.map(({ name, _id: id }) => ({
    value: id,
    label: name,
  }));
  const handleSubmitArticle = async (values) => {
    const payload = {
      ...(values.title !== '' ? { title: values.title } : {}),
      ...(values.subtitle !== '' ? { subtitle: values.subtitle } : {}),
      ...(values.description !== '' ? { description: values.description } : {}),
      ...(values.category !== '' ? { categoryId: values.category } : {}),
      ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
    };
    await dispatch(addArticle(payload));
    history.push('/admin/mrl?tab=articles');
  };

  const handleEditArticle = async (values) => {
    const payload = {
      ...(values.title !== '' ? { title: values.title } : {}),
      ...(values.subtitle !== '' ? { subtitle: values.subtitle } : {}),
      ...(values.description !== '' ? { description: values.description } : {}),
      ...(values.category !== '' ? { categoryId: values.category } : {}),
      ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
    };
    await dispatch(editArticle(articleId, payload));
    history.push('/admin/mrl?tab=articles');
  };

  const handleGetAllCategories = async () => {
    await dispatch(getAllCategories());
  };

  const handleGetArticle = async (articleId) => {
    await dispatch(getArticleById(articleId));
  };

  const defaultValues = {
    title: '',
    subtitle: '',
    description: '',
    category: '',
    isHidden: false,
  };

  const editValues = isEdit
    ? {
        title: article.title || '',
        subtitle: article.subtitle || '',
        description: article.description || '',
        category: article.categoryId || '',
        isHidden: !article.isVisible || false,
      }
    : {};

  const initialValues = { ...defaultValues, ...editValues };

  const fetchData = async () => {
    await handleGetAllCategories();
    if (articleId) {
      await handleGetArticle(articleId);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [articleId]);

  if (isLoading) {
    return <div />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
        }}
      >
        <Box
          onClick={history.goBack}
          sx={{
            borderBottom: '1px solid #74A3FE',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            paddingBottom: '10px',
            cursor: 'pointer',
          }}
        >
          <ArrowLeft />
          <H2>Inpoi la lista</H2>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',

          gap: '10px',
          justifyContent: 'center',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={isEdit ? handleEditArticle : handleSubmitArticle}
        >
          {({ handleSubmit, values }) => {
            const isSubmitDisabled = !values.title;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '10px',
                      }}
                    >
                      <H2>Articol</H2>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <CustomSwitch
                          id="isHidden"
                          labelRight="Ascunsa"
                          labelLeft="Vizibila"
                        />
                        <Box
                          sx={{
                            width: '1px',
                            backgroundColor: '#DEE7FC',
                            height: '32px',
                          }}
                        />
                        <Box sx={{ height: '32px' }}>
                          <PrimaryButton
                            disabled={isSubmitDisabled}
                            type="submit"
                            icon={SaveIconOutlined}
                          >
                            Salveaza
                          </PrimaryButton>
                        </Box>
                      </Box>
                    </Box>
                    <Grid sx={{ width: '100%', height: '100%' }}>
                      <CustomTextField id="title" label="Titlu.." />
                      <CustomTextField id="subtitle" label="Subtitlu.." />
                      <CustomSelectField
                        id="category"
                        placeholder="Alege categoria in care se incadreaza articolul"
                        label="Categorie"
                        options={categoryOptions}
                        disabled={!categoryOptions.length}
                      />
                      <RenderRichText
                        name="description"
                        label="Descriere"
                        height="60vh"
                        fullHeight
                        config={CHAPTER_TOOLBAR_CONFIG}
                      />
                      {/* TODO: foloseste unde trebuie facute taskurile */}
                      {/* <Box */}
                      {/*  sx={{ */}
                      {/*    border: `1px solid #74A3FE`, */}
                      {/*    borderRadius: '5px', */}
                      {/*    padding: '20px 40px', */}
                      {/*    display: 'flex', */}
                      {/*    justifyContent: 'space-between', */}
                      {/*  }} */}
                      {/* > */}
                      {/*  <Box */}
                      {/*    sx={{ */}
                      {/*      display: 'flex', */}
                      {/*      flexDirection: 'column', */}
                      {/*      gap: '10px', */}
                      {/*    }} */}
                      {/*  > */}
                      {/*    <H3>Taskuri:</H3> */}
                      {/*    <p faded> */}
                      {/*      Adauga un task facand click pe butonul din dreapta... */}
                      {/*    </p> */}
                      {/*  </Box> */}
                      {/*  <Box sx={{ height: '32px', alignSelf: 'flex-start' }}> */}
                      {/*    <PrimaryButton */}
                      {/*      // onClick={handleToggleAddDetailsModal} */}
                      {/*      icon={AddIcon} */}
                      {/*    > */}
                      {/*      Adauga un task */}
                      {/*    </PrimaryButton> */}
                      {/*  </Box> */}
                      {/* </Box> */}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditArticle;
