import { sortBy, map, each, get, size, reverse, find, some } from 'lodash-es';

const enhanceTeams = (teams) =>
  map(teams, (team) => {
    let validHypotheses = 0;
    let invalidHypotheses = 0;

    each(team.hypotheses, (hypothesis) => {
      if (
        some(
          hypothesis.resolutions,
          (resolution) =>
            resolution.outcome === 'invalidatedWithoutMVP' ||
            resolution.outcome === 'invalidatedWithMVP'
        )
      ) {
        invalidHypotheses++;
        return;
      }

      if (
        some(
          hypothesis.resolutions,
          (resolution) =>
            resolution.outcome === 'validatedWithoutMVP' ||
            resolution.outcome === 'validatedWithMVP'
        )
      ) {
        validHypotheses++;
      }
    });
    return {
      ...team,
      validatedHypotheses: validHypotheses,
      invalidatedHypotheses: invalidHypotheses,
      numberOfHypotheses: size(team.hypotheses),
      numberOfExperiments: size(team.experiments),
    };
  });

export const computeGeneralLeaderboard = (teams) => {
  const enhancedTeams = enhanceTeams(teams);
  return map(
    reverse(
      sortBy(enhancedTeams, [
        'irl',
        'validatedHypotheses',
        'numberOfHypotheses',
        'numberOfExperiments',
      ])
    ),
    (team, index) => ({ ...team, platformPosition: index + 1 })
  );
};

export const computeLeaderboardPosition = (teams, allTeams) => {
  const enhancedTeams = enhanceTeams(teams);
  return map(
    reverse(
      sortBy(enhancedTeams, [
        'irl',
        'validatedHypotheses',
        'numberOfHypotheses',
        'numberOfExperiments',
      ])
    ),
    (team, index) => ({
      ...team,
      cohortPosition: index + 1,
      platformPosition: get(
        find(allTeams, { _id: team._id }),
        'platformPosition'
      ),
    })
  );
};
