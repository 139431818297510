import React, { useMemo, useState } from 'react';
import { map, concat, size, get, find, reduce } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
} from '../../common/components/styled';
import { Export, SortTableHead } from '../../common/components';
import { BMC_OPTIONS } from '../../canvas/constants';
import { TitleButtonsDiv } from '../../mentors/components/styled';
import { useHistory } from 'react-router-dom';

const KPIPage = ({ hypotheses }) => {
  const { push } = useHistory();
  const kpis = useMemo(
    () =>
      reduce(
        hypotheses,
        (kpiOptions, hypothesis) => {
          const hypothesisKpis = map(hypothesis.KPIIndicators, (kpi) => ({
            title: kpi.title,
            hypothesisTitle: hypothesis.title,
            hypothesisId: hypothesis._id,
            value: kpi.targetValue,
            columnBMC: get(
              find(BMC_OPTIONS, { value: hypothesis.columnBMC }),
              'label',
              '-'
            ),
          }));

          const carry = concat(kpiOptions, hypothesisKpis);

          return carry;
        },
        []
      ),
    [hypotheses]
  );

  const [sortedKpis, setSortedKpis] = useState(kpis);

  const getDownloadData = useMemo(
    () =>
      map(sortedKpis, (kpi, index) => ({
        '#': index + 1,
        'Denumire indicator': kpi.title,
        'Valoare ținta': kpi.targetValue,
        Ipoteza: kpi.hypothesisTitle,
        'Secțiune BMC': kpi.columnBMC,
      })),
    [sortedKpis]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Indicatori</Title>
          <CounterDiv>
            <CounterText>{size(kpis)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export withMargin={false} data={getDownloadData} filename="kpi.csv" />
      </TitleButtonsDiv>

      <SortTableHead
        array={sortedKpis}
        setArray={setSortedKpis}
        cellSortKeys={[null, ['title']]}
      >
        <TableHeadCell alignLeft cellWidth="5%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="40%">
          <Heading2>Denumire indicator</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="15%">
          <Heading2>Valoare ținta</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="20%">
          <Heading2>Ipoteza</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="20%">
          <Heading2 center>Secțiune BMC</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(sortedKpis, (kpi, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="5%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="40%">
            <LinkTable onClick={() => push(`/hypothesis/${kpi.hypothesisId}`)}>
              {kpi.title}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="15%">
            <Heading4Regular>{kpi.value}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="20%">
            <LinkTable onClick={() => push(`/hypothesis/${kpi.hypothesisId}`)}>
              {kpi.hypothesisTitle}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="20%">
            <Heading4Regular>{kpi.columnBMC}</Heading4Regular>
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.hypotheses.isLoading,
  hypotheses: state.hypotheses.hypotheses,
});

export default withRouter(connect(mapStateToProps, null)(KPIPage));
