function generateRows(cellRows = [], columnHeaders) {
  const grouped = cellRows.reduce((acc, cur, idx) => {
    const {
      competitor: { name, _id: competitorId },
      criteria: { _id: criteriaId },
      value,
    } = cur;

    acc[name] = acc[name] || [
      {
        coordinates: { competitorId: columnHeaders[name]._id },
        value: name,
        disabled: false,
        action: 'editCompetitorName',
        id: columnHeaders[name]._id,
      },
    ];

    acc[name].push({
      coordinates: { competitorId, criteriaId },
      value: value || 0,
      disabled: false,
      id: `${cur.competitor.name}-${cur.criteria.name}-${idx}`,
    });
    return acc;
  }, {});

  Object.keys(grouped).forEach((key) => {
    let identifier = 0;
    while (grouped[key].length < 6) {
      grouped[key].push({
        coordinates: { competitorId: null, criteriaId: null },
        value: 0,
        disabled: true,
        id: `${key}-empty-${identifier}`,
      });
      identifier++;
    }
  });

  return Object.values(grouped);
}

function padRows(rows, count) {
  const lastRow = rows[rows.length - 1][0];

  while (rows.length < count) {
    const rowIndex = rows.length;
    const filler = [
      {
        value: 'Adauga un competitor',
        action: 'addCompetitor',
        coordinates: { competitorId: null, criteriaId: null },
        id: `criteria-null-${rowIndex}`,
      },
      { value: 0, disabled: true, id: `cell-post-${lastRow.id}-1` },
      { value: 0, disabled: true, id: `cell-post-${lastRow.id}-2` },
      { value: 0, disabled: true, id: `cell-post-${lastRow.id}-3` },
      { value: 0, disabled: true, id: `cell-post-${lastRow.id}-4` },
      { value: 0, disabled: true, id: `cell-post-${lastRow.id}-5` },
    ];

    rows.push(filler);
  }
}

export const generateFullCompetitionRows = (fullMetrics) => {
  const {
    competitorTableHeaders,
    competitionMetrics = [],
    competitors = {},
  } = fullMetrics;

  const rows = [competitorTableHeaders.filter(Boolean)];

  if (competitionMetrics.length > 0) {
    const result = generateRows(competitionMetrics, competitors);
    padRows(result, 5);
    rows.push(...result);
  } else {
    const { criteria } = fullMetrics;

    Object.values(criteria).forEach((criteria, rowIndex) => {
      const rowId = `row-${rowIndex}`;
      rows.push([
        {
          coordinates: { criteriaId: criteria._id },
          value: criteria.name,
          disabled: false,
          action: 'editCriteriaName',
          id: `${rowId}-criteria`,
        },
        { value: 0, disabled: true, id: `${rowId}-cell-1` },
        { value: 0, disabled: true, id: `${rowId}-cell-2` },
        { value: 0, disabled: true, id: `${rowId}-cell-3` },
        { value: 0, disabled: true, id: `${rowId}-cell-4` },
        { value: 0, disabled: true, id: `${rowId}-cell-5` },
      ]);
    });
    padRows(rows, 6);
  }

  return rows;
};

export const formatChartData = (inputData, metrics = {}) => {
  const { criteriaList = [] } = metrics;

  if (!Array.isArray(inputData) || !Array.isArray(inputData[0])) {
    return [];
  }

  const validRows = inputData.filter(
    (row) => row[0].value !== 'Adauga un competitor'
  );

  const finalData = criteriaList.reduce((acc, cur, idx) => {
    const rowName = cur;

    validRows.forEach((row, rowIndex) => {
      acc[rowName] = {
        ...acc[rowName],
        [`value-${rowIndex}`]: row[idx + 1].value,
      };
    });

    return acc;
  }, {});

  return Object.keys(finalData).map((key) => ({
    name: key,
    ...finalData[key],
  }));
};
