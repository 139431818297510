import { Box, Drawer, IconButton } from '@mui/material';
import React from 'react';
import {
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import {
  CrossIcon,
  PencilIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';
import { INDUSTRY_IMPACT_OPTIONS, INDUSTRY_TYPES_LABELS } from '../constants';

export const IndustryDrawer = ({
  selectedIndustry,
  isDrawerOpen,
  handleCloseDrawer,
  handleEditIndustry,
  handleDeleteIndustry,
}) => {
  let influence = '';
  if (selectedIndustry) {
    influence = INDUSTRY_IMPACT_OPTIONS.find(
      (option) => option.value === selectedIndustry.influence
    ).label;
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box sx={{ width: '400px', padding: '20px' }}>
        {selectedIndustry && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <P light>
                Vizualizare
                {
                  INDUSTRY_TYPES_LABELS[selectedIndustry.industryType].title
                }{' '}
              </P>
              <IconButton onClick={handleCloseDrawer}>
                <CrossIcon fill="blue" />
              </IconButton>
            </Box>
            <P marginBottom="20px">
              <b>Titlu:</b> {selectedIndustry.title}{' '}
            </P>
            <P marginBottom="20px">
              <b>Descriere:</b> {selectedIndustry.description}
            </P>
            <P marginBottom="20px">
              <b>Influenta asupra industriei:</b> {influence}
            </P>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <PrimaryButton
                onClick={() => handleEditIndustry(selectedIndustry)}
                inverted
                icon={PencilIcon}
                iconColor="#74A3FE"
                marginRight="10px"
              >
                Edit
              </PrimaryButton>
              <SecondaryButton
                onClick={() => handleDeleteIndustry(selectedIndustry)}
                icon={TrashIconOutlined}
              >
                Sterge
              </SecondaryButton>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};
