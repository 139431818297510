import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { DeleteButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import { useApi } from '../../hooks/useApi';
import {
  deleteConclusionById,
  getAllConclusionsByTeam,
} from '../../requests/conclusions';
import { ThumbsUp } from '../../common/assets/icons';
import {ClampedRichText} from "../../common/components/ClampedRichText";
import {ACTIVITY_CONCLUSION_SEGMENT_OPTIONS} from "../../hypotheses/constants/activities";

const ResolutionCell = ({ favorable, experimentType }) => {
  console.log('experimentType=', experimentType)
  const defaultStyle = {
    padding: 0,
    width: '20px',
    ...(!favorable && { transform: 'rotate(180deg)' }),
  };
  return (
    <Box sx={{ display: 'flex', gap: '7px' }}>
      <Box sx={defaultStyle}>
        <ThumbsUp width="20" height="20" fill={favorable ? 'green' : 'red'} />
      </Box>
      <Box>{experimentType}</Box>
    </Box>
  );
};

const ConclusionsComponent = () => {
  const dispatch = useDispatch();

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: teamConclusions }, fetchConclusions] = useApi(
    getAllConclusionsByTeam,
    []
  );

  console.log('teamConclusions=', teamConclusions)
  const [, removeConclusions] = useApi(deleteConclusionById);

  const handleFetchConclusions = () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };
    fetchConclusions(payload);
  };

  const handleDeleteConclusion = async (id) => {
    const payload = {
      params: { conclusionId: id },
    };
    await removeConclusions(payload);
    await handleFetchConclusions();
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: 'Concluzie', key: 'title' },
    { type: 'string', content: 'Observatii', key: 'description' },
    { type: 'string', content: 'Rezolutie', key: 'status' },
    // { type: 'string', content: 'Cale', key: 'path' },
    ...(teamConclusions.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const renderExperimentType = (experimentType) => {
    return ACTIVITY_CONCLUSION_SEGMENT_OPTIONS.find((option) => option.value === experimentType)?.label
  }

  const createRows = () =>
    teamConclusions.map(
      ({ _id, title, description, status, experimentType }, index) => ({
        rowId: _id,
        order: index + 1,
        title : (
          <Box>
            <ClampedRichText text={title} lineClamp={1} />
          </Box>
        ),
        description: (
          <Box>
            <ClampedRichText text={description} lineClamp={1} />
          </Box>
        ),
        status: (
          <ResolutionCell
            favorable={status !== 'negative'}
            experimentType={renderExperimentType(experimentType)}
          />
        ),
        action: (
          <DeleteButtonComponent
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteConclusion(_id);
            }}
          />
        ),
      })
    );



  useEffect(() => {
    if (teamId) {
      handleFetchConclusions();
    }
  }, []);
  return (
    <PageContainer className="scrollbar">
      {teamConclusions && !!teamConclusions.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            handleSelectedRow={() => {}}
          />
        </Grid>
      ) : (
        <GenericEmptyTable
          textColor="#74A3FE"
          headers={createTableHeaderCells()}
          detailMessage="Niciuo concluzie nu a fost adaugata..."
        />
      )}
    </PageContainer>
  );
};

export default ConclusionsComponent;
