// Context Analysis
const commentTypeContextTrends = 'contextTrends';
const commentTypeContextIndustry = 'contextIndustry';
const commentTypeContextCompetition = 'contextCompetition';
const commentTypeContextSwot = 'contextSwot';
const commentTypeContextDetails = 'contextDetails';
const commentTypeContextConclusion = 'contextConclusion';

// BMC
export const commentTypeBmc = 'bmc';

// Hypothesis
const commentTypeHypothesisDescription = 'hypothesisDescription';
const commentTypeHypothesisProfile = 'hypothesisProfile';
const commentTypeHypothesisActivities = 'hypothesisActivities';
const commentTypeHypothesisDetails = 'hypothesisDetails';

export const HYPOTHESIS_COMMENTS_TYPES_BY_TAB = {
  description: commentTypeHypothesisDescription,
  profile: commentTypeHypothesisProfile,
  activities: commentTypeHypothesisActivities,
  details: commentTypeHypothesisDetails,
};

export const CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB = {
  trends: commentTypeContextTrends,
  industry: commentTypeContextIndustry,
  competition: commentTypeContextCompetition,
  swot: commentTypeContextSwot,
  details: commentTypeContextDetails,
  conclusions: commentTypeContextConclusion,
};

export const COMMENTS_TABLE_HEADERS = [
  { type: 'string', content: '#', hideSortIcon: true },
  { type: 'string', content: 'Comentariu', ket: 'comment', hideSortIcon: true },
  { type: 'string', content: 'Cale', key: 'cale', hideSortIcon: true },
  { type: 'string', content: 'Raspunsuri', key: 'nrOfReplies' },
  { type: 'string', content: 'Responsabil', key: 'user', hideSortIcon: true },
  { type: 'string', content: 'Data', key: 'createdAt' },
  { type: 'string', content: 'Status', key: 'status' },
  { type: 'string', content: '', hideSortIcon: true },
];

export const COMMENT_ROUTES_BY_TYPE = {
  [commentTypeContextTrends]: 'opportunity-analysis?tab=trends',
  [commentTypeContextIndustry]: 'opportunity-analysis?tab=industry',
  [commentTypeContextCompetition]: 'opportunity-analysis?tab=competition',
  [commentTypeContextSwot]: 'opportunity-analysis?tab=swot',
  [commentTypeContextDetails]: 'opportunity-analysis?tab=details',
  [commentTypeContextConclusion]: 'opportunity-analysis?tab=conclusions',
  [commentTypeBmc]: 'canvas',
  [commentTypeHypothesisDescription]: 'hypothesis/:entityId?tab=description',
  [commentTypeHypothesisProfile]: 'hypothesis/:entityId?tab=profile',
  [commentTypeHypothesisActivities]: 'hypothesis/:entityId?tab=activities',
  [commentTypeHypothesisDetails]: 'hypothesis/:entityId?tab=details',
};

export const READABLE_ROUTES_BY_TYPE = {
  [commentTypeContextTrends]: 'Analiza contextului/Trenduri',
  [commentTypeContextIndustry]: 'Analiza contextului/Industrie',
  [commentTypeContextCompetition]: 'Analiza contextului/Competitie',
  [commentTypeContextSwot]: 'Analiza contextului/SWOT',
  [commentTypeContextDetails]: 'Analiza contextului/Detalii',
  [commentTypeContextConclusion]: 'Analiza contextului/Concluzii',
  [commentTypeBmc]: 'BMC',
  [commentTypeHypothesisDescription]: 'Ipoteza/Descriere',
  [commentTypeHypothesisProfile]: 'Ipoteza/Profil',
  [commentTypeHypothesisActivities]: 'Ipoteza/Activitati',
  [commentTypeHypothesisDetails]: 'Ipoteza/Detalii',
};
