import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';
import { ReactComponent as LinkIconSvg } from '../../assets/icons/Link.svg';

export const LinkIcon = styled(LinkIconSvg)`
  float: right;
  position: absolute;
  right: 23px;
  top: 10px;
  z-index: 100;
`;

export const LinkInput = styled(Form.Control)`
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding-right: 45px;
`;

export const PositionParent = styled.div`
  position: relative;
  width: ${(props) => props.width};
`;

export const DetailIcon = styled.div`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    props.icon &&
    css`
      background-image: url(${props.icon});
    `}
`;

export const ProfileTabIcon = styled.div`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    props.icon &&
    css`
      background-image: url(${props.icon});
      background-size: contain;
    `}
`;

export const ProfileTabSvgIcon = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
