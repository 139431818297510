import ModalComponent from '../../common/components/ModalComponent';

import React from 'react';
import ActivityConclusionForm from './ActivityConclusionForm';

const AddActivityConclusionModal = ({
  handleClose,
  isModalOpen,
  handleSubmit,
  formValues,
  handleUpdate,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={handleClose}
    title="Adauga o concluzie"
    size="md"
  >
    <ActivityConclusionForm
      handleUpdate={handleUpdate}
      formValues={formValues}
      handleSubmit={handleSubmit}
    />
  </ModalComponent>
);

export default AddActivityConclusionModal;
