import React from 'react';
import { get } from 'lodash-es';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createTeam, editTeam, getTeam } from '../../ducks';
import { loadUserTeams, currentTeamIdSelector } from '../../../common/ducks';
import { CreateTeamForm } from '../forms';
import ModalComponent from '../../../common/components/ModalComponent';

const CreateTeamModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    createTeam,
    loadUserTeams,
    userId,
    isEditMode,
    team,
    teamId,
    editTeam,
    getTeam,
    setEditedTeam,
  } = props;
  const onSubmit = async (values) => {
    if (isEditMode) {
      await editTeam(teamId, { ...team, ...values }, userId);
      setIsModalOpen(false);
      await getTeam(teamId);
    } else {
      await createTeam(values);
      setIsModalOpen(false);
      await loadUserTeams(userId);
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditedTeam(null);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title={isEditMode ? 'Editeaza ideea' : 'Adauga o idee noua'}
    >
      <CreateTeamForm onSubmit={onSubmit} team={team} isEditMode={isEditMode} />
    </ModalComponent>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { createTeam, loadUserTeams, editTeam, getTeam };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateTeamModal)
);
