import { Box, Popper } from '@mui/material';
import React, { useState } from 'react';
import { H1 } from '../../common/components/styled/ReusableTextComponents';
import { AddButtonComponent, FilterButtonComponent } from './Buttons';
import { TrendFilters } from './TrendFilters';

const TrendsPageHeader = ({
  selectedFilters,
  handleCheck,
  openAddTrendModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleToggleFilters = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'simple-popper' : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '45px',
        alignItems: 'center',
      }}
    >
      <H1>Trenduri</H1>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <FilterButtonComponent
            handleClick={handleToggleFilters}
            selectedFilters={selectedFilters}
          />

          <Popper id={popperId} open={popperOpen} anchorEl={anchorEl}>
            <TrendFilters
              handleCheck={handleCheck}
              selectedFilters={selectedFilters}
            />
          </Popper>
        </div>

        <AddButtonComponent
          id="context-analysis-4"
          onClick={openAddTrendModal}
        />
      </Box>
    </Box>
  );
};

export default TrendsPageHeader;
