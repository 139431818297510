import React from 'react';
import { useField } from 'formik';
import { Box, Grid, Switch } from '@mui/material';
import { P } from '../../styled/ReusableTextComponents';

const CustomSwitch = ({
  labelRight,
  labelLeft,
  id,
  md = 12,
  uncheckedColor,
  checkedColor,
}) => {
  const [field, meta] = useField(id);

  const handleChange = () => {
    field.onChange({
      target: {
        name: field.name,
        value: !field.value,
      },
    });
  };

  return (
    <Grid item xs={12} md={md} sx={{ display: 'flex', alignItems: 'center' }}>
      <P faded={field.value}>{labelLeft}</P>
      <Box
        sx={{
          '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: checkedColor || '#74A3FE',
          },
          '& .MuiSwitch-track': {
            backgroundColor: uncheckedColor || '#ccc',
          },
        }}
      >
        <Switch
          checked={field.value}
          onChange={handleChange}
          name={field.name}
          disableRipple
          color="secondary"
        />
      </Box>
      <P faded={!field.value}>{labelRight}</P>
    </Grid>
  );
};

export default CustomSwitch;
