import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { register, resetRegister } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel } from '../styled';
import { RegisterForm } from '../forms';
import { Title, WhiteCard } from '../../../common/components/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { getInvitedMemberData } from '../../../requests/registration';
import { Spinner } from 'react-bootstrap';
import { P } from '../../../common/components/styled/ReusableTextComponents';

const RegisterPage = (props) => {
  const { isSubmitting, resetRegister, register } = props;
  useEffect(() => () => resetRegister(), [resetRegister]);
  const history = useHistory();
  const [{ result: invitedUserData, isLoading }, runGetInvitedMemberData] =
    useApi(getInvitedMemberData, null);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('invitedUser');

  const getInvitedUserData = () => {
    if (token) {
      runGetInvitedMemberData({
        params: {
          memberToken: token,
        },
      });
    }
  };

  useEffect(() => {
    getInvitedUserData();
  }, []);

  const onSubmit = (data) => {
    register(data, '/', history);
  };

  const pageText = () => {
    if (token) {
      return 'Bine ai venit, ai fost sa te alaturi unei echipe, Înregistrează-te!';
    }
    return 'Bine ai venit! Înregistrează-te!';
  };

  return (
    <>
      <DocumentTitle>Register</DocumentTitle>
      <AccountPage register>
        <AccountPanel size="small" isLoading={isSubmitting}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <P
              style={{
                fontSize: '22px',
                textAlign: 'center',
                lineHeight: '30px',
              }}
            >
              {pageText()}
            </P>
          </div>
          <WhiteCard borderRadius="10px" height="100%">
            {isLoading ? (
              <Spinner />
            ) : (
              <RegisterForm
                invitedUserData={invitedUserData}
                memberToken={token}
                onSubmit={onSubmit}
              />
            )}
          </WhiteCard>
        </AccountPanel>
      </AccountPage>
    </>
  );
};

RegisterPage.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  resetRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.account.register.isSubmitting,
  resetRegister: state.account.register.resetRegister,
});

const mapDispatchToProps = {
  register,
  resetRegister,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
);
