import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  loadProfile,
  loadHypothesesPercentage,
  loadExperimentsPercentage,
} from '../../ducks';

import { getAllSubscriptionProducts } from '../../ducks/userProfile';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { currentTeamIdSelector } from '../../../common/ducks';
import ProfilePage from './ProfilePage';

const ProfilePageResolver = (props) => {
  const {
    loadProfile,
    loadExperimentsPercentage,
    loadHypothesesPercentage,
    teamId,
    role,
    getAllSubscriptionProducts,
  } = props;

  const loadDependencies = async () => {
    await loadProfile();

    if (teamId && role === 'FOUNDER') {
      Promise.all([
        loadExperimentsPercentage(teamId),
        loadHypothesesPercentage(teamId),
        getAllSubscriptionProducts(),
      ]);
    } else {
      Promise.resolve();
    }
  };

  return (
    <>
      <DocumentTitle>Profil</DocumentTitle>
      <Resolver
        successComponent={ProfilePage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

ProfilePageResolver.propTypes = {
  loadProfile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  role: get(state.account.login, 'user.role'),
});

const mapDispatchToProps = {
  loadProfile,
  loadExperimentsPercentage,
  loadHypothesesPercentage,
  getAllSubscriptionProducts,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePageResolver)
);
