const monthNamesRomanian = [
  'ianuarie',
  'februarie',
  'martie',
  'aprilie',
  'mai',
  'iunie',
  'iulie',
  'august',
  'septembrie',
  'octombrie',
  'noiembrie',
  'decembrie',
];

const getRomanianMonthName = (monthNumber) => monthNamesRomanian[monthNumber];

export const formatTimestampInRomanian = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds

  const day = date.getDate();
  const monthNumber = date.getMonth();
  const year = date.getFullYear();
  const monthName = getRomanianMonthName(monthNumber);

  return `${day} ${monthName} ${year}`;
};

export const formatDate = (inputDateString) => {
  if (!inputDateString) {
    return '';
  }

  const date = new Date(inputDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
