import Box from '@mui/material/Box';
import React, { useState, useRef } from 'react';
import { ClickAwayListener, Drawer, IconButton } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Assets
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CrossIcon, UnreadNotificationIcon } from '../../common/assets/icons';
import { COMMENT_TOOLBAR_CONFIG } from '../../common/constants/RichTextEditorConstants';

// Components
import RenderRichText from '../../common/components/RichEditor';
import {
  PrimaryButton,
  SPAN,
} from '../../common/components/styled/ReusableTextComponents';
import CommentAndRepliesList from './CommentAndRepliesList';
import { CustomDrawer, OrangeCircle } from './styled/Comments';
import {
  CenteredBox,
  FlexEndBox,
  SpaceBetweenBox,
} from '../../common/components/styled/ReusablePositioningComponents';
import {
  deleteCommentById,
  editCommentById,
  getAllComments,
  postNewComment,
} from '../ducks';
import CommentsHeader from './CommentsHeadder';

const GenericComments = ({
  teamId,
  userId,
  type,
  entityId = null,
  comments = [],
  fetchComments,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const query = new URLSearchParams(useLocation().search);
  const queryCommentId = query.get('comment') || '';
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(
    !!queryCommentId
  );
  const [commentToReplyTo, setCommentToReplyTo] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [edditedReply, setEdditedReply] = useState(null);

  const hasUnreadMessages = !!comments.find(
    (comment) => comment.unread || comment.unreadReplies
  );
  const [showRichText, setShowRichText] = useState(false);

  const fetchFullComments = () => {
    dispatch(
      getAllComments({
        teamId,
        filterParams: {
          isMainThread: true,
        },
        userId,
      })
    );
  };

  const handleFetchComments = async () => {
    await fetchComments();
    fetchFullComments();
  };

  const generateRichTextEditorTitle = () => {
    if (commentToReplyTo) {
      return 'Răspunde la comentariu';
    }

    if (editingComment) {
      return 'Editează comentariul';
    }

    if (edditedReply) {
      return 'Editează răspunsul';
    }

    return 'Adaugă un comentariu';
  };

  const schema = Yup.object().shape({
    description: Yup.string().required('Câmp obligatoriu'),
  });

  const initialValues = {
    description: '',
  };

  const handleAbort = () => {
    setEditingComment(null);
    setCommentToReplyTo(null);
    setEdditedReply(null);
    inputRef.current.clearEditor();
  };

  const handleClickOutside = () => {
    if (showRichText) {
      handleAbort();
    }
  };

  const handleHideRichText = () => {
    setShowRichText(false);
  };

  const handleAddNewComment = () => {
    setShowRichText(true);
    inputRef.current.focusEditor();
  };

  const handleSubmit = (values) => {
    if (edditedReply) {
      dispatch(
        editCommentById({
          commentId: edditedReply._id,
          comment: values.description,
          teamId,
          type,
          userId,
          ...(entityId && { entityId }),
        })
      );

      setEdditedReply(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    if (editingComment) {
      dispatch(
        editCommentById({
          commentId: editingComment._id,
          comment: values.description,
          teamId,
          type,
          userId,
          ...(entityId && { entityId }),
        })
      );

      setEditingComment(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    if (commentToReplyTo) {
      dispatch(
        postNewComment({
          message: values.description,
          teamId,
          type,
          userId,
          parentId: commentToReplyTo._id,
          ...(entityId && { entityId }),
        })
      );

      setCommentToReplyTo(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    dispatch(
      postNewComment({
        message: values.description,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );

    inputRef.current.clearEditor();
    setShowRichText(false);
  };

  const handleReply = (e, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setCommentToReplyTo(comment);
    inputRef.current.clearEditor();
    inputRef.current.focusEditor();
  };

  const handleDeleteComment = (e, commentId) => {
    e.stopPropagation();
    dispatch(
      deleteCommentById({
        commentId,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );
  };

  const handleDeleteReply = (e, replyId) => {
    e.stopPropagation();
    dispatch(
      deleteCommentById({
        commentId: replyId,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );
  };

  const handleEditComment = (e, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setEdditedReply(null);
    setCommentToReplyTo(null);
    setEditingComment(comment);

    inputRef.current.setContentAndFocus(comment.title);
  };

  const handleEditReply = (e, reply, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setCommentToReplyTo(null);
    setEditingComment(null);
    setEdditedReply({ ...reply, hypothesisCommentId: comment._id });
    inputRef.current.setContentAndFocus(reply.title);
  };

  return (
    <>
      <OrangeCircle onClick={() => setNotificationDrawerOpen(true)}>
        <UnreadNotificationIcon hasUnreadMessages={hasUnreadMessages} />
      </OrangeCircle>

      <Drawer
        anchor="right"
        open={notificationDrawerOpen}
        onClose={() => {
          setNotificationDrawerOpen(false);
        }}
      >
        <CustomDrawer>
          <Box>
            <CommentsHeader
              commentsLength={comments.length}
              onClose={() => setNotificationDrawerOpen(false)}
            />
          </Box>
          <Box
            sx={{
              height: '96%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              className="scrollbar"
              sx={{ height: '100%', padding: '5px', overflowY: 'scroll' }}
            >
              {!!comments.length && (
                <CommentAndRepliesList
                  fetchComments={handleFetchComments}
                  queryCommentId={queryCommentId}
                  commentsArray={comments}
                  handleDeleteComment={handleDeleteComment}
                  handleEditComment={handleEditComment}
                  handleReply={handleReply}
                  handleDeleteReply={handleDeleteReply}
                  handleEditReply={handleEditReply}
                />
              )}
            </Box>
            <ClickAwayListener onClickAway={handleClickOutside}>
              <Box
                sx={{
                  padding: '10px',
                  display: showRichText ? 'block' : 'none',
                }}
              >
                <Box>
                  <SpaceBetweenBox marginBottom="10px">
                    <SPAN>{generateRichTextEditorTitle()}</SPAN>
                    <IconButton onClick={handleHideRichText}>
                      <CrossIcon fill="blue" />
                    </IconButton>
                  </SpaceBetweenBox>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                  >
                    {({ values }) => (
                      <RenderRichText
                        show={showRichText}
                        name="description"
                        config={COMMENT_TOOLBAR_CONFIG}
                        ref={inputRef}
                        height="260px"
                        positionToolbarBottom
                      >
                        <FlexEndBox height="40px" padding="5px">
                          <PrimaryButton
                            type="button"
                            onClick={() => handleSubmit(values)}
                            marginLeft="10px"
                          >
                            Trimite ➔
                          </PrimaryButton>
                        </FlexEndBox>
                      </RenderRichText>
                    )}
                  </Formik>
                </Box>
              </Box>
            </ClickAwayListener>

            <Box
              sx={{
                display: showRichText ? 'none' : 'flex',
              }}
            >
              <CenteredBox height="55px" padding="5px">
                <PrimaryButton onClick={handleAddNewComment}>
                  Adauga un comentariu nou
                </PrimaryButton>
              </CenteredBox>
            </Box>
          </Box>
        </CustomDrawer>
      </Drawer>
    </>
  );
};

export default GenericComments;
