import { combineReducers } from 'redux';
import { reducer as usersReducer } from './users';
import { reducer as resourcesReducer } from './resources';
import { reducer as articlesReducer } from './articles';
import { reducer as categoriesReducer } from './categories';

export { loadUsers, deleteUser, deleteUserProfile } from './users';
export {
  loadResources,
  loadResourcesBySection,
  deleteResource,
  addResource,
  editResource,
  reorderResources,
} from './resources';

export const reducer = combineReducers({
  users: usersReducer,
  resources: resourcesReducer,
  articles: articlesReducer,
  categories: categoriesReducer,
});
