import { Box } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import ChatGptForm from './ChatGptForm';
import ChatGptMessagesList from './ChatGptMessagesList';
import {
  ChatMessagesListContainer,
  ChatTextFieldContainer,
} from './styled/chatMessages';

// Helpers
import { useApi } from '../../hooks/useApi';
import { postOpenAiConversation } from '../../requests/openAI';
import { updateChatSessionState } from '../helpers';

const ChatBotMessagesScreen = () => {
  const messagesEndRef = useRef(null);
  const [chatSession, setChatSession] = useState({
    sessionId: null,
    timestamp: null,
    messages: [],
  });
  const [{ isLoading }, postChatGptConversation] = useApi(
    postOpenAiConversation
  );

  const currentUserId = useSelector(
    (state) => state.userProfile?.userProfile?._id
  );

  const resetChatSession = () => {
    setChatSession({
      sessionId: null,
      timestamp: null,
      messages: [],
    });
    sessionStorage.removeItem('chatSession');
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (!values.message) return;

    await updateChatSessionState({
      chatSession,
      setChatSession,
      resetForm,
      currentUserId,
      postChatGptConversation,
      userMessage: values.message,
    });
  };

  // When a new message appears we will scroll to the bottom of the chat
  useLayoutEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [chatSession, isLoading]);

  // Save the chat session in the session storage
  useEffect(() => {
    if (!chatSession || chatSession.messages.length === 0) return;

    sessionStorage.setItem('chatSession', JSON.stringify(chatSession));
  }, [chatSession]);

  // Load the chat session from the session storage
  useEffect(() => {
    const storedSession = sessionStorage.getItem('chatSession');

    if (storedSession) {
      setChatSession(JSON.parse(storedSession));
    }
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <ChatMessagesListContainer className="scrollbar" ref={messagesEndRef}>
        <ChatGptMessagesList
          messages={chatSession?.messages}
          isChatLoading={isLoading}
        />
      </ChatMessagesListContainer>

      <ChatTextFieldContainer>
        <ChatGptForm
          handleSubmit={handleSubmit}
          resetSession={resetChatSession}
        />
      </ChatTextFieldContainer>
    </Box>
  );
};

export default ChatBotMessagesScreen;
