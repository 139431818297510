import React from 'react';
import { ReactComponent as UploadIcon } from '../assets/icons/Upload.svg';

import {
  AvatarImageContainer,
  AvatarImagePlaceholder,
  AvatarIconPlaceholder,
} from './styled';

const AvatarImage = ({
  src,
  large = false,
  medium = false,
  small = false,
  upload,
  width,
  height,
}) => (
  <>
    {src ? (
      <AvatarImageContainer
        width={width}
        height={height}
        large={large}
        medium={medium}
        backgroundUrl={src}
      />
    ) : (
      <AvatarImagePlaceholder height={height} width={width}>
        <AvatarIconPlaceholder>
          {upload ? <UploadIcon /> : 'N/A'}
        </AvatarIconPlaceholder>
      </AvatarImagePlaceholder>
    )}
  </>
);

export default AvatarImage;
