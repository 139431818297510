import styled, { css } from 'styled-components';

export const GridUnresponsive = styled.div`
  width: 100%;

  ${(props) =>
    props.padded &&
    css`
      padding: 0 15px;
    `}

  .row {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;

  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

    
  ${(props) =>
    props.columnGap &&
    css`
      column-gap: ${props.columnGap};
    `}
`;

export const GridElement = styled.div`
  ${({ row, column }) =>
    css`
      grid-row: ${row};
      grid-column: ${column};
    `}
`;
