import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Container, TrendCard } from './styled/ContextAnalysis';
import {
  EyeIcon,
  PencilIcon,
  TrashIconOutlined,
  UpwardsTrend,
} from '../../common/assets/icons';
import { SPAN } from '../../common/components/styled/ReusableTextComponents';
import { ClampedText } from '../../common/components/ClampedText';
import { ClampedRichText } from '../../common/components/ClampedRichText';

export const TrendCardComponent = ({
  trend,
  handleViewTrend,
  handleEditTrend,
  deleteTrendById,
}) => (
  <TrendCard>
    <Container>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{ ...(!trend.isTrendFavorable && { transform: 'scaleY(-1)' }) }}
        >
          <UpwardsTrend fill={trend.isTrendFavorable ? 'green' : 'red'} />
        </Box>
        <Box
          sx={{ color: '#2A5AB7', wordBreak: 'break-all', marginLeft: '5px' }}
        >
          <ClampedText text={trend.title} />
        </Box>
      </Box>
      <Box sx={{ opacity: 0, transition: 'opacity 0.3s', display: 'flex' }}>
        <IconButton onClick={() => handleViewTrend(trend)}>
          <EyeIcon fill="#74A3FE" />
        </IconButton>
        <IconButton onClick={() => handleEditTrend(trend)}>
          <PencilIcon fill="#74A3FE" />
        </IconButton>
        <IconButton onClick={() => deleteTrendById(trend._id)}>
          <TrashIconOutlined fill="red" />
        </IconButton>
      </Box>
    </Container>
    <SPAN faded>
      <ClampedRichText lineClamp={4} text={trend.description} />
    </SPAN>
  </TrendCard>
);
