import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import {
  Input,
  SubmitButton,
  ButtonDiv,
  Heading2,
  SaveIcon,
} from '../../../common/components/styled';

const RenderEquityFiled = (props) => {
  const { values, handleChange, name, placeholder, controlId, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type="text"
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderEquityFiled
        name="equity"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Equity"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddEquityForm = (props) => {
  const { onSubmit, user } = props;
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    equity: Yup.number()
      .typeError('Trebuie sa fie un numãr')
      .min(1, 'Trebuie sa fie mai mare sau egal cu 1')
      .max(100, 'Trebuie sa fie mai mic sau egal cu 100')
      .required('Câmp obligatoriu'),
  });

  const initialValues = {
    equity: user.equity ? user.equity : '',
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default AddEquityForm;
