import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Box, IconButton } from '@mui/material';
import CustomTextFieldWithButton from '../../common/components/forms/formComponents/CustomTextFieldWithButton';
import { TrashIconOutlined } from '../../common/assets/icons';

const ChatGptForm = ({ handleSubmit, resetSession }) => {
  const initialValues = {
    message: '',
  };
  const schema = Yup.object().shape({
    message: Yup.string(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingBottom: '5px',
            }}
          >
            <IconButton onClick={resetSession}>
              <TrashIconOutlined fill="#BA3232" />
            </IconButton>
          </Box>
          <CustomTextFieldWithButton
            id="message"
            rows={3}
            multiline
            buttonText="Trimite ➔"
          />
        </form>
      )}
    </Formik>
  );
};

export default ChatGptForm;
