import { useField } from 'formik';
import { Autocomplete, Grid, TextField } from '@mui/material';
import React from 'react';

const CustomSelectField = ({
  label,
  id,
  options,
  md = 12,
  disabled,
  customStyles,
  onSelect,
}) => {
  const [field, meta, helpers] = useField(id);
  const selectedOption = options.find((option) => option.value === field.value);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <Autocomplete
        disabled={disabled}
        id={id}
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedOption || null}
        sx={{ ...customStyles?.autocompleteStyles }}
        ListboxProps={{
          className: 'scrollbar',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            fullWidth
            disabled={disabled}
            classes={{
              padding: 0,
            }}
            sx={{
              ...customStyles?.textFieldStyles,
            }}
          />
        )}
        onChange={(event, newValue) => {
          helpers.setValue(newValue ? newValue.value : '');
          onSelect && onSelect(newValue.value);
        }}
      />
    </Grid>
  );
};

export default CustomSelectField;
