import update from 'immutability-helper';
import {
  addResolutionComment as doAddResolutionComment,
  editResolutionComment as doEditResolutionComment,
  deleteResolutionComment as doDeleteResolutionComment,
  addResolutionCommentReply as doAddResolutionCommentReply,
  deleteResolutionCommentReply as doDeleteResolutionCommentReply,
  editResolutionCommentReply as doEditResolutionCommentReply,
  addResolution as doAddResolution,
  getResolution as doGetResolution,
  editResolution as doEditResolution,
  deleteResolution as doDeleteResolution,
  addExperimentResolutionComment as doAddExperimentResolutionComment,
  editExperimentResolutionComment as doEditExperimentResolutionComment,
  deleteExperimentResolutionComment as doDeleteExperimentResolutionComment,
  addExperimentResolutionCommentReply as doAddExperimentResolutionCommentReply,
  deleteExperimentResolutionCommentReply as doDeleteExperimentResolutionCommentReply,
  editExperimentResolutionCommentReply as doEditExperimentResolutionCommentReply,
  addExperimentResolution as doAddExperimentResolution,
  getExperimentResolution as doGetExperimentResolution,
  editExperimentResolution as doEditExperimentResolution,
  deleteExperimentResolution as doDeleteExperimentResolution,
} from '../services';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';

// Actions

const START_ADD_COMMENT = 'resolution/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'resolution/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'resolution/FAIL_ADD_COMMENT';

const START_EDIT_COMMENT = 'resolution/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'resolution/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'resolution/FAIL_EDIT_COMMENT';

const START_DELETE_COMMENT = 'resolution/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'resolution/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'resolution/FAIL_DELETE_COMMENT';

const START_ADD_REPLY = 'resolution/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'resolution/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'resolution/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'resolution/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'resolution/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'resolution/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'resolution/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'resolution/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'resolution/FAIL_DELETE_REPLY';

const START_LOAD_RESOLUTION = 'resolution/START_LOAD_RESOLUTION';
const COMPLETE_LOAD_RESOLUTION = 'resolution/COMPLETE_LOAD_RESOLUTION';
const FAIL_LOAD_RESOLUTION = 'resolution/FAIL_LOAD_RESOLUTION';

const START_ADD_RESOLUTION = 'resolution/START_ADD_RESOLUTION';
const COMPLETE_ADD_RESOLUTION = 'resolution/COMPLETE_ADD_RESOLUTION';
const FAIL_ADD_RESOLUTION = 'resolution/FAIL_ADD_RESOLUTION';

const START_EDIT_RESOLUTION = 'resolution/START_EDIT_RESOLUTION';
const COMPLETE_EDIT_RESOLUTION = 'resolution/COMPLETE_EDIT_RESOLUTION';
const FAIL_EDIT_RESOLUTION = 'resolution/FAIL_EDIT_RESOLUTION';

const START_DELETE_RESOLUTION = 'resolution/START_DELETE_RESOLUTION';
const COMPLETE_DELETE_RESOLUTION = 'resolution/COMPLETE_DELETE_RESOLUTION';
const FAIL_DELETE_RESOLUTION = 'resolution/FAIL_DELETE_RESOLUTION';

const RESET = 'resolution/RESET';

// Initial state
const initialState = {
  resolution: undefined,
  isLoading: false,
  isDeleting: false,
  isEditing: false,
  isCreating: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_RESOLUTION:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RESOLUTION:
      return update(state, {
        $merge: {
          isLoading: false,
          resolution: action.resolution,
        },
      });

    case FAIL_LOAD_RESOLUTION:
      return update(state, {
        $merge: {
          isLoading: false,
          resolution: undefined,
        },
      });

    case START_EDIT_RESOLUTION:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT_RESOLUTION:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT_RESOLUTION:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case START_DELETE_RESOLUTION:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE_RESOLUTION:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE_RESOLUTION:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_ADD_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD_RESOLUTION:
      return update(state, { $merge: { resolution: { isCreating: true } } });

    case COMPLETE_ADD_RESOLUTION:
      return update(state, { $merge: { resolution: { isCreating: false } } });

    case FAIL_ADD_RESOLUTION:
      return update(state, { $merge: { resolution: { isCreating: false } } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

const startAddResolution = () => ({
  type: START_ADD_RESOLUTION,
});

const completeAddResolution = () => ({
  type: COMPLETE_ADD_RESOLUTION,
});

const failAddResolution = () => ({
  type: FAIL_ADD_RESOLUTION,
});

const startLoadResolution = () => ({
  type: START_LOAD_RESOLUTION,
});

const completeLoadResolution = (resolution) => ({
  type: COMPLETE_LOAD_RESOLUTION,
  resolution,
});

const failLoadResolution = () => ({
  type: FAIL_LOAD_RESOLUTION,
});

const startEditResolution = () => ({
  type: START_EDIT_RESOLUTION,
});

const completeEditResolution = () => ({
  type: COMPLETE_EDIT_RESOLUTION,
});

const failEditResolution = () => ({
  type: FAIL_EDIT_RESOLUTION,
});

const startDeleteResolution = () => ({
  type: START_DELETE_RESOLUTION,
});

const completeDeleteResolution = () => ({
  type: COMPLETE_DELETE_RESOLUTION,
});

const failDeleteResolution = () => ({
  type: FAIL_DELETE_RESOLUTION,
});

export const addResolution = (values) => (dispatch) => {
  dispatch(startAddResolution());
  return doAddResolution(values)
    .then(() => dispatch(completeAddResolution()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddResolution());
    });
};

export const editResolution = (values) => (dispatch) => {
  dispatch(startEditResolution());
  return doEditResolution(values)
    .then(() => dispatch(completeEditResolution()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditResolution());
    });
};

export const loadResolution = (resolutionId) => (dispatch) => {
  dispatch(startLoadResolution());
  return doGetResolution(resolutionId)
    .then((res) => dispatch(completeLoadResolution(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadResolution());
    });
};

export const deleteResolution = (resolutionId) => (dispatch) => {
  dispatch(startDeleteResolution());
  return doDeleteResolution(resolutionId)
    .then((res) => dispatch(completeDeleteResolution(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDeleteResolution());
    });
};

export const addResolutionComment = (values, resolutionId) => (dispatch) => {
  dispatch(startAddComment());
  const addResolutionCommentPromise = doAddResolutionComment(
    values,
    resolutionId
  );
  addResolutionCommentPromise
    .then(() => {
      dispatch(completeAddComment());
      dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddComment());
    });
  return addResolutionCommentPromise;
};

export const deleteResolutionComment = (resolutionId) => (dispatch) => {
  dispatch(startDeleteComment());
  const deleteResolutionCommentPromise =
    doDeleteResolutionComment(resolutionId);
  deleteResolutionCommentPromise
    .then(() => {
      dispatch(completeDeleteComment());
      dispatch(notifySuccess('Comentariul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDeleteComment());
    });
  return deleteResolutionCommentPromise;
};

export const editResolutionComment =
  (values, resolutionCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editResolutionCommentPromise = doEditResolutionComment(
      values,
      resolutionCommentId
    );
    editResolutionCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editResolutionCommentPromise;
  };

export const addResolutionCommentReply =
  (values, resolutionCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addResolutionCommentReplyPromise = doAddResolutionCommentReply(
      values,
      resolutionCommentId
    );
    addResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addResolutionCommentReplyPromise;
  };

export const editResolutionCommentReply =
  (values, resolutionCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editResolutionCommentReplyPromise = doEditResolutionCommentReply(
      values,
      resolutionCommentId,
      replyId
    );
    editResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editResolutionCommentReplyPromise;
  };

export const deleteResolutionCommentReply =
  (resolutionCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteResolutionCommentReplyPromise = doDeleteResolutionCommentReply(
      resolutionCommentId,
      replyId
    );
    deleteResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteResolutionCommentReplyPromise;
  };

export const addExperimentResolution = (values) => (dispatch) => {
  dispatch(startAddResolution());
  return doAddExperimentResolution(values)
    .then(() => dispatch(completeAddResolution()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddResolution());
    });
};

export const editExperimentResolution = (values) => (dispatch) => {
  dispatch(startEditResolution());
  return doEditExperimentResolution(values)
    .then(() => dispatch(completeEditResolution()))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditResolution());
    });
};

export const loadExperimentResolution = (resolutionId) => (dispatch) => {
  dispatch(startLoadResolution());
  return doGetExperimentResolution(resolutionId)
    .then((res) => dispatch(completeLoadResolution(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadResolution());
    });
};

export const deleteExperimentResolution = (resolutionId) => (dispatch) => {
  dispatch(startDeleteResolution());
  return doDeleteExperimentResolution(resolutionId)
    .then((res) => dispatch(completeDeleteResolution(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDeleteResolution());
    });
};

export const addExperimentResolutionComment =
  (values, resolutionId) => (dispatch) => {
    dispatch(startAddComment());
    const addResolutionCommentPromise = doAddExperimentResolutionComment(
      values,
      resolutionId
    );
    addResolutionCommentPromise
      .then(() => {
        dispatch(completeAddComment());
        dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddComment());
      });
    return addResolutionCommentPromise;
  };

export const deleteExperimentResolutionComment =
  (resolutionId) => (dispatch) => {
    dispatch(startDeleteComment());
    const deleteResolutionCommentPromise =
      doDeleteExperimentResolutionComment(resolutionId);
    deleteResolutionCommentPromise
      .then(() => {
        dispatch(completeDeleteComment());
        dispatch(notifySuccess('Comentariul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteComment());
      });
    return deleteResolutionCommentPromise;
  };

export const editExperimentResolutionComment =
  (values, resolutionCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editResolutionCommentPromise = doEditExperimentResolutionComment(
      values,
      resolutionCommentId
    );
    editResolutionCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editResolutionCommentPromise;
  };

export const addExperimentResolutionCommentReply =
  (values, resolutionCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addResolutionCommentReplyPromise =
      doAddExperimentResolutionCommentReply(values, resolutionCommentId);
    addResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addResolutionCommentReplyPromise;
  };

export const editExperimentResolutionCommentReply =
  (values, resolutionCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editResolutionCommentReplyPromise =
      doEditExperimentResolutionCommentReply(
        values,
        resolutionCommentId,
        replyId
      );
    editResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editResolutionCommentReplyPromise;
  };

export const deleteExperimentResolutionCommentReply =
  (resolutionCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteResolutionCommentReplyPromise =
      doDeleteExperimentResolutionCommentReply(resolutionCommentId, replyId);
    deleteResolutionCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteResolutionCommentReplyPromise;
  };
