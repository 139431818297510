import Box from '@mui/material/Box';
import React from 'react';
import {
  H2,
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { AddIcon } from '../../common/assets/icons';

const FirstIdeeaComponent = ({ setIsModalOpen }) => (
  <Box>
    <H2 marginBottom="20px">Ideea mea</H2>
    <P marginBottom="20px">
      O idee de afacere reprezintă o concepție sau un concept inovator referitor
      la un produs sau un serviciu, care are potențialul de a genera profit și
      de a fi implementat cu succes în piață.
    </P>
    <P marginBottom="20px">
      Adauga aici prima ta idee de afacere pe care sa o dezvolti.
    </P>
    <PrimaryButton onClick={setIsModalOpen} icon={AddIcon}>
      Adauga idee
    </PrimaryButton>
  </Box>
);

export default FirstIdeeaComponent;
