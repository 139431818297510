import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

const ClampedBox = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => (props.lineClamp ? props.lineClamp : 1)};
  display: -webkit-box;
  overflow: hidden;
  margin: 0;
`;

export const ClampedText = ({ text, lineClamp = 1, withTooltip = false }) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const isOverflowing = () => {
      const element = titleRef.current;
      return element.scrollHeight > element.clientHeight;
    };
    setIsTextOverflowing(isOverflowing());
  }, [text]);

  const textContent = (
    <ClampedBox ref={titleRef} lineClamp={lineClamp}>
      {text}
    </ClampedBox>
  );

  return (
    <>
      {isTextOverflowing && withTooltip ? (
        <Tooltip
          title={text}
          arrow={false}
          classes={{ tooltip: 'clamped-tooltip' }}
        >
          {textContent}
        </Tooltip>
      ) : (
        textContent
      )}
    </>
  );
};
