import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import { AddIcon, EditIcon } from '../../common/assets/icons';

const CategoryForm = ({ handleSubmit, data = null, allCategoryNames = [] }) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Câmp obligatoriu')
      .notOneOf(allCategoryNames, 'Name already exists'),
  });

  const initialValues = data ? { name: data.name } : { name: '' };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomTextField id="name" label="Nume" />
          {data ? (
            <PrimaryButton icon={EditIcon} marginTop="30px" type="submit">
              Editeaza
            </PrimaryButton>
          ) : (
            <PrimaryButton icon={AddIcon} marginTop="30px" type="submit">
              Adauga
            </PrimaryButton>
          )}
        </form>
      )}
    </Formik>
  );
};

export default CategoryForm;
