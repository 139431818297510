import {
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../api/Api';
import {
  GET_QUESTIONNAIRE_PROGRESS,
  POST_QUESTIONNAIRE_ANSWER,
  PUT_FINISH_QUESTIONAIRE,
} from '../common/constants/apiRoutes';

export const getQuestionnaireProgress = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_QUESTIONNAIRE_PROGRESS,
    params: payload.params,
  });

export const postQuestionnaireAnswer = ({ payload }) =>
  runPostApiRequest({
    endpoint: POST_QUESTIONNAIRE_ANSWER,
    payload: payload.questionData,
  });

export const putFinishQuestionnaire = () =>
  runUpdateApiRequest({
    endpoint: PUT_FINISH_QUESTIONAIRE,
  });
