export const industriesSerializer = (industries) => {
  const mappingByIndustryType = {};

  industries.forEach((industry) => {
    const { industryType } = industry;
    if (!mappingByIndustryType[industryType]) {
      mappingByIndustryType[industryType] = [];
    }
    mappingByIndustryType[industryType].push(industry);
  });

  return mappingByIndustryType;
};

export const swotsSerializer = (swots) => {
  const mappingBySwotType = {};

  swots.forEach((swot) => {
    const { swotType } = swot;
    if (!mappingBySwotType[swotType]) {
      mappingBySwotType[swotType] = [];
    }
    mappingBySwotType[swotType].push(swot);
  });

  return mappingBySwotType;
};

export const teamCompetitorsSerializer = (competitorData) => {
  const {
    competitors = [],
    competitionMetrics = [],
    criteria = [],
  } = competitorData;

  const initialHeader = [
    {
      value: 'Competitor/Criteriu',
      coordinates: { competitorId: 'default' },
      disabled: true,
      id: 'defaultHeader',
    },
  ];

  const criteriaHeaders = criteria.map((criterion) => ({
    value: criterion.name,
    coordinates: { criteriaId: criterion._id },
    action: 'editCriteriaName',
    disabled: false,
    id: criterion._id,
  }));

  const headers = [...initialHeader, ...criteriaHeaders];
  const lastHeader = headers && headers[headers.length - 1];

  const criteriaMap = {};
  criteria.forEach((criterion) => {
    const { name } = criterion;
    criteriaMap[name] = criterion;
  });

  const competitorMap = {};
  competitors.forEach((competitor) => {
    const { name } = competitor;
    competitorMap[name] = competitor;
  });

  const additionalHeadersCount = 6 - headers.length;
  const additionalHeaders = Array.from(
    { length: additionalHeadersCount },
    (_, index) => ({
      value: 'Adauga un criteriu',
      coordinates: { criteriaId: null },
      disabled: false,
      action: 'addCriteria',
      id: `header-post-${lastHeader.id}-${index}`,
    })
  );

  return {
    competitorTableHeaders: [...headers, ...additionalHeaders],
    competitionMetrics,
    criteria: criteriaMap,
    criteriaList: Object.keys(criteriaMap),
    competitorList: competitors,
    competitors: competitorMap,
  };
};
