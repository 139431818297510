import React from 'react';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Popper } from '@mui/material';
import { P } from '../styled/ReusableTextComponents';

const TeamFilterForm = ({
  userTeams = [],
  userTeamValue,
  handleChangeTeam,
}) => {
  const { label: selectedLabel = 'Echipa mea' } = userTeamValue;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'simple-popper' : undefined;

  const handleChooseTeam = (team) => {
    handleChangeTeam(team);
    setAnchorEl(null);
  };

  const teamOptions = userTeams.filter(
    (team) => team.value !== userTeamValue.value
  );

  return (
    <>
      <Box
        sx={{
          border: '1px solid #74A3FE',
          padding: '3px 10px 3px 15px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : event.currentTarget);
        }}
      >
        <P>{selectedLabel}</P>
        <FontAwesomeIcon
          icon={faAngleDown}
          style={{ cursor: 'pointer', color: '#74A3FE' }}
        />
      </Box>
      <Popper
        id={popperId}
        open={popperOpen}
        anchorEl={anchorEl}
        sx={{ zIndex: '100' }}
        placement="bottom-start"
      >
        <Box
          sx={{
            backgroundColor: 'white',
            width: '200px',
            border: '1px solid #74A3FE',
            marginTop: '5px',
          }}
        >
          {teamOptions.map((team) => (
            <Box
              sx={{ padding: '5px 10px', cursor: 'pointer' }}
              key={team.value}
              onClick={() => handleChooseTeam(team)}
            >
              <P>{team.label}</P>
            </Box>
          ))}
        </Box>
      </Popper>
    </>
  );
};

export default TeamFilterForm;
