import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
  ACCOUNT_CREATION_FORM_PAYMENT_ADDITIONAL_DATA,
  QUESTION_FLOWS,
} from '../constants/questions';
import {
  H1,
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { InfoIcon } from '../../common/components/styled';
import {
  RegistrationFormImageBox,
  RegistrationFormLeftPanel,
  RegistrationFormRightPanel,
  RegistrationPageContainer,
} from './styledComponents/registrationForm';
import RegistrationBigBoxQuestion from './RegistrationBigBoxQuestion';
import RegistrationPillQuestion from './RegistrationPillQuestion';
import DescribeIdeaForm from './DescribeIdeaForm';
import {
  postQuestionnaireAnswer,
  putFinishQuestionnaire,
} from '../../requests/chestionar';
import { useApi } from '../../hooks/useApi';
import { getUserProfile } from '../../requests/profile';
import { generateQuestionData } from '../helpers/questions';
import { useSelector } from 'react-redux';
import ChooseNumberOfMembers from './ChooseNumberOfMembers';
import { useHistory } from 'react-router-dom';
import {
  getAllSubscriptionOptions,
  startCheckoutSession,
} from '../../requests/payments';
import { postNewTeam } from '../../requests/teams';
import DescribeExperiecneForm from './DescribeExperiecneForm';
import uuid from 'react-uuid';

const componentMap = {
  bigBox: RegistrationBigBoxQuestion,
  pill: RegistrationPillQuestion,
  describeIdeaForm: DescribeIdeaForm,
  numberOfMembers: ChooseNumberOfMembers,
  experienceForm: DescribeExperiecneForm,
};

const AccountCreationContainer = () => {
  const userRole = useSelector((state) => state?.account?.login?.user?.role);

  const [activeStep, setActiveStep] = useState(0);
  const [activeStepAnswers, setActiveStepAnswers] = useState([
    userRole.toLowerCase(),
  ]);
  const [answerReactiveMessage, setAnswerReactiveMessage] = useState(null);
  const [, runCheckoutSession] = useApi(startCheckoutSession);

  const currentStepQuestionData = QUESTION_FLOWS[userRole][activeStep];
  const QuestionComponent = componentMap[currentStepQuestionData.questionType];

  const [
    { isLoading: isProfileLoading, result: userProfile },
    runGetLoggedUserProfile,
  ] = useApi(getUserProfile);

  const [{ result: availableSubscriptions }, runGetAllSubscriptions] = useApi(
    getAllSubscriptionOptions
  );

  const [, runAnswerQuestion] = useApi(postQuestionnaireAnswer);
  const [, runFinishQuestionnaire] = useApi(putFinishQuestionnaire);
  const [, runPostNewTeam] = useApi(postNewTeam);
  const history = useHistory();

  const handleFinishQuestionnaire = async () => {
    await runFinishQuestionnaire();
    return history.push('/');
  };

  const initializeQuestionnaire = async () => {
    const userData = await runGetLoggedUserProfile();

    const { questionnaireStep = 0 } = userData;

    if (questionnaireStep) {
      setActiveStep(questionnaireStep);
    }
  };

  useEffect(() => {
    if (userProfile?.userId?.subscription) {
      handleFinishQuestionnaire();
    }

    initializeQuestionnaire();
    runGetAllSubscriptions();
  }, []);

  const handleContinueToNextQuestion = async () => {
    const questionData = generateQuestionData({
      currentDisplayedQuestion: currentStepQuestionData,
      step: activeStep,
      currentSelectedAnswers: activeStepAnswers,
    });

    const shouldCreateIdea = activeStepAnswers?.[0]?.createIdea;

    if (shouldCreateIdea) {
      await runPostNewTeam({
        teamData: {
          name: activeStepAnswers?.[0]?.name,
          description: activeStepAnswers?.[0]?.name,
          stage: 'startup',
          teamCode: uuid(),
        },
      });
    }

    if (activeStepAnswers?.[0] === 'paid') {
      const checkoutSessionResponse = await runCheckoutSession({
        data: { planId: availableSubscriptions?.prices?.data[1]?.id },
      });

      await runFinishQuestionnaire();
      const { redirectUrL } = checkoutSessionResponse;
      window.location.href = redirectUrL;
      return;
    }

    await runAnswerQuestion({
      questionData,
    });

    if (activeStep + 1 === QUESTION_FLOWS[userRole].length) {
      await runFinishQuestionnaire();
      return history.push('/');
    } else {
      setActiveStep(activeStep + 1);
      setActiveStepAnswers([]);
    }
  };

  const handleClickAnswer = (answer, reactiveMessage = null) => {
    const answerIsAlreadySelected = activeStepAnswers.includes(answer);

    if (answerIsAlreadySelected) {
      const newAnswers = activeStepAnswers.filter((el) => el !== answer);
      setAnswerReactiveMessage(null);
      return setActiveStepAnswers(newAnswers);
    }

    if (activeStepAnswers.length < currentStepQuestionData?.answerLimit) {
      setAnswerReactiveMessage(reactiveMessage);
      const newAnswers = [...activeStepAnswers, answer];
      return setActiveStepAnswers(newAnswers);
    }

    if (activeStepAnswers.length === currentStepQuestionData?.answerLimit) {
      const rollOverAnswers = [...activeStepAnswers].slice(
        0,
        currentStepQuestionData?.answerLimit - 1
      );
      setAnswerReactiveMessage(reactiveMessage);
      return setActiveStepAnswers([...rollOverAnswers, answer]);
    }
  };

  return (
    <RegistrationPageContainer>
      {isProfileLoading ? (
        <CircularProgress />
      ) : currentStepQuestionData?.finalStep ? (
        <Box sx={{ width: '100%', heith: '100%' }}>
          <RegistrationFormLeftPanel>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <p
                style={{ fontSize: '30px', fontWeight: '800', color: 'black' }}
              >
                Felicitari, contul tau LaunchPad este gata
              </p>
              <PrimaryButton onClick={handleFinishQuestionnaire}>
                Finalizeaza chestionarul
              </PrimaryButton>
            </Box>
          </RegistrationFormLeftPanel>
          <RegistrationFormRightPanel>
            <RegistrationFormImageBox
              sx={{
                backgroundImage: `url(${currentStepQuestionData.image})`,
              }}
            />
          </RegistrationFormRightPanel>
        </Box>
      ) : (
        <>
          <RegistrationFormLeftPanel>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '80%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <span>
                  {`Intrebarea ${activeStep + 1}/${
                    QUESTION_FLOWS[userRole].length
                  }`}
                </span>
                <Box sx={{ display: 'flex' }}>
                  {QUESTION_FLOWS[userRole].map((el, idx) => (
                    <Box
                      key={el.questionCode}
                      sx={{
                        width: '20px',
                        height: '4px',
                        backgroundColor:
                          activeStep >= idx ? '#2A5AB7' : 'lightGrey',
                        marginRight: '2px',
                      }}
                    />
                  ))}
                </Box>
              </Box>

              <Box sx={{ marginBottom: '30px', marginTop: '50px' }}>
                <H1>{currentStepQuestionData?.question}</H1>
                {currentStepQuestionData?.questionAdditionalInformation && (
                  <P>
                    <i>
                      {currentStepQuestionData?.questionAdditionalInformation}
                    </i>
                  </P>
                )}

                <Box sx={{ marginTop: '150px' }}>
                  <QuestionComponent
                    handleClickAnswer={handleClickAnswer}
                    currentStepQuestionData={currentStepQuestionData}
                    selectedAnswers={activeStepAnswers}
                    preselectedAnswer={
                      activeStep === 0 ? userRole.toLowerCase() : null
                    }
                    userProfile={userProfile}
                  />
                </Box>
              </Box>

              {currentStepQuestionData?.additionalInformation && (
                <Box
                  sx={{
                    marginTop: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <InfoIcon />
                  <Box sx={{ width: '80%' }}>
                    <P marginLeft="10px">
                      {currentStepQuestionData.additionalInformation}
                    </P>
                  </Box>
                </Box>
              )}

              {answerReactiveMessage && (
                <Box
                  sx={{
                    marginTop: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <InfoIcon />
                  <Box sx={{ width: '80%' }}>
                    <P marginLeft="10px">{answerReactiveMessage}</P>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              <PrimaryButton
                style={{
                  padding: '12px 40px',
                  backgroundColor: !activeStepAnswers?.length
                    ? 'lightGrey'
                    : '#2A5AB7',
                  color: 'white',
                }}
                disabled={!activeStepAnswers?.length}
                onClick={handleContinueToNextQuestion}
              >
                {activeStepAnswers?.[0] === 'paid'
                  ? 'Continua spre pagina de pata cu cardul'
                  : 'Continua'}
              </PrimaryButton>
            </Box>
          </RegistrationFormLeftPanel>
          <RegistrationFormRightPanel>
            {currentStepQuestionData?.image && (
              <RegistrationFormImageBox
                sx={{
                  backgroundImage: `url(${currentStepQuestionData.image})`,
                }}
              />
            )}
            {currentStepQuestionData?.purchaseStatus && (
              <Box>
                <Box
                  sx={{
                    width: '500px',
                    height: '500px',
                    backgroundColor: 'bisque',
                    padding: '20px',
                    borderRadius: '20px',
                  }}
                >
                  {ACCOUNT_CREATION_FORM_PAYMENT_ADDITIONAL_DATA[
                    activeStepAnswers?.[0] || 'free'
                  ].map((el) => (
                    <p key={el.id}>
                      <span
                        style={{
                          marginRight: '5px',
                          fontWeight: '700',
                          fontSize: '18px',
                          color: el?.color || 'black',
                        }}
                      >
                        {el?.sign}
                      </span>
                      <span style={{ fontWeight: '600', color: 'black' }}>
                        {el?.text}
                      </span>
                    </p>
                  ))}
                </Box>
              </Box>
            )}
          </RegistrationFormRightPanel>
        </>
      )}
    </RegistrationPageContainer>
  );
};

export default AccountCreationContainer;
