/* eslint-disable react/no-render-return-value */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Normalize } from 'styled-normalize';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from './core/styles/GlobalStyle';
import AccordionStyles from './core/styles/AccordionStyles';
import registerAuthInterceptor from './account/services/registerAuthInterceptor';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initFacebookSdk } from './utils/services/initFacebookSdk';

registerAuthInterceptor();

const startApp = () => {
  const root = document.getElementById('root');
  const reactRoot = createRoot(root);

  reactRoot.render(
    <>
      <Normalize />
      <GlobalStyle />
      <AccordionStyles />
      <App />
    </>
  );
};

initFacebookSdk().then(startApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
