import React from 'react';
import { UserMessageBubble } from './styled/chatMessages';
import { CHAT_ROLE_ASSISTANT } from '../constants';
import ChatLoader from './ChatLoader';

const ChatGptMessagesList = ({ messages = [], isChatLoading = false }) => (
  <>
    {messages.map((message, idx) => (
      <UserMessageBubble
        key={idx}
        isBot={message?.role === CHAT_ROLE_ASSISTANT}
      >
        {message?.content}
      </UserMessageBubble>
    ))}
    {isChatLoading && <ChatLoader />}
  </>
);

export default ChatGptMessagesList;
