export const commentsSerializer = (commentList = [], userId = null) => {
  const mainCommentsMap = {};
  const replies = [];

  commentList.forEach((comment) => {
    if (comment.isMainThread) {
      comment.replies = [];
      comment.unread = !comment.readBy.includes(userId);
      mainCommentsMap[comment._id] = comment;
    } else {
      replies.push(comment);
    }
  });

  replies.forEach((reply) => {
    const parentComment = mainCommentsMap[reply.parentCommentId];

    if (parentComment) {
      if (!reply.readBy.includes(userId)) {
        parentComment.unreadReplies = true;
      }
      parentComment.replies.push(reply);
    }
  });

  return Object.values(mainCommentsMap);
};
