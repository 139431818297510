import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Fade } from '@mui/material';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import { BMC_OPTIONS } from '../../../canvas/constants';

import { targetIndicatorsOptions } from '../../../hypotheses/components/forms/HypothesisForm';
import CustomMultipleOptionsGroup from './formComponents/CustomMultipleOptionsGroup';
import CustomMultipleSelectField from '../../../common/components/forms/formComponents/CustomMultipleSelectField';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import CustomCounter from '../../../common/components/forms/formComponents/CustomCounter';

const calculateTargetIndicatorsNumber = (values) => {
  const { targetIndicatorType1, targetIndicatorType2, targetIndicatorType3 } =
    values;
  let targetIndicatorsNumber = 0;
  if (targetIndicatorType1) {
    targetIndicatorsNumber++;
  }
  if (targetIndicatorType2) {
    targetIndicatorsNumber++;
  }
  if (targetIndicatorType3) {
    targetIndicatorsNumber++;
  }
  return targetIndicatorsNumber;
};
const HypothesisForm = ({
  segmentOptions,
  handleSubmit,
  formValues,
  isEdit,
}) => {
  const { columnBMC } = formValues;
  const isCustomerSegment =
    columnBMC === 'customerSegment' || columnBMC === 'customerSegments';
  let bmcSegmentOptions = BMC_OPTIONS;
  if (!isCustomerSegment) {
    bmcSegmentOptions = BMC_OPTIONS.filter(
      (option) => option.value !== 'customerSegments'
    );
  }

  const initialValues = {
    title: formValues.title || '',
    description: formValues.description || '',
    columnBMC: formValues.columnBMC || '',
    importance: formValues.importance || 'medium',
    segment: formValues.segment || [],
    targetIndicatorsNumber: calculateTargetIndicatorsNumber(formValues),
    targetIndicatorType1: formValues.targetIndicatorType1 || '',
    targetIndicatorValue1: formValues.targetIndicatorValue1 || '',
    targetIndicatorType2: formValues.targetIndicatorType2 || '',
    targetIndicatorValue2: formValues.targetIndicatorValue2 || '',
    targetIndicatorType3: formValues.targetIndicatorType3 || '',
    targetIndicatorValue3: formValues.targetIndicatorValue3 || '',
  };

  const CustomSelectedOptionComponent = ({ label, color }) => (
    <Box
      sx={{
        marginLeft: '10px',
        border: `1px solid ${color}`,
        borderRadius: '10px',
        padding: '2px 10px',
        cursor: 'pointer',
      }}
    >
      {label}
    </Box>
  );

  const CustomOptionComponent = ({ option, selected, ...otherProps }) => (
    <Box
      {...otherProps}
      sx={{
        margin: '5px 0',
        marginLeft: '10px',
        border: `1px solid ${option.color}`,
        borderRight: `7px solid ${option.color}`,
        borderRadius: '10px',
        padding: '2px 10px',
        cursor: 'pointer',
      }}
    >
      {option.label}
    </Box>
  );

  const validate = (values) => {
    const errors = {};

    // Validate title
    if (!values.title) {
      errors.title = 'Câmp obligatoriu';
    }

    // Validate description
    if (!values.description) {
      errors.description = 'Câmp obligatoriu';
    }

    // Validate columnBMC
    if (!values.columnBMC) {
      errors.columnBMC = 'Câmp obligatoriu';
    }

    // Validate importance
    if (!values.importance) {
      errors.importance = 'Câmp obligatoriu';
    }

    // Validate segment if isCustomerSegment is false
    if (!isCustomerSegment) {
      if (!values.segment || values.segment.length < 1) {
        errors.segment = 'Câmp obligatoriu';
      }
    }

    Array.from({ length: values.targetIndicatorsNumber }).forEach(
      (_, index) => {
        const targetIndicatorType = values[`targetIndicatorType${index + 1}`];
        const targetIndicatorValue = values[`targetIndicatorValue${index + 1}`];

        if (!targetIndicatorType && targetIndicatorValue) {
          errors[`targetIndicatorType${index + 1}`] = 'Câmp obligatoriu';
        }

        if (targetIndicatorType && !targetIndicatorValue) {
          errors[`targetIndicatorValue${index + 1}`] = 'Câmp obligatoriu';
        }
      }
    );

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomTextField id="title" label="Titlul ipotezei" />
          <CustomTextField
            id="description"
            label="Descriere"
            rows={3}
            multiline
          />
          <CustomSelectField
            id="columnBMC"
            label="Alege BMC"
            options={bmcSegmentOptions}
            disabled={isCustomerSegment}
          />
          <CustomMultipleOptionsGroup
            id="importance"
            label="Importanta"
            customStyles={{ padding: '2px 0 2px 14px' }}
          />
          {!isCustomerSegment && (
            <CustomMultipleSelectField
              id="segment"
              label="Segmente vizate"
              options={segmentOptions}
              customSelectedOptionComponent={CustomSelectedOptionComponent}
              customOptionComponent={CustomOptionComponent}
            />
          )}
          <CustomCounter
            id="targetIndicatorsNumber"
            label="Număr indicatori"
            customStyles={{ padding: '10px' }}
            minValue={0}
          />
          {Array.from({ length: values.targetIndicatorsNumber }).map(
            (_, index) => (
              <Fade key={index} in={true} timeout={500}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    flexGrow: 1,
                    '& > :nth-of-type(1)': { width: '75%' },
                    '& > :nth-of-type(2)': { width: '25%' },
                  }}
                >
                  <CustomSelectField
                    id={`targetIndicatorType${index + 1}`}
                    placeholder="Alege tipul indicatorului"
                    label="Indicator"
                    options={targetIndicatorsOptions}
                  />
                  <CustomTextField
                    id={`targetIndicatorValue${index + 1}`}
                    label="Valoare indicator"
                  />
                </Box>
              </Fade>
            )
          )}
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {isEdit ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default HypothesisForm;
