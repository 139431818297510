import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CONTEXT_ANALYSIS_TABS,
  RESOURCES_TABS,
} from '../../../opportunityAnalysis/constants';
import { ProfileMenu } from '../../../userProfile/components/styled/ProfilePage';
import {
  ProfilePageContainer,
  StyledNavLink,
  StyledProfileNav,
} from '../../../userProfile/components/styled/ProfileTabItem';
import { Nav } from 'react-bootstrap';
import { ProfileTabSvgIcon } from '../../../common/components/styled/LinkIcon';
import { PlaceholderComponent } from '../../../hypotheses/components/pages/HypothesisPage';
import ResourceComponent from '../ResourceComponent';

const pageComponents = {
  chapters: {
    title: 'Cursuri',
    component: <ResourceComponent />,
  },
  articles: {
    title: 'Articole',
    component: <ResourceComponent />,
  },
  patterns: {
    title: 'Sabloane',
    component: <PlaceholderComponent />,
  },
};

export const ResourcesPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const tabFromQuery = query.get('tab') || 'articles';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/resources?tab=${tab}`);
  };

  return (
    <>
      <ProfileMenu>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {RESOURCES_TABS.map((tab, index) => (
            <Nav.Item
              id={tab?.id || tab.key}
              key={tab.key}
              style={{ position: 'relative' }}
            >
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === RESOURCES_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabSvgIcon>{tab.icon}</ProfileTabSvgIcon>
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <ProfilePageContainer
        fullBorder={activeTab !== 'articles'}
        height="91.5vh"
      >
        {pageComponents[activeTab].component}
      </ProfilePageContainer>
    </>
  );
};
