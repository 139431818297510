import { Box, IconButton } from '@mui/material';
import React from 'react';
import { P, SPAN } from '../../common/components/styled/ReusableTextComponents';
import {
  EyeIcon,
  PencilIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';
import { INDUSTRY_ADORNMENTS } from '../constants';
import { ClampedText } from '../../common/components/ClampedText';

export const IndustryCardComponent = ({
  industry,
  handleEditIndustry,
  handleDeleteIndustry,
  handleViewIndustry,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
      borderRadius: '10px',
      border: '1px solid #74a3fe',
      height: '125px',
      padding: '5px',
      position: 'relative',
      '&:hover .actions': {
        opacity: 1,
      },
      '&:hover .title': {
        opacity: 0,
      },
    }}
  >
    <Box
      className="title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        width: '100%',
        opacity: 1,
        transition: 'opacity 0.3s',
        margin: '5px 0 0 5px',
      }}
    >
      <Box>
        <ClampedText
          text={`${INDUSTRY_ADORNMENTS[industry.influence]} ${industry.title}`}
          lineClamp={1}
        />
      </Box>
    </Box>

    <Box
      className="actions"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '100%',
        opacity: 0,
        transition: 'opacity 0.3s',
      }}
    >
      <IconButton onClick={() => handleViewIndustry(industry)}>
        <EyeIcon fill="#74A3FE" />
      </IconButton>
      <IconButton onClick={() => handleEditIndustry(industry)}>
        <PencilIcon fill="#74A3FE" />
      </IconButton>
      <IconButton onClick={() => handleDeleteIndustry(industry)}>
        <TrashIconOutlined fill="red" />
      </IconButton>
    </Box>
    <SPAN faded marginTop="35px">
      <ClampedText text={industry.description} lineClamp={4} />
    </SPAN>
  </Box>
);
