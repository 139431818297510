import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import {
  MrlContainer,
  MrlHeader,
  ProgressBarContainer,
  ProgressElement,
  ProgressCounter,
  MrlStepContainer,
} from './styled';

import MrlTask from './MrlTask';
import { loadMrl } from '../ducks';

import MrlStep from './MrlStep';
import {
  CloseMrlContainer,
  MrlContainerClosed,
  StepNumberContainer,
  MrlElementContainer,
  OpenMrlContainerButton,
  MarginContainer,
  MrlTaskContainer,
  StepNumber,
} from './styled/MrlStyled';
import {
  CloseFullscreenIcon,
  FullScreenIcon,
  ThermometherIcon,
} from '../../common/assets/icons';
import { P } from '../../common/components/styled/ReusableTextComponents';
import { capitalizeFirstLetter } from '../../common/functions/genericHelpers';
import RichTextContainer from '../../common/components/RichTextContainer';
import { useHistory } from 'react-router-dom';

function Mrl({
  loadMrl,
  mrlData = [],
  isMrlContainerOpen,
  isEditing,
  setIsMrlContainerOpen,
}) {
  const [selectedIndex, setSelectedIndex] = useState();
  const [closeTransition, setCloseTransition] = useState(false);
  const [selectedTaskToOpen, setSelectedTaskToOpen] = useState();
  const { push } = useHistory();

  const calculateCompletionStatus = (indexOfStep) => {
    const selectedStep = mrlData[indexOfStep - 1];
    const totalTasks = selectedStep.tasks.length;
    const completedTasks = selectedStep.tasks.filter(
      (task) => task.isDone
    ).length;
    return `${completedTasks}/${totalTasks}`;
  };

  useEffect(() => {
    loadMrl();
  }, [isEditing]);

  const handleTransition = (index) => {
    if (!closeTransition) {
      if (!selectedIndex) {
        return { height: '4.5vh', marginBottom: '25px' };
      }
      if (index !== selectedIndex) {
        return {
          height: 0,
          opacity: 0,
          transition: 'width 0.3s, height 0.2s',
        };
      }
      return {
        height: 0,
        transition: 'all .25s',
      };
    }
    if (selectedIndex) {
      return { height: '4vh' };
    }
    return {
      opacity: selectedIndex ? 0 : 1,
      transition: 'height 0.25s, width 1s, opacity 1.5s',
      height: '4.5vh',
      marginBottom: '30px',
    };
  };

  const handleDescriptionStyle = () => {
    if (!selectedIndex) {
      return { opacity: 0 };
    }
    return { transition: 'all .2s', transitionDelay: '.3s' };
  };

  function compareFunction(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const sortItems = (list) => {
    const newList = list.sort(compareFunction);

    return newList;
  };

  const handleMrlTaskOpen = (task) => {
    if (task === selectedTaskToOpen) {
      setSelectedTaskToOpen();
      return;
    }
    setSelectedTaskToOpen(task);
  };

  const renderMrlOpenContainer = () => (
    <MrlContainer id="mrl-container">
      <MrlHeader>
        <MarginContainer
          marginLeft="1vw"
          width="25px"
          height="25px"
          zIndex="555"
        >
          {selectedIndex && (
            <FontAwesomeIcon
              icon={faChevronLeft}
              color="#2A5AB7"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setCloseTransition(true);
                setSelectedTaskToOpen('');
                setSelectedIndex(undefined);
                e.stopPropagation();
              }}
            />
          )}
        </MarginContainer>

        <Box
          sx={{
            display: 'flex',
            marginLeft: '-2vw',
            justifyContent: 'flex-start',
            paddingLeft: selectedIndex ? '26px' : '16px',
            alignItems: 'center',
            width: '100%',
            borderBottom: selectedIndex ? '1px solid #B1CCFE' : 'none',
            paddingBottom: '5px',
          }}
        >
          {!selectedIndex ? (
            <>
              <ThermometherIcon />
              <P marginLeft="10px">Ghid MRL</P>
            </>
          ) : (
            <P>{`${capitalizeFirstLetter(
              mrlData[selectedIndex - 1].name
            )} | ${calculateCompletionStatus(selectedIndex)}`}</P>
          )}
        </Box>
      </MrlHeader>
      <ProgressBarContainer>
        <ProgressElement width={`${(4 / 15) * 18}vw`} />
        <ProgressCounter>10%</ProgressCounter>
      </ProgressBarContainer>
      <MrlStepContainer disableScroll={selectedIndex} className="scrollbar">
        {sortItems(mrlData).map((item, index) => (
          <div
            key={index}
            id={`mrl-${index}`}
            style={handleTransition(index + 1)}
            onClick={() => {
              if (!selectedIndex) {
                setSelectedIndex(index + 1);
                setCloseTransition(false);
              }
            }}
          >
            <MarginContainer display="flex" alignItems="center">
              <MrlStep
                number={index}
                title={item.name}
                isCompleted={false}
                tasks={item.tasks}
                description={item.description}
                subtitle={item.subtitle}
                isOpen={!selectedIndex}
                completionStatus={calculateCompletionStatus(index + 1)}
              />
            </MarginContainer>
          </div>
        ))}

        <div style={handleDescriptionStyle()}>
          <MrlTaskContainer marginTop="5vh">
            {selectedIndex && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (e.target.tagName === 'A') {
                    if (
                      e.target.href.toString().includes('youtube') ||
                      e.target.href.toString().includes('vimeo')
                    ) {
                      push(`?video-player=${e.target.href}`);
                    } else {
                      window.location.replace(e.target.href);
                    }
                  }
                }}
              >
                <RichTextContainer
                  text={mrlData[selectedIndex - 1].description}
                >
                  <P>{mrlData[selectedIndex - 1].description}</P>
                </RichTextContainer>
              </div>
            )}
          </MrlTaskContainer>

          {mrlData[selectedIndex - 1] &&
            mrlData[selectedIndex - 1].tasks.map((task) => (
              <MrlTask
                key={task._id}
                isCompleted={task.isDone}
                title={task.name}
                link={task.link}
                buttonName={task.buttonName}
                description={task.description}
                descriptionHtml={task.descriptionHtml}
                isOpen={selectedTaskToOpen === task.name}
                onMrlTaskOpen={handleMrlTaskOpen}
                push={push}
              />
            ))}
        </div>
      </MrlStepContainer>

      <CloseMrlContainer
        id="mrl-size-controll"
        onClick={() => setIsMrlContainerOpen(false)}
      >
        <CloseFullscreenIcon />
        <span style={{ color: '#74A3FE', fontSize: '16px', marginLeft: '5px' }}>
          Minimizeaza
        </span>
      </CloseMrlContainer>
    </MrlContainer>
  );

  const renderMrlCloseContainer = () => (
    <MrlContainerClosed id="mrl-container">
      <div>
        <MrlElementContainer>
          <ThermometherIcon />
        </MrlElementContainer>
        <MrlElementContainer
          style={{ paddingTop: '2vh', fontSize: '12px', textAlign: 'center' }}
        >
          <ProgressBarContainer>
            <ProgressElement />
          </ProgressBarContainer>
        </MrlElementContainer>
        <div>
          {mrlData.map((item, index) => (
            <div style={{ marginTop: '2.5vh' }} key={index}>
              <StepNumberContainer>
                <StepNumber>{index + 1}</StepNumber>
              </StepNumberContainer>
            </div>
          ))}
        </div>

        <OpenMrlContainerButton
          id="mrl-size-controll"
          marginTop="4vh"
          onClick={() => {
            setIsMrlContainerOpen(true);
          }}
        >
          <FullScreenIcon />
        </OpenMrlContainerButton>
      </div>
    </MrlContainerClosed>
  );

  if (!mrlData) return <></>;

  return isMrlContainerOpen
    ? renderMrlOpenContainer()
    : renderMrlCloseContainer();
}

const mapDispatchToProps = {
  loadMrl,
};

const mapStateToProps = (state) => ({
  mrlLoading: state.mrl.isLoading,
  mrlData: state.mrl.mrls,
  isEditing: state.mrl.isEditing,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mrl));
