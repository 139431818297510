import { Box, Drawer, IconButton } from '@mui/material';
import React from 'react';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { SWOT_TYPES } from '../constants';
import {
  CrossIcon,
  PencilIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';

export const SwotDrawer = ({
  selectedSwotElement,
  isDrawerOpen,
  handleCloseDrawer,
  handleDeleteSwotElement,
  handleEditSwotElement,
}) => (
  <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
    <Box sx={{ width: '400px', padding: '20px' }}>
      {selectedSwotElement && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <H3 marginBottom="20px">
              Vizualizare SWOT -{' '}
              {SWOT_TYPES[selectedSwotElement.swotType].drawerName}
            </H3>
            <IconButton onClick={handleCloseDrawer}>
              <CrossIcon fill="blue" />
            </IconButton>
          </Box>
          <P marginBottom="20px">
            <b>Tip:</b> {SWOT_TYPES[selectedSwotElement.swotType].drawerName}
          </P>
          <P marginBottom="20px">
            <b>Descriere:</b> {selectedSwotElement.description}
          </P>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <PrimaryButton
              onClick={() => handleEditSwotElement(selectedSwotElement)}
              inverted
              icon={PencilIcon}
              iconColor="#74A3FE"
              marginRight="10px"
            >
              Edit
            </PrimaryButton>
            <SecondaryButton
              onClick={() => handleDeleteSwotElement(selectedSwotElement._id)}
              icon={TrashIconOutlined}
            >
              Sterge
            </SecondaryButton>
          </Box>
        </>
      )}
    </Box>
  </Drawer>
);
