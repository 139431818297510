import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import { reducer as coreReducer } from './core/ducks';
import { reducer as accountReducer } from './account/ducks';
import { reducer as cohortsReducer } from './accelerator/ducks/cohorts';
import { reducer as adminReducer } from './admin/ducks';
import { reducer as commonReducer } from './common/ducks';
import { reducer as teamReducer } from './team/ducks/team';
import { reducer as experimentsReducer } from './experiments/ducks/experiments';
import { reducer as hypothesesReducer } from './hypotheses/ducks/hypotheses';
import { reducer as teamsReducer } from './teams/ducks/teams';
import { reducer as hypothesisDetailReducer } from './hypothesisDetails/ducks/hypothesisDetail';
import { reducer as experimentDetailReducer } from './experimentDetails/ducks/experimentsDetail';
import { reducer as opportunityAnalysislReducer } from './opportunityAnalysis/ducks/opportunityAlalysis';
import { reducer as mentorsReducer } from './mentors/ducks/mentors';
import { reducer as userProfileReducer } from './userProfile/ducks/userProfile';
import { reducer as resolutionReducer } from './resolutions/ducks/resolution';
import { reducer as detailsReducer } from './details/ducks/details';
import { reducer as plaftormInformationsReducer } from './applicationStatistics/ducks/platformInformations';
import { reducer as contactFormReducer } from './admin/ducks/contactForm';
import { reducer as mrlReducer } from './mrl/ducks/mrl';
import { reducer as contextAnalysisReducer } from './opportunityAnalysis/ducks/contextAnalysis';
import { reducer as targetIndicatorsReducer } from './targetIndicators/ducks/targetIndicators';
import { reducer as commentsReducer } from './comentsAndNotifications/ducks/comments';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  core: coreReducer,
  account: accountReducer,
  cohorts: cohortsReducer,
  admin: adminReducer,
  userProfile: userProfileReducer,
  common: commonReducer,
  team: teamReducer,
  experiments: experimentsReducer,
  hypotheses: hypothesesReducer,
  teams: teamsReducer,
  hypothesisDetail: hypothesisDetailReducer,
  experimentDetail: experimentDetailReducer,
  opportunityAnalysis: opportunityAnalysislReducer,
  mentors: mentorsReducer,
  resolution: resolutionReducer,
  details: detailsReducer,
  contactForm: contactFormReducer,
  platformInformations: plaftormInformationsReducer,
  mrl: mrlReducer,
  contextAnalysis: contextAnalysisReducer,
  targetIndicators: targetIndicatorsReducer,
  comments: commentsReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
