import React from 'react';
import {
  ConditionsHeading,
  ConditionsContainer,
  ConditionsText,
  ConditionsTitle,
  Anchor,
} from '../styled/CondtionsStyle';

const Conditions = () => (
  <>
    <ConditionsContainer className="scrollbar">
      <ConditionsTitle>Confidentialitate</ConditionsTitle>
      <ConditionsHeading>Scop</ConditionsHeading>
      <ConditionsText>
        Politica de confidentialitate are rolul de a clarifica tipul
        informatiilor pe care noi, Patronatul Tinerilor Intreprinzatori Cluj
        (denumit in continuare PTIC), le colectam si cum utilizam aceste
        informatii. Confidentialitatea informatiilor tale este foarte importanta
        psi le tratam cu foarte mare responsabilitate. Ne supunem si respectam
        legislatia Romaniei si Uniunii Europene in domeniul protectiei datelor
        cu caracter personal. Pentru mai multe informatii poti sa accesezi
        urmatorul link pe link
        <Anchor
          href="https://eur-lex.europa.eu/content/cookies/cookies-notice.html?locale=en"
          target="_blank"
        >
          Eur-lex
        </Anchor>
        .
      </ConditionsText>
      <ConditionsHeading>Ce fel de informatii colectam?</ConditionsHeading>
      <ConditionsText>
        Modalitatile in care colectam informatiile sunt urmatoarele: prin
        abonarea la newsletter, solicitarea unor informatii suplimentare prin
        formularul de contact, participarea la diverse promotii sau competitii,
        uneltele si serviciile Google (adwords remarketing), formulare de
        contact si alte formulare interactive. Informatiile si documentele pe
        care le colectam sunt: nume, prenume, adresa de email, sexul si numarul
        de telefon, sursele de informare, imagini, profilele de pe retelele
        sociale, date despre afacere (denumire, sediu, situatii financiare,
        documente si acte constitutive, codul caen), copii dupa documentele de
        identitate (CI, certificat de nastere, certificat de casatorie,
        pasaport), diplomele de studii, adeverinte medicale, informatii despre
        locul de munca.
      </ConditionsText>
      <ConditionsText>
        Alte informatii pe care le colectam sunt legate de activitatea ta pe
        website sau in aplicatiile noastre. Informatiile acestea le obtinem prin
        intermediul altor mijloace (cookies, google analytics, google adwords,
        facebook pixel) sunt de natura timpului petrecut pe site, paginile
        vizitate, browser-ul folosit traficul de pe si inspre site (mai multe
        despre aceste informatii poti gasi aici
        <Anchor
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop%hl=ro"
          target="_blank"
        >
          Google cookies
        </Anchor>
        si aici
        <Anchor
          href="https://www.facebook.com/policies/cookies/"
          target="_blank"
        >
          Facebook cookies
        </Anchor>
        ).
      </ConditionsText>
      <ConditionsText>
        Informatiile colectate sunt stocate si procesate prin intermediul
        site-ului in baze de date si a calculatoarelor proprii. Protejam
        informatiile tale prin toate mijloacele pe care le avem la dispozitie
        pentru a nu permite accesul neautorizat, dezvaluirea sau alterarea
        acestora.
      </ConditionsText>
      <ConditionsHeading>Cum folosim informatiile colectate?</ConditionsHeading>
      <ConditionsText>
        Colectam aceste informatii pentru a-ti oferi servicii si informatii
        personalizate si pentru o experienta cat mai placuta pe site-ul nostru.
        Utilizam aceste informatii pentru:
      </ConditionsText>
      <ConditionsText>
        <ul style={{ marginLeft: '24px' }}>
          <li>
            Asistarea ta in obtinerea unor solutii eficiente legate de
            dezvoltarea afacerii;
          </li>
          <li>
            Personalizarea si imbunatatirea materialelor, aplicatiilor si
            solutiilor furnizate;
          </li>
          <li>
            Transmiterea de informatii, invitatii la cursurile si evenimentele
            organizate sau alte materiale promotionale;
          </li>
          <li>
            Contactarea telefonica, email sau posta pentru transmiterea
            informatiilor solicitate de tine;
          </li>
          <li>Transmiterea unor oferte de pret personalizate;</li>
          <li>Oferirea accesului la continut sau informatii private;</li>
          <li>
            Realizarea unor statistici agregate legate de performantele
            site-ului nostru;
          </li>
          <li>Facilitarea colaborarilor comerciale;</li>
          <li>
            Evaluarea respectarii conditiilor de eligibilitate in cazul
            finantarilor nerambursabile.
          </li>
        </ul>
      </ConditionsText>
      <ConditionsText>
        Nu vindem, inchiriem sau instrainam datele tale, iar daca va fi cazul in
        viitor nu o vom face fara acordul tau scris. Am putea combina
        informatiile primite de la tine cu cele primite din partea altor
        persoane pentru a imbunatatii modul in care facem publicitatea. Daca nu
        doresti sa primesti informatii publicitare din partea noastra sau sa
        participi la programele de imbunatatire a serviciilor, marcheaza aceste
        preferinte la inregistrarea pe site. Vom respecta preferintele tale in
        acest sens.
      </ConditionsText>
      <ConditionsText>
        Prin completarea si transmiterea unui chestionar, formular de contact
        sau alt document de pe site, denumite in continuare "formulare", tu ca
        si Utilizator esti de acord cu formele noastre preferate de comunicare
        (telefonic sau e-mail) prin care ne desfasuram activitatea. Emailurile
        primite ulterior transmiterii formularelor de contact sau chestionarelor
        au rol de informare si nu reprezinta nicio forma de angajament
        contractual din partea ta. Aceaste notificare se fac de obicei
        electronic (e-mail) sau telefonic.
      </ConditionsText>
      <ConditionsText>
        Compania noastra nu isi asuma in niciun fel raspunderea si nu garanteaza
        posibilitatea accesarii finantarilor nerambursabile sau succesul unui
        proiect de consultanta in proportie de 100%. Informatiile,
        chestionarele, documentele si restul formularelor prezente pe acest site
        sunt prezentate cu titlu informativ. Compania noastra nu isi asuma
        niciun fel de raspundere pentru demersurile, formalitatile si costurile
        angajate de tine, Utilizatorul, in baza informatiilor prezentate pe
        acest site. Pentru ca suntem ocupati cu furnizarea unor servicii de
        calitate si datorita schimbarilor legislative, fiscale sau politice
        foarte dese informatiile de pe siteul nostru s-ar putea sa nu fie
        intotdeauna actualizate. Iti recomandam ca intotdeauna sa iei legatura
        in mod direct cu noi sau sa apelezi la sursele oficiale de informare
        (site-urile ministerelor, institutiilor publice sau altor entitati).
      </ConditionsText>
      <ConditionsHeading>
        Cum transmitem datele tale personale altor persoane?
      </ConditionsHeading>
      <ConditionsText>
        In anumite situatii vom comunica unor terte persoane (contabili,
        avocati, colaboratori, parteneri sau alte entitati) datele tale de
        contact pentru facilitarea unor servicii, doar in urma acordului sau
        solicitarii tale exprese. Aceste acorduri le vom obtine cel mai adesea
        verbal si prin intermediul contractelor pe care le vom incheia. Nu
        transferam si procesam datele tale personale in afara Romaniei.
      </ConditionsText>
      <ConditionsHeading>
        Securitatea si durate de pastrare a datelor
      </ConditionsHeading>
      <ConditionsText>
        Ne dam concursul pentru a tine datele tale personale cat mai sigure
        posibile in limita maxima a capacitatilor noastre, a capacitatii
        furnizorilor nostri si a evolutiei tehnologice. Datele tale personale
        vor fi retinute atat timp cat va fi necesar pentru unul din scopurile
        mentionate mai sus sau pana la finalizarea eventualelor dispute
        litigioase.
      </ConditionsText>
      <ConditionsText>
        Daca ai transmis informatii prin intermediul formularelor de contact sau
        chestionarelor de nevoi si doresti ca aceste date sau informatii sa fie
        sterse din bazele noastre de date poti sa ne soliciti in scriis pe
        adresa de email de mai jos acest lucru si ne vom conforma. Inainte de a
        face acest lucru iti vom confirma aceasta solicitare cu tine pentru a-ti
        da posibilitea sa recuperezi o parte din informatiile transmise. In
        anumite cazuri, atunci cand comunicarile dintre noi si tine nu se
        concretizeaza intr-un acord scris, e posibil sa sterge datele tale
        personale in termen de 30 de zile de la data transmiterii acestora
      </ConditionsText>
      <ConditionsText>
        Documetele oficiale transmise si materialele rezultate in urma
        contractelor de consultanta implementate vor fi pastrate si arhivate
        corespunzator in conformitate cu legislatia actualizata in domeniu.
      </ConditionsText>
      <ConditionsHeading>Dreptul de proprietate intelectuala</ConditionsHeading>
      <ConditionsText>
        Continutul acestui site, incluzand fara a se limita la acestea: logouri
        si alte materiale de identitate vizuala, imagini, texte, continut,
        chestionare, informatii, materiale video si multimedia sunt proprietatea
        exclusiva a PTIC fiindu-i rezervate toate drepturile obtinute in acest
        sens in mod direct sau indirect (prin licente de utilizare si/sau
        publicare).
      </ConditionsText>
      <ConditionsHeading>Drepturile si obligatiile tale</ConditionsHeading>
      <ConditionsText>
        Ai dreptul sa accesezi datele tale personale, sa le stergi, sa alegi
        modul in care sunt procesate si folosite. Daca doresti sa iti manifesti
        aceste drepturi te rog sa ne contactezi pe adresa de email mai jos si
        iti vom raspunde solicitarii tale in conformitate cu legislatia actuala.
      </ConditionsText>
      <ConditionsText>
        In cazul newsletter-elor poti sa te dezabonezi oricand alegand aceasta
        optiune din emailul receptionat sau din contul tau creat la noi pe site.
      </ConditionsText>
      <ConditionsText>
        Tie, Utilizatorului, nu iti este permisa copierea, distribuirea,
        publicarea, transferul catre terte parti, modificarea si/sau altfel
        alterarea, utilizarea, legarea la, expunerea, includerea oricarui
        continut in orice alt ConditionsText decat cel original intentionat de
        PTIC, includerea oricarui continut in afara siteului, indepartarea
        insemnelor care semnifica dreptul de autor al PTIC asupra continutului
        precum si participarea la transferul, vanzarea, distributia unor
        materiale realizate prin reproducerea, modificarea sau afisarea
        continutului, decat cu acordul scris expres al PTIC.
      </ConditionsText>
      <ConditionsHeading>
        Interactiunea prin intermediul siteului
      </ConditionsHeading>
      <ConditionsText>
        Inscrierea de recenzii (reviews), comentarii, intrebari se poate face,
        de catre Utilizatori, in casutele de comentarii si recenzii.
        Informatiile inscrise pot fi atat pozitive, cat si negative, si se vor
        referi doar la caracteristicile si modul de folosire al unui serviciu.
        In momentul publicarii unei recenzii, comentariu sau intrebare
        Utilizatorii acorda PTIC o licenta neexclusiva, perpetuua, irevocabila,
        nelimitata teritorial si dau dreptul PTIC de a utiliza, reproduce,
        modifica, adapta, publica, traduce, distribui si de a afisa acest
        continut.
      </ConditionsText>
      <ConditionsText>
        Fiecare Utilizator, in momentul inscrierii de recenzii (reviews),
        comentarii, intrebari in sectiunile mentionate, se angajeaza sa respecte
        urmatoarele reguli:
      </ConditionsText>
      <ConditionsText>
        <ul style={{ marginLeft: '24px' }}>
          <li>
            Sa faca referiri doar la caracteristicile unui anumit serviciu,
            evitand informatii legate de aspecte ce se pot schimba (pret sau
            oferte promotionale) sau informatii care tin de modul de desfasurare
            a activitatii;
          </li>
          <li>
            Sa foloseasca doar limba romana. Sunt permise si cuvintele sau
            expresiile care, desi nu sunt considerate romanesti, sunt de larga
            utilizare in toate mediile referitoare la domeniul respectiv (ex.:
            mouse, notebook, plug and play);
          </li>
          <li>
            Sa utilizeze un limbaj corespunzator, neofensator, fara termeni care
            pot jigni sau afecta oricare alt Utilizator sau personalul PTIC;
          </li>
          <li>
            Sa se asigure de incadrarea corecta a continutului introdus pe site;
          </li>
          <li>
            Sa se asigure ca informatiile introduse de ei sunt realiste,
            corecte, neinselatoare si in conformitate cu legile aplicabile,
            respectand astfel inclusiv drepturile altor parti, drepturile de
            autor, de marca comerciala, de licenta sau alte drepturi de
            proprietate, publicitate sau intimitate;
          </li>
          <li>
            Sa foloseasca aceasta facilitate doar pentru a comunica sau a obtine
            detalii suplimentare referitoare la un anumit serviciu de pe site
            fara a face referire catre alte firme ce promoveaza vanzarea si
            cumpararea de servicii asemanatoare;
          </li>
          <li>
            Sa nu furnizeze sau sa solicite, in niciun mod si in nicio masura,
            date personale sau orice alta informatie care poate determina
            dezvaluirea acestor date personale;
          </li>
          <li>
            Sa nu inscrie informatii si/sau detalii despre URL-uri (legaturi)
            din alte site-uri ce desfasoara aceeasi activitate ca PTIC;
          </li>
          <li>
            Sa nu incerce fraudarea serviciilor puse la dispozitie de catre PTIC
            sau sa inscrie recenzii, comentarii, intrebari, raspunsuri care sa
            contina materiale de natura publicitara;
          </li>
        </ul>
      </ConditionsText>
      <ConditionsText>
        Cand o recenzie,comentariu, intrebare sau raspuns este semnalat ca avand
        un continut inadecvat, dintr-o perspectiva strict subiectiva, acest
        continut va fiexaminat cu atentie pentru a determina daca incalca
        termeni si conditiile de utilizare a siteului.
      </ConditionsText>
      <ConditionsText>
        Te asiguram ca nu am transmis si nu vom transmite niciodata mesaje
        nesolicitate sau de natura publicitara si ca vom cere acordul tau in
        prealabil pentru utilizarea oricarei informatii cu caracter personal
        (cel mai adesea in scopul furnizarii de recomandari).
      </ConditionsText>
      <ConditionsText>
        Pentru mai multe informatii despre politica noastra de
        confidentialitate, termenii de utilizare a siteului sau alte informatii
        ne poti contacta pe:
      </ConditionsText>
      <ConditionsText>
        <ul style={{ marginLeft: '24px' }}>
          <li>
            Posta: Cluj Napoca, str. Ferdinand nr. 22-26, et. 3, Cluj, Romania
          </li>
          <li>
            Email: contact
            <Anchor href="mailto:contact@ptic.ro"> ptic.ro </Anchor>
          </li>
          <li>Mobil: {'{0}'}742 61 76 76</li>
        </ul>
      </ConditionsText>
      <ConditionsText>Ultima actualizare: 16.07.2020</ConditionsText>
    </ConditionsContainer>
  </>
);

export default Conditions;
