import update from 'immutability-helper';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';
import {
  addContactFormUrl as doAddContactFormUrl,
  getLastContactForm as doGetLastContactForm,
} from '../services/contactForm';

// Actions
const START_LOAD_LAST_CONTACT_FORM =
  'contact-form/START_LOAD_LAST_CONTACT_FORM';
const COMPLETE_LOAD_LAST_CONTACT_FORM =
  'contact-form/COMPLETE_LOAD_LAST_CONTACT_FORM';
const FAIL_LOAD_LAST_CONTACT_FORM = 'contact-form/FAIL_LOAD_LAST_CONTACT_FORM';

const START_ADD_CONTACT_FORM_URL = 'contact-form/START_ADD_CONTACT_FORM_URL';
const COMPLETE_ADD_CONTACT_FORM_URL =
  'contact-form/COMPLETE_ADD_CONTACT_FORM_URL';
const FAIL_ADD_CONTACT_FORM_URL = 'contact-form/FAIL_ADD_CONTACT_FORM_URL';

const RESET = 'resources/RESET';

// Initial state
const initialState = {
  isDeleting: false,
  isLoading: false,
  isEditing: false,
  isCreating: false,
  contactForm: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_LAST_CONTACT_FORM:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_LAST_CONTACT_FORM:
      return update(state, {
        $merge: {
          isLoading: false,
          contactForm: action.contactForm,
        },
      });

    case FAIL_LOAD_LAST_CONTACT_FORM:
      return update(state, {
        $merge: {
          isLoading: false,
          contactForm: undefined,
        },
      });

    case START_ADD_CONTACT_FORM_URL:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_CONTACT_FORM_URL:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_CONTACT_FORM_URL:
      return update(state, { $merge: { isCreating: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startLoadLastContactForm = () => ({
  type: START_LOAD_LAST_CONTACT_FORM,
});

const completeLoadLastContactForm = (contactForm) => ({
  type: COMPLETE_LOAD_LAST_CONTACT_FORM,
  contactForm,
});

const failLoadLastContactForm = () => ({
  type: FAIL_LOAD_LAST_CONTACT_FORM,
});

const startAddContactFormUrl = () => ({
  type: START_ADD_CONTACT_FORM_URL,
});

const completeAddContactFormUrl = () => ({
  type: COMPLETE_ADD_CONTACT_FORM_URL,
});

const failAddContactFormUrl = () => ({
  type: FAIL_ADD_CONTACT_FORM_URL,
});

export const getLastContactFormUrl = () => (dispatch) => {
  dispatch(startLoadLastContactForm());
  return doGetLastContactForm()
    .then((res) => {
      dispatch(completeLoadLastContactForm(res.data));
    })
    .catch(() => dispatch(failLoadLastContactForm()));
};

export const addContactFormUrl = (values) => (dispatch) => {
  dispatch(startAddContactFormUrl());
  return doAddContactFormUrl(values)
    .then(() => {
      dispatch(completeAddContactFormUrl());
      dispatch(
        notifySuccess('Link-ul catre formular a fost adaugat cu succes!')
      );
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAddContactFormUrl());
    });
};
