import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as ChatGptHero } from '../../common/assets/icons/chat_gpt_welcome.svg';
import {
  H2,
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';

const ChatWelcomeScreen = ({ skipWelcomeScreen }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <Box sx={{ padding: '20px' }}>
      <H2>Asistentul tau AI</H2>
      <P>
        Folosind asistentul tău AI poți să pui întrebări și să primești
        răspunsuri folosind inteligența artificială ChatGPT
      </P>
    </Box>

    <ChatGptHero />

    <P>
      Atenție! Momentan, conversațiile cu ChatGPT sunt salvate și accesibile
      timp de 4 ore.
    </P>

    <PrimaryButton onClick={skipWelcomeScreen}>Continua ➔</PrimaryButton>
  </Box>
);

export default ChatWelcomeScreen;
