import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSun, faX } from '@fortawesome/free-solid-svg-icons';
import { MarginContainer } from '../../mrl/components/styled/MrlStyled';
import CustomLabel from './CustomLabel';
import { theme } from '../styles';

const StyledDiv = styled.div`
  border: 1px solid rgb(116, 163, 254);
  border-radius: 4px;
  flex-wrap: wrap;
  border: 1px solid ${(props) => (props.isInvalid ? '#fa5c7c' : '#74A3FE')};
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 8vw;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  color: black;
`;

const BlockDropdown = ({
  options,
  value,
  onChange,
  label,
  tooltipDescription,
  fieldRef,
  width,
  isInvalid,
  classes,
  marginBottom,
}) => {
  const handleChange = (selectedOption) => {
    if (onChange) onChange(selectedOption);
  };

  const iconColorHandler = (selectedItem, color) => {
    if (!value) return color;
    if (selectedItem.label === value.label) return 'white';

    return !value || selectedItem.label !== value.label ? color : 'white';
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%' }} ref={fieldRef}>
        <StyledDiv isInvalid={isInvalid}>
          <MarginContainer display="flex">
            <CustomLabel
              label={label}
              tooltipDescription={tooltipDescription}
              classes={classes}
              marginBottom={marginBottom}
            />
          </MarginContainer>
          <MarginContainer
            display="flex"
            justifyContent="center"
            marginBottom="1vh"
          >
            {options.map((x, index) => (
              <ButtonContainer
                key={index}
                onClick={() => handleChange(x)}
                style={{
                  textTransform: 'capitalize',
                  width: `${width || 'auto'}`,
                  margin: '5px 10px',
                  background: value
                    ? x.value === value.value && theme.blueAccent
                    : '',
                  color: value ? x.value === value.value && 'white' : '',
                }}
              >
                {x.label === 'Confirmat' && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={iconColorHandler(x, 'green')}
                  />
                )}
                {x.label === 'Infirmat' && (
                  <FontAwesomeIcon
                    icon={faX}
                    color={iconColorHandler(x, 'red')}
                  />
                )}
                {x.label === 'Informație nouă' && (
                  <FontAwesomeIcon
                    icon={faSun}
                    color={iconColorHandler(x, '#FFAA6C')}
                  />
                )}

                {x.label}
              </ButtonContainer>
            ))}
          </MarginContainer>
        </StyledDiv>
      </div>
    </>
  );
};
export default BlockDropdown;
