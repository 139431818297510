import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import infoTooltip from '../../common/assets/icons/dropdownInfo.svg';

const CustomedLabel = styled.label`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '10px')};
  transition: all 0.1s ease-in-out;
  color: #74a3fe;
`;

const Img = styled.img`
  width: ${({ width }) => width && width}; //17
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '5px'};
  height: ${({ height }) => height && height}; //15
  cursor: pointer;
  margin-left: 10px;
`;

const CustomLabel = ({
  label,
  tooltipDescription,
  classes,
  marginBottom,
  marginLeft,
  style,
  labelFor,
  isFocused,
}) => (
  <CustomedLabel
    className={`label ${classes || ''}`}
    marginLeft={marginLeft}
    style={style}
    htmlFor={labelFor}
  >
    {label}
    {!!tooltipDescription && (
      <OverlayTrigger
        trigger={['hover', 'hover']}
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip" className="tooltip-modal">
            {tooltipDescription}
          </Tooltip>
        }
      >
        <Img
          marginBottom={marginBottom}
          src={infoTooltip}
          alt={infoTooltip}
          width={!isFocused ? '15px' : '11px'}
          height={!isFocused ? '17px' : '12px'}
        />
      </OverlayTrigger>
    )}
  </CustomedLabel>
);
export default CustomLabel;
