import { Box } from '@mui/material';
import { QuestionBigBoxAnswer } from './styledComponents/registrationForm';
import React from 'react';

const RegistrationBigBoxQuestion = ({
  currentStepQuestionData,
  handleClickAnswer,
  selectedAnswers,
  preselectedAnswer,
}) => {
  const thereIsAPreselectedAnswer = !!preselectedAnswer;

  const handleClick = (selectedAnswer, supportingMessage) => {
    if (thereIsAPreselectedAnswer) {
      return;
    } else {
      handleClickAnswer(selectedAnswer, supportingMessage);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {currentStepQuestionData.answerOptions.map((option) => (
        <QuestionBigBoxAnswer
          selected={
            preselectedAnswer === option.value ||
            selectedAnswers.includes(option.value)
          }
          key={option.value}
          onClick={() => handleClick(option.value, option?.onSelectMessage)}
        >
          <h3>{option.label}</h3>
          <p>{option?.supportingText}</p>
        </QuestionBigBoxAnswer>
      ))}
    </Box>
  );
};

export default RegistrationBigBoxQuestion;
