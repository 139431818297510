import styled from 'styled-components';

export const InputContainer = styled.div`
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 2vh;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  margin-right: 1vw;
  background-color: ${(props) => props.background && props.background};
`;
