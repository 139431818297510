import { v4 as uuidv4 } from 'uuid';
import { CHAT_ROLE_USER } from './constants';

export const updateChatSessionState = async ({
  chatSession,
  setChatSession,
  userMessage,
  resetForm,
  currentUserId,
  postChatGptConversation,
}) => {
  const currentChatSessionId = chatSession.sessionId;
  const conversationHistoryWithNewUserMessage = [
    ...chatSession.messages,
    { content: userMessage, role: CHAT_ROLE_USER },
  ];

  if (!currentChatSessionId) {
    const newSessionId = uuidv4();
    setChatSession({
      messages: conversationHistoryWithNewUserMessage,
      sessionId: newSessionId,
      timestamp: Date.now(),
    });
  } else {
    setChatSession((prevState) => ({
      ...prevState,
      messages: conversationHistoryWithNewUserMessage,
    }));
  }

  resetForm();

  const payload = {
    messages: conversationHistoryWithNewUserMessage,
    userId: currentUserId,
    sessionId: currentChatSessionId,
  };

  const gptResponse = await postChatGptConversation(payload);
  if (!gptResponse) return;

  const conversationHistoryWithNewBotMessage = [
    ...conversationHistoryWithNewUserMessage,
    gptResponse,
  ];
  setChatSession((prevState) => ({
    ...prevState,
    messages: conversationHistoryWithNewBotMessage,
  }));
};
