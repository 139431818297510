import { Formik, useField } from 'formik';
import { Grid, TextField } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import { postNewSwot, editSwotById } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { SWOT_TYPES, SWOT_DROPDOWN_OPTIONS } from '../../constants';
import { RenderDropdownField } from '../../../common/components/forms/Fields';

const RenderTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Grid>
  );
};

const SwotForm = ({
  selectedSwotElement,
  teamId,
  handleCloseModal,
  postNewSwot,
  swotType,
  editSwotById,
}) => {
  const schema = Yup.object().shape({
    swotType: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
  });

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      title: values.description,
    };

    if (selectedSwotElement) {
      editSwotById(selectedSwotElement._id, payload, teamId);
    } else {
      postNewSwot(payload, teamId);
    }

    handleCloseModal();
  };

  const initialValues = selectedSwotElement
    ? {
        swotType: selectedSwotElement.swotType,
        description: selectedSwotElement.description,
      }
    : {
        swotType: swotType || SWOT_TYPES[0].value,
        description: '',
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderDropdownField
            id="swotType"
            name="swotType"
            label="Type"
            options={SWOT_DROPDOWN_OPTIONS}
          />
          <RenderTextField
            id="description"
            label="Descriere"
            multiline
            rows={4}
          />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewSwot,
  editSwotById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwotForm);
