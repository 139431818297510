import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypotheses } from '../../hypotheses/ducks';
import { Resolver } from '../../core/components';
import { Loading } from '../../common/components/styled';
import { DocumentTitle } from '../../common/components';
import { currentTeamIdSelector } from '../../common/ducks';
import { loadOpportunityAnalysis } from '../../opportunityAnalysis/ducks';
import BusinessModelCanvasPage from './BusinessModelCanvasPage';
import { useHistory } from 'react-router-dom';

const BusinessModelCanvasPageResolver = (props) => {
  const { loadHypotheses, loadOpportunityAnalysis, teamId, role, userTeams } =
    props;
  const { push } = useHistory();

  useEffect(() => {
    if (role === 'ADMIN') push('/admin/users');
  }, [role, push]);
  const loadDependencies = () =>
    teamId && userTeams && role !== 'ADMIN'
      ? Promise.all([loadHypotheses(teamId), loadOpportunityAnalysis(teamId)])
      : Promise.resolve();

  return (
    <>
      <DocumentTitle>Business Model Canvas</DocumentTitle>
      <Resolver
        successComponent={BusinessModelCanvasPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

BusinessModelCanvasPageResolver.propTypes = {
  loadHypotheses: PropTypes.func.isRequired,
  teamId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  userTeams: state.common.userTeams.userTeams,
  role: get(state.account.login, 'user.role'),
});

const mapDispatchToProps = { loadHypotheses, loadOpportunityAnalysis };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessModelCanvasPageResolver)
);
