import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { getAllComments } from '../ducks';
import { currentTeamIdSelector } from '../../common/ducks';
import CommentsAndNotificationsHeader from './CommentsAndNotificationsHeader';
import CommentsAndNotificationsTable from './CommentsAndNotificationsTable';
import { getCommentsCsv } from '../../requests/notificationsAndComments';
import { useApi } from '../../hooks/useApi';
import { downloadCSV } from '../../common/functions/genericHelpers';
import { formatDate } from '../../common/functions/dateHelpers';

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.userProfile.userProfile.userId._id
  );
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const allComments = useSelector((state) => state.comments.commentList) || [];
  const [readFilterValue, setReadFilterValue] = useState(false);
  const [, fetchCsv] = useApi(getCommentsCsv);

  const handleCsvDownload = async () => {
    const csvData = await fetchCsv({ params: { teamId } });

    const today = formatDate(new Date());
    downloadCSV(csvData, `comments-${today}.csv`);
  };

  const fetchComments = async () => {
    if (!currentUserId || !teamId) return;

    dispatch(
      getAllComments({
        teamId,
        filterParams: {
          ...(readFilterValue === 'all' ? {} : { isRead: readFilterValue }),
          isMainThread: true,
        },
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    fetchComments();
  }, [currentUserId, teamId, readFilterValue]);

  return (
    <Box>
      <CommentsAndNotificationsHeader
        readFilterValue={readFilterValue}
        setReadFilterValue={setReadFilterValue}
        commentsLength={allComments.length}
        handleCsvDownload={handleCsvDownload}
      />
      <Box sx={{ overflowY: 'scroll', height: '750px' }} className="scrollbar">
        <CommentsAndNotificationsTable
          comments={allComments}
          fetchComments={fetchComments}
        />
      </Box>
    </Box>
  );
};

export default NotificationsPage;
