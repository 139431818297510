import React from 'react';
import { Formik } from 'formik';
import { SaveIconOutlined } from '../../../common/assets/icons';
import { DETAILS_IMPACT_OPTIONS } from '../../constants';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import CustomRadioGroup from '../../../common/components/forms/formComponents/CustomRadioGroup';
import CustomCounter from '../../../common/components/forms/formComponents/CustomCounter';
import CustomOptionsGroup from '../../../common/components/forms/formComponents/CustomOptionsGroup';
import GenericFormWrapper from '../../../common/components/forms/formComponents/GenericFormWrapper';

export const activityTypeOptions = [
  { value: 'faceToFace', label: 'Față în față' },
  { value: 'online', label: 'Online' },
  { value: 'byPhone', label: 'Telefonic' },
  { value: 'email', label: 'Email' },
  { value: 'hybrid', label: 'Hibrid' },
  { value: 'deskResearch', label: 'Desk research' },
  { value: 'anotherMethod', label: 'Another method' },
];

export const categoryOptions = [
  { value: 'listaBusinessAngels', label: 'Listă business angels' },
  { value: 'listaFonduriDeInvestitii', label: 'Listă fonduri de investiții' },
  { value: 'termSheetAgreement', label: 'Term sheet agreement' },
  { value: 'listaFinantariPublice', label: 'Lista finanțări publice' },
  {
    value: 'intentieScrisoareCumparare',
    label: 'Intenție/scrisoare cumpărare',
  },
  { value: 'ordinComandaFerma', label: 'Ordin/comanda fermă' },
  { value: 'documentVanzare', label: 'Document vânzare' },
  {
    value: 'documentatieTehnicaSolutie',
    label: 'Documentație tehnică soluție',
  },
  { value: 'feedbackDemo', label: 'Feedback demo' },
  { value: 'analizaDateDemo', label: 'Analiza date demo (Analytics)' },
  { value: 'politicaDePret', label: 'Politică de preț' },
  {
    value: 'chestionarDescoperireNevoi',
    label: 'Chestionar descoperire nevoi',
  },
  { value: 'structuraInterviu', label: 'Structură interviu' },
  { value: 'analiza', label: 'Analiză' },
  { value: 'detaliiDeContact', label: 'Detalii de contact' },
  { value: 'imagine', label: 'Imagine' },
  { value: 'inregistrareAudio', label: 'Înregistrare audio' },
  { value: 'inregistrareVideo', label: 'Înregistrare video' },
  { value: 'librarieDeDate', label: 'Librărie de date' },
  { value: 'minutaInterviuClienti', label: 'Minută interviu clienți' },
  { value: 'minutaDiscutie', label: 'Minută discuție' },
  { value: 'mockupSchita', label: 'Mockup / Schiță' },
  { value: 'prototipMVPLoFi', label: 'Prototip / MVP Lo-Fi' },
  { value: 'prototipMVPHiFi', label: 'Prototip / MVP Hi-fi' },
  { value: 'prezentareIdee', label: 'Prezentare idee' },
  {
    value: 'pitchDeckPentruInvestitori',
    label: 'Pitch deck pentru investitori',
  },
  { value: 'prezentareEchipa', label: 'Prezentare echipă' },
  { value: 'proofOfConcept', label: 'Proof of Concept' },
  { value: 'raspunsChestionar', label: 'Răspuns chestionar' },
  { value: 'raport', label: 'Raport' },
  { value: 'studiuDePiata', label: 'Studiu de piață' },
  {
    value: 'strategieDePenetrareAPietei',
    label: 'Strategie de penetrare a pieței',
  },
  { value: 'cashFlow', label: 'Cash flow' },
  {
    value: 'argumentareDimensiuneaPietei',
    label: 'Argumentare dimensiunea pieței',
  },
  { value: 'intelegereaAsociatilor', label: 'Înțelegerea asociaților' },
  { value: 'analizaSWOT', label: 'Analiza SWOT' },
  { value: 'analizaPESTLE', label: 'Analiza PESTLE' },
  { value: 'analiza5Forte', label: 'Analiza 5 Forte' },
  { value: 'analizaTrenduri', label: 'Analiza trenduri' },
  { value: 'analizaCompetitiei', label: 'Analiza competiției' },
  { value: 'solutionRoadMap', label: 'Solution road map' },
  { value: 'design', label: 'Design' },
  { value: 'designSolutie', label: 'Design soluție' },
  {
    value: 'specificatiiTehniceSolutie',
    label: 'Specificații tehnice soluție',
  },
  { value: 'userFlow', label: 'User flow' },
  { value: 'customerJourney', label: 'Customer journey' },
  { value: 'listaClientiPotentiali', label: 'Listă clienți potențiali' },
  { value: 'altaCategorie', label: 'Altă categorie' },
];

export const problemSeverityOptions = [
  { value: 1, label: '1 - Nu știe că are o problemă' },
  { value: 2, label: '2 - Știe că are o problemă' },
  {
    value: 3,
    label:
      '3 - A creat sau lucrează la o soluție proprie pentru rezolvarea problemei',
  },
  { value: 5, label: '4 - Caută o soluție pentru rezolvarea problemei' },
  {
    value: 6,
    label: '5 - A cheltuit sau cheltuie bani pentru rezolvarea problemei',
  },
];

const DetailsForm = ({ handleSubmit, formValues, handleUpdateDetail }) => {
  const validate = (values) => {
    const errors = {};

    // Validate description
    if (!values.description) {
      errors.description = 'Câmp obligatoriu';
    }

    // Validate activityType
    if (!values.activityType) {
      errors.activityType = 'Câmp obligatoriu';
    }

    // Validate category
    if (!values.category) {
      errors.category = 'Câmp obligatoriu';
    }

    if (values.category === 'minutaInterviuClienti') {
      // Validate problemSeverity
      if (!values.problemSeverity) {
        errors.problemSeverity = 'Câmp obligatoriu';
      }

      // Validate levelOfInterest
      if (!values.levelOfInterest) {
        errors.levelOfInterest = 'Câmp obligatoriu';
      }
    }

    // Validate dynamic links
    Array.from({ length: values.linkNumber }).forEach((_, index) => {
      const linkName = `link${index + 1}`;
      if (!values[linkName]) {
        errors[linkName] = 'Câmp obligatoriu';
      }
    });

    return errors;
  };

  const initialValues = formValues || {
    description: '',
    activityType: '',
    category: '',
    problemSeverity: [],
    levelOfInterest: null,
    contactDisponibility: true,
    linkNumber: 1,
    link1: '',
    link2: '',
    link3: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formValues ? handleUpdateDetail : handleSubmit}
      validate={validate}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <GenericFormWrapper>
            <CustomTextField
              id="description"
              label="Descrie detaliul"
              placeholder="Descrie detaliul.."
              rows={3}
              multiline
            />
            <CustomSelectField
              id="activityType"
              placeholder="Alege tipul contractului"
              label="Tip activitate"
              options={activityTypeOptions}
            />
            <CustomSelectField
              id="category"
              placeholder="Alege categoria detaliului"
              label="Categorie detaliu"
              options={categoryOptions}
            />
            {values.category === 'minutaInterviuClienti' && (
              <>
                <CustomRadioGroup
                  id="problemSeverity"
                  label="Alege severitatea problemei:"
                  options={problemSeverityOptions}
                />
                <CustomRadioGroup
                  id="levelOfInterest"
                  label="Alege nivelul de interes în cumpărarea soluției:"
                  options={DETAILS_IMPACT_OPTIONS}
                />
                <CustomOptionsGroup
                  id="contactDisponibility"
                  label="Există disponibilitate de contact ulterior?"
                  customStyles={{ padding: '2px 0 2px 14px' }}
                />
              </>
            )}

            <CustomCounter
              id="linkNumber"
              label="Număr de link-uri"
              customStyles={{ padding: '10px' }}
            />
            {Array.from({ length: values.linkNumber }).map((_, index) => (
              <CustomTextField
                key={index}
                id={`link${index + 1}`}
                label="Link"
              />
            ))}
          </GenericFormWrapper>
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {formValues ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default DetailsForm;
