import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { loadProfile, editProfile } from '../../ducks';
import { Title, DeleteButtonSmall } from '../../../common/components/styled';
import { CustomLabel } from '../../../core/components';
import { RoleForm } from '../forms';

const IdeeaProfileModal = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="md"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px" fontWeight="bold">
            Rol
            <CustomLabel tooltipDescription="ROL" />
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <RoleForm />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.userProfile.isEditing,
  userProfile: state.userProfile.userProfile,
});

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IdeeaProfileModal)
);
