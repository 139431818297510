import React from 'react';
import { Formik } from 'formik';
import { RenderTextField } from '../../opportunityAnalysis/components/forms/TrendsForm';
import { RenderDropdownField } from '../../common/components/forms/Fields';
import { ideaCategoryOptions } from '../../team/constants';

const DescribeIdeaForm = ({ handleClickAnswer }) => {
  const initialValues = {
    name: '',
    description: '',
    ideaCategory: [],
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Câmp obligatoriu';
    }

    if (!values.description) {
      errors.description = 'Câmp obligatoriu';
    }

    if (!values?.ideaCategory || values?.ideaCategory?.length < 1) {
      errors.ideaCategory = 'Camp obligatoriu';
    }

    if (!Object.keys(errors).length) {
      handleClickAnswer({
        ...values,
        questionAsAnswer: true,
        createIdea: true,
      });
    }

    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validateForm}>
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderTextField id="name" label="Nume idee" />
          <RenderTextField
            id="description"
            label="Descriere idee"
            multiline
            rows={2}
          />
          <RenderDropdownField
            values={values}
            setFieldValue={setFieldValue}
            label="Industrie"
            placeholder="Adauga maxim 3"
            name="ideaCategory"
            options={ideaCategoryOptions}
            md={12}
            multiple
          />
        </form>
      )}
    </Formik>
  );
};

export default DescribeIdeaForm;
