import React from 'react';

import ModalComponent from '../../../common/components/ModalComponent';
import ActivityTaskForm from '../ActivityTaskForm';

const AddActivityTaskModal = ({
  handleClose,
  isModalOpen,
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
  isEditMode,
  editedTask,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={handleClose}
    title={isEditMode ? 'Editeaza task' : 'Adauga un task'}
  >
    <ActivityTaskForm
      handleUpdate={handleUpdate}
      formValues={formValues}
      handleSubmit={handleSubmit}
      teamMembers={teamMembers}
      isEditMode={isEditMode}
      editedTask={editedTask}
    />
  </ModalComponent>
);

export default AddActivityTaskModal;
