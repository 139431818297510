import React from 'react';
import { Formik, useField } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined, UpwardsTrend } from '../../../common/assets/icons';
import { postNewTrend, editTrendById } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import RenderRichText from '../../../common/components/RichEditor';

export const RenderTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Grid>
  );
};

export const RenderRoundedTextField = ({ label, id }) => {
  const [field, meta] = useField(id);

  return (
    <div>
      <input
        {...field} // Spread the field object here
        style={{
          background: '#efeff4',
          borderRadius: '20px',
          border: 'none',
          padding: '10px',
          boxSizing: 'border-box',
          marginRight: '10px',
        }}
        id={id}
        placeholder={label}
      />
      {/* You can also show error messages here */}
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const RenderRoundedSelectField = ({ label, id, options }) => {
  const [field, meta] = useField(id);

  return (
    <div>
      <select
        {...field} // Spread the field object here
        placeholder={label}
        style={{
          background: '#efeff4',
          borderRadius: '20px',
          border: 'none',
          padding: '10px',
          boxSizing: 'border-box',
          minWidth: '150px',
          marginRight: '10px',
          paddingRight: '20px',
        }}
        id={id}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* Error message */}
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const CustomButton = ({ active, favorable, ...props }) => {
  const defaultStyle = {
    borderRadius: '4px',
    background: active ? 'rgba(116, 163, 254, 0.2)' : 'transparent',
    width: '42px',
    height: '42px',
    border: '1px solid #74A3FE',
    margin: '0 5px',
    ...(!favorable && { transform: 'scaleY(-1)' }),
  };

  return (
    <Button style={defaultStyle} {...props}>
      <UpwardsTrend fill={favorable ? 'green' : 'red'} />
    </Button>
  );
};

export const RenderRadioGroup = ({ id, label, md = 12 }) => {
  const [field, meta] = useField(id);
  const { value, onChange } = field;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Grid item xs={12} md={md}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #74A3FE',
          color: '#74A3FE',
          borderRadius: '4px',
          padding: '18.5px 14px',
        }}
      >
        <div>{label}</div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomButton
            active={value === true}
            onClick={() => handleChange({ target: { name: id, value: true } })}
            favorable
          />
          <CustomButton
            active={value === false}
            onClick={() => handleChange({ target: { name: id, value: false } })}
          />
        </Box>
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </Box>
    </Grid>
  );
};

const TrendsForm = ({
  postNewTrend,
  teamId,
  setIsModalOpen,
  selectedTrend,
  editTrendById,
  setSelectedTrend,
}) => {
  const schema = Yup.object().shape({
    title: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    isTrendFavorable: Yup.boolean().required('Câmp obligatoriu'),
  });

  const initialValues = selectedTrend
    ? {
        title: selectedTrend.title,
        description: selectedTrend.description,
        isTrendFavorable: selectedTrend.isTrendFavorable,
      }
    : {
        title: '',
        description: '',
        isTrendFavorable: true,
      };

  const handleSubmit = (values) => {
    if (selectedTrend) {
      editTrendById(selectedTrend._id, values, teamId);
      setIsModalOpen(false);
      setSelectedTrend(null);
      return;
    }

    postNewTrend(values, teamId);
    setIsModalOpen(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderTextField id="title" label="Titlu" />
          <RenderRichText height="300px" name="description" label="Descriere" />
          <RenderRadioGroup
            id="isTrendFavorable"
            label="Consideri că trendul este favorabil?"
          />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewTrend,
  editTrendById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendsForm);
