import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import {
  DeleteButtonComponent,
  EditButtonComponent,
} from '../../opportunityAnalysis/components/Buttons';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import {
  createActivity,
  deleteActivity,
  getAllActivitiesByTeam,
  updateActivity,
} from '../../requests/activities';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import { formatDate } from '../../common/functions/dateHelpers';
import AddActivitiesModal from '../../hypotheses/components/AddActivitiesModal';
import { getMyIdeeaMembers } from '../../requests/myIdeea';
import { getTeam } from '../../team/ducks';
import { TASK_STATUS_OPTIONS } from '../../hypotheses/constants/activities';

const ActivitiesComponent = () => {
  const dispatch = useDispatch();

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const hypothesisId = useSelector((state) => state.hypotheses.hypothesis?._id);

  const [{ result: activities }, fetchAllActivitiesByTeam] = useApi(
    getAllActivitiesByTeam,
    []
  );

  const [{ result: teamMembers }, runGetTeamMembers] = useApi(
    getMyIdeeaMembers,
    []
  );

  const [, removeActivity] = useApi(deleteActivity);

  const [, doUpdateActivity] = useApi(updateActivity);

  const [, addActivity] = useApi(createActivity);

  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [editFormValues, setEditFormValues] = useState(null);

  const [isAddActivitiesModalOpen, setIsAddActivitiesModalOpen] =
    useState(false);

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const handleToggleAddActivitiesModal = () => {
    setIsAddActivitiesModalOpen(!isAddActivitiesModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddActivitiesModal();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const handleSubmitActivity = async (formValues) => {
    const payload = {
      params: { teamId },
      activityData: {
        ...formValues,
        hypothesisId,
        status: formValues.state,
      },
    };
    await addActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleUpdateActivity = async (formValues) => {
    const payload = {
      params: { activityId: selectedActivityId },
      activityData: {
        ...formValues,
        status: formValues.state,
      },
    };
    await doUpdateActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };
  const handleEditActivity = (id) => {
    const { status, userResponsibleId, successCriteria, title } =
      activities.find((activity) => activity._id === id);
    setSelectedActivityId(id);
    setEditFormValues({
      state: status,
      userResponsibleId,
      successCriteria,
      title,
    });
    handleToggleAddActivitiesModal();
  };

  const handleDeleteActivity = async (id) => {
    const payload = {
      params: { activityId: id },
    };
    await removeActivity(payload);
    await handleGetActivities();
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: 'Numar', key: 'number' },
    { type: 'string', content: 'Titlu', key: 'title' },
    // { type: 'string', content: 'Responsabil', key: 'userResponsibleId' },
    // { type: 'string', content: 'Cost', key: 'cost' },
    // {
    //   type: 'component',
    //   content: <ContentPaste />,
    //   hideSortIcon: true,
    // },
    // {
    //   type: 'component',
    //   content: <Paperclip />,
    //   hideSortIcon: true,
    // },
    { type: 'string', content: 'Stadiu', key: 'status' },
    // { type: 'string', content: 'Cost estimat', key: 'cost' },
    { type: 'string', content: 'Data finalizare', key: 'createdAt' },
    ...(activities.length
      ? [{ type: 'string', content: '', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    activities.map(
      (
        {
          _id,
          title,
          // userResponsibleId,
          status,
          createdAt,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        title,
        // userResponsibleId,
        status: TASK_STATUS_OPTIONS.find((option) => option.value === status)
          .label,
        createdAt: formatDate(createdAt),
        action: (
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <DeleteButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteActivity(_id);
              }}
            />
            <EditButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleEditActivity(_id);
              }}
            />
          </Box>
        ),
      })
    );

  const handleGetActivities = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };

    await fetchAllActivitiesByTeam(payload);
  };

  const handleGetTeam = async () => {
    await dispatch(getTeam(teamId));
  };

  useEffect(() => {
    if (teamId) {
      handleGetActivities();
      handleGetTeam();
      updateTeamMembers();
    }
  }, []);

  const handleSelectedActivity = () => {};
  return (
    <PageContainer className="scrollbar">
      {activities && !!activities.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            handleSelectedRow={handleSelectedActivity}
          />
        </Grid>
      ) : (
        <GenericEmptyTable
          textColor="#74A3FE"
          headers={createTableHeaderCells()}
          onAddClick={handleToggleAddActivitiesModal}
          detailMessage="Nicio activitate nu a fost adaugata..."
        />
      )}
      <AddActivitiesModal
        teamId={teamId}
        isModalOpen={isAddActivitiesModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitActivity}
        formValues={editFormValues}
        handleUpdate={handleUpdateActivity}
        teamMembers={teamMembers.map((member) => ({
          value: member.userId,
          label: member.userRole,
        }))}
      />
    </PageContainer>
  );
};

export default ActivitiesComponent;
