import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Loading, PageContainer } from '../../../common/components/styled';
import GenericTableComponent from '../../../common/components/GenericTableComponent';
import {
  AddButtonComponent,
  DeleteButtonComponent,
  EditButtonComponent,
} from '../../../opportunityAnalysis/components/Buttons';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../../common/ducks';
import AddActivitiesModal from '../AddActivitiesModal';
import { getTeam } from '../../../team/ducks';
import { useApi } from '../../../hooks/useApi';
import { getMyIdeeaMembers } from '../../../requests/myIdeea';
import {
  createActivity,
  deleteActivity,
  getActivityById,
  getAllActivitiesByTeam,
  updateActivity,
} from '../../../requests/activities';
import { formatDate } from '../../../common/functions/dateHelpers';
import ActivitiesDrawer from '../ActivitiesDrawer';
import { getAllTasksByActivity } from '../../../requests/tasks';
import { getAllConclusionsByTeam } from '../../../requests/conclusions';
import { TASK_STATUS_OPTIONS } from '../../constants/activities';

const ActivitiesPage = () => {
  const dispatch = useDispatch();
  const hypothesisId = useSelector((state) => state.hypotheses.hypothesis?._id);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: teamMembers }, runGetTeamMembers] = useApi(
    getMyIdeeaMembers,
    []
  );

  const extractName = (member) => {
    const { firstname = '', lastName = '' } = member;
    return `${firstname} ${lastName}`;
  };

  const teamMembersOptions = teamMembers?.map((member) => ({
    value: member.userId,
    label: extractName(member),
  }));

  const [
    { result: activities, isLoading: activitiesLoading },
    fetchAllActivitiesByTeam,
  ] = useApi(getAllActivitiesByTeam, []);
  const [, addActivity] = useApi(createActivity);
  const [, doUpdateActivity] = useApi(updateActivity);
  const [, removeActivity] = useApi(deleteActivity);
  const [, fetchActivityById] = useApi(getActivityById);
  const [
    { result: activityTasks, isLoading: isTaskLoading },
    runGetTasksByActivity,
  ] = useApi(getAllTasksByActivity, []);
  const [
    { result: activityConclusions, isLoading: isConclusionsLoading },
    runGetConclusionsByActivity,
  ] = useApi(getAllConclusionsByTeam, []);

  const [isAddActivitiesModalOpen, setIsAddActivitiesModalOpen] =
    useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editFormValues, setEditFormValues] = useState(null);
  const [drawerActivity, setDrawerActivity] = useState(null);

  const fetchDrawerData = async () => {
    if (!drawerActivity) return;

    await runGetTasksByActivity({
      params: {
        activityId: drawerActivity._id,
      },
    });

    await runGetConclusionsByActivity({
      params: {
        teamId,
      },
      queryParams: {
        filters: {
          activityId: drawerActivity._id,
        },
      },
    });
  };

  useEffect(() => {
    fetchDrawerData();
  }, [drawerActivity]);

  const handleToggleAddActivitiesModal = () => {
    setIsAddActivitiesModalOpen(!isAddActivitiesModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddActivitiesModal();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const handleGetActivities = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: { hypothesisId } },
    };

    await fetchAllActivitiesByTeam(payload);
  };

  const handleSubmitActivity = async (formValues) => {
    const payload = {
      params: { teamId },
      activityData: {
        ...formValues,
        hypothesisId,
        status: formValues.state,
      },
    };
    await addActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleUpdateActivity = async (formValues) => {
    const payload = {
      params: { activityId: selectedActivityId },
      activityData: {
        ...formValues,
        status: formValues.state,
      },
    };

    await doUpdateActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleEditActivity = (id) => {
    const { status, successCriteria, title, userId } = activities.find(
      (activity) => activity._id === id
    );

    setSelectedActivityId(id);
    setEditFormValues({
      state: status,
      userResponsibleId: userId,
      successCriteria,
      title,
    });
    handleToggleAddActivitiesModal();
  };

  const handleDeleteActivity = async (id) => {
    const payload = {
      params: { activityId: id },
    };
    await removeActivity(payload);
    await handleGetActivities();
  };

  const handleGetActivityById = async (id) => {
    const payload = {
      params: { activityId: id },
    };
    await fetchActivityById(payload);
  };

  const handleToggleDrawerStatus = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    handleToggleDrawerStatus();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const handleGetTeam = async () => {
    await dispatch(getTeam(teamId));
  };

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: 'Numar', key: 'number' },
    { type: 'string', content: 'Titlu', key: 'title' },
    { type: 'string', content: 'Stadiu', key: 'status' },
    { type: 'string', content: 'Data finalizare', key: 'createdAt' },
    {
      type: 'component',
      content: (
        <Box sx={{ display: 'flex' }}>
          <AddButtonComponent
            id="add-activity"
            onClick={handleToggleAddActivitiesModal}
          />
        </Box>
      ),
      hideSortIcon: true,
    },
  ];

  const createRows = () =>
    activities.map(
      (
        {
          _id,
          title,
          // userResponsibleId,
          status,
          createdAt,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        title,
        // userResponsibleId,
        status: TASK_STATUS_OPTIONS.find((option) => option.value === status)
          .label,
        createdAt: formatDate(createdAt),
        action: (
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <DeleteButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteActivity(_id);
              }}
            />
            <EditButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleEditActivity(_id);
              }}
            />
          </Box>
        ),
      })
    );

  useEffect(() => {
    if (teamId) {
      handleGetTeam();
      updateTeamMembers();
    }
  }, []);

  useEffect(() => {
    if (teamId && hypothesisId) {
      handleGetActivities();
    }
  }, []);

  const handleSelectedActivity = (activityId) => {
    setIsDrawerOpen(true);
    const matchingActivity = activities.find(
      (activity) => activity._id === activityId
    );
    setDrawerActivity(matchingActivity);
  };

  return (
    <PageContainer className="scrollbar">
      {activitiesLoading && <Loading isLoading={activitiesLoading} />}

      {!activitiesLoading && activities && !!activities.length && (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            handleSelectedRow={handleSelectedActivity}
          />
        </Grid>
      )}

      {!activitiesLoading && !activities.length && (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          onAddClick={handleToggleAddActivitiesModal}
          addButtonText="Adauga o activitate"
          detailMessage="Adaugă un prim experiment pentru a începe validarea acestei ipoteze."
        />
      )}

      <AddActivitiesModal
        teamId={teamId}
        isModalOpen={isAddActivitiesModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitActivity}
        formValues={editFormValues}
        handleUpdate={handleUpdateActivity}
        teamMembers={teamMembersOptions}
      />
      <ActivitiesDrawer
        isOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        activity={drawerActivity}
        teamMembers={teamMembersOptions}
        tasks={activityTasks}
        conclusions={activityConclusions}
        isLoadingResources={isTaskLoading || isConclusionsLoading}
        fetchDrawerData={fetchDrawerData}
        handleDeleteActivity={handleDeleteActivity}
        handleEditActivity={handleEditActivity}
      />
    </PageContainer>
  );
};

export default ActivitiesPage;
