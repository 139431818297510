import React, { useState, useImperativeHandle, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { useFormikContext } from 'formik';
import ContentState from 'draft-js/lib/ContentState';

// Helpers
import { Box } from '@mui/material';
import { getBlockStyle } from '../../admin/constants/MrlEdittor';
import { PLATFORM_TOOLBAR_CONFIG } from '../constants/RichTextEditorConstants';

// Components
import { PlatformEditorContainer } from '../../admin/components/styled/MrlStyled';

const RenderRichText = React.forwardRef((props, ref) => {
  const editorRef = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const {
    config = PLATFORM_TOOLBAR_CONFIG,
    height = '300px',
    fullHeight,
    label,
    positionToolbarBottom = false,
  } = props;
  const [editorState, setEditorState] = useState(() => {
    const initialValue = values[props.name];

    if (initialValue) {
      let contentState;
      try {
        const rawContentFromDatabase = JSON.parse(initialValue);
        contentState = convertFromRaw(rawContentFromDatabase);
      } catch (error) {
        // Consider the initialValue as plain text.
        contentState = ContentState.createFromText(initialValue);
      }
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setFieldValue(
      props.name,
      JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
    );
  };

  const setEditorContent = (rawContent) => {
    const formattedContent = JSON.parse(rawContent);
    const convertedContent = convertFromRaw(formattedContent);
    const newEditorState = EditorState.createWithContent(convertedContent);
    setEditorState(newEditorState);
  };

  const clearEditor = () => {
    const emptyContent = EditorState.createEmpty();
    setEditorState(emptyContent);
    setFieldValue(
      props.name,
      JSON.stringify(convertToRaw(emptyContent.getCurrentContent()))
    );
  };

  const setContentAndFocus = (rawContent) => {
    const formattedContent = JSON.parse(rawContent);
    const convertedContent = convertFromRaw(formattedContent);
    const newEditorState = EditorState.createWithContent(convertedContent);

    const blockArray = convertedContent.getBlocksAsArray();
    const lastBlock = blockArray[blockArray.length - 1];
    const lastBlockLength = lastBlock ? lastBlock.getLength() : 0;

    const selectionState = newEditorState.getSelection().merge({
      anchorKey: lastBlock.getKey(),
      anchorOffset: lastBlockLength,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlockLength,
    });

    const endSelectedEditorState = EditorState.forceSelection(
      newEditorState,
      selectionState
    );

    setEditorState(endSelectedEditorState);

    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  };

  // This hook allows us to call methods on the ref of this component.
  useImperativeHandle(ref, () => ({
    clearEditor,
    setEditorContent,
    setContentAndFocus,
    focusEditor: (editorStateToFocus = editorState) => {
      if (editorRef.current) {
        const selectionState = editorStateToFocus.getSelection();
        const beginningOfContent = selectionState.merge({
          anchorOffset: 0,
          focusOffset: 0,
        });
        const newEditorState = EditorState.forceSelection(
          editorStateToFocus,
          beginningOfContent
        );
        setEditorState(newEditorState);
        editorRef.current.focusEditor();
      }
    },
  }));

  return (
    <Box sx={{ position: 'relative' }}>
      {label && (
        <Box
          sx={{
            position: 'absolute',
            top: '-10px',
            left: '10px',
            zIndex: '1',
            backgroundColor: 'white',
            padding: '0 5px',
            fontSize: '13px',
            color: '#74A3FE',
            fontWeight: '500',
          }}
        >
          {label}
        </Box>
      )}
      <PlatformEditorContainer
        height={height}
        className={`rich-text-editor ${
          positionToolbarBottom ? 'bottom-toolbar' : ''
        }`}
      >
        <Editor
          editorState={editorState}
          editorClassName="scrollbar editor-override"
          toolbarClassName="rich-toolbar"
          wrapperClassName={
            fullHeight ? 'rich-wrapper-full-height' : 'rich-wrapper'
          }
          toolbar={config}
          onEditorStateChange={onEditorStateChange}
          blockStyleFn={getBlockStyle}
          ref={editorRef}
        />
        {props.children}
      </PlatformEditorContainer>
    </Box>
  );
});

export default RenderRichText;
