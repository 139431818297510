import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TitleButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 25px;
  cursor: pointer;
`;
export const Title = styled.h1`
  margin: 0 15px 0 0;
`;

export const TitleIconDiv = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.detailPage &&
    css`
      padding-left: 10px;
    `}
`;

export const EmpathyMapButton = styled.div`
  border: 1.5px solid ${(props) => props.theme.blueAccent};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.blueAccent};
  height: 32px;
  cursor: pointer;
  :hover {
    border: 1.5px solid ${(props) => props.theme.accentHover};
  }

  > h4 {
    color: ${(props) => props.theme.blueAccent};
  }
  :hover > h4 {
    color: ${(props) => props.theme.accentHover};
  }
  :hover > svg > path {
    fill: ${(props) => props.theme.accentHover};
  }
`;

export const OutComeIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

export const Label = styled.h4`
  margin-bottom: 10px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
`;
export const Container = styled.div`
  padding: 30px;
`;

export const BorderDiv = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.borderGray};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;
export const EditIconDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
    :hover {
      > path {
        fill: #727cf5;
      }
    }
  }
`;
export const CommentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 10vw;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.isSelected && props.theme.blueAccent};
  padding: 10px 15px;
  border: ${(props) =>
    props.isSelected && `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  cursor: pointer;
`;

export const Strong = styled.div`
  font-weight: ${(props) => props.isSelected && 'bold'};
  margin-left: 0.5vw;
`;

export const Paragraph = styled.p`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;

export const ProfileParagraph = styled.p`
  text-align: center;
  color: #dee7fc;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
`;

export const IndicatorContainer = styled.div`
  display: flex;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  padding: 10px;
  gap: 5px;
`;

export const WhiteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const PhotoFormContainer = styled.div`
  border: ${(props) => `1px dashed ${props.theme.blueAccent}`};
  border-radius: 5px;
  height: ${({ height }) => height || '10vh'};
  padding: 10px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.blueAccent};
`;

export const CharacteristicParagraph = styled.div`
  margin-top: 1vh;
  border: 1px solid #74a3fe;
  border-radius: 10px;
  padding: 12px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
`;

export const ProfileDescriptionTooltipContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  background: white;
  transform: translate(-50%, -50%);
  max-width: 25vw;
  text-align: center;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
  height: 20vh;
  border-radius: 10px;
  overflow-y: auto;
  min-width: 25vw;
`;

export const MiddleContainer = styled.div`
  background: white;
  width: 250px;
  height: 250px;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  border-radius: 100%;
`;

export const EditButtonContainer = styled.div`
  float: right;
  position: absolute;
  margin-bottom: 5vh;
  left: 89%;
  top: 40%;
  background-color: #2a5ab7;
  width: 20px;
  height: 20px;
`;

export const NameContainer = styled.div`
  position: absolute;
  display: flex;
  left: 18%;
  top: 70%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  max-width: 6vw;
  width: 6vw;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NameParagraph = styled.div`
  width: 6vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const FilterContainer = styled.div`
  position: absolute;
  top: 9%;
  background: #ffffff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 15vw;
`;

export const FilterElement = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5vh;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  opacity: ${({ opacity }) => opacity && opacity};
`;
export const TableDataContainer = styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '78vh')};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ParagraphContainer = styled.div`
  font-size: ${({ fontSize }) => fontSize && fontSize};
  color: black;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
`;

export const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #eef5ff;
  border-radius: 5px;
  height: 22vh;
  width: 1.5vw;
`;

export const ProgressContainer = styled.div`
  background: #ffa15e;
  border-radius: 5px;
  width: 1vw;
  height: 15vh;
`;

export const DoughnutContainer = styled.div`
  width: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const PercentageContainer = styled.div`
  position: absolute;
  height: 50px;
`;
export const ColoredPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
  margin-right: 3px;
`;

export const LegendElement = styled.div`
  display: flex;
  align-items: center;
`;

export const BudgetContainer = styled.div`
  margin-bottom: 10px;
`;
export const BudgetContainerText = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ProfileCharcteristicsContainer = styled.div`
  border: 1px solid #eef5ff;
  border-radius: 5px;
  flex: none;
  width: 20vw;
  height: 20vh;
  padding: 10px;
`;

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const CharacteristicItem = styled.div`
  padding: 8px 10px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #dee7fc;
  border-radius: 4px;
  margin-top: 1vh;
`;

export const HypothesisDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  width: 165px;
  height: 120px;
  background: #eef5ff;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DetailDescription = styled.div`
  text-align: center;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #3f3f3f;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DetailCounter = styled.div`
  color: #74a3fe;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
`;

export const SynthesisContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 78vh;
  margin-top: 16px;
`;

export const ResolutionExperimentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 78vh;
  overflow-y: auto;
`;

export const HypothesisPageContainer = styled.div`
  border: 1px solid #74a3fe;
  border-radius: ${(props) => (props.fullBorder ? '10px' : '0 10px 10px 10px')};
  max-width: 100%;
  height: 83.5vh;
  padding: 40px;
`;
