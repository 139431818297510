import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../api/Api';
import {
  DELETE_TEAM_BY_ID,
  GET_ALL_TEAMS,
  POST_NEW_TEAM,
  PUT_ACCEPT_TEAM_INVITE,
  PUT_REJECT_TEAM_INVITE,
} from '../common/constants/apiRoutes';

export function postNewTeam({ payload = {} }) {
  return runPostApiRequest({
    endpoint: POST_NEW_TEAM,
    params: payload.params,
    payload: payload?.teamData,
    onFailMessage: 'Something went wrong while adding the team',
  });
}

export function getAllTeams({ payload = {} }) {
  return runGetApiRequest({
    endpoint: GET_ALL_TEAMS,
    params: payload.params,
  });
}

export function acceptTeamInvite({ payload }) {
  return runUpdateApiRequest({
    endpoint: PUT_ACCEPT_TEAM_INVITE,
    payload: payload.userData,
  });
}

export function rejectTeamInvite({ payload }) {
  return runUpdateApiRequest({
    endpoint: PUT_REJECT_TEAM_INVITE,
    payload: payload.userData,
  });
}

export function deleteTeamById({ payload }) {
  return runDeleteApiRequest({
    endpoint: DELETE_TEAM_BY_ID,
    params: payload.params,
  });
}
