import { Avatar, Box, ClickAwayListener } from '@mui/material';
import React from 'react';
import {
  LinkStyleButton,
  P,
} from '../../common/components/styled/ReusableTextComponents';
import { CommentsPopperContainer } from './styled/Comments';

const CommentHeader = ({ user = {}, currentUserId, unread }) => {
  const { firstName, lastName, profilePicture } = user;
  const fullName = `${lastName} ${firstName}`;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        sx={{ width: '20px', height: '20px', marginRight: '5px' }}
        src={profilePicture}
      />
      <P>{`${fullName} ${currentUserId === user._id ? '(tu)' : ''}`}</P>
      {unread && (
        <Box
          sx={{
            width: '5px',
            height: '5px',
            backgroundColor: 'red',
            borderRadius: '50%',
            marginLeft: '5px',
          }}
        />
      )}
    </Box>
  );
};

const PopperContainer = ({
  hideOptionsMenu,
  checkIfItsMine,
  setOpenedMenu,
  handleEdit,
  handleDelete,
  handleCopyLink,
  item,
  entry = null,
  isReply = false,
}) => {
  const belongsToMe = checkIfItsMine(item.userProfileId._id);

  return (
    <ClickAwayListener onClickAway={hideOptionsMenu}>
      <CommentsPopperContainer>
        {belongsToMe && (
          <LinkStyleButton
            onClick={(e) => {
              if (isReply) e.stopPropagation();
              setOpenedMenu({ id: null, anchorEl: null });
              handleEdit(e, item, entry); // will be handleEditComment or handleEditReply based on context
            }}
            fontSize="14px"
            marginBottom="10px"
          >
            Editeaza
          </LinkStyleButton>
        )}
        <LinkStyleButton
          onClick={(e) => {
            if (isReply) e.stopPropagation();
            setOpenedMenu({ id: null, anchorEl: null });
            handleCopyLink(item._id);
          }}
          fontSize="14px"
        >
          Copy link
        </LinkStyleButton>
        {belongsToMe && (
          <LinkStyleButton
            onClick={(e) => {
              if (isReply) e.stopPropagation();
              setOpenedMenu({ id: null, anchorEl: null });
              handleDelete(e, item._id);
            }}
            fontSize="14px"
            marginTop="10px"
          >
            Sterge
          </LinkStyleButton>
        )}
      </CommentsPopperContainer>
    </ClickAwayListener>
  );
};

export { CommentHeader, PopperContainer };
