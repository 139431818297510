import { Nav } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import GenericComments from '../../../comentsAndNotifications/components/GenericComments';
import TrendsPage from './TrendsPage';
import IndustriesPage from './IndustriesPage';
import SwotPage from './SwotPage';
import ConclusionPage from './ConclusionPage';
import CompetitionPage from './CompetitionPage';
import ContextDetailsPage from './ContextDetailsPage';

// Styled components
import { ProfileMenu } from '../../../userProfile/components/styled/ProfilePage';
import {
  ProfilePageContainer,
  StyledNavLink,
  StyledProfileNav,
} from '../../../userProfile/components/styled/ProfileTabItem';
import { ProfileTabIcon } from '../../../common/components/styled/LinkIcon';
import { H1 } from '../../../common/components/styled/ReusableTextComponents';
import { SpaceBetweenBox } from '../../../common/components/styled/ReusablePositioningComponents';

// Constants and utils
import { getAllComments } from '../../../comentsAndNotifications/ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { PROFILE_PAGE_TABS } from '../../../userProfile/constants';
import { CONTEXT_ANALYSIS_TABS } from '../../constants';
import { CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB } from '../../../comentsAndNotifications/constants';

export const ContextAnalysisPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const history = useHistory();

  const tabFromQuery = query.get('tab') || 'profile';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const location = useLocation();

  useEffect(() => {
    const queryTab =
      new URLSearchParams(location.search).get('tab') || 'profile';
    setActiveTab(queryTab);
  }, [location]);

  const currentUserId = useSelector(
    (state) =>
      state.userProfile.userProfile && state.userProfile.userProfile.userId._id
  );

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const contextAnalysisComments =
    useSelector(
      (state) =>
        state.comments[CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab]]
    ) || [];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/opportunity-analysis?tab=${tab}`);
  };

  const pageComponents = {
    trends: {
      title: 'Trenduri',
      component: <TrendsPage />,
    },
    industry: {
      title: 'Industrie',
      component: <IndustriesPage />,
    },
    competition: {
      title: 'Competitie',
      component: <CompetitionPage />,
    },
    swot: {
      title: 'SWOT',
      component: <SwotPage />,
    },
    details: {
      title: 'Detalii',
      component: <ContextDetailsPage />,
    },
    conclusions: {
      title: 'Concluzie analiza context',
      component: <ConclusionPage />,
    },
  };

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab],
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    if (!teamId || !currentUserId) return;
    fetchComments();
  }, [teamId, currentUserId, activeTab]);

  return (
    <>
      <SpaceBetweenBox>
        <H1 marginBottom="15px">{pageComponents[activeTab]?.title}</H1>
        <GenericComments
          fetchComments={fetchComments}
          type={CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab]}
          teamId={teamId}
          userId={currentUserId}
          comments={contextAnalysisComments}
        />
      </SpaceBetweenBox>

      <ProfileMenu>
        <StyledProfileNav
          id="context-analysis-2"
          variant="tabs"
          activeKey={activeTab}
        >
          {CONTEXT_ANALYSIS_TABS.map((tab, index) => (
            <Nav.Item
              id={tab?.id || tab.key}
              key={tab.key}
              style={{ position: 'relative' }}
            >
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === PROFILE_PAGE_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabIcon icon={tab.icon} />
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <ProfilePageContainer fullBorder={activeTab !== 'trends'}>
        {pageComponents[activeTab].component}
      </ProfilePageContainer>
    </>
  );
};
