import update from 'immutability-helper';
import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

// Actions
const OPEN_MODAL = 'modals/OPEN_MODAL';
const CLOSE_MODAL = 'modals/CLOSE_MODAL';

// Initial state
const initialState = {
  isModalOpen: false,
  name: undefined,
  props: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return update(state, {
        $merge: {
          isModalOpen: true,
          name: action.name,
          props: action.props,
        },
      });

    case CLOSE_MODAL:
      return update(state, {
        $merge: {
          isModalOpen: false,
          name: undefined,
          props: undefined,
        },
      });
    default:
      return state;
  }
};

// Action creators
const openModalCreator = (name, props) => ({
  type: OPEN_MODAL,
  name,
  props,
});

const closeModalCreator = () => ({
  type: CLOSE_MODAL,
});

export const openModal = (name, props) => (dispatch) => {
  dispatch(openModalCreator(name, props));
};

export const closeModal = () => (dispatch) => {
  dispatch(closeModalCreator());
};

const getModalStateByName = (state, modalName) =>
  state.modal.name === modalName ? state.modal.isModalOpen : false;

export const getModalOpenStateSelector = createSelector(
  getModalStateByName,
  identity
);
