/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { Card, Button } from 'react-bootstrap';

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  padding: 6px 15px;
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  padding-bottom: 14px;
  margin-bottom: 20px;
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;

export const TableRow = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  padding: 6px 15px;
  height: 50px;
  display: flex;
  align-items: center;
`;
export const TableData = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: ${(props) => props.marginLeft};
`;

export const TableHeading = styled.div`
  text-align: center;
  width: ${(props) => props.cellWidth};
`;

export const StyledCard = styled(Card)`
  width: 100%;
  margin: 0 auto;
  max-width: 1250px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  h2 {
    color: #2ad4c3;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const TableBody = styled.tbody`
  display: block;
  max-height: 500px;
  overflow-y: scroll;
  margin-right: -15px;
`;

export const TableHeadCell = styled.div`
  cursor: pointer;
  justify-content: ${(props) => (props.alignLeft ? 'flex-start' : 'center')};
  ${(props) =>
    props.alignRight &&
    css`
      justify-content: flex-end;
    `}
  &:after {
    content: ${(props) =>
      props.indicator ? (props.reverse ? '   ⇧' : '   ⇩') : ''};
  }
  width: ${(props) => props.cellWidth};
  display: flex;
  min-width: ${(props) => props.cellWidth};
`;

export const TableBodyCell = styled.div`
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  ${(props) =>
    props.alignRight &&
    css`
      text-align: right;
    `}
  width: ${(props) => props.cellWidth};
  min-width: ${(props) => props.cellWidth};
`;

export const TableLinkButton = styled(Button)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
`;

export const BorderTableRow = styled.div`
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 6px 15px;
  height: ${(props) => props.height || '50px'};
  margin-left: 1vw;
  margin-right: 1vw;
`;

export const NoDataParagraph = styled.div`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #dee7fc;
`;

export const TableContainer = styled.div`
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 76vh;
  width: 100%;
`;

export const ExperimentCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  text-align: center;

  width: ${(props) => props.width && props.width};
  min-width: 5vw;
  height: 120px;

  background: #eef5ff;
  border-radius: 10px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;
