import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';
import { ReactComponent as SaveIConSvg } from '../../assets/icons/save.svg';
import { ReactComponent as InfoIconSvg } from '../../assets/icons/info.svg';
import { ReactComponent as CopyIconSvg } from '../../assets/icons/copy.svg';

export const SaveIcon = styled(SaveIConSvg)`
  margin-right: 5px;
`;
export const InfoIcon = styled(InfoIconSvg)`
  margin-left: 5px;
  cursor: pointer;
`;

export const CopyIcon = styled(CopyIconSvg)`
  cursor: pointer;
  position: absolute;
  top: 37px;
  right: 13px;
`;
export const SubmitButton = styled.button`
  height: 32px;
  border-radius: 4px;
  padding: 5px 15px;
  background: ${(props) => props.theme.blueAccent};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: start;
  color: ${(props) => props.theme.white};
  border: none;

  :hover {
    background: ${(props) => props.theme.accentHover};
  }

  ${(props) =>
    props.facebookButton &&
    css`
      background: #4267b2;
    `}
`;

export const Input = styled(Form.Control)`
  display: block;
  width: 100%;
  background: ${(props) => props.theme.white};
  border: 1px solid
    ${(props) => (props.isInvalid ? '#fa5c7c' : props.theme.accentLight)};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: ${(props) => (props.height ? props.height : '40px')};
  color: #8e8eb5;
  padding: 10px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '10px')};
  :hover {
    color: ${(props) => props.theme.text};
  }

  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: ${(props) => props.theme.textInactive};
  }
  &:hover::placeholder {
    color: ${(props) => props.theme.text};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.accentLight} !important;
  }
  ${(props) =>
    props.formlink &&
    css`
      overflow: hidden;
      text-overflow: ellipsis !important;
      padding-right: 45px;
    `}

  ${(props) =>
    props.disabledInput &&
    css`
      background-color: ${(props) => props.theme.textInactive} !important;
      color: ${props.theme.white};
      :hover {
        color: ${(props) => props.theme.white};
      }
      :focus {
        color: ${(props) => props.theme.white};
      }
    `}
`;

export const InputProfile = styled(Form.Control)`
  display: block;
  width: 100%;
  background: ${(props) => props.theme.white};
  border: 1px solid
    ${(props) => (props.isInvalid ? '#fa5c7c' : props.theme.accentLight)};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: ${(props) => (props.height ? props.height : '60px')};
  color: ${(props) => (props.isInvalid ? '#fa5c7c' : props.theme.accentLight)};
  padding: 10px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '10px')};
  :hover {
    color: ${(props) => props.theme.text};
  }

  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: ${(props) => props.theme.textInactive};
  }
  &:hover::placeholder {
    color: ${(props) => props.theme.text};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.accentLight} !important;
  }
  ${(props) =>
    props.formlink &&
    css`
      overflow: hidden;
      text-overflow: ellipsis !important;
      padding-right: 45px;
    `}

  ${(props) =>
    props.disabledInput &&
    css`
      background-color: ${(props) => props.theme.textInactive} !important;
      color: ${props.theme.white};
      :hover {
        color: ${(props) => props.theme.white};
      }
      :focus {
        color: ${(props) => props.theme.white};
      }
    `}
`;

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.textInactive};
  box-sizing: border-box;
  border-radius: 4px;
  height: 100px;
  color: #8e8eb5;
  padding: 10px;
  :hover {
    color: ${(props) => props.theme.text};
  }
  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: ${(props) => props.theme.textInactive};
  }
  &:hover::placeholder {
    color: ${(props) => props.theme.text};
  }
  &:focus {
    border: 1px solid #131336;
  }
`;

export const PlaceHolderTextArea = styled.div`
  background: ${(props) => props.theme.white};
  height: ${(props) => (props.height ? props.height : '100px')};
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.textInactive};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const CircleIrl = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.textInactive};
  box-sizing: border-box;
  margin-left: 10px;

  ${(props) =>
    props.current &&
    css`
      background-color: ${(props) => props.theme.textInactive};
      border: none;
    `}
  ${(props) =>
    props.passed &&
    css`
      background-color: ${(props) => props.theme.blueAccent};
      border: none;
    `}
`;

export const PlaceHolderInput = styled.div`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.textInactive};
  max-width: ${(props) => props.maxWidth};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  overflow-y: auto;
  align-items: center;
  ${(props) =>
    props.hypothesisPage &&
    css`
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `}
  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}

  margin-bottom: ${(props) => props.marginBottom};
  ${(props) =>
    props.hypothesisCard &&
    css`
      padding: 5px 7px;
      cursor: pointer;
    `}
`;

export const RowDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${(props) => props.marginBottom};
`;
export const WhiteCard = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  height: ${(props) => (props.height ? props.height : '100%')};
  width: ${(props) => props.width};
  padding: ${(props) => (props.padding ? props.padding : '15px')};
  overflow-y: ${(props) => (props.noOverflow ? 'none' : 'auto')};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};

  ::-webkit-scrollbar-track {
    margin-top: ${({ scrollbarLimitUp }) => scrollbarLimitUp && '100px'};
    margin-bottom: ${({ scrollbarLimitDown }) => scrollbarLimitDown && '100px'};
    margin: 0px;
  }
`;

export const ColDiv = styled.div`
  padding-right: ${(props) => props.paddingRight};
  padding-left: ${(props) => props.paddingLeft};
  width: ${(props) => props.width};
  ${(props) =>
    props.card &&
    css`
      background: ${(props) => props.theme.white};
      border-radius: 8px;
      padding: 15px;
      height: 300px;
    `}

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}


     ${(props) =>
    props.flexEnd &&
    css`
      display: flex;
      flex-direction: column;
    `}

     ${(props) =>
    props.borderLeft &&
    css`
      border-left: 1px solid #cccccc;
    `}
`;

export const ChildsDiv = styled.div`
  height: ${(props) => (props.height ? props.height : '195px')};
  overflow-y: auto;
  padding-right: 4px;
`;
export const DivBorderLeft = styled.div`
  border-left: 1px solid #cccccc;
  padding-left: 10px;
  margin-left: 10px;
`;
export const ChildCard = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.backgroundBigScreen};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 10px 15px;
  width: 100%;
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `}
`;

export const InputImg = styled.div`
  border: ${(props) => `1px solied ${props.theme.accentLight}`};
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: ${(props) => (props.height ? props.height : '108px')};
  color: #74a3fe;
  padding: 10px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '10px')};
  :hover {
    color: ${(props) => props.theme.text};
  }
`;

export const InputImgProfile = styled.div`
  border: ${(props) => `1px solied ${props.theme.accentLight}`};
  border: 1px dashed
    ${(props) => (props.isInvalid ? '#fa5c7c' : props.theme.accentLight)};
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: ${(props) => (props.height ? props.height : '60px')};
  color: ${(props) => (props.isInvalid ? '#fa5c7c' : props.theme.accentLight)};
  padding: 10px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '10px')};
  :hover {
    color: ${(props) => props.theme.text};
  }
`;

export const InputImgModalDiv = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
`;

export const InputImgText = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  color: #74a3fe;
`;
export const InputImgTextIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 12px;
`;

export const DivFlexTabs = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 5px;
`;
export const RoleTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 90px;
  width: 180px;
  margin: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
`;

export const RoleTabsActive = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 90px;
  width: 180px;
  margin: 10px;
  border-radius: 5px;
  background: #74a3fe;
`;
export const RoleTabsText = styled.p`
  width: 41px;
  height: 14px;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #505050;
  margin-bottom: 0px;
`;

export const RoleTabsTextActive = styled.p`
  width: 41px;
  height: 14px;
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
`;
