import HighImportanceIcon from '../../common/assets/icons/Higher.svg';
import MediumImportanceIcon from '../../common/assets/icons/Medium.svg';
import LowImportanceIcon from '../../common/assets/icons/Small.svg';

export const evidenceOptions = [
  {
    value: 'solid',
    label: 'solide',
  },
  {
    value: 'weak',
    label: 'slabe',
  },
  {
    value: 'noProof',
    label: 'fara Dovezi',
  },
];

export const importanceOptions = [
  {
    value: 'high',
    label: 'mare',
  },
  {
    value: 'medium',
    label: 'medie',
  },
  {
    value: 'low',
    label: 'mică',
  },
];

export const HYPOTHESIS_IMPORTANCE_DETAILS = {
  high: {
    label: 'Mare',
    icon: HighImportanceIcon,
  },
  medium: {
    label: 'Medie',
    icon: MediumImportanceIcon,
  },
  low: {
    label: 'Mică',
    icon: LowImportanceIcon,
  },
};

export const statusOptions = [
  {
    value: 'confirmed',
    label: 'Confirmat',
  },
  {
    value: 'denied',
    label: 'Infirmat',
  },
  {
    value: 'new',
    label: 'Informație nouă',
  },
];
export const categoryOptions = [
  {
    value: 'dezirabila',
    label: 'dezirabilă',
  },
  {
    value: 'viabila',
    label: 'viabilă',
  },
  {
    value: 'fezabila',
    label: 'fezabilă',
  },
];

export const PROFILE_HYPOTHESIS_PLACEHOLDERS = {
  pain: 'Adaugă câteva frici și bariere pe care le resimte clientul în legătura cu tipologia soluțiilor pe care le-ai propus',
  who: 'Adaugă câteva caracteristici ale clientului în legătura cu tipologia soluțiilor pe care le-ai propus',
  tasks:
    'Adaugă câteva caracteristici ale clientului în legătura cu tipologia soluțiilor pe care le-ai propus',
  wins: 'Adaugă câteva caracteristici ale clientului în legătura cu tipologia soluțiilor pe care le-ai propus',
};

export const colorOptions = [
  '#FF0000',
  '#FF7F00',
  '#FFFF00',
  '#00FF00',
  '#0000FF',
  '#2E2B5F',
  '#8B00FF',
];
