import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 100% {
    box-shadow: 15px 0 0 0 #656565, 30px 0 0 0 #656565, 45px 0 0 0 #656565;
  }
  16.667% {
    box-shadow: 15px -10px 0 0 #656565, 30px 0 0 0 #656565, 45px 0 0 0 #656565;
  }
  50% {
    box-shadow: 15px 0 0 0 #656565, 30px -10px 0 0 #656565, 45px 0 0 0 #656565;
  }
  83.333% {
    box-shadow: 15px 0 0 0 #656565, 30px 0 0 0 #656565, 45px -10px 0 0 #656565;
  }
`;

export const DotsLoader = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: transparent;
  color: #656565;
  box-shadow: 20px 0 0 0 #656565, 40px 0 0 0 #656565, 60px 0 0 0 #656565;
  animation: ${bounce} 0.9s infinite linear;
`;

export const DotsBubbleContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  background: #eee;
  border-radius: 25px 25px 25px 0;
  margin-top: 15px;
`;
