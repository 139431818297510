export const capitalizeFirstLetter = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export function downloadCSV(csvContent, filename) {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');

  link.setAttribute('download', filename);

  link.href = URL.createObjectURL(blob);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
