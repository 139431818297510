import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { PageContainer } from '../../../common/components/styled';
import { P } from '../../../common/components/styled/ReusableTextComponents';
import CoursesPageHeaderSection from '../CoursesPageHeaderSection';
import { useApi } from '../../../hooks/useApi';
import { getAllChapters, orderChapters } from '../../../requests/chapters';
import ChapterAccordion from '../ChapterAccordion';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';

const CoursesListEmptyState = () => (
  <Box sx={{ width: '70%', marginTop: '10px' }}>
    <P faded>Adauga un capitol facand click pe butonul din dreapta... </P>
  </Box>
);

const Courses = () => {
  const history = useHistory();
  const location = useLocation();

  const [filter, setFilter] = useState('all');

  const [chaptersList, setChaptersList] = useState([]);

  const [{ result: chaptersListResult = [] }, fetchAllChapters] =
    useApi(getAllChapters);

  const [, reorderChapters] = useApi(orderChapters);

  const getIdFromUrl = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const subchapterId = getIdFromUrl('id');
  const chapterId = getIdFromUrl('chapterId');

  const createNewChapter = () => {
    const order = chaptersList.length + 1;
    history.push(`/admin/mrl/course/form?order=${order}`);
  };

  const createNewSubchapter = (chapterId) => {
    const chapterData = chaptersList.find(
      (chapter) => chapter._id === chapterId
    );
    const order = chapterData.subchapters.length + 1;
    history.push(
      `/admin/mrl/course/form?chapterId=${chapterId}&order=${order}`
    );
  };

  const handleChaptersFetch = async () => {
    const payload =
      filter === 'visible' || filter === 'hidden'
        ? { isVisible: filter === 'visible' }
        : {};

    await fetchAllChapters(payload);
  };

  const handleReorderChapters = async (newList) => {
    const payload = newList.map((chapter) => ({
      id: chapter._id,
      order: chapter.order,
    }));

    //The setChaptersList is used to update the UI before the API call is made to avoid the UI flickering
    setChaptersList(newList);
    await reorderChapters(payload);
    await handleChaptersFetch();
  };

  const handleReorderSubchapters = async (newList) => {
    const payload = newList.map((chapter) => ({
      id: chapter._id,
      order: chapter.order,
      chapterId: chapter.chapterId,
    }));

    await reorderChapters(payload);
    await handleChaptersFetch();
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    // If there's no destination (dropped outside a droppable area) or the item is dropped in the same place, do nothing
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    // Reordering chapters
    if (type === 'CHAPTERS') {
      const newChaptersList = Array.from(chaptersList);
      const [reorderedItem] = newChaptersList.splice(source.index, 1);
      newChaptersList.splice(destination.index, 0, reorderedItem);

      // Update the order property for chapters
      newChaptersList.forEach((chapter, index) => {
        chapter.order = index + 1; // Assuming the order starts at 1
      });

      await handleReorderChapters(newChaptersList);
    } else if (type === 'SUBCHAPTER') {
      // Reordering subchapters
      const newChaptersList = Array.from(chaptersList);
      const sourceChapter = newChaptersList.find(
        (chapter) =>
          chapter._id === source.droppableId.replace('subchapters-', '')
      );
      const destChapter = newChaptersList.find(
        (chapter) =>
          chapter._id === destination.droppableId.replace('subchapters-', '')
      );
      const [reorderedSubchapter] = sourceChapter.subchapters.splice(
        source.index,
        1
      );

      // If source and destination chapters are the same, we simply reorder subchapters within the chapter
      if (source.droppableId === destination.droppableId) {
        sourceChapter.subchapters.splice(
          destination.index,
          0,
          reorderedSubchapter
        );
      } else {
        // If they're different, we transfer the subchapter to another chapter
        destChapter.subchapters.splice(
          destination.index,
          0,
          reorderedSubchapter
        );
      }

      // Update the order property for subchapters in both source and destination chapters
      sourceChapter.subchapters.forEach((sub, index) => {
        sub.order = index + 1;
      });
      destChapter.subchapters.forEach((sub, index) => {
        sub.order = index + 1;
        sub.chapterId = destChapter._id;
      });
      let newSubchaptersList = [];
      newChaptersList.forEach(
        (chapter) =>
          (newSubchaptersList = [...newSubchaptersList, ...chapter.subchapters])
      );
      await handleReorderSubchapters(newSubchaptersList);
    }
  };

  const scrollToFlickerElement = (elementId, flickerDuration = 1000) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.classList.add('flicker');

      // Remove flicker class after a short duration
      setTimeout(() => element.classList.remove('flicker'), flickerDuration);
    }
  };

  useEffect(() => {
    handleChaptersFetch();
  }, [filter]);

  useEffect(() => {
    setChaptersList(chaptersListResult);
  }, [chaptersListResult]);

  useEffect(() => {
    if (subchapterId) {
      scrollToFlickerElement(subchapterId);
      if (chapterId) {
        scrollToFlickerElement(chapterId);
      }
    }
  }, [subchapterId, chapterId]);

  const isDragDiabled = filter !== 'all';

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <PageContainer className="scrollbar">
        <Box
          sx={{
            width: '100%',
            margin: '20px auto',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CoursesPageHeaderSection
            headerTitle="Module curs"
            onAddClick={createNewChapter}
            handleSubmitFilters={(filter) => setFilter(filter)}
          />
          {!chaptersList.length ? (
            <CoursesListEmptyState />
          ) : (
            <Droppable droppableId="all-chapters" type="CHAPTERS">
              {(provided) => (
                <Box
                  className="scrollbar"
                  sx={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    overflowY: 'auto',
                    maxHeight: '81vh',
                    paddingBottom: '20px',
                    width: '100%',
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {chaptersList.map(
                    (
                      {
                        title,
                        description,
                        isVisible,
                        order,
                        createdAt,
                        subchapters,
                        _id: chapterId,
                      },
                      index
                    ) => (
                      <Box
                        key={chapterId}
                        sx={{
                          width: '70%',
                        }}
                      >
                        <Draggable
                          draggableId={chapterId}
                          index={index}
                          isDragDisabled={isDragDiabled}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              id={chapterId}
                            >
                              <ChapterAccordion
                                order={order}
                                subchapters={subchapters}
                                createNewSubchapter={() =>
                                  createNewSubchapter(chapterId)
                                }
                                chapterId={chapterId}
                                title={title}
                                description={description}
                                isVisible={isVisible}
                                key={chapterId}
                                fetchChapters={handleChaptersFetch}
                                chaptersProvided={provided}
                                index={index}
                                isDragDiabled={isDragDiabled}
                              />
                            </Box>
                          )}
                        </Draggable>
                      </Box>
                    )
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          )}
        </Box>
      </PageContainer>
    </DragDropContext>
  );
};

export default Courses;
