import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import { postMyIdeeaMember, postMyIdeaMentor } from '../../requests/myIdeea';
import { useApi } from '../../hooks/useApi';
import { RenderDropdownField } from '../../common/components/forms/Fields';
import { ideaRoleOptions } from '../../team/constants/CreateTeamForm';

const InviteUserToIdeaForm = ({
  isMentor,
  teamId,
  runUpdateTeamMentors,
  runUpdateTeamMembers,
  handleClose,
}) => {
  const [, runPostMember] = useApi(postMyIdeeaMember);
  const [, runPostMentor] = useApi(postMyIdeaMentor);

  const initialValues = {
    email: '',
    ...(!isMentor && {
      equity: '',
      role: '',
    }),
  };
  const handleSubmit = async (values) => {
    if (isMentor) {
      await runPostMentor({
        params: {
          teamId,
        },
        data: {
          email: values.email,
        },
      });

      runUpdateTeamMentors();
      handleClose();
    } else {
      await runPostMember({
        params: {
          teamId,
        },
        data: {
          email: values.email,
          equity: values.equity,
          role: values.role,
        },
      });

      runUpdateTeamMembers();
      handleClose();
    }
  };

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    ...(!isMentor && {
      equity: Yup.number()
        .typeError('Alege un numar de la 0 la 100')
        .min(0, 'Alege un numar mai mare decat 0')
        .max(100, 'Alege un numar mai mic decat 100')
        .required('Required'),
      role: Yup.string().required('Required'),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomTextField id="email" label="Email" />
          {!isMentor && (
            <>
              <CustomTextField id="equity" label="Equity%" />

              <RenderDropdownField
                values={values}
                setFieldValue={setFieldValue}
                label="Rol"
                name="role"
                options={ideaRoleOptions}
                md={12}
                multiple
              />
            </>
          )}

          <PrimaryButton marginTop="30px" type="submit">
            Invita
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default InviteUserToIdeaForm;
