import React from 'react';
import { Card } from 'react-bootstrap';
import { DocumentTitle } from '../../../common/components';
import {
  AccountPage,
  AccountPanel,
  AccountPanelTitle,
  AccountPanelDescription,
  AccountPanelLink,
} from '../styled';

const PasswordResetEmailSentPage = () => (
  <>
    <DocumentTitle>Resetare parolă</DocumentTitle>
    <AccountPage>
      <AccountPanel size="small">
        <Card>
          <Card.Body>
            <AccountPanelTitle>
              A fost trimis un email pentru resetarea parolei
            </AccountPanelTitle>
            <AccountPanelDescription>
              Accesați link-ul atasat in email.
            </AccountPanelDescription>
            <AccountPanelDescription>
              <AccountPanelLink to="/account/login">
                Inapoi la Login
              </AccountPanelLink>
            </AccountPanelDescription>
          </Card.Body>
        </Card>
      </AccountPanel>
    </AccountPage>
  </>
);

export default PasswordResetEmailSentPage;
