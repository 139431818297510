import React from 'react';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';

const CustomFormCheckbox = ({ name, label, md = 12, disabled = false }) => {
  const [field, meta] = useField({ name, type: 'checkbox' });

  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <FormControl component="fieldset" error={meta.touched && !!meta.error}>
        <FormControlLabel
          control={
            <Checkbox {...field} disabled={disabled} checked={field.value} />
          }
          label={label}
        />
        {meta.touched && meta.error ? (
          <FormHelperText>{meta.error}</FormHelperText>
        ) : null}
      </FormControl>
    </Grid>
  );
};

export default CustomFormCheckbox;
