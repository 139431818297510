import React from 'react';
import { Formik } from 'formik';
import GenericFormWrapper from '../../common/components/forms/formComponents/GenericFormWrapper';
import RenderRichText from '../../common/components/RichEditor';
import CustomSelectField from '../../common/components/forms/formComponents/CustomSelectField';
import { ACTIVITIES_TOOLBAR_CONFIG } from '../../common/constants/RichTextEditorConstants';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../common/assets/icons';
import CustomMultipleOptionRows from '../../common/components/forms/formComponents/CustomMultipleOptionRows';
import { ACTIVITY_CONCLUSION_TRUST_OPTIONS } from '../constants/activities';
import * as Yup from 'yup';

const ActivityConclusionForm = ({ handleSubmit, formValues, handleUpdate }) => {
  const initialValues = formValues || {
    summary: 'Sumar concluzie',
    observations: '',
    trustLevel: '',
    selectedSegment: '',
  };

  const schema = Yup.object({
    summary: Yup.string().required('Câmp obligatoriu'),
    observations: Yup.string().required('Câmp obligatoriu'),
    trustLevel: Yup.string().required('Câmp obligatoriu'),
    selectedSegment: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formValues ? handleUpdate : handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <GenericFormWrapper>
            <RenderRichText
              name="summary"
              label="Sumar concluzie"
              height="200px"
              fullHeight
              config={ACTIVITIES_TOOLBAR_CONFIG}
              positionToolbarBottom
            />
            <RenderRichText
              name="observations"
              label="Observatii detaliate"
              height="200px"
              fullHeight
              config={ACTIVITIES_TOOLBAR_CONFIG}
              positionToolbarBottom
            />
            <CustomSelectField
              id="trustLevel"
              placeholder="Alege responsabilul"
              label="Increderea in date"
              options={ACTIVITY_CONCLUSION_TRUST_OPTIONS}
            />
            <CustomMultipleOptionRows
              label="Pe care dintre elementele de mai jos le confirma experimentul?"
              id="selectedSegment"
            />
          </GenericFormWrapper>
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {formValues ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default ActivityConclusionForm;
