import React from 'react';
import { Avatar, Box } from '@mui/material';
import { useHistory } from 'react-router';

import GenericTableComponent from '../../common/components/GenericTableComponent';
import { formatDate } from '../../common/functions/dateHelpers';
import { UnreadNotificationIcon } from '../../common/assets/icons';
import { COMMENTS_TABLE_HEADERS } from '../constants';
import CommentPathRouter from './CommentPathRouter';
import { ClampedRichText } from '../../common/components/ClampedRichText';
import { markCommentsAsRead } from '../../requests/notificationsAndComments';

const CommentsAndNotificationsTable = ({ comments, fetchComments }) => {
  const history = useHistory();
  const handleSelectedRow = () => {};

  const handleMarkCommentAsRead = async (commentId) => {
    const payload = {
      comments: [{ commentId, markRepliesAsRead: true }],
    };

    await markCommentsAsRead({ payload });
    fetchComments();
  };

  const createRows = () =>
    comments.map(
      (
        {
          _id,
          nrOfReplies,
          commentType,
          updatedAt,
          userProfileId: user,
          title,
          entityId,
          unread,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        comment: (
          <Box>
            <ClampedRichText text={title} lineClamp={1} />
          </Box>
        ),
        cale: (
          <CommentPathRouter
            entityId={entityId}
            commentId={_id}
            commentType={commentType}
            history={history}
          />
        ),
        nrOfReplies,
        user: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{ width: '20px', height: '20px', marginRight: '5px' }}
              src={user?.profilePicture}
            />
            {user?.lastName}
          </Box>
        ),
        createdAt: formatDate(updatedAt),
        status: unread ? 'Necitit' : 'Citit',
        action: (
          <Box
            onClick={() => handleMarkCommentAsRead(_id)}
            sx={{ cursor: 'pointer' }}
          >
            <UnreadNotificationIcon hasUnreadMessages={unread} />
          </Box>
        ),
      })
    );

  return (
    <Box sx={{ marginTop: '10px' }}>
      <GenericTableComponent
        headerCells={COMMENTS_TABLE_HEADERS}
        rows={createRows()}
        handleSelectedRow={handleSelectedRow}
      />
    </Box>
  );
};

export default CommentsAndNotificationsTable;
