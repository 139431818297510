import { useState } from 'react';

export function useApi(doFetch, defaultResponse = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(defaultResponse);
  const [error, setError] = useState(null);

  const onFetch = async (payload = null) => {
    let response = {};
    setIsLoading(true);
    setError(null);

    try {
      response = await doFetch({ payload });

      setResult(response);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
    return response;
  };

  return [{ isLoading, result, error }, onFetch];
}
