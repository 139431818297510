import { toast } from 'react-toastify';
import {
  checkStatus,
  errorParse,
  generateApiRoute,
  getHeaders,
  responseParser,
} from './apiUtils';
import { getAuthToken } from '../account/services/auth';

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_UPDATE = 'PUT';
export const METHOD_DELETE = 'DELETE';

export const REQUEST_OPTIONS = {
  mode: 'cors',
};

const handleFailMessage = (message) => {
  toast.error(message, {
    className: 'toastify toastify-type-error',
    bodyClassName: 'toastify-body',
    progressClassName: 'toastify-progress',
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4500,
  });
};

export function runGetApiRequest(props) {
  const {
    endpoint,
    queryParams,
    params,
    serializer = null,
    onFailMessage = null,
  } = props;
  const authToken = getAuthToken();

  const headers = getHeaders(authToken);

  const options = {
    headers,
    ...REQUEST_OPTIONS,
    method: METHOD_GET,
  };
  const route = generateApiRoute({ endpoint, queryParams, params });

  let request = fetch(route, options).then(checkStatus).then(responseParser);

  if (serializer) {
    request = request.then(serializer);
  }

  return request.catch((err) => {
    if (onFailMessage) {
      handleFailMessage(onFailMessage);
    }

    return errorParse(err);
  });
}

export function runPostApiRequest(props) {
  const {
    endpoint,
    payload,
    queryParams,
    params,
    serializer = null,
    onFailMessage = null,
  } = props;
  const authToken = getAuthToken();

  const headers = getHeaders(authToken);

  const options = {
    headers,
    method: METHOD_POST,
    body: payload ? JSON.stringify(payload) : null,
  };

  const route = generateApiRoute({ endpoint, queryParams, params });
  let request = fetch(route, options).then(checkStatus).then(responseParser);

  if (serializer) {
    request = request.then(serializer);
  }

  return request.catch((err) => {
    if (onFailMessage) {
      handleFailMessage(onFailMessage);
    }

    return errorParse(err);
  });
}

export function runDeleteApiRequest(props) {
  const { endpoint, queryParams, params, payload } = props;
  const authToken = getAuthToken();

  const headers = getHeaders(authToken);
  const options = {
    headers,
    ...REQUEST_OPTIONS,
    method: METHOD_DELETE,
    body: payload ? JSON.stringify(payload) : null,
  };
  const route = generateApiRoute({ endpoint, queryParams, params });

  return fetch(route, options)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParse);
}

export function runUpdateApiRequest(props) {
  const {
    endpoint,
    payload,
    queryParams,
    params,
    serializer = null,
    onFailMessage = null,
  } = props;

  const authToken = getAuthToken();

  const headers = getHeaders(authToken);
  const options = {
    headers,
    ...REQUEST_OPTIONS,
    method: METHOD_UPDATE,
    body: payload ? JSON.stringify(payload) : null,
  };
  const route = generateApiRoute({ endpoint, queryParams, params });

  let request = fetch(route, options).then(checkStatus).then(responseParser);

  if (serializer) {
    request = request.then(serializer);
  }

  return request.catch((err) => {
    if (onFailMessage) {
      handleFailMessage(onFailMessage);
    }

    return errorParse(err);
  });
}
