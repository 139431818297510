import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { Tooltip as MuiTooltip } from '@mui/material';
import {
  loadUserTeams,
  setCurrentUserTeam,
  resetUserTeams,
  handleMenu,
} from '../ducks';
import { ReactComponent as RocketIcon } from '../assets/icons/Navigation_Logo.svg';

import { getLastContactFormUrl } from '../../admin/ducks/contactForm';

import {
  NavigationToggleDiv,
  NavigationBarLink,
  StyledLink,
  PositionText,
} from './styled';

import {
  FounderNavigationMenu,
  MentorNavigationMenu,
  AdminNavigationMenu,
  AcceleratorNavigationMenu,
} from './partials';
import {
  ChatBotNavIcon,
  CompassNavIcon,
  FeedbackNavIcon,
  LogoutNavIcon,
} from '../assets/icons/navbar';
import { NavigationBarIconContainer } from './styled/NavigationBar';
import ChatBotDrawer from '../../chatBot/components/ChatBotDrawer';
import InteractiveTooltip from './InteractiveTooltip';
import OnboardingDashboard from '../../onboarding/components/OnboardingDashboard';

const NavigationBar = ({ role, contactForm, getLastContactFormUrl }) => {
  const [isChatBotDrawerOpen, setIsChatBotDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsChatBotDrawerOpen(true);
  };

  useEffect(() => {
    getLastContactFormUrl();
  }, []);

  const getMenuByRole = useCallback(() => {
    switch (role) {
      case 'FOUNDER':
        return <FounderNavigationMenu />;
      case 'MENTOR':
        return <MentorNavigationMenu />;
      case 'ADMIN':
        return <AdminNavigationMenu />;
      case 'ACCELERATOR':
        return <AcceleratorNavigationMenu />;

      default:
        return <div />;
    }
  }, [role]);

  return (
    <NavigationToggleDiv className="left-side-menu">
      <div style={{ overflow: 'hidden' }}>
        <ul
          className="metismenu side-nav"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
            width: '66px',
          }}
        >
          <div>
            <li className="side-nav-item" style={{ marginTop: '30px' }}>
              <NavigationBarLink
                titleapp="true"
                to="/"
                className="side-sub-nav-link side-nav-link"
                style={{
                  fontSize: '20px',
                }}
              >
                <RocketIcon />
                <PositionText isTitle>LAUNCHPAD</PositionText>
              </NavigationBarLink>
            </li>
            <div id="navbar-tools">{getMenuByRole()}</div>

            <li className="side-nav-item">
              <MuiTooltip
                title="Assisetnt AI"
                classes={{ tooltip: 'nav-tooltip' }}
                placement="right"
              >
                <NavigationBarIconContainer
                  className="icon-container"
                  onClick={openDrawer}
                >
                  <ChatBotNavIcon />
                </NavigationBarIconContainer>
              </MuiTooltip>
            </li>
          </div>
          <div>
            <li
              style={{
                margin: '0 5px 10px 5px',
                borderBottom: '2px solid rgba(255, 255, 255, 0.25)',
              }}
            />

            {role !== 'ADMIN' && (
              <li id="feedback-form" className="side-nav-item">
                <MuiTooltip
                  title="Formular feedback"
                  classes={{ tooltip: 'nav-tooltip' }}
                  placement="right"
                >
                  <StyledLink
                    href={contactForm && contactForm.formUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="side-sub-nav-link side-nav-link"
                  >
                    <NavigationBarIconContainer className="icon-container">
                      <FeedbackNavIcon />
                    </NavigationBarIconContainer>
                  </StyledLink>
                </MuiTooltip>
              </li>
            )}

            <li
              style={{
                margin: '0 5px 10px 5px',
                borderBottom: '2px solid rgba(255, 255, 255, 0.25)',
              }}
            />

            <li className="side-nav-item" style={{ marginBottom: '20px' }}>
              <InteractiveTooltip
                title="Onboarding"
                tooltipClasses={{ tooltip: 'nav-tooltip' }}
                content={<OnboardingDashboard />}
              >
                <NavigationBarIconContainer className="icon-container">
                  <CompassNavIcon />
                </NavigationBarIconContainer>
              </InteractiveTooltip>
            </li>

            <li className="side-nav-item" style={{ marginBottom: '50px' }}>
              <MuiTooltip
                title="Iesire / Logout"
                classes={{ tooltip: 'nav-tooltip' }}
                placement="right"
              >
                <NavigationBarLink
                  activeClassName="activeNavItem"
                  to="/account/logout"
                  className="side-sub-nav-link side-nav-link"
                >
                  <NavigationBarIconContainer active className="icon-container">
                    <LogoutNavIcon />
                  </NavigationBarIconContainer>
                </NavigationBarLink>
              </MuiTooltip>
            </li>
          </div>
        </ul>
      </div>
      <ChatBotDrawer
        setDrawerStatus={setIsChatBotDrawerOpen}
        isOpen={isChatBotDrawerOpen}
      />
    </NavigationToggleDiv>
  );
};

const mapStateToProps = (state) => ({
  role: get(state.account.login, 'user.role'),
  isMenuOpen: state.common.menu.isMenuOpen,
  contactForm: state.contactForm.contactForm,
});

const mapDispatchToProps = {
  loadUserTeams,
  setCurrentUserTeam,
  getLastContactFormUrl,
  resetUserTeams,
  handleMenu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);
