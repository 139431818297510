import React from 'react';
import { useField } from 'formik';
import { Box, Grid } from '@mui/material';
import { PrimaryButton } from '../../styled/ReusableTextComponents';

const CustomImageUpload = ({
  label,
  id,
  md = 12,
  helperText = '',
  initialImage,
  withDelete = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(id);
  const inputRef = React.useRef();
  const [previewImage, setPreviewImage] = React.useState(initialImage);

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
    helpers.setValue(file);
    helpers.setTouched(true);
  };

  const handleDeleteImage = (e) => {
    e.stopPropagation();
    setPreviewImage(null);
    helpers.setValue('');
    helpers.setTouched(true);
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <label style={{ color: '#2A5AB7' }} htmlFor={id}>
        {label}
      </label>
      <input
        type="file"
        id={id}
        name={id}
        onChange={handleChange}
        ref={inputRef}
        style={{ display: 'none' }}
        {...props}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          border: '2px dashed #74A3FE',
          padding: '10px',
          borderRadius: '5px',
        }}
        onClick={handleImageClick}
      >
        <Box
          sx={{
            width: '70px',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: previewImage
              ? `url(${previewImage}) no-repeat center/cover`
              : '',
          }}
        />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 'calc(100% - 70px)',
          }}
        >
          <span>{previewImage ? 'Schimba poza' : 'Incarca o poza'}</span>
          {previewImage && withDelete && (
            <PrimaryButton onClick={(e) => handleDeleteImage(e)}>
              Sterge
            </PrimaryButton>
          )}
        </Box>
      </Box>
      {meta.touched && meta.error ? (
        <Box sx={{ color: 'red' }}>{meta.error}</Box>
      ) : (
        <Box>{helperText}</Box>
      )}
    </Grid>
  );
};

export default CustomImageUpload;
