import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import { P } from '../../../common/components/styled/ReusableTextComponents';

export const ChartFilters = ({
  handleCheck,
  selectedFilters,
  competitorMetrics,
}) => {
  const { competitorList = [] } = competitorMetrics;

  const options = competitorList.map((item) => item.name);

  return (
    <Box
      sx={{
        background: '#fff',
        padding: '15px',
        border: '1px solid #74A3FE',
        borderRadius: '4px',
        marginTop: '5px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              value={option}
              checked={selectedFilters.includes(option)}
              onChange={handleCheck}
              name={option}
              color="primary"
            />
          }
          label={<P>{option}</P>}
        />
      ))}
    </Box>
  );
};
