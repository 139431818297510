export const RESOURCE_SECTION_OPTIONS = [
  {
    value: 'hypothesis',
    label: 'Ipoteză',
  },
  {
    value: 'experiment',
    label: 'Experiment',
  },
  {
    value: 'resolution',
    label: 'Concluzie',
  },
  {
    value: 'detail',
    label: 'Detaliu',
  },
];
export const COLOR_PICKER_OPTIONS = [
  '#6c757d',
  'black',
  'white',
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'brown',
  'grey',
  'transparent',
];
