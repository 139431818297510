import { useField } from 'formik';
import { get } from 'lodash-es';
import React from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import DatePicker from 'react-datepicker';

export const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  const [meta] = useField(props);
  const fieldValue = get(values, name);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      inputRef={ref}
      value={value}
      label={label}
      placeholder={placeholder}
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} />
          </InputAdornment>
        ),
      }}
    />
  ));

  return (
    <Grid item xs={12} md={6} id={controlId}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={fieldValue}
        onChange={(date) => setFieldValue(name, date)}
        customInput={<CustomInput />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </Grid>
  );
};

export const RenderDropdownField = (props) => {
  const { label, name, options, placeholder, md = 6, multiple = false } = props;
  const [field, meta, helpers] = useField(props);

  const fieldValue = multiple
    ? Array.isArray(field.value)
      ? field.value
      : []
    : field.value;

  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <FormControl fullWidth error={meta.touched && !!meta.error}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          {...field}
          multiple={multiple}
          value={fieldValue}
          label={label}
          labelId={`${name}-label`}
          id={name}
          placeholder={placeholder}
          onChange={(event) => {
            if (multiple && event.target.value.length <= 3) {
              helpers.setValue(event.target.value);
              helpers.setTouched(true);
            } else if (!multiple) {
              helpers.setValue(event.target.value);
              helpers.setTouched(true);
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
        {multiple && fieldValue.length > 3 && (
          <FormHelperText>Maximum 3 options</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};
