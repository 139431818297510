import { Box, Drawer } from '@mui/material';
import React, { useState } from 'react';
import AddActivityConclusionModal from './AddActivityConclusionModal';
import { useApi } from '../../hooks/useApi';
import { createConclusion } from '../../requests/conclusions';
import AddActivityTaskModal from './pages/AddActivityTaskModal';
import {
  createTask,
  deleteTaskById,
  postTaksDetail,
  updateTaskById,
} from '../../requests/tasks';
import {
  statusNegative,
  statusPositive,
  TASK_STATUSESS,
} from '../constants/activities';
import ActivitiesDrawerContent from './activities/ActivitiesDrawerContent';
import ActivitiesDrawerTaskPreview from './activities/ActivitiesDrawerTaskPreview';
import AddTaskDetailModal from './pages/AddTaskDetailModal';
import { useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';

const ActivitiesDrawer = ({
  isOpen,
  handleCloseDrawer,
  activity,
  teamMembers = [],
  tasks,
  conclusions,
  fetchDrawerData,
  handleDeleteActivity,
  handleEditActivity,
  isLoadingResources,
}) => {
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const [isAddConclusionModalOpen, setIsAddConclusionModalOpen] =
    useState(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [taskDetailModalId, setTaskDetailModalId] = useState(null);

  const [previewedTask, setPreviewedTask] = useState(null);
  const [, removeTask] = useApi(deleteTaskById);
  const [, runCreateConclusion] = useApi(createConclusion);
  const [, runCreateTask] = useApi(createTask);
  const [, runUpdateTask] = useApi(updateTaskById);
  const [, runCreateTaskDetail] = useApi(postTaksDetail);

  const totalCost = tasks.reduce((acc, cur) => acc + cur?.cost, 0);
  const responsable =
    teamMembers.find((el) => el.value === activity?.userId) || {};

  const handleEdit = () => {
    handleCloseDrawer();
    handleEditActivity(activity._id);
  };

  const handleDelete = () => {
    handleCloseDrawer();
    handleDeleteActivity(activity._id);
  };

  const handleDeleteTask = async (id) => {
    await removeTask({
      params: {
        taskId: id,
      },
    });
    fetchDrawerData();
    setPreviewedTask(null);
  };

  const handleEditTask = () => {
    setIsAddTaskModalOpen(true);
  };
  const handleUpdateTask = async (data) => {
    await runUpdateTask({
      params: { taskId: previewedTask?._id },
      taskData: {
        ...data,
      },
    });

    setIsAddTaskModalOpen(false);
    setPreviewedTask(null);
    await fetchDrawerData();
  };

  const updateTaskStatus = (taskId, status) =>
    runUpdateTask({
      params: {
        taskId,
      },
      taskData: {
        status,
      },
    });

  const handleToggleTask = async (taskId, currentTaskStatus) => {
    if (currentTaskStatus === TASK_STATUSESS.done) {
      await updateTaskStatus(taskId, TASK_STATUSESS.inProgress);
    } else {
      await updateTaskStatus(taskId, TASK_STATUSESS.done);
    }

    fetchDrawerData();
  };

  const handleSubmitDetail = async (values) => {
    const { summary, selectedSegment, observations, trustLevel } = values;
    const statusText = selectedSegment.status ? statusPositive : statusNegative;

    await runCreateConclusion({
      params: {
        activityId: activity._id,
      },
      conclusionData: {
        title: summary,
        description: observations,
        dataTrust: trustLevel,
        experimentType: selectedSegment?.value,
        status: statusText,
      },
    });

    setIsAddConclusionModalOpen(false);
    fetchDrawerData();
  };

  const handleSubmitTask = async (values) => {
    const { title, state, description, cost, assignedTo } = values;

    await runCreateTask({
      params: {
        activityId: activity._id,
      },
      taskData: {
        userResponsibleId: assignedTo,
        title,
        description,
        cost: +cost,
        status: state,
        completionDate: Date.now(),
      },
    });
    setIsAddTaskModalOpen(false);
    fetchDrawerData();
  };

  const handleCreateTaskDetail = async (vals) => {
    await runCreateTaskDetail({
      params: { teamId },
      detailData: {
        ...vals,
        taskId: taskDetailModalId,
        section: 'tasks',
        activityId: activity?._id,
      },
    });
    setTaskDetailModalId(null);
  };

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
        {previewedTask ? (
          <ActivitiesDrawerTaskPreview
            previewedTask={previewedTask}
            handleCloseDrawer={handleCloseDrawer}
            setPreviewedTask={setPreviewedTask}
            handleDeleteTask={handleDeleteTask}
            handleEditTask={handleEditTask}
          />
        ) : (
          <ActivitiesDrawerContent
            handleCloseDrawer={handleCloseDrawer}
            activity={activity}
            totalCost={totalCost}
            isLoadingResources={isLoadingResources}
            tasks={tasks}
            conclusions={conclusions}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            responsable={responsable}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            handleToggleTask={handleToggleTask}
            setIsAddConclusionModalOpen={setIsAddConclusionModalOpen}
            setPreviewedTask={setPreviewedTask}
            setTaskDetailModalId={setTaskDetailModalId}
          />
        )}
      </Drawer>

      <AddActivityConclusionModal
        handleClose={() => setIsAddConclusionModalOpen(false)}
        handleSubmit={handleSubmitDetail}
        isModalOpen={isAddConclusionModalOpen}
      />
      <AddActivityTaskModal
        handleClose={() => setIsAddTaskModalOpen(false)}
        handleSubmit={handleSubmitTask}
        handleUpdate={handleUpdateTask}
        isModalOpen={isAddTaskModalOpen}
        teamMembers={teamMembers}
        isEditMode={!!previewedTask}
        editedTask={previewedTask}
      />
      <AddTaskDetailModal
        handleSubmit={handleCreateTaskDetail}
        isModalOpen={!!taskDetailModalId}
        setTaskDetailModalId={setTaskDetailModalId}
      />
    </>
  );
};

export default ActivitiesDrawer;
