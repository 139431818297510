import React, { useState } from 'react';
import { Box, Drawer } from '@mui/material';
import ChatWelcomeScreen from './ChatWelcomeScreen';
import ChatBotMessagesScreen from './ChatBotMessagesScreen';

const ChatBotDrawer = ({ isOpen = false, setDrawerStatus }) => {
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const handleClose = () => setDrawerStatus(false);
  const skipWelcomeScreen = () => setShowWelcomeScreen(false);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <Box sx={{ width: '350px', padding: '20px', height: '100%' }}>
        {showWelcomeScreen && (
          <ChatWelcomeScreen skipWelcomeScreen={skipWelcomeScreen} />
        )}
        {!showWelcomeScreen && <ChatBotMessagesScreen />}
      </Box>
    </Drawer>
  );
};

export default ChatBotDrawer;
