import styled from 'styled-components';

export const CounterValue = styled.h1`
  text-align: center;
  font-weight: bold;
  color: #4267b2;
  font-size: 32px;
`;

export const CounterLabel = styled.h4`
  text-align: center;
  font-weight: bold;
  color: #313131;
  font-size: 22px;
`;

export const PlatformInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlatformInfoElement = styled.div`
  display: inline-block;
`;

export const IconContainer = styled.div`
  font-size: 22px;
  text-align: center;
`;
