import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { currentTeamIdSelector } from '../../../common/ducks';
import { getAllIndustriesByTeamId, deleteIndustryById } from '../../ducks';
import { INDUSTRY_TYPES, INDUSTRY_TYPES_LABELS } from '../../constants';
import {
  H3,
  P,
} from '../../../common/components/styled/ReusableTextComponents';
import { AddButtonComponent } from '../Buttons';
import { IndustryModal } from '../modals/IndustryModal';
import { IndustryDrawer } from '../IndustryDrawer';
import { IndustryCardComponent } from '../IndustryCardComponent';
import { IndustriesPageContainer } from '../styled/ContextAnalysis';

const IndustriesPage = ({
  teamId,
  getAllIndustriesByTeamId,
  teamIndustries,
  deleteIndustryById,
}) => {
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);
  const [industryType, setIndustryType] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (teamId) {
      getAllIndustriesByTeamId(teamId);
    }
  }, [teamId]);

  const handleAddIndustry = (type) => {
    setIndustryType(type);
    setIsIndustryModalOpen(true);
  };

  const handleViewIndustry = (industry) => {
    setSelectedIndustry(industry);
    setIsDrawerOpen(true);
  };

  const handleEditIndustry = (industry) => {
    setSelectedIndustry(industry);
    setIsIndustryModalOpen(true);
    setIsDrawerOpen(false);
  };

  const handleDeleteIndustry = (industry) => {
    deleteIndustryById(industry._id, teamId);
    setSelectedIndustry(null);
    setIsDrawerOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedIndustry(null);
  };

  return (
    <IndustriesPageContainer>
      <Grid sx={{ height: '100%' }} container spacing={1}>
        {INDUSTRY_TYPES.map((type, idx) => (
          <Grid sx={{ height: '100%' }} item xs key={type}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                borderRight: '1px solid rgba(116, 163, 254, 0.3)',
                height: '100%',
                ...(idx === INDUSTRY_TYPES.length - 1 && { border: 'none' }),
              }}
            >
              <Box
                id={`industry-type-${type}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <H3> {INDUSTRY_TYPES_LABELS[type].title} </H3>

                <AddButtonComponent onClick={() => handleAddIndustry(type)} />
              </Box>
              <Box
                className="scrollbar"
                sx={{
                  height: '100%',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  paddingRight: '5px',
                }}
              >
                {teamIndustries && teamIndustries[type] && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {teamIndustries[type].map((industry) => (
                      <IndustryCardComponent
                        key={industry._id}
                        industry={industry}
                        handleEditIndustry={handleEditIndustry}
                        handleDeleteIndustry={handleDeleteIndustry}
                        handleViewIndustry={handleViewIndustry}
                      />
                    ))}
                  </Box>
                )}
                {teamIndustries && !teamIndustries[type] && (
                  <P marginTop="22px" faded>
                    {INDUSTRY_TYPES_LABELS[type].description}
                  </P>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <IndustryModal
        industryType={industryType}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
        isModalOpen={isIndustryModalOpen}
        setIsModalOpen={setIsIndustryModalOpen}
      />

      <IndustryDrawer
        isDrawerOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        selectedIndustry={selectedIndustry}
        handleEditIndustry={handleEditIndustry}
        handleDeleteIndustry={handleDeleteIndustry}
      />
    </IndustriesPageContainer>
  );
};

const mapStateToProps = (state) => ({
  teamIndustries: state.contextAnalysis.teamIndustries,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  getAllIndustriesByTeamId,
  deleteIndustryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustriesPage);
