import { runGetApiRequest } from '../api/Api';
import {
  GET_ALL_ARTICLES,
  GET_ARTICLE_BY_ID,
} from '../common/constants/apiRoutes';

export function getAllArticles({ payload = {} }) {
  const defaultQueryParams = {
    filters: {
      ...payload,
    },
  };

  return runGetApiRequest({
    endpoint: GET_ALL_ARTICLES,
    params: payload.params,
    queryParams: { ...defaultQueryParams, ...payload.queryParams },
    onFailMessage: 'Something went wrong while fetching articles',
  });
}

export function getArticleById({ payload = {} }) {
  return runGetApiRequest({
    endpoint: GET_ARTICLE_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while fetching article',
  });
}
