import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
} from '../../../common/components/styled';

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  passwordConfirmed,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationFormikReset01">
        <Heading2 marginBottom="10px">Noua Parolă</Heading2>
        <Input
          type="password"
          placeholder="Noua Parolă"
          name="password"
          value={values.password}
          onChange={handleChange}
          isInvalid={submitCount > 0 && !!errors.password}
        />

        <Form.Control.Feedback type="invalid">
          {submitCount > 0 && errors.password}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationFormikReset02">
        <Heading2 marginBottom="10px">Confirmă parola</Heading2>
        <Input
          type="password"
          placeholder="Noua Parolă"
          name="passwordConfirmation"
          value={values.passwordConfirmation}
          onChange={handleChange}
          isInvalid={submitCount > 0 && !!errors.passwordConfirmation}
        />
        <Form.Control.Feedback type="invalid">
          {submitCount > 0 && errors.passwordConfirmation}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>

    {!passwordConfirmed && (
      <Form.Row>
        <Form.Group as={Col} md="12">
          Parola nu coincide
        </Form.Group>
      </Form.Row>
    )}
    <ButtonDiv>
      <SubmitButton type="submit">Salvează Parola</SubmitButton>
    </ButtonDiv>
  </Form>
);

const ResetPasswordForm = ({ handleSubmit }) => {
  const [passwordConfirmed, setPasswordConfirmed] = useState(true);

  const onSubmit = (values, { resetForm }) => {
    if (values.password === values.passwordConfirmation) {
      handleSubmit(values);
    } else {
      setPasswordConfirmed(false);
    }
  };

  const schema = Yup.object({
    password: Yup.string().required('Câmp obligatoriu'),
    passwordConfirmation: Yup.string()
      .required('Câmp obligatoriu')
      .oneOf([Yup.ref('password'), null], 'Parolele nu coincid'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ password: '', passwordConfirmation: '' }}
    >
      {(props) => (
        <RenderForm {...props} passwordConfirmed={passwordConfirmed} />
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
