import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { PageContainer } from '../../../common/components/styled';
import {
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';

import AddCategoryModal from '../AddCategoryModal';
import {
  addCategory,
  deleteCategory,
  editCategory,
  getAllCategories,
} from '../../ducks/categories';
import { deleteArticle, getAllArticles } from '../../ducks/articles';
import ArticlesFilterModal from '../ArticlesFilterModal';
import GenericDeleteModal from '../../../common/components/GenericDeleteModal';
import { TrashIconOutlined } from '../../../common/assets/icons';
import CategoryList from '../CategoryList';
import ArticlesList from '../ArticlesList';

const Articles = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isCategoriesModalOpen, setCategoriesIsModalOpen] = useState(false);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    visibility: ['all'],
    categories: [],
  });
  const [isDeleteArticleModalOpen, setIsDeleteArticleModalOpen] =
    useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
    useState(false);
  const [categoryIdToEdit, setCategoryIdToEdit] = useState(null);
  const [toggleValue, setToggleValue] = useState('articles');
  const [articleIdToDelete, setArticleIdToDelete] = useState(null);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);

  const categories =
    useSelector((state) => state.admin.categories.categories) || [];
  const articles = useSelector((state) => state.admin.articles.articles) || [];

  const resetCategoryIdToEdit = () => {
    setCategoryIdToEdit(null);
  };

  const handleCategoriesModalStatus = () => {
    setCategoriesIsModalOpen(!isCategoriesModalOpen);
  };

  const handleApplyFilters = (values) => {
    // Destructure filters for better readability
    const { visibility, categories } = values;

    // Filtering based on the visibility and categories
    const newArticles = articles.filter((item) => {
      // Visibility Filter
      let visibilityPass;
      if (visibility[0] === 'all') {
        visibilityPass = true;
      } else if (visibility[0] === 'hidden') {
        visibilityPass = !item.isVisible;
      } else if (visibility[0] === 'visible') {
        visibilityPass = item.isVisible;
      }

      // Categories Filter
      let categoriesPass = true;
      if (categories.length > 0) {
        categoriesPass = categories.includes(item.categoryId._id);
      }

      return visibilityPass && categoriesPass;
    });

    setFilteredArticles(newArticles);
  };

  const handleGetAllArticles = async () => {
    await dispatch(getAllArticles());
  };

  const handleToggleValue = (newValue) => {
    setToggleValue(newValue);
    handleGetAllArticles();
  };

  const createNewArticle = () => {
    history.push('/admin/mrl/article/form');
  };

  const handleGetAllCategories = async () => {
    await dispatch(getAllCategories());
  };

  const createNewCategory = async (formValues) => {
    handleCategoriesModalStatus();
    const payload = formValues;
    await dispatch(addCategory(payload));
    await handleGetAllCategories();
    handleCategoriesModalStatus();
  };

  const onEditCategory = async (categoryId, payload) => {
    await dispatch(editCategory(categoryId, payload));
    await handleGetAllCategories();
    handleCategoriesModalStatus();
    resetCategoryIdToEdit();
  };

  const handleEditCategory = (categoryId, e) => {
    e.stopPropagation();
    handleCategoriesModalStatus();
    setCategoryIdToEdit(categoryId);
  };

  const onDeleteCategory = async () => {
    await dispatch(deleteCategory(categoryIdToDelete));
    await handleGetAllCategories();
  };

  const handleEditArticle = (articleId, e) => {
    e.stopPropagation();
    history.push(`/admin/mrl/article/form?articleId=${articleId}`);
  };

  const onDeleteArticle = async () => {
    await dispatch(deleteArticle(articleIdToDelete));
    await handleGetAllArticles();
  };

  const toggleDeleteArticleModal = () => {
    setIsDeleteArticleModalOpen(!isDeleteArticleModalOpen);
  };
  const toggleDeleteCategoryModal = () => {
    setIsDeleteCategoryModalOpen(!isDeleteCategoryModalOpen);
  };

  const handleDeleteArticle = (articleId, e) => {
    e.stopPropagation();
    toggleDeleteArticleModal();
    setArticleIdToDelete(articleId);
  };

  const handleDeleteCategory = (categoryId, e) => {
    e.stopPropagation();
    toggleDeleteCategoryModal();
    setCategoryIdToDelete(categoryId);
  };

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  const handleSubmitFilters = (values) => {
    setFilters(values); // Update your filter state here
    handleApplyFilters(values); // Apply filters after state is updated
    toggleFiltersModal(); // Hide the modal
  };

  useEffect(() => {
    handleGetAllCategories();
    handleGetAllArticles();
  }, []);

  useEffect(() => {
    handleApplyFilters(filters);
  }, [articles, categories, filters, toggleValue]);

  return (
    <PageContainer className="scrollbar">
      <Box
        sx={{
          width: '100%',
          margin: '20px auto',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {toggleValue === 'articles' ? (
          <ArticlesList
            filters={filters}
            createNewArticle={createNewArticle}
            toggleFiltersModal={toggleFiltersModal}
            articles={filteredArticles}
            handleEditArticle={handleEditArticle}
            handleDeleteArticle={handleDeleteArticle}
            isDeleteArticleModalOpen={isDeleteArticleModalOpen}
            toggleDeleteArticleModal={toggleDeleteArticleModal}
            handleToggleValue={handleToggleValue}
            toggleValue={toggleValue}
          />
        ) : (
          <CategoryList
            categories={categories}
            toggleValue={toggleValue}
            filters={filters}
            handleToggleValue={handleToggleValue}
            handleEditCategory={handleEditCategory}
            handleDeleteCategory={handleDeleteCategory}
          />
        )}
      </Box>
      <AddCategoryModal
        isModalOpen={isCategoriesModalOpen}
        handleClose={() => {
          handleCategoriesModalStatus();
          resetCategoryIdToEdit();
        }}
        handleSubmit={
          categoryIdToEdit
            ? (values) => onEditCategory(categoryIdToEdit, values)
            : createNewCategory
        }
        categoryData={categories.find(({ _id }) => _id === categoryIdToEdit)}
        allCategoryNames={categories.map(({ name }) => name)}
      />
      <ArticlesFilterModal
        isModalOpen={isFiltersModalOpen}
        handleSubmit={handleSubmitFilters}
        handleClose={toggleFiltersModal}
        filters={filters}
        categories={categories}
      />
      <GenericDeleteModal
        isModalOpen={isDeleteArticleModalOpen}
        handleClose={() => setIsDeleteArticleModalOpen(false)}
        title="Confirmare stergere"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '25px',
          }}
        >
          <P>Sunteti sigur ca doriti stergerea articolului?</P>
          <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
            <PrimaryButton
              onClick={() => setIsDeleteArticleModalOpen(false)}
              inverted
              marginRight="10px"
            >
              Renunta
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                onDeleteArticle();
                setIsDeleteArticleModalOpen(false);
                setArticleIdToDelete(null);
              }}
              icon={TrashIconOutlined}
              inverted
            >
              Sterge
            </SecondaryButton>
          </Box>
        </Box>
      </GenericDeleteModal>
      <GenericDeleteModal
        isModalOpen={isDeleteCategoryModalOpen}
        handleClose={() => setIsDeleteCategoryModalOpen(false)}
        title="Confirmare stergere"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '25px',
          }}
        >
          <P>Sunteti sigur ca doriti stergerea categoriei?</P>
          <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
            <PrimaryButton
              onClick={() => setIsDeleteCategoryModalOpen(false)}
              inverted
              marginRight="10px"
            >
              Renunta
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                onDeleteCategory();
                setIsDeleteCategoryModalOpen(false);
                setCategoryIdToDelete(null);
              }}
              icon={TrashIconOutlined}
              inverted
            >
              Sterge
            </SecondaryButton>
          </Box>
        </Box>
      </GenericDeleteModal>
    </PageContainer>
  );
};

export default Articles;
