import { http } from '../../core/services/http';

export const postNewTrend = (values, teamId) =>
  http.post(`/trends/add/teams/${teamId}`, values);

export const getAllTrendsByTeamId = (teamId, trendFilters = [true, false]) => {
  const JsonFilters = JSON.stringify({ isTrendFavorable: [...trendFilters] });

  return http.get(`/trends/get-all/teams/${teamId}?filters=${JsonFilters}`);
};

export const deleteTrendById = (trendId) =>
  http.delete(`/trends/delete/${trendId}`);

export const editTrendById = (trendId, values) =>
  http.put(`/trends/edit/${trendId}`, values);

export const gettAllIndustriesByTeamId = (teamId) =>
  http.get(`/industries/get-all/teams/${teamId}`);

export const postNewIndustry = (values, teamId) =>
  http.post(`/industries/add/teams/${teamId}`, values);

export const editIndustryById = (industryId, values) =>
  http.put(`/industries/edit/${industryId}`, values);

export const deleteIndustryById = (industryId) =>
  http.delete(`/industries/delete/${industryId}`);

export const postNewSwotElement = (values, teamId) =>
  http.post(`/swot/add/teams/${teamId}`, values);

export const getAllSwotElementsByTeamId = (teamId) =>
  http.get(`/swot/get-all/teams/${teamId}`);

export const deleteSwotElementById = (swotId) =>
  http.delete(`/swot/delete/${swotId}`);

export const editSwotElementById = (swotId, values) =>
  http.put(`/swot/edit/${swotId}`, values);

export const getAllCompetitorMetricsByTeamId = (teamId) =>
  http.get(`/team/${teamId}/get/competitionMetrics`);

export const postNewCompetitor = (values, teamId) =>
  http.post(`/team/${teamId}/competitor/add`, values);

export const editCompetitor = (values, teamId, competitorId) =>
  http.put(`/team/${teamId}/competitor/edit/${competitorId}`, values);

export const postCompetitionMetric = (values, teamId) =>
  http.post(`/team/${teamId}/competitionMetrics/add`, values);

export const postNewCriteria = (values, teamId) =>
  http.post(`/team/${teamId}/criteria/add`, values);

export const editCriteria = (values, teamId, criteriaId) =>
  http.put(`/team/${teamId}/criteria/edit/${criteriaId}`, values);

export const deleteCriteria = (teamId, criteriaId) =>
  http.delete(`/team/${teamId}/criteria/delete/${criteriaId}`);

export const deleteCompetitor = (teamId, competitorId) =>
  http.delete(`/team/${teamId}/competitor/delete/${competitorId}`);

export const editConclusion = (values, teamId) =>
  http.put(`/team/edit/${teamId}/analysisConclusion`, values);

export const getAllDetails = (teamId, filters) => {
  const url = `/details/get-all/teams/${teamId}?filters=${JSON.stringify(
    filters
  )}`;
  return http.get(url);
};

export const postDetail = (values, teamId) =>
  http.post(`/details/add/teams/${teamId}`, values);

export const editDetail = (values, contextAnalysisDetailsId) =>
  http.put(`/details/edit/${contextAnalysisDetailsId}`, values);

export const deleteDetail = (contextAnalysisDetailsId) =>
  http.delete(`/details/delete/${contextAnalysisDetailsId}`);
