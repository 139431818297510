import {
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../api/Api';
import {
  CANCEL_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTIONS,
  PREVIEW_UPDATE_USER_SUBSCRIPTION,
  START_CHECKOUT_SESSION,
  UPDATE_USER_SUBSCRIPTION,
  USER_NEXT_PAYMENT,
} from '../common/constants/apiRoutes';
import { formatTimestampInRomanian } from '../common/functions/dateHelpers';

export const startCheckoutSession = ({ payload }) =>
  runPostApiRequest({
    endpoint: START_CHECKOUT_SESSION,
    payload: payload.data,
  });

export const updateUserSubscription = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_USER_SUBSCRIPTION,
    payload: payload.data,
  });

export const previewUpdateUserSubscription = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: PREVIEW_UPDATE_USER_SUBSCRIPTION,
    payload: payload.data,
  });

export const cancelUserSubscription = () =>
  runUpdateApiRequest({
    endpoint: CANCEL_SUBSCRIPTION,
  });

const nextPaymentSerializer = (data) => {
  if (!data) return null;

  const nextPaymentData = {
    customerId: data?.customer,
    subscriptionPrice: data?.subtotal && data?.subtotal / 100,
    startedOn: formatTimestampInRomanian(data?.period_start),
    willEndOn: formatTimestampInRomanian(data?.period_end),
    nextPaymentAttempt: formatTimestampInRomanian(data?.next_payment_attempt),
  };

  return nextPaymentData;
};

export const getLoggedUserNextPayment = () =>
  runGetApiRequest({
    endpoint: USER_NEXT_PAYMENT,
    serializer: nextPaymentSerializer,
  });

export const getAllSubscriptionOptions = () =>
  runGetApiRequest({
    endpoint: GET_ALL_SUBSCRIPTIONS,
  });
