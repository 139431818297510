import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import uuid from 'react-uuid';
import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ButtonDiv,
  SaveIcon,
  DatePicker,
  Input,
  Heading2,
  SubmitButton,
  CopyIcon,
} from '../../../common/components/styled';

const RenderNameField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderCohortCodeField = (props) => {
  const {
    values,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    copyToClipBoard,
    cohortCodeFieldRef,
    copySuccess,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <div style={{ display: 'flex', justifyContent: 'spacebetween' }}>
        <Input
          disabledInput
          ref={cohortCodeFieldRef}
          type={type}
          name={name}
          readOnly
          placeholder={placeholder}
          value={fieldValue}
          isInvalid={meta.touched && !!meta.error}
        />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{copySuccess}</Tooltip>}
        >
          <CopyIcon onClick={(e) => copyToClipBoard(e)} />
        </OverlayTrigger>
      </div>

      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        component={DatePicker}
        as={DatePicker}
        name={name}
        placeholderText={placeholder}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={new Date()}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  copyToClipBoard,
  cohortCodeFieldRef,
  copySuccess,
  setFieldValue,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderNameField
        placeholder="Nume"
        name="name"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Nume"
      />
      <RenderCohortCodeField
        placeholder="Cod cohortă"
        name="cohortCode"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Cod cohortă"
        cohortCodeFieldRef={cohortCodeFieldRef}
        copyToClipBoard={copyToClipBoard}
        copySuccess={copySuccess}
      />
    </Form.Row>
    <Form.Row>
      <RenderDateField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="startDate"
        label="Început perioadă înscrieri"
        placeholder="zz/ll/aaaa"
      />
      <RenderDateField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik04"
        name="endDate"
        label="Sfârșit perioadă înscrieri"
        placeholder="zz/ll/aaaa"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const CreateCohortForm = (props) => {
  const [copySuccess, setCopySuccess] = useState('Copy');
  const cohortCodeFieldRef = useRef(null);

  const copyToClipBoard = (e) => {
    cohortCodeFieldRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
  };

  const { onSubmit, cohort, isEditMode } = props;
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    name: Yup.string().required('Câmp obligatoriu'),
    cohortCode: Yup.string().required('Câmp obligatoriu'),
    startDate: Yup.string().required('Câmp obligatoriu'),
    endDate: Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    name: '',
    cohortCode: uuid(),
    startDate: '',
    endDate: '',
  };

  const defaultcohort = {
    ...cohort,
    startDate: cohort && cohort.startDate && new Date(cohort.startDate),
    endDate: cohort && cohort.endDate && new Date(cohort.endDate),
  };

  const initialValues = isEditMode ? defaultcohort : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          cohortCodeFieldRef={cohortCodeFieldRef}
          copyToClipBoard={copyToClipBoard}
          copySuccess={copySuccess}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};

export default CreateCohortForm;
