import styled from 'styled-components';

export const PositionDiv = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  text-align: center;
`;
export const PercentageText = styled.p`
  position: absolute;
  top: 30%;
  left: 45%;
  font-size: 15px;
`;
export const TagEditDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 41px;
`;

export const ProfileMenuH2 = styled.h2`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #3f3f3f;
`;

export const ProfileMenu = styled.div`
  display: flex;
`;
