import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import AddCharacteristicsModal from './modals/AddCharacteristicsModal';
import { deleteCharacteristicElement, loadHypothesis } from '../ducks';
import AddPersonInfoModal from './modals/AddPersonInfoModal';
import {
  ExclamationMarkIcon,
  HappyIcon,
  SadIcon,
  SearchPersonIcon,
} from '../../common/assets/icons';
import HypothesisProfileMiddleComponent from './HypothesisProfileMiddleComponent';
import CharacteristicsSection from './ProfileCharacteristicSection';
import {
  checkResizePain,
  checkResizeTask,
  checkResizeWho,
  checkResizeWin,
} from '../helpers';
import { PROFILE_HYPOTHESIS_PLACEHOLDERS } from '../constants/hypotheses';

function HypothesisProfileComponent(props) {
  const { hypothesis, loadHypothesis, deleteCharacteristic } = props;

  const [isCharacteristicsModalOpen, setIsCharacteristicsModalOpen] =
    useState(false);
  const [isPersonInfoModalOpen, setIsPersonInfoModalOpen] = useState(false);
  const [characteristicType, setCharacteristicType] = useState();
  const [whoCharacteristics, setWhoCharacteristics] = useState([]);
  const [taskCharacteristics, setTaskCharacteristics] = useState([]);
  const [painCharacteristics, setPainCharacteristics] = useState([]);
  const [winCharacteristics, setWinCharacteristics] = useState([]);
  const [columns, setColumns] = useState();

  let imageContainerElement = document.getElementById('middle-container');

  const handleDeleteCharacteristic = (id) => {
    deleteCharacteristic(id, hypothesis._id);
  };

  useEffect(() => {
    imageContainerElement = document.getElementById('middle-container');
    checkResizeWho('parent-container-who');
    checkResizeTask('parent-container-task');
    checkResizePain('parent-container-pain');
    checkResizeWin('parent-container-win');
  }, [imageContainerElement, columns]);

  useEffect(() => {
    const whos = [];
    const tasks = [];
    const pains = [];
    const wins = [];

    if (hypothesis.clientProfile) {
      for (const item of hypothesis.clientProfile.characteristics) {
        switch (item.type) {
          case 'who':
            whos.push(item);
            break;
          case 'task':
            tasks.push(item);
            break;
          case 'win':
            wins.push(item);
            break;
          case 'pain':
            pains.push(item);
            break;
          default:
            console.warn(`Unknown type: ${item.type}`);
        }
      }
    }

    setWhoCharacteristics(whos);
    setTaskCharacteristics(tasks);
    setPainCharacteristics(pains);
    setWinCharacteristics(wins);

    setColumns({
      who: { items: whos },
      task: { items: tasks },
      pain: { items: pains },
      win: { items: wins },
    });
  }, [hypothesis]);

  const handleHookSet = (type) => {
    switch (type) {
      case 'who':
        setIsCharacteristicsModalOpen(true);
        setCharacteristicType('who');
        break;
      case 'task':
        setIsCharacteristicsModalOpen(true);
        setCharacteristicType('task');
        break;
      case 'pain':
        setIsCharacteristicsModalOpen(true);
        setCharacteristicType('pain');
        break;
      case 'win':
        setIsCharacteristicsModalOpen(true);
        setCharacteristicType('win');
        break;
      case 'hypothesis':
        // setIsHypothesisModalOpen(true);
        break;

      default:
    }
  };

  function compareFunction(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const sortItems = (list) => {
    const newList = list.sort(compareFunction);
    return newList;
  };

  if (!columns) return <></>;

  // TODO: Refacot to use the same component for all 4 sections

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', height: '50%', marginBottom: '20px' }}>
          <CharacteristicsSection
            id="parent-container-who"
            onScroll={() => checkResizeWho('parent-container-who')}
            characteristics={whoCharacteristics}
            title="Cine"
            IconComponent={SearchPersonIcon}
            onAddClick={() => handleHookSet('who')}
            onSortClick={() => {}}
            sortItems={sortItems}
            deleteCharacteristic={handleDeleteCharacteristic}
            placeholder={PROFILE_HYPOTHESIS_PLACEHOLDERS.who}
          />
          <CharacteristicsSection
            id="parent-container-task"
            onScroll={() => checkResizeTask('parent-container-task')}
            characteristics={taskCharacteristics}
            title="Sarcini sau probleme"
            IconComponent={SadIcon}
            onAddClick={() => handleHookSet('task')}
            onSortClick={() => {}}
            sortItems={sortItems}
            placeholder={PROFILE_HYPOTHESIS_PLACEHOLDERS.tasks}
            deleteCharacteristic={handleDeleteCharacteristic}
          />
        </Box>
        <Box sx={{ display: 'flex', height: '50%', marginBottom: '20px' }}>
          <CharacteristicsSection
            id="parent-container-pain"
            onScroll={() => checkResizePain('parent-container-pain')}
            characteristics={painCharacteristics}
            title="Dureri"
            IconComponent={ExclamationMarkIcon}
            onAddClick={() => handleHookSet('pain')}
            onSortClick={() => {}}
            sortItems={sortItems}
            paddingRight="130px"
            placeholder={PROFILE_HYPOTHESIS_PLACEHOLDERS.pain}
            deleteCharacteristic={handleDeleteCharacteristic}
          />
          <CharacteristicsSection
            id="parent-container-win"
            onScroll={() => checkResizeWin('parent-container-win')}
            characteristics={winCharacteristics}
            title="Castiguri"
            IconComponent={HappyIcon}
            onAddClick={() => handleHookSet('win')}
            onSortClick={() => {}}
            sortItems={sortItems}
            paddingLeft="110px"
            deleteCharacteristic={handleDeleteCharacteristic}
            placeholder={PROFILE_HYPOTHESIS_PLACEHOLDERS.wins}
          />
        </Box>

        <HypothesisProfileMiddleComponent
          hypothesis={hypothesis}
          setIsPersonInfoModalOpen={setIsPersonInfoModalOpen}
        />

        <AddCharacteristicsModal
          isModalOpen={isCharacteristicsModalOpen}
          setIsModalOpen={setIsCharacteristicsModalOpen}
          type={characteristicType}
          entityId={hypothesis._id}
          hypothesis={hypothesis}
          loadHypothesis={loadHypothesis}
        />

        <AddPersonInfoModal
          isModalOpen={isPersonInfoModalOpen}
          setIsModalOpen={setIsPersonInfoModalOpen}
          isEditMode
          entityId={hypothesis._id}
          hypothesis={hypothesis}
          loadHypothesis={loadHypothesis}
        />
      </Box>
    </>
  );
}

const mapDispatchToProps = {
  loadHypothesis,
  deleteCharacteristic: deleteCharacteristicElement,
};

export default connect(
  undefined,
  mapDispatchToProps
)(HypothesisProfileComponent);
