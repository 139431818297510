import React, { useState, useEffect } from 'react';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import draftToHtmlPuri from 'draftjs-to-html';
import { Title, TitleCounterDiv } from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { loadMrl, getMrlById, editMrl } from '../../../mrl/ducks/mrl';

import {
  CounterContainer,
  EditorContainer,
  HeadingStage,
  MrlGuideContainer,
  SaveButtonContainer,
  StageTitle,
  WhiteCard,
} from '../styled/MrlStyled';
import MrlTask from '../MrlTask';
import {
  ListButtonContainer,
  MarginContainer,
} from '../../../mrl/components/styled/MrlStyled';
import { FONT_FAMILY_OPTIONS } from '../../../common/constants';
import { emptyContentState, getBlockStyle } from '../../constants/MrlEdittor';
import { COLOR_PICKER_OPTIONS } from '../../constants/ResourceSectionOptions';
import { useHistory } from 'react-router-dom';

const MrlStage = (props) => {
  const [editorState, setEditorState] = useState();
  const [stage, setStage] = useState();
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [columns, setColumns] = useState();
  const [dragHeight, setDragHeight] = useState('auto');
  const history = useHistory();

  const {
    match: {
      params: { id },
    },
    editMrl,
    mrl,
    getMrlById,
  } = props;

  useEffect(() => {
    getMrlById(id);
  }, [id]);

  useEffect(() => {
    if (mrl) {
      // Convert the saved Raw Content State back to EditorState
      const rawContentState =
        mrl.description !== ''
          ? JSON.parse(mrl.description)
          : emptyContentState;
      const contentState = convertFromRaw(rawContentState);
      setEditorState(EditorState.createWithContent(contentState));

      setStage(mrl);
    }
  }, [mrl]);

  useEffect(() => {
    if (mrl) {
      setColumns({ tasks: { items: mrl.tasks } });
    }
  }, [mrl]);

  let htmlPuri;
  if (editorState) {
    htmlPuri = draftToHtmlPuri(
      convertToRaw(editorState._immutable.currentContent)
    );
  }

  const submitHandler = async () => {
    // in order to keep the formatting of the text, we need to convert the editorState to raw and then to html
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    await editMrl(
      {
        ...stage,
        ...{
          descriptionHtml: htmlPuri,
          description: JSON.stringify(rawContentState),
        },
      },
      id
    );
    await getMrlById(id);
  };

  const saveChangesOnTask = ({
    title,
    htmlPuri,
    description,
    correctEditorState,
    link,
    buttonName,
    taskName,
  }) => {
    const auxiliar = { ...stage };

    for (const item of auxiliar.tasks) {
      if (item.name === title) {
        item.descriptionHtml = htmlPuri;
        item.description = correctEditorState;
        item.link = link;
        item.buttonName = buttonName;
        item.name = taskName;
        break;
      }
    }
    setStage(auxiliar);
    editMrl(auxiliar, id);
  };

  const updateOrder = (list) => {
    const newList = [];

    list.map((item, index) => {
      const newItem = item;
      newItem.order = index;
      newList.push(newItem);
      return newItem;
    });

    const newColumnList = {
      [list[0].columnBMC]: {
        items: newList,
      },
    };
    setColumns((prev) => ({ ...prev, ...newColumnList }));
    editMrl({ ...mrl, tasks: newList }, id);
  };

  function compareFunction(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      removed.columnBMC = result.destination.droppableId;
      destItems.splice(destination.index, 0, removed);
      updateOrder(destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      removed.order = result.destination.index;
      copiedItems.splice(destination.index, 0, removed);
      updateOrder(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const sortItems = (list) => {
    const newList = list.sort(compareFunction);
    return newList;
  };

  if (!mrl || !stage || !columns) return <></>;

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <ListButtonContainer
            onClick={() => {
              history.push('/admin/mrl');
            }}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{
                marginRight: '5px',
                color: '#2A5AB7',
              }}
            />
            <span>Lista</span>
          </ListButtonContainer>
          <Title>Etapa</Title>
        </TitleCounterDiv>
      </TitleButtonsDiv>
      <MrlGuideContainer className="scrollbar">
        <WhiteCard>
          <HeadingStage>Etapa</HeadingStage>
          <MarginContainer display="flex">
            <CounterContainer>{stage.order + 1}</CounterContainer>
            <MarginContainer display="block" width="90%">
              <StageTitle
                marginLeft="1vw"
                placeholder="Etapa"
                value={stage.name}
                onChange={(e) => {
                  setStage({
                    ...stage,
                    ...{ name: e.target.value },
                  });
                }}
              />
              <StageTitle
                marginLeft="1vw"
                placeholder="Subtitlu"
                value={stage.subtitle}
                onChange={(e) => {
                  setStage({
                    ...stage,
                    ...{ subtitle: e.target.value },
                  });
                }}
              />
              <EditorContainer marginLeft="1vw" className="scrollbar">
                <Editor
                  editorState={editorState}
                  wrapperClassName="rich-editor-wrapper"
                  editorClassName="scrollbar"
                  onEditorStateChange={setEditorState}
                  editorStyle={{
                    paddingLeft: '1vh',
                    paddingRight: '1vh',
                    overflowX: 'hidden',
                  }}
                  toolbarStyle={{ zIndex: 9999 }}
                  blockStyleFn={getBlockStyle}
                  toolbar={{
                    fontFamily: {
                      options: FONT_FAMILY_OPTIONS,
                    },
                    textAlign: {
                      inDropdown: false,
                      options: ['left', 'center', 'right', 'justify'],
                    },
                    image: {
                      defaultSize: {
                        height: 'auto',
                        width: '100%',
                      },
                    },
                    colorPicker: {
                      colors: COLOR_PICKER_OPTIONS,
                      defaultColor: COLOR_PICKER_OPTIONS[0],
                    },
                  }}
                />
              </EditorContainer>
            </MarginContainer>
          </MarginContainer>
          <MarginContainer
            display="flex"
            justifyContent="flex-end"
            marginRight="1.5vw"
          >
            <SaveButtonContainer onClick={submitHandler}>
              <FontAwesomeIcon icon={faSave} />
              <span style={{ marginLeft: '5px' }}>Salvează</span>
            </SaveButtonContainer>
          </MarginContainer>
        </WhiteCard>

        <WhiteCard>
          <HeadingStage>Taskuri</HeadingStage>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            <Droppable droppableId="tasks">
              {(provided) => (
                <MarginContainer
                  marginLeft="3.77vw"
                  display="block"
                  width="90%"
                  style={{ minHeight: dragHeight }}
                >
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {sortItems(columns.tasks.items).map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item._id}
                            onClick={() => {
                              setSelectedTask(item);
                              setDragHeight('33vh');
                            }}
                          >
                            <MrlTask
                              title={item.name}
                              exempleLink={item.link}
                              buttonDescription={item.buttonName}
                              descriptionHtml={item.descriptionHtml}
                              description={item.description}
                              saveChanges={saveChangesOnTask}
                              setSelectedTask={setSelectedTask}
                              selectedTask={selectedTask}
                              isOpen={
                                selectedTask && selectedTask.name === item.name
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                </MarginContainer>
              )}
            </Droppable>
          </DragDropContext>
        </WhiteCard>
      </MrlGuideContainer>
    </>
  );
};

MrlStage.propTypes = {
  match: RouterPropTypes.match,
};

const mapDispatchToProps = {
  loadMrl,
  getMrlById,
  editMrl,
};

const mapStateToProps = (state) => ({
  mrl: state.mrl.currentMrl,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MrlStage)
);
