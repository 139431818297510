import React, { useEffect, useState } from 'react';
import { H1 } from '../../common/components/styled/ReusableTextComponents';
import { Box, Grid } from '@mui/material';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import {
  DeleteButtonComponent,
  EditButtonComponent,
} from '../../opportunityAnalysis/components/Buttons';

import { formatDate } from '../../common/functions/dateHelpers';
import { useApi } from '../../hooks/useApi';
import {
  deleteTaskById,
  getAllTasksByTeam,
  updateTaskById,
} from '../../requests/tasks';
import { useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import AddActivityTaskModal from '../../hypotheses/components/pages/AddActivityTaskModal';
import { getMyIdeeaMembers } from '../../requests/myIdeea';
import { TASK_STATUS_OPTIONS } from '../../hypotheses/constants/activities';

const TasksComponent = () => {
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: teamMembers }, runGetTeamMembers] = useApi(
    getMyIdeeaMembers,
    []
  );

  const [{ result: tasks }, fetchAllTasksByTeam] = useApi(
    getAllTasksByTeam,
    []
  );

  const [, removeTask] = useApi(deleteTaskById);

  const [, doUpdateTask] = useApi(updateTaskById);

  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [editFormValues, setEditFormValues] = useState(null);
  const [isAddTasksModalOpen, setIsAddTasksModalOpen] = useState(false);

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const extractName = (member) => {
    const { firstname = '', lastName = '' } = member;
    return `${firstname} ${lastName}`;
  };

  const teamMembersOptions = teamMembers?.map((member) => ({
    value: member.userId,
    label: extractName(member),
  }));

  const handleToggleAddTasksModal = () => {
    setIsAddTasksModalOpen(!isAddTasksModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddTasksModal();
    setSelectedTaskId(null);
    setEditFormValues(null);
  };

  const handleGetTasks = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };

    await fetchAllTasksByTeam(payload);
  };

  const handleDeleteTask = async (id) => {
    const payload = {
      params: { taskId: id },
    };
    await removeTask(payload);
    await handleGetTasks();
  };

  const handleEditTask = (id) => {
    const { status, userResponsibleId, successCriteria, title } = tasks.find(
      (activity) => activity._id === id
    );
    setSelectedTaskId(id);
    setEditFormValues({
      state: status,
      userResponsibleId,
      successCriteria,
      title,
    });
    handleToggleAddTasksModal();
  };

  const handleUpdateTask = async (formValues) => {
    const payload = {
      params: { taskId: selectedTaskId },
      activityData: {
        ...formValues,
        status: formValues.state,
        completionDate: Date.now(),
      },
    };
    console.log('payload handleUpdateTask=', payload);
    await doUpdateTask(payload);
    await handleGetTasks();
    handleCloseModal();
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: 'Task', key: 'title' },
    { type: 'string', content: 'Cost', key: 'cost' },
    // { type: 'string', content: 'Responsabil', key: 'userResponsibleId' },
    { type: 'string', content: 'Stadiu', key: 'status' },
    { type: 'string', content: 'Finalizat', key: 'completionDate' },
    // { type: 'string', content: 'Cale', key: 'way' },
    ...(tasks.length
      ? [{ type: 'string', content: '', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    tasks.map(
      (
        {
          _id,
          title,
          // userResponsibleId,
          status,
          completionDate,
          cost,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        title,
        cost,
        // userResponsibleId,
        status: TASK_STATUS_OPTIONS.find((option) => option.value === status)
          .label,
        completionDate: formatDate(completionDate),
        action: (
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <DeleteButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteTask(_id);
              }}
            />
            <EditButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                handleEditTask(_id);
              }}
            />
          </Box>
        ),
      })
    );

  const handleSelectedTask = () => {};

  useEffect(() => {
    if (teamId) {
      handleGetTasks();
      updateTeamMembers();
    }
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: '30px' }}>
        <H1>Lista taskuri</H1>
      </Box>
      <PageContainer className="scrollbar">
        {tasks && !!tasks.length ? (
          <Grid
            container
            spacing={2}
            direction="column"
            sx={{ margin: '0 auto' }}
          >
            <GenericTableComponent
              rows={createRows()}
              headerCells={createTableHeaderCells()}
              handleSelectedRow={handleSelectedTask}
            />
          </Grid>
        ) : (
          <GenericEmptyTable
            textColor="#74A3FE"
            headers={createTableHeaderCells()}
            onAddClick={handleToggleAddTasksModal}
            detailMessage="Niciun task nu a fost adaugat..."
          />
        )}
        <AddActivityTaskModal
          handleClose={handleToggleAddTasksModal}
          handleUpdate={handleUpdateTask}
          isModalOpen={isAddTasksModalOpen}
          teamMembers={teamMembersOptions}
          formValues={editFormValues}
        />
      </PageContainer>
    </>
  );
};

export default TasksComponent;
