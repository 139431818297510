import styled from 'styled-components';

export const ChatMessagesListContainer = styled.div`
  height: calc(100% - 170px);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow-y: scroll;
  padding-right: 5px;
`;

export const UserMessageBubble = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.isBot ? '#EEEEEE' : props.theme.blueAccent)};
  color: ${(props) => (props.isBot ? '#656565' : props.theme.white)};
  border-radius: ${(props) =>
    props.isBot ? '25px 25px 25px 0' : '25px 0 25px 25px'};
  padding: 15px;
  margin-top: 15px;
`;

export const ChatTextFieldContainer = styled.div`
  height: 170px;

  textarea {
    ::-webkit-scrollbar {
      width: 5px;
      margin-right: 1px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #74a3fe;
      border-radius: 8px;
      background-clip: padding-box;
    }
  }
`;
