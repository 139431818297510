import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  H1,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  ThumbsUp,
} from '../../../common/assets/icons';
import { PageContainer } from '../styled/ContextAnalysis';
import AddConclusionModal from '../modals/AddConclusionModal';
import { currentTeamIdSelector } from '../../../common/ducks';
import { getTeam } from '../../../team/ducks';
import { editConclusion } from '../../ducks/contextAnalysis';

const ConclusionPage = () => {
  const dispatch = useDispatch();
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const teamConclusions = useSelector(
    (state) => state.team.team && state.team.team.analysisConclusion
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalStatus = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGetTeam = async () => {
    await dispatch(getTeam(teamId));
  };

  const handleSubmitConclusion = async (formValues) => {
    const payload = { analysisConclusion: formValues };
    await dispatch(editConclusion(payload, teamId));
    handleModalStatus();
    handleGetTeam();
  };

  const handleDeleteConclusion = async () => {
    const payload = {
      analysisConclusion: {
        description: '',
        title: '',
      },
    };

    await dispatch(editConclusion(payload, teamId));
    handleGetTeam();
  };

  useEffect(() => {
    if (teamId) {
      handleGetTeam();
    }
  }, []);

  const { description = '', title = '' } = teamConclusions || {};

  return (
    <PageContainer className="scrollbar">
      {teamConclusions && description.length && title.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ width: '50%', margin: '0 auto', padding: 0 }}
        >
          <Grid
            container
            justifyContent="flex-start"
            sx={{
              borderBottom: `2px solid ${
                teamConclusions.isTrendFavorable ? 'green' : 'red'
              }`,
              paddingBottom: '10px',
              gap: '10px',
            }}
          >
            <Button
              sx={{
                ...(!teamConclusions.isTrendFavorable && {
                  transform: 'rotate(180deg)',
                }),
                padding: 0,
                minWidth: '24px',
              }}
            >
              <ThumbsUp
                fill={teamConclusions.isTrendFavorable ? 'green' : 'red'}
              />
            </Button>
            <Box sx={{ fontSize: '20px' }}>{teamConclusions.title}</Box>
          </Grid>
          <Box sx={{ color: '#2A5AB7', paddingTop: '20px' }}>
            {teamConclusions.description}
          </Box>
          <Grid container sx={{ gap: '20px' }}>
            <PrimaryButton
              onClick={handleModalStatus}
              marginTop="20px"
              icon={EditIcon}
              inverted
              id="conclusionButton"
            >
              Editeaza
            </PrimaryButton>
            <SecondaryButton
              onClick={handleDeleteConclusion}
              marginTop="20px"
              icon={DeleteIcon}
              inverted
            >
              Sterge
            </SecondaryButton>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <H1>Concluzie analiză context</H1>
          <Box sx={{ marginTop: '10px' }}>
            <P faded>
              <i>Adaugă concluzia făcând click pe butonul „+” de mai jos.</i>
            </P>
            <PrimaryButton
              onClick={handleModalStatus}
              marginTop="20px"
              icon={AddIcon}
              id="conclusionButton"
            >
              Adauga o concluzie
            </PrimaryButton>
          </Box>
        </Box>
      )}
      <AddConclusionModal
        teamId={teamId}
        teamConclusions={teamConclusions}
        handleSubmitConclusion={handleSubmitConclusion}
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModalStatus}
      />
    </PageContainer>
  );
};

export default ConclusionPage;
