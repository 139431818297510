import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import { ReactComponent as ExampleIcon } from '../../common/assets/icons/ExampleIcon.svg';
import { StyledLink } from '../../common/components/styled';
import {
  ButtonText,
  ExempleButtonContainer,
  MarginContainer,
  TaskContainer,
  TransitionTaskContainer,
} from './styled/MrlStyled';
import RichTextContainer from '../../common/components/RichTextContainer';
import { P } from '../../common/components/styled/ReusableTextComponents';
import { CheckIcon } from '../../common/assets/icons';

function MrlTask({
  isCompleted,
  title,
  isOpen,
  onMrlTaskOpen,
  push,
  descriptionHtml,
  description,
  link,
  buttonName,
}) {
  return (
    <>
      <MarginContainer marginBottom="1vh">
        <TaskContainer onClick={() => onMrlTaskOpen(title)}>
          <Box sx={{ paddingRight: '10px' }}>
            <CheckIcon checked={isCompleted} />
          </Box>

          <span>{title}</span>
          <div style={{ flex: 1 }}>
            {!isOpen ? (
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ float: 'right' }}
              />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} style={{ float: 'right' }} />
            )}
          </div>
        </TaskContainer>

        <TransitionTaskContainer isOpen={isOpen}>
          {isOpen && (
            <>
              <RichTextContainer text={description}>
                <P>{description}</P>
              </RichTextContainer>
              {link && (
                <MarginContainer
                  display="flex"
                  justifyContent="space-between"
                  marginTop="1vh"
                >
                  <StyledLink
                    href={link}
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    <ExempleButtonContainer>
                      <ExampleIcon />{' '}
                      <ButtonText>{buttonName || 'Exemplu'}</ButtonText>
                    </ExempleButtonContainer>
                  </StyledLink>
                </MarginContainer>
              )}
            </>
          )}
        </TransitionTaskContainer>
      </MarginContainer>
    </>
  );
}

export default MrlTask;
