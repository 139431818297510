import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { AddButton, FilterButton } from './styled/ContextAnalysis';
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  FilterIcon,
  SortIcon,
} from '../../common/assets/icons';
import { P } from '../../common/components/styled/ReusableTextComponents';

export const FilterButtonComponent = ({
  handleClick,
  selectedFilters,
  withoutCount = false,
}) => (
  <FilterButton onClick={handleClick}>
    <FilterIcon fill="#74A3FE" />
    <P marginLeft="10px">
      Filtre {withoutCount ? '' : `(${selectedFilters.length})`}
    </P>
  </FilterButton>
);

export const ChartButtonComponent = ({ handleClick }) => (
  <FilterButton onClick={handleClick}>
    <P marginRight="40px">Competitor</P>{' '}
    <FontAwesomeIcon icon={faChevronDown} />
  </FilterButton>
);

export const AddButtonComponent = ({ onClick, id }) => (
  <AddButton id={id} onClick={onClick}>
    <AddIcon fill="#fff" />
  </AddButton>
);

export const DeleteButtonComponent = ({ onClick }) => (
  <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
    <DeleteIcon width="18" height="20" />
  </Box>
);

export const EditButtonComponent = ({ onClick }) => (
  <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
    <EditIcon width="18" height="20" />
  </Box>
);

export const SortButton = ({ onClick, color = '#74A3FE', margin = '0' }) => (
  <Box
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      border: `1px solid ${color}`,
      padding: '5px 8px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: { margin },
    }}
  >
    <SortIcon fill={color} />
  </Box>
);
