import styled, { css } from 'styled-components';
import { ReactComponent as ResourcesLinkIconSvg } from '../../assets/icons/Link.svg';
import { ReactComponent as ToggleIcon } from '../../assets/icons/Navigation_Chevron.svg';

export const ResourcesContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
`;

export const ResourcesHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const ResourcesHeaderText = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #131336;
`;

export const ResourceContent = styled.div`
  max-height: 230px;
  overflow-y: auto;
`;
export const ResourceBox = styled.a`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  padding: 0 10px;
  background: #f7f7f8;
  border-radius: 8px;
  height: 50px;

  &:last-of-type() {
    margin-bottom: 0;
  }
`;

export const ResourceTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ResourceLinkIcon = styled(ResourcesLinkIconSvg)`
  margin: 15px 0px;
  max-width: 20px;
  > path {
    fill: #000;
  }
`;

export const ResourcesHeaderToggleIcon = styled(ToggleIcon)`
  max-width: 16px;
  margin-top: 8px;
  cursor: pointer;

  ${(props) =>
    !props.isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;
