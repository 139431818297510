import { runGetApiRequest, runUpdateApiRequest } from '../api/Api';
import {
  GET_COMMENTS_CSV,
  PUT_MARK_COMMENT_AS_READ,
} from '../common/constants/apiRoutes';

export function getCommentsCsv({ payload }) {
  return runGetApiRequest({
    endpoint: GET_COMMENTS_CSV,
    params: payload.params,
  });
}

export function markCommentsAsRead({ payload }) {
  return runUpdateApiRequest({
    endpoint: PUT_MARK_COMMENT_AS_READ,
    payload,
  });
}
