import React, { useState } from 'react';
import {
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DefaultCell } from '../styled/ContextAnalysis';
import { CHART_COLORS } from '../../constants';

const EditableCell = ({
  initialValue,
  type = 'number',
  isHeader = false,
  cellData,
  updateCell,
  postNewCompetitor,
  handleEditCompetitor,
  handleAddCriteria,
  handleEditCriteria,
  handleDeleteCompetitor,
  handleDeleteCriteria,
  placeholder = 'Adauga',
  id,
  color,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleFocusOut = (e) => {
    const { competitorId, criteriaId } = cellData.coordinates;
    const { action } = cellData;

    if (action) {
      if (action === 'addCompetitor') {
        postNewCompetitor(e.target.value);
      }

      if (action === 'editCompetitorName') {
        if (!e.target.value) {
          handleDeleteCompetitor(competitorId);
          return;
        }

        handleEditCompetitor(e.target.value, competitorId);
      }

      if (action === 'addCriteria') {
        handleAddCriteria(e.target.value);
      }

      if (action === 'editCriteriaName') {
        if (!e.target.value) {
          handleDeleteCriteria(criteriaId);
          return;
        }

        handleEditCriteria && handleEditCriteria(e.target.value, criteriaId);
      }

      return;
    }

    if (criteriaId && competitorId) {
      if (+e.target.value === initialValue) {
        setValue(initialValue);
        return;
      }

      if (e.target.value === '') {
        setValue(initialValue);
        return;
      }

      updateCell({
        competitorId,
        criteriaId,
        value: e.target.value || initialValue,
        initialValue,
      });
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  const handleChangeNumber = (event) => {
    let inputValue = event.target.value;

    if (inputValue !== '') {
      inputValue = parseInt(inputValue, 10);

      setValue(inputValue);
    } else {
      setValue(0);
    }
  };

  const defaultValues = [0, 'Adauga un criteriu', 'Adauga un competitor'];
  const checkForDefaultValue = (value) => {
    if (defaultValues.includes(value)) {
      return '';
    }
    return value;
  };

  return (
    <DefaultCell isHeader={isHeader}>
      <OutlinedInput
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          '& input[type="number"]': {
            MozAppearance: 'textfield',
          },
          color: color || 'black',
        }}
        type={type}
        value={checkForDefaultValue(value)}
        onChange={type === 'number' ? handleChangeNumber : handleChange}
        disabled={cellData.disabled}
        onBlur={handleFocusOut}
        placeholder={placeholder}
        id={id}
      />
    </DefaultCell>
  );
};

export const CompetitionTable = ({
  rows = [],
  handleCellUpdate,
  postNewCompetitor,
  handleEditCompetitor,
  handleAddCriteria,
  handleEditCriteria,
  handleDeleteCompetitor,
  handleDeleteCriteria,
}) => {
  if (rows.length === 0) return <div />;

  return (
    <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {rows[0].map((header) => (
              <EditableCell
                id={header.id}
                key={header.id}
                initialValue={header.value}
                placeholder="Adauga un Criteriu"
                type="text"
                isHeader
                cellData={header}
                updateCell={handleCellUpdate}
                handleAddCriteria={handleAddCriteria}
                handleEditCriteria={handleEditCriteria}
                handleDeleteCriteria={handleDeleteCriteria}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(1).map((row, index) => (
            <TableRow key={index}>
              {row.map((rowElement, idx) =>
                idx === 0 ? (
                  <EditableCell
                    id={`cell-${idx}${index}`}
                    key={rowElement.id}
                    initialValue={rowElement.value}
                    type="text"
                    isHeader
                    cellData={rowElement}
                    updateCell={handleCellUpdate}
                    postNewCompetitor={postNewCompetitor}
                    handleEditCompetitor={handleEditCompetitor}
                    handleDeleteCompetitor={handleDeleteCompetitor}
                    placeholder="Adauga un competitor"
                    color={CHART_COLORS[index]}
                  />
                ) : (
                  <EditableCell
                    id={`${index}-${rowElement.id}`}
                    key={rowElement.id}
                    initialValue={rowElement.value}
                    cellData={rowElement}
                    updateCell={handleCellUpdate}
                    placeholder="Completeaza de la 1-5"
                  />
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
