import Box from '@mui/material/Box';
import { Collapse, Popper } from '@mui/material';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  LinkStyleButton,
  SubText,
} from '../../common/components/styled/ReusableTextComponents';
import {
  CommentIcon,
  ReplyIcon,
  ThreeDotsIcon,
} from '../../common/assets/icons';
import RichTextContainer from '../../common/components/RichTextContainer';
import { SpaceBetweenBox } from '../../common/components/styled/ReusablePositioningComponents';
import { CommentContainer, DotsIconContainer } from './styled/Comments';
import { theme } from '../../core/styles';
import { notifySuccess } from '../../core/ducks';
import { CommentHeader, PopperContainer } from './OneCommentPieces';
import { markCommentsAsRead } from '../../requests/notificationsAndComments';

const CommentAndRepliesList = ({
  commentsArray,
  handleEditComment,
  handleDeleteComment,
  handleReply,
  handleDeleteReply,
  handleEditReply,
  queryCommentId,
  fetchComments,
}) => {
  const [anchorEl] = React.useState(null);
  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'simple-popper' : undefined;
  const [expandedCommentIds, setExpandedCommentIds] = React.useState([
    queryCommentId,
  ]);
  const currentUserId = useSelector(
    (state) => state.userProfile.userProfile._id
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const hasUnreadComments = commentsArray.some((comment) => comment.unread);
    if (hasUnreadComments) {
      const payload = {
        comments: commentsArray.map((comment) => ({
          commentId: comment._id,
          markRepliesAsRead: false,
        })),
      };

      setTimeout(async () => {
        await markCommentsAsRead({ payload });
        fetchComments();
      }, 2000);
    }
  }, []);

  const [openedMenu, setOpenedMenu] = React.useState({
    type: null, // "comment" or "reply"
    id: null,
    anchorEl: null,
  });

  const handleCopyLink = (commentId) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('comment', commentId);
    url.search = params.toString();
    navigator.clipboard.writeText(url.toString());
    dispatch(notifySuccess('Link copiat in clipboard'));
  };

  const showOptionsMenu = (event, id, type) => {
    event.stopPropagation();

    if (openedMenu.id === id && openedMenu.type === type) {
      setOpenedMenu({ type: null, id: null, anchorEl: null });
    } else {
      setOpenedMenu({ type, id, anchorEl: event.currentTarget });
    }
  };

  const hideOptionsMenu = () => {
    setOpenedMenu({ id: null, anchorEl: null });
  };

  const toggleCommentDropdown = async (comment) => {
    if (expandedCommentIds.includes(comment._id)) {
      const newExpandedCommentIds = expandedCommentIds.filter(
        (id) => id !== comment._id
      );
      setExpandedCommentIds(newExpandedCommentIds);
    } else {
      if (comment?.unreadReplies) {
        const payload = {
          comments: [{ commentId: comment._id, markRepliesAsRead: true }],
        };
        await markCommentsAsRead({ payload });
        fetchComments();
      }

      setExpandedCommentIds([...expandedCommentIds, comment._id]);
    }
  };

  const openReplies = (comment) => {
    if (!expandedCommentIds.includes(comment._id)) {
      setExpandedCommentIds([...expandedCommentIds, comment._id]);
    }

    if (comment?.unreadReplies) {
      const payload = {
        comments: [{ commentId: comment._id, markRepliesAsRead: true }],
      };
      markCommentsAsRead({ payload });
      fetchComments();
    }
  };

  const checkIfItsMine = (userId) => currentUserId === userId;

  return (
    <>
      {!!commentsArray.length &&
        commentsArray.map((entry) => (
          <CommentContainer key={entry._id}>
            <SpaceBetweenBox marginBottom="10px">
              <CommentHeader
                unread={entry?.unread}
                user={entry.userProfileId}
                currentUserId={currentUserId}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {entry.replies.length > 0 && (
                  <Box
                    sx={{ padding: '5px', cursor: 'pointer' }}
                    onClick={() => toggleCommentDropdown(entry)}
                  >
                    {expandedCommentIds.includes(entry._id) ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </Box>
                )}

                <DotsIconContainer
                  onClick={(e) => showOptionsMenu(e, entry._id, 'comment')}
                >
                  <ThreeDotsIcon />
                </DotsIconContainer>
              </Box>
              <Popper
                id={openedMenu.type === 'comment' ? popperId : undefined}
                open={
                  openedMenu.id === entry._id && openedMenu.type === 'comment'
                }
                anchorEl={openedMenu.anchorEl}
                style={{ zIndex: 2000 }}
                placement="bottom-end"
              >
                <PopperContainer
                  handleCopyLink={handleCopyLink}
                  hideOptionsMenu={hideOptionsMenu}
                  checkIfItsMine={checkIfItsMine}
                  setOpenedMenu={setOpenedMenu}
                  item={entry}
                  handleEdit={handleEditComment}
                  handleDelete={handleDeleteComment}
                />
              </Popper>
            </SpaceBetweenBox>
            <RichTextContainer text={entry.title}>
              Loading text...
            </RichTextContainer>
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              <LinkStyleButton
                icon={ReplyIcon}
                iconColor={theme.accentLight}
                marginTop="5px"
                onClick={(e) => {
                  openReplies(entry);
                  handleReply(e, entry);
                }}
              >
                Raspunde
              </LinkStyleButton>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                }}
              >
                <CommentIcon />
                <SubText marginLeft="5px">{entry.replies.length}</SubText>
                {entry?.unreadReplies && (
                  <Box
                    sx={{
                      width: '5px',
                      height: '5px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      marginLeft: '5px',
                    }}
                  />
                )}
              </Box>
            </Box>
            <Collapse in={expandedCommentIds.includes(entry._id)}>
              <Box
                sx={{
                  paddingLeft: '15px',
                  borderLeft: '1px solid #B1CCFE',
                }}
              >
                {!!entry.replies.length &&
                  entry.replies.map((reply) => (
                    <Box
                      key={reply._id}
                      sx={{ marginBottom: '5px', marginTop: '10px' }}
                    >
                      <SpaceBetweenBox>
                        <CommentHeader
                          user={reply.userProfileId}
                          currentUserId={currentUserId}
                        />

                        <DotsIconContainer
                          onClick={(e) =>
                            showOptionsMenu(e, reply._id, 'reply')
                          }
                        >
                          <ThreeDotsIcon />
                        </DotsIconContainer>

                        <Popper
                          id={
                            openedMenu.type === 'reply' ? popperId : undefined
                          }
                          open={
                            openedMenu.id === reply._id &&
                            openedMenu.type === 'reply'
                          }
                          anchorEl={openedMenu.anchorEl}
                          style={{ zIndex: 2000 }}
                          placement="bottom-end"
                        >
                          <PopperContainer
                            handleCopyLink={handleCopyLink}
                            hideOptionsMenu={hideOptionsMenu}
                            checkIfItsMine={checkIfItsMine}
                            setOpenedMenu={setOpenedMenu}
                            item={reply}
                            entry={entry}
                            isReply
                            handleEdit={handleEditReply}
                            handleDelete={handleDeleteReply}
                          />
                        </Popper>
                      </SpaceBetweenBox>

                      <RichTextContainer text={reply.title}>
                        'Loading...'
                      </RichTextContainer>
                    </Box>
                  ))}
              </Box>
            </Collapse>
          </CommentContainer>
        ))}
    </>
  );
};

export default CommentAndRepliesList;
