import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../api/Api';

import {
  DELETE_CHAPTER_BY_ID,
  GET_ALL_CHAPTERS,
  GET_CHAPTER_BY_ID,
  POST_ADD_CHAPTER,
  PUT_EDIT_CHAPTER,
  PUT_ORDER_CHAPTERS,
} from '../common/constants/apiRoutes';

const getChaptersSerializer = (data) => {
  const chapterMap = {};

  data.forEach((item) => {
    if (item.chapterId) {
      // If it's a subchapter, add it to the corresponding chapter's subchapters array
      if (!chapterMap[item.chapterId]) {
        // If the chapter doesn't exist in the map yet, create a placeholder for it
        chapterMap[item.chapterId] = { subchapters: [] };
      }
      chapterMap[item.chapterId].subchapters.push(item);
    } else {
      // If it's a chapter and already has an entry in the map, retain its subchapters
      if (chapterMap[item._id]) {
        chapterMap[item._id] = {
          ...item,
          subchapters: chapterMap[item._id].subchapters,
        };
      } else {
        // If it's a new chapter, start with an empty subchapters array
        chapterMap[item._id] = { ...item, subchapters: [] };
      }
    }
  });

  // Convert the chapterMap to an array and sort by the 'order' property
  const sortedChapters = Object.values(chapterMap).sort(
    (a, b) => a.order - b.order
  );

  // Sort the subchapters within each chapter by their 'order' property
  sortedChapters.forEach((chapter) => {
    if (chapter.subchapters) {
      chapter.subchapters.sort((a, b) => a.order - b.order);
    }
  });

  return sortedChapters;
};

export function getAllChapters({ payload = {} }) {
  const defaultQueryParams = {
    filters: {
      ...payload,
    },
  };

  return runGetApiRequest({
    endpoint: GET_ALL_CHAPTERS,
    params: payload.params,
    queryParams: { ...defaultQueryParams, ...payload.queryParams },
    onFailMessage: 'Something went wrong while fetching chapters',
    serializer: getChaptersSerializer,
  });
}

export function getChapterById({ payload = {} }) {
  return runGetApiRequest({
    endpoint: GET_CHAPTER_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while fetching chapter',
  });
}

export function addChapter({ payload = {} }) {
  return runPostApiRequest({
    endpoint: POST_ADD_CHAPTER,
    payload,
    onFailMessage: 'Something went wrong while adding chapter',
  });
}

export function editChapter({ payload = {} }) {
  return runUpdateApiRequest({
    endpoint: PUT_EDIT_CHAPTER,
    params: payload.params,
    payload: payload.payload,
    onFailMessage: 'Something went wrong while editing chapter',
  });
}

export function orderChapters({ payload = [] }) {
  return runUpdateApiRequest({
    endpoint: PUT_ORDER_CHAPTERS,
    payload,
    onFailMessage: 'Something went wrong while ordering chapters',
  });
}

export function deleteChapterById({ payload = {} }) {
  return runDeleteApiRequest({
    endpoint: DELETE_CHAPTER_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while deleting chapter',
  });
}
