/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash-es';
import { Modal } from 'react-bootstrap';
import { AddDetailForm } from '../forms';
import { Loading, Title, DeleteButtonSmall } from '../styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { editHypothesisDetail } from '../../../hypothesisDetails/ducks';
import { loadHypothesis } from '../../../hypotheses/ducks';

const AddDetailModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    addDetail,
    isLoading,
    parentId,
    isEditMode,
    detail,
    editDetail,
    firstName,
    lastName,
    addDetailGlobal,
    isModalOpenGlobal,
    isLoadingGlobal,
    global,
    isHypothesis,
    isHypothesisTitle,
    isExperimentTitle,
    isExperiment,
    closeModal,
    loadHypothesis,
  } = props;

  const createdBy = `${firstName} ${lastName}`;
  const handleSubmit = async (values) => {
    const formValues = {
      ...values,
      detailCategory: get(values.detailCategory, 'value', undefined),
      isEarlyAdopter: get(values.isEarlyAdopter, 'value', undefined),
      contactType: get(values.contactType, 'value', undefined),
      canContactLater: get(values.canContactLater, 'value', undefined),
      engagementLevel: get(values.engagementLevel, 'value', undefined),
      buyingSignals: get(values.buyingSignals, 'value', undefined),
    };

    isEditMode
      ? await editDetail(formValues, detail._id)
      : await addDetail(formValues, createdBy, parentId);
    await loadHypothesis(parentId);
    setIsModalOpen(false);
  };

  const handleSubmitGlobal = async (values, parentId) => {
    const formValues = {
      ...values,
      detailCategory: get(values.detailCategory, 'value', undefined),
      isEarlyAdopter: get(values.isEarlyAdopter, 'value', undefined),
      contactType: get(values.contactType, 'value', undefined),
      canContactLater: get(values.canContactLater, 'value', undefined),
      engagementLevel: get(values.engagementLevel, 'value', undefined),
      buyingSignals: get(values.buyingSignals, 'value', undefined),
    };
    await addDetailGlobal(formValues, createdBy, parentId);
    closeModal();
  };

  const handleCloseModal = () => {
    global ? closeModal() : setIsModalOpen(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={global ? isModalOpenGlobal : isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {isEditMode
              ? 'Editează detaliul'
              : isHypothesisTitle
              ? 'Adaugă un detaliu'
              : isExperimentTitle && 'Adaugă un detaliu experimentului'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} black />
        </TitleButtonsDiv>
        <AddDetailForm
          onSubmit={handleSubmit}
          onSubmitGlobal={handleSubmitGlobal}
          isEditMode={isEditMode}
          detail={detail}
          isExperiment={isExperiment}
          isHypothesis={isHypothesis}
          global={global}
        />
      </Modal.Body>

      <Loading isLoading={global ? isLoadingGlobal : isLoading} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});
const mapDispatchToProps = {
  editDetail: editHypothesisDetail,
  loadHypothesis,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDetailModal)
);
