import { Grid } from '@mui/material';
import React from 'react';
import {
  ChartBackgroundColumn,
  ChartBackgroundColumnPaddedBox,
} from '../styled/ContextAnalysis';
import { limitCharacters } from '../../../common/functions/textFormatters';

export const ChartColumns = ({ criterias = [] }) =>
  criterias.map((entry, index) => (
    <Grid key={index} sx={{ height: '350px', padding: '0 10px' }} item xs>
      <ChartBackgroundColumn>
        <ChartBackgroundColumnPaddedBox>
          <h6>{entry && <b> {limitCharacters(entry, 35)}</b>}</h6>
        </ChartBackgroundColumnPaddedBox>
      </ChartBackgroundColumn>
    </Grid>
  ));
