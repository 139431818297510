import React from 'react';
import ModalComponent from '../../../common/components/ModalComponent';
import HypothesisForm from '../../../opportunityAnalysis/components/forms/HypothesisForm';
import { BMC_OPTIONS } from '../../../canvas/constants';

const AddHypothesisModal = ({
  handleClose,
  isModalOpen,
  handleSubmitHypothesis,
  hypothesisFormValues,
  segmentOptions,
  isEdit,
}) => {
  const currentOption = BMC_OPTIONS.find(
    (option) => option.value === hypothesisFormValues.columnBMC
  );
  const { label: columnBMCLabel } = currentOption || {};

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleClose}
      title={isEdit ? 'Editeaza Ipoteza' : 'Adauga o ipoteza noua'}
      subtitle={columnBMCLabel}
      size="md"
    >
      <HypothesisForm
        isEdit={isEdit}
        handleSubmit={handleSubmitHypothesis}
        segmentOptions={segmentOptions}
        formValues={hypothesisFormValues}
      />
    </ModalComponent>
  );
};

export default AddHypothesisModal;
