import React from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { theme } from '../styles';
import CustomLabel from './CustomLabel';

const StyledDiv = styled.div`
  border: 1px solid rgb(116, 163, 254);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid ${(props) => (props.isInvalid ? '#fa5c7c' : '#74A3FE')};
  border-radius: 4px;
`;

const Img = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-left: 2px;
`;

const InlineDropdownStyles = {
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    fontSize: '14px',
    fontFamily: ' Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: state.isFocused ? theme.text : theme.textInactive,
  }),
};

const InlineDropdown = ({
  options,
  value,
  onChange,
  label,
  styles = InlineDropdownStyles,
  tooltipDescription,
  fieldRef,
  buttonBorder,
  width,
  isInvalid,
  classes,
  showIcon = false,
  marginBottom,
}) => {
  const handleChange = (selectedOption) => {
    if (onChange) onChange(selectedOption);
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%' }} ref={fieldRef}>
        <StyledDiv isInvalid={isInvalid}>
          <CustomLabel
            label={label}
            tooltipDescription={tooltipDescription}
            classes={classes}
            marginBottom={marginBottom}
          />
          <div className="inline-dropdown-menu">
            {options.map((x) => (
              <Button
                variant="outline-secondary"
                key={`button_${x.value}`}
                className={`${
                  value && value.value === x.value ? 'selected' : ''
                } ${buttonBorder ? 'button-border' : ''}`}
                onClick={() => handleChange(x)}
                style={{
                  textTransform: 'capitalize',
                  width: `${width || 'auto'}`,
                  margin: '5px 10px',
                }}
              >
                {!!x.icon && showIcon && <Img src={x.icon} alt={x.label} />}
                {x.label}
              </Button>
            ))}
          </div>
        </StyledDiv>
      </div>
    </>
  );
};
export default InlineDropdown;
