import React from 'react';
import {
  Box,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { AddIcon, ArrowDown } from '../assets/icons';
import { theme } from '../../core/styles';
import { P, PrimaryButton } from './styled/ReusableTextComponents';

const headerCellStyle = {
  maxWidth: '100px',
  textAlign: 'center',
  color: theme.blueAccent,
  fontWeight: 'bold',
  padding: '0 0 10px 0',
};

const centeredDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '5px',
  cursor: 'pointer',
};
const GenericEmptyTable = ({
  headers,
  onAddClick,
  addButtonText,
  detailMessage,
  textColor,
}) => (
  <Box>
    <Table>
      <TableHead>
        <TableRow sx={{ '& > th': { borderBottom: '1px solid #B1CCFE' } }}>
          {headers.map((cell, index) => (
            <TableCell key={index} style={headerCellStyle}>
              <Box sx={centeredDivStyle}>
                <span>{cell.content}</span>
                {!cell.hideSortIcon && <ArrowDown />}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
    <Box sx={{ marginTop: '40px' }}>
      <P faded>
        <i style={{ color: textColor || '#74A3FE', fontStyle: 'italic' }}>
          {detailMessage}
        </i>
      </P>
    </Box>
    {addButtonText && (
      <PrimaryButton onClick={onAddClick} marginTop="20px" icon={AddIcon}>
        {addButtonText}
      </PrimaryButton>
    )}
  </Box>
);

export default GenericEmptyTable;
