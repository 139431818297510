import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import draftToHtmlPuri from 'draftjs-to-html';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  EditorContainer,
  SaveButtonContainer,
  TaskContainer,
  StageTitle,
} from './styled/MrlStyled';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { MarginContainer } from '../../mrl/components/styled/MrlStyled';
import { FONT_FAMILY_OPTIONS } from '../../common/constants';
import { COLOR_PICKER_OPTIONS } from '../constants/ResourceSectionOptions';
import { emptyContentState, getBlockStyle } from '../constants/MrlEdittor';

function MrlTask({
  title,
  isOpen,
  saveChanges,
  description,
  setSelectedTask,
  selectedTask,
  exempleLink,
  buttonDescription,
}) {
  const [editorState, setEditorState] = useState();
  const [link, setLink] = useState(exempleLink);
  const [buttonName, setButtonName] = useState(buttonDescription);
  const [taskName, setTaskName] = useState(title);

  useEffect(() => {
    const rawContentState =
      description !== '' ? JSON.parse(description) : emptyContentState;
    const contentState = convertFromRaw(rawContentState);
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  let htmlPuri;
  if (editorState) {
    htmlPuri = draftToHtmlPuri(
      convertToRaw(editorState._immutable.currentContent)
    );
  }

  return (
    <MarginContainer marginBottom="1vh">
      <TaskContainer
        onClick={(event) => {
          if (event.detail === 1) {
            if (!selectedTask) {
              setSelectedTask(title);
              return;
            }
            setSelectedTask(undefined);
            event.stopPropagation();
          }
        }}
      >
        <div>{title}</div>
        <MarginContainer marginRight="1vw">
          {!isOpen ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </MarginContainer>
      </TaskContainer>

      {isOpen && (
        <div>
          <StageTitle
            placeholder="Nume task"
            value={taskName}
            onChange={(e) => {
              setTaskName(e.target.value);
            }}
          />
          <EditorContainer className="scrollbar rich-text-editor">
            <Editor
              editorState={editorState}
              wrapperClassName="rich-editor-wrapper"
              editorClassName="scrollbar"
              onEditorStateChange={setEditorState}
              blockStyleFn={getBlockStyle}
              editorStyle={{ paddingLeft: '1vh', paddingRight: '1vh' }}
              toolbarStyle={{ maxWidth: '40vw', zIndex: 9999 }}
              toolbar={{
                fontFamily: {
                  options: FONT_FAMILY_OPTIONS,
                },
                colorPicker: {
                  colors: COLOR_PICKER_OPTIONS,
                  defaultColor: COLOR_PICKER_OPTIONS[0],
                },
              }}
            />
          </EditorContainer>

          <StageTitle
            placeholder="Link buton"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />

          <StageTitle
            placeholder="Nume buton"
            value={buttonName}
            onChange={(e) => {
              setButtonName(e.target.value);
            }}
          />

          <MarginContainer
            display="flex"
            justifyContent="flex-end"
            marginTop="1vh"
          >
            <SaveButtonContainer
              onClick={() => {
                saveChanges({
                  title,
                  htmlPuri,
                  editorState:
                    editorState._immutable.currentContent.getPlainText(
                      '\u0001'
                    ),
                  correctEditorState: JSON.stringify(
                    convertToRaw(editorState.getCurrentContent())
                  ),
                  link,
                  buttonName,
                  taskName,
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} />
              <span style={{ marginLeft: '5px' }}>Salvează</span>
            </SaveButtonContainer>
          </MarginContainer>
        </div>
      )}
    </MarginContainer>
  );
}

export default MrlTask;
