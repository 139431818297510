import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadCohorts } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import CohortsPage from './CohortsPage';

const CohortsPageResolver = (props) => {
  const { loadCohorts } = props;
  const loadDependencies = async () => {
    await loadCohorts();
  };

  return (
    <>
      <DocumentTitle>Cohorte</DocumentTitle>
      <Resolver
        successComponent={CohortsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

CohortsPageResolver.propTypes = {
  loadCohorts: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadCohorts };

export default withRouter(
  connect(undefined, mapDispatchToProps)(CohortsPageResolver)
);
