import { P } from '../../common/components/styled/ReusableTextComponents';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  Typography,
} from '@mui/material';
import { AccordionExpandIcon } from '../../common/assets/icons';
import React from 'react';

const ChaptersSideGuide = ({
  chaptersListResult,
  expandedAccordions,
  handleAccordionChange,
  subchapterId,
  isAdminPage,
  handleSubchapterClick,
}) => (
  <>
    <P fontSize="18px">Cuprins</P>
    <Box
      className="scrollbar"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '5px',
        overflowY: 'scroll',
        height: '100%',
        paddingRight: '7px',
      }}
    >
      {!!chaptersListResult.length &&
        chaptersListResult.map((chapter, index) => (
          <Box key={chapter._id}>
            <Accordion
              expanded={!!expandedAccordions[`panel${index}`]}
              onChange={handleAccordionChange(`panel${index}`)}
              sx={{
                borderRadius: '6px',
                boxShadow: 'none',
                border: 'none',
                borderBottom: '1px solid #E0E0E0',
                padding: '5px 0',
              }}
            >
              <AccordionSummary
                expandIcon={<AccordionExpandIcon width="12px" />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{
                  minHeight: '30px',
                  padding: 0,
                  margin: 0,
                  '&.Mui-expanded': {
                    minHeight: '30px',
                    margin: 0,
                    '& .MuiAccordionSummary-content': {
                      margin: '0px',
                    },
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '0px',
                  },
                }}
              >
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <P fontSize="16px">{chapter.title}</P>
                  <Divider orientation="vertical" flexItem />
                  <Box>
                    <P fontSize="16px">0/{chapter.subchapters.length}</P>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '5px 0' }}>
                <Typography component={'span'}>
                  {chapter.subchapters.map((subchapter) => (
                    <Box
                      key={subchapter._id}
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        backgroundColor:
                          subchapterId === subchapter._id ? '#BBBBBB' : '',
                      }}
                    >
                      {!isAdminPage && (
                        <Checkbox
                          sx={{
                            padding: '2px',
                            '& .MuiSvgIcon-root': {
                              fontSize: '20px',
                            },
                          }}
                        />
                      )}
                      <Box
                        sx={{ cursor: 'pointer', fontSize: '14px' }}
                        onClick={() =>
                          handleSubchapterClick(subchapter._id, chapter._id)
                        }
                      >
                        {subchapter.title}
                      </Box>
                    </Box>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </Box>
  </>
);

export default ChaptersSideGuide;
