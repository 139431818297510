import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { HtmlContainer } from '../../mrl/components/styled/MrlStyled';

const RichTextContainer = ({ text, children }) => {
  const [needsToBeParsed, setNeedsToBeParsed] = useState(false);
  const [textToRender, setTextToRender] = useState(text);

  useEffect(() => {
    try {
      const rawContentFromDatabase = JSON.parse(text);
      const html = draftToHtml(rawContentFromDatabase);
      setTextToRender(html);
      setNeedsToBeParsed(true);
    } catch (err) {
      setTextToRender(text);
      setNeedsToBeParsed(false);
    }
  }, [text]);

  return needsToBeParsed ? (
    <HtmlContainer>{parse(textToRender)}</HtmlContainer>
  ) : (
    children
  );
};

export default RichTextContainer;
