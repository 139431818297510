import React from 'react';
import { Formik, useField } from 'formik';
import { Box, Button, Grid } from '@mui/material';
import * as Yup from 'yup';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined, ThumbsUp } from '../../../common/assets/icons';
import { RenderTextField } from './TrendsForm';

export const CustomButton = ({ active, favorable, ...props }) => {
  const defaultStyle = {
    borderRadius: '4px',
    background: active ? 'rgba(116, 163, 254, 0.2)' : 'transparent',
    width: '42px',
    height: '42px',
    border: '1px solid #74A3FE',
    margin: '0 5px',
    ...(!favorable && { transform: 'rotate(180deg)' }),
  };

  return (
    <Button style={defaultStyle} {...props}>
      <ThumbsUp fill={favorable ? 'green' : 'red'} />
    </Button>
  );
};

export const RenderRadioGroup = ({ id, label, md = 12 }) => {
  const [field, meta] = useField(id);
  const { value, onChange } = field;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Grid item xs={12} md={md}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #74A3FE',
          color: '#74A3FE',
          borderRadius: '4px',
          padding: '18.5px 14px',
        }}
      >
        <div>{label}</div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomButton
            active={value === false}
            onClick={() => handleChange({ target: { name: id, value: false } })}
          />
          <CustomButton
            active={value === true}
            onClick={() => handleChange({ target: { name: id, value: true } })}
            favorable
          />
        </Box>
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </Box>
    </Grid>
  );
};
const ConclusionForm = ({ teamConclusions, handleSubmitConclusion }) => {
  const schema = Yup.object().shape({
    title: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    isTrendFavorable: Yup.boolean().required('Câmp obligatoriu'),
  });

  const initialValues = Object.keys(teamConclusions).length
    ? {
        title: teamConclusions.title,
        description: teamConclusions.description,
        isTrendFavorable: teamConclusions.isTrendFavorable,
      }
    : {
        title: '',
        description: '',
        isTrendFavorable: true,
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitConclusion}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderTextField id="title" label="Titlu" />
          <RenderTextField
            id="description"
            label="Descriere"
            multiline
            rows={4}
          />
          <RenderRadioGroup
            id="isTrendFavorable"
            label="Consideri că trendul este favorabil?"
          />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default ConclusionForm;
