import Cookie from 'js-cookie';
import { get } from 'lodash-es';

const COOKIE_KEY = 'session';

export const createSession = (authToken, user) => {
  Cookie.set(COOKIE_KEY, { authToken, user });
};

export const getSessionValue = (key, defaultValue) =>
  get(Cookie.getJSON(COOKIE_KEY), key, defaultValue);

export const destroySession = () => {
  Cookie.remove(COOKIE_KEY);
};
