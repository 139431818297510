import update from 'immutability-helper';
import { get } from 'lodash-es';
import { notifyError } from '../../core/ducks';
import { login } from './login';
import { resetPassword as doResetPassword } from '../services/account';

// Actions
const START_RESET_PASSWORD = 'account/resetPassword/START_RESET_PASSWORD';
const COMPLETE_RESET_PASSWORD = 'account/resetPassword/COMPLETE_RESET_PASSWORD';
const RESET = 'account/resetPassword/RESET';

// Initial state
const initialState = {
  isResettingPassword: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_RESET_PASSWORD:
      return update(state, {
        $merge: { isResettingPassword: true },
      });

    case COMPLETE_RESET_PASSWORD:
      return update(state, {
        $merge: { isResettingPassword: false },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startResetPassword = () => ({
  type: START_RESET_PASSWORD,
});

const completeResetPassword = () => ({
  type: COMPLETE_RESET_PASSWORD,
});

export const resetPassword = (token, email, password) => (dispatch) => {
  dispatch(startResetPassword());
  return doResetPassword(token, email, password)
    .then(() => dispatch(login(email, password)))
    .catch((err) => {
      const errors = get(err, 'response.data.errors', {});
      const keys = Object.keys(errors);

      if (keys) {
        keys.forEach((key) => {
          dispatch(
            notifyError({ message: `${errors[key].param}: ${errors[key].msg}` })
          );
        });
      } else {
        dispatch(
          notifyError({
            message: 'S-a produs o eroare. Va rugam incercati din nou',
          })
        );
      }
    })
    .finally(() => dispatch(completeResetPassword()));
};

export const resetResetPassword = () => ({
  type: RESET,
});
