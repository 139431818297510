import styled, { css } from 'styled-components';

export const BusinessModelCanvasContainer = styled.div`
  width: 100%;
`;

export const BusinessModelCanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
  height: ${({ height }) => height && height};
`;

export const BusinessModelCanvasColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  border: ${({ container }) => !container && '1px solid #74A3FE'};
  padding-right: 15px;
  ${(props) =>
    !!props.singleOnRow &&
    css`
      background: #ffffff;
      border-radius: 10px;
    `}

  ${(props) =>
    !!props.secondRow &&
    css`
      margin-right: 15px;
    `}
  
  max-height: ${(props) => (props.firstRow ? '57.6vh' : '30vh')};
  height: ${(props) => (props.firstRow ? '57.6vh' : '30vh')};
  height: ${(props) => props.lastRow && '23vh'};
  max-width: ${(props) => (props.firstRow ? '20%' : '50%')};
  ${(props) =>
    !!props.empathy &&
    css`
      width: 33%;
      height: 540px;
    `}
  ${(props) =>
    !!props.noPadding &&
    css`
      padding: 0;
    `}
  ${(props) =>
    !!props.multipleChildren &&
    css`
      max-width: 100%;
      display: flex;
      flex-direction: column;
      &:last-of-type {
        padding-top: 15px;
      }
      &:first-of-type {
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
      background: #ffffff;
      max-height: 28vh;
      border-radius: 10px;
      padding: 12px;
      padding-top: 0;
    `};
`;

export const BMCColumnTitle = styled.h3`
  font-size: 20px;
  padding-right: 20px;
  position: relative;
  color: #0acf97;
  font-weight: 400;
`;

export const BMCColumnTitleIcon = styled.div`
  position: absolute;
  right: 0px;
  top: 2px;
  cursor: pointer;
  > svg > path {
    fill: #6c757d;
  }
  :hover {
    > svg > path {
      fill: #0acf97;
    }
  }
`;

export const NoResultsFound = styled.div`
  font-size: 14px;
  padding-right: 20px;
  font-style: italic;
`;

export const HypothesisCardsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
  margin-right: -5px;
`;

export const BmcTimelapseFilterContainer = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
  border: 1px solid #74a3fe;
  padding: 5px;
  border-radius: 5px;
`;

export const BmcFilterContainer = styled.div`
  padding: 2px 20px;
  border-radius: 5px;
  height: 30px;
`;

export const BmcTimelapseButton = styled.div`
  border: 1px solid #74a3fe;
  padding: 3px 5px;
  margin-right: 10px;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BmcPlayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffd3c6;
  padding: 3px 5px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
`;

export const BmcStyledFiltersDropdown = styled.div`
  width: 230px;
  height: 400px;
  background-color: white;
  border-radius: 5px;
  padding: 20px 10px;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  margin-top: 10px;
  z-index: 999;
`;
