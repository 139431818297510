import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ReactComponent as FoundersIcon } from '../../common/assets/icons/Founders.svg';
import { ReactComponent as MentorIcon } from '../../common/assets/icons/Mentor.svg';
import { ReactComponent as MyIdeeasIcon } from '../../common/assets/icons/Login_Idei.svg';
import { ReactComponent as ExperimentsIcon } from '../../common/assets/icons/Navigation_Experiment-1.svg';
import { ReactComponent as HypothesisIcon } from '../../common/assets/icons/Login_Hypothesis.svg';
import { WhiteCard, LoadingContainer } from '../../common/components/styled';

import { loadPlatformInformations } from '../ducks/platformInformations';

import {
  CounterValue,
  IconContainer,
  PlatformInfoContainer,
  PlatformInfoElement,
} from '../../account/components/styled';

function PlatformInformationsCounter({
  loadPlatformInformations,
  platformInfos,
}) {
  useEffect(() => {
    loadPlatformInformations();
  }, [loadPlatformInformations]);

  return (
    <>
      {platformInfos ? (
        <WhiteCard
          padding="0px 35px 5px 35px"
          marginRight="5px"
          borderRadius="10px"
          width="560px"
          marginBottom="10px"
          noOverflow
        >
          <PlatformInfoContainer>
            <PlatformInfoElement>
              <CounterValue>{platformInfos.numberOfFounders}</CounterValue>
              <IconContainer>
                <FoundersIcon style={{ height: '50px', width: '50px' }} />
              </IconContainer>
            </PlatformInfoElement>

            <PlatformInfoElement>
              <CounterValue>{platformInfos.numberOfMentors}</CounterValue>
              <IconContainer>
                <MentorIcon
                  style={{
                    height: '50px',
                    width: '50px',
                  }}
                />
              </IconContainer>
            </PlatformInfoElement>

            <PlatformInfoElement>
              <CounterValue>{platformInfos.numberOfTeams}</CounterValue>
              <IconContainer>
                <MyIdeeasIcon
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                />
              </IconContainer>
            </PlatformInfoElement>

            <PlatformInfoElement>
              <CounterValue>{platformInfos.numberOfHypotheses}</CounterValue>
              <IconContainer>
                <HypothesisIcon
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                />
              </IconContainer>
            </PlatformInfoElement>

            <PlatformInfoElement>
              <CounterValue>{platformInfos.numberOfExperiments}</CounterValue>
              <IconContainer>
                <ExperimentsIcon
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                />
              </IconContainer>
            </PlatformInfoElement>
          </PlatformInfoContainer>
        </WhiteCard>
      ) : (
        <WhiteCard
          padding="0px 35px 5px 35px"
          marginRight="5px"
          borderRadius="10px"
          marginBottom="10px"
          noOverflow
        >
          <PlatformInfoContainer>
            <div style={{ height: '110px', width: '560px' }}>
              <LoadingContainer isLoading />
            </div>
          </PlatformInfoContainer>
        </WhiteCard>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  platformInfos: state.platformInformations.plaftormInformations,
  isLoading: state.platformInformations.isLoading,
});

const mapDispatchToProps = {
  loadPlatformInformations,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlatformInformationsCounter)
);
