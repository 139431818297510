import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from '@mui/material';
import React from 'react';

const CustomCheckboxGroup = ({
  singleSelect = false,
  label,
  id,
  options,
  md = 12,
  hideBorder,
  checkboxStyles,
}) => {
  const [field, meta, helpers] = useField(id);
  const { value } = field; // Initialize to an empty array if undefined
  const { setValue } = helpers;

  const handleChange = (optionValue) => {
    if (singleSelect) {
      setValue([optionValue]);
    } else {
      const newValue = value.includes(optionValue)
        ? value.filter((val) => val !== optionValue)
        : [...value, optionValue];
      setValue(newValue);
    }
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        border: hideBorder ? 'none' : '1px solid #74A3FE',
        borderRadius: '4px',
        color: '#74A3FE',
        padding: '10px',
        marginBottom: '15px',
      }}
    >
      <FormControl
        component="fieldset"
        variant="outlined"
        fullWidth
        error={meta.touched && !!meta.error}
      >
        <FormLabel component="legend" sx={{ color: '#74A3FE' }}>
          {label}
        </FormLabel>
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={value?.includes(option.value)}
                  onChange={() => handleChange(option.value)}
                  name={option.label}
                  sx={{
                    color: '#74A3FE',
                    padding: '0 5px 0 0',
                    ...checkboxStyles,
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default CustomCheckboxGroup;
