import React, { useEffect, useState, useCallback } from 'react';
import { get, map, size, find, isArray, filter } from 'lodash-es';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box } from '@mui/material';
import {
  getBMCData,
  loadHypotheses,
  editHypothesis,
  editHypothesesOrder,
  addHypothesis,
} from '../../hypotheses/ducks';
import {
  openModal,
  currentTeamIdSelector,
  setCurrentUserTeam,
} from '../../common/ducks';
import { isMentorSelector, isAcceleratorSelector } from '../../account/ducks';

import { TitleButtonsDiv } from '../../mentors/components/styled';
import { TeamFilterForm } from '../../common/components/forms';
import {
  deleteOpportunityAnalysisComment,
  loadOpportunityAnalysis,
  editOpportunityAnalysisComment,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
} from '../../opportunityAnalysis/ducks';
import HypothesisCard from './HypothesisCard';

import {
  BusinessModelCanvasContainer,
  BusinessModelCanvasRow,
  BusinessModelCanvasColumn,
  NoResultsFound,
  HypothesisCardsContainer,
} from './styled';
import { Heading2 } from '../../common/components/styled';
import { userRoles } from '../../account/constants';
import { MarginContainer } from '../../mrl/components/styled/MrlStyled';
import { BmcColumnHeader } from '../../common/components/styled/Headings';

import { BmcAddButton } from './Canvas';
import { H1, P } from '../../common/components/styled/ReusableTextComponents';
import BmcFilters from './BmcFilters';
import { BmcSegmentDrawer } from './BmcSegmentDrawer';
import AddHypothesisModal from '../../hypotheses/components/modals/AddHypothesisModal';
import { createTargetIndicators } from '../../targetIndicators/ducks/targetIndicators';
import GenericComments from '../../comentsAndNotifications/components/GenericComments';
import { commentTypeBmc } from '../../comentsAndNotifications/constants';
import { getAllComments } from '../../comentsAndNotifications/ducks';
import { getNewHypothesisColor } from '../utils';

const BusinessModelCanvasPage = (props) => {
  const {
    userTeams,
    canvasData,
    openModal,
    isMentor,
    teamId,
    loadHypotheses,
    loadOpportunityAnalysis,
    isAccelerator,
    editHypothesis,
    editHypothesesOrder,
    role,
    setCurrentUserTeam,
    userId,
    hypotheses,
    userProfile,
    addHypothesis,
    createTargetIndicators,
  } = props;
  const SLIDESHOW_DELAY = 1000; // 10s

  const [columns, setColumns] = useState(undefined);
  const isDragAndDropActive = !(role === userRoles.FOUNDER);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredList, setFilteredList] = useState(canvasData);
  const [allowClick, setAllowClick] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [isPaused, setIsPaused] = useState(true);
  const [maxWeekNumber, setMaxWeek] = useState(100);
  const [currentWeek, setCurrentWeek] = useState(maxWeekNumber);
  const [startDate, setStartDate] = useState(new Date());
  const [intervalObject, setIntervalObject] = useState(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [isHypothesisModalOpen, setIsHypothesisModalOpen] = useState(false);
  const [columnBMCValue, setColumnBMCValue] = useState('');

  console.log(selectedFilters);

  const currentUserId = useSelector(
    (state) => state.userProfile.userProfile.userId._id
  );
  const dispatch = useDispatch();

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: commentTypeBmc,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    fetchComments();
  }, [teamId]);

  const bmcComments =
    useSelector((state) => state.comments[commentTypeBmc]) || [];

  const handleOpenHypothesisModal = (columnBMCValue) => {
    setIsHypothesisModalOpen(true);
    setColumnBMCValue(columnBMCValue);
  };

  const handleCloseHypothesisModal = () => {
    setIsHypothesisModalOpen(false);
  };

  const segmentHypothesis = filter(hypotheses, {
    columnBMC: 'customerSegments',
  });
  const segmentOptions = map(segmentHypothesis, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
    color: `${el.color}`,
  }));

  const handleCreateTargetIndicators = async (hypothesisId, formValues) => {
    const targetIndicators = [1, 2, 3].reduce((acc, index) => {
      const type = formValues[`targetIndicatorType${index}`];
      const value = formValues[`targetIndicatorValue${index}`];
      if (type && value) {
        acc.push({ targetIndicatorType: type, value });
      }
      return acc;
    }, []);

    const payload = {
      targetIndicators,
      hypothesisId,
    };

    await createTargetIndicators(payload);
  };

  const onCreateHypothesis = async (values) => {
    const usedColors = [];
    hypotheses.forEach((existingHypothesis) => {
      if (existingHypothesis.columnBMC === 'customerSegments') {
        usedColors.push(existingHypothesis.color);
      }
    });

    const { firstName } = userProfile.userProfile;
    const { lastName } = userProfile.userProfile;
    const createdBy = `${firstName} ${lastName} `;
    const payload = {
      title: values.title,
      createdBy,
      teamId,
      description: values.description,
      columnBMC: values.columnBMC,
      importance: values.importance,
      segment: values.segment,
      hypothesisCategory: 'dezirabila',
      color: getNewHypothesisColor(usedColors),
    };

    const addedHypothesis = await addHypothesis(payload);

    if (addedHypothesis.status === 200) {
      const addedHypothesisId = addedHypothesis.data._id;
      await handleCreateTargetIndicators(addedHypothesisId, values);
      loadHypotheses(teamId);
    }
    setIsHypothesisModalOpen(false);
  };

  useEffect(() => {
    if (teamId) {
      loadHypotheses(teamId);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses]);

  useEffect(() => {
    if (currentWeek === maxWeekNumber && !isPaused && intervalObject) {
      setIsPaused(true);
      setAllowClick(true);
    }
  }, [currentWeek, intervalObject, isPaused, maxWeekNumber]);

  const getReducedWeek = useCallback(
    (inputWeek) => {
      let week = inputWeek;

      if (week > maxWeekNumber) {
        week %= maxWeekNumber;
      }

      if (week === 0) {
        return maxWeekNumber;
      }

      return week;
    },
    [maxWeekNumber]
  );

  const intervalFunction = useCallback(() => {
    setCurrentWeek((x) => getReducedWeek(x + 1)); // currentWeek state cannot be discovered in interval
  }, [isPaused, maxWeekNumber, setIsPaused, setCurrentWeek]);

  useEffect(() => {
    if (!isPaused && !intervalObject) {
      intervalFunction();
      setIntervalObject(setInterval(intervalFunction, SLIDESHOW_DELAY));
    }

    if (isPaused && intervalObject) {
      clearInterval(intervalObject);
      setIntervalObject(undefined);
    }
  }, [isPaused, intervalObject]);

  const getCurrentWeekForDate = useCallback(
    (currentDate) => {
      const dayOfWeek = (7 - startDate.getDay()) * 1000 * 60 * 60 * 24;
      return (
        +Math.ceil(
          (currentDate - startDate - dayOfWeek) / (1000 * 60 * 60 * 24 * 7)
        ) + 1
      );
    },
    [startDate]
  );

  const filterItemsByWeek = (items, week) =>
    items.filter(
      (item) => getCurrentWeekForDate(new Date(item.createdAt)) <= week
    );

  const getLastState = (states) => {
    const lastState = { ...states[states.length - 1] };
    delete lastState.createdAt;
    return lastState;
  };

  const filterAndUpdateStates = (items, week) =>
    items.map((item) => {
      if (item.states) {
        const states = filterItemsByWeek(item.states, week);
        if (states && states.length > 0) {
          return { ...item, ...getLastState(states) };
        }
      }
      return item;
    });

  const filterAndUpdateSubProperties = (items, week) => {
    const properties = ['experiments', 'resolutions', 'comments'];
    return items.map((item) => {
      for (const property of properties) {
        if (item[property]) {
          const filteredItems = filterItemsByWeek(item[property], week);
          item[property] = filterAndUpdateStates(filteredItems, week);
        }
      }
      return item;
    });
  };

  const groupItemsByColumnBMC = (items) => {
    const groupedItems = {};
    for (const item of items) {
      const { columnBMC } = item;
      if (!groupedItems[columnBMC]) {
        groupedItems[columnBMC] = [item];
      } else {
        groupedItems[columnBMC].push(item);
      }
    }
    return groupedItems;
  };

  const getFilteredDataForWeek = useCallback(
    (week) => {
      const newCanvasData = {};

      for (const key of Object.keys(filteredList)) {
        const filteredItems = filterItemsByWeek(filteredList[key], week);
        const updatedStatesItems = filterAndUpdateStates(filteredItems, week);
        const updatedSubPropertiesItems = filterAndUpdateSubProperties(
          updatedStatesItems,
          week
        );

        newCanvasData[key] = updatedSubPropertiesItems;
      }

      return groupItemsByColumnBMC([].concat(...Object.values(newCanvasData)));
    },
    [getCurrentWeekForDate, filteredList, selectedFilters]
  );

  useEffect(() => {
    setSelectedTeam(find(userTeams, (team) => !!team.selected));
    if (selectedTeam) {
      setStartDate(new Date(selectedTeam.createdAt));
    }
  }, [userTeams, selectedTeam]);

  useEffect(() => {
    const currentweekNumber = getCurrentWeekForDate(new Date());
    setMaxWeek(currentweekNumber);
    setCurrentWeek(currentweekNumber);
  }, [startDate]);

  const handleDeleteFilter = (filter) => {
    setSelectedFilters((prev) => prev.filter((el) => el !== filter));
  };

  const handleDropdownValueChange = useCallback(
    (e) => {
      if (selectedFilters.includes(e)) {
        handleDeleteFilter(e);
        return;
      }

      setSelectedFilters((previous) => [...previous, e]);
    },
    [selectedFilters, handleDeleteFilter]
  );

  const filterByImportance = (data) => {
    if (!selectedFilters.length) {
      return data;
    }
    const obj = data;

    for (const key of Object.keys(obj)) {
      const valueForKey = get(data, key);
      if (isArray(valueForKey)) {
        obj[key] = valueForKey.filter((el) => {
          let isIncluded = false;
          for (const currentFilter of selectedFilters) {
            if (currentFilter === el.importance) {
              isIncluded = true;
              break;
            }
          }
          return isIncluded;
        });
      }
    }

    return obj;
  };

  const handleOpenDrawer = (segmentName) => {
    if (columns[segmentName].items.length === 0) {
      return;
    }

    setSelectedSegment({
      column: segmentName,
      items: columns[segmentName].items,
    });
    setIsDrawerOpen(true);
  };

  const updateOrder = (list) => {
    const newList = [];

    list.map((item, index) => {
      const newItem = item;
      newItem.order = index;
      newItem.importance = item.importanceValue;
      newList.push(newItem);
      return newItem;
    });

    const newColumnList = {
      [list[0].columnBMC]: {
        items: newList,
      },
    };
    setColumns((prev) => ({ ...prev, ...newColumnList }));

    editHypothesesOrder(newList);
  };

  const updateHypothesis = (values, entityId, currentHypothesis) => {
    const newValues = {
      ...values,
      ...currentHypothesis,
    };
    newValues.columnBMC = values.columnBMC;
    newValues.hypothesisCategory = { value: newValues.hypothesisCategory };
    newValues.importance = { value: currentHypothesis.importanceValue };
    newValues.columnBMC = { value: newValues.columnBMC };
    newValues.order = values.order;
    newValues.segment = [{ value: currentHypothesis.segment[0] }];
    const editPromise = editHypothesis(newValues, entityId);

    return editPromise;
  };

  function compareFunction(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const orderHypothesis = (list) => {
    const newList = list.sort(compareFunction);
    return newList;
  };

  useEffect(() => {
    const filteredDataForWeek = getFilteredDataForWeek(currentWeek);
    setColumns({
      keyPartners: {
        items: filteredDataForWeek.keyPartners || [],
      },
      channels: {
        items: filteredDataForWeek.channels || [],
      },
      costStructure: {
        items: filteredDataForWeek.costStructure || [],
      },
      customerRelationships: {
        items: filteredDataForWeek.customerRelationships || [],
      },
      customerSegments: {
        items: filteredDataForWeek.customerSegments || [],
      },
      keyActivities: {
        items: filteredDataForWeek.keyActivities || [],
      },
      keyResources: {
        items: filteredDataForWeek.keyResources || [],
      },
      revenueStreams: {
        items: filteredDataForWeek.revenueStreams || [],
      },
      valuePropositions: {
        items: filteredDataForWeek.valuePropositions || [],
      },
    });
  }, [
    teamId,
    selectedFilters,
    filteredList,
    getFilteredDataForWeek,
    currentWeek,
  ]);

  useEffect(() => {
    setFilteredList(filterByImportance({ ...canvasData }));
  }, [selectedFilters, canvasData]);

  useEffect(() => {
    if (teamId) {
      Promise.all([loadHypotheses(teamId), loadOpportunityAnalysis(teamId)]);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses, loadOpportunityAnalysis]);

  const getColor = (segmentId) =>
    map(segmentId, (segment) =>
      get(find(get(canvasData, 'customerSegments'), { _id: segment }), 'color')
    );
  // const openHypothesisModal = (details) => {
  //   openModal('hypothesis', details);
  // };

  const getNewWeek = useCallback(
    (decrease = false) => getReducedWeek(currentWeek + (decrease ? -1 : 1)),
    [getReducedWeek, currentWeek]
  );

  const setAllowedProperties = (weekNumber) => {
    setCurrentWeek(weekNumber);
    if (weekNumber === maxWeekNumber) {
      setAllowClick(true);
    } else {
      setAllowClick(false);
    }
  };

  const getTeamOptions = useCallback(
    () =>
      map(userTeams, (team) => ({
        label: team.name,
        value: team._id,
      })),
    [userTeams]
  );

  const getTeamValue = useCallback(() => {
    const team = find(userTeams, (team) => !!team.selected);
    return {
      label: get(team, 'name'),
      value: get(team, '_id'),
      logo: get(team, 'logo'),
    };
  }, [userTeams]);

  const getSelectedTeamCohortId = useCallback(
    () =>
      get(
        find(userTeams, (team) => !!team.selected),
        'cohortId'
      ),
    [userTeams]
  );

  const handleChangeTeam = (team) => {
    setCurrentUserTeam(userId, team.value);
    setSelectedTeam(find(userTeams, { _id: team.value }));
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      removed.columnBMC = result.destination.droppableId;
      destItems.splice(destination.index, 0, removed);
      updateOrder(destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      removed.order = result.destination.index;
      copiedItems.splice(destination.index, 0, removed);
      updateOrder(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
    const currentHypothesis =
      columns[result.source.droppableId].items[result.source.index];

    updateHypothesis(
      {
        order: result.destination.index,
        columnBMC: result.destination.droppableId,
      },
      result.draggableId,
      currentHypothesis
    );
  };

  if (!columns) return <></>;

  const missingFirstCustomerSegment =
    columns.customerSegments.items.length === 0;

  return (
    <MarginContainer height="95vh">
      <TitleButtonsDiv marginBottom="10px">
        <H1>Business Model Canvas</H1>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GenericComments
            fetchComments={fetchComments}
            type={commentTypeBmc}
            userId={currentUserId}
            teamId={teamId}
            comments={bmcComments}
          />
          <div
            style={{
              display: 'block',
              minWidth: '13rem',
              marginRight: '2px',
              marginLeft: '5px',
            }}
            id="team-dropdown"
          >
            <TeamFilterForm
              userTeams={getTeamOptions()}
              userTeamValue={getTeamValue()}
              handleChangeTeam={handleChangeTeam}
              cohortId={getSelectedTeamCohortId()}
            />
          </div>
        </Box>
      </TitleButtonsDiv>
      <BmcFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        isPaused={isPaused}
        setIsPaused={setIsPaused}
        setAllowedProperties={setAllowedProperties}
        setAllowClick={setAllowClick}
        currentWeek={currentWeek}
        setCurrentWeek={setCurrentWeek}
        maxWeekNumber={maxWeekNumber}
        getNewWeek={getNewWeek}
      />

      <div style={{ marginTop: '15px' }}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <BusinessModelCanvasContainer>
            <BusinessModelCanvasRow borderBottom>
              <Droppable droppableId="keyPartners">
                {(provided) => (
                  <BusinessModelCanvasColumn firstRow singleOnRow>
                    <TitleButtonsDiv marginBottom="15px" alignItems>
                      <Heading2 paddingTop="12px">
                        <BmcColumnHeader
                          isActive={!missingFirstCustomerSegment}
                          onClick={() => handleOpenDrawer('keyPartners')}
                        >
                          Key Partners
                        </BmcColumnHeader>
                      </Heading2>
                      {!isMentor && !isAccelerator && allowClick && (
                        <BmcAddButton
                          disabled={missingFirstCustomerSegment}
                          id="keyPartners"
                          onClick={() =>
                            handleOpenHypothesisModal('keyPartners')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.keyPartners.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.keyPartners.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getColor(
                                        get(hypothesis, 'segment')
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>

              <BusinessModelCanvasColumn borderLeft firstRow container>
                <Droppable droppableId="keyActivities">
                  {(provided, snapshot) => (
                    <BusinessModelCanvasColumn
                      borderBottom
                      noPadding
                      multipleChildren
                    >
                      <TitleButtonsDiv marginBottom="15px" alignItems>
                        <Heading2 paddingTop="12px">
                          <BmcColumnHeader
                            isActive={!missingFirstCustomerSegment}
                            onClick={() => handleOpenDrawer('keyActivities')}
                          >
                            Key Activities
                          </BmcColumnHeader>
                        </Heading2>
                        {!isMentor && !isAccelerator && allowClick && (
                          <BmcAddButton
                            disabled={missingFirstCustomerSegment}
                            id="keyActivities"
                            onClick={() =>
                              handleOpenHypothesisModal('keyActivities')
                            }
                          />
                        )}
                      </TitleButtonsDiv>
                      <HypothesisCardsContainer
                        className="scrollbar"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {!size(columns.keyActivities.items) ? (
                          <NoResultsFound />
                        ) : (
                          <>
                            {map(
                              orderHypothesis(columns.keyActivities.items),
                              (hypothesis, index) => (
                                <Draggable
                                  isDragDisabled={
                                    isDragAndDropActive || !allowClick
                                  }
                                  key={hypothesis._id}
                                  draggableId={hypothesis._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <HypothesisCard
                                        key={hypothesis._id}
                                        hypothesis={hypothesis}
                                        canvasData={canvasData}
                                        allowClick={allowClick}
                                        backgroundColor={getColor(
                                          get(hypothesis, 'segment')
                                        )}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                          </>
                        )}
                      </HypothesisCardsContainer>
                    </BusinessModelCanvasColumn>
                  )}
                </Droppable>
                <Droppable droppableId="keyResources">
                  {(provided, snapshot) => (
                    <BusinessModelCanvasColumn noPadding multipleChildren>
                      <TitleButtonsDiv marginBottom="15px" alignItems>
                        <Heading2>
                          <BmcColumnHeader
                            isActive={!missingFirstCustomerSegment}
                            onClick={() => handleOpenDrawer('keyResources')}
                          >
                            Key Resources
                          </BmcColumnHeader>
                        </Heading2>
                        {!isMentor && !isAccelerator && allowClick && (
                          <BmcAddButton
                            id="keyResources"
                            disabled={missingFirstCustomerSegment}
                            onClick={() =>
                              handleOpenHypothesisModal('keyResources')
                            }
                          />
                        )}
                      </TitleButtonsDiv>
                      <HypothesisCardsContainer
                        className="scrollbar"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {!size(columns.keyResources.items) ? (
                          <NoResultsFound />
                        ) : (
                          <>
                            {map(
                              orderHypothesis(columns.keyResources.items),
                              (hypothesis, index) => (
                                <Draggable
                                  isDragDisabled={
                                    isDragAndDropActive || !allowClick
                                  }
                                  key={hypothesis._id}
                                  draggableId={hypothesis._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <HypothesisCard
                                        key={hypothesis._id}
                                        hypothesis={hypothesis}
                                        canvasData={canvasData}
                                        allowClick={allowClick}
                                        backgroundColor={getColor(
                                          get(hypothesis, 'segment')
                                        )}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                          </>
                        )}
                      </HypothesisCardsContainer>
                    </BusinessModelCanvasColumn>
                  )}
                </Droppable>
              </BusinessModelCanvasColumn>
              <Droppable droppableId="valuePropositions">
                {(provided, snapshot) => (
                  <BusinessModelCanvasColumn borderLeft firstRow singleOnRow>
                    <TitleButtonsDiv marginBottom="15px" alignItems>
                      <Heading2 paddingTop="12px">
                        <BmcColumnHeader
                          isActive={!missingFirstCustomerSegment}
                          onClick={() => handleOpenDrawer('valuePropositions')}
                        >
                          Value Propositions
                        </BmcColumnHeader>
                      </Heading2>
                      {!isMentor && !isAccelerator && allowClick && (
                        <BmcAddButton
                          disabled={missingFirstCustomerSegment}
                          id="valuePropositions"
                          onClick={() =>
                            handleOpenHypothesisModal('valuePropositions')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.valuePropositions.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.valuePropositions.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getColor(
                                        get(hypothesis, 'segment')
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
              <BusinessModelCanvasColumn borderLeft firstRow container>
                <Droppable droppableId="customerRelationships">
                  {(provided, snapshot) => (
                    <BusinessModelCanvasColumn
                      borderBottom
                      noPadding
                      multipleChildren
                    >
                      <TitleButtonsDiv marginBottom="15px" alignItems>
                        <Heading2 paddingTop="12px">
                          <BmcColumnHeader
                            isActive={!missingFirstCustomerSegment}
                            onClick={() =>
                              handleOpenDrawer('customerRelationships')
                            }
                          >
                            Customer Relationships
                          </BmcColumnHeader>
                        </Heading2>
                        {!isMentor && !isAccelerator && allowClick && (
                          <BmcAddButton
                            disabled={missingFirstCustomerSegment}
                            id="customerRelationships"
                            onClick={() =>
                              handleOpenHypothesisModal('customerRelationships')
                            }
                          />
                        )}
                      </TitleButtonsDiv>
                      <HypothesisCardsContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="scrollbar"
                      >
                        {!size(columns.customerRelationships.items) ? (
                          <NoResultsFound />
                        ) : (
                          <>
                            {map(
                              orderHypothesis(
                                columns.customerRelationships.items
                              ),
                              (hypothesis, index) => (
                                <Draggable
                                  isDragDisabled={
                                    isDragAndDropActive || !allowClick
                                  }
                                  key={hypothesis._id}
                                  draggableId={hypothesis._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <HypothesisCard
                                        key={hypothesis._id}
                                        hypothesis={hypothesis}
                                        canvasData={canvasData}
                                        allowClick={allowClick}
                                        backgroundColor={getColor(
                                          get(hypothesis, 'segment')
                                        )}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                          </>
                        )}
                      </HypothesisCardsContainer>
                    </BusinessModelCanvasColumn>
                  )}
                </Droppable>

                <Droppable droppableId="channels">
                  {(provided, snapshot) => (
                    <BusinessModelCanvasColumn noPadding multipleChildren>
                      <TitleButtonsDiv marginBottom="15px" alignItems>
                        <Heading2>
                          <BmcColumnHeader
                            isActive={!missingFirstCustomerSegment}
                            onClick={() => handleOpenDrawer('channels')}
                          >
                            Channels
                          </BmcColumnHeader>
                        </Heading2>
                        {!isMentor && !isAccelerator && allowClick && (
                          <BmcAddButton
                            disabled={missingFirstCustomerSegment}
                            id="channels"
                            onClick={() =>
                              handleOpenHypothesisModal('channels')
                            }
                          />
                        )}
                      </TitleButtonsDiv>
                      <HypothesisCardsContainer
                        className="scrollbar"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {!size(columns.channels.items) ? (
                          <NoResultsFound />
                        ) : (
                          <>
                            {map(
                              orderHypothesis(columns.channels.items),
                              (hypothesis, index) => (
                                <Draggable
                                  isDragDisabled={
                                    isDragAndDropActive || !allowClick
                                  }
                                  key={hypothesis._id}
                                  draggableId={hypothesis._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <HypothesisCard
                                        key={hypothesis._id}
                                        hypothesis={hypothesis}
                                        canvasData={canvasData}
                                        allowClick={allowClick}
                                        backgroundColor={getColor(
                                          get(hypothesis, 'segment')
                                        )}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                          </>
                        )}
                      </HypothesisCardsContainer>
                    </BusinessModelCanvasColumn>
                  )}
                </Droppable>
              </BusinessModelCanvasColumn>
              <Droppable isDropDisabled droppableId="customerSegments">
                {(provided, snapshot) => (
                  <BusinessModelCanvasColumn borderLeft firstRow singleOnRow>
                    <TitleButtonsDiv marginBottom="15px" alignItems>
                      <Heading2 paddingTop="12px">
                        <BmcColumnHeader
                          isActive={!missingFirstCustomerSegment}
                          onClick={() => handleOpenDrawer('customerSegments')}
                        >
                          Customer Segments
                        </BmcColumnHeader>
                      </Heading2>
                      {!isMentor && !isAccelerator && allowClick && (
                        <BmcAddButton
                          id="customerSegments"
                          onClick={() =>
                            handleOpenHypothesisModal('customerSegments')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.customerSegments.items) ? (
                        <NoResultsFound>
                          <P faded light>
                            Nici un customer segment.
                          </P>
                          <P marginTop="20px" faded light>
                            Începeți completarea BMC-ului ideii prin conturarea
                            primelor segmente de clienți vizați de soluția
                            voastră.
                          </P>
                        </NoResultsFound>
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.customerSegments.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      customerSegment="customerSegments"
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={hypothesis.color}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
            </BusinessModelCanvasRow>
            <BusinessModelCanvasRow>
              <Droppable droppableId="costStructure">
                {(provided, snapshot) => (
                  <BusinessModelCanvasColumn lastRow singleOnRow secondRow>
                    <TitleButtonsDiv marginBottom="15px" alignItems>
                      <Heading2 paddingTop="12px">
                        <BmcColumnHeader
                          isActive={!missingFirstCustomerSegment}
                          onClick={() => handleOpenDrawer('costStructure')}
                        >
                          Cost Structure
                        </BmcColumnHeader>
                      </Heading2>
                      {!isMentor && !isAccelerator && allowClick && (
                        <BmcAddButton
                          disabled={missingFirstCustomerSegment}
                          id="costStructure"
                          onClick={() =>
                            handleOpenHypothesisModal('costStructure')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="scrollbar"
                    >
                      {!size(columns.costStructure.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          <Row>
                            <Col md="6">
                              {map(
                                orderHypothesis(columns.costStructure.items),
                                (hypothesis, index) =>
                                  index % 2 === 0 && (
                                    <Draggable
                                      isDragDisabled={
                                        isDragAndDropActive || !allowClick
                                      }
                                      key={hypothesis._id}
                                      draggableId={hypothesis._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <HypothesisCard
                                            key={index}
                                            hypothesis={hypothesis}
                                            canvasData={canvasData}
                                            allowClick={allowClick}
                                            backgroundColor={getColor(
                                              get(hypothesis, 'segment')
                                            )}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                            </Col>
                            <Col md="6">
                              {map(
                                orderHypothesis(columns.costStructure.items),
                                (hypothesis, index) =>
                                  Math.abs(index % 2) === 1 && (
                                    <Draggable
                                      isDragDisabled={
                                        isDragAndDropActive || !allowClick
                                      }
                                      key={hypothesis._id}
                                      draggableId={hypothesis._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <HypothesisCard
                                            floatRight
                                            key={index}
                                            hypothesis={hypothesis}
                                            canvasData={canvasData}
                                            allowClick={allowClick}
                                            backgroundColor={getColor(
                                              get(hypothesis, 'segment')
                                            )}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                            </Col>
                          </Row>
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
              <Droppable droppableId="revenueStreams">
                {(provided, snapshot) => (
                  <BusinessModelCanvasColumn lastRow borderLeft singleOnRow>
                    <TitleButtonsDiv marginBottom="15px" alignItems>
                      <Heading2 paddingTop="12px">
                        <BmcColumnHeader
                          isActive={!missingFirstCustomerSegment}
                          onClick={() => handleOpenDrawer('revenueStreams')}
                        >
                          Revenue Streams
                        </BmcColumnHeader>
                      </Heading2>
                      {!isMentor && !isAccelerator && allowClick && (
                        <BmcAddButton
                          disabled={missingFirstCustomerSegment}
                          id="revenueStreams"
                          onClick={() =>
                            handleOpenHypothesisModal('revenueStreams')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!size(get(canvasData, 'revenueStreams')) ? (
                        <NoResultsFound />
                      ) : (
                        <HypothesisCardsContainer className="scrollbar">
                          <Row>
                            <Col md="6">
                              {map(
                                columns.revenueStreams.items,
                                (hypothesis, index) =>
                                  index % 2 === 0 && (
                                    <Draggable
                                      isDragDisabled={
                                        isDragAndDropActive || !allowClick
                                      }
                                      key={hypothesis._id}
                                      draggableId={hypothesis._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <HypothesisCard
                                            key={index}
                                            hypothesis={hypothesis}
                                            canvasData={canvasData}
                                            allowClick={allowClick}
                                            backgroundColor={getColor(
                                              get(hypothesis, 'segment')
                                            )}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                            </Col>
                            <Col md="6">
                              {map(
                                columns.revenueStreams.items,
                                (hypothesis, index) =>
                                  Math.abs(index % 2) === 1 && (
                                    <Draggable
                                      isDragDisabled={
                                        isDragAndDropActive || !allowClick
                                      }
                                      key={hypothesis._id}
                                      draggableId={hypothesis._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <HypothesisCard
                                            floatRight
                                            key={index}
                                            hypothesis={hypothesis}
                                            canvasData={canvasData}
                                            allowClick={allowClick}
                                            backgroundColor={getColor(
                                              get(hypothesis, 'segment')
                                            )}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                            </Col>
                          </Row>
                        </HypothesisCardsContainer>
                      )}
                    </div>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
            </BusinessModelCanvasRow>
          </BusinessModelCanvasContainer>
        </DragDropContext>
      </div>
      <BmcSegmentDrawer
        isDrawerOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        selectedSegment={selectedSegment}
        getColor={getColor}
      />
      <AddHypothesisModal
        isModalOpen={isHypothesisModalOpen}
        setIsModalOpen={setIsHypothesisModalOpen}
        segmentOptions={segmentOptions}
        handleClose={handleCloseHypothesisModal}
        handleSubmitHypothesis={onCreateHypothesis}
        hypothesisFormValues={{ columnBMC: columnBMCValue }}
      />
    </MarginContainer>
  );
};

const mapStateToProps = (state) => ({
  hypothesis: state.hypotheses.hypothesis,
  hypotheses: state.hypotheses.hypotheses,
  isLoadingOpportunity: state.opportunityAnalysis.isLoading,
  isEditing: state.opportunityAnalysis.isEditing,
  isCreating: state.opportunityAnalysis.isCreating,
  userTeams: state.common.userTeams.userTeams,
  opportunityAnalysis: state.opportunityAnalysis.opportunityAnalysis,
  isLoading: state.hypotheses.isLoadingHypotheses,
  canvasData: getBMCData(state.hypotheses),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
  role: get(state.account.login, 'user.role'),
  userId: get(state.account.login, 'user._id'),
  userProfile: state.userProfile,
});

const mapDispatchToProps = {
  openModal,
  loadHypotheses,
  deleteOpportunityAnalysisComment,
  editOpportunityAnalysisComment,
  loadOpportunityAnalysis,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
  editHypothesis,
  editHypothesesOrder,
  setCurrentUserTeam,
  addHypothesis,
  createTargetIndicators,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessModelCanvasPage)
);
