import React from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CloseButtonContainer,
  VideoModalContainer,
} from './styled/VideoModalStyled';
import { isAdminSelector } from '../../account/ducks';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VideoModal() {
  const query = useQuery();
  const history = useHistory();

  if (!query.get('video-player')) return <></>;

  return (
    <VideoModalContainer>
      <CloseButtonContainer
        onClick={() => {
          query.delete('video-player');
          history.replace({
            search: query.toString(),
          });
        }}
      >
        X
      </CloseButtonContainer>
      <iframe
        src={query.get('video-player')}
        width="740"
        height="420"
        title="Video"
        allowFullScreen
      />
    </VideoModalContainer>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  isAdmin: isAdminSelector(state.account.login),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoModal)
);
