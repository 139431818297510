import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import {
  getAllLoggedUserPayments,
  getInvoiceForPayment,
} from '../../ducks/userProfile';
import {
  PaymentsPageContainer,
  PaymentsSegment,
  SubscriptionsSegmentBox,
  TransactionsContainer,
  UserDetailsButtonsBox,
  UserDetailsSegmentBox,
} from '../styled/PaymentsStyle';
import {
  H2,
  P,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { SpaceBetweenBox } from '../../../common/components/styled/ReusablePositioningComponents';
import GenericTable from '../../../common/components/GenericTable';
import SpinnerComponent from '../../../common/components/Spinner';
import { SelectSubscriptionModal } from '../PaymentsModal';
import { LEGAL_CUSTOMER_TYPE, PAYMENTS_TABLE_HEADERS } from '../../constants';

import { formatDate } from '../../../common/functions/dateHelpers';
import BillingDetailsModal from '../BillingDetailsModal';

PrimaryButton.propTypes = { children: PropTypes.node };
const Payments = ({
  getAllLoggedUserPayments,
  userPayments = [],
  isLoading,
  getInvoiceForPayment,
  userProfile,
  nextPaymentData = null,
}) => {
  const [isSubscriptionSelectionOpen, setIsSubscriptionSelectionOpen] =
    useState(false);
  const [isBillingDetailsOpen, setIsBillingDetailsOpen] = useState(false);

  const { invoiceDetails, userId } = userProfile;
  const { subscription: currentSubscription } = userId;
  const hasActiveSubscription =
    currentSubscription && !currentSubscription?.cancelAt;

  const hasCancelledSubscription = currentSubscription?.cancelAt;

  let subscriptionReccurence: string | null;
  if (currentSubscription) {
    subscriptionReccurence =
      currentSubscription.planRecurringPeriod === 'year' ? 'Anual' : 'lunar';
  } else {
    subscriptionReccurence = null;
  }

  let userAddress = '';
  if (invoiceDetails) {
    if (invoiceDetails.customerType === 'legal') {
      userAddress = invoiceDetails.legalPerson.address;
    } else {
      userAddress = invoiceDetails.individual.address;
    }
  }

  useEffect(() => {
    getAllLoggedUserPayments();
  }, [getAllLoggedUserPayments]);

  const handleOpenSubscriptionSelection = () => {
    setIsSubscriptionSelectionOpen(true);
  };

  const getInvoiceById = (paymentId) => {
    getInvoiceForPayment(paymentId);
  };

  const rowOnClickHandler = (row, header) => {
    if (header.key === 'actions') {
      return getInvoiceById(row.invoiceId);
    }
  };

  const renderAdditionalTableInformation = () => {
    if (isLoading) {
      return <SpinnerComponent isLoading />;
    }

    if (userPayments.length === 0) {
      return <P faded>Nu exista tranzactii</P>;
    }

    return <P>End of history</P>;
  };

  const renderNextPaymentDetails = () => (
    <Box>
      <P marginBottom="3px" marginTop="8px">
        {nextPaymentData.subscriptionPrice} RON
      </P>
      <P faded>{nextPaymentData?.nextPaymentAttempt}</P>
    </Box>
  );

  const renderCurrentPaymentDetails = () => (
    <Box>
      <P marginBottom="3px" marginTop="8px">
        {subscriptionReccurence} ({currentSubscription?.amountDecimal / 100}{' '}
        RON)
      </P>
      <P faded>Initiat pe {formatDate(currentSubscription?.createdAt)}</P>
    </Box>
  );

  return (
    <PaymentsPageContainer className="scrollbar">
      <PaymentsSegment>
        <SubscriptionsSegmentBox>
          <H2> Abonamentul tau </H2>
          <SpaceBetweenBox>
            {hasCancelledSubscription && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <P faded>Abonamentul tau a fost anulat</P>

                <PrimaryButton
                  style={{ height: '40px' }}
                  inverted
                  onClick={handleOpenSubscriptionSelection}
                >
                  Schimba planul
                </PrimaryButton>
              </Box>
            )}
            {hasActiveSubscription && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {renderCurrentPaymentDetails()}

                <PrimaryButton
                  style={{ height: '40px' }}
                  inverted
                  onClick={handleOpenSubscriptionSelection}
                >
                  Schimba planul
                </PrimaryButton>
              </Box>
            )}
            {!hasActiveSubscription && !hasCancelledSubscription && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <P faded>Nu ai selectat un abonament inca</P>

                <PrimaryButton
                  style={{ height: '40px' }}
                  inverted
                  onClick={handleOpenSubscriptionSelection}
                >
                  Schimba planul
                </PrimaryButton>
              </Box>
            )}
          </SpaceBetweenBox>
        </SubscriptionsSegmentBox>
        <SubscriptionsSegmentBox>
          <H2>Urmatoarea plata</H2>
          {nextPaymentData && renderNextPaymentDetails()}
        </SubscriptionsSegmentBox>
      </PaymentsSegment>
      <PaymentsSegment withBorders height="250px">
        <UserDetailsSegmentBox>
          <H2>Date facturare</H2>
          <Grid container>
            <Grid py={2} md={6} item>
              <P>Detalii Metoda de plata</P>
            </Grid>
            <Grid item py={2} md={6}>
              <P faded>Camp gol</P>
            </Grid>
            <Grid py={2} md={6} item>
              <P>Adresa de facturare</P>
            </Grid>
            <Grid item py={2} md={6}>
              {invoiceDetails && invoiceDetails.customerType ? (
                <P>{userAddress}</P>
              ) : (
                <P faded>Camp gol</P>
              )}
            </Grid>
            {invoiceDetails &&
              invoiceDetails.customerType === LEGAL_CUSTOMER_TYPE && (
                <>
                  <Grid py={2} md={6} item>
                    <P>Identificator fiscal</P>
                  </Grid>
                  <Grid item py={2} md={6}>
                    <P>{invoiceDetails.legalPerson.cui}</P>
                  </Grid>
                </>
              )}
          </Grid>
        </UserDetailsSegmentBox>
        <UserDetailsButtonsBox>
          <PrimaryButton marginTop="20px" marginBottom="20px" inverted>
            Editeaza
          </PrimaryButton>
          <PrimaryButton onClick={() => setIsBillingDetailsOpen(true)} inverted>
            Editeaza
          </PrimaryButton>
        </UserDetailsButtonsBox>
      </PaymentsSegment>
      <TransactionsContainer>
        <H2>Istoric tranzactii</H2>
        <GenericTable
          headers={PAYMENTS_TABLE_HEADERS}
          rows={isLoading ? [] : userPayments}
          cellOnClick={rowOnClickHandler}
        />
        {renderAdditionalTableInformation()}
      </TransactionsContainer>
      <SelectSubscriptionModal
        isModalOpen={isSubscriptionSelectionOpen}
        setIsModalOpen={setIsSubscriptionSelectionOpen}
        nextPaymentData={nextPaymentData}
      />
      <BillingDetailsModal
        isModalOpen={isBillingDetailsOpen}
        setIsModalOpen={setIsBillingDetailsOpen}
      />
    </PaymentsPageContainer>
  );
};

const mapStateToProps = (state) => ({
  userPayments: state.userProfile.loggedUserPayments,
  isLoading: state.userProfile.isLoadingPayments,
  loggedUserNextPayment: state.userProfile.loggedUserNextPayment,
  userProfile: state.userProfile.userProfile,
});

const mapDispatchToProps = {
  getAllLoggedUserPayments,
  getInvoiceForPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
