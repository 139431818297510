import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import { get, find, trim } from 'lodash-es';
import uuid from 'react-uuid';
import { Box, Grid, TextField } from '@mui/material';

import { stageOptions, ideaCategoryOptions } from '../../constants';
import { isCohortCodeUsed } from '../../services/createTeam';
import { isCohortExpired } from '../../../accelerator/services/cohorts';
import {
  RenderDateField,
  RenderDropdownField,
} from '../../../common/components/forms/Fields';
import { ideaRoleOptions } from '../../constants/CreateTeamForm';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import CustomSwitch from '../../../common/components/forms/formComponents/CusomSwitch';
import { CustomLabel } from '../../../core/components';
import { PublicTeamSwitchContainer } from '../styled/TeamProfile';

const RenderTextField = (props) => {
  const {
    label,
    placeholder,
    type = 'text',
    md = 6,
    rows = 1,
    multiline = false,
  } = props;
  const [field, meta] = useField(props);
  return (
    <Grid item xs={12} md={md}>
      <TextField
        {...field}
        fullWidth
        label={label}
        type={type}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
      />
    </Grid>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  checkTeamChodeUsed,
  initialValues,
  setFieldValue,
  isValid,
  isSubmitting,
  onSubmit,
  setPrivateTeam,
  privateTeam,
  isEditMode,
  checkIsCohortExpired,
  isCohortExpired,
  ...rest
}) => (
  <form noValidate>
    <Grid container spacing={2}>
      <RenderTextField name="name" label="Nume" md={12} />
      <RenderTextField name="domain" label="Website" />
      <RenderTextField name="headquarters" label="Localitate" />
      <RenderTextField name="cui" label="CUI" />
      <RenderDateField
        setFieldValue={setFieldValue}
        values={values}
        name="dateOfEstablishment"
        label="Dată înființare"
        placeholder="zz/ll/aaaa"
      />
      <RenderDropdownField
        values={values}
        setFieldValue={setFieldValue}
        label="Industrie"
        placeholder="Adauga maxim 3"
        name="ideaCategory"
        options={ideaCategoryOptions}
        md={12}
        multiple
      />
      <RenderDropdownField
        values={values}
        setFieldValue={setFieldValue}
        label="Stadiul ideii"
        placeholder="Adauga stadiul ideii tale"
        name="stage"
        options={stageOptions}
        md={12}
      />
      {!isEditMode && (
        <RenderTextField
          name="description"
          label="Descrie ideea"
          md={12}
          multiline
          rows={3}
        />
      )}
      {!isEditMode && (
        <RenderTextField
          name="cohortCode"
          md={12}
          label="Vrei să te alături unei cohorte?"
        />
      )}

      <RenderTextField name="equity" md={12} label="Equity%" />
      <RenderDropdownField
        values={values}
        setFieldValue={setFieldValue}
        label="Rol"
        name="role"
        options={ideaRoleOptions}
        md={12}
        multiple
      />

      <Box
        sx={{ display: 'flex', paddingLeft: '16px', flexDirection: 'column' }}
      >
        <PublicTeamSwitchContainer>
          <CustomSwitch id="isPublic" labelRight="Idee publica" />
          <CustomLabel tooltipDescription="Cand este activat, vor aparea informatii despre idee in clasamentul general." />
        </PublicTeamSwitchContainer>
        <PrimaryButton
          marginTop="20px"
          icon={SaveIconOutlined}
          onClick={handleSubmit}
          type="submit"
        >
          Salveaza
        </PrimaryButton>
      </Box>
    </Grid>
  </form>
);

const CreateTeamForm = ({
  onSubmit,
  team,
  isEditMode,
  isEditDescriptionMode,
}) => {
  const checkTeamChodeUsed = null;
  const [privateTeam, setPrivateTeam] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    name: Yup.string().required('Câmp obligatoriu'),
    ideaCategory: Yup.array().required('Câmp obligatoriu'),
    stage: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    cohortCode: Yup.string()
      .test('is-invalid', 'Cod cohortă invalid', async (value) =>
        trim(value)
          ? isCohortCodeUsed(value).then(({ data }) => data.isUsed)
          : true
      )
      .test('is-expired', 'Această cohortă a expirat', async (value) =>
        trim(value)
          ? isCohortExpired(value).then(({ data }) => !data.isExpired)
          : true
      ),
    teamCode: Yup.string(),
    domain: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    dateOfEstablishment: Yup.string(),
    headquarters: Yup.string(),
    cui: Yup.string(),
    equity: Yup.number()
      .typeError('Alege un numar de la 0 la 100')
      .min(0, 'Alege un numar mai mare decat 0')
      .max(100, 'Alege un numar mai mic decat 100')
      .required('Obkigatoriu'),
    role: Yup.string().required('Câmp obligatoriu'),
    isPublic: Yup.boolean(),
  });

  const defaultStage =
    team &&
    team.stage &&
    get(find(stageOptions, { value: team.stage }), 'value');

  const initialValues = {
    name: team && team.name ? team.name : '',
    ideaCategory: team && team.ideaCategory ? team.ideaCategory : [],
    stage: team && team.stage ? defaultStage : '',
    description: team && team.description ? team.description : '',
    cohortCode: team && team.cohortCode ? team.cohortCode : '',
    equity: team && team.equity ? team.equity : '100',
    role: team && team.role ? team.role : '',
    teamCode: team && team.teamCode ? team.teamCode : uuid(),
    domain: team && team.domain ? team.domain : '',
    dateOfEstablishment:
      team && team.dateOfEstablishment
        ? new Date(team.dateOfEstablishment)
        : '',
    headquarters: team && team.headquarters ? team.headquarters : '',
    cui: team && team.cui ? team.cui : '',
    isPublic: team && team.isPublic ? team.isPublic : false,
  };

  return (
    <Formik
      validationSchema={
        !isEditDescriptionMode
          ? schema
          : Yup.object({
              description: Yup.string().required('Câmp obligatoriu'),
            })
      }
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          checkTeamChodeUsed={checkTeamChodeUsed}
          setPrivateTeam={() => setPrivateTeam(!privateTeam)}
          privateTeam={privateTeam}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};

CreateTeamForm.propTypes = {};

export default CreateTeamForm;
