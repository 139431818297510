import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { ArrowLeft, SaveIconOutlined } from '../../../common/assets/icons';
import {
  H2,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import CustomSwitch from '../../../common/components/forms/formComponents/CusomSwitch';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import RenderRichText from '../../../common/components/RichEditor';
import { useApi } from '../../../hooks/useApi';
import {
  addChapter,
  editChapter,
  getAllChapters,
  getChapterById,
} from '../../../requests/chapters';
import { CHAPTER_TOOLBAR_CONFIG } from '../../../common/constants/RichTextEditorConstants';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
  isHidden: Yup.boolean(),
});

const EditChapter = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const chapterId = query.get('chapterId');
  const order = +query.get('order');
  const isEdit = query.get('isEdit');

  const [{ result: chapter = {}, isLoading }, fetchChapterById] =
    useApi(getChapterById);
  const [{ result: chaptersList = [] }, fetchAllChapters] =
    useApi(getAllChapters);
  const [, postChapter] = useApi(addChapter);
  const [, updateChapter] = useApi(editChapter);

  const defaultValues = {
    title: '',
    description: '',
    isHidden: false,
  };

  const editValues = isEdit
    ? {
        title: chapter.title || '',
        description: chapter.description || '',
        isHidden: !chapter.isVisible || false,
      }
    : {};

  const initialValues = { ...defaultValues, ...editValues };

  const handleChapter = (values) => {
    if (isEdit) {
      return handleEditChapter(values);
    } else {
      return handleCreateNewChapter(values);
    }
  };

  const handleCreateNewChapter = async (values) => {
    const postChapterPayload = {
      ...(values.title !== '' ? { title: values.title } : {}),
      ...(values.description !== '' ? { description: values.description } : {}),
      ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
      ...(chapterId ? { chapterId } : {}), //chapterId exists only when creating a subchapter
      ...(order ? { order } : {}),
    };
    await postChapter(postChapterPayload);
    history.push('/admin/mrl?tab=chapters');
  };

  const handleEditChapter = async (values) => {
    const updateChapterPayload = {
      params: { chapterId },
      payload: {
        ...(values.title !== '' ? { title: values.title } : {}),
        ...(values.description !== ''
          ? { description: values.description }
          : {}),
        ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
      },
      order,
    };
    await updateChapter(updateChapterPayload);
    history.push('/admin/mrl?tab=chapters');
  };

  const handleGetChapterById = async () => {
    const payload = { params: { chapterId: chapterId } };
    fetchChapterById(payload);
  };

  useEffect(() => {
    if (isEdit) {
      handleGetChapterById();
    }
    fetchAllChapters();
  }, [chapterId]);

  if (isLoading) {
    return <div />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
        }}
      >
        <Box
          onClick={history.goBack}
          sx={{
            borderBottom: '1px solid #74A3FE',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            paddingBottom: '10px',
            cursor: 'pointer',
          }}
        >
          <ArrowLeft />
          <H2>Inpoi la cuprins</H2>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleChapter}
        >
          {({ handleSubmit, values }) => {
            let descriptionIsEmpty = true;
            try {
              const descriptionObj = JSON.parse(values.description);
              // Check if at least one text block has text content
              descriptionIsEmpty = !descriptionObj.blocks.some(
                (block) => block.text.trim() !== ''
              );
            } catch (error) {
              // Handle parsing error or set description to empty if JSON is invalid
              descriptionIsEmpty = true;
            }

            const isSubmitDisabled = !values.title || descriptionIsEmpty;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '90%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '10px',
                      }}
                    >
                      <H2>Capitol</H2>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <CustomSwitch
                          id="isHidden"
                          labelRight="Ascunsa"
                          labelLeft="Vizibila"
                        />
                        <Box
                          sx={{
                            width: '1px',
                            backgroundColor: '#DEE7FC',
                            height: '32px',
                          }}
                        />
                        <Box sx={{ height: '32px' }}>
                          <PrimaryButton
                            disabled={isSubmitDisabled}
                            type="submit"
                            icon={SaveIconOutlined}
                          >
                            Salveaza
                          </PrimaryButton>
                        </Box>
                      </Box>
                    </Box>
                    <Grid sx={{ width: '100%' }}>
                      <CustomTextField id="title" label="Titlu" />
                      <RenderRichText
                        name="description"
                        label="Descriere"
                        height="70vh"
                        fullHeight
                        config={CHAPTER_TOOLBAR_CONFIG}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditChapter;
