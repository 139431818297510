import { Box } from '@mui/material';
import React from 'react';
import { SpaceBetweenBox } from '../../common/components/styled/ReusablePositioningComponents';
import { H1 } from '../../common/components/styled/ReusableTextComponents';
import {
  CommentsCounter,
  NotificationsFilterContainer,
  NotificationsFilterOption,
} from './styled/Comments';
import { ExcelButton } from '../../common/components/styled';

const CommentsAndNotificationsHeader = ({
  commentsLength,
  setReadFilterValue,
  readFilterValue,
  handleCsvDownload,
}) => (
  <Box>
    <SpaceBetweenBox>
      <Box sx={{ display: 'flex' }}>
        <H1>Notificari si comentarii</H1>
        <CommentsCounter> {commentsLength} </CommentsCounter>
      </Box>
      <Box onClick={handleCsvDownload}>
        <ExcelButton />
      </Box>
    </SpaceBetweenBox>
    <NotificationsFilterContainer>
      <NotificationsFilterOption
        onClick={() => setReadFilterValue('all')}
        active={readFilterValue === 'all'}
      >
        Toate
      </NotificationsFilterOption>
      <NotificationsFilterOption
        onClick={() => setReadFilterValue(false)}
        active={readFilterValue === false}
      >
        Noi
      </NotificationsFilterOption>
      <NotificationsFilterOption
        onClick={() => setReadFilterValue(true)}
        active={readFilterValue === true}
      >
        Arhiva
      </NotificationsFilterOption>
    </NotificationsFilterContainer>
  </Box>
);

export default CommentsAndNotificationsHeader;
