import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { get } from 'lodash-es';
import { CreateCohortForm } from '../forms';
import { addCohort, loadCohorts, editCohort } from '../../ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const AddCohortModal = (props) => {
  const {
    addCohort,
    editCohort,
    isOpen,
    loadCohorts,
    isLoading,
    cohort,
    isEditMode,
    closeModal,
    userId,
  } = props;
  const onSubmit = async (values) => {
    if (isEditMode) {
      await editCohort(cohort._id, values);
      closeModal();
      await loadCohorts(userId);
    } else {
      await addCohort(values);
      closeModal();
      await loadCohorts(userId);
    }
  };

  return (
    <Modal onHide={closeModal} show={isOpen} size="lg" backdrop="static">
      <Modal.Body>
        <TitleButtonsDiv marginBottom="30px">
          <Title fontSize="24px">
            {isEditMode ? 'Editează cohorta' : 'Adaugă cohortă'}
          </Title>
          <DeleteButtonSmall onClick={closeModal} />
        </TitleButtonsDiv>
        <CreateCohortForm
          onSubmit={onSubmit}
          cohort={cohort}
          isEditMode={isEditMode}
        />
      </Modal.Body>

      <Loading isLoading={isLoading} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
});

const mapDispatchToProps = {
  addCohort,
  loadCohorts,
  editCohort,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCohortModal)
);
