import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { get } from 'lodash-es';
import { AddExperimentForm } from '../forms';
import { addExperiment, editExperiment } from '../../ducks';
import { currentTeamIdSelector } from '../../../common/ducks';
import { loadHypotheses, loadHypothesis } from '../../../hypotheses/ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { handleAutomaticStatus } from '../forms/AddExperimentForm';

const AddExperimentModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    addExperiment,
    editExperiment,
    teamId,
    loadData,
    loadHypotheses,
    hypothesis,
    id,
    global,
    closeModal,
    isEditMode,
    experiment,
    isLoading,
    firstName,
    loadHypothesis,
    lastName,
    match: {
      params: { hypothesisId },
    },
  } = props;

  useEffect(() => {
    if (teamId) {
      loadHypotheses(teamId);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses]);

  const onSubmit = async (values) => {
    const createdBy = `${firstName} ${lastName} `;

    const auxValues = {
      ...values,
      ...{ hypothesisId: [{ value: hypothesisId }] },
    };
    if (isEditMode) {
      const editPromise = editExperiment(handleAutomaticStatus(auxValues), id);
      editPromise.then(() => {
        if (global) {
          closeModal();
        } else {
          setIsModalOpen(false);
        }
        loadHypothesis(hypothesisId);
      });

      return editPromise;
    }
    const addPromise = addExperiment(auxValues, teamId, createdBy);
    addPromise.then(() => {
      if (global) {
        closeModal();
      } else {
        setIsModalOpen(false);
      }
      loadHypothesis(hypothesisId);
      if (id) loadData(id);
    });
    return addPromise;
  };

  const handleCloseModal = () => {
    if (global) {
      closeModal();
    } else {
      setIsModalOpen(false);
    }
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {isEditMode ? 'Editează experiment' : 'Adaugă un experiment nou'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} black />
        </TitleButtonsDiv>
        <AddExperimentForm
          onSubmit={onSubmit}
          hypothesis={hypothesis}
          experiment={experiment}
          isEditMode={isEditMode}
        />
      </Modal.Body>

      <Loading isLoading={isLoading} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {
  addExperiment,
  loadHypotheses,
  editExperiment,
  loadHypothesis,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddExperimentModal)
);
