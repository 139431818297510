import React from 'react';
import { Grid } from '@mui/material';
import { IndicatorContainer } from './styled/HypothesisDetailsPage';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';
import { targetIndicatorsOptions } from './forms/HypothesisForm';

const HypothesesIndicators = ({ targetIndicators = [] }) => (
  <Grid
    sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: 0 }}
  >
    <H2>Indicatori/ valoare tinta</H2>
    {targetIndicators.map((item, index) => {
      const targetIndicator =
        targetIndicatorsOptions.find(
          (option) => option.value === item.targetIndicatorType
        ) || {};
      return (
        item.targetIndicatorType && (
          <IndicatorContainer key={index}>
            <P>{targetIndicator.label}</P>
            <P>/</P>
            <P>{item.value}</P>
          </IndicatorContainer>
        )
      );
    })}
  </Grid>
);

export default HypothesesIndicators;
