import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BOARDS_TABS } from '../../../opportunityAnalysis/constants';
import {
  StyledNavLink,
  StyledProfileNav,
} from '../../../userProfile/components/styled/ProfileTabItem';
import { Nav } from 'react-bootstrap';
import { ProfileTabSvgIcon } from '../../../common/components/styled/LinkIcon';
import { Box } from '@mui/material';
import ActivitiesComponent from '../ActivitiesComponent';
import HypothesisComponent from '../HypothesisComponent';
import DetailsComponent from '../DetailsComponent';
import IndicatorsComponent from '../IndicatorsComponent';
import ConclusionsComponent from '../ConclusionsComponent';
import TasksComponent from '../TasksComponent';
import { TitleCounterDiv } from '../../../common/components/styled';
import {
  H1,
  H2,
} from '../../../common/components/styled/ReusableTextComponents';
import { ArrowLeft } from '../../../common/assets/icons';

const pageComponents = {
  activities: {
    title: 'Activitati',
    component: <ActivitiesComponent />,
  },
  tasks: {
    title: 'Taskuri',
    component: <TasksComponent />,
  },
  hypothesis: {
    title: 'Ipoteze',
    component: <HypothesisComponent />,
  },
  details: {
    title: 'Detalii',
    component: <DetailsComponent />,
  },
  indicators: {
    title: 'Indicatori',
    component: <IndicatorsComponent />,
  },
  conclusions: {
    title: 'Concluzii',
    component: <ConclusionsComponent />,
  },
};

export const BoardsPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const tabFromQuery = query.get('tab') || 'activities';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/boards?tab=${tab}`);
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
        <Box
          onClick={() => history.goBack()}
          sx={{ cursor: 'pointer', marginRight: '10px' }}
        >
          <ArrowLeft />
        </Box>

        <H2>{`Boards | ${pageComponents[activeTab]?.title}`}</H2>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {BOARDS_TABS.map((tab, index) => (
            <Nav.Item
              id={tab?.id || tab.key}
              key={tab.key}
              style={{ position: 'relative' }}
            >
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === BOARDS_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabSvgIcon>{tab.icon}</ProfileTabSvgIcon>
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </Box>
      <Box
        sx={{
          border: '1px solid #74a3fe',
          borderRadius:
            activeTab !== 'activities' ? '10px' : '0 10px 10px 10px',
          maxWidth: '100%',
          height: '84vh',
          padding: '40px',
        }}
      >
        {pageComponents[activeTab].component}
      </Box>
    </>
  );
};
