import React from 'react';
import { AddIcon } from '../../common/assets/icons';
import { BmcButtonContainer } from '../../common/components/styled/Headings';

export const BmcAddButton = ({ onClick, disabled = false, id }) => (
  <BmcButtonContainer
    disabled={disabled}
    onClick={onClick}
    id={`button-add-${id}`}
  >
    <AddIcon width={16} height={16} fill={disabled ? '#ccc' : '#74A3FE'} />
  </BmcButtonContainer>
);
