import styled from 'styled-components';

export const VideoModalContainer = styled.div`
  position: absolute;
  top: 25vh;
  left: 28%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  z-index: 9999;
`;

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  color: red;
  width: 100px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 720px;
`;
