import { http } from '../../core/services/http';

export const getAllTargetIndicators = (teamId, hypothesisId) => {
  const url = `/targetIndicators/get-all/teams/${teamId}?filters=${JSON.stringify(
    { hypothesisId }
  )}`;
  return http.get(url);
};

export const createTargetIndicators = (payload) => {
  const url = '/targetIndicators/add-multiple';
  return http.post(url, payload);
};

export const updateTargetIndicator = (targetIndicatorId, payload) => {
  const url = `/targetIndicators/edit/${targetIndicatorId}`;
  return http.put(url, payload);
};

export const deleteTargetIndicator = (targetIndicatorId) => {
  const url = `/targetIndicators/delete/${targetIndicatorId}`;
  return http.delete(url);
};
