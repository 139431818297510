export default {
  ADMIN: 'ADMIN',
  MENTOR: 'MENTOR',
  FOUNDER: 'FOUNDER',
  ACCELERATOR: 'ACCELERATOR',
};

export const USER_ROLE_NAMES = {
  FOUNDER: 'Fondator',
  MENTOR: 'Mentor',
  ADMIN: 'Admin',
};
