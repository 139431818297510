import React from 'react';
import { Box, Grid } from '@mui/material';
import HypothesisCard from '../../canvas/components/HypothesisCard';
import { H2 } from '../../common/components/styled/ReusableTextComponents';

const HypothesesSegments = ({ segmentsHypothesis, canvasData }) => (
  <Grid
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginBottom: '20px',
      marginLeft: '50px',
    }}
  >
    <H2>Segmente vizate</H2>

    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr', // Two equal-width columns
        gap: '20px',
      }}
    >
      {segmentsHypothesis.map((item, index) => (
        <HypothesisCard
          key={index}
          sx={{
            gridColumn: (index % 2) + 1,
          }}
          customerSegment="customerSegments"
          hypothesis={item}
          canvasData={canvasData}
          allowClick
          backgroundColor={item.color}
        />
      ))}
    </Box>
  </Grid>
);

export default HypothesesSegments;
