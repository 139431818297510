import { runUpdateApiRequest } from '../api/Api';
import {
  RESET_ONBOARDING_PROGRESS,
  UPDATE_ONBOARDING_PROGRESS,
} from '../common/constants/apiRoutes';

export const updateOnboardingProgress = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_ONBOARDING_PROGRESS,
    payload: payload.stepData,
  });

export const resetOnboardingProgress = () =>
  runUpdateApiRequest({
    endpoint: RESET_ONBOARDING_PROGRESS,
  });
