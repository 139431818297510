import { Box } from '@mui/material';
import React from 'react';
import ArticlePageHeaderSection from './ArticlePageHeaderSection';
import Article from './Article';
import { P } from '../../common/components/styled/ReusableTextComponents';

const EmptyArticleList = () => (
  <Box sx={{ width: '70%', marginTop: '10px' }}>
    <P faded>
      Adauga un articol facand click pe butonul din dreapta sau verifica
      filtrele...
    </P>
  </Box>
);

const ArticlesList = ({
  toggleFiltersModal,
  createNewArticle,
  articles = [],
  toggleValue,
  handleToggleValue,
  handleEditArticle,
  filters,
  handleDeleteArticle,
}) => {
  const filtersNumber =
    filters.categories.length +
    filters.visibility.filter((item) => item !== 'all').length;

  return (
    <>
      <ArticlePageHeaderSection
        handleFiltersModalStatus={toggleFiltersModal}
        hasFilters
        filtersNumber={filtersNumber}
        toggleValue={toggleValue}
        handleToggleValue={handleToggleValue}
        onAddClick={createNewArticle}
        headerTitle="Articole"
      />
      {!articles.length ? (
        <EmptyArticleList />
      ) : (
        <Box
          className="scrollbar"
          sx={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '81vh',
            paddingBottom: '20px',
            width: '100%',
          }}
        >
          {articles.map(({ _id: id, categoryId, title, isVisible }, index) => (
            <Article
              key={index}
              order={index + 1}
              categoryName={categoryId ? categoryId.name : ''}
              isVisible={isVisible}
              title={title}
              handleEdit={(e) => handleEditArticle(id, e)}
              handleDelete={(e) => handleDeleteArticle(id, e)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default ArticlesList;
