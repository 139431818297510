/* eslint-disable camelcase */
import { get } from 'lodash-es';
import { http } from '../../core/services/http';
import { createSession, destroySession } from './session';
import transformLogin from './transformLogin';

const getCurrentUserInfo = (userId, resolve) =>
  window.FB.api(
    `/${userId}`,
    {
      fields: 'id, email, first_name, last_name, picture',
    },
    async (res) => {
      const { id, email, first_name, last_name, picture } = res;
      const response = await http.post('account/facebook-login', {
        facebookId: id,
        email,
        firstName: first_name,
        lastName: last_name,
        profilePicture: get(picture, 'data.url'),
      });
      const user = transformLogin(response.data);
      createSession(response.headers.authtoken, user);
      resolve(user);
    }
  );

export const handleFacebookAuth = () =>
  new Promise((resolve, reject) =>
    window.FB.login(
      ({ authResponse, status }) => {
        if (status === 'connected') {
          return getCurrentUserInfo(authResponse.userID, resolve);
        }

        return reject();
      },
      { scope: 'email' }
    )
  );

export const handleFacebookLogout = () => {
  window.FB.logout();
  destroySession();
};
