import React, { useState } from 'react';
import { map, filter, size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PopoverContent from 'react-bootstrap/PopoverContent';
import PopoverTitle from 'react-bootstrap/PopoverTitle';
import {
  NoResultsFound,
  BusinessModelCanvasColumn,
  BusinessModelCanvasRow,
  HypothesisCardContainer,
  HypothesisTitle,
  HypothesisCardsContainer,
  HypothesisCardIconsContainer,
} from '../../../canvas/components/styled';

import {
  isMentorSelector,
  isAcceleratorSelector,
} from '../../../account/ducks';
import { ReactComponent as ToDoIcon } from '../../../common/assets/icons/to-do.svg';
import { ReactComponent as EarningsIcon } from '../../../common/assets/icons/castiguri.svg';
import { ReactComponent as PainsIcon } from '../../../common/assets/icons/dureri.svg';
import { ReactComponent as ValidIcon } from '../../../common/assets/icons/Ipoteza-Valida_S.svg';
import { ReactComponent as InvalidIcon } from '../../../common/assets/icons/Ipoteza-Invalida_S.svg';
import { IconLabelDiv } from '../../../team/components/styled';

import { EmpathyMapModal } from '../modals';
import {
  Heading2,
  Heading1,
  Heading4Regular,
  Heading3Semibold,
  ColDiv,
  Title,
  AddCommentButton,
  RowDiv,
} from '../../../common/components/styled';

import { TitleButtonsDiv } from '../../../mentors/components/styled';

const EmpathyMapPage = (props) => {
  const { hypothesis, isMentor, isAccelerator } = props;
  const [isModalOpenEarnings, setIsModalOpenEarnings] = useState(false);
  const [isModalOpenPains, setIsModalOpenPains] = useState(false);
  const [isModalOpenGains, setIsModalOpenGains] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [item, setItem] = useState('');

  const handleHookSet = (type, item, editMode) => {
    setItem(item || '');
    setIsEditMode(editMode || false);
    switch (type) {
      case 'pains':
        setIsModalOpenPains(true);
        break;
      case 'gains':
        setIsModalOpenGains(true);
        break;

      case 'earnings':
        setIsModalOpenEarnings(true);
        break;

      default:
        setIsModalOpenGains(false);
        setIsModalOpenEarnings(false);
        setIsModalOpenPains(false);
    }
  };

  const sortedItems = (array) => {
    const sortedArray = [];
    const inProgress = filter(array, (el) => el.outcome === 'inProgress');
    const validated = filter(array, (el) => el.outcome === 'validated');
    const invalidated = filter(array, (el) => el.outcome === 'invalidated');
    sortedArray.push(inProgress);
    sortedArray.push(validated);
    sortedArray.push(invalidated);
    return sortedArray.flat();
  };

  const getIcon = (outcome) => {
    switch (outcome) {
      case 'invalidated':
        return <InvalidIcon />;
      case 'validated':
        return <ValidIcon />;
      default:
        return '';
    }
  };

  return (
    <>
      <TitleButtonsDiv marginBottom="38px">
        <Title>{`CS : ${hypothesis.title} `}</Title>
      </TitleButtonsDiv>
      <RowDiv>
        <ColDiv width="60%">
          <BusinessModelCanvasRow>
            <BusinessModelCanvasColumn empathy>
              <TitleButtonsDiv marginBottom="20px">
                <IconLabelDiv>
                  <ToDoIcon />
                  <Heading2 marginLeft="12px">Sarcini</Heading2>
                </IconLabelDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton onClick={() => handleHookSet('earnings')} />
                )}
              </TitleButtonsDiv>

              {!size(hypothesis.earnings) ? (
                <NoResultsFound>Nu există sarcini</NoResultsFound>
              ) : (
                <HypothesisCardsContainer>
                  {map(sortedItems(hypothesis.earnings), (item) => (
                    <HypothesisCardContainer
                      empathy
                      key={item._id}
                      onClick={() => handleHookSet('earnings', item, true)}
                    >
                      <div style={{ display: 'flex', width: '100%' }}>
                        <HypothesisTitle
                          decorationThrough={item.outcome === 'invalidated'}
                        >
                          {item.title}
                        </HypothesisTitle>
                        <HypothesisCardIconsContainer>
                          {getIcon(item.outcome)}
                        </HypothesisCardIconsContainer>
                      </div>
                    </HypothesisCardContainer>
                  ))}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft empathy>
              <TitleButtonsDiv marginBottom="20px">
                <IconLabelDiv>
                  <PainsIcon />
                  <Heading2 marginLeft="12px">Dureri</Heading2>
                </IconLabelDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton onClick={() => handleHookSet('pains')} />
                )}
              </TitleButtonsDiv>
              {!size(hypothesis.pains) ? (
                <NoResultsFound>Nu există dureri</NoResultsFound>
              ) : (
                <HypothesisCardsContainer>
                  {map(sortedItems(hypothesis.pains), (item) => (
                    <HypothesisCardContainer
                      empathy
                      key={item._id}
                      onClick={() => handleHookSet('pains', item, true)}
                    >
                      <div style={{ display: 'flex', width: '100%' }}>
                        <HypothesisTitle
                          decorationThrough={item.outcome === 'invalidated'}
                        >
                          {item.title}
                        </HypothesisTitle>
                        <HypothesisCardIconsContainer>
                          {getIcon(item.outcome)}
                        </HypothesisCardIconsContainer>
                      </div>
                    </HypothesisCardContainer>
                  ))}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
            <BusinessModelCanvasColumn borderLeft empathy>
              <TitleButtonsDiv marginBottom="20px">
                <IconLabelDiv>
                  <EarningsIcon />
                  <Heading2 marginLeft="12px">Castiguri</Heading2>
                </IconLabelDiv>
                {!isMentor && !isAccelerator && (
                  <AddCommentButton onClick={() => handleHookSet('gains')} />
                )}
              </TitleButtonsDiv>

              {!size(hypothesis.gains) ? (
                <NoResultsFound>Nu există câștiguri</NoResultsFound>
              ) : (
                <HypothesisCardsContainer>
                  {map(sortedItems(hypothesis.gains), (item) => (
                    <HypothesisCardContainer
                      empathy
                      key={item._id}
                      onClick={() => handleHookSet('gains', item, true)}
                    >
                      <div style={{ display: 'flex', width: '100%' }}>
                        <HypothesisTitle
                          decorationThrough={item.outcome === 'invalidated'}
                        >
                          {item.title}
                        </HypothesisTitle>
                        <HypothesisCardIconsContainer>
                          {getIcon(item.outcome)}
                        </HypothesisCardIconsContainer>
                      </div>
                    </HypothesisCardContainer>
                  ))}
                </HypothesisCardsContainer>
              )}
            </BusinessModelCanvasColumn>
          </BusinessModelCanvasRow>
        </ColDiv>
        <ColDiv width="35%">
          <div className="empathyMap-outer">
            <ul className="empathyMap">
              <li className="empathyMap-section">
                <div className="content first">
                  <OverlayTrigger
                    trigger={['hover', 'hover']}
                    placement="right"
                    overlay={
                      <Popover id="popover-basic">
                        <PopoverTitle>
                          <Heading1>Câștiguri</Heading1>
                        </PopoverTitle>
                        <PopoverContent>
                          <Heading2 marginBottom="10px">
                            Câștigurile descriu rezultatele si beneficiile pe
                            care și le doresc clienții tăi. Unele câștiguri sunt
                            cerute, așteptate sau dorite de clienți, iar uneleți
                            vor surprinde. Câștigurile includ funcționalități,
                            emoții pozitive, câștiguri sociale si economii.
                          </Heading2>
                          <Heading3Semibold marginBottom="10px">
                            Folosește următoarele întrebări stimulente pentru a
                            te ajuta să te gândești la modurile diferite în care
                            clienții pot să câștige de pe urma produsului sau
                            serviciului tău:
                          </Heading3Semibold>

                          <Heading4Regular noEllipsis marginBottom="10px">
                            1. Ce economii ar face clienții tăi fericiți?
                            Gândește-te în termeni de timp, bani și efort?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            2. Ce nivel de calitate se așteaptă să primească și
                            ce și-ar dori mai mult sau mai puțin din produsul
                            sau serviciul tău?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            3. Cum mulțumește actualul tău produs sau serviciu
                            clienții? Ce atribute sau caracteristici specifice
                            apreciază foarte mult? Ce performanțe și calitated
                            așteaptă?
                          </Heading4Regular>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <EarningsIcon style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                </div>
                <div className="background" />
              </li>
              <li className="empathyMap-section">
                <div className="content second">
                  <OverlayTrigger
                    trigger={['hover', 'hover']}
                    placement="right"
                    overlay={
                      <Popover id="popover-basic">
                        <PopoverTitle>
                          <Heading1>Sarcini</Heading1>
                        </PopoverTitle>
                        <PopoverContent>
                          <Heading2 marginBottom="10px">
                            Sarcinile descriu lucrurile pe care clienții tăi
                            trebuie să le rezolve fi în viața personală, fie în
                            viața profesională. O sarcină poate să fie o
                            activitate, problemă sau nevoie pe care încearcă să
                            o rezolve, finalizeze sau să o satisfacă.
                          </Heading2>
                          <Heading3Semibold marginBottom="10px">
                            Folosește întrebările stimulente de mai jos ca să te
                            ajute să te gândești la diferitele sarcini ale
                            clienților tăi potențiali:
                          </Heading3Semibold>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            1. Care este acel lucru fără de care clientul nu ar
                            putea să trăiască dacă nu l-ar realiza? Care sunt
                            pașii care ar putea să ajute clientul să realizeze
                            această sarcină importantă?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            2. Care este contextul în care s-ar putea afla
                            clientul tău? Cum s-ar putea schimba obiectivele sau
                            activitățile sale în funcție de aceste contexte
                            diferite?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            3. Ce trebuie să realizeze clientul tău și implică
                            interacțiunea cu alte persoane?
                          </Heading4Regular>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <ToDoIcon style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                </div>
                <div className="background" />
              </li>
              <li className="empathyMap-section">
                <div className="content third">
                  <OverlayTrigger
                    trigger={['hover', 'hover']}
                    placement="right"
                    overlay={
                      <Popover id="popover-basic">
                        <PopoverTitle>
                          <Heading1>Dureri</Heading1>
                        </PopoverTitle>
                        <PopoverContent>
                          <Heading2 marginBottom="10px">
                            Durerile descriu orice lucru care supără sau
                            enervează clienții înainte, pe durata și după ce
                            încearcă să rezolve o sarcină sau activitate sau pur
                            și simplu îi împiedică să facă ce își doresc.
                            Durerile includ riscuri, rezultate negative asociate
                            unei sarcini care iese rău parțial sau complet.
                          </Heading2>
                          <Heading3Semibold marginBottom="10px">
                            Folosește întrebările stimulente de mai jos ca să te
                            ajute să te gândești la diferitele dureri ale
                            clienților:
                          </Heading3Semibold>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            1. Cum definesc clienții „prea costisitor“? I-a prea
                            mult timp, implică prea mulți bani, necesită un
                            efort prea mare?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            2. Ce face clienții să se simtă rău? Care le sunt
                            frustrările, supărările sau alte lucruri care le dau
                            bătăi de cap?
                          </Heading4Regular>
                          <Heading4Regular noEllipsis marginBottom="10px">
                            3. Cum îi nemulțumesc produsele sau serviciile
                            actuale? Ce caracteristici le lipsesc? Exista
                            probleme de performanță care îi enervează sau îi
                            împiedică să recomande produsul sau serviciul?
                          </Heading4Regular>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <PainsIcon style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                </div>
                <div className="background" />
              </li>
            </ul>
          </div>
        </ColDiv>
      </RowDiv>

      <EmpathyMapModal
        isModalOpen={isModalOpenGains}
        setIsModalOpen={setIsModalOpenGains}
        name="gains"
        formLabel="Ce castiguri sau beneficii se asteapta clientul tau sa obtina?"
        iconsFormLabel="Ai dovezi ca solutia ta ii aduce acest beneficiu?"
        modalLabel="castig"
        hypothesisId={hypothesis._id}
        item={item}
        isEditMode={isEditMode}
      />
      <EmpathyMapModal
        isModalOpen={isModalOpenEarnings}
        setIsModalOpen={setIsModalOpenEarnings}
        name="earnings"
        formLabel="Ce sarcina ar avea clinetul tau de rezolvat?"
        modalLabel="sarcina"
        iconsFormLabel="Ai dovezi ca solutia ta satisface aceasta sarcina?"
        hypothesisId={hypothesis._id}
        item={item}
        isEditMode={isEditMode}
      />
      <EmpathyMapModal
        isModalOpen={isModalOpenPains}
        setIsModalOpen={setIsModalOpenPains}
        name="pains"
        modalLabel="durere"
        formLabel="Ce durere ar avea clientul tau legat de"
        iconsFormLabel="Ai dovezi ca solutia ta inlatura aceasta durere?"
        hypothesisId={hypothesis._id}
        item={item}
        isEditMode={isEditMode}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesis: state.hypotheses.hypothesis,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmpathyMapPage)
);
