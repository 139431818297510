import { Box, CircularProgress, IconButton } from '@mui/material';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import {
  CrossIcon,
  EditIcon,
  TrashIconOutlined,
} from '../../../common/assets/icons';
import RichTextContainer from '../../../common/components/RichTextContainer';
import { SpaceBetweenBox } from '../../../common/components/styled/ReusablePositioningComponents';
import {
  ActivitiesDrawerButtonsContainer,
  ScrollableDrawerContent,
} from '../styled/ActivitiesDrawerStyled';
import DrawerTasks from './DrawerTasks';
import DrawerConclusions from './DrawerConclusions';
import React from 'react';

const ActivitiesDrawerContent = ({
  handleCloseDrawer,
  activity,
  totalCost,
  isLoadingResources,
  tasks,
  conclusions,
  handleEdit,
  handleDelete,
  responsable,
  setIsAddTaskModalOpen,
  handleToggleTask,
  setIsAddConclusionModalOpen,
  setPreviewedTask,
  setTaskDetailModalId,
}) => (
  <ScrollableDrawerContent className="scrollbar">
    <Box
      className="display-flex align-items-center justify-content-space-between"
      sx={{
        marginBottom: '20px',
      }}
    >
      <H3>Vizualizare activitate</H3>
      <IconButton onClick={handleCloseDrawer}>
        <CrossIcon fill="blue" />
      </IconButton>
    </Box>

    <Box>
      <P faded>
        <i>Titlu</i>
      </P>
      <P>{activity?.title}</P>

      <P marginTop="10px" faded>
        <i>Criteriu de succes</i>
      </P>
      <P marginBottom="20px">
        <RichTextContainer text={activity?.successCriteria} />
      </P>

      <SpaceBetweenBox>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <P faded>
            <i>Responsabil</i>
          </P>
          <P>{responsable?.label || ''}</P>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '25px',
          }}
        >
          <P faded>
            <i>Cost total</i>
          </P>
          <P>${totalCost}</P>
        </Box>
      </SpaceBetweenBox>

      <ActivitiesDrawerButtonsContainer>
        <PrimaryButton
          marginRight="10px"
          inverted
          icon={EditIcon}
          onClick={handleEdit}
        >
          Editeaza
        </PrimaryButton>
        <SecondaryButton
          onClick={handleDelete}
          icon={TrashIconOutlined}
          iconColor="white"
        >
          Sterge
        </SecondaryButton>
      </ActivitiesDrawerButtonsContainer>

      {isLoadingResources ? (
        <CircularProgress />
      ) : (
        <>
          <DrawerTasks
            tasks={tasks}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            handleToggleTask={handleToggleTask}
            setPreviewedTask={setPreviewedTask}
            setTaskDetailModalId={setTaskDetailModalId}
          />

          <DrawerConclusions
            setIsAddConclusionModalOpen={setIsAddConclusionModalOpen}
            conclusions={conclusions}
          />
        </>
      )}
    </Box>
  </ScrollableDrawerContent>
);

export default ActivitiesDrawerContent;
