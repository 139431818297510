// eslint-disable-next-line no-unused-vars
import Joyride, { STATUS } from 'react-joyride';
import React, { useContext, useEffect, useState } from 'react';
import TourContext from '../../context/tourContext';
import { useHistory } from 'react-router-dom';
import { PLATFORM_TOURS } from '../utils';
import { joyRideStyleOverride } from '../constants';
import { useApi } from '../../hooks/useApi';
import { updateOnboardingProgress } from '../../requests/onboarding';

const JoyRideTour = () => {
  const { runTour, stopTour, tourName, currentStepIndex, setCurrentStep } =
    useContext(TourContext);
  const [, runUpdateTourProgress] = useApi(updateOnboardingProgress);

  const activeTour = PLATFORM_TOURS[tourName];
  const [isTourActive, setIsTourActive] = useState(true);
  const { push } = useHistory();

  useEffect(() => {
    setIsTourActive(true);
  }, [activeTour]);

  const callBack = (data) => {
    activeTour?.callback({
      data,
      setIsTourActive,
      stopTour,
      push,
      setCurrentStep,
      runUpdateTourProgress,
    });
  };

  return (
    <Joyride
      callback={callBack}
      continuous
      spotlightClicks
      disableOverlayClose
      hideBackButton
      stepIndex={currentStepIndex}
      steps={activeTour?.steps}
      run={runTour && isTourActive}
      locale={{
        next: 'Mai departe ➔',
        last: 'Incheie turul',
      }}
      styles={joyRideStyleOverride}
    />
  );
};

export default JoyRideTour;
