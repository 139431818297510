import { Box, Drawer, IconButton } from '@mui/material';
import React from 'react';
import { get, size } from 'lodash-es';
import { useHistory } from 'react-router';
import {
  CrossIcon,
  ExperimentVialIcon,
  LineAndBarsIcon,
  PaperClipIcon,
  PersonCircleIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '../../common/assets/icons';
import { H3, P } from '../../common/components/styled/ReusableTextComponents';
import { HypothesisColor } from './styled';
import { BMC_OPTIONS } from '../constants';

import { SegmentContainer, SegmentTitle } from './styled/HypothesisCard';
import { SpaceBetweenBox } from '../../common/components/styled/ReusablePositioningComponents';
import { ClampedText } from '../../common/components/ClampedText';

const ResolutionBox = ({ resolutionLength, IconComponent }) =>
  !!resolutionLength && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #74A3FE',
        padding: '1px 3px',
        borderRadius: '4px',
        marginLeft: '5px',
      }}
    >
      <IconComponent fill="#74A3FE" />
      <P marginLeft="5px">{resolutionLength}</P>
    </Box>
  );

const SegmentIdea = ({ segment, getColor }) => {
  const history = useHistory();

  const {
    resolutions = [],
    experiments = [],
    KPIIndicators = [],
    clientProfile = null,
  } = segment;

  const colors = getColor(get(segment, 'segment'));

  const positiveResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'validatedWithMVP' ||
      resolution.outcome === 'validatedWithoutMVP'
  );

  const negativeResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'invalidatedWithMVP' ||
      resolution.outcome === 'invalidatedWithoutMVP'
  );

  const hypothesisDetails = size(segment.details) > 0 && (
    <Box sx={{ marginLeft: '5px' }}>
      <PaperClipIcon fill="#74A3FE" />
    </Box>
  );

  const KPIIndicatorsBox = size(KPIIndicators) > 0 && (
    <Box sx={{ marginLeft: '5px' }}>
      <LineAndBarsIcon fill="#74A3FE" />
    </Box>
  );

  const clientProfileBox = clientProfile && (
    <Box sx={{ marginLeft: '5px' }}>
      <PersonCircleIcon fill="#74A3FE" />
    </Box>
  );

  const experimentBox = size(experiments) > 0 && (
    <ResolutionBox
      resolutionLength={experiments.length}
      IconComponent={ExperimentVialIcon}
    />
  );

  const handleNavigateToSegment = () => {
    history.push(`/hypothesis/${segment._id}?tab=description`);
  };

  return (
    <SegmentContainer>
      <SegmentTitle onClick={handleNavigateToSegment}>
        <ClampedText text={segment.title} />
      </SegmentTitle>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
            {colors.map((color, idx) => (
              <HypothesisColor
                marginBottom="0px"
                key={idx}
                backgroundColor={color}
              />
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '3px',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {clientProfileBox}
            {hypothesisDetails}
            {KPIIndicatorsBox}
            {experimentBox}
            <ResolutionBox
              resolutionLength={positiveResolutions.length}
              IconComponent={ThumbsUpIcon}
            />
            <ResolutionBox
              resolutionLength={negativeResolutions.length}
              IconComponent={ThumbsDownIcon}
            />
          </div>
        </div>
      </Box>
    </SegmentContainer>
  );
};

export const BmcSegmentDrawer = ({
  isDrawerOpen,
  setIsOpen,
  selectedSegment = { items: [], column: '' },
  getColor,
}) => {
  let title = '';
  if (selectedSegment.items.length > 0) {
    title = BMC_OPTIONS.find(
      (option) => option.value === selectedSegment.column
    ).label;
  }

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box sx={{ width: '400px', padding: '20px' }}>
        {!!selectedSegment.items.length && (
          <Box>
            <SpaceBetweenBox marginBottom="10px">
              <H3>{title}</H3>
              <IconButton onClick={handleCloseDrawer}>
                <CrossIcon fill="blue" />
              </IconButton>
            </SpaceBetweenBox>

            <Box
              sx={{
                marginBottom: '20px',
                marginTop: '5px',
                borderBottom: '1px solid #B1CCFE',
              }}
            />

            {selectedSegment.items.map((item, idx) => (
              <SegmentIdea key={idx} segment={item} getColor={getColor} />
            ))}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
