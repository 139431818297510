import { Box, Drawer, IconButton } from '@mui/material';
import React from 'react';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import {
  CrossIcon,
  PencilIcon,
  TrashIconOutlined,
  UpwardsTrend,
} from '../../common/assets/icons';
import RichTextContainer from '../../common/components/RichTextContainer';

export const TrendsDrawer = ({
  isDrawerOpen,
  selectedTrend,
  handleCloseDrawer,
  handleEditTrend,
  handleDeleteTrend,
}) => (
  <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
    <Box sx={{ width: '400px', padding: '20px' }}>
      {selectedTrend && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <H3>Vizualizare trend</H3>
            <IconButton onClick={handleCloseDrawer}>
              <CrossIcon fill="blue" />
            </IconButton>
          </Box>
          <P marginBottom="20px">
            <b>Titlu:</b> {selectedTrend.title}{' '}
          </P>
          <Box mb={2}>
            <P>
              <b>Descriere:</b>
            </P>
            <RichTextContainer text={selectedTrend.description}>
              <b>Descriere:</b> {selectedTrend.description}
            </RichTextContainer>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid #74A3FE',
              color: '#74A3FE',
              borderRadius: '4px',
              padding: '18.5px 14px',
              marginTop: '20px',
            }}
          >
            <P>
              {selectedTrend.isTrendFavorable
                ? 'Trend favorabil'
                : 'Trend nefavorabil'}
            </P>
            {selectedTrend.isTrendFavorable ? (
              <UpwardsTrend fill="green" />
            ) : (
              <Box
                sx={{
                  transform: 'scaleY(-1)',
                }}
              >
                <UpwardsTrend fill="red" />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <PrimaryButton
              onClick={() => handleEditTrend(selectedTrend)}
              inverted
              icon={PencilIcon}
              iconColor="#74A3FE"
              marginRight="10px"
            >
              Edit
            </PrimaryButton>
            <SecondaryButton
              onClick={() => handleDeleteTrend(selectedTrend._id)}
              icon={TrashIconOutlined}
              iconColor="#fff"
            >
              Sterge
            </SecondaryButton>
          </Box>
        </>
      )}
    </Box>
  </Drawer>
);
