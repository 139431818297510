import styled, { css } from 'styled-components';
import { Box, TableCell } from '@mui/material';
import { P } from '../../../common/components/styled/ReusableTextComponents';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #74a3fe;
  color: #74a3fe;
  border-radius: 4px;
  height: 32px;
  padding: 0 15px;
  margin-right: 10px;
  cursor: pointer;
`;

export const AddButton = styled.div`
  color: #fff;
  background: #74a3fe;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #2a5ab7;
  }
`;

export const TrendCard = styled(Box)`
  border: 1px solid #74a3fe;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  &:hover > :first-child > :last-child {
    opacity: 1;
  }
`;

export const TrendTitle = styled(P)`
  margin-left: 10px;
  word-break: break-all;
`;

export const PageContainer = styled.div`
  height: 82vh;
  padding: 40px 40px;
  overflow-y: scroll;
`;

export const SwotCardContainer = styled.div`
  height: 100%;
  border: 1px solid #b1ccfe;
  border-radius: 4px;
  overflow-y: scroll;
  padding: 0 20px 20px 20px;
`;

export const ChartBackgroundColumn = styled.div`
  border: 2px solid #74a3fe;
  height: 100%;
  border-radius: 4px;
  opacity: 0.5;
  position: relative;
`;

export const ChartBackgroundColumnPaddedBox = styled.div`
  position: absolute;
  bottom: -30px;
  color: #3498db;
`;

export const HeaderCellStyles = css`
  background-color: rgba(177, 204, 254, 0.2);
  color: #2a5ab7;
`;

const shouldNotForward = ['isHeader'];
export const DefaultCell = styled(TableCell).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !shouldNotForward.includes(prop) && defaultValidatorFn(prop),
})`
  color: #2a5ab7 !important;
  padding: 0 !important;
  ${(props) => props.isHeader && HeaderCellStyles};
`;

export const IndustriesPageContainer = styled.div`
  height: 85.5vh;
  padding: 40px 40px 20px 40px;
`;
