import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { map, size, find, get } from 'lodash-es';
import moment from 'moment';
import { isMentorSelector, isAcceleratorSelector } from '../../account/ducks';
import {
  Heading1,
  TextInactive,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  Loading,
  PlaceHolderInput,
  DeleteButton,
  AddCommentButton,
  DeleteCommentButton,
  RightSection,
  LeftSection,
  WhiteCard,
  EditButtonLarge,
  RowDiv,
} from '../../common/components/styled';
import EditIconLarge from '../../common/assets/icons/Button_Edit_Large.svg';
import EditIconLargeHover from '../../common/assets/icons/Button_Edit_Large_Hover.svg';
import { AddResolutionModal } from '../components/modals';
import {
  loadResolution,
  editResolution,
  deleteResolution,
  addResolutionComment,
  editResolutionComment,
  deleteResolutionComment,
  editResolutionCommentReply,
  deleteResolutionCommentReply,
  addResolutionCommentReply,
} from '../ducks';
import { outcomeOptions } from '../constants/resolutions';

import {
  AcceptButton,
  TitleButtonsDiv,
  DivBorderBottom,
  ButtonText,
} from '../../mentors/components/styled';
import { AddCommentModal } from '../../common/components/modals';
import { CommentRenderComponent } from '../../common/components';
import { handleBmcLabels } from '../../hypotheses/components/pages/HypothesisDetailsPageLegacy';
import { sizeComments } from '../../common/functions';
import { useHistory } from 'react-router-dom';

const AlertMessage = ({ show, setShow, onSubmit }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit();
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere concluzie</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea concluziei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HypothesisResolutionPage = (props) => {
  const {
    resolution,
    isMentor,
    isAccelerator,
    loadResolution,
    editResolution,
    addResolutionComment,
    editResolutionComment,
    isCreating,
    isEditing,
    deleteResolution,
    isLoading,
    isDeleting,
    deleteResolutionComment,
    editResolutionCommentReply,
    deleteResolutionCommentReply,
    addResolutionCommentReply,
    match: {
      params: { hypothesisId, resolutionId },
    },
  } = props;

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isEditModeComment, setIsEditModeComment] = useState(false);
  const [comment, setComment] = useState(undefined);
  const [hovered, setHovered] = useState(false);
  const { push } = useHistory();
  const handleHookSet = (type) => {
    switch (type) {
      case 'resolution':
        setIsResolutionModalOpen(true);
        break;
      case 'comment':
        setIsCommentModalOpen(true);
        break;
      default:
        setIsResolutionModalOpen(false);
        setIsCommentModalOpen(false);
    }
  };

  const handleOpenEditCommentModal = (type, commentId) => {
    handleHookSet(type);
    setComment(commentId);
    setIsEditModeComment(true);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteResolutionComment(commentId);
    await loadResolution(resolutionId);
  };

  const handleCardClick = (url) => push(url);

  const handleOutcomeValue = (outcome) =>
    get(
      find(outcomeOptions, {
        value: outcome,
      }),
      'label'
    );

  const handleOutcomeIcon = (outcome) =>
    get(
      find(outcomeOptions, {
        value: outcome,
      }),
      'icon'
    );

  const handleDeleteResolution = async () => {
    await deleteResolution(resolution._id);
    push(`/hypothesis/${hypothesisId}`);
  };

  return (
    <>
      <div>
        <TitleButtonsDiv marginBottom="15px">
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <img
              alt=""
              src={handleOutcomeIcon(resolution.outcome)}
              style={{
                marginRight: '5px',
                width:
                  resolution.outcome === 'validatedWithoutMVP'
                    ? '26px'
                    : '28px',
                height:
                  resolution.outcome === 'validatedWithoutMVP'
                    ? '26px'
                    : '28px',
              }}
            />
            <Title width="80%">{resolution.insightName} </Title>
          </div>
          <div style={{ display: 'flex' }}>
            {!isMentor && !isAccelerator && (
              <>
                <EditButtonLarge
                  src={hovered ? EditIconLargeHover : EditIconLarge}
                  onClick={() => handleHookSet('resolution')}
                  onMouseEnter={() => setHovered(!hovered)}
                  onMouseLeave={() => setHovered(!hovered)}
                />
                <DeleteButton onClick={() => setOpenAlertMessage(true)} />
              </>
            )}
          </div>
        </TitleButtonsDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '40px',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Heading1>Ipoteze:</Heading1>
            {map(resolution.hypothesisId, (hypothesis, index) => (
              <PlaceHolderInput
                key={index}
                hypothesisCard
                maxWidth="100%"
                onClick={() => handleCardClick(`/hypothesis/${hypothesis._id}`)}
              >
                <Heading4Regular maxWidth="40vw">
                  {`${handleBmcLabels(hypothesis.columnBMC)}: ${
                    hypothesis.title
                  }`}
                </Heading4Regular>
              </PlaceHolderInput>
            ))}
          </div>
          <Heading4Regular inactive>{`${resolution.personResponsible} ${moment(
            resolution.createdAt
          ).format('DD/MM/YYYY  H:mm')}`}</Heading4Regular>
        </div>
      </div>
      <RowDiv>
        <LeftSection>
          <WhiteCard height="auto" borderRadius="3px" padding="20px">
            <Title marginBottom="20px" fontSize="24px" lineHeight="28px">
              Rezoluție ipoteză
            </Title>
            <Heading2 marginBottom="10px">
              În baza datelor am concluzionat:
            </Heading2>
            <Heading4Regular marginBottom="30px" textArea noEllipsis>
              {resolution.fromThat}
            </Heading4Regular>
            <Heading2 marginBottom="10px">Prin urmare vom:</Heading2>
            <Heading4Regular marginBottom="30px" textArea noEllipsis>
              {resolution.therefore}
            </Heading4Regular>
            <Heading2 marginBottom="10px">Astfel, considerăm ipoteza:</Heading2>
            <div style={{ width: '30%', display: 'flex' }}>
              <img
                alt=""
                src={handleOutcomeIcon(resolution.outcome)}
                style={{
                  marginRight: '5px',
                  width:
                    resolution.outcome === 'validatedWithoutMVP'
                      ? '26px'
                      : '28px',
                  height:
                    resolution.outcome === 'validatedWithoutMVP'
                      ? '26px'
                      : '28px',
                }}
              />
              <Heading4Regular style={{ marginTop: '3px' }}>
                {handleOutcomeValue(resolution.outcome)}
              </Heading4Regular>
            </div>
          </WhiteCard>
        </LeftSection>

        <RightSection>
          <WhiteCard
            noOverflow
            height="100%"
            borderRadius="3px"
            padding="20px"
            style={{ height: '413px' }}
          >
            <TitleButtonsDiv marginBottom="39px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title fontSize="24px" lineHeight="28px">
                  Comentarii
                </Title>
                <CounterDiv>
                  <CounterText>{sizeComments(resolution.comments)}</CounterText>
                </CounterDiv>
              </div>
              <AddCommentButton onClick={() => handleHookSet('comment')} />
            </TitleButtonsDiv>
            {!size(resolution.comments) > 0 && (
              <TextInactive>
                Momentan nu există niciun comentariu pentru această secțiune.
                Poți adăuga un comentariu făcând click pe butonul „+” din partea
                dreaptă a secțiunii.
              </TextInactive>
            )}
            <div
              style={{
                maxHeight: '85%',
                overflow: 'auto',
                paddingRight: '4px',
              }}
              className="scrollbar-modal"
            >
              {map(resolution.comments, (comment, index) => (
                <CommentRenderComponent
                  key={index}
                  comment={comment}
                  handleDeleteComment={handleDeleteComment}
                  handleEdit={() =>
                    handleOpenEditCommentModal('comment', comment)
                  }
                  handleAddReply={addResolutionCommentReply}
                  handleEditReply={(values, replyId) =>
                    editResolutionCommentReply(values, comment._id, replyId)
                  }
                  handleDeleteReply={(replyId) =>
                    deleteResolutionCommentReply(comment._id, replyId)
                  }
                  loadData={() => loadResolution(resolutionId)}
                  isLoading={isEditModeComment ? isEditing : isCreating}
                />
              ))}
            </div>
          </WhiteCard>
        </RightSection>
      </RowDiv>

      <AddResolutionModal
        isModalOpen={isResolutionModalOpen}
        setIsModalOpen={setIsResolutionModalOpen}
        isLoading={isEditing}
        isHypothesis
        loadData={() => loadResolution(resolutionId)}
        entityId={resolution._id}
        resolution={resolution}
        onSubmitResolution={editResolution}
        isEditingResolution
      />

      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteResolution}
      />
      <AddCommentModal
        isModalOpen={isCommentModalOpen}
        setIsModalOpen={setIsCommentModalOpen}
        parentId={resolution._id}
        onSubmit={addResolutionComment}
        isLoading={isEditModeComment ? isEditing : isCreating}
        loadData={() => loadResolution(resolutionId)}
        isEditModeComment={isEditModeComment}
        comment={isEditModeComment ? comment : undefined}
        setIsEditModeComment={setIsEditModeComment}
        editComment={editResolutionComment}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.resolution.isDeleting,
  isCreating: state.resolution.isCreating,
  isEditing: state.resolution.isEditing,
  isLoading: state.resolution.isLoading,
  resolution: state.resolution.resolution,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {
  loadResolution,
  editResolution,
  deleteResolution,
  addResolutionComment,
  editResolutionComment,
  deleteResolutionComment,
  editResolutionCommentReply,
  deleteResolutionCommentReply,
  addResolutionCommentReply,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HypothesisResolutionPage)
);
