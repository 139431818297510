import update from 'immutability-helper';
import {
  addOpportunityAnalysisComment as doAddOpportunityAnalysisComment,
  deleteOpportunityAnalysisComment as doDeleteOpportunityAnalysisComment,
  editOpportunityAnalysisComment as doEditOpportunityAnalysisComment,
  deleteOpportunityAnalysis as doDeleteOpportunityAnalysis,
  addOpportunityAnalysis as doAddOpportunityAnalysis,
  editOpportunityAnalysis as doEditOpportunityAnalysis,
  loadOpportunityAnalysis as doLoadOpportunityAnalysis,
  addOpportunityAnalysisReply as doAddOpportunityAnalysisReply,
  editOpportunityAnalysisReply as doEditOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply as doDeleteOpportunityAnalysisReply,
} from '../services/opportunityAnalysis';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';

// Actions

const START_ADD_COMMENT = 'opportunityAlalysis/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'opportunityAlalysis/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'opportunityAlalysis/FAIL_ADD_COMMENT';

const START_DELETE_COMMENT = 'opportunityAlalysis/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'opportunityAlalysis/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'opportunityAlalysis/FAIL_DELETE_COMMENT';

const START_EDIT_COMMENT = 'opportunityAlalysis/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'opportunityAlalysis/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'opportunityAlalysis/FAIL_EDIT_COMMENT';

const START_DELETE = 'opportunityAlalysis/START_DELETE';
const COMPLETE_DELETE = 'opportunityAlalysis/COMPLETE_DELETE';
const FAIL_DELETE = 'opportunityAlalysis/FAIL_DELETE';

const START_ADD = 'opportunityAlalysis/START_ADD';
const COMPLETE_ADD = 'opportunityAlalysis/COMPLETE_ADD';
const FAIL_ADD = 'opportunityAlalysis/FAIL_ADD';

const START_LOAD = 'opportunityAlalysis/START_LOAD';
const COMPLETE_LOAD = 'opportunityAlalysis/COMPLETE_LOAD';
const FAIL_LOAD = 'opportunityAlalysis/FAIL_LOAD';

const START_EDIT = 'opportunityAlalysis/START_EDIT';
const COMPLETE_EDIT = 'opportunityAlalysis/COMPLETE_EDIT';
const FAIL_EDIT = 'opportunityAlalysis/FAIL_EDIT';

const START_ADD_REPLY = 'opportunityAlalysis/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'opportunityAlalysis/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'opportunityAlalysis/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'opportunityAlalysis/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'opportunityAlalysis/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'opportunityAlalysis/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'opportunityAlalysis/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'opportunityAlalysis/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'opportunityAlalysis/FAIL_DELETE_REPLY';

const RESET = 'opportunityAlalysis/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  opportunityAnalysis: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_COMMENT:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_ADD_COMMENT:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_ADD_COMMENT:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case START_DELETE_COMMENT:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE_COMMENT:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE_COMMENT:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_EDIT_COMMENT:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT_COMMENT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT_COMMENT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });
    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_ADD:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case START_EDIT:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunityAnalysis: action.opportunityAnalysis,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunityAnalysis: undefined,
        },
      });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (opportunityAnalysis) => ({
  type: COMPLETE_LOAD,
  opportunityAnalysis,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

export const addOpportunityAnalysisComment =
  (values, opportunityAnalysisId) => (dispatch) => {
    dispatch(startAddComment());
    const addOpportunityAnalysisCommentPromise =
      doAddOpportunityAnalysisComment(values, opportunityAnalysisId);
    addOpportunityAnalysisCommentPromise
      .then(() => {
        dispatch(completeAddComment());
        dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddComment());
      });
    return addOpportunityAnalysisCommentPromise;
  };

export const deleteOpportunityAnalysis =
  (opportunityAnalysisId) => (dispatch) => {
    dispatch(startDelete());
    const deleteOpportunityAnalysisPromise = doDeleteOpportunityAnalysis(
      opportunityAnalysisId
    );
    deleteOpportunityAnalysisPromise
      .then(() => {
        dispatch(completeDelete());
        dispatch(
          notifySuccess('Analiza de oportunitate a fost stearsa cu succes')
        );
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDelete());
      });
    return deleteOpportunityAnalysisPromise;
  };

export const deleteOpportunityAnalysisComment =
  (opportunityAnalysisCommentId) => (dispatch) => {
    dispatch(startDeleteComment());
    const deleteOpportunityAnalysisCommentPromise =
      doDeleteOpportunityAnalysisComment(opportunityAnalysisCommentId);
    deleteOpportunityAnalysisCommentPromise
      .then(() => {
        dispatch(completeDeleteComment());
        dispatch(notifySuccess('Comentariul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteComment());
      });
    return deleteOpportunityAnalysisCommentPromise;
  };

export const editOpportunityAnalysisComment =
  (values, opportunityAnalysisCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editOpportunityAnalysisCommentPromise =
      doEditOpportunityAnalysisComment(values, opportunityAnalysisCommentId);
    editOpportunityAnalysisCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editOpportunityAnalysisCommentPromise;
  };

export const addOpportunityAnalysis = (values, teamId) => (dispatch) => {
  dispatch(startAdd());
  const addOpportunityAnalysisPromise = doAddOpportunityAnalysis(
    values,
    teamId
  );
  addOpportunityAnalysisPromise
    .then(() => {
      dispatch(completeAdd());
      dispatch(
        notifySuccess('Analiza de oportunitate a fost adăugata cu succes')
      );
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failAdd());
    });
  return addOpportunityAnalysisPromise;
};

export const editOpportunityAnalysis =
  (values, entityId, parent, personResponsible) => (dispatch) => {
    dispatch(startEdit());
    const editOpportunityAnalysisPromise = doEditOpportunityAnalysis(
      values,
      entityId,
      parent,
      personResponsible
    );
    editOpportunityAnalysisPromise
      .then(() => {
        dispatch(completeEdit());
        dispatch(
          notifySuccess('Analiza de oportunitate a fost editata cu succes')
        );
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEdit());
      });
    return editOpportunityAnalysisPromise;
  };

export const loadOpportunityAnalysis = (teamId) => (dispatch) => {
  dispatch(startLoad());
  const loadOpportunityAnalysisPromise = doLoadOpportunityAnalysis(teamId);
  loadOpportunityAnalysisPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoad());
    });
  return loadOpportunityAnalysisPromise;
};

export const addOpportunityAnalysisReply =
  (values, opportunityAnalysisCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addOpportunityAnalysisReplyPromise = doAddOpportunityAnalysisReply(
      values,
      opportunityAnalysisCommentId
    );
    addOpportunityAnalysisReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addOpportunityAnalysisReplyPromise;
  };

export const editOpportunityAnalysisReply =
  (values, opportunityAnalysisCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editOpportunityAnalysisReplyPromise = doEditOpportunityAnalysisReply(
      values,
      opportunityAnalysisCommentId,
      replyId
    );
    editOpportunityAnalysisReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editOpportunityAnalysisReplyPromise;
  };

export const deleteOpportunityAnalysisReply =
  (opportunityAnalysisCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteOpportunityAnalysisReplyPromise =
      doDeleteOpportunityAnalysisReply(opportunityAnalysisCommentId, replyId);
    deleteOpportunityAnalysisReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteOpportunityAnalysisReplyPromise;
  };
