import { Box, IconButton } from '@mui/material';
import React from 'react';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';
import { AddButtonComponent } from './Buttons';
import {
  EyeIcon,
  PencilIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';
import { ClampedText } from '../../common/components/ClampedText';

export const SwotCard = ({
  swotSegmentData,
  handleAddSwotElement,
  swotSegmentDetails,
  handleDeleteSwotElement,
  handleEditSwotElement,
  handleViewSwotElement,
}) => (
  <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: '#FFF',
        padding: '15px 0',
      }}
    >
      <H2>{swotSegmentDetails.label}</H2>
      <AddButtonComponent
        id={swotSegmentDetails.value}
        onClick={() => handleAddSwotElement(swotSegmentDetails.value)}
      />
    </Box>

    {swotSegmentData.length > 0 &&
      swotSegmentData.map((swot) => (
        <Box
          key={swot._id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #B1CCFE',
            borderRadius: '10px',
            padding: '5px 10px',
            marginBottom: '5px',
            '&:hover .actions': {
              opacity: 1,
            },
          }}
        >
          <P>
            <ClampedText text={swot.description} lineClamp={2} />
          </P>
          <Box
            className="actions"
            sx={{ opacity: 0, transition: 'opacity 0.3s', display: 'flex' }}
          >
            <IconButton onClick={() => handleViewSwotElement(swot)}>
              <EyeIcon fill="#74A3FE" />
            </IconButton>
            <IconButton onClick={() => handleEditSwotElement(swot)}>
              <PencilIcon fill="#74A3FE" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDeleteSwotElement(swot._id);
              }}
            >
              <TrashIconOutlined fill="red" />
            </IconButton>
          </Box>
        </Box>
      ))}
  </>
);
