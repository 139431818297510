import styled from 'styled-components';

export const MrlGuideContainer = styled.div`
  padding: 0 16px;
  max-height: 84vh;
  overflow-y: auto;
`;

export const MrlGuideStep = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  height: 6vh;
  margin-bottom: 1vh;
  cursor: pointer;
`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 0.761905px solid #868699;
  border-radius: 50%;
  width: 4vh;
  height: 4vh;
  color: black;
  font-weight: bold;
  margin-left: 1vw;
`;

export const StepLabel = styled.div`
  color: black;
  font-size: 14px;
  margin-left: 1vw;
`;

export const WhiteCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 5px;
  margin-bottom: 2vh;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.15);
  padding-bottom: 2vh;
`;

export const HeadingStage = styled.p`
  font-weight: bold;
  color: #3f3f3f;
  font-size: 24px;
  margin-left: 3.5vw;
`;

export const StageTitle = styled.input`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  width: 100%;
  height: 5vh;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  padding: 10px;
  margin-bottom: 1vh;
`;

export const TaskContainer = styled.div`
  background: #eef5ff;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  height: 5vh;
  margin-bottom: 1vh;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 4px;
  right: ${(props) => props.right};
  font-weight: 700;
  color: black;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 1px;
  width: 1vw;
  text-align: center;
  cursor: pointer;
`;

export const SaveButtonContainer = styled.div`
  padding: 10px 10px;
  background: ${(props) =>
    props.opacity
      ? `rgba(42, 90, 183, ${props.opacity});`
      : 'rgba(42, 90, 183, 0.9);'};
  border-radius: 3px;
  width: 8vw;
  cursor: pointer;
  color: white;
`;

export const EditorContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  width: 100%;
  margin-bottom: 1.5vh;
  max-height: 21vw;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PlatformEditorContainer = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  width: 100%;
  margin-bottom: 15px;
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  min-height: 240px;
  height: ${({ height }) => height && height};
  border-radius: 8px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PageContainer = styled.div`
  border: 1px solid #74a3fe;
  border-radius: ${(props) => (props.fullBorder ? '10px' : '0 10px 10px 10px')};
  max-width: 100%;
  height: 91vh;
  overflow: hidden;
`;
