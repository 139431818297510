import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

const baseBoxStyles = css`
  display: flex;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
`;

export const SpaceBetweenBox = styled(Box)`
  ${baseBoxStyles};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FlexEndBox = styled.div`
  ${baseBoxStyles};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexStartBox = styled.div`
  ${baseBoxStyles};
  justify-content: flex-start;
  align-items: center;
`;

export const CenteredBox = styled.div`
  ${baseBoxStyles};
  justify-content: center;
  align-items: center;
`;
