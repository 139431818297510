import React from 'react';
import { Box } from '@mui/material';

const boxStyles = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const createHoverBackgroundColor = (current, toggleValue) => {
  if (current === 'articles' && toggleValue === 'categories') {
    return '#dee7fc';
  }
  if (current === 'categories' && toggleValue === 'articles') {
    return '#dee7fc';
  }
  return '#74A3FE';
};

const DualOptionsBox = ({ toggleValue, value, handleToggleValue, label }) => (
  <Box
    onClick={() => handleToggleValue(value)}
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: toggleValue === value ? '#74A3FE' : '#fff',
      color: toggleValue === value ? '#fff' : '#74A3FE',
      cursor: toggleValue === value ? 'default' : 'pointer',
      '&:hover': {
        backgroundColor: createHoverBackgroundColor(value, toggleValue),
      },
    }}
  >
    {label}
  </Box>
);

const DualOptionsSelect = ({ toggleValue, handleToggleValue }) => {
  const commonStyles = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #74A3FE',
    color: '#74A3FE',
    borderRadius: '4px',
    height: '32px',
    fontSize: '12px',
    padding: '2px',
    width: '180px',
  };

  return (
    <Box sx={commonStyles}>
      <DualOptionsBox
        toggleValue={toggleValue}
        value="articles"
        handleToggleValue={handleToggleValue}
        label="Articole"
      />
      <DualOptionsBox
        toggleValue={toggleValue}
        value="categories"
        handleToggleValue={handleToggleValue}
        label="Categorii"
      />
    </Box>
  );
};

export default DualOptionsSelect;
