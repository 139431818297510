import React from 'react';
import ModalComponent from '../../common/components/ModalComponent';
import ActivitiesForm from './ActivitiesForm';

const AddActivitiesModal = ({
  handleClose,
  isModalOpen,
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={handleClose}
    title="Adauga un detaliu"
  >
    <ActivitiesForm
      handleUpdate={handleUpdate}
      formValues={formValues}
      handleSubmit={handleSubmit}
      teamMembers={teamMembers}
    />
  </ModalComponent>
);

export default AddActivitiesModal;
