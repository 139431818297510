import update from 'immutability-helper';

import {
  getAllArticles as doGetAllArticles,
  addArticle as doAddArticle,
  editArticle as doEditArticle,
  deleteArticle as doDeleteArticle,
  getArticleById as doGetArticleById,
} from '../services/articles';

const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES';
const GET_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS';
const GET_ALL_ARTICLES_ERROR = 'GET_ALL_ARTICLES_ERROR';

const ADD_ARTICLE = 'ADD_ARTICLE';
const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS';
const ADD_ARTICLE_ERROR = 'ADD_ARTICLE_ERROR';

const EDIT_ARTICLE = 'EDIT_ARTICLE';
const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS';
const EDIT_ARTICLE_ERROR = 'EDIT_ARTICLE_ERROR';

const DELETE_ARTICLE = 'DELETE_ARTICLE';
const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR';

const GET_ARTICLE_BY_ID = 'GET_ARTICLE_BY_ID';
const GET_ARTICLE_BY_ID_SUCCESS = 'GET_ARTICLE_BY_ID_SUCCESS';
const GET_ARTICLE_BY_ID_ERROR = 'GET_ARTICLE_BY_ID_ERROR';

const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  articles: undefined,
  article: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ARTICLES:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_ARTICLES_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        articles: { $set: action.payload },
      });
    case GET_ALL_ARTICLES_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });

    case ADD_ARTICLE:
      return update(state, {
        isCreating: { $set: true },
      });
    case ADD_ARTICLE_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case ADD_ARTICLE_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });

    case EDIT_ARTICLE:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_ARTICLE_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_ARTICLE_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });

    case GET_ARTICLE_BY_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ARTICLE_BY_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        article: { $set: action.payload },
      });
    case GET_ARTICLE_BY_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });

    case DELETE_ARTICLE:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_ARTICLE_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_ARTICLE_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    default:
      return state;
  }
};

export const addArticle = (payload) => async (dispatch) => {
  let response;
  dispatch({ type: ADD_ARTICLE });
  try {
    response = await doAddArticle(payload);
    dispatch({ type: ADD_ARTICLE_SUCCESS });
  } catch (error) {
    dispatch({ type: ADD_ARTICLE_ERROR, payload: error });
  }
  return response;
};

export const editArticle = (articleId, payload) => async (dispatch) => {
  dispatch({ type: EDIT_ARTICLE });
  try {
    await doEditArticle(articleId, payload);
    dispatch({ type: EDIT_ARTICLE_SUCCESS });
  } catch (error) {
    dispatch({ type: EDIT_ARTICLE_ERROR, payload: error });
  }
};

export const getAllArticles = (isVisible, categories) => async (dispatch) => {
  dispatch({ type: GET_ALL_ARTICLES });
  try {
    const response = await doGetAllArticles(isVisible, categories);
    dispatch({
      type: GET_ALL_ARTICLES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_ARTICLES_ERROR, payload: error });
  }
};

export const getArticleById = (articleId) => async (dispatch) => {
  dispatch({ type: GET_ARTICLE_BY_ID });
  try {
    const response = await doGetArticleById(articleId);
    dispatch({
      type: GET_ARTICLE_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: GET_ARTICLE_BY_ID_ERROR, payload: error });
  }
};

export const deleteArticle = (articleId) => async (dispatch) => {
  dispatch({ type: DELETE_ARTICLE });
  try {
    await doDeleteArticle(articleId);
    dispatch({ type: DELETE_ARTICLE_SUCCESS });
  } catch (error) {
    dispatch({ type: DELETE_ARTICLE_ERROR, payload: error });
  }
};
