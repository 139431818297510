import React from 'react';
import ModalComponent from '../../../common/components/ModalComponent';
import IndustryForm from '../forms/IndustryForm';

export const IndustryModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    teamId,
    selectedIndustry,
    setSelectedIndustry,
    industryType,
  } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIndustry(null);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title="Adauga un element industrie"
    >
      <IndustryForm
        selectedIndustry={selectedIndustry}
        handleCloseModal={handleCloseModal}
        setSelectedIndustry={setSelectedIndustry}
        industryType={industryType}
        teamId={teamId}
      />
    </ModalComponent>
  );
};
