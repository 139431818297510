import update from 'immutability-helper';

// Actions
const HANDLE_MENU = 'menu/HANDLE_MENU';

// Initial state
const initialState = {
  isMenuOpen: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_MENU:
      return update(state, {
        $merge: {
          isMenuOpen: action.isMenuOpen,
        },
      });

    default:
      return state;
  }
};

// Action creators
const handleOpenMenuCreator = (isMenuOpen) => ({
  type: HANDLE_MENU,
  isMenuOpen,
});

export const handleMenu = (isMenuOpen) => (dispatch) => {
  dispatch(handleOpenMenuCreator(isMenuOpen));
};
