import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { Box } from '@mui/material';
import {
  H1,
  P,
} from '../../../common/components/styled/ReusableTextComponents';
import { ProfileMenu } from '../../../userProfile/components/styled/ProfilePage';
import {
  StyledNavLink,
  StyledProfileNav,
} from '../../../userProfile/components/styled/ProfileTabItem';
import { HYPOTHESES_TABS } from '../../../opportunityAnalysis/constants';
import { ProfileTabSvgIcon } from '../../../common/components/styled/LinkIcon';
import HypothesisDescriptionPage from './HypothesisDescriptionPage';
import { getBMCData } from '../../ducks';

import { currentTeamIdSelector } from '../../../common/ducks';
import HypothesisProfileComponent from '../HypothesisProfileComponent';
import { ArrowLeft } from '../../../common/assets/icons';
import { BMC_OPTIONS } from '../../../canvas/constants';
import HypothesisDetailsPage from './HypothesisDetailsPage';
import GenericComments from '../../../comentsAndNotifications/components/GenericComments';
import { getAllComments } from '../../../comentsAndNotifications/ducks';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../comentsAndNotifications/constants';
import { HypothesisPageContainer } from '../styled/HypothesisDetailsPage';
import ActivitiesPage from './ActivitiesPage';

export const PlaceholderComponent = () => (
  <div style={{ height: '80vh', padding: '40px' }}>
    <H1>Page being worked on</H1>
  </div>
);

const HypothesisPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'description';
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  const history = useHistory();

  const previousLocation = localStorage.getItem('previousHypothesisLocation');

  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);
  const canvasData = useSelector((state) => getBMCData(state.hypotheses));

  const [segmentsHypothesis, setSegmentsHypothesis] = useState([]);
  const [isPersonInfoModalOpen, setIsPersonInfoModalOpen] = useState(false);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const dispatch = useDispatch();
  const comments =
    useSelector(
      (state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab]]
    ) || [];

  const currentUserId = useSelector(
    (state) => state.userProfile.userProfile.userId._id
  );

  let hypothesisTabOptions = HYPOTHESES_TABS;
  if (hypothesis.columnBMC !== 'customerSegments') {
    hypothesisTabOptions = hypothesisTabOptions.filter(
      (tab) => tab.key !== 'profile'
    );
  }

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab],
          entityId: hypothesis._id,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    fetchComments();
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/hypothesis/${hypothesis._id}?tab=${tab}`);
  };

  const pageComponents = {
    description: {
      title: 'Descriere',
      component: (
        <HypothesisDescriptionPage
          hypothesis={hypothesis}
          segmentsHypothesis={segmentsHypothesis}
          canvasData={canvasData}
        />
      ),
    },
    profile: {
      title: 'Profil',
      component: (
        <HypothesisProfileComponent
          hypothesis={hypothesis}
          setIsPersonInfoModalOpen={setIsPersonInfoModalOpen}
        />
      ),
    },
    activities: {
      title: 'Activitati',
      component: <ActivitiesPage />,
    },
    details: {
      title: 'Detalii',
      component: <HypothesisDetailsPage />,
    },
  };

  const getSegmentsHypothesis = (canvasData, hypothesis) => {
    if (
      hypothesis &&
      Object.keys(canvasData).length &&
      canvasData.customerSegments
    ) {
      return canvasData.customerSegments.filter((item) =>
        hypothesis.segment.includes(item._id)
      );
    }
    return [];
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hour = String(date.getUTCHours()).padStart(2, '0');
    const minute = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year}, ${hour}:${minute}`;
  }

  useEffect(() => {
    const result = getSegmentsHypothesis(canvasData, hypothesis);
    setSegmentsHypothesis(result);
  }, [canvasData, hypothesis]);

  useEffect(
    () => () => localStorage.removeItem('previousHypothesisLocation'),
    []
  );

  return (
    <>
      <Box sx={{ marginBottom: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              onClick={() => history.push(previousLocation)}
              sx={{ cursor: 'pointer', marginRight: '10px' }}
            >
              <ArrowLeft />
            </Box>
            <H1>{hypothesis.title}</H1>
          </Box>
          <GenericComments
            fetchComments={fetchComments}
            comments={comments}
            teamId={teamId}
            userId={currentUserId}
            type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab]}
            entityId={hypothesis._id}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <P faded>
            {
              BMC_OPTIONS.find(
                (option) => option.value === hypothesis.columnBMC
              ).label
            }
          </P>
          <P faded>|</P>
          <P faded>
            Last edited by: {hypothesis.createdBy}{' '}
            {formatTimestamp(hypothesis.updatedAt)}
          </P>
        </Box>
      </Box>

      <ProfileMenu>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {hypothesisTabOptions.map((tab, index) => (
            <Nav.Item
              id={`hypothesis-${tab.key}`}
              key={tab.key}
              style={{ position: 'relative' }}
            >
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === HYPOTHESES_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabSvgIcon>{tab.icon}</ProfileTabSvgIcon>
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <HypothesisPageContainer>
        {pageComponents[activeTab].component}
      </HypothesisPageContainer>
    </>
  );
};

export default HypothesisPage;
