import React from 'react';
import { Formik } from 'formik';
import { RenderRoundedSelectField } from '../../opportunityAnalysis/components/forms/TrendsForm';

const DescribeExperiecneForm = () => {
  const a = 34;
  const initialValues = {
    exp1: '',
  };

  return (
    <Formik initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderRoundedSelectField
            id="role"
            label="alege rol"
            options={[
              { label: 'fondator', value: 'founder' },
              { label: 'expert', value: 'mentor' },
            ]}
          />
        </form>
      )}
    </Formik>
  );
};

export default DescribeExperiecneForm;
