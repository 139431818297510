import styled, { css } from 'styled-components';

export const SubscriptionsTableContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 82vh;
  padding: 0 40px 40px 40px;
  box-sizing: border-box;
  position: relative;

  & table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  & table thead th {
    border-bottom: none;
    border-top: none;
    background-color: white;
    vertical-align: middle !important;
    padding-top: 40px;
  }

  & table tbody td {
    border-top: none;
    border-bottom: 1px solid #b1cbfd;
  }

  & table tbody tr {
    border-bottom: none;
    border-top: none;
  }
`;

export const StyledTd = styled.td`
  font-size: 16px;
  color: #74a3fe;
  text-align: center;
  vertical-align: middle !important;
`;

export const TitleTd = styled.td`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #2a5ab7;
  max-width: 500px;
`;

export const SubscriptionButton = styled.button`
  color: white;
  background-color: #74a3fe;
  padding: 9px 20px;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #efeff4;
      color: #ccc;
    `}
`;

export const SubscriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border: 1px solid #74a3fe;
  border-radius: 10px;
  margin-left: 10px;
  width: 200px;

  & p {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  & button {
    white-space: nowrap;
  }
`;

export const ToggleWrapper = styled.div`
  width: 48px;
  height: 26px;
  background: white;
  border-radius: 13px;
  position: relative;
  cursor: pointer;
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #d9d9d9;
`;

export const ToggleCircle = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 2px;
  background: #74a3fe;
  border-radius: 13px;
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.active
      ? css`
          right: 2px;
          left: auto;
        `
      : css`
          left: 2px;
          right: auto;
        `}
`;
