import { http } from '../../core/services/http';

export const addCategory = (payload) => {
  const url = `/articleCategories/add`;
  return http.post(url, payload);
};

export const getAllCategories = () => {
  const url = `/articleCategories/get-all`;
  return http.get(url);
};

export const editCategory = (categoryId, payload) => {
  const url = `/articleCategories/edit/${categoryId}`;
  return http.put(url, payload);
};

export const deleteCategory = (categoryId) => {
  const url = `/articleCategories/delete/${categoryId}`;
  return http.delete(url);
};

export const getCategoryById = (categoryId) => {
  const url = `/articleCategories/get-by-id/${categoryId}`;
  return http.get(url);
};
