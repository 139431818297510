import { DotsBubbleContainer, DotsLoader } from './styled/dots';
import React from 'react';

const ChatLoader = () => (
  <DotsBubbleContainer>
    <DotsLoader />
  </DotsBubbleContainer>
);

export default ChatLoader;
