import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router';
import { AdminRoute } from '../../account/components';
import Page from '../../common/components/Page';

import {
  ContactForm,
  EditArticle,
  MrlPage,
  MrlStage,
  ResourcesPageResolver,
  UsersPageResolver,
} from './pages';
import EditChapter from './pages/EditChapter';

const AdminRouter = () => (
  <Page>
    <Switch>
      <AdminRoute exact path="/admin/users" component={UsersPageResolver} />
      <AdminRoute exact path="/admin/mrl" component={MrlPage} />
      <AdminRoute
        exact
        path="/admin/mrl/article/form"
        component={EditArticle}
      />
      <AdminRoute exact path="/admin/mrl/course/form" component={EditChapter} />
      <AdminRoute exact path="/admin/stage/:id" component={MrlStage} />
      <AdminRoute
        exact
        path="/admin/resources"
        component={ResourcesPageResolver}
      />
      <AdminRoute exact path="/admin/contact-form" component={ContactForm} />
      <Redirect path="/admin" to={{ pathname: '/admin/users' }} />
    </Switch>
  </Page>
);
export default withRouter(AdminRouter);
