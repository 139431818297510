import { createTheme } from '@mui/material';

const theme = {
  text: '#131336',
  blueAccent: '#2A5AB7',
  accentHover: '#063795',
  white: '#FFFFFF',
  danger: '#BA3232',
  dangerHover: '#890B0B',
  success: '#15BB89',
  successHover: '#058E65',
  textInactive: '#8E8EB5',
  accentLight: '#74A3FE',
  accentLight2: '#B1CCFE',
  accentLightHover: 'rgba(116, 163, 254, 0.15)',
  borderGray: '#CCCCCC',
  errorLight: '#FF3D00',
  purple: '#9441FE',
  black: 'black',

  bodyBackgroundColor: '#EFEFF4',
  backgroundBigScreen: '#F7F7F8',

  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
};

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.blueAccent,
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        sx: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          color: theme.accentLight,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: theme.accentLight,
          '&.Mui-focused': {
            color: theme.accentLight,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.accentLight,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.accentLight,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: theme.accentLight,
        },
      },
      defaultProps: {
        sx: {
          color: theme.accentLight,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.accentLight,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 2,
          '&$checked': {
            transform: 'translateX(24px)',
            color: theme.white,
            '& + $track': {
              backgroundColor: theme.accentLight,
              border: 'none',
            },
            '&:hover + $track': {
              backgroundColor: theme.accentLight,
            },
          },
          '&$hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
        thumb: {
          width: 22,
          height: 22,
          backgroundColor: theme.white,
        },
        track: {
          borderRadius: 13,
          height: 26,
          opacity: 1,
          backgroundColor: theme.accentLight,
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&.MuiSwitch-track': {
            opacity: '1 !important',
          },
        },
      },
    },
  },
});

export default theme;
export { muiTheme };
