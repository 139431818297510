import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { DeleteIcon, DragIndicator, EditIcon } from '../../common/assets/icons';

const Subchapter = ({ data, editChapter, deleteChapter }) => {
  const sunchapterColor = '#74A3FE';
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        alignItems: 'center',
        borderBottom: '1px solid #DEE7FC',
      }}
    >
      <Box
        sx={{
          padding: '20px 0',
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <DragIndicator fill={sunchapterColor} />
        {data.title}
      </Box>
      <Box sx={{ display: 'flex', ml: 'auto', mr: '10px' }}>
        <IconButton onClick={editChapter}>
          <EditIcon width="16" height="18" />
        </IconButton>
        <IconButton onClick={deleteChapter}>
          <DeleteIcon width="16" height="18" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Subchapter;
