import update from 'immutability-helper';

import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { notifyError } from '../../core/ducks';
import {
  login as doLogin,
  logout as doLogout,
  getUser,
} from '../services/auth';
import { userRoles } from '../constants';
import { handleFacebookAuth } from '../services/facebookAuth';

// Actions
const START_LOGIN = 'account/login/START_LOGIN';
const COMPLETE_LOGIN = 'account/login/COMPLETE_LOGIN';
const FAIL_LOGIN = 'account/login/FAIL_LOGIN';
const COMPLETE_LOGOUT = 'account/login/COMPLETE_LOGOUT';
const RESET = 'account/login/RESET';

// Initial state
const user = getUser();
const initialState = {
  isLoginFailed: false,
  isLoggingIn: false,
  isLoggedIn: !!user,
  user,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: true,
          isLoggedIn: false,
        },
      });

    case COMPLETE_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: false,
          isLoggedIn: true,
          user: action.user,
        },
      });

    case FAIL_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: true,
          isLoggingIn: false,
          isLoggedIn: false,
        },
      });

    case COMPLETE_LOGOUT:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: false,
          isLoggedIn: false,
          user: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLogin = () => ({
  type: START_LOGIN,
});

const completeLogin = (userData) => ({
  type: COMPLETE_LOGIN,
  user: userData,
});

const failLogin = () => ({
  type: FAIL_LOGIN,
});

export const login = (email, password, redirect, history) => (dispatch) => {
  dispatch(startLogin());
  return doLogin(email, password)
    .then((userData) => {
      dispatch(completeLogin(userData));
      history.push(redirect);
    })
    .catch((err) => {
      dispatch(notifyError(err.response.data));
      dispatch(failLogin());
    });
};

export const facebookLogin =
  (redirect = '/', history) =>
  (dispatch) => {
    dispatch(startLogin());
    handleFacebookAuth()
      .then(() => {
        const loggedUser = getUser();
        dispatch(completeLogin(loggedUser));
        history.push(redirect);
      })
      .catch(() => {
        dispatch(failLogin());
      });
  };

const completeLogout = () => ({
  type: COMPLETE_LOGOUT,
});

export const logout = () => (dispatch) => {
  sessionStorage.removeItem('chatSession');
  doLogout();
  dispatch(completeLogout());
};

export const resetLogin = () => ({
  type: RESET,
});

const hasRole = (loginState, role) =>
  !!loginState.user && loginState.user.role === role;

const isAdmin = (loginState) => hasRole(loginState, userRoles.ADMIN);
export const isAdminSelector = createSelector(isAdmin, identity);

const isMentor = (loginState) => hasRole(loginState, userRoles.MENTOR);
export const isMentorSelector = createSelector(isMentor, identity);

const isAccelerator = (loginState) =>
  hasRole(loginState, userRoles.ACCELERATOR);
export const isAcceleratorSelector = createSelector(isAccelerator, identity);

// Selectors
