import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import { SaveIconOutlined } from '../../common/assets/icons';
import { useApi } from '../../hooks/useApi';
import { joinCohort } from '../../requests/myIdeea';

const JoinCohortForm = ({ teamId, handleClose }) => {
  const [, runjoinCohort] = useApi(joinCohort);

  const initialValues = {
    code: '',
  };

  const handleSubmit = async (values) => {
    await runjoinCohort({
      params: {
        teamId: teamId,
      },
      data: {
        cohortCode: values.code,
      },
    });
    handleClose();
  };

  const schema = Yup.object().shape({
    code: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomTextField id="code" label="Cod cohorta" />

          <PrimaryButton
            marginTop="20px"
            icon={SaveIconOutlined}
            onClick={handleSubmit}
            type="submit"
          >
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default JoinCohortForm;
