import styled, { css } from 'styled-components';
import { loadingOverlay2, loadingOverlay3 } from '../../../core/styles';

export const Loading = styled.div`
  z-index: 9999;

  ${(props) =>
    props.isLoading &&
    css`
      ${loadingOverlay2};
    `};
`;

export const LoadingContainer = styled.div`
  z-index: 9999;

  ${(props) =>
    props.isLoading &&
    css`
      ${loadingOverlay3};
    `};
`;
