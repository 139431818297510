import React from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { JoinTeamForm } from '../forms';
import { joinTeam } from '../../../team/ducks';
import { loadUserTeams } from '../../../common/ducks';
import ReusableModal from '../../../common/components/ModalComponent';

const JoinTeaModal = (props) => {
  const { joinTeam, isOpen, setIsModalOpen, loadUserTeams, userId } = props;
  const onSubmit = async (teamCode) => {
    await joinTeam(teamCode);
    setIsModalOpen(false);
    await loadUserTeams(userId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ReusableModal
      title="Alatura-te unei echipe"
      open={isOpen}
      handleClose={handleCloseModal}
    >
      <JoinTeamForm onSubmit={onSubmit} />
    </ReusableModal>
  );
};
const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  isJoining: state.team.isJoining,
});

const mapDispatchToProps = {
  loadUserTeams,
  joinTeam,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinTeaModal)
);
