import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { ProfileMenu } from '../../../userProfile/components/styled/ProfilePage';
import {
  StyledNavLink,
  StyledProfileNav,
} from '../../../userProfile/components/styled/ProfileTabItem';
import { ProfileTabSvgIcon } from '../../../common/components/styled/LinkIcon';
import { MRL_TABS } from '../../constants';
import { MrlGuide } from './index';
import Articles from './Articles';
import { PageContainer } from '../styled/MrlStyled';
import Courses from './Courses';

const MrlPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const tabFromQuery = query.get('tab') || 'mrlSteps';

  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/admin/mrl?tab=${tab}`);
  };

  const pageComponents = {
    mrlSteps: {
      title: 'Etape ghid',
      component: <MrlGuide />,
    },
    articles: {
      title: 'Articles',
      component: <Articles />,
    },
    chapters: {
      title: 'Cursuri',
      component: <Courses />,
    },
  };

  return (
    <>
      <ProfileMenu>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {MRL_TABS.map((tab, index) => (
            <Nav.Item key={tab.key} style={{ position: 'relative' }}>
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === MRL_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabSvgIcon>{tab.icon}</ProfileTabSvgIcon>
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <PageContainer fullBorder={activeTab !== 'mrlSteps'}>
        {pageComponents[activeTab].component}
      </PageContainer>
    </>
  );
};

export default MrlPage;
