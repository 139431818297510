import React from 'react';
import Select from '@mui/material/Select';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
} from '@mui/material';
import { SaveIcon } from '../../common/components/styled';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import ReusableModal from '../../common/components/ModalComponent';
import { useSelector } from 'react-redux';
import { useApi } from '../../hooks/useApi';
import {
  cancelUserSubscription,
  startCheckoutSession,
  updateUserSubscription,
} from '../../requests/payments';

export const SelectSubscriptionModal = ({
  isModalOpen,
  setIsModalOpen,
  nextPaymentData = {},
}) => {
  const handleCloseModal = () => setIsModalOpen(false);
  const [selectedSubscription, setSelectedSubscription] = React.useState(
    nextPaymentData?.subscriptionPrice || 0
  );
  const subscriptionOptions = useSelector(
    (state) => state?.userProfile?.allSubscriptionProducts || {}
  );
  const currentSubscription =
    useSelector(
      (state) => state?.userProfile?.userProfile?.userId?.subscription
    ) || null;

  const [, runCheckoutSession] = useApi(startCheckoutSession);
  const [, runCancelSubscription] = useApi(cancelUserSubscription);
  const [, runUpdateSubscription] = useApi(updateUserSubscription);

  const planOption = [{ value: 0, label: 'Gratis' }];

  let reccurenceTranslation = {
    monthly: 'luna',
    yearly: 'an',
  };

  Object.entries(subscriptionOptions).map(
    ([reccurencePeriod, sectionOptions]) => {
      sectionOptions.forEach((option) => {
        planOption.push({
          label: `${option.price} lei /${reccurenceTranslation[reccurencePeriod]}`,
          id: option.id,
          priceId: option.priceId,
          recurrence: reccurencePeriod,
          value: option.price,
        });
      });
    }
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  const handleChange = async (event) => {
    const targetSubscripion = event.target.value;

    const subscrptionOption = planOption.find(
      (option) => option.value === targetSubscripion
    );

    if (nextPaymentData?.subscriptionPrice === targetSubscripion) {
      return;
    }

    if (!targetSubscripion) {
      return runCancelSubscription();
    }

    if (!currentSubscription) {
      const checkoutSessionResponse = await runCheckoutSession({
        data: { planId: subscrptionOption.priceId },
      });

      const { redirectUrL } = checkoutSessionResponse;

      if (!redirectUrL) {
        return;
      }

      window.location.href = redirectUrL;
    } else {
      await runUpdateSubscription({
        data: { planId: subscrptionOption.priceId },
      });
    }

    window.location.reload();
  };

  return (
    <ReusableModal
      open={isModalOpen}
      title="Schimbare plan"
      handleClose={handleCloseModal}
    >
      <FormControl style={{ width: '50%' }}>
        <InputLabel id="demo-multiple-checkbox-label">Plan</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={selectedSubscription}
          MenuProps={MenuProps}
          onChange={handleChange}
          renderValue={(selected) => {
            const selectedOption = planOption.find(
              (option) => option.value === selected
            );
            return selectedOption.label;
          }}
          input={<OutlinedInput label="Plan" />}
        >
          {planOption.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Radio checked={option.value === selectedSubscription} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <PrimaryButton style={{ marginTop: '20px' }}>
        <SaveIcon /> Salveaza
      </PrimaryButton>
    </ReusableModal>
  );
};
