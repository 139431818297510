import update from 'immutability-helper';
import { push } from 'react-router-redux';
import {
  addHypothesisDetailComment as doAddHypothesisDetailComment,
  deleteHypothesisDetailComment as doDeleteHypothesisDetailComment,
  editHypothesisDetailComment as doEditHypothesisDetailComment,
  deleteHypothesisDetail as doDeleteHypothesisDetail,
  loadHypothesisDetail as doLoadHypothesisDetail,
  editHypothesisDetail as doEditHypothesisDetail,
  addHypothesisDetail as doAddHypothesisDetail,
  addHypothesisDetailCommentReply as doAddHypothesisDetailCommentReply,
  editHypothesisDetailCommentReply as doEditHypothesisDetailCommentReply,
  deleteHypothesisDetailCommentReply as doDeleteHypothesisDetailCommentReply,
  getAllDetails as doGetAllDetails,
  postDetail as doPostDetail,
  editDetail as doEditDetail,
  deleteDetail as doDeleteDetail,
} from '../services/hypothesisDetail';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';

// Actions

const START_ADD_COMMENT = 'hypothesesDetail/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'hypothesesDetail/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'hypothesesDetail/FAIL_ADD_COMMENT';

const START_DELETE_COMMENT = 'hypothesesDetail/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'hypothesesDetail/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'hypothesesDetail/FAIL_DELETE_COMMENT';

const START_EDIT_COMMENT = 'hypothesesDetail/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'hypothesesDetail/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'hypothesesDetail/FAIL_EDIT_COMMENT';

const START_DELETE = 'hypothesesDetail/START_DELETE';
const COMPLETE_DELETE = 'hypothesesDetail/COMPLETE_DELETE';
const FAIL_DELETE = 'hypothesesDetail/FAIL_DELETE';

const START_ADD = 'hypothesesDetail/START_ADD';
const COMPLETE_ADD = 'hypothesesDetail/COMPLETE_ADD';
const FAIL_ADD = 'hypothesesDetail/FAIL_ADD';

const START_LOAD = 'hypothesesDetail/START_LOAD';
const COMPLETE_LOAD = 'hypothesesDetail/COMPLETE_LOAD';
const FAIL_LOAD = 'hypothesesDetail/FAIL_LOAD';

const START_EDIT = 'hypothesesDetail/START_EDIT';
const COMPLETE_EDIT = 'hypothesesDetail/COMPLETE_EDIT';
const FAIL_EDIT = 'hypothesesDetail/FAIL_EDIT';

const START_ADD_REPLY = 'hypothesesDetail/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'hypothesesDetail/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'hypothesesDetail/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'hypothesesDetail/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'hypothesesDetail/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'hypothesesDetail/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'hypothesesDetail/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'hypothesesDetail/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'hypothesesDetail/FAIL_DELETE_REPLY';

const RESET = 'hypothesesDetail/RESET';

const GET_ALL_DETAILS = 'GET_ALL_DETAILS';
const GET_ALL_DETAILS_SUCCESS = 'GET_ALL_DETAILS_SUCCESS';
const GET_ALL_DETAILS_ERROR = 'GET_ALL_DETAILS_ERROR';

const POST_DETAIL = 'POST_DETAIL';
const POST_DETAIL_SUCCESS = 'POST_DETAIL_SUCCESS';
const POST_DETAIL_ERROR = 'POST_DETAIL_ERROR';

const EDIT_DETAIL = 'EDIT_DETAIL';
const EDIT_DETAIL_SUCCESS = 'EDIT_DETAIL_SUCCESS';
const EDIT_DETAIL_ERROR = 'EDIT_DETAIL_ERROR';

const DELETE_DETAIL = 'DELETE_DETAIL';
const DELETE_DETAIL_SUCCESS = 'DELETE_DETAIL_SUCCESS';
const DELETE_DETAIL_ERROR = 'DELETE_DETAIL_ERROR';

// Initial state
const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  hypothesisDetail: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });
    case START_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesisDetail: action.hypothesisDetail,
        },
      });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesisDetail: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case GET_ALL_DETAILS:
      return update(state, {
        isCreating: { $set: true },
      });
    case GET_ALL_DETAILS_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
        details: { $set: action.payload },
      });
    case GET_ALL_DETAILS_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_DETAIL:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_DETAIL_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_DETAIL_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_DETAIL:
      return update(state, {
        isCreating: { $set: true },
      });
    case EDIT_DETAIL_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_DETAIL_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });

    default:
      return state;
  }
};

// Action creators

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (hypothesisDetail) => ({
  type: COMPLETE_LOAD,
  hypothesisDetail,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

export const addHypothesisDetailComment =
  (values, hypothesisDetailId) => (dispatch) => {
    dispatch(startAddComment());
    const addHypothesisDetailCommentPromise = doAddHypothesisDetailComment(
      values,
      hypothesisDetailId
    );
    addHypothesisDetailCommentPromise
      .then(() => {
        dispatch(completeAddComment());
        dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddComment());
      });
    return addHypothesisDetailCommentPromise;
  };

export const deleteHypothesisDetail = (hypothesisDetailId) => (dispatch) => {
  dispatch(startDelete());
  const deleteHypothesisDetailPromise =
    doDeleteHypothesisDetail(hypothesisDetailId);
  deleteHypothesisDetailPromise
    .then(() => {
      dispatch(completeDelete());
      dispatch(notifySuccess('Detaliul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDelete());
    });
  return deleteHypothesisDetailPromise;
};

export const deleteHypothesisDetailComment =
  (hypothesisDetailCommentId) => (dispatch) => {
    dispatch(startDeleteComment());
    const deleteHypothesisDetailCommentPromise =
      doDeleteHypothesisDetailComment(hypothesisDetailCommentId);
    deleteHypothesisDetailCommentPromise
      .then(() => {
        dispatch(completeDeleteComment());
        dispatch(notifySuccess('Comentariul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteComment());
      });
    return deleteHypothesisDetailCommentPromise;
  };

export const editHypothesisDetailComment =
  (values, hypothesisDetailCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editHypothesisDetailCommentPromise = doEditHypothesisDetailComment(
      values,
      hypothesisDetailCommentId
    );
    editHypothesisDetailCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editHypothesisDetailCommentPromise;
  };

export const addHypothesisDetail =
  (values, createdBy, parentId) => (dispatch) => {
    dispatch(startAdd());
    const addHypothesisDetailPromise = doAddHypothesisDetail(
      values,
      createdBy,
      parentId
    );
    addHypothesisDetailPromise
      .then(() => {
        dispatch(completeAdd());
        dispatch(notifySuccess('Detaliul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAdd());
      });
    return addHypothesisDetailPromise;
  };

export const editHypothesisDetail =
  (values, entityId, parent, personResponsible) => (dispatch) => {
    dispatch(startEdit());
    const editHypothesisDetailPromise = doEditHypothesisDetail(
      values,
      entityId,
      parent,
      personResponsible
    );
    editHypothesisDetailPromise
      .then(() => {
        dispatch(completeEdit());
        dispatch(notifySuccess('Detaliul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEdit());
      });
    return editHypothesisDetailPromise;
  };

export const loadHypothesisDetail = (hypothesisDetailId) => (dispatch) => {
  dispatch(startLoad());
  const loadHypothesisDetailPromise =
    doLoadHypothesisDetail(hypothesisDetailId);
  loadHypothesisDetailPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
      if (error.response.status === 404) dispatch(push('/canvas'));
    });
  return loadHypothesisDetailPromise;
};

export const addHypothesisDetailCommentReply =
  (values, hypothesisDetailCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addHypothesisDetailCommentReplyPromise =
      doAddHypothesisDetailCommentReply(values, hypothesisDetailCommentId);
    addHypothesisDetailCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addHypothesisDetailCommentReplyPromise;
  };

export const editHypothesisDetailCommentReply =
  (values, hypothesisDetailCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editHypothesisDetailCommentReplyPromise =
      doEditHypothesisDetailCommentReply(
        values,
        hypothesisDetailCommentId,
        replyId
      );
    editHypothesisDetailCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editHypothesisDetailCommentReplyPromise;
  };

export const deleteHypothesisDetailCommentReply =
  (hypothesisDetailCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteHypothesisDetailCommentReplyPromise =
      doDeleteHypothesisDetailCommentReply(hypothesisDetailCommentId, replyId);
    deleteHypothesisDetailCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteHypothesisDetailCommentReplyPromise;
  };

export const getAllDetails = (teamId, filters) => async (dispatch) => {
  dispatch({ type: GET_ALL_DETAILS });
  try {
    const response = await doGetAllDetails(teamId, filters);
    dispatch({ type: GET_ALL_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_DETAILS_ERROR, payload: error });
  }
};

export const postDetail = (detail, teamId) => async (dispatch) => {
  dispatch({ type: POST_DETAIL });
  try {
    const response = await doPostDetail(detail, teamId);
    dispatch({ type: POST_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: POST_DETAIL_ERROR, payload: error });
  }
};

export const editDetail = (detail, id) => async (dispatch) => {
  dispatch({ type: EDIT_DETAIL });
  try {
    const response = await doEditDetail(detail, id);
    dispatch({ type: EDIT_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: EDIT_DETAIL_ERROR, payload: error });
  }
};

export const deleteDetail = (id) => async (dispatch) => {
  dispatch({ type: DELETE_DETAIL });
  try {
    const response = await doDeleteDetail(id);
    dispatch({ type: DELETE_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: DELETE_DETAIL_ERROR, payload: error });
  }
};
