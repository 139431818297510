import React, { useState } from 'react';
import { Box, Switch } from '@mui/material';
import ChangePasswordModal from './modals/ChangePasswordModal';
import DeleteProfilModal from './modals/DeleteProfileModal';
import { SettingsContainer } from './styled/SettingsStyle';
import {
  H2,
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../common/components/styled/ReusableTextComponents';

const renderIpsum = () => (
  <P light marginBottom="20px">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    cillum dolore eu fugiat nulla pariatur
  </P>
);

const Settings = (props) => {
  const {
    userProfile,
    subscribeUserToMailchimp,
    unsubscribeUserFromMailchimp,
  } = props;

  const ghidMrlStoredStatus = localStorage.getItem('SHOW_MRL_GUIDE');
  const [onboardingChecked, setOnboardingChecked] = useState(true);
  const [mrlChecked, setMrlChecked] = useState(ghidMrlStoredStatus !== 'false');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNewsletterActive, setIsNewsletterActive] = useState(
    userProfile.userId.newsletter
  );
  const [feedbackChecked, setFeedbackChecked] = useState(true);

  const handleOnboardingToggle = () => {
    setOnboardingChecked(!onboardingChecked);
  };
  const handleFeedbackToggle = () => {
    setFeedbackChecked(!feedbackChecked);
  };

  const handleCloseDeleteProfileModal = () => {
    setShowDeleteModal(false);
  };

  const handleMrlToggle = () => {
    if (mrlChecked) {
      localStorage.setItem('SHOW_MRL_GUIDE', 'false');
      window.location.reload();
    } else {
      localStorage.setItem('SHOW_MRL_GUIDE', 'true');
      window.location.reload();
    }

    setMrlChecked(!mrlChecked);
  };

  const handleNewsletterToggle = () => {
    if (isNewsletterActive) {
      unsubscribeUserFromMailchimp(userProfile.email);
    } else {
      subscribeUserToMailchimp(userProfile.email);
    }
    setIsNewsletterActive(!isNewsletterActive);
  };

  return (
    <SettingsContainer className="scrollbar">
      <Box sx={{ width: '60%' }}>
        <H2 marginBottom="40px">Preferinte</H2>
        <Box id="mrl-switch" sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={onboardingChecked}
            onChange={handleOnboardingToggle}
          />
          <H3>Afiseaza ghid onboarding</H3>
        </Box>
        {renderIpsum()}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch checked={mrlChecked} onChange={handleMrlToggle} />
          <H3>Afiseaza ghid MRL</H3>
        </Box>
        {renderIpsum()}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={isNewsletterActive}
            onChange={handleNewsletterToggle}
          />
          <H3>Newsletter</H3>
        </Box>
        {renderIpsum()}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch checked={feedbackChecked} onChange={handleFeedbackToggle} />
          <H3>Sunt de acord sa fiu contactat pentru feedback platforma</H3>
        </Box>
        {renderIpsum()}
      </Box>
      <Box sx={{ width: '40%', paddingLeft: '40px' }}>
        <H2 marginBottom="40px">Managementul contului</H2>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          <PrimaryButton inverted marginBottom="30px">
            Schimba parola
          </PrimaryButton>
          <SecondaryButton inverted onClick={() => setShowDeleteModal(true)}>
            Sterge contul
          </SecondaryButton>
          <ChangePasswordModal isModalOpen={false} />
          <DeleteProfilModal
            isModalOpen={showDeleteModal}
            handleClose={handleCloseDeleteProfileModal}
          />
        </Box>
      </Box>
    </SettingsContainer>
  );
};

export default Settings;
