import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const HypothesisOnboardingRouter = () => {
  const history = useHistory();
  const userTeams = useSelector((state) => state?.hypotheses?.hypotheses);
  const firstCustomerSegment = userTeams.find(
    (team) => team.columnBMC === 'customerSegments'
  );

  useEffect(
    () => () => {
      history.push(`hypothesis/${firstCustomerSegment._id}?tab=profile`);
    },
    []
  );

  console.log(firstCustomerSegment, 'first segment');

  return <div>ceva cacat aci</div>;
};

export default HypothesisOnboardingRouter;
