import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPauseCircle,
  faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import { BMCHistorySpan } from '../../mentors/components/styled';
import {
  FilterContainer,
  InputWeek,
} from '../../mentors/components/styled/MentorsPage';

import { ReactComponent as SmallFilter } from '../../common/assets/icons/Small.svg';
import { ReactComponent as MediumFilter } from '../../common/assets/icons/Medium.svg';
import { ReactComponent as HigherFilter } from '../../common/assets/icons/Higher.svg';
import { ReactComponent as ChevronLeft } from '../../common/assets/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../common/assets/icons/chevron_right.svg';
import { ReactComponent as FirstPage } from '../../common/assets/icons/first_page.svg';
import { ReactComponent as LastPage } from '../../common/assets/icons/last_page.svg';

import {
  BmcFilterContainer,
  BmcPlayButtonContainer,
  BmcTimelapseButton,
  BmcTimelapseFilterContainer,
} from './styled/BusinessModelCavas';
import { Box, Popper } from '@mui/material';
import InteractiveTooltip from '../../common/components/InteractiveTooltip';
import { FilterIcon } from '../../common/assets/icons';
import { P } from '../../common/components/styled/ReusableTextComponents';
import BmcFilterDropdown from './BmcFilterDropdown';

const BmcFilters = ({
  selectedFilters,
  setSelectedFilters,
  isPaused,
  setIsPaused,
  setAllowedProperties,
  setAllowClick,
  currentWeek,
  setCurrentWeek,
  maxWeekNumber,
  getNewWeek,
}) => {
  const [importanceValues, setImportanceValues] = useState({
    high: false,
    medium: false,
    low: false,
  });

  const [statusValues, setStatusValues] = useState({
    valid: false,
    invalid: false,
    no_status: false,
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <BmcTimelapseFilterContainer id="bmc-time-controlls">
        <BmcTimelapseButton onClick={() => setAllowedProperties(1)}>
          <FirstPage />
        </BmcTimelapseButton>
        <BmcTimelapseButton
          onClick={() => setAllowedProperties(getNewWeek(true))}
        >
          <ChevronLeft />
        </BmcTimelapseButton>

        <BMCHistorySpan>
          <span className="no-select">Week</span>

          <InputWeek
            type="number"
            value={currentWeek}
            onChange={(e) => {
              if (maxWeekNumber > parseInt(e.target.value))
                setCurrentWeek(e.target.value);
              else {
                setCurrentWeek(maxWeekNumber);
              }
            }}
          />
        </BMCHistorySpan>

        <BmcTimelapseButton onClick={() => setAllowedProperties(getNewWeek())}>
          <ChevronRight />
        </BmcTimelapseButton>
        <BmcTimelapseButton onClick={() => setAllowedProperties(maxWeekNumber)}>
          <LastPage />
        </BmcTimelapseButton>
        <BmcPlayButtonContainer>
          {isPaused ? (
            <FontAwesomeIcon
              icon={faPlayCircle}
              style={{
                color: '#FF3D00',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsPaused(false);
                setAllowClick(false);
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPauseCircle}
              style={{
                color: '#FF3D00',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={() => setIsPaused(true)}
            />
          )}
        </BmcPlayButtonContainer>
      </BmcTimelapseFilterContainer>

      <InteractiveTooltip
        placement="bottom-start"
        content={
          <BmcFilterDropdown
            statusValues={statusValues}
            importanceValues={importanceValues}
            setStatusValues={setStatusValues}
            setImportanceValues={setImportanceValues}
            setSelectedFilters={setSelectedFilters}
          />
        }
      >
        <BmcFilterContainer className="display-flex align-items-center default-border">
          <FilterIcon fill="#74A3FE" />
          <P marginLeft="10px">
            {`Filtre (${Object.values({
              ...statusValues,
              ...importanceValues,
            }).reduce((acc, cur) => acc + cur, 0)})`}
          </P>
        </BmcFilterContainer>
      </InteractiveTooltip>
    </div>
  );
};

export default BmcFilters;
