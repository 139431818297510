import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NotificationsPage from './NotificationsPage';
import { Loading } from '../../common/components/styled';
import { DocumentTitle } from '../../common/components';
import { Resolver } from '../../core/components';
import { currentTeamIdSelector } from '../../common/ducks';
import { loadProfile } from '../../userProfile/ducks';

const NotificationsPageResolver = (props) => {
  const { loadProfile } = props;
  const loadDependencies = () => loadProfile();

  return (
    <>
      <DocumentTitle>Notifications</DocumentTitle>
      <Resolver
        successComponent={NotificationsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { loadProfile };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsPageResolver)
);
