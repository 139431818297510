import React from 'react';

import { get } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Heading2,
  SubmitButton,
  SaveIcon,
} from '../../../common/components/styled';
import { ButtonDivStartChange } from '../../../common/components/styled/Buttons';
import { InputProfile } from '../../../common/components/styled/Inputs';

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  const [meta] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <InputProfile
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          handleChange(e);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  initialValues,
  setFieldValue,
  isValid,
  role,
  ...rest
}) => (
  <Form noValidate>
    <Form.Row>
      <RenderTextField
        placeholder="Parola veche"
        name="oldPassword"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Parola veche*"
      />
      <RenderTextField
        placeholder="Parola noua"
        name="newPassword"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Parola noua*"
      />
      <RenderTextField
        placeholder="Repetare parola noua"
        name="repetPassword"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Repetare parola noua*"
      />
    </Form.Row>

    <ButtonDivStartChange>
      <SubmitButton type="submit" onClick={handleSubmit}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDivStartChange>
  </Form>
);

const ChangePasswordForm = ({ onSubmit, role }) => {
  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  return (
    <Formik onSubmit={handleSubmit}>
      {(props) => <RenderForm {...props} role={role} />}
    </Formik>
  );
};

export default ChangePasswordForm;
