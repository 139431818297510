import React from 'react';

// Components
import ModalComponent from '../../../common/components/ModalComponent';
import TrendsForm from '../forms/TrendsForm';

const AddTrendsModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    teamId,
    selectedTrend,
    setSelectedTrend,
  } = props;

  const handleCloseModal = () => {
    setSelectedTrend(null);
    setIsModalOpen(false);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title="Adauga un trend"
      size="lg"
    >
      <TrendsForm
        teamId={teamId}
        selectedTrend={selectedTrend}
        setIsModalOpen={setIsModalOpen}
        setSelectedTrend={setSelectedTrend}
      />
    </ModalComponent>
  );
};

export default AddTrendsModal;
