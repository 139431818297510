import { Box, Grid } from '@mui/material';
import React from 'react';
import { TrendCardComponent } from './TrendCardComponent';
import {
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { AddIcon } from '../../common/assets/icons';

const TrendsList = ({
  trendList,
  handleViewTrend,
  handleEditTrend,
  handleDeleteTrend,
  handleToggleAddTrendModal,
}) => (
  <Box
    sx={{ overflowY: 'scroll', height: '100%', paddingRight: '40px' }}
    className="scrollbar"
  >
    {trendList.length > 0 ? (
      <Box mt={3}>
        <Grid container spacing={2}>
          {trendList.map((trend) => (
            <Grid item xs={12} sm={6} md={4} key={trend._id}>
              <TrendCardComponent
                trend={trend}
                handleViewTrend={handleViewTrend}
                handleEditTrend={handleEditTrend}
                deleteTrendById={handleDeleteTrend}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    ) : (
      <Box>
        <P faded>
          <i>
            Adaugă un prim trend pentru a începe analiza contextului făcând
            click pe butonul „+” din partea dreaptă a secțiunii.
          </i>
        </P>
        <PrimaryButton
          onClick={handleToggleAddTrendModal}
          marginTop="20px"
          icon={AddIcon}
        >
          Adauga un trend
        </PrimaryButton>
      </Box>
    )}
  </Box>
);

export default TrendsList;
