import { BMC_CUSTOMER_SEGMENT_COLORS } from './constants/BMCOptions';

export const getNewHypothesisColor = (usedColors) => {
  const availableColors = BMC_CUSTOMER_SEGMENT_COLORS.filter(
    (color) => !usedColors.includes(color)
  );

  const randomIndex = Math.floor(Math.random() * availableColors.length);
  return availableColors[randomIndex];
};
