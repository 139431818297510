import React from 'react';
import { Box, Modal } from '@mui/material';
import { ModalBox } from './styled/ModalBox';
import { H2, H3 } from './styled/ReusableTextComponents';
import { SpaceBetweenBox } from './styled/ReusablePositioningComponents';

import { DeleteButtonX } from './styled/Buttons';

const ReusableModal = ({
  open,
  handleClose,
  title,
  children,
  size = 'sm',
  subtitle,
  hideHeader,
}) => (
  <Modal open={open} onClose={handleClose}>
    <ModalBox size={size} className="scrollbar">
      {hideHeader ? (
        ''
      ) : (
        <Box sx={{ marginBottom: '30px' }}>
          <SpaceBetweenBox>
            <H2>{title}</H2>
            <DeleteButtonX onClick={handleClose} />
          </SpaceBetweenBox>
          {subtitle && (
            <H3 lightBlue marginTop="10px">
              {subtitle}
            </H3>
          )}
        </Box>
      )}

      {children}
    </ModalBox>
  </Modal>
);

export default ReusableModal;
