import React from 'react';

// Components
import ModalComponent from '../../../common/components/ModalComponent';
import DetailsForm from '../forms/DetailsForm';

const AddDetailsModal = ({
  handleClose,
  isModalOpen,
  handleSubmitDetail,
  selectedDetailData,
  formValues,
  handleUpdateDetail,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={handleClose}
    title="Adauga un detaliu"
  >
    <DetailsForm
      handleUpdateDetail={handleUpdateDetail}
      formValues={formValues}
      data={selectedDetailData}
      handleSubmit={handleSubmitDetail}
    />
  </ModalComponent>
);

export default AddDetailsModal;
