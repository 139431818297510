// tourContext.js
import React, { useState } from 'react';

const TourContext = React.createContext();

export const TourProvider = ({ children }) => {
  const [runTour, setRunTour] = useState(false);
  const [tourName, setTourName] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const startTour = (tourName, startStep = 0) => {
    setTourName(tourName);
    setCurrentStepIndex(startStep);
    setRunTour(true);
  };

  const stopTour = () => {
    setRunTour(false);
    setTourName('');
    setCurrentStepIndex(0);
  };

  return (
    <TourContext.Provider
      value={{
        runTour,
        startTour,
        stopTour,
        currentStepIndex,
        setCurrentStep: setCurrentStepIndex,
        tourName,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export default TourContext;
