import styled from 'styled-components';

export const MemberTypeContainer = styled.div`
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  min-width: 350px;
  min-height: 100px;
  margin-bottom: 40px;
`;

export const MembersCounter = styled.div`
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const MemberRowContainer = styled.div`
  display: flex;
  margin-top: 5px;
  border: 1px solid blue;
  padding: 10px;
  border-radius: 8px;
`;
