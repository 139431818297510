import styled from 'styled-components';

export const OnboardingDashboardContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.accentLight};
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  margin-left: 20px;
  z-index: 999999;
`;

export const OnboardingSegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.accentLight};
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.accentLightHover};
  }
`;

export const OnboardingOrderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.accentLight};
  width: 22px;
  height: 22px;
  border-radius: 3px;
  margin-right: 5px;
`;

export const OnboardingLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.accentLight2};
  margin-top: 5px;
  position: relative;
`;

export const OnboardingProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.accentLight};
  height: 10px;
  width: ${(props) => props.progress}%;
`;

export const PageMiddlePoint = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
