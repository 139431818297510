import styled from 'styled-components';

export const MyIdeaPageContainer = styled.div`
  border: ${(props) => `1px solid ${props.theme.accentLight}`};
  border-radius: ${(props) => (props.fullBorder ? '10px' : '0 10px 10px 10px')};
  max-width: 100%;
  height: 83.5vh;
  padding: 20px;
`;

export const MyIdeaTag = styled.div`
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  color: ${(props) => props.theme.purple};
  border: ${(props) => `1px solid ${props.theme.purple}`};
`;

export const MyIdeaTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  margin-left: 10px;
`;
