import formRoleSelectionImage from '../../common/assets/img/form-role-selection.jpg';
import formManThinkingImage from '../../common/assets/img/form-man-thinking.jpg';
import formBullsEyeImage from '../../common/assets/img/form-bullseye.jpg';
import formPuzzleImage from '../../common/assets/img/form-puzzle.jpg';
import formPieChartImage from '../../common/assets/img/form-pie-chart.jpg';
import formGraphImage from '../../common/assets/img/form-graph.jpg';
import formCrossroadsImage from '../../common/assets/img/form-crossroads.jpg';

export const MULTIPLE_ANSWER_TYPE = 'multiple';
export const SINGLE_ANSWER_TYPE = 'single';
export const BOOLEAN_ANSWER_TYPE = 'boolean';

export const ACCOUNT_CREATION_FORM_PAYMENT_FREE = [
  {
    text: 'Poți invita un număr nelimitat de membrii',
    sign: '✓',
    id: 1,
    color: 'blue',
  },
  {
    text: 'Acces la toate instrumentele',
    sign: '✓',
    id: 2,
    color: 'blue',
  },
  {
    text: 'Acces la librăria de articole și șabloane',
    sign: '✓',
    id: 3,
    color: 'blue',
  },
  {
    text: 'Acces la ghiul MRL',
    sign: '✓',
    id: 4,
    color: 'blue',
  },
  {
    text: 'Acces la baza de date cu experți',
    sign: '✓',
    id: 5,
    color: 'blue',
  },
  {
    text: 'Acces la cursul online și materiale educaționale',
    sign: '✓',
    id: 6,
    color: 'blue',
  },
  {
    text: 'Poți lucra la o singură idee',
    sign: '⚠',
    id: 7,
    color: 'orange',
  },
  {
    text: 'Asistent virtual ChatGPT (fără istoric și limitat)',
    sign: '⚠',
    id: 8,
    color: 'orange',
  },
  {
    text: 'Facilitare personalizată online în limita a 60 min/ lună',
    sign: '⊘',
    id: 9,
    color: 'red',
  },
  {
    text: 'Business coaching online în limita a 60 min/ lună',
    sign: '⊘',
    id: 10,
    color: 'red',
  },
];
export const ACCOUNT_CREATION_FORM_PAYMENT_PAID = [
  {
    text: 'Poți invita un număr nelimitat de membrii',
    sign: '✓',
    id: 1,
    color: 'blue',
  },
  {
    text: 'Acces la toate instrumentele',
    sign: '✓',
    id: 2,
    color: 'blue',
  },
  {
    text: 'Acces la librăria de articole și șabloane',
    sign: '✓',
    id: 3,
    color: 'blue',
  },
  {
    text: 'Acces la ghiul MRL',
    sign: '✓',
    id: 4,
    color: 'blue',
  },
  {
    text: 'Acces la baza de date cu experți',
    sign: '✓',
    id: 5,
    color: 'blue',
  },
  {
    text: 'Acces la cursul online și materiale educaționale',
    sign: '✓',
    id: 6,
    color: 'blue',
  },
  {
    text: 'Poți lucra la o singură idee',
    sign: '✓',
    id: 7,
    color: 'blue',
  },
  {
    text: 'Asistent virtual ChatGPT (fără istoric și limitat)',
    sign: '✓',
    id: 8,
    color: 'blue',
  },
  {
    text: 'Facilitare personalizată online în limita a 60 min/ lună',
    sign: '✓',
    id: 9,
    color: 'blue',
  },
  {
    text: 'Business coaching online în limita a 60 min/ lună',
    sign: '✓',
    id: 10,
    color: 'blue',
  },
];

export const ACCOUNT_CREATION_FORM_PAYMENT_ADDITIONAL_DATA = {
  free: ACCOUNT_CREATION_FORM_PAYMENT_FREE,
  paid: ACCOUNT_CREATION_FORM_PAYMENT_PAID,
};

const ACCOUNT_TYPE_CHOICE_QUESTION = {
  question: 'Ce cont dorești să creezi?',
  questionType: 'bigBox',
  answerOptions: [
    {
      value: 'founder',
      label: 'Fondator',
      supportingText: 'Crezi idei sau contribui\n' + 'la cele existente',
      onSelectMessage:
        'Ca fondator ai posibilitatea de a crea idei noi si de a contribui la cele in care ai fost invitat. In\n' +
        'calitate de fondator poti sa detii sau nu parti sociale, actiuni sau o parte din proprietatea\n' +
        'intelectuala legate de afacerea sau idea respectiva',
    },
    {
      value: 'mentor',
      label: 'Expert',
      supportingText: 'Pune-ți în valoare\n' + 'experiența și expertiza',
      onSelectMessage:
        'Ca expert ai posibilitatea de a vizualiza ideile și de a lasă comentarii. Vei putea sa îți organizezi\n' +
        'echipele și să contribui în dezvoltarea lor. Acesta este un cont pentru mentori, investitori,\n' +
        'consultanți sau alte tipuri de specialiști care oferă recomandări și sprijin punctual echipei. Nu vei\n' +
        'putea edita sau modifica elementele unei idei.',
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  image: formRoleSelectionImage,
};
const WHAT_BRINGS_YOU_QUESTION = {
  image: formBullsEyeImage,
  question: 'Ce te aduce azi pe platformă LaunchPad?',
  questionType: 'pill',
  questionAdditionalInformation: 'Maxim 3 raspunuri',
  answerOptions: [
    {
      value: 'Vreau să încep o afacere',
    },
    {
      value: 'Vreau să câștig o competiție de idee de afaceri',
    },
    {
      value: 'Dezvolt o soluție nouă și am nevoie de un ghid',
    },
    {
      value: 'Îmbunătățesc o soluție existentă ',
    },
    {
      value: 'Vreau să învăț despre pașii de urmat ca să lansez o afacere',
    },
    {
      value: 'Vreau să testez platforma fără un scop precis',
    },
    {
      value: 'Vreau să mă alătur unei echipe existente ca co-echipier',
    },
    {
      value: 'Am nevoie de un task manager pentru startup-ul/ soluția mea',
    },
    {
      value: 'Alt motiv',
    },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
};
const WHAT_WOULD_HELP_QUESTION = {
  image: formPuzzleImage,
  question: 'Ce te-ar ajuta cel mai mult să-ți atingi scopul?',
  questionAdditionalInformation: 'Maxim 3 raspunuri',
  questionType: 'pill',
  answerOptions: [
    {
      value: 'Instrumente',
    },
    {
      value: 'Ghiduri pas cu pas',
    },
    {
      value: 'Cursuri sau workshopuri online',
    },
    {
      value: 'Șabloane documente',
    },
    {
      value: 'Webinar-ii tematice',
    },
    {
      value: 'Cursuri sau workshopuri offline',
    },
    {
      value: 'Consultanță de business',
    },
    {
      value: 'Articole explicative',
    },
    {
      value: 'Expertiză tehnică punctuală (marketing, vânzări, juridică etc)',
    },
    {
      value: 'Coaching 1 la 1',
    },
    {
      value: 'Mentorat 1 la 1',
    },
    {
      value: 'Facilitare 1 la 1',
    },
    {
      value: 'Introduceri și recomandări',
    },
    {
      value: 'Metode și instrumente de evaluare a progresului',
    },
    {
      value: 'Metode și instrumente de comunicare și feedback eficiente',
    },
    {
      value: 'Alte resurse',
    },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
};
// const WHAT_EXPERIENCE_DO_YOU_HAVE = {
//   image: formPuzzleImage,
//   question: 'Ce care este expertiza ta?',
//   questionAdditionalInformation: 'Maxim 3 raspunuri',
//   questionType: 'experienceForm',
//   answerLimit: 3,
//   questionAnswerType: MULTIPLE_ANSWER_TYPE,
// }
const DESCRIBE_YOUR_IDEEA_QUESTION = {
  image: formManThinkingImage,
  question: 'Descrie idea ta',
  questionType: 'describeIdeaForm',
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  answerWithQuestion: true,
};
const HOW_MANY_MEMBERS_QUESTION = {
  image: formPieChartImage,
  question: 'Câți membrii are echipa de bază?\n',
  questionType: 'numberOfMembers',
  answerOptions: [
    {
      value: 'Doar eu',
    },
    {
      value: '2-5',
    },
    {
      value: '6-9',
    },
    {
      value: '10-49',
    },
    {
      value: '50-249',
    },
    {
      value: '+250',
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
};
const WHAT_DO_YOU_FOCUS_ON_QUESION = {
  image: formGraphImage,
  question: 'Pe ce ai vrea să te concentrezi mai întâi?',
  questionType: 'pill',
  answerOptions: [
    {
      value: 'Validarea nevoii ',
    },
    {
      value: 'Validarea nevoii Validarea clienților \n',
    },
    {
      value: 'Validarea pieței',
    },
    {
      value: 'Validarea soluției',
    },
    {
      value: 'Validarea modelului de business',
    },
    {
      value: 'Învățarea conceptelor',
    },
    {
      value: 'Familiarizarea cu ghidul MRL',
    },
    {
      value: 'Familiarizare cu LaunchPad',
    },
    {
      value: 'Încă nu știu',
    },
    {
      value: 'Altceva',
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
};
const WANT_TO_BE_HELPED_QUESTION = {
  image: formCrossroadsImage,
  question:
    'Vrei ca un expert facilitator care să te ajute cu familiarizarea LaunchPad?',
  questionType: 'pill',
  answerOptions: [
    {
      label: 'Da',
      value: true,
      onSelectMessage:
        'Un coleg te va contacta pentru a stabili ziua și ora potrivită pentru a te familiariza cuplatforma și metodologia',
    },
    { label: 'Nu', value: false },
  ],
  answerLimit: 1,
  questionAnswerType: BOOLEAN_ANSWER_TYPE,
};
const HOW_DID_YOU_HEAR_ABOUT_US_QUESTION = {
  image: formPuzzleImage,
  question: 'Cum ai auzit de LaunchPad?',
  questionType: 'pill',
  answerOptions: [
    {
      value: 'Cineva care îl folosește',
    },
    {
      value: 'Facebook',
    },
    {
      value: 'Presa scrisă',
    },
    {
      value: 'LinkedIn',
    },
    {
      value: 'Program antreprenorial',
    },
    {
      value: 'Newsletter',
    },
    {
      value: 'Motor de căutare',
    },
    {
      value: 'Mentor / Expert',
    },
    {
      value: 'Eveniment',
    },
    {
      value: 'Altă metodă',
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
};
const WHAT_IS_YOUR_JOB_QUESTION = {
  image: formPuzzleImage,
  question: 'Care este ocupația ta curentă?',
  questionAdditionalInformation: 'Maxim 3 raspunuri',
  questionType: 'pill',
  answerOptions: [
    {
      value: 'Antreprenor cu una sau mai multe firme',
    },
    {
      value: 'Intraprenor într-o companie ce dezvoltă o soluție inovativă',
    },
    {
      value: 'Membru într-o echipă care dezvoltă o soluție',
    },
    {
      value: 'Student',
    },
    {
      value: 'Facilitator / Trainer',
    },
    {
      value: 'Freelancer',
    },
    {
      value: 'Angel investior',
    },
    {
      value: 'Manager program accelerare sau incubare',
    },
    {
      value: 'Consultant de business',
    },
    {
      value: 'Mentor',
    },
    {
      value: 'Expert tehnic',
    },
    {
      value: 'Angajat',
    },
    {
      value: 'Director inovare ',
    },
    {
      value: 'Manager echipă',
    },
    {
      value: 'CEO',
    },
    {
      value: 'Alta',
    },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
};
const FEEDBACK_COLLECTION_QUESTION = {
  image: formPuzzleImage,
  question:
    'Părerea ta este foarte importantă pentru noi,\n' +
    'așadar am putea să te contactăm ocazional în\n' +
    'legătură cu LaunchPad?',
  questionAdditionalInformation: 'Alege răspunsurile care ți se potrivesc',
  questionType: 'pill',
  answerOptions: [
    {
      value: 'Da pe email',
    },
    {
      value: 'Da, telefonic',
    },
    {
      value: 'Da, față în față',
    },
    {
      value: 'Da, online',
    },
    {
      value: 'Nu vreau să fiu contactat/ă',
    },
  ],
  answerLimit: 4,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
};
const ACCOUNT_TIER_CHOICE_QUESTION = {
  question: 'Și acum ultima întrebare, ce tip de cont dorești?',
  questionType: 'bigBox',
  answerOptions: [
    {
      value: 'free',
      label: 'Gratuit',
      supportingText: 'pentru totdeauna',
      onSelectMessage:
        'Ca fondator ai posibilitatea de a crea idei noi si de a contribui la cele in care ai fost invitat. In\n' +
        'calitate de fondator poti sa detii sau nu parti sociale, actiuni sau o parte din proprietatea\n' +
        'intelectuala legate de afacerea sau idea respectiva',
    },
    {
      value: 'paid',
      label: 'Plătit',
      supportingText: '69 RON / lună',
      onSelectMessage:
        'Ca expert ai posibilitatea de a vizualiza ideile și de a lasă comentarii. Vei putea sa îți organizezi\n' +
        'echipele și să contribui în dezvoltarea lor. Acesta este un cont pentru mentori, investitori,\n' +
        'consultanți sau alte tipuri de specialiști care oferă recomandări și sprijin punctual echipei. Nu vei\n' +
        'putea edita sau modifica elementele unei idei.',
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  purchaseStatus: true,
};

const ACCOUNT_FLOW_FINAL = {
  finalStep: true,
  image: formPuzzleImage,
};

const FOUNDER_FLOW_QUESTIONS = [
  {
    questionCode: 'question1_v1',
    ...ACCOUNT_TYPE_CHOICE_QUESTION,
  },
  {
    questionCode: 'question2_v1',
    ...WHAT_BRINGS_YOU_QUESTION,
  },
  {
    questionCode: 'question3_v1',
    ...WHAT_WOULD_HELP_QUESTION,
  },
  {
    questionCode: 'question4_v1',
    ...DESCRIBE_YOUR_IDEEA_QUESTION,
  },
  {
    questionCode: 'question5_v1',
    ...HOW_MANY_MEMBERS_QUESTION,
  },
  {
    questionCode: 'question6_v1',
    ...WHAT_DO_YOU_FOCUS_ON_QUESION,
  },
  {
    questionCode: 'question7_v1',
    ...WANT_TO_BE_HELPED_QUESTION,
  },
  {
    questionCode: 'question8_v1',
    ...ACCOUNT_TIER_CHOICE_QUESTION,
  },
  {
    questionCode: 'end',
    ...ACCOUNT_FLOW_FINAL,
  },
];
const EXPERT_FLOW_QUESTIONS = [
  {
    questionCode: 'question1_v1',
    ...ACCOUNT_TYPE_CHOICE_QUESTION,
  },
  {
    questionCode: 'question2_v1',
    ...WHAT_BRINGS_YOU_QUESTION,
  },
  {
    questionCode: 'question3_v1',
    ...WHAT_WOULD_HELP_QUESTION,
  },
  // {
  //   questionCode: 'question4_v1',
  //   ...WHAT_EXPERIENCE_DO_YOU_HAVE,
  // },
  {
    questionCode: 'question4_v1',
    ...WHAT_IS_YOUR_JOB_QUESTION,
  },
  {
    questionCode: 'question5_v1',
    ...HOW_DID_YOU_HEAR_ABOUT_US_QUESTION,
  },
  {
    questionCode: 'question6_v1',
    ...FEEDBACK_COLLECTION_QUESTION,
  },
  {
    questionCode: 'question7_v1',
    ...ACCOUNT_TIER_CHOICE_QUESTION,
  },
];

export const QUESTION_FLOWS = {
  FOUNDER: FOUNDER_FLOW_QUESTIONS,
  MENTOR: EXPERT_FLOW_QUESTIONS,
};
