import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { DeleteButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import { formatDate } from '../../common/functions/dateHelpers';
import {
  deleteTargetIndicator,
  getAllTargetIndicators,
} from '../../targetIndicators/ducks/targetIndicators';
import { targetIndicatorsOptions } from '../../hypotheses/components/forms/HypothesisForm';

const IndicatorsComponent = () => {
  const dispatch = useDispatch();

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const indicators = useSelector(
    (state) => state.targetIndicators.targetIndicators || []
  );

  const handleFetchIndicators = async () => {
    await dispatch(getAllTargetIndicators(teamId));
  };

  const handleDeleteIndicator = async (id) => {
    dispatch(deleteTargetIndicator(id));
    await handleFetchIndicators();
  };

  // const searchLabel = (value, collection) =>
  //   collection.find((item) => item.value === value)?.label || '-';

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: 'Denumire', key: 'targetIndicatorType' },
    { type: 'string', content: 'Valoare tinta', key: 'value' },
    { type: 'string', content: 'Data', key: 'createdAt' },
    // { type: 'string', content: 'BMC', key: 'columnBMC' },
    // { type: 'string', content: 'Importanta', key: 'importance' },
    // { type: 'string', content: 'Cost', key: 'cost' }, ??????
    // {
    //   type: 'component',
    //   content: <Box>icon</Box>,
    //   hideSortIcon: true,
    //   key: 'ceva'
    // },
    // { type: 'string', content: 'Concluzie', key: 'conclusion' },
    ...(indicators.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    indicators.map(({ _id, targetIndicatorType, value, createdAt }, index) => ({
      rowId: _id,
      order: index + 1,
      targetIndicatorType:
        targetIndicatorsOptions.find(
          (option) => option.value === targetIndicatorType
        )?.label || '-',
      value,
      createdAt: formatDate(createdAt),
      // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
      action: (
        <DeleteButtonComponent
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteIndicator(_id);
          }}
        />
      ),
    }));

  useEffect(() => {
    if (teamId) {
      handleFetchIndicators();
    }
  }, []);
  return (
    <PageContainer className="scrollbar">
      {indicators && !!indicators.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            // handleSelectedRow={handleSelectedActivity}
          />
        </Grid>
      ) : (
        <GenericEmptyTable
          textColor="#74A3FE"
          headers={createTableHeaderCells()}
          detailMessage="Niciun indicator nu a fost adaugat..."
        />
      )}
    </PageContainer>
  );
};

export default IndicatorsComponent;
