export function getBlockStyle(block) {
  const alignment = block.getData().get('text-align');

  switch (alignment) {
    case 'left':
      return 'richEditor-align-left';
    case 'center':
      return 'richEditor-align-center';
    case 'right':
      return 'richEditor-align-right';
    case 'justify':
      return 'richEditor-align-justify';
    default:
      return 'richEditor-align-left';
  }
}

export const emptyContentState = {
  entityMap: {},
  blocks: [
    {
      key: 'initial',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};
