import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PageContainer } from '../../../common/components/styled';
import GenericTableComponent from '../../../common/components/GenericTableComponent';
import {
  P,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { AddIcon, ArrowDown } from '../../../common/assets/icons';
import AddDetailsModal from '../../../opportunityAnalysis/components/modals/AddDetailsModal';
import { currentTeamIdSelector } from '../../../common/ducks';
import { categoryOptions } from '../../../opportunityAnalysis/components/forms/DetailsForm';
import {
  AddButtonComponent,
  DeleteButtonComponent,
} from '../../../opportunityAnalysis/components/Buttons';
import { DetailsDrawer } from '../../../opportunityAnalysis/components/DetailsDrawer';
import {
  deleteDetail,
  editDetail,
  getAllDetails,
  postDetail,
} from '../../../hypothesisDetails/ducks/hypothesisDetail';
import { theme } from '../../../core/styles';

const headerCellStyle = {
  maxWidth: '100px',
  textAlign: 'center',
  color: theme.blueAccent,
  fontWeight: 'bold',
  padding: '0 0 10px 0',
};

const centeredDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '5px',
  cursor: 'pointer',
};
const HypothesisDetailsPage = () => {
  const dispatch = useDispatch();
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const details = useSelector((state) => state.hypothesisDetail.details);
  const { hypothesisId } = useParams();
  const profilePicture = useSelector(
    (state) =>
      state.userProfile.userProfile &&
      state.userProfile.userProfile.profilePicture
  );
  const firstName =
    useSelector(
      (state) =>
        state.userProfile.userProfile && state.userProfile.userProfile.firstName
    ) || '';
  const lastName =
    useSelector(
      (state) =>
        state.userProfile.userProfile && state.userProfile.userProfile.lastName
    ) || '';

  const [isAddDetailsModalOpen, setIsAddDetailsModalOpen] = useState(false);
  const [selectedDetailId, setSelectedDetailId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editFormValues, setEditFormValues] = useState(null);

  const handleToggleAddDetailsModal = () => {
    setIsAddDetailsModalOpen(!isAddDetailsModalOpen);
  };

  const handleToggleDrawerStatus = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    handleToggleDrawerStatus();
    setSelectedDetailId(null);
    setEditFormValues(null);
  };

  const handleGetDetails = async () => {
    const filters = { section: 'hypotheses', hypothesisId };
    await dispatch(getAllDetails(teamId, filters));
  };

  const handleDeleteDetail = async (id) => {
    await dispatch(deleteDetail(id));
    await handleGetDetails();
  };

  const adjustFormValues = (values) => {
    const { problemSeverity, levelOfInterest, contactDisponibility, ...rest } =
      values;
    return values.activityType !== 'minutaInterviuClienti' ? values : rest;
  };

  const handleSubmitDetail = async (formValues) => {
    const adjustedValues = adjustFormValues(formValues);
    const payload = {
      ...adjustedValues,
      links: [
        adjustedValues.link1 && { name: adjustedValues.link1 },
        adjustedValues.link2 && { name: adjustedValues.link2 },
        adjustedValues.link3 && { name: adjustedValues.link3 },
      ].filter(Boolean),
      section: 'hypotheses',
      hypothesisId,
    };
    await dispatch(postDetail(payload, teamId));
    handleToggleAddDetailsModal();
    await handleGetDetails();
  };

  const handleUpdateDetail = async (formValues) => {
    const adjustedValues = adjustFormValues(formValues);
    const payload = {
      ...adjustedValues,
      links: [
        adjustedValues.link1 && { name: adjustedValues.link1 },
        adjustedValues.link2 && { name: adjustedValues.link2 },
        adjustedValues.link3 && { name: adjustedValues.link3 },
      ].filter(Boolean),
    };

    await dispatch(editDetail(payload, selectedDetailId));
    handleToggleAddDetailsModal();
    setSelectedDetailId(null);
    setEditFormValues(null);
    await handleGetDetails();
  };

  const formatDate = (initialDate) => {
    const date = new Date(initialDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const CreatorCell = ({ firstName }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <Avatar
        alt="poza de profil"
        src={profilePicture}
        variant="circle"
        sx={{ width: 20, height: 20 }}
      />
      <span>{firstName}</span>
    </Box>
  );

  const searchLabel = (value, collection) =>
    collection.find((item) => item.value === value)?.label;

  const determineLevelOfInterest = (activityType, levelOfInterest) => {
    if (activityType !== 'minutaInterviuClienti') {
      if (levelOfInterest < 3) {
        return 'Nu';
      }
      return 'Da';
    }
    return '-';
  };

  const determineContactDisponibility = (
    activityType,
    contactDisponibility
  ) => {
    if (activityType !== 'minutaInterviuClienti') {
      if (contactDisponibility) {
        return 'Nu';
      }
      return 'Da';
    }
    return '-';
  };

  const createRows = () =>
    details.map(
      (
        {
          description,
          category,
          createdAt,
          _id,
          // activityType,
          // problemSeverity,
          // levelOfInterest,
          // contactDisponibility,
        },
        index
      ) => ({
        rowId: _id,
        number: index + 1,
        description,
        // activityType: searchLabel(activityType, activityTypeOptions),
        category: searchLabel(category, categoryOptions),
        // problemSeverity:
        //   activityType === 'minutaInterviuClienti' && problemSeverity
        //     ? searchLabel(+problemSeverity, problemSeverityOptions)
        //     : '-',
        // levelOfInterest: determineLevelOfInterest(
        //   activityType,
        //   levelOfInterest
        // ),
        // contactDisponibility: determineContactDisponibility(
        //   activityType,
        //   contactDisponibility
        // ),
        createdAt: formatDate(createdAt),
        // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
        action: (
          <DeleteButtonComponent
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteDetail(_id);
            }}
          />
        ),
      })
    );

  const createTableHeaderCells = () => [
    { type: 'string', content: 'Numar', key: 'number' },
    { type: 'string', content: 'Denumire', key: 'description' },
    { type: 'string', content: 'Categorie', key: 'category' },
    { type: 'string', content: 'Creat la:', key: 'createdAt' },
    {
      type: 'component',
      content: (
        <AddButtonComponent
          id="add-detail"
          onClick={handleToggleAddDetailsModal}
        />
      ),
      hideSortIcon: true,
    },
    // { type: 'string', content: 'Tip activitate' },
    // { type: 'string', content: 'Severitate' },
    // { type: 'string', content: 'Semnale cumparare' },
    // { type: 'string', content: 'Contact ulterior' },
    // { type: 'string', content: 'Creator' },
  ];

  const handleSelectedDetail = (id) => {
    const detailData = details.find((detail) => detail._id === id);
    const {
      description,
      activityType,
      category,
      problemSeverity,
      levelOfInterest,
      contactDisponibility,
      links,
    } = detailData;
    const formValues = {
      description,
      activityType,
      category,
      problemSeverity,
      levelOfInterest,
      contactDisponibility,
      linkNumber: links.length,
      link1: (links[0] && links[0].name) || '',
      link2: (links[1] && links[1].name) || '',
      link3: (links[2] && links[2].name) || '',
    };

    setEditFormValues(formValues);
    setSelectedDetailId(id);
    handleToggleDrawerStatus();
  };

  const handleCloseModal = () => {
    handleToggleAddDetailsModal();
    setSelectedDetailId(null);
    setEditFormValues(null);
  };

  const createDrawerDetailData = () => {
    if (!details || !selectedDetailId) {
      return null;
    }
    const {
      activityType,
      category,
      contactDisponibility,
      createdAt,
      description,
      levelOfInterest,
      links,
      problemSeverity,
    } = details.find((detail) => detail._id === selectedDetailId);
    return {
      activityType,
      category,
      contactDisponibility,
      createdAt,
      description,
      levelOfInterest,
      links,
      problemSeverity,
    };
  };

  const handleEditDetailsElement = () => {
    handleToggleDrawerStatus();
    handleToggleAddDetailsModal();
  };

  useEffect(() => {
    if (teamId) {
      handleGetDetails();
    }
  }, []);

  return (
    <PageContainer className="scrollbar">
      {details && !!details.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            handleSelectedRow={handleSelectedDetail}
          />
        </Grid>
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow
                sx={{ '& > th': { borderBottom: '1px solid #B1CCFE' } }}
              >
                {createTableHeaderCells().map((cell, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    <Box sx={centeredDivStyle}>
                      <span>{cell.content}</span>
                      {!cell.hideSortIcon && <ArrowDown />}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <Box sx={{ marginTop: '40px' }}>
            <P faded>
              <i>
                Adaugă un prim detailu pentru a începe validarea acestei ipoteze
                făcând click pe butonul „+” din partea stângă a secțiunii.
              </i>
            </P>
          </Box>
          <PrimaryButton
            onClick={handleToggleAddDetailsModal}
            marginTop="20px"
            icon={AddIcon}
          >
            Adauga un detaliu
          </PrimaryButton>
        </Box>
      )}
      <AddDetailsModal
        teamId={teamId}
        isModalOpen={isAddDetailsModalOpen}
        handleClose={handleCloseModal}
        handleSubmitDetail={handleSubmitDetail}
        formValues={editFormValues}
        handleUpdateDetail={handleUpdateDetail}
      />
      <DetailsDrawer
        selectedDetailsElement={createDrawerDetailData() || {}}
        handleCloseDrawer={handleCloseDrawer}
        isDrawerOpen={isDrawerOpen}
        formatDate={formatDate}
        handleEditDetailsElement={handleEditDetailsElement}
        handleDeleteDetailsElement={() => {
          handleDeleteDetail(selectedDetailId);
          setSelectedDetailId(null);
          setEditFormValues(null);
          handleToggleDrawerStatus();
        }}
      />
    </PageContainer>
  );
};

export default HypothesisDetailsPage;
