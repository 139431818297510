import Box from '@mui/material/Box';
import React from 'react';
import { MiddleContainer } from './styled/HypothesisDetailsPage';
import { MarginContainer } from '../../mrl/components/styled/MrlStyled';
import {
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { AddIcon, EditIcon } from '../../common/assets/icons';
import { truncateText } from '../../common/functions/sizeComments';

const HypothesisProfileMiddleComponent = ({
  hypothesis,
  setIsPersonInfoModalOpen,
}) => (
  <MiddleContainer id="middle-container">
    <MarginContainer display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: '250px',
          height: '250px',
          borderRadius: '50%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          justifyContent: 'center',
          border: '1px solid #74A3FE',
          ...(hypothesis.clientProfile &&
          hypothesis.clientProfile.profilePicture
            ? {
                backgroundImage: `url(${hypothesis.clientProfile.profilePicture})`,
                justifyContent: 'flex-end',
                paddingBottom: '35px',
              }
            : {}),
        }}
        onClick={() => setIsPersonInfoModalOpen(true)}
      >
        {hypothesis.clientProfile && hypothesis.clientProfile.profilePicture ? (
          <Box
            sx={{
              backgroundColor: 'white',
              width: '150px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              border: '1px solid #74A3FE',
              paddingTop: '5px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <P elipsed marginRight="5px">
                {truncateText(hypothesis.clientProfile.name, 15)}
              </P>
              <EditIcon />
            </Box>
            <P elipsed light>
              {truncateText(hypothesis.clientProfile.description, 18)}
            </P>
          </Box>
        ) : (
          <PrimaryButton icon={AddIcon}> Adauga profil</PrimaryButton>
        )}
      </Box>
    </MarginContainer>
  </MiddleContainer>
);

export default HypothesisProfileMiddleComponent;
