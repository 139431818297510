import React from 'react';
import styled, { css } from 'styled-components';

const baseStyles = css`
  font-family: 'Open Sans', serif;
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : props.theme.blueAccent)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};
  font-weight: ${(props) => (props.light ? 400 : 600)};
  font-weight: ${(props) => (props.bold ? 700 : 600)};
  color: ${(props) => props.lightBlue && props.theme.accentLight};
`;

const fadedStyles = css`
  color: #b1ccfe;
`;
const elipsedStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const H1 = styled.h1`
  ${baseStyles};
  font-size: 28px;
  line-height: 33px;
`;

const H2 = styled.h2`
  ${baseStyles};
  font-size: 24px;
  line-height: 33px;
`;

const H3 = styled.h3`
  ${baseStyles};
  font-size: 16px;
`;

const P = styled.p`
  margin: ${(props) => (props.margin ? props.margin : '0')};
  ${baseStyles};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: 19px;
  ${(props) => props.faded && fadedStyles};
  font-weight: ${(props) => (props.light ? 400 : 600)};
  ${(props) => props.elipsed && elipsedStyles};
`;

const TextBox = styled.div`
  margin: 0;
  ${baseStyles};
  font-size: 14px;
  line-height: 19px;
  font-weight: ${(props) => (props.light ? 400 : 600)};
  ${(props) => props.elipsed && elipsedStyles};
`;

const SubText = styled.p`
  margin: 0;
  ${baseStyles};
  font-size: 12px;
  line-height: 16px;
  ${(props) => props.faded && fadedStyles};
  font-weight: ${(props) => (props.light ? 400 : 600)};
  ${(props) => props.elipsed && elipsedStyles};
  ${(props) => props.width && `width: ${props.width}`};
`;

const Header2Paragraph = styled.p`
  margin: 0;
  ${baseStyles};
  font-size: 24px;
  line-height: 33px;
  ${(props) => props.faded && fadedStyles};
  font-weight: ${(props) => (props.light ? 400 : 600)};
`;

const Header3Paragraph = styled.p`
  margin: 0;
  ${baseStyles};
  font-size: 16px;
  line-height: 33px;
  ${(props) => props.faded && fadedStyles};
  font-weight: ${(props) => (props.light ? 400 : 600)};
`;

const SPAN = styled.span`
  ${baseStyles};
  font-size: 14px;
  line-height: 19px;
  ${(props) => props.faded && fadedStyles};

  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
`;

export const PrimaryButton = ({
  children,
  icon: Icon,
  iconPosition = 'before',
  iconColor = '#fff',
  enforceColor,
  disabled,
  inverted,
  ...rest
}) => {
  const iconFillColor = () => {
    if (enforceColor) {
      return iconColor;
    }
    if (disabled) {
      return '#CCCCCC';
    }
    if (inverted) {
      return '#74A3FE';
    }
    return iconColor;
  };
  return (
    <StyledButton disabled={disabled} inverted={inverted} {...rest}>
      {Icon && iconPosition === 'before' && (
        <IconContainer iconPosition={iconPosition}>
          <Icon fill={iconFillColor()} />
        </IconContainer>
      )}
      {children}
      {Icon && iconPosition === 'after' && (
        <IconContainer iconPosition={iconPosition}>
          <Icon fill={iconFillColor()} />
        </IconContainer>
      )}
    </StyledButton>
  );
};

const buttonStyles = css`
  color: #fff;
  background-color: ${(props) => props.theme.accentLight};
  border: none;
  cursor: pointer;
`;

const disabledButtonStyles = css`
  cursor: default;
  background-color: #efeff4;
  border: none;
`;

const invertedButtonStyles = css`
  color: ${(props) => props.theme.accentLight};
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.accentLight};
`;

const hoverButtonStyles = css`
  color: #fff;
  background-color: #2a5ab7;
  border: none;
`;

const invertedHoverButtonStyles = css`
  color: #74a3fe;
  background-color: #dee7fc;
  border: 1px solid #74a3fe;
`;

const disabledInvertedButtonStyles = css`
  color: #cccccc;
  background-color: #fff;
  border: none;
`;

const StyledButton = styled.button`
  padding: ${(props) => (props.padding ? props.padding : '8px 20px')};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  ${(props) => {
    if (props.disabled && props.inverted) {
      return disabledInvertedButtonStyles;
    }
    if (props.disabled) {
      return disabledButtonStyles;
    }
    if (props.inverted) {
      return invertedButtonStyles;
    }
    return buttonStyles;
  }}

  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft}`};
  ${(props) => props.marginRight && `margin-right: ${props.marginRight}`};

  &:hover {
    ${(props) => {
      if (props.disabled && props.inverted) {
        return disabledInvertedButtonStyles;
      }
      if (props.disabled) {
        return disabledButtonStyles;
      }

      if (props.inverted) {
        return invertedHoverButtonStyles;
      }

      return hoverButtonStyles;
    }}
  }
`;

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: ${(props) => (props.iconPosition === 'before' ? '8px' : 0)};
  margin-left: ${(props) => (props.iconPosition === 'after' ? '8px' : 0)};
`;

const secondaryInvertedButtonStyles = css`
  color: ${(props) => props.theme.errorLight};
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.errorLight};
`;

const secondaryButtonStyles = css`
  color: #fff;
  background-color: #ff3d00;
  border: none;
`;

const secondaryButtonHoverStyles = css`
  color: #fff;
  background-color: #e63600;
  border: none;
`;
const secondaryInvertedButtonHoverStyles = css`
  color: #fff;
  background-color: #ffd3c6;
  border: 1px solid #ffd3c6;
`;

const secondaryDisabledInvertedButtonStyles = css`
  color: #cccccc;
  background-color: #e5e5e5;
  border: 1px solid #cccccc;
`;

export const StyledSecondaryButton = ({
  children,
  icon: Icon,
  iconPosition = 'before',
  iconColor = '#ff3d00',
  disabled,
  inverted,
  ...rest
}) => {
  const iconFillColor = () => {
    if (disabled) {
      return '#CCCCCC';
    }
    return iconColor;
  };
  return (
    <StyledButton disabled={disabled} inverted={inverted} {...rest}>
      {Icon && iconPosition === 'before' && (
        <IconContainer iconPosition={iconPosition}>
          <Icon fill={iconFillColor()} />
        </IconContainer>
      )}
      {children}
      {Icon && iconPosition === 'after' && (
        <IconContainer iconPosition={iconPosition}>
          <Icon fill={iconFillColor()} />
        </IconContainer>
      )}
    </StyledButton>
  );
};
export const SecondaryButton = styled(StyledSecondaryButton)`
  ${(props) => {
    if (props.disabled && props.inverted) {
      return secondaryDisabledInvertedButtonStyles;
    }
    if (props.disabled) {
      return disabledButtonStyles;
    }
    if (props.inverted) {
      return secondaryInvertedButtonStyles;
    }
    return secondaryButtonStyles;
  }}
  &:hover {
    ${(props) => {
      if (props.disabled && props.inverted) {
        return secondaryDisabledInvertedButtonStyles;
      }
      if (props.disabled) {
        return disabledButtonStyles;
      }

      if (props.inverted) {
        return secondaryInvertedButtonHoverStyles;
      }

      return secondaryButtonHoverStyles;
    }}
  }
`;

export const LinkStyleButton = styled(PrimaryButton)`
  background-color: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.inverted ? props.theme.errorLight : props.theme.accentLight};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  padding: 0;
  border: none;
  :hover {
    background-color: transparent;
    border: none;
    color: ${(props) =>
      props.inverted ? props.theme.errorLight : props.theme.accentLight};
  }
`;

export {
  H1,
  H2,
  H3,
  P,
  SPAN,
  Header2Paragraph,
  Header3Paragraph,
  SubText,
  TextBox,
};
