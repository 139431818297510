import React, { useMemo, useState } from 'react';
import { map, size, get, find } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { dataOptions, actionOptions } from '../../../resolutions/constants';
import { Export, SortTableHead } from '../../../common/components';
import { ReactComponent as ListViewIcon } from '../../../common/assets/icons/list-icon.svg';
import { ReactComponent as TableIcon } from '../../../common/assets/icons/table-icon.svg';

import ExperimentsListViewComponent from '../ExperimentsListViewComponent';

import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  LinkTable,
  DeleteButton,
  DeleteCommentButton,
} from '../../../common/components/styled';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
  TitleButtonsDiv,
} from '../../../mentors/components/styled';
import { StyledRow } from '../styled';
import { getExperimentIcon } from './ExperimentDetailsPage';
import { deleteExperiment } from '../../ducks';
import {
  isAcceleratorSelector,
  isMentorSelector,
} from '../../../account/ducks';
import { TableDataContainer } from '../../../hypotheses/components/styled/HypothesisDetailsPage';
import { useHistory } from 'react-router-dom';

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere experiment</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea experimentului?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ExperimentsPage = (props) => {
  const { experiments, deleteExperiment, isMentor, isAccelerator } = props;
  const { push } = useHistory();

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [sortedExperiments, setSortedExperiments] = useState(experiments);
  const [selectedExperiment, setSelectedExperiment] = useState(undefined);

  const [listView, setListView] = useState(false);

  const handleDataOptions = (value, options) =>
    get(
      find(options, {
        value,
      }),
      'label'
    );

  const handleDeleteExperiment = async (id) => {
    await deleteExperiment(id);
    window.location.reload();
  };

  const handleOutcome = (status) => {
    switch (status) {
      case 'like':
        return 'Valideaza ipoteza';
      case 'dislike':
        return 'Invalideaza ipoteza';
      case 'inProgress':
        return 'Neconcludent';
      default:
        return 'Neconcludent';
    }
  };

  const getExperimentIconText = (outcome) => {
    switch (outcome) {
      case 'inProgress':
        return 'In Progress';
      case 'like':
        return 'Valideaza Ipoteza';
      case 'dislike':
        return 'Invalideaza Ipoteza';
      default:
        return 'In Progress';
    }
  };

  const getDownloadData = useMemo(
    () =>
      map(sortedExperiments, (experiment, index) => ({
        '#': index + 1,
        Titlu: experiment.title,
        Stadiu: experiment.status,
        Concluzie: getExperimentIconText(
          experiment.resolutions[0] && experiment.resolutions[0].outcome
        ),
        'Cost estimat (€)': experiment.estimatedPrice,
        Termen: experiment.deadline
          ? moment(experiment.deadline).format('DD/MM/YYYY')
          : '-',
        'Încredere în date ':
          experiment.resolutions[0] && experiment.resolutions[0].dataReliability
            ? handleDataOptions(
                experiment.resolutions[0] &&
                  experiment.resolutions[0].dataReliability,
                dataOptions
              )
            : '-',
        'Tip acțiune':
          experiment.resolutions[0] && experiment.resolutions[0].actionRequired
            ? handleDataOptions(
                experiment.resolutions[0].actionRequired,
                actionOptions
              )
            : '-',
      })),
    [sortedExperiments]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Experimente</Title>
          <CounterDiv>
            <CounterText>{size(sortedExperiments)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <div style={{ display: 'flex' }}>
          <Export
            withMargin={false}
            data={getDownloadData}
            filename="experimente.csv"
          />
          <div
            onClick={() => setListView(!listView)}
            style={{
              display: 'flex',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
          >
            {listView ? (
              <TableIcon style={{ height: '32px' }} />
            ) : (
              <ListViewIcon />
            )}
          </div>
        </div>
      </TitleButtonsDiv>
      {listView ? (
        <StyledRow>
          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="În constructie"
          />
          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="În derulare"
          />

          <ExperimentsListViewComponent
            experiments={experiments}
            filterStatus="Finalizat"
          />

          <ExperimentsListViewComponent
            resolutionExperiments
            experiments={experiments}
          />
        </StyledRow>
      ) : (
        <>
          <SortTableHead
            array={sortedExperiments}
            setArray={setSortedExperiments}
            cellSortKeys={[
              null,
              ['title'],
              ['status'],
              (el) => handleOutcome(el.resolution.outcome),
              ['estimatedPrice'],
              ['deadline'],
              ['resolution.dataReliability'],
              ['resolution.actionRequired'],
            ]}
            defaultSortKey={['title']}
          >
            <TableHeadCell alignLeft cellWidth="2%">
              <Heading2 center>#</Heading2>
            </TableHeadCell>
            <TableHeadCell alignLeft cellWidth="23%">
              <Heading2 center>Titlu</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Stadiu</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Concluzie</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="8%">
              <Heading2 center>Cost estimat (€)</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Termen</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="22%">
              <Heading2 center>Tip acțiune</Heading2>
            </TableHeadCell>
            <TableHeadCell cellWidth="10%">
              <Heading2 center>Încredere în date </Heading2>
            </TableHeadCell>

            <TableHeadCell cellWidth="5%">
              {!isMentor && !isAccelerator && (
                <Heading2 center>Acțiuni</Heading2>
              )}
            </TableHeadCell>
          </SortTableHead>
          <TableDataContainer className="scrollbar">
            {map(sortedExperiments, (experiment, index) => (
              <TableRow key={index}>
                <TableBodyCell alignLeft cellWidth="2%">
                  <Heading4Regular>{index + 1}</Heading4Regular>
                </TableBodyCell>
                <TableBodyCell alignLeft cellWidth="23%">
                  <LinkTable
                    maxWidth="95%"
                    onClick={() =>
                      push(
                        `/hypothesis/${
                          experiment.hypothesisId &&
                          experiment.hypothesisId[0] &&
                          experiment.hypothesisId[0]._id
                        }/experiment/${experiment._id}`
                      )
                    }
                  >
                    {experiment.title}
                  </LinkTable>
                </TableBodyCell>
                <TableBodyCell cellWidth="10%">
                  <Heading4Regular noEllipsis>
                    {getExperimentIcon('', experiment.status)}
                  </Heading4Regular>
                </TableBodyCell>
                <TableBodyCell cellWidth="10%">
                  <Heading4Regular noEllipsis>
                    {getExperimentIcon(
                      experiment.resolutions[0]
                        ? experiment.resolutions[0].outcome
                        : 'neutral'
                    )}
                  </Heading4Regular>
                </TableBodyCell>
                <TableBodyCell cellWidth="8%">
                  <Heading4Regular noEllipsis>
                    {experiment.estimatedPrice}
                  </Heading4Regular>
                </TableBodyCell>

                <TableBodyCell cellWidth="10%">
                  <Heading4Regular noEllipsis>
                    {experiment.deadline
                      ? moment(experiment.deadline).format('DD/MM/YYYY')
                      : '-'}
                  </Heading4Regular>
                </TableBodyCell>
                <TableBodyCell cellWidth="22%">
                  <Heading4Regular noEllipsis>
                    {experiment.resolutions[0] &&
                      experiment.resolutions[0].actionRequired &&
                      handleDataOptions(
                        experiment.resolutions[0] &&
                          experiment.resolutions[0].actionRequired,
                        actionOptions
                      )}
                  </Heading4Regular>
                </TableBodyCell>
                <TableBodyCell cellWidth="10%">
                  <Heading4Regular noEllipsis>
                    {experiment.resolutions[0] &&
                      experiment.resolutions[0].dataReliability &&
                      handleDataOptions(
                        experiment.resolutions[0].dataReliability,
                        dataOptions
                      )}
                  </Heading4Regular>
                </TableBodyCell>

                <TableBodyCell cellWidth="5%">
                  {!isMentor && !isAccelerator && (
                    <>
                      <DeleteButton
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                        onClick={() => {
                          setSelectedExperiment(experiment);
                          setOpenAlertMessage(true);
                        }}
                      />
                    </>
                  )}
                </TableBodyCell>
              </TableRow>
            ))}
          </TableDataContainer>
        </>
      )}
      <AlertMessage
        show={openAlertMessage}
        id={selectedExperiment ? selectedExperiment._id : undefined}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteExperiment}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  experiments: state.experiments.experiments,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});

const mapDispatchToProps = {
  deleteExperiment,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExperimentsPage)
);
