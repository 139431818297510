import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import React from 'react';
import { SpaceBetweenBox } from '../../common/components/styled/ReusablePositioningComponents';
import { H3, P } from '../../common/components/styled/ReusableTextComponents';
import { CrossIcon } from '../../common/assets/icons';
import { CommentsCounter } from './styled/Comments';

const CommentsHeader = ({ commentsLength, onClose }) => (
  <SpaceBetweenBox>
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <H3>Comentarii</H3>
        <CommentsCounter>
          <P>{commentsLength}</P>
        </CommentsCounter>
      </Box>
    </Box>
    <IconButton onClick={onClose}>
      <CrossIcon fill="blue" />
    </IconButton>
  </SpaceBetweenBox>
);

export default CommentsHeader;
