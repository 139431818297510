import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { get, map, find, filter, includes, isEmpty, isDate } from 'lodash-es';
import { Formik, useField, useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { CustomLabel } from '../../../core/components';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  SubmitButton,
  DatePicker,
  ButtonDiv,
  SaveIcon,
} from '../../../common/components/styled';
import { handleBmcLabels } from '../../../hypotheses/components/pages/HypothesisDetailsPageLegacy';

export const handleAutomaticStatus = (values) => {
  const today = new Date();
  const newValues = values;

  if (
    !isEmpty(values.title) &&
    !isEmpty(values.forValidation) &&
    !isEmpty(values.measure) &&
    !isEmpty(values.weConsider) &&
    isDate(values.deadline) &&
    isDate(values.startDate) &&
    today <= values.deadline &&
    today >= values.startDate
  ) {
    newValues.status = 'În derulare';
  } else if (today >= values.deadline) {
    newValues.status = 'Finalizat';
  } else {
    newValues.status = 'În constructie';
  }

  return newValues;
};

const statusOptions = [
  {
    value: 'În asteptare',
    label: 'În așteptare',
  },
  {
    value: 'În constructie',
    label: 'În construcție',
  },
  {
    value: 'În derulare',
    label: 'În derulare',
  },
  {
    value: 'Finalizat',
    label: 'Finalizat',
  },
];

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderDeadlineField = (props) => {
  const { values, name, controlId, label, setFieldValue, minDate, maxDate } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };
  return (
    <Form.Group as={Col} md="4">
      <Input
        component={DatePicker}
        id={controlId}
        as={DatePicker}
        name={name}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        height="60px"
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        style={{
          position: 'absolute',
          left: '5px',
          top: !fieldValue ? '28%' : '4%',
        }}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderCostField = (props) => {
  const { values, handleChange, name, controlId, label, type } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="4">
      <Input
        ref={fieldRef}
        id={controlId}
        type={type}
        name={name}
        height="60px"
        value={fieldValue || ''}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderValidateField = (props) => {
  const { values, handleChange, name, controlId, label, type, rows } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12">
      <Input
        height="100px"
        paddingTop="30px"
        id={controlId}
        ref={fieldRef}
        as={type}
        rows={rows}
        name={name}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderMeasureField = (props) => {
  const { values, handleChange, name, controlId, label, type, rows } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  return (
    <Form.Group as={Col} md="12">
      <Input
        height="100px"
        paddingTop="30px"
        id={controlId}
        ref={fieldRef}
        as={type}
        rows={rows}
        name={name}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  hypothesesOptions,
  setFieldValue,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderValidateField
        name="forValidation"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Pentru a valida experimentul vom:*"
        type="textarea"
        rows={3}
        placeholder="Descrie pe scurt cum vei valida experimentul."
      />
    </Form.Row>

    <Form.Row>
      <RenderMeasureField
        name="measure"
        height="100px"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik04"
        label="Vom măsura rezultatele prin:*"
        placeholder="Descrie pe scurt cum vei măsura rezultatele."
        type="textarea"
        rows={3}
      />
    </Form.Row>
    <Form.Row>
      <RenderDeadlineField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik05"
        name="startDate"
        maxDate={values.deadline}
        label="Dată începere"
        placeholder="dd/mm/yy"
      />
      <RenderDeadlineField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik06"
        name="deadline"
        minDate={values.startDate}
        label="Dată finalizare"
        placeholder="dd/mm/yy"
      />
      <RenderCostField
        name="estimatedPrice"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik07"
        label="Cost estimativ (€):*"
        type="number"
      />
    </Form.Row>

    <ButtonDiv justifyLeft>
      <SubmitButton type="submit" style={{ padding: '20px' }}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddExperimentForm = (props) => {
  const { onSubmit, hypotheses, experiment, isEditMode } = props;

  const hypothesesOptions = map(hypotheses, (el) => ({
    label: `${handleBmcLabels(el.columnBMC)}: ${el.title}`,
    value: `${el._id}`,
  }));

  const hypothesisIdsToSearch =
    experiment && map(experiment.hypothesisId, (el) => el._id);

  const defaultExperiment = {
    ...experiment,
    hypothesisId:
      experiment &&
      filter(hypothesesOptions, (elem) =>
        includes(map(hypothesisIdsToSearch), elem.value)
      ),
    startDate:
      experiment && experiment.startDate && new Date(experiment.startDate),
    deadline:
      experiment && experiment.deadline && new Date(experiment.deadline),
    status:
      experiment &&
      find(statusOptions, {
        value: experiment.status,
      }),
  };

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(handleAutomaticStatus(values));
  };

  const schema = Yup.object({
    status: Yup.string(),
    estimatedPrice: Yup.number(),
    forValidation: Yup.string().required('Câmp obligatoriu'),
    measure: Yup.string().required('Câmp obligatoriu'),
    startDate: Yup.string().nullable(true),
    deadline: Yup.string().nullable(true),
  });

  const defaultValues = {
    title: '',
    status: '',
    estimatedPrice: 0,
    forValidation: '',
    measure: '',
    weConsider: '',
    startDate: '',
    deadline: '',
  };

  const initialValues = isEditMode ? defaultExperiment : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          hypothesesOptions={hypothesesOptions}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddExperimentForm);
