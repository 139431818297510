import styled, { css } from 'styled-components';

export const CounterDiv = styled.div`
  background: ${(props) => props.theme.white};
  height: 30px;
  width: 27px;
  border: 1px solid ${(props) => props.theme.blueAccent};
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '15px')};
`;
export const CounterText = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.blueAccent};
`;

export const CounterDivHypothesisDetails = styled.div`
  height: 30px;
  width: 27px;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '15px')};
  padding-top: 4px;
`;
export const CounterTextHypothesisDetails = styled.span`
  font-weight: bold;
  font-size: 17px;
  line-height: 16px;
  color: ${(props) => props.theme.blueAccent};
`;
export const TitleCounterDiv = styled.div`
  width: ${(props) => (props.width ? props.width : '50%')};
  display: flex;
  margin-bottom: ${(props) => (props.smallMargin ? '15px' : '39px')};
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;
