import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
  ExpandButtonContainer,
  MarginContainer,
  MrlSelectedStepContainer,
  StepNumber,
} from './styled/MrlStyled';
import { SubText } from '../../common/components/styled/ReusableTextComponents';
import { capitalizeFirstLetter } from '../../common/functions/genericHelpers';

function MrlStep({
  number,
  title,
  isCompleted,
  isOpen,
  subtitle,
  completionStatus,
}) {
  return (
    <MrlSelectedStepContainer isOpen={isOpen}>
      <MarginContainer
        display="flex"
        width="100%"
        marginLeft={isOpen ? '0.3vw' : '-0.6vw'}
      >
        {isOpen && (
          <MarginContainer
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="16px"
          >
            <StepNumber isCompleted={isCompleted}>{number + 1}</StepNumber>

            <MarginContainer marginLeft="1vh" paddingBottom="3px">
              <>
                <SubText elipsed>{`${capitalizeFirstLetter(
                  title
                )} | ${completionStatus}`}</SubText>
                <SubText light faded elipsed width="10vw">
                  {subtitle || 'Lorem ipsum'}
                </SubText>
              </>
            </MarginContainer>
          </MarginContainer>
        )}
      </MarginContainer>
      {isOpen && (
        <ExpandButtonContainer>
          <FontAwesomeIcon icon={faChevronRight} color="#2A5AB7" />
        </ExpandButtonContainer>
      )}
    </MrlSelectedStepContainer>
  );
}

export default MrlStep;
