import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { logout } from '../../ducks';

const LogoutPage = ({ logout, location }) => {
  useEffect(() => {
    logout();
  });

  return <Redirect to={{ pathname: '/', state: { from: location } }} />;
};

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapDispatchToProps = { logout };

export default connect(undefined, mapDispatchToProps)(LogoutPage);
