import styled from 'styled-components';

export const TableHead = styled.th`
  color: ${(props) => props.theme.blueAccent};
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  font-family: 'Open Sans', serif;
`;

export const TableData = styled.td`
  color: ${(props) => props.theme.blueAccent};
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: 'Open Sans', serif;
`;
