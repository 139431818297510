import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { PageContainer, SwotCardContainer } from '../styled/ContextAnalysis';
import { SWOT_TYPES } from '../../constants';
import { currentTeamIdSelector } from '../../../common/ducks';
import { SwotModal } from '../modals/SwotModal';
import { getAllSwotElementsByTeamId, deleteSwotById } from '../../ducks';

import { SwotCard } from '../SwotCard';
import { SwotDrawer } from '../SwotDrawer';

const SwotPage = ({
  getAllSwotElementsByTeamId,
  teamId,
  teamSwots = {},
  deleteSwotById,
}) => {
  const [isSwotModalOpen, setIsSwotModalOpen] = useState(false);
  const [swotType, setSwotType] = useState('');
  const [selectedSwotElement, setSelectedSwotElement] = useState(null);
  const [isSwotDrawerOpen, setIsSwotDrawerOpen] = useState(false);

  const {
    strengths = [],
    weaknesses = [],
    opportunities = [],
    threats = [],
  } = teamSwots;

  useEffect(() => {
    if (teamId) {
      getAllSwotElementsByTeamId(teamId);
    }
  }, [getAllSwotElementsByTeamId]);

  const handleAddSwotElement = (swotType) => {
    setSwotType(swotType);
    setIsSwotModalOpen(true);
    setSelectedSwotElement(null);
  };

  const handleDeleteSwotElement = (swotId) => {
    deleteSwotById(swotId, teamId);
    setIsSwotDrawerOpen(false);
    setSelectedSwotElement(null);
  };

  const handleEditSwotElement = (swotElement) => {
    setIsSwotModalOpen(true);
    setSelectedSwotElement(swotElement);
    setIsSwotDrawerOpen(false);
  };

  const handleViewSwotElement = (swotElement) => {
    setIsSwotDrawerOpen(true);
    setSelectedSwotElement(swotElement);
  };

  const handleCloseDrawer = () => {
    setIsSwotDrawerOpen(false);
    setSelectedSwotElement(null);
  };

  return (
    <PageContainer className="scrollbar">
      <Grid
        container
        spacing={2}
        style={{ height: '100%', alignItems: 'stretch' }}
      >
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.strengths}
              swotSegmentData={strengths}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleDeleteSwotElement}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.weaknesses}
              swotSegmentData={weaknesses}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleDeleteSwotElement}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.opportunities}
              swotSegmentData={opportunities}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleDeleteSwotElement}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.threats}
              swotSegmentData={threats}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleDeleteSwotElement}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
      </Grid>

      <SwotModal
        isModalOpen={isSwotModalOpen}
        setIsModalOpen={setIsSwotModalOpen}
        swotType={swotType}
        selectedSwotElement={selectedSwotElement}
      />

      <SwotDrawer
        isDrawerOpen={isSwotDrawerOpen}
        setIsDrawerOpen={setIsSwotDrawerOpen}
        swotType={swotType}
        selectedSwotElement={selectedSwotElement}
        handleCloseDrawer={handleCloseDrawer}
        handleDeleteSwotElement={handleDeleteSwotElement}
        handleEditSwotElement={handleEditSwotElement}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  teamSwots: state.contextAnalysis.teamSwots,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  getAllSwotElementsByTeamId,
  deleteSwotById,
};

export default connect(mapStateToProps, mapDispatchToProps)(SwotPage);
