import React from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
  TextBox,
} from '../../common/components/styled/ReusableTextComponents';
import {
  CrossIcon,
  PencilIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';
import { activityTypeOptions, categoryOptions } from './forms/DetailsForm';

export const DetailsDrawer = ({
  isDrawerOpen,
  handleCloseDrawer,
  selectedDetailsElement,
  handleEditDetailsElement,
  handleDeleteDetailsElement,
  formatDate,
}) => {
  const searchLabel = (value, collection) =>
    collection.find((item) => item.value === value)?.label || '';

  const {
    description,
    createdAt,
    category,
    activityType,
    links,
    contactDisponibility,
    levelOfInterest,
  } = selectedDetailsElement;
  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box sx={{ width: '400px', padding: '20px' }}>
        {selectedDetailsElement && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <H3 marginBottom="20px">Vizualizare detaliu</H3>
              <IconButton sx={{ padding: 0 }} onClick={handleCloseDrawer}>
                <CrossIcon fill="blue" />
              </IconButton>
            </Box>

            {description && (
              <TextBox marginBottom="20px">
                <P faded light>
                  Descriere
                </P>
                <P light>{description}</P>
              </TextBox>
            )}

            <Box sx={{ display: 'flex', gap: '35px', width: '100%' }}>
              <Box>
                {createdAt && (
                  <TextBox faded marginBottom="20px">
                    <P faded light>
                      Data creare
                    </P>
                    <P light>{formatDate(createdAt)}</P>
                  </TextBox>
                )}

                {activityType && (
                  <TextBox faded marginBottom="20px">
                    <P faded light>
                      Tip activitate
                    </P>
                    <P light>
                      {searchLabel(activityType, activityTypeOptions)}
                    </P>
                  </TextBox>
                )}

                {typeof contactDisponibility === 'boolean' && (
                  <TextBox faded marginBottom="20px">
                    <P faded light>
                      Semnale de comparare?
                    </P>
                    <P light>{contactDisponibility ? 'Da' : 'Nu'}</P>
                  </TextBox>
                )}

                {links && !!links.length && (
                  <TextBox faded>
                    <P faded light>
                      Link-uri
                    </P>
                    {links &&
                      links.map((link, index) => (
                        <P light key={index}>
                          {link.name}
                        </P>
                      ))}
                  </TextBox>
                )}
              </Box>
              <Box>
                {category && (
                  <TextBox faded marginBottom="20px">
                    <P faded light>
                      Categorie
                    </P>
                    <P light>{searchLabel(category, categoryOptions)}</P>
                  </TextBox>
                )}

                {levelOfInterest && (
                  <TextBox faded>
                    <P faded light>
                      Interes
                    </P>
                    <P light>{levelOfInterest < 3 ? 'Nu' : 'Da'}</P>
                  </TextBox>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
                paddingTop: '20px',
                borderTop: '1px solid #DEE7FC',
              }}
            >
              <PrimaryButton
                onClick={() => handleEditDetailsElement()}
                inverted
                icon={PencilIcon}
                iconColor="#74A3FE"
                marginRight="10px"
              >
                Editeaza
              </PrimaryButton>
              <SecondaryButton
                onClick={handleDeleteDetailsElement}
                icon={TrashIconOutlined}
                iconColor="#fff"
              >
                Sterge
              </SecondaryButton>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};
