// TRENDS
export const GET_ALL_TRENDS = 'trends/get-all/teams/:teamId';
export const DELETE_TREND_BY_ID = 'trends/delete/:trendId';

// CHAT GPT
export const POST_CHAT_GPT_CONVERSATION = 'openai/chat';

// COMMENTS AND NOTIFICATIONS
export const GET_COMMENTS_CSV = 'comments/export-csv/teams/:teamId';
export const PUT_MARK_COMMENT_AS_READ = 'comments/mark-read/';

// PAYMENTS
export const START_CHECKOUT_SESSION = 'create-checkout-session';
export const UPDATE_USER_SUBSCRIPTION = 'updateSubscription';

export const PREVIEW_UPDATE_USER_SUBSCRIPTION = 'previewUpdateSubscription';

export const CANCEL_SUBSCRIPTION = 'cancelSubscription';
export const USER_NEXT_PAYMENT = 'payments-next-one';

// CHAPTERS
export const GET_ALL_CHAPTERS = 'chapters/get-all';

export const GET_CHAPTER_BY_ID = 'chapters/get-by-id/:chapterId';

export const POST_ADD_CHAPTER = 'chapters/add';

export const PUT_EDIT_CHAPTER = 'chapters/edit/:chapterId';

export const DELETE_CHAPTER_BY_ID = 'chapters/delete/:chapterId';

export const PUT_ORDER_CHAPTERS = 'chapters/order';

// ARTICLES

export const GET_ALL_ARTICLES = 'articles/get-all';

export const GET_ARTICLE_BY_ID = 'articles/get-by-id/:articleId';

// DETAILS
export const CREATE_DETAIL = 'details/add/teams/:teamId';

export const GET_ALL_DETAILS = 'details/get-all/teams/:teamId';

export const DELETE_DETAIL_BY_ID = 'details/delete/:detailId';

// TEAMS
export const GET_TEAM_MEMBERS = 'team/:teamId/members';
export const GET_TEAM_MENTORS = 'team/:teamId/mentors';
export const ADD_TEAM_MEMBER = 'team/:teamId/members/add';
export const ADD_TEAM_MENTOR = 'team/:teamId/mentors/add';
export const GET_TEAM_COHORTS = 'team/get-all/:cohortId';
export const JOIN_TEAM_COHORT = 'team/join-cohort/:teamId';
export const REMOVE_TEAM_MEMBER = 'team/remove-member/:userId/:teamId';
export const GET_TEAM_BY_ID = 'team/get/:teamId';
export const POST_NEW_TEAM = 'team/add';
export const PUT_ACCEPT_TEAM_INVITE = 'team/accept-invite';
export const PUT_REJECT_TEAM_INVITE = 'team/reject-invite';
export const DELETE_TEAM_BY_ID = '/team/:teamId';

//Activities

export const GET_ALL_ACTIVITIES_BY_TEAM = 'activities/get-all/teams/:teamId';

export const CREATE_ACTIVITY = 'activities/add/teams/:teamId';

export const UPDATE_ACTIVITY = 'activities/edit/:activityId';

export const DELETE_ACTIVITY = 'activities/delete/:activityId';

export const GET_ACTIVITY_BY_ID = 'activities/get-by-id/:activityId';

//Conclusions

export const CREATE_CONCLUSION = 'conclusions/add/activities/:activityId';
export const UPDATE_CONCLUSION_BY_ID = 'conclusions/edit/:conclusionId';
export const DELETE_CONCLUSION_BY_ID = 'conclusions/delete/:conclusionId';
export const GET_ALL_CONCLUSIONS_BY_TEAM = 'conclusions/get-all/teams/:teamId';
export const GET_CONCLUSIONS_DETAILS_BY_ID =
  'conclusions/get-by-id/:conclusionId';

export const CREATE_TASK = 'tasks/add/activities/:activityId';
export const UPDATE_TASK_BY_ID = 'tasks/edit/:taskId';
export const DELETE_TASK_BY_ID = 'tasks/delete/:taskId';
export const GET_ALL_TASKS_BY_TEAM = 'tasks/get-all/teams/:teamId';
export const GET_ALL_TASKS_BY_ACTIVITY = 'tasks/get-all/activities/:activityId';
export const GET_TASK_DETAILS_BY_ID = 'tasks/get-by-id/:taskId';

// QUESTIONNAIRE
export const GET_QUESTIONNAIRE_PROGRESS = 'questions/get-all/:userId';
export const POST_QUESTIONNAIRE_ANSWER = 'questions/add';

export const PUT_FINISH_QUESTIONAIRE = 'account/complete-questionnaire';

// PROFILE
export const GET_USER_PROFILE = 'profile/get';

export const GET_ALL_TEAMS = 'user/:userId/get-all-teams';

// PAYMENTS
export const GET_ALL_SUBSCRIPTIONS = 'products-get-all';

// REGISTRATION
export const GET_INVITED_MEMBER_DATA =
  'account/get-invited-member-data/:memberToken';
export const POST_SIGN_UP_INVITED_MEMBER = 'account/sign-up-invited-member';

// ONBOARDING
export const UPDATE_ONBOARDING_PROGRESS = 'account/update-onboarding';
export const RESET_ONBOARDING_PROGRESS = 'account/reset-onboarding';
