import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import { SaveIconOutlined } from '../../common/assets/icons';

const ManageTeamEquityForm = ({ members }) => {
  const initialValues = {};

  members.map((el, idx) => {
    let role = el?.equity ? 'Asociat' : 'Membru';

    initialValues[`equity-${idx}`] = el?.equity || 0;
    initialValues[`role-${idx}`] = el?.isInvited ? 'Invitat' : role;
  });

  const handleSubmit = (values) => {
    //TODO implement a bulk update of the equity values

    console.log(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {members.map((entry, idx) => (
              <>
                <CustomTextField
                  type="number"
                  id={`equity-${idx}`}
                  label={`${entry?.lastName || entry?.email}`}
                  md={6}
                />
                <CustomTextField
                  disabled
                  id={`role-${idx}`}
                  label="Tag"
                  md={6}
                />
              </>
            ))}
          </Grid>

          <PrimaryButton type="submit" icon={SaveIconOutlined}>
            Salveaza
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default ManageTeamEquityForm;
