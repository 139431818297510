/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { get, find } from 'lodash-es';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Tooltip as MuiTooltip } from '@mui/material';
import MemberIcon from '../../assets/icons/Avatar_Membru_White.svg';

import {
  ImageIconRound,
  NavigationBarIconContainer,
  NavigationBarImageContainer,
} from '../styled/NavigationBar';

import {
  NavigationBarLink,
  PositionBadge,
  Heading2,
  StyledLink,
} from '../styled';
import {
  setCurrentUserTeam,
  currentTeamIdSelector,
  resetUserTeams,
} from '../../ducks';

import { getUserRole } from '../../services/roleBadgeColors';

import {
  BmcNavIcon,
  ContextAnalysisNavIcon,
  HypothesesNavIcon,
  IdeasNavIcon,
  LeaderboardNavIcon,
  NotificationsNavIcon,
  PersonSearchIcon,
  ResourcesNavIcon,
} from '../../assets/icons/navbar';
import { getAllComments } from '../../../comentsAndNotifications/ducks';

const FounderNavigationMenu = (props) => {
  const {
    teamId,
    userTeams,
    userId,
    resetUserTeams,
    location,
    userProfile,
    role,
  } = props;

  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const dispatch = useDispatch();
  const userHasUnreadcomments = useSelector(
    (state) => state.comments.unreadComments
  );
  const { push } = useHistory();

  const fetchComments = async () => {
    if (!userId || !teamId) return;

    dispatch(
      getAllComments({
        teamId,
        filterParams: {
          isMainThread: true,
        },
        userId,
      })
    );
  };

  useEffect(() => {
    fetchComments();
  }, [userId, teamId]);

  useEffect(() => {
    const { hasCompletedQuestionnaire } = userProfile;
    if (!hasCompletedQuestionnaire) {
      return push('/form');
    }

    if (!teamId && !userTeams) push('/profile');
    if (!!teamId && location.pathname === '/home') push('/canvas');
    setSelectedTeam(find(userTeams, (team) => !!team.selected));
    return () => resetUserTeams();
  }, []);

  return (
    <>
      <li className="side-nav-item">
        <NavigationBarLink
          activeClassName="activeNavItem"
          imageitem="true"
          to="/profile?tab=profile"
          className="side-sub-nav-link side-nav-link my-1"
          style={{
            padding: '8px 30px',
          }}
        >
          {!!userProfile && (
            <MuiTooltip
              title="Profilul meu"
              classes={{ tooltip: 'nav-tooltip' }}
              placement="right"
            >
              <NavigationBarImageContainer className="nav-profile-container">
                <ImageIconRound
                  withBorder={!!userProfile.profilePicture}
                  src={userProfile.profilePicture || MemberIcon}
                />
              </NavigationBarImageContainer>
            </MuiTooltip>
          )}
          <PositionBadge className="mb-1">
            <Heading2 color="#15BB89">
              {getUserRole(
                role,
                userId,
                selectedTeam && get(selectedTeam, 'createdBy'),
                selectedTeam &&
                  get(find(selectedTeam.members, { userId }), 'equity')
              )}
            </Heading2>
          </PositionBadge>
        </NavigationBarLink>
      </li>
      <li
        id="context-analysis-1"
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
      >
        <MuiTooltip
          title="Analiza contextului"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/opportunity-analysis?tab=trends"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <ContextAnalysisNavIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      <li
        id="mrl-nav"
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
      >
        <MuiTooltip
          title="Business Model Canvas"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/canvas"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <BmcNavIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      <li
        id="mrl-nav"
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
      >
        <MuiTooltip
          title="Resurse"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/resources?tab=articles"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <PersonSearchIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      {/* <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}> */}
      {/*   <MuiTooltip */}
      {/*     title="Ipoteze" */}
      {/*     classes={{ tooltip: 'nav-tooltip' }} */}
      {/*     placement="right" */}
      {/*   > */}
      {/*     <NavigationBarLink */}
      {/*       activeClassName="activeNavItem" */}
      {/*       className="side-sub-nav-link side-nav-link" */}
      {/*       to="/hypotheses" */}
      {/*       onClick={(e) => !teamId && e.preventDefault()} */}
      {/*     > */}
      {/*       <NavigationBarIconContainer className="icon-container"> */}
      {/*         <HypothesesNavIcon /> */}
      {/*       </NavigationBarIconContainer> */}
      {/*     </NavigationBarLink> */}
      {/*   </MuiTooltip> */}
      {/* </li> */}
      <li
        id="mrl-nav"
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
      >
        <MuiTooltip
          title="Boards"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/boards?tab=activities"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <HypothesesNavIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      <li
        style={{
          margin: '0 5px 10px 5px',
          borderBottom: '2px solid rgba(255, 255, 255, 0.25)',
        }}
      />

      <li className="side-nav-item">
        <MuiTooltip
          title="Idea mea"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className={'side-sub-nav-link side-nav-link '}
            to="/idea?tab=description"
          >
            <NavigationBarIconContainer className="icon-container">
              <IdeasNavIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      <li
        id="notifications-bell"
        className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}
      >
        <MuiTooltip
          title="Notificări"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/notifications"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <NotificationsNavIcon unread={userHasUnreadcomments} />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>

      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <MuiTooltip
          title="Leaderboards"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            to="/leaderboard"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <NavigationBarIconContainer className="icon-container">
              <LeaderboardNavIcon />
            </NavigationBarIconContainer>
          </NavigationBarLink>
        </MuiTooltip>
      </li>
      <li className="side-nav-item">
        <MuiTooltip
          title="Resurse"
          classes={{ tooltip: 'nav-tooltip' }}
          placement="right"
        >
          <StyledLink
            href="https://ptic.ro/launchpad/"
            target="_blank"
            rel="noopener noreferrer"
            className="side-sub-nav-link side-nav-link"
          >
            <NavigationBarIconContainer className="icon-container">
              <ResourcesNavIcon />
            </NavigationBarIconContainer>
          </StyledLink>
        </MuiTooltip>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  userProfile: get(state.userProfile, 'userProfile'),
  userTeams: state.common.userTeams.userTeams,
  teamId: currentTeamIdSelector(state.common.userTeams),
  role: get(state.account.login, 'user.role'),
});

const mapDispatchToProps = {
  setCurrentUserTeam,
  resetUserTeams,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FounderNavigationMenu)
);
