import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { theme } from '../../core/styles';
import { ArrowDown } from '../assets/icons';

const cellStyle = {
  maxWidth: '100px',
  textAlign: 'center',
  color: theme.blueAccent,
};

const headerCellStyle = {
  maxWidth: '100px',
  textAlign: 'center',
  color: theme.blueAccent,
  fontWeight: 'bold',
  padding: '0 0 10px 0',
};

const divStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  maxWidth: '100%',
};

const centeredDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '5px',
  cursor: 'pointer',
};

function GenericTableComponent({ rows, headerCells, handleSelectedRow }) {
  const [sortState, setSortState] = useState({
    column: 'number',
    order: 'asc',
  });
  const [sortedRows, setSortedRows] = useState([...rows]);

  const handleSort = (column) => {
    let newOrder = 'asc';
    if (sortState.column === column) {
      if (sortState.order === 'asc') {
        newOrder = 'desc';
      } else {
        newOrder = 'asc';
      }
    }

    const isNumeric = (str) => /^-?\d+(\.\d+)?$/.test(str);
    const isDate = (str) => !isNaN(Date.parse(str));

    const sortedData = [...rows].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (isNumeric(aValue) && isNumeric(bValue)) {
        return newOrder === 'asc'
          ? parseFloat(aValue) - parseFloat(bValue)
          : parseFloat(bValue) - parseFloat(aValue);
      }

      if (isDate(aValue) && isDate(bValue)) {
        return newOrder === 'asc'
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      return newOrder === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    setSortedRows(sortedData);
    setSortState({ column, order: newOrder });
  };

  useEffect(() => {
    setSortedRows([...rows]);
  }, [rows]);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ '& > th': { borderBottom: '1px solid #B1CCFE' } }}>
            {headerCells.map((cell, index) => (
              <TableCell key={index} style={headerCellStyle}>
                <Box sx={centeredDivStyle}>
                  <span>{cell.content}</span>
                  {!cell.hideSortIcon && (
                    <Box
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSort(cell.key);
                      }}
                      sx={{
                        transform:
                          sortState.column === cell.key &&
                          sortState.order === 'desc'
                            ? 'rotate(180deg)'
                            : '',
                      }}
                    >
                      <ArrowDown />
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, index) => {
            const { rowId, ...rowElements } = row;
            return (
              <TableRow
                onClick={() => handleSelectedRow(rowId)}
                key={index}
                sx={{
                  '& > *': { borderBottom: '1px solid #EFF3FE' },
                  '&:hover': { backgroundColor: '#DEE7FC', cursor: 'pointer' },
                }}
              >
                {Object.entries(rowElements).map(([key, cell], i) => (
                  <TableCell key={i} style={cellStyle}>
                    {typeof cell === 'string' ? (
                      <div style={divStyle} title={cell}>
                        {cell}
                      </div>
                    ) : (
                      <div style={centeredDivStyle}>{cell}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTableComponent;
