import { runGetApiRequest, runPostApiRequest } from '../api/Api';
import {
  GET_INVITED_MEMBER_DATA,
  POST_SIGN_UP_INVITED_MEMBER,
} from '../common/constants/apiRoutes';

export const getInvitedMemberData = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_INVITED_MEMBER_DATA,
    params: payload.params,
  });

export const postRegisterInvitedMember = ({ payload }) =>
  runPostApiRequest({
    endpoint: POST_SIGN_UP_INVITED_MEMBER,
    payload: payload?.userData,
  });
