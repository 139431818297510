import styled from 'styled-components';

export const ProfileImageUploaderInput = styled.input`
  display: none;
`;

export const ProfileImageUploaderText = styled.p`
  vertical-align: middle;
  text-align: center;
  margin-top: 10px;
`;

export const ProfileImageUploaderLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 13px;
`;

export const ProfileImageUploader = styled.div``;
