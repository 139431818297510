import styled from 'styled-components';
import { Row, Button } from 'react-bootstrap';

export const TeamMembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 10px;
`;

export const AddButton = styled(Button)`
  position: absolute;
  top: -58px;
  right: 0;
  float: right;
`;
export const MembersDiv = styled.div`
  overflow-y: auto;
  padding-right: 4px;
`;
export const IconLabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const WhiteCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '22vw')};
  width: ${(props) => (props.width ? props.width : '22vw')};
  height: ${(props) => props.height && props.height};
  padding: 10px;
  margin-right: 1vh;
  margin-bottom: 1vh;
  font-family: Work Sans;
  font-size: 12px;
  color: #3f3f3f;
  font-weight: 400;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  overflow-y: ${({ noScrollBar }) => (noScrollBar ? '' : 'auto')};
`;

export const WhiteCardTitle = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
`;

export const InvestitionElement = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
`;

export const InvestitionValue = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const InvestitionLabel = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-left: 5px;
`;

export const ProgressBarContainer = styled.div`
  width: 85%;
  height: 2vh;
  background-color: #dee7fc;
  border-radius: 19px;
  margin-left: auto;
  margin-right: auto;
`;

export const ProgressElement = styled.div`
  height: 2vh;
  width: ${(props) => (props.width ? props.width : '1vw')};
  background-color: #74a3fe;
  border-radius: 19px;
`;

export const InvestitionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #74a3fe;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 1vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width && width};
`;

export const CustomContainer = styled.div`
  display: ${({ display }) => display && display};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${({ color }) => color || 'black'};
`;

export const CustomParagraph = styled.p`
  font-size: 14px;
  color: ${({ color }) => color || 'black'};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
`;

export const TeamProfileContainer = styled.div`
  height: 90.2vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 70vw;
  padding-left: 10px;
`;

export const PublicTeamSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  & > label {
    margin: 0;
  }
`;
