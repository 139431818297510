import React, { useState, useEffect, useMemo } from 'react';
import { get, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { deleteUser, deleteUserProfile, loadUsers } from '../../ducks';
import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  Loading,
  DeleteCommentButton,
} from '../../../common/components/styled';
import { Export, SortTableHead } from '../../../common/components';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
  TitleButtonsDiv,
} from '../../../mentors/components/styled';
import { TableDataContainer } from '../../../hypotheses/components/styled/HypothesisDetailsPage';

const AlertMessage = ({ show, setShow, onSubmit, user }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(user);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere utilizator</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea utilizatoruli{' '}
          {get(user, 'firstName')} {get(user, 'lastName')}?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const UsersPage = (props) => {
  const {
    users,
    isLoading,
    deleteUser,
    loadUsers,
    isDeleting,
    deleteUserProfile,
  } = props;
  const [sortedUsers, setSortedUsers] = useState(users);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [user, setUser] = useState(undefined);

  const handleDeleteUser = async (user) => {
    const id = get(user, 'userId._id');
    if (id) await deleteUser(id);
    if (!id) await deleteUserProfile(user._id);
    await loadUsers();
  };

  useEffect(() => {
    setSortedUsers(users);
  }, [users]);

  const getDownloadData = useMemo(
    () =>
      map(sortedUsers, (user, index) => ({
        '#': index + 1,
        Nume: user.lastName,
        Prenume: user.firstName,
        Telefon: user.phone,
        Email: user.email,
        Echipe: map(user.teams, (team) => team.name).join(';'),
        'Cum ai aflat de noi': user.howFindUs,
        Rol: user.userId && user.userId.role,
      })),
    [sortedUsers]
  );

  return (
    <>
      <TitleButtonsDiv>
        <TitleCounterDiv>
          <Title>Utilizatori</Title>
          <CounterDiv>
            <CounterText>{size(sortedUsers)}</CounterText>
          </CounterDiv>
        </TitleCounterDiv>
        <Export
          data={getDownloadData}
          filename="utilizatori.csv"
          withMargin={false}
        />
      </TitleButtonsDiv>
      <SortTableHead
        array={sortedUsers}
        setArray={setSortedUsers}
        cellSortKeys={[
          null,
          ['firstName'],
          ['lastName'],
          ['phone'],
          ['email'],
          (el) => map(el.teams, (team) => team.name).join('\n'),
          ['howFindUs'],
          ['createdAt'],
          (el) => el.userId && el.userId.role,
          null,
        ]}
        defaultSortKey={['firstName']}
      >
        <TableHeadCell cellWidth="2%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="7%">
          <Heading2>Nume</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="7%">
          <Heading2>Prenume</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="8%">
          <Heading2>Telefon</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="18%">
          <Heading2>Email</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="15%">
          <Heading2>Echipe </Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="15%">
          <Heading2>Cum a aflat de noi</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2>Data creării</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="10%">
          <Heading2>Rol</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="8%">
          <Heading2>Acțiuni</Heading2>
        </TableHeadCell>
      </SortTableHead>
      <TableDataContainer className="scrollbar">
        {map(sortedUsers, (user, index) => (
          <TableRow key={index}>
            <TableBodyCell cellWidth="2%">
              <Heading4Regular>{index + 1}</Heading4Regular>
            </TableBodyCell>
            <TableBodyCell alignLeft cellWidth="7%">
              <Heading4Regular>{user.lastName}</Heading4Regular>
            </TableBodyCell>
            <TableBodyCell alignLeft cellWidth="7%">
              <Heading4Regular>{user.firstName}</Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="8%">
              <Heading4Regular>{user.phone}</Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="18%">
              <Heading4Regular>{user.email}</Heading4Regular>
            </TableBodyCell>

            <TableBodyCell cellWidth="15%">
              <Heading4Regular>
                {' '}
                {map(user.teams, (team) => team.name).join('\n')}
              </Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="15%">
              <Heading4Regular>{user.howFindUs}</Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="10%">
              <Heading4Regular>
                {user.createdAt && moment(user.createdAt).format('DD/MM/YYYY')}
              </Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="10%">
              <Heading4Regular>
                {user.userId && user.userId.role}
              </Heading4Regular>
            </TableBodyCell>
            <TableBodyCell cellWidth="8%">
              <Heading4Regular>
                <DeleteCommentButton
                  onClick={() => {
                    setUser(user);
                    setOpenAlertMessage(true);
                  }}
                />
              </Heading4Regular>
            </TableBodyCell>
          </TableRow>
        ))}
      </TableDataContainer>
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteUser}
        user={user}
      />
      <Loading isLoading={isDeleting || isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.admin.users.users,
  isLoading: state.admin.users.isLoading,
  isDeleting: state.admin.users.isDeleting,
});

const mapDispatchToProps = { deleteUser, deleteUserProfile, loadUsers };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersPage)
);
