import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { AddHypothesisForm } from '../forms';
import { loadHypotheses, editHypothesis, addHypothesis } from '../../ducks';

import { currentTeamIdSelector } from '../../../common/ducks';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { createTargetIndicators } from '../../../targetIndicators/ducks/targetIndicators';
import { useHistory } from 'react-router-dom';

const AddHypothesisModalLegacy = (props) => {
  const {
    addHypothesis,
    isCreating,
    isEditing,
    isModalOpen,
    teamId,
    isEditMode,
    loadHypotheses,
    closeModal,
    entityId,
    editHypothesis,
    loadHypothesis,
    setIsModalOpen,
    global,
    firstName,
    lastName,
    hypothesis,
  } = props;
  const { push } = useHistory();

  useEffect(() => {
    if (teamId) {
      loadHypotheses(teamId);
    } else {
      Promise.resolve();
    }
  }, [teamId, loadHypotheses]);

  const handleModalClose = () =>
    global ? closeModal() : setIsModalOpen(false);

  const onSubmit = async (values) => {
    const createdBy = `${firstName} ${lastName} `;
    if (isEditMode) {
      try {
        await editHypothesis(values, entityId);
        // await editTargetIndicator(targetIndicatorId)
        handleModalClose();
        await loadHypothesis(entityId);
        push(`/hypothesis/${entityId}`);
      } catch (error) {
        // handle error if any
      }
    } else {
      try {
        const targetIndicatorsPayload = {
          targetIndicators: [],
          hypothesisId: hypothesis._id,
        };
        const body = await addHypothesis(values, teamId, createdBy);
        await createTargetIndicators();

        if (global) await loadHypotheses(teamId);
        push(`/hypothesis/${body.data._id}`);
        handleModalClose();
      } catch (error) {
        // handle error if any
      }
    }
  };

  const handleCloseModal = () => {
    handleModalClose();
  };

  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="modal-responsive scrollbar-modal">
        <TitleButtonsDiv marginBottom="30px">
          <Title lineHeight="28px" fontSize="24px">
            {isEditMode ? 'Editează ipoteza' : 'Adaugă o ipoteză nouă'}
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <AddHypothesisForm
          global={global}
          onSubmit={onSubmit}
          isEditMode={isEditMode}
          hypothesis={hypothesis}
        />
      </Modal.Body>

      <Loading isLoading={isEditMode ? isEditing : isCreating} />
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.hypotheses.isEditing,
  isCreating: state.hypotheses.isCreating,
  teamId: currentTeamIdSelector(state.common.userTeams),
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {
  addHypothesis,
  loadHypotheses,
  editHypothesis,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddHypothesisModalLegacy)
);
