import React from 'react';
import { size } from 'lodash-es';
import { CSVLink } from 'react-csv';
import { ExcelButton } from './styled';

const Export = ({ data, filename = 'export.csv', withMargin = true }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '39px',
      marginRight: withMargin ? '42px' : '0px',
    }}
  >
    <CSVLink
      data={data}
      filename={filename}
      style={{ pointerEvents: !size(data) > 0 ? 'none' : 'auto' }}
    >
      <ExcelButton disabled={!size(data) > 0} />
    </CSVLink>
  </div>
);

export default Export;
