import React from 'react';
import { some, isArray, size } from 'lodash-es';
import Box from '@mui/material/Box';

import {
  HypothesisCardContainer,
  HypothesisColor,
  HypothesisTitle,
  HypothesisColorContainer,
  ColorColumnDiv,
} from './styled';

import {
  ExperimentVialIcon,
  LineAndBarsIcon,
  PaperClipIcon,
  PersonCircleIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '../../common/assets/icons';

import { P } from '../../common/components/styled/ReusableTextComponents';
import { SegmentCartContainer } from './styled/HypothesisCard';
import { ClampedText } from '../../common/components/ClampedText';
import { useHistory } from 'react-router-dom';

const ResolutionBox = ({ resolutionLength, IconComponent }) =>
  !!resolutionLength && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #74A3FE',
        padding: '1px 3px',
        borderRadius: '4px',
        marginLeft: '5px',
      }}
    >
      <IconComponent fill="#74A3FE" />
      <P marginLeft="5px">{resolutionLength}</P>
    </Box>
  );

const HypothesisCard = (props) => {
  const {
    hypothesis,
    backgroundColor,
    floatRight,
    customerSegment,
    allowClick,
  } = props;
  const { push } = useHistory();

  const onRouteChange = (route) => {
    push(route);
    localStorage.setItem('previousHypothesisLocation', '/canvas');
  };

  const {
    resolutions = [],
    experiments = [],
    KPIIndicators = [],
    clientProfile = null,
  } = hypothesis;

  const positiveResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'validatedWithMVP' ||
      resolution.outcome === 'validatedWithoutMVP'
  );

  const negativeResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'invalidatedWithMVP' ||
      resolution.outcome === 'invalidatedWithoutMVP'
  );

  const handleClick = allowClick
    ? () => onRouteChange(`/hypothesis/${hypothesis._id}?tab=description`)
    : () => {};

  const resolutionCondition = some(
    hypothesis.resolutions,
    (resolution) =>
      resolution.outcome === 'invalidatedWithMVP' ||
      resolution.outcome === 'invalidatedWithoutMVP'
  );

  const colorColumn = customerSegment && (
    <ColorColumnDiv backgroundColor={backgroundColor} />
  );

  const hypothesisDetails = size(hypothesis.details) > 0 && (
    <PaperClipIcon fill="#74A3FE" />
  );

  const experimentBox = size(experiments) > 0 && (
    <ResolutionBox
      resolutionLength={experiments.length}
      IconComponent={ExperimentVialIcon}
    />
  );

  const KPIIndicatorsBox = size(KPIIndicators) > 0 && (
    <Box sx={{ marginLeft: '5px' }}>
      <LineAndBarsIcon fill="#74A3FE" />
    </Box>
  );

  const clientProfileBox = clientProfile && (
    <Box sx={{ marginLeft: '5px' }}>
      <PersonCircleIcon fill="#74A3FE" />
    </Box>
  );

  return customerSegment ? (
    <SegmentCartContainer
      onClick={handleClick}
      customStyles="flex-grow: 1; height: 60px"
      borderColor={backgroundColor}
    >
      <HypothesisTitle
        customerSegment={customerSegment}
        decorationThrough={resolutionCondition}
      >
        <ClampedText lineClamp={1} withTooltip text={hypothesis.title} />
      </HypothesisTitle>

      {colorColumn}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingRight: '5px',
          marginTop: '10px',
        }}
      >
        {hypothesisDetails}
        <ResolutionBox
          resolutionLength={positiveResolutions.length}
          IconComponent={ThumbsUpIcon}
        />
        <ResolutionBox
          resolutionLength={negativeResolutions.length}
          IconComponent={ThumbsDownIcon}
        />
        {experimentBox}
        {KPIIndicatorsBox}
        {clientProfileBox}
      </Box>
    </SegmentCartContainer>
  ) : (
    <HypothesisCardContainer
      style={{ marginBottom: '10px' }}
      customerSegment={customerSegment}
      floatRight={floatRight}
      allowClick={allowClick}
      onClick={handleClick}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <ClampedText lineClamp={1} withTooltip text={hypothesis.title} />
      </div>

      {backgroundColor && (
        <HypothesisColorContainer>
          {isArray(backgroundColor) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                  {backgroundColor.map((color, idx) => (
                    <HypothesisColor key={idx} backgroundColor={color} />
                  ))}
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '3px',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {hypothesisDetails}
                  {clientProfileBox}
                  {KPIIndicatorsBox}
                  {experimentBox}
                  <ResolutionBox
                    resolutionLength={positiveResolutions.length}
                    IconComponent={ThumbsUpIcon}
                  />
                  <ResolutionBox
                    resolutionLength={negativeResolutions.length}
                    IconComponent={ThumbsDownIcon}
                  />
                </div>
              </div>
            </Box>
          )}
        </HypothesisColorContainer>
      )}
    </HypothesisCardContainer>
  );
};

export default HypothesisCard;
