import React from 'react';
import { Formik } from 'formik';
import GenericFormWrapper from '../../common/components/forms/formComponents/GenericFormWrapper';
import RenderRichText from '../../common/components/RichEditor';
import CustomSelectField from '../../common/components/forms/formComponents/CustomSelectField';
import { ACTIVITIES_TOOLBAR_CONFIG } from '../../common/constants/RichTextEditorConstants';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../common/assets/icons';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import CustomRadioGroup from '../../common/components/forms/formComponents/CustomRadioGroup';
import { TASK_STATUS_OPTIONS } from '../constants/activities';

const ActivitiesForm = ({
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
}) => {
  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Title is required';
    }

    if (!values.successCriteria) {
      errors.successCriteria = 'Success criteria is required';
    }

    if (!values.userResponsibleId) {
      errors.userResponsibleId = 'Responsible user is required';
    }

    if (!values.state) {
      errors.state = 'Status is required';
    }

    return errors;
  };

  const initialValues = formValues || {
    title: '',
    successCriteria: '',
    userResponsibleId: '',
    state: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formValues ? handleUpdate : handleSubmit}
      validate={validate}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <GenericFormWrapper maxHeight="700px">
            <CustomTextField id="title" label="Titlu" />
            <RenderRichText
              name="successCriteria"
              height="250px"
              config={ACTIVITIES_TOOLBAR_CONFIG}
              positionToolbarBottom
            />
            <CustomSelectField
              id="userResponsibleId"
              placeholder="Alege responsabilul"
              label="Alocat lui"
              options={teamMembers}
            />
            <CustomRadioGroup
              id="state"
              options={TASK_STATUS_OPTIONS}
              label="Stadiu"
            />
          </GenericFormWrapper>
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {formValues ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default ActivitiesForm;
