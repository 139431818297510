import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { joinCohort, getTeam } from '../../ducks';
import { currentTeamIdSelector, loadUserTeams } from '../../../common/ducks';
import { JoinCohortForm } from '../forms';
import {
  Loading,
  Title,
  DeleteButtonSmall,
} from '../../../common/components/styled';
import { TitleButtonsDiv } from '../../../mentors/components/styled';

const JoinCohortModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    joinCohort,
    getTeam,
    teamId,
    isLoading,
    loadUserTeams,
    userId,
  } = props;
  const onSubmit = async (cohortCode) => {
    await joinCohort(teamId, cohortCode);
    setIsModalOpen(false);
    await loadUserTeams(userId);
    await getTeam(teamId);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Body className="modal-responsive scrollbar-modal">
          <TitleButtonsDiv marginBottom="30px">
            <Title fontSize="24px">Alătură-te unei cohorte</Title>
            <DeleteButtonSmall onClick={handleCloseModal} />
          </TitleButtonsDiv>
          <JoinCohortForm onSubmit={onSubmit} />
        </Modal.Body>
        <Loading isLoading={isLoading} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  isLoading: state.team.isEditing,
});

const mapDispatchToProps = { joinCohort, getTeam, loadUserTeams };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinCohortModal)
);
