/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { filter, map } from 'lodash-es';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { getExperimentIcon } from './pages/ExperimentDetailsPage';
import { Heading2, LinkTable } from '../../common/components/styled';
import { BorderDiv } from '../../hypotheses/components/styled/HypothesisDetailsPage';
import { Wrapper, LinkIconWrapper } from './styled';
import { HypothesisCardContainer } from '../../canvas/components/styled';
import { useHistory } from 'react-router-dom';

const ExperimentsListViewComponent = (props) => {
  const { experiments, filterStatus, resolutionExperiments } = props;
  const { push } = useHistory();
  const experimentsToRender = filter(
    experiments,
    (experiment) =>
      experiment.status === filterStatus && !experiment.resolutions[0]
  );

  const resolutionExperimentsToRender = filter(
    experiments,
    (experiment) =>
      experiment.resolutions[0] &&
      experiment.resolutions[0].outcome !== 'inProgress'
  );

  return (
    <Col md="3" style={{ maxHeight: 'calc(100% - 10px)' }}>
      <BorderDiv style={{ padding: '12px 15px 27px' }}>
        <Heading2>
          {resolutionExperiments ? 'Concluzie' : filterStatus}
        </Heading2>
      </BorderDiv>
      <Wrapper className="scrollbar-modal">
        {map(
          resolutionExperiments
            ? resolutionExperimentsToRender
            : experimentsToRender,
          (experiment, index) => (
            <HypothesisCardContainer
              onClick={() =>
                push(
                  `/hypothesis/${
                    experiment.hypothesisId &&
                    experiment.hypothesisId[0] &&
                    experiment.hypothesisId[0]._id
                  }/experiment/${experiment._id}`
                )
              }
              experiment
              key={index}
            >
              <LinkIconWrapper>
                <LinkTable maxWidth="85%">{experiment.title}</LinkTable>
                {getExperimentIcon(
                  experiment.resolutions[0] &&
                    experiment.resolutions[0].outcome,
                  experiment.status
                )}
              </LinkIconWrapper>
              <Heading2 marginTop="4px" normal>
                <b>Termen:</b>{' '}
                {moment(experiment.deadline).format('DD/MM/YYYY')}
              </Heading2>
            </HypothesisCardContainer>
          )
        )}
      </Wrapper>
    </Col>
  );
};

export default connect(undefined, null)(ExperimentsListViewComponent);
