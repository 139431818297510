import React from 'react';

// Components
import CategoryForm from './CategoryForm';
import ModalComponent from '../../common/components/ModalComponent';

const AddCategoryModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  categoryData,
  handleClose,
  allCategoryNames,
}) => (
  <ModalComponent
    open={isModalOpen}
    handleClose={handleClose}
    title={categoryData ? 'Editeaza categoria' : 'Adauga o categorie noua'}
  >
    <CategoryForm
      handleSubmit={handleSubmit}
      data={categoryData}
      allCategoryNames={allCategoryNames}
    />
  </ModalComponent>
);

export default AddCategoryModal;
