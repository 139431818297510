import React from 'react';
import { Formik } from 'formik';
import GenericFormWrapper from '../../common/components/forms/formComponents/GenericFormWrapper';
import RenderRichText from '../../common/components/RichEditor';
import CustomSelectField from '../../common/components/forms/formComponents/CustomSelectField';
import { ACTIVITIES_TOOLBAR_CONFIG } from '../../common/constants/RichTextEditorConstants';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../common/assets/icons';
import CustomTextField from '../../common/components/forms/formComponents/CustomTextField';
import CustomRadioGroup from '../../common/components/forms/formComponents/CustomRadioGroup';

import * as Yup from 'yup';
import { TASK_STATUS_OPTIONS } from '../constants/activities';

const ActivityTaskForm = ({
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
  isEditMode,
  editedTask,
}) => {
  const initialValues = formValues || {
    title: editedTask?.title || '',
    description: editedTask?.description || '',
    assignedTo: editedTask?.userId || '',
    cost: editedTask?.cost || 1,
    state: editedTask?.status || '',
  };

  const schema = Yup.object({
    title: Yup.string().required('Camp Obligatoriu'),
    assignedTo: Yup.string().required('Camp Obligatoriu'),
    cost: Yup.number('Numar invalid').required('Camp Obligatoriu'),
    state: Yup.string().required('Camp Obligatoriu'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={isEditMode ? handleUpdate : handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <GenericFormWrapper maxHeight="700px">
            <CustomTextField id="title" label="Titlul taskului" />
            <RenderRichText
              name="description"
              label="Adauga o descriere"
              height="200px"
              fullHeight
              config={ACTIVITIES_TOOLBAR_CONFIG}
              positionToolbarBottom
            />
            <CustomSelectField
              id="assignedTo"
              placeholder="Alege responsabilul"
              label="Alocat lui"
              options={teamMembers}
            />
            <CustomTextField id="cost" label="Cost estimativ" />
            <CustomRadioGroup
              id="state"
              options={TASK_STATUS_OPTIONS}
              label="Stadiu"
            />
          </GenericFormWrapper>
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {formValues ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};

export default ActivityTaskForm;
