import { http } from '../../core/services/http';

export const loadProfile = () => http.get('/profile/get');

export const editProfile = (values) => http.put('/profile/edit', values);

export const loadHypothesesPercentage = (teamId) =>
  http.get(`/hypothesis/get-percentage/${teamId}`);

export const loadExperimentsPercentage = (teamId) =>
  http.get(`/experiment/get-percentage/${teamId}`);

export const uploadProfilePicture = (image) => {
  const formData = new FormData();
  formData.append('image', image);

  const res = http.post('/profile/edit-profile-picture/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const subscribeUserToMailchimp = (email) =>
  http.post('/account/mailchimp/subscribe', { email });

export const unsubscribeUserFromMailchimp = (email) =>
  http.post('/account/mailchimp/unsubscribe', { email });

export const getAllSubscriptionProducts = () => http.get('/products-get-all');

export const getPaymentsForLoggedUser = () => http.get('/payments-get-all');

export const getInvoiceById = (invoiceId) => http.get(`/invoice/${invoiceId}`);

export const startCheckoutSession = (planId) =>
  http.post('/create-checkout-session', { planId });
export const cancelSubscription = () => http.put('/cancelSubscription');
export const updateSubscription = (planId) =>
  http.put('/updateSubscription', { planId });
