import { http } from '../../core/services/http';

export const requestPasswordReset = (email) =>
  http.post(`/account/create-password-reset-token/${email}`);

export const resetPassword = (token, email, password) =>
  http.put(`/account/reset-password?passwordResetToken=${token}`, {
    email,
    password,
  });

export const findConfirmationToken = (token) =>
  http.get(`account-confirmation/get/${token}`);

export const resendConfirmationToken = (token) =>
  http.post(`account-confirmation/resend-email/${token}`);
