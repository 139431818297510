import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import styled from 'styled-components';

const ClampedBox = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${(props) => props.lineClamp || 2};

  & > * {
    margin: 0;
  }
`;

export const ClampedRichText = ({ text, lineClamp }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    try {
      const contentObject = JSON.parse(text);
      const generatedHtml = draftToHtml(contentObject);
      setHtmlContent(generatedHtml);
    } catch (error) {
      setHtmlContent(text);
    }
  }, [text]);

  return <ClampedBox lineClamp={lineClamp}>{parse(htmlContent)}</ClampedBox>;
};
