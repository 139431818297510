import { get, map, isArray } from 'lodash-es';
import { http } from '../../core/services/http';

export const loadExperiments = (teamId) =>
  http.get(`/experiment/get-all/${teamId}`);

export const loadExperiment = (experimentId) =>
  http.get(`/experiment/get/${experimentId}`);

export const deleteExperiment = (experimentId) =>
  http.delete(`/experiment/delete/${experimentId}`);

export const addExperiment = (values, teamId, createdBy) => {
  const registerData = {
    ...values,
    hypothesisId: isArray(values.hypothesisId)
      ? map(values.hypothesisId, (el) => get(el, 'value'))
      : get(values.hypothesisId, 'value'),
    teamId,
    createdBy,
  };
  return http.post('/experiment/add', registerData);
};

export const addExperimentComment = (values, experimentId) => {
  const registerData = {
    ...values,
    experimentId,
  };
  return http.post('/experiment-comment/add', registerData);
};

export const editExperiment = (values, entityId) => {
  const registerExperiment = {
    ...values,
    hypothesisId: isArray(values.hypothesisId)
      ? map(values.hypothesisId, (el) => get(el, 'value'))
      : get(values.hypothesisId, 'value'),
  };

  return http.put(`/experiment/edit/${entityId}`, registerExperiment);
};

export const editExperimentComment = (values, experimentCommentId) =>
  http.put(`/experiment-comment/edit/${experimentCommentId}`, values);

export const deleteExperimentComment = (experimentCommentId) =>
  http.delete(`/experiment-comment/delete/${experimentCommentId}`);

export const addExperimentCommentReply = (values, experimentCommentId) =>
  http.post(`/experiment-comment/reply/add/${experimentCommentId}`, values);

export const editExperimentCommentReply = (
  values,
  experimentCommentId,
  replyId
) =>
  http.put(
    `/experiment-comment/reply/edit/${experimentCommentId}/${replyId}`,
    values
  );

export const deleteExperimentCommentReply = (experimentCommentId, replyId) =>
  http.delete(
    `/experiment-comment/reply/delete/${experimentCommentId}/${replyId}`
  );
