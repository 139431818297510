import React, { useEffect } from 'react';
import { Avatar, Box, Divider, IconButton } from '@mui/material';
import {
  H3,
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import {
  CrossIcon,
  EditIcon,
  TrashIconOutlined,
} from '../../../common/assets/icons';
import { ScrollableDrawerContent } from '../styled/ActivitiesDrawerStyled';
import RichTextContainer from '../../../common/components/RichTextContainer';
import { useApi } from '../../../hooks/useApi';
import { getTaskDetailsById } from '../../../requests/tasks';
import {
  TASK_STATUS_COLOR,
  TASK_STATUS_OPTIONS,
} from '../../constants/activities';
import { formatDate } from '../../../common/functions/dateHelpers';

const ActivitiesDrawerTaskPreview = ({
  setPreviewedTask,
  handleCloseDrawer,
  previewedTask,
  handleDeleteTask,
  handleEditTask,
}) => {
  const [{ result: taskDetails }, runGetTaskById] = useApi(getTaskDetailsById);
  useEffect(() => {
    runGetTaskById({
      params: {
        taskId: previewedTask?._id,
      },
    });
  }, []);

  const handleClose = () => {
    handleCloseDrawer();
    setPreviewedTask(null);
  };

  const statusText = () =>
    TASK_STATUS_OPTIONS.find((el) => el.value === previewedTask.status)?.label;

  return (
    <ScrollableDrawerContent className="scrollbar">
      <Box
        className="display-flex align-items-center justify-content-space-between"
        sx={{
          marginBottom: '20px',
        }}
      >
        <H3>Vizualizare task</H3>
        <IconButton onClick={handleClose}>
          <CrossIcon fill="blue" />
        </IconButton>
      </Box>
      <Box>
        <P faded>Titlu</P>
        <P>{previewedTask?.title}</P>
      </Box>
      <Box>
        <P marginTop="10px" faded>
          Descriere
        </P>
        <P>
          <RichTextContainer text={previewedTask?.description} />
        </P>
      </Box>
      <Box
        sx={{ marginTop: '10px' }}
        className="display-flex justify-content-space-between "
      >
        <Box className="display-flex flex-column ">
          <P marginBottom="10px" faded>
            <i>Responsabil</i>
          </P>
          <Box className="display-flex align-items-center">
            <Avatar
              sx={{ width: '20px', height: '20px', marginRight: '10px' }}
            />
            <P>Ceva nume</P>
          </Box>
        </Box>
        <Box className="display-flex flex-column text-right">
          <P marginBottom="10px" faded>
            Cost
          </P>
          <P light>{previewedTask?.cost}</P>
        </Box>
      </Box>
      <Box
        sx={{ marginTop: '10px' }}
        className="display-flex justify-content-space-between "
      >
        <Box className="display-flex flex-column">
          <P marginBottom="10px" faded>
            Status
          </P>
          <Box className="display-flex align-items-center">
            <Box
              sx={{
                marginRight: '5px',
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: TASK_STATUS_COLOR[previewedTask?.status],
              }}
            />
            <P light>{statusText()}</P>
          </Box>
        </Box>
        <Box className="display-flex flex-column">
          <P marginBottom="10px" faded>
            Data finalizare
          </P>
          <P className="text-right" light>
            {formatDate(previewedTask?.completionDate)}
          </P>
        </Box>
      </Box>

      <Box sx={{ marginTop: '10px' }}>
        <Divider />
        <Box sx={{ margin: '15px 0' }} className="display-flex">
          <PrimaryButton
            onClick={handleEditTask}
            icon={EditIcon}
            marginRight="10px"
            inverted
          >
            Editeaza
          </PrimaryButton>
          <SecondaryButton
            onClick={() => handleDeleteTask(previewedTask?._id)}
            icon={TrashIconOutlined}
            iconColor="white"
          >
            Sterge
          </SecondaryButton>
        </Box>
        <Divider />
      </Box>
    </ScrollableDrawerContent>
  );
};

export default ActivitiesDrawerTaskPreview;
