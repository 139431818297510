import update from 'immutability-helper';
import { push } from 'react-router-redux';
import {
  addExperimentsDetailComment as doAddExperimentDetailComment,
  deleteExperimentDetailComment as doDeleteExperimentDetailComment,
  editExperimentDetailComment as doEditExperimentDetailComment,
  deleteExperimentDetail as doDeleteExperimentDetail,
  addExperimentDetail as doAddExperimentDetail,
  editExperimentDetail as doEditExperimentDetail,
  loadExperimentDetail as doLoadExperimentDetail,
  addExperimentDetailCommentReply as doAddExperimentDetailCommentReply,
  editExperimentDetailCommentReply as doEditExperimentDetailCommentReply,
  deleteExperimentDetailCommentReply as doDeleteExperimentDetailCommentReply,
} from '../services/experimentsDetail';
import { notifyError, notifySuccess } from '../../core/ducks/notifications';

// Actions

const START_ADD_COMMENT = 'experimentsDetail/START_ADD_COMMENT';
const COMPLETE_ADD_COMMENT = 'experimentsDetail/COMPLETE_ADD_COMMENT';
const FAIL_ADD_COMMENT = 'experimentsDetail/FAIL_ADD_COMMENT';

const START_DELETE_COMMENT = 'experimentsDetail/START_DELETE_COMMENT';
const COMPLETE_DELETE_COMMENT = 'experimentsDetail/COMPLETE_DELETE_COMMENT';
const FAIL_DELETE_COMMENT = 'experimentsDetail/FAIL_DELETE_COMMENT';

const START_EDIT_COMMENT = 'experimentsDetail/START_EDIT_COMMENT';
const COMPLETE_EDIT_COMMENT = 'experimentsDetail/COMPLETE_EDIT_COMMENT';
const FAIL_EDIT_COMMENT = 'experimentsDetail/FAIL_EDIT_COMMENT';

const START_DELETE = 'experimentsDetail/START_DELETE';
const COMPLETE_DELETE = 'experimentsDetail/COMPLETE_DELETE';
const FAIL_DELETE = 'experimentsDetail/FAIL_DELETE';

const START_ADD = 'experimentsDetail/START_ADD';
const COMPLETE_ADD = 'experimentsDetail/COMPLETE_ADD';
const FAIL_ADD = 'experimentsDetail/FAIL_ADD';

const START_LOAD = 'experimentsDetail/START_LOAD';
const COMPLETE_LOAD = 'experimentsDetail/COMPLETE_LOAD';
const FAIL_LOAD = 'experimentsDetail/FAIL_LOAD';

const START_EDIT = 'experimentsDetail/START_EDIT';
const COMPLETE_EDIT = 'experimentsDetail/COMPLETE_EDIT';
const FAIL_EDIT = 'experimentsDetail/FAIL_EDIT';

const START_ADD_REPLY = 'experimentsDetail/START_ADD_REPLY';
const COMPLETE_ADD_REPLY = 'experimentsDetail/COMPLETE_ADD_REPLY';
const FAIL_ADD_REPLY = 'experimentsDetail/FAIL_ADD_REPLY';

const START_EDIT_REPLY = 'experimentsDetail/START_EDIT_REPLY';
const COMPLETE_EDIT_REPLY = 'experimentsDetail/COMPLETE_EDIT_REPLY';
const FAIL_EDIT_REPLY = 'experimentsDetail/FAIL_EDIT_REPLY';

const START_DELETE_REPLY = 'experimentsDetail/START_DELETE_REPLY';
const COMPLETE_DELETE_REPLY = 'experimentsDetail/COMPLETE_DELETE_REPLY';
const FAIL_DELETE_REPLY = 'experimentsDetail/FAIL_DELETE_REPLY';

const RESET = 'experimentsDetail/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  experimentDetail: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_COMMENT:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD_COMMENT:
      return update(state, { $merge: { isCreating: false } });

    case START_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });
    case FAIL_DELETE_COMMENT:
      return update(state, { $merge: { isDeleting: false } });

    case START_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_COMMENT:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE:
      return update(state, { $merge: { isDeleting: false } });

    case START_ADD:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_ADD:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_ADD:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentDetail: action.experimentDetail,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentDetail: undefined,
        },
      });

    case START_ADD_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_ADD_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_REPLY:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_REPLY:
      return update(state, { $merge: { isEditing: false } });

    case START_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: true } });

    case COMPLETE_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case FAIL_DELETE_REPLY:
      return update(state, { $merge: { isDeleting: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startAddComment = () => ({
  type: START_ADD_COMMENT,
});

const completeAddComment = () => ({
  type: COMPLETE_ADD_COMMENT,
});

const failAddComment = () => ({
  type: FAIL_ADD_COMMENT,
});

const startDeleteComment = () => ({
  type: START_DELETE_COMMENT,
});

const completeDeleteComment = () => ({
  type: COMPLETE_DELETE_COMMENT,
});

const failDeleteComment = () => ({
  type: FAIL_DELETE_COMMENT,
});

const startEditComment = () => ({
  type: START_EDIT_COMMENT,
});

const completeEditComment = () => ({
  type: COMPLETE_EDIT_COMMENT,
});

const failEditComment = () => ({
  type: FAIL_EDIT_COMMENT,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (experimentDetail) => ({
  type: COMPLETE_LOAD,
  experimentDetail,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startAddReply = () => ({
  type: START_ADD_REPLY,
});

const completeAddReply = () => ({
  type: COMPLETE_ADD_REPLY,
});

const failAddReply = () => ({
  type: FAIL_ADD_REPLY,
});

const startEditReply = () => ({
  type: START_EDIT_REPLY,
});

const completeEditReply = () => ({
  type: COMPLETE_EDIT_REPLY,
});

const failEditReply = () => ({
  type: FAIL_EDIT_REPLY,
});

const startDeleteReply = () => ({
  type: START_DELETE_REPLY,
});

const completeDeleteReply = () => ({
  type: COMPLETE_DELETE_REPLY,
});

const failDeleteReply = () => ({
  type: FAIL_DELETE_REPLY,
});

export const addExperimentDetailComment =
  (values, experimentDetailId) => (dispatch) => {
    dispatch(startAddComment());
    const addExperimentDetailCommentPromise = doAddExperimentDetailComment(
      values,
      experimentDetailId
    );
    addExperimentDetailCommentPromise
      .then(() => {
        dispatch(completeAddComment());
        dispatch(notifySuccess('Comentariul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddComment());
      });
    return addExperimentDetailCommentPromise;
  };

export const deleteExperimentDetail = (experimentDetailId) => (dispatch) => {
  dispatch(startDelete());
  const deleteExperimentDetailPromise =
    doDeleteExperimentDetail(experimentDetailId);
  deleteExperimentDetailPromise
    .then(() => {
      dispatch(completeDelete());
      dispatch(notifySuccess('Detaliul a fost șters cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failDelete());
    });
  return deleteExperimentDetailPromise;
};

export const deleteExperimentDetailComment =
  (experimentDetailCommentId) => (dispatch) => {
    dispatch(startDeleteComment());
    const deleteExperimentDetailCommentPromise =
      doDeleteExperimentDetailComment(experimentDetailCommentId);
    deleteExperimentDetailCommentPromise
      .then(() => {
        dispatch(completeDeleteComment());
        dispatch(notifySuccess('Comentariul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteComment());
      });
    return deleteExperimentDetailCommentPromise;
  };

export const editExperimentDetailComment =
  (values, experimentDetailCommentId) => (dispatch) => {
    dispatch(startEditComment());
    const editExperimentDetailCommentPromise = doEditExperimentDetailComment(
      values,
      experimentDetailCommentId
    );
    editExperimentDetailCommentPromise
      .then(() => {
        dispatch(completeEditComment());
        dispatch(notifySuccess('Comentariul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditComment());
      });
    return editExperimentDetailCommentPromise;
  };

export const addExperimentDetail =
  (values, createdBy, parentId) => (dispatch) => {
    dispatch(startAdd());
    const addExperimentDetailPromise = doAddExperimentDetail(
      values,
      createdBy,
      parentId
    );
    addExperimentDetailPromise
      .then(() => {
        dispatch(completeAdd());
        dispatch(notifySuccess('Detaliul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAdd());
      });
    return addExperimentDetailPromise;
  };

export const editExperimentDetail =
  (values, entityId, parent, personResponsible) => (dispatch) => {
    dispatch(startEdit());
    const editExperimentDetailPromise = doEditExperimentDetail(
      values,
      entityId,
      parent,
      personResponsible
    );
    editExperimentDetailPromise
      .then(() => {
        dispatch(completeEdit());
        dispatch(notifySuccess('Detaliul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEdit());
      });
    return editExperimentDetailPromise;
  };

export const loadExperimentDetail = (experimentDetailId) => (dispatch) => {
  dispatch(startLoad());
  const loadExperimentDetailPromise =
    doLoadExperimentDetail(experimentDetailId);
  loadExperimentDetailPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
      if (error.response.status === 404) dispatch(push('/canvas'));
    });
  return loadExperimentDetailPromise;
};

export const addExperimentDetailCommentReply =
  (values, experimentDetailCommentId) => (dispatch) => {
    dispatch(startAddReply());
    const addExperimentDetailCommentReplyPromise =
      doAddExperimentDetailCommentReply(values, experimentDetailCommentId);
    addExperimentDetailCommentReplyPromise
      .then(() => {
        dispatch(completeAddReply());
        dispatch(notifySuccess('Răspunsul a fost adăugat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failAddReply());
      });
    return addExperimentDetailCommentReplyPromise;
  };

export const editExperimentDetailCommentReply =
  (values, experimentDetailCommentId, replyId) => (dispatch) => {
    dispatch(startEditReply());
    const editExperimentDetailCommentReplyPromise =
      doEditExperimentDetailCommentReply(
        values,
        experimentDetailCommentId,
        replyId
      );
    editExperimentDetailCommentReplyPromise
      .then(() => {
        dispatch(completeEditReply());
        dispatch(notifySuccess('Răspunsul a fost editat cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failEditReply());
      });
    return editExperimentDetailCommentReplyPromise;
  };

export const deleteExperimentDetailCommentReply =
  (experimentDetailCommentId, replyId) => (dispatch) => {
    dispatch(startDeleteReply());
    const deleteExperimentDetailCommentReplyPromise =
      doDeleteExperimentDetailCommentReply(experimentDetailCommentId, replyId);
    deleteExperimentDetailCommentReplyPromise
      .then(() => {
        dispatch(completeDeleteReply());
        dispatch(notifySuccess('Răspunsul a fost șters cu succes'));
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failDeleteReply());
      });
    return deleteExperimentDetailCommentReplyPromise;
  };
