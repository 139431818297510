import React from 'react';
import * as Yup from 'yup';
import { isObject } from 'lodash-es';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import {
  isAcceleratorSelector,
  isMentorSelector,
} from '../../../account/ducks';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import CustomImageUpload from '../../../common/components/forms/formComponents/CustomImageUpload';

const AddPersonInfoForm = (props) => {
  const { onSubmit, isEditMode, defaultValue = {}, isEdit } = props;
  const { profilePicture } = defaultValue;

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    name: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    photo: Yup.mixed().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    name: defaultValue ? defaultValue.name : '',
    description: defaultValue ? defaultValue.description : '',
    photo: '',
  };

  const initialValues = defaultValues;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CustomImageUpload
            label="Poza profil"
            id="photo"
            initialImage={profilePicture || null}
          />
          <CustomTextField id="name" label="Nume" />
          <CustomTextField id="description" label="Descriere" />
          <PrimaryButton marginTop="30px" icon={SaveIconOutlined} type="submit">
            {isEdit ? 'Editeaza' : 'Salveaza'}
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  defaultColumnBMC:
    isObject(state.common.modal.props) &&
    state.common.modal.props.initialValues.columnBMC,
  isMentor: isMentorSelector(state.account.login),
  isAccelerator: isAcceleratorSelector(state.account.login),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonInfoForm);
