import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypothesis } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import EmpathyMapPage from './EmpathyMapPage';

const EmpathyMapResolver = (props) => {
  const {
    loadHypothesis,
    match: {
      params: { hypothesisId },
    },
  } = props;
  const loadDependencies = () =>
    hypothesisId ? loadHypothesis(hypothesisId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Empathy Map</DocumentTitle>
      <Resolver
        successComponent={EmpathyMapPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

EmpathyMapResolver.propTypes = {
  loadHypothesis: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadHypothesis };

export default withRouter(
  connect(undefined, mapDispatchToProps)(EmpathyMapResolver)
);
