/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { get, find } from 'lodash-es';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  setCurrentUserTeam,
  currentTeamIdSelector,
  resetUserTeams,
} from '../../ducks';
import { getUserRole } from '../../services/roleBadgeColors';
import { ReactComponent as TeamProfileIcon } from '../../assets/icons/Navigation_Detalii.svg';
import { ReactComponent as BmcIcon } from '../../assets/icons/Navigation_BMC.svg';
import { ReactComponent as ExperimentsIcon } from '../../assets/icons/Navigation_Experiment.svg';
import { ReactComponent as HypothesisIcon } from '../../assets/icons/Navigation_Hypothesis.svg';
import { ReactComponent as MyIdeeasIcon } from '../../assets/icons/Navigation_Idei.svg';
import { ReactComponent as LeaderBoardIcon } from '../../assets/icons/Navigation_Clasament.svg';
import { ReactComponent as InvitationsIcon } from '../../assets/icons/invitatii.svg';
import { ReactComponent as ResourceIcon } from '../../assets/icons/Navigation_Resurse.svg';
import MemberIcon from '../../assets/icons/Avatar_Membru.svg';
import {
  ImageIconRound,
  NavigationBarImageContainer,
} from '../styled/NavigationBar';
import {
  NavigationBarLink,
  PositionText,
  PositionBadge,
  Heading2,
  StyledLink,
} from '../styled';

const MentorNavigationMenu = (props) => {
  const {
    teamId,
    userTeams,
    userId,
    resetUserTeams,
    location,
    userProfile,
    role,
  } = props;

  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const { push } = useHistory();

  useEffect(() => {
    const { hasCompletedQuestionnaire } = userProfile;
    if (!hasCompletedQuestionnaire) {
      return push('/form');
    }

    if (!teamId && !userTeams) push('/profile');
    if (!!teamId && location.pathname === '/home') push('/canvas');
    setSelectedTeam(find(userTeams, (team) => !!team.selected));
    return () => resetUserTeams();
  }, []);

  return (
    <>
      <li className="side-nav-item" style={{ marginBottom: '30px' }}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          imageitem="true"
          to="/profile"
          className="side-sub-nav-link side-nav-link"
          style={{
            padding: '8px 30px',
          }}
        >
          {!!userProfile && (
            <NavigationBarImageContainer>
              <ImageIconRound src={userProfile.profilePicture || MemberIcon} />
            </NavigationBarImageContainer>
          )}
          <PositionBadge className="mb-0">
            <Heading2 color="#FB791C">
              {getUserRole(
                role,
                userId,
                selectedTeam && get(selectedTeam, 'createdBy'),
                selectedTeam &&
                  get(find(selectedTeam.members, { userId }), 'equity')
              )}
            </Heading2>
          </PositionBadge>
        </NavigationBarLink>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Detalii idee
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
            to="/team-profile"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <TeamProfileIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              BMC
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
            to="/canvas"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <BmcIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Ipoteze
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
            to="/hypotheses"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <HypothesisIcon />
            <PositionText>Ipoteze</PositionText>
          </NavigationBarLink>
        </OverlayTrigger>
      </li>

      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Experimente
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
            to="/experiments"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <ExperimentsIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li
        style={{
          margin: '0 1rem',
          paddingBottom: '2rem',
          borderBottom: '1px solid #8E8EB5',
        }}
      />
      <li style={{ paddingTop: '2rem' }} />

      <li className="side-nav-item">
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Invitațiile mele
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            to="/mentor-invitations"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
          >
            <InvitationsIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className="side-nav-item">
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Echipele mele
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className={'side-sub-nav-link side-nav-link '}
            style={{
              padding: '8px 30px',
            }}
            to="/idea"
          >
            <MyIdeeasIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>

      <li className={`side-nav-item ${!teamId ? 'disabledNavItem' : ''}`}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Clasament echipe
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
            to="/leaderboard"
            onClick={(e) => !teamId && e.preventDefault()}
          >
            <LeaderBoardIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className="side-nav-item">
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Resurse
            </Tooltip>
          }
        >
          <StyledLink
            href="https://ptic.ro/help/"
            target="_blank"
            rel="noopener noreferrer"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
          >
            <ResourceIcon />
          </StyledLink>
        </OverlayTrigger>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account.login, 'user._id'),
  userProfile: get(state.userProfile, 'userProfile'),
  userTeams: state.common.userTeams.userTeams,
  teamId: currentTeamIdSelector(state.common.userTeams),
  role: get(state.account.login, 'user.role'),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  setCurrentUserTeam,
  resetUserTeams,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorNavigationMenu)
);
