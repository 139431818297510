import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { PageContainer } from '../styled/OportunityAnalysisStyled';
import AddTrendsModal from '../modals/AddTrendsModal';
import { TrendsDrawer } from '../TrendsDrawer';
import { useApi } from '../../../hooks/useApi';
import { getAllTrends, deleteTrendById } from '../../../requests/trends';
import { currentTeamIdSelector } from '../../../common/ducks';
import TrendsPageHeader from '../TrendsPageHeader';
import TrendsList from '../TrendsList';

const TrendsPage = () => {
  const [isAddTrendModalOpen, setIsAddTrendModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([true, false]);
  const [{ result: trendList = [] }, fetchAllTrends] = useApi(getAllTrends);
  const [, deleteTrend] = useApi(deleteTrendById);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const handleViewTrend = (trend) => {
    setIsDrawerOpen(true);
    setSelectedTrend(trend);
  };

  const handleEditTrend = (trend) => {
    setSelectedTrend(trend);
    setIsAddTrendModalOpen(true);
    setIsDrawerOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedTrend(null);
  };

  const handleDeleteTrend = async (trendId) => {
    await deleteTrend({ params: { trendId } });
    await fetchAllTrends({
      params: { teamId },
      queryParams: {
        filters: { isTrendFavorable: selectedFilters },
      },
    });
    setIsDrawerOpen(false);
    setSelectedTrend(null);
  };

  const handleCheck = (filter) => {
    if (selectedFilters.includes(filter)) {
      const newFilters = selectedFilters.filter((item) => item !== filter);
      setSelectedFilters(newFilters);
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  useEffect(() => {
    if (teamId) {
      const payload = {
        params: { teamId },
        queryParams: {
          filters: { isTrendFavorable: selectedFilters },
        },
      };

      fetchAllTrends(payload);
    }
  }, [teamId, selectedFilters]);

  const handleToggleAddTrendModal = () => {
    setIsAddTrendModalOpen(true);
  };

  const openAddTrendModal = () => {
    setIsAddTrendModalOpen(true);
  };

  return (
    <PageContainer>
      <Box sx={{ height: '100%' }}>
        <TrendsPageHeader
          handleCheck={handleCheck}
          selectedFilters={selectedFilters}
          openAddTrendModal={openAddTrendModal}
        />
        <TrendsList
          handleEditTrend={handleEditTrend}
          handleDeleteTrend={handleDeleteTrend}
          handleViewTrend={handleViewTrend}
          trendList={trendList}
          handleToggleAddTrendModal={handleToggleAddTrendModal}
        />
      </Box>
      <TrendsDrawer
        selectedTrend={selectedTrend}
        setIsDrawerOpen={setIsDrawerOpen}
        isDrawerOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        handleEditTrend={handleEditTrend}
        handleDeleteTrend={handleDeleteTrend}
      />
      <AddTrendsModal
        teamId={teamId}
        selectedTrend={selectedTrend}
        setSelectedTrend={setSelectedTrend}
        isModalOpen={isAddTrendModalOpen}
        setIsModalOpen={setIsAddTrendModalOpen}
      />
    </PageContainer>
  );
};

export default TrendsPage;
