import styled, { css } from 'styled-components';
import { ReactComponent as SegmentIcon } from '../../../common/assets/icons/Menu_Profilsegment.svg';

export const SegmentCartContainer = styled.div`
  min-height: 70px;
  position: relative;
  max-width: 100%;
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : '1px solid #74a3fe'};
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  ${(props) => props.customStyles}
`;

export const SegmentTitle = styled.div`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const SegmentContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #74a3fe;
  margin-bottom: 10px;
  :hover {
    background-color: rgba(116, 163, 254, 0.15);
  }
`;

export const HypothesisCardContainer = styled.div`
  justify-content: space-between;
  ${(props) =>
    props.floatRight &&
    css`
      float: right;
    `}
  min-height: 70px;
  position: relative;
  max-width: 100%;
  border: 1px solid #74a3fe;
  ${(props) =>
    props.empathy &&
    css`
      width: 100%;
    `}

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  ${(props) =>
    props.customerSegment &&
    css`
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
    `}
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.customerSegment &&
    css`
      flex-direction: row;
    `}
  width: 100%;
  ${(props) =>
    props.allowClick &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.experiment &&
    css`
      background: #f7f7f8;
      min-height: 76px;
      padding: 8px 5px;
      justify-content: space-between;
    `}
`;
export const HypothesisColorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const HypothesisIconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
  & > div:first-child {
    margin-left: 0;
  }
`;
export const SegmentCardIcon = styled(SegmentIcon)`
  width: 17px;
  height: 17px;
  float: right;
`;
export const HypothesisColor = styled.div`
  margin-right: 3px;
  border-radius: 2px;
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
`;

export const HypothesisColorBar = styled.div`
  border-radius: 2px;
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
`;

export const ColorDropdownDiv = styled.div`
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
  height: auto;
  width: 55px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const ColorColumnDiv = styled.div`
  background-color: ${(props) =>
    !!props.backgroundColor && props.backgroundColor};
  height: 100%;
  width: 9px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const HypothesisCardIconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 40px;
  height: 45px;
  ${(props) =>
    props.customerSegment === 'customerSegments' &&
    css`
      padding-top: 10px;
      margin-left: 15px;
    `}
`;
export const HypothesisTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.text};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => (props.lineClamp ? props.lineClamp : 2)};
  overflow: hidden;
  height: ${(props) => (props.height ? props.height : '')};
  width: ${(props) => (props.width ? props.width : '75%')};
  text-decoration: ${(props) =>
    props.decorationThrough ? 'line-through #2d9bf0' : null};
  ${(props) =>
    props.hover &&
    css`
      :hover {
        -webkit-line-clamp: initial;
        white-space: normal;
        height: auto;
      }
    `}
`;
