import { Box } from '@mui/material';
import { H2, P } from '../../common/components/styled/ReusableTextComponents';
import React from 'react';
import { useSelector } from 'react-redux';

export const TourImageComponent = ({ title, text = [], image }) => {
  const currentUser = useSelector((state) => state?.userProfile?.userProfile);
  const { lastName } = currentUser;
  const personalizedTitle = title.replace('prename', lastName);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'start',
      }}
    >
      <img src={image} alt="rocket" />
      <H2 marginTop="15px" marginBottom="15px">
        {personalizedTitle}
      </H2>

      {text.map((piece, idx) => (
        <P marginBottom="10px" key={idx} light style={{ color: 'black' }}>
          {piece}
        </P>
      ))}
    </Box>
  );
};
