import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadExperiments } from '../../../experiments/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { ExperimentsPage } from '../../../experiments/components/pages';

const TeamExperimentsPageResolver = (props) => {
  const {
    loadExperiments,
    match: {
      params: { teamId },
    },
  } = props;
  const loadDependencies = () =>
    teamId ? loadExperiments(teamId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Experimente</DocumentTitle>
      <Resolver
        successComponent={ExperimentsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

TeamExperimentsPageResolver.propTypes = {
  loadExperiments: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadExperiments };

export default withRouter(
  connect(undefined, mapDispatchToProps)(TeamExperimentsPageResolver)
);
