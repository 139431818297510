import styled from 'styled-components';

export const OrangeCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid orange;
  cursor: pointer;
`;

export const CustomDrawer = styled.div`
  width: 400px;
  padding: 10px 10px 0 10px;
  height: 100%;
`;

export const CommentsCounter = styled.div`
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  padding: 1px 10px;
  margin-left: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DotsIconContainer = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  padding: 5px;
`;

export const CommentsPopperContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  border: 1px solid rgba(116, 163, 254, 0.4);
  padding: 5px;
  margin-top: 2px;
  border-radius: 4px;
  width: 100px;
`;

export const CommentContainer = styled.div`
  margin-bottom: 25px;
`;

export const NotificationsFilterContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.blueAccent};
  width: 220px;
  padding: 5px;
  justify-content: space-between;
  margin-top: 10px;
`;

export const NotificationsFilterOption = styled.div`
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? props.theme.accentLight : props.theme.white};
  color: ${(props) =>
    props.active ? props.theme.white : props.theme.accentLight};
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
