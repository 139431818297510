import { runGetApiRequest, runUpdateApiRequest } from '../api/Api';
import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MENTOR,
  GET_TEAM_BY_ID,
  GET_TEAM_COHORTS,
  GET_TEAM_MEMBERS,
  GET_TEAM_MENTORS,
  JOIN_TEAM_COHORT,
  REMOVE_TEAM_MEMBER,
} from '../common/constants/apiRoutes';

export const getMyIdeeaMembers = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_MEMBERS,
    params: payload.params,
  });

export const getMyIdeeaMentors = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_MENTORS,
    params: payload.params,
  });

export const postMyIdeeaMember = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: ADD_TEAM_MEMBER,
    params: payload.params,
    payload: payload.data,
    onFailMessage: 'Nu poti invita acest utilizator',
  });

export const postMyIdeaMentor = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: ADD_TEAM_MENTOR,
    params: payload.params,
    payload: payload.data,
    onFailMessage: 'Adaugarea mentorului a esuat.',
  });

export const joinCohort = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: JOIN_TEAM_COHORT,
    params: payload.params,
    payload: payload.data,
    onFailMessage: 'Alaturarea la cohorta a esuat.',
  });

export const getTeamById = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_BY_ID,
    params: payload.params,
  });

export const removeTeamMember = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: REMOVE_TEAM_MEMBER,
    params: payload.params,
  });

export const getTeamCohorts = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_COHORTS,
    params: payload.params,
  });
