import React from 'react';
import { Modal } from 'react-bootstrap';

import { TitleButtonsDiv } from '../../../mentors/components/styled';
import { Title, DeleteButtonSmall } from '../../../common/components/styled';
import { CustomLabel } from '../../../core/components';
import ChangePasswordForm from '../forms/ChangePasswordForm';

const ChangePasswordModal = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      onHide={handleCloseModal}
      show={isModalOpen}
      size="md"
      backdrop="static"
    >
      <Modal.Body>
        <TitleButtonsDiv>
          <Title fontSize="24px" fontWeight="bold">
            Schimba parola
            <CustomLabel tooltipDescription="SCHIMBA PAROLA" />
          </Title>
          <DeleteButtonSmall onClick={handleCloseModal} />
        </TitleButtonsDiv>
        <ChangePasswordForm />
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
