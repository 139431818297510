/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { get, find, map } from 'lodash-es';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CohortFilterForm } from '../forms';
import {
  setCurrentUserCohort,
  resetUserCohorts,
  currentCohortIdSelector,
} from '../../../accelerator/ducks';
import { ReactComponent as MyIdeeasIcon } from '../../assets/icons/Navigation_Idei.svg';
import { ReactComponent as CohortIcon } from '../../assets/icons/Avatar_Cohorta-2.svg';
import { ReactComponent as LeaderBoardIcon } from '../../assets/icons/Navigation_Clasament.svg';
import MemberIcon from '../../assets/icons/Avatar_Membru.svg';

import {
  ImageIconRound,
  NavigationBarImageContainer,
} from '../styled/NavigationBar';

import {
  NavigationBarLink,
  PositionBadge,
  PositionMenu,
  Heading2,
} from '../styled';
import { useHistory } from 'react-router-dom';

const AcceleratorNavigationMenu = (props) => {
  const {
    cohortId,
    userCohorts,
    resetUserCohorts,
    setCurrentUserCohort,
    userId,
    userProfile,
    role,
  } = props;
  const { push } = useHistory();

  useEffect(() => {
    if (!cohortId && !userCohorts) push('/cohorts');
    if (cohortId) push(`/accelerator/${cohortId}/leaderboard`);
    return () => resetUserCohorts();
  }, []);

  const getCohortOptions = useCallback(
    () =>
      map(userCohorts, (cohort) => ({
        label: cohort.name,
        value: cohort._id,
      })),
    [userCohorts]
  );

  const getCohortValue = useCallback(() => {
    const value = find(userCohorts, (cohort) => !!cohort.selected);
    return { label: get(value, 'name'), value: get(value, '_id') };
  }, [userCohorts]);

  const handleChangeCohort = (cohort) => {
    setCurrentUserCohort(userId, cohort.value);
  };

  return (
    <>
      <li className="side-nav-item" style={{ marginBottom: '1.5rem' }}>
        <NavigationBarLink
          activeClassName="activeNavItem"
          imageitem="true"
          to="/profile"
          className="side-sub-nav-link side-nav-link my-1"
          style={{
            padding: '8px 30px',
          }}
        >
          {!!userProfile && (
            <NavigationBarImageContainer>
              <ImageIconRound src={userProfile.profilePicture || MemberIcon} />
            </NavigationBarImageContainer>
          )}
          <PositionBadge className="mb-0">
            <Heading2 color="#74A3FE">
              {role === 'ACCELERATOR' && 'Accelerator'}
            </Heading2>
          </PositionBadge>
        </NavigationBarLink>
      </li>
      <div>
        <PositionMenu style={{ marginBottom: '10px' }}>
          {!!userCohorts && (
            <CohortFilterForm
              userCohorts={getCohortOptions()}
              userCohortValue={getCohortValue()}
              handleChangeCohort={handleChangeCohort}
            />
          )}
        </PositionMenu>
      </div>

      <li
        style={{
          margin: '0 20px',
          paddingBottom: '30px',
          borderBottom: '1px solid #8E8EB5',
        }}
      />
      <li style={{ paddingTop: '30px' }} />

      <li className="side-nav-item" style={{ marginBottom: '30px' }}>
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Cohorte
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            to="/cohorts"
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
          >
            <CohortIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className="side-nav-item">
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Echipe
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            to={`/accelerator/${cohortId}/teams`}
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
          >
            <MyIdeeasIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
      <li className="side-nav-item">
        <OverlayTrigger
          trigger={['hover', 'hover']}
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip id="button-tooltip" className="tooltip-tooltip">
              Clasament
            </Tooltip>
          }
        >
          <NavigationBarLink
            activeClassName="activeNavItem"
            to={`/accelerator/${cohortId}/leaderboard`}
            className="side-sub-nav-link side-nav-link"
            style={{
              padding: '8px 30px',
            }}
          >
            <LeaderBoardIcon />
          </NavigationBarLink>
        </OverlayTrigger>
      </li>
    </>
  );
};

const mapStateToProps = (state) => ({
  cohortId: currentCohortIdSelector(state.cohorts),
  userCohorts: state.cohorts.cohorts,
  userProfile: get(state.userProfile, 'userProfile'),
  role: get(state.account.login, 'user.role'),
});

const mapDispatchToProps = {
  setCurrentUserCohort,
  resetUserCohorts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceleratorNavigationMenu);
