import React from 'react';
import ModalComponent from '../../../common/components/ModalComponent';
import { Formik } from 'formik';
import GenericFormWrapper from '../../../common/components/forms/formComponents/GenericFormWrapper';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { SaveIconOutlined } from '../../../common/assets/icons';
import RenderRichText from '../../../common/components/RichEditor';
import { ACTIVITIES_TOOLBAR_CONFIG } from '../../../common/constants/RichTextEditorConstants';
import * as Yup from 'yup';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import {
  activityTypeOptions,
  categoryOptions,
} from '../../../opportunityAnalysis/components/forms/DetailsForm';

const AddTaskDetailModal = ({
  isModalOpen,
  isEditMode = false,
  handleSubmit,
  setTaskDetailModalId,
}) => {
  const initialValues = {
    title: '',
    description: '',
    activityType: '',
    category: '',
  };
  const schema = Yup.object({
    title: Yup.string().required('Camp Obligatoriu'),
    description: Yup.string().required('Camp Obligatoriu'),
    activityType: Yup.string().required('Camp Obligatoriu'),
    category: Yup.string().required('Camp Obligatoriu'),
  });

  const handleClose = () => {
    setTaskDetailModalId(null);
  };

  const handleUpdate = () => {};

  return (
    <ModalComponent
      title="Adauga detaliu"
      open={isModalOpen}
      handleClose={handleClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={isEditMode ? handleUpdate : handleSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <GenericFormWrapper maxHeight="700px">
              <CustomTextField id="title" label="Titlul detaliului" />
              <CustomSelectField
                id="activityType"
                placeholder="Alege tipul contractului"
                label="Tip activitate"
                options={activityTypeOptions}
              />
              <CustomSelectField
                id="category"
                placeholder="Alege categoria detaliului"
                label="Categorie detaliu"
                options={categoryOptions}
              />
              <RenderRichText
                name="description"
                label="Adauga o descriere"
                height="200px"
                fullHeight
                config={ACTIVITIES_TOOLBAR_CONFIG}
                positionToolbarBottom
              />
            </GenericFormWrapper>
            <PrimaryButton
              marginTop="30px"
              icon={SaveIconOutlined}
              type="submit"
            >
              {isEditMode ? 'Editeaza' : 'Salveaza'}
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </ModalComponent>
  );
};

export default AddTaskDetailModal;
