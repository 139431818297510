import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, useField, FieldArray, useFormikContext } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { get, size } from 'lodash-es';
import {
  Input,
  Heading2,
  SubmitButton,
  DatePicker,
  ButtonDiv,
  SaveIcon,
  LinkIcon,
  PositionParent,
  AddCommentButton,
  MinusButton,
} from '../../../common/components/styled';

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderLinkField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <PositionParent>
        <LinkIcon />
        <Input
          formlink
          ref={fieldRef}
          type={type}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onBlur={() => {
            helpers.setTouched();
          }}
          onChange={(e) => {
            helpers.setTouched();
            handleChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Control.Feedback type="invalid">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </PositionParent>
    </Form.Group>
  );
};

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
    md,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md={md} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        ref={fieldRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onBlur={() => {
          helpers.setTouched();
        }}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDateField = (props) => {
  const { values, name, placeholder, controlId, label, setFieldValue } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue(name, '');
    }
  };

  return (
    <Form.Group as={Col} md="6" controlId={controlId}>
      <Heading2 marginBottom="10px" ref={fieldRef}>
        {label}
      </Heading2>
      <Input
        component={DatePicker}
        as={DatePicker}
        name={name}
        placeholderText={placeholder}
        selected={fieldValue}
        value={fieldValue}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={new Date()}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
          handleNullValue(v);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderKPIsField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    setShowPlusButton,
    showPlusButton,
    label,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (size(values.KPIs) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.KPIs, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Heading2 marginBottom="10px">{label}</Heading2>

          {values.KPIs.map((KPI, index) => (
            <Form.Row key={index} style={{ width: '100%' }}>
              <Form.Group as={Col} md="10">
                <PositionParent>
                  <LinkIcon />
                  <Input
                    formlink
                    type={type}
                    placeholder={placeholder}
                    name={`KPIs[${index}]`}
                    onChange={(e) => {
                      helpers.setTouched();
                      handleChange(e);
                    }}
                    value={values.KPIs[index]}
                    isInvalid={
                      meta.touched &&
                      meta.error &&
                      meta.error[index] &&
                      meta.error[index]
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: 'inherit' }}
                  >
                    {meta.touched &&
                      meta.error &&
                      meta.error[index] &&
                      meta.error[index]}
                  </Form.Control.Feedback>
                </PositionParent>
              </Form.Group>
              <Form.Group
                as={Col}
                md="1"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {values.KPIs.length > 1 && (
                  <MinusButton onClick={() => arrayHelpers.remove(index)} />
                )}
              </Form.Group>
            </Form.Row>
          ))}
          <div
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {showPlusButton && (
              <AddCommentButton
                positionedButton
                onClick={() => arrayHelpers.push('')}
              />
            )}
          </div>
        </>
      )}
    />
  );
};
const RenderFoundsDestinationField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    setShowPlusButton,
    showPlusButton,
    label,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (size(values.foundsDestination) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.foundsDestination, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Heading2 marginBottom="10px">{label}</Heading2>

          {values.foundsDestination.map((found, index) => (
            <Form.Row key={index} style={{ width: '100%' }}>
              <Form.Group as={Col} md="10">
                <PositionParent>
                  <Input
                    link
                    type={type}
                    placeholder={placeholder}
                    name={`foundsDestination[${index}]`}
                    onChange={(e) => {
                      helpers.setTouched();
                      handleChange(e);
                    }}
                    value={values.foundsDestination[index]}
                    isInvalid={
                      meta.touched &&
                      meta.error &&
                      meta.error[index] &&
                      meta.error[index]
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: 'inherit' }}
                  >
                    {meta.touched &&
                      meta.error &&
                      meta.error[index] &&
                      meta.error[index]}
                  </Form.Control.Feedback>
                </PositionParent>
              </Form.Group>

              <Form.Group
                as={Col}
                md="1"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {values.foundsDestination.length > 1 && (
                  <MinusButton onClick={() => arrayHelpers.remove(index)} />
                )}
              </Form.Group>
            </Form.Row>
          ))}
          <div style={{ width: '100%', position: 'relative' }}>
            {showPlusButton && (
              <AddCommentButton
                positionedButton
                onClick={() => arrayHelpers.push('')}
              />
            )}
          </div>
        </>
      )}
    />
  );
};

const RenderFoundsDestinationToEditField = (props) => {
  const {
    values,
    handleChange,
    name,
    type,
    setShowPlusButton,
    showPlusButton,
    label,
    placeholder,
    valueToEdit,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (size(values.foundsDestination) === 3) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [values.foundsDestination, setShowPlusButton]);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Heading2 marginBottom="10px">{label}</Heading2>

          {values.foundsDestination.map(
            (found, index) =>
              valueToEdit === index && (
                <Form.Row key={index} style={{ width: '100%' }}>
                  <Form.Group as={Col} md="12">
                    <PositionParent>
                      <Input
                        link
                        type={type}
                        placeholder={placeholder}
                        name={`foundsDestination[${index}]`}
                        onChange={(e) => {
                          helpers.setTouched();
                          handleChange(e);
                        }}
                        value={values.foundsDestination[index]}
                        isInvalid={
                          meta.touched &&
                          meta.error &&
                          meta.error[index] &&
                          meta.error[index]
                        }
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'inherit' }}
                      >
                        {meta.touched &&
                          meta.error &&
                          meta.error[index] &&
                          meta.error[index]}
                      </Form.Control.Feedback>
                    </PositionParent>
                  </Form.Group>
                </Form.Row>
              )
          )}
          <div style={{ width: '100%', position: 'relative' }}>
            {showPlusButton && (
              <AddCommentButton
                positionedButton
                onClick={() => arrayHelpers.push('')}
              />
            )}
          </div>
        </>
      )}
    />
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  checkTeamChodeUsed,
  initialValues,
  setFieldValue,
  isValid,
  isSubmitting,
  onSubmit,
  setPrivateTeam,
  privateTeam,
  copyToClipBoard,
  teamCodeFieldRef,
  copySuccess,
  section,
  showPlusButton,
  setShowPlusButton,
  valueToEdit,
  ...rest
}) => (
  <Form>
    {section === 'IRL' && (
      <>
        <Form.Row>
          <RenderLinkField
            values={values}
            md="12"
            name="linkVideo"
            label="1. Video prezentare echipă"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik01"
          />
        </Form.Row>
        <Form.Row>
          <RenderLinkField
            values={values}
            md="12"
            name="marketSize"
            label="4. Dimensiunea pieței țintă"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik02"
          />
        </Form.Row>
        <Form.Row>
          <RenderLinkField
            values={values}
            md="6"
            name="LVP"
            label="6. Prezentare/video cu prototipul brut"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik03"
          />
          <RenderLinkField
            values={values}
            md="6"
            name="HVP"
            label="9. Prezentare/video cu prototipul rafinat"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik04"
          />
        </Form.Row>
        <Form.Row>
          <RenderLinkField
            values={values}
            md="12"
            name="financialProjections"
            label="12. Proiecții financiare"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik05"
          />
        </Form.Row>
        <Form.Row>
          <RenderKPIsField
            values={values}
            md="12"
            name="KPIs"
            label="13. Indicatorii ideii"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik06"
            showPlusButton={showPlusButton}
            setShowPlusButton={setShowPlusButton}
          />
        </Form.Row>
        <Form.Row>
          <RenderLinkField
            values={values}
            md="6"
            name="market"
            label="14. Strategie intrare pe piață"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik07"
          />

          <RenderLinkField
            values={values}
            md="6"
            name="pitchDeck"
            label="15. Prezentarea ideii"
            placeholder="https://"
            handleChange={handleChange}
            controlId="validationFormik08"
          />
        </Form.Row>
      </>
    )}
    {section === 'investment' && (
      <>
        <Form.Row>
          <RenderTextField
            values={values}
            md="6"
            name="investmentSought"
            label="Investiție căutată €"
            placeholder="Suma"
            handleChange={handleChange}
            controlId="validationFormik09"
          />
          <RenderTextField
            values={values}
            md="6"
            name="equity"
            label="Acțiuni oferite %"
            placeholder="Procent"
            handleChange={handleChange}
            controlId="validationFormik10"
          />
        </Form.Row>
        <Form.Row>
          <RenderTextField
            name="round"
            values={values}
            handleChange={handleChange}
            controlId="validationFormik11"
            label="Rundă investiție"
            placeholder="Numarul rundei"
          />

          <RenderDateField
            values={values}
            setFieldValue={setFieldValue}
            controlId="validationFormik12"
            name="deadline"
            label="Termen"
            placeholder="zz/ll/aaaa"
          />
        </Form.Row>
      </>
    )}

    {section === 'funds' && (
      <Form.Row>
        <RenderFoundsDestinationField
          values={values}
          md="12"
          name="foundsDestination"
          label="Destinația fondurilor​"
          handleChange={handleChange}
          controlId="validationFormik13"
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
          placeholder="Salarii, R&D, marketing, proprietate intelectuală, cheltuieli juridice etc"
        />
      </Form.Row>
    )}

    {section === 'edit_fund' && (
      <Form.Row>
        <RenderFoundsDestinationToEditField
          values={values}
          valueToEdit={valueToEdit}
          md="12"
          name="foundsDestination"
          label="Destinația fondurilor​"
          handleChange={handleChange}
          controlId="validationFormik13"
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
          placeholder="Salarii, R&D, marketing, proprietate intelectuală, cheltuieli juridice etc"
        />
      </Form.Row>
    )}

    <ButtonDiv>
      <SubmitButton type="button" onClick={handleSubmit}>
        <SaveIcon />
        Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddTeamDetailsForm = ({ onSubmit, team, section, valueToEdit }) => {
  const [showPlusButton, setShowPlusButton] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    investmentSought: Yup.string(),
    equity: Yup.string(),
    round: Yup.string(),
    deadline: Yup.string(),
    pitchDeck: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    LVP: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    HVP: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    linkVideo: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    marketSize: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    financialProjections: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    market: Yup.string().url(
      'URL Invalid (linkul trebuie sa contina http:// sau https://)'
    ),
    KPIs: Yup.array().of(
      Yup.string().url(
        'URL Invalid (linkul trebuie sa contina http:// sau https://)'
      )
    ),
    foundsDestination: Yup.array().of(Yup.string()),
  });

  const defaultValues = {
    investmentSought: team.investmentSought ? team.investmentSought : '',
    equity: team.equity ? team.equity : '',
    round: team.round ? team.round : '',
    deadline: team.deadline ? new Date(team.deadline) : '',
    pitchDeck: team.pitchDeck ? team.pitchDeck : '',
    LVP: team.LVP ? team.LVP : '',
    HVP: team.HVP ? team.HVP : '',
    linkVideo: team.linkVideo ? team.linkVideo : '',
    marketSize: team.marketSize ? team.marketSize : '',
    financialProjections: team.financialProjections
      ? team.financialProjections
      : '',
    market: team.market ? team.market : '',
    KPIs: size(team.KPIs) !== 0 ? team.KPIs : [''],
    foundsDestination:
      size(team.foundsDestination) !== 0 ? team.foundsDestination : [''],
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={defaultValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          valueToEdit={valueToEdit}
          section={section}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      )}
    </Formik>
  );
};

AddTeamDetailsForm.propTypes = {};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  team: state.team.team,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamDetailsForm);
