import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { getInvitedMemberData } from '../../../requests/registration';
import { useHistory } from 'react-router';
import {
  H2,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { USER_ROLE_NAMES } from '../../constants/userRoles';
import { getTeamById } from '../../../requests/myIdeea';
import { IdeeaMemberAvatar } from '../../../userProfile/components/styled/UserProfileComponentStyle';
import { Spinner } from 'react-bootstrap';
import { acceptTeamInvite, rejectTeamInvite } from '../../../requests/teams';
import { useSelector } from 'react-redux';

const JoinTeamPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const userToken = query.get('invitedUser');
  const history = useHistory();
  const [
    { result: invitedUserData, isLoading: loadingUser },
    runGetInvitedMemberData,
  ] = useApi(getInvitedMemberData, null);
  const [{ result: teamData, isLoading: loadingTeam }, runGetTeamById] = useApi(
    getTeamById,
    { members: [] }
  );
  const [, runRejectTeamInvite] = useApi(rejectTeamInvite);
  const [, runAcceptTeamInvite] = useApi(acceptTeamInvite);
  const isLoggedIn = useSelector((state) => state?.account?.login?.isLoggedIn);

  useEffect(() => {
    if (!userToken) {
      return history.push('/');
    }

    if (!isLoggedIn) {
      history.push(`account/login?redirectToAccept=${userToken}`);
    }

    async function initializeData() {
      const userData = await runGetInvitedMemberData({
        params: {
          memberToken: userToken,
        },
      });

      await runGetTeamById({
        params: {
          teamId: userData?.teamId,
        },
      });
    }

    initializeData();
  }, []);

  const handleRejectTeamInvite = () => {
    runRejectTeamInvite({ userData: { memberToken: userToken } });
    history.push('/');
  };
  const handleAcceptTeamInvite = () => {
    runAcceptTeamInvite({ userData: { memberToken: userToken } });
    history.push('/');
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgb(239, 239, 244)',
      }}
    >
      {loadingTeam || loadingUser ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            padding: '40px',
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '600px',
            height: '500px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <H2>
              {`Ai fost invitat sa te alaturi echipei ${
                teamData?.name
              } in calitate de ${USER_ROLE_NAMES[invitedUserData?.userRole]}`}
            </H2>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {teamData?.members.map((member) => (
              <IdeeaMemberAvatar
                style={{ marginRight: '10px' }}
                key={member?.userId}
                src={member?.profilePicture}
                size={70}
                variant="circle"
              >
                {member?.firstName?.substring(0, 2)}
              </IdeeaMemberAvatar>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SecondaryButton
              onClick={handleRejectTeamInvite}
              inverted
              marginRight="20px"
            >
              Refuza invitatia
            </SecondaryButton>
            <PrimaryButton onClick={handleAcceptTeamInvite}>
              Accepta invitatia
            </PrimaryButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default JoinTeamPage;
