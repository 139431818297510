import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import IdeaDetailsSection from './IdeeaDetailsSection';
import FirstIdeeaComponent from './FirstIdeeaComponent';
import { CreateTeamModal } from '../../team/components/modals';
import MembersBreakdownSection from './MembersBreakdownSection';
import { useApi } from '../../hooks/useApi';
import { getMyIdeeaMembers, getMyIdeeaMentors } from '../../requests/myIdeea';

const IdeaDescriptionPage = ({ currentTeam = {} }) => {
  const [isAddIdeaModalOpen, setIsAddIdeaModalOpen] = useState(false);
  const {
    ideaCategory: tags = [],
    description = '',
    dateOfEstablishment = null,
    cui = null,
    headquarters = null,
    domain = null,
    name = '',
    _id: teamId,
    teamCode,
  } = currentTeam;

  const [{ result: teamMembers }, runGetTeamMembers] =
    useApi(getMyIdeeaMembers);
  const [{ result: teamMentors }, runGetTeamMentors] =
    useApi(getMyIdeeaMentors);

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const updateTeamMentors = async () => {
    await runGetTeamMentors({
      params: {
        teamId: teamId,
      },
    });
  };

  useEffect(() => {
    if (!teamId) return;

    updateTeamMembers();
    updateTeamMentors();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px',
        }}
      >
        <Box sx={{ width: '50%' }}>
          {name ? (
            <IdeaDetailsSection
              tags={tags}
              description={description}
              dateOfEstablishment={dateOfEstablishment}
              cui={cui}
              headquarters={headquarters}
              domain={domain}
              name={name}
              setIsAddIdeaModalOpen={setIsAddIdeaModalOpen}
              teamCode={teamCode}
            />
          ) : (
            <FirstIdeeaComponent setIsModalOpen={setIsAddIdeaModalOpen} />
          )}
        </Box>
        <Box sx={{ width: '50%' }}>
          <MembersBreakdownSection
            members={teamMembers}
            mentors={teamMentors}
            teamId={teamId}
            runUpdateTeamMembers={updateTeamMembers}
            runUpdateTeamMentors={updateTeamMentors}
          />
        </Box>
      </Box>
      <CreateTeamModal
        isModalOpen={isAddIdeaModalOpen}
        setIsModalOpen={setIsAddIdeaModalOpen}
        team={currentTeam}
        isEditMode={!!name}
      />
    </>
  );
};

export default IdeaDescriptionPage;
