import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadHypotheses } from '../../../hypotheses/ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import HypothesesPageLegacy from '../../../hypotheses/components/pages/HypothesesPageLegacy';

const TeamHypothesesPageResolver = (props) => {
  const {
    loadHypotheses,
    match: {
      params: { teamId },
    },
  } = props;
  const loadDependencies = () =>
    teamId ? loadHypotheses(teamId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>Ipoteze</DocumentTitle>
      <Resolver
        successComponent={HypothesesPageLegacy}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

TeamHypothesesPageResolver.propTypes = {
  loadHypotheses: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadHypotheses };

export default withRouter(
  connect(undefined, mapDispatchToProps)(TeamHypothesesPageResolver)
);
