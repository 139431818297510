import React, { useState, useRef } from 'react';
import { get } from 'lodash-es';

import { Popover, Overlay, Modal } from 'react-bootstrap';
import {
  AvatarContainer,
  Heading3Semibold,
  Heading2,
  Heading4Regular,
  EditButtonSmall,
  RowDiv,
  ColDiv,
  DeleteButtonSmall,
  DeleteCommentButton,
} from './styled';
import { AddEquityForm } from '../../team/components/forms';
import MemberIcon from '../assets/icons/Avatar_Membru.svg';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
  ImageIconRoundDiv,
  TitleButtonsDiv,
} from '../../mentors/components/styled';
import { ImageIconRound } from './styled/NavigationBar';

const AlertMessage = ({ show, setShow, onSubmit, userId, resetDeleteUser }) => {
  const handleClose = () => {
    resetDeleteUser();
    setShow(false);
  };
  const handleSubmit = () => {
    onSubmit(userId);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Părăsește echipa</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești să părăsești echipa?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit} maxWidth="100%">
            <Heading4Regular white>Da, părăsește</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const AvatarIcon = ({
  user,
  handleRemoveMember,
  userId,
  createdBy,
  handleEditEquityMember,
  handleMentorLeave,
  isMentor,
  color,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const ref = useRef(null);

  const handleSubmit = (values) => {
    handleEditEquityMember(user.profile.userId._id, values);
    setShow(false);
  };

  const getUserRole = () => {
    const role = get(user, 'profile.userId.role');
    const teamUserId = get(user, 'profile.userId._id');
    const equity = get(user, 'equity');

    if (teamUserId === createdBy) return 'Fondator';
    if (role === 'FOUNDER' && equity > 0) return 'Fondator';
    if (role === 'FOUNDER' && !equity) return 'Partener';
    if (role === 'MENTOR') return 'Mentor';
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const resetDeleteUser = () => setDeleteUser(undefined);

  return (
    <AvatarContainer>
      <RowDiv>
        <ColDiv width="20%">
          <ImageIconRoundDiv>
            <ImageIconRound
              src={
                user.profile.profilePicture
                  ? user.profile.profilePicture
                  : MemberIcon
              }
            />
          </ImageIconRoundDiv>
        </ColDiv>
        <ColDiv width="80%">
          <TitleButtonsDiv>
            <Heading3Semibold>
              {user && `${user.profile.firstName} ${user.profile.lastName}`}
            </Heading3Semibold>

            {userId === createdBy &&
              userId !== user.profile.userId._id &&
              !isMentor && (
                <DeleteButtonSmall
                  onClick={() => handleRemoveMember(user.profile.userId._id)}
                />
              )}

            {isMentor && userId === user.profile.userId._id && (
              <DeleteButtonSmall
                onClick={() => {
                  setDeleteUser(user.profile.userId._id);
                  setOpenAlertMessage(true);
                }}
              />
            )}
          </TitleButtonsDiv>
          <div ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
            <Heading2 color={color}>{getUserRole()}</Heading2>
            {!isMentor && (
              <>
                <Heading4Regular marginLeft="10px">
                  {`Equity : ${user.equity === null ? '0' : user.equity}%`}
                </Heading4Regular>
                {userId === createdBy && (
                  <EditButtonSmall marginleft="5px" onClick={handleClick} />
                )}
                <Overlay
                  show={show}
                  target={target}
                  placement="right"
                  container={ref.current}
                >
                  <Popover id="popover-basic">
                    <Popover.Title as="h3">Adaugă equity</Popover.Title>
                    <Popover.Content>
                      <AddEquityForm onSubmit={handleSubmit} user={user} />
                    </Popover.Content>
                  </Popover>
                </Overlay>
              </>
            )}
          </div>
        </ColDiv>
      </RowDiv>
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleMentorLeave}
        resetDeleteUser={resetDeleteUser}
        userId={deleteUser}
      />
    </AvatarContainer>
  );
};

export default AvatarIcon;
