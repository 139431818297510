import React, { useState } from 'react';
import { Avatar, Box, Grid, IconButton } from '@mui/material';
import { ReactComponent as EditIcon } from '../../common/assets/icons/edit.svg';
import { ProfileModal } from './modals';
import {
  AddIcon,
  ExternalLink,
  PeopleIcon,
  TrashIconOutlined,
} from '../../common/assets/icons';

import {
  ProfileInfoRole,
  IdeeaBoxDiv,
  IdeaProfileBoxEdit,
  ProfileContainer,
  MyIdeasScroll,
  MyIdeasCounter,
  ProfileAvatar,
  NameAndRole,
} from './styled/UserProfileComponentStyle';

import {
  H2,
  H3,
  P,
  PrimaryButton,
  SPAN,
} from '../../common/components/styled/ReusableTextComponents';
import { formatDate } from '../../common/functions/dateHelpers';
import { JoinTeamModal } from '../../teams/components/modals';
import { theme } from '../../core/styles';
import { CreateTeamModal } from '../../team/components/modals';
import { useApi } from '../../hooks/useApi';
import { deleteTeamById } from '../../requests/teams';

const UserProfileTab = (props) => {
  const { userProfile, role, userTeams = [] } = props;

  const [, runDeleteIdea] = useApi(deleteTeamById);

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isJoinTeamModalOpen, setIsJoinTeamModalOpen] = useState(false);
  const [isAddIdeaModalOpen, setIsAddIdeaModalOpen] = useState(false);
  const [editedTeam, setEditedTeam] = useState(null);

  const handleEditIdea = (idea) => {
    setEditedTeam(idea);
    setIsAddIdeaModalOpen(true);
  };

  const handleDeleteIdea = async (idea) => {
    console.log(idea);
    await runDeleteIdea({
      params: {
        teamId: idea._id,
      },
    });
  };

  const handleRoleText = (role) => {
    switch (role) {
      case 'FOUNDER':
        return 'Fondator';
      case 'MENTOR':
        return 'Mentor';

      case 'ACCELERATOR':
        return 'Accelerator';

      default:
        return 'Fondator';
    }
  };

  return (
    <>
      <ProfileContainer className="scrollbar">
        <Box sx={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ProfileAvatar src={userProfile.profilePicture} variant="square" />
            <NameAndRole>
              <H2>
                {userProfile.firstName} {userProfile.lastName}
              </H2>
              <ProfileInfoRole>{handleRoleText(role)}</ProfileInfoRole>
            </NameAndRole>
          </Box>
          <Box sx={{ paddingTop: '50px' }}>
            <Grid container>
              <Grid item mb={3} md={12}>
                <P>
                  Descriere:{' '}
                  {userProfile.about || <SPAN faded> Necompletat. </SPAN>}
                </P>
              </Grid>
              <Grid item mb={3} md={6}>
                <P>
                  Data nasterii:{' '}
                  {formatDate(userProfile.birthDate) || (
                    <SPAN lightBlue> Necompletat. </SPAN>
                  )}
                </P>
              </Grid>
              <Grid item mb={3} md={6}>
                <P>
                  Locatie:{' '}
                  {userProfile.address || <SPAN lightBlue> Necompletat. </SPAN>}
                </P>
              </Grid>
              <Grid item mb={3} md={12}>
                <P>
                  Telefon:{' '}
                  {userProfile.phone || <SPAN lightBlue> Necompletat. </SPAN>}
                </P>
              </Grid>
              <Grid item md={12}>
                <P>
                  Profil Linkedin:{' '}
                  {userProfile.linkedIn ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <a
                        href={userProfile.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '5px' }}
                      >
                        {userProfile.linkedIn}
                      </a>
                      <ExternalLink fill={theme.blueAccent} />
                    </span>
                  ) : (
                    <SPAN lightBlue> Necompletat. </SPAN>
                  )}
                </P>
              </Grid>
            </Grid>

            <PrimaryButton
              inverted
              marginTop="20px"
              onClick={() => setIsProfileModalOpen(true)}
              icon={EditIcon}
            >
              Edit
            </PrimaryButton>
          </Box>
        </Box>
        <Box sx={{ width: '55%', paddingLeft: '20px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <H2>Ideile mele</H2>
              <MyIdeasCounter>
                <span>{userTeams.length}</span>
              </MyIdeasCounter>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <PrimaryButton
                onClick={() => setIsJoinTeamModalOpen(true)}
                inverted
                icon={PeopleIcon}
                iconColor={theme.accentLight}
              >
                Alatura-te unei echipe
              </PrimaryButton>
              <PrimaryButton
                id="add-ideea-button"
                onClick={() => setIsAddIdeaModalOpen(true)}
                icon={AddIcon}
                marginLeft="20px"
              >
                Adauga o idee
              </PrimaryButton>
            </Box>
          </Box>
          <MyIdeasScroll className="scrollbar">
            {userTeams &&
              userTeams.map((item) => (
                <IdeeaBoxDiv key={item._id}>
                  <Box>
                    <Avatar
                      sx={{ width: '67px', height: '67px' }}
                      src={item.logo}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <H3 marginBottom="5px" bold>
                      {item.name}
                    </H3>
                    <P> Asociat </P>
                    <Box sx={{ display: 'flex', marginTop: '5px' }}>
                      <P marginRight="5px">
                        Equity: {item.equity ? item.equity : 'Necompletat'}%
                      </P>
                      <P lightBlue bold>
                        {item.role ? item.role : 'Necompletat'}
                      </P>
                    </Box>
                  </Box>
                  <IdeaProfileBoxEdit>
                    <IconButton onClick={() => handleDeleteIdea(item)}>
                      <TrashIconOutlined fill="red" />
                    </IconButton>

                    <IconButton onClick={() => handleEditIdea(item)}>
                      <EditIcon />
                    </IconButton>
                  </IdeaProfileBoxEdit>
                </IdeeaBoxDiv>
              ))}
            {userTeams.length === 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <P lightBlue> Nici o idee de afacere </P>
              </Box>
            )}
          </MyIdeasScroll>
        </Box>
      </ProfileContainer>
      <ProfileModal
        isModalOpen={isProfileModalOpen}
        setIsModalOpen={setIsProfileModalOpen}
      />
      <CreateTeamModal
        isModalOpen={isAddIdeaModalOpen}
        setIsModalOpen={setIsAddIdeaModalOpen}
        setEditedTeam={setEditedTeam}
        isEditMode={!!editedTeam}
        team={editedTeam}
      />
      <JoinTeamModal
        isOpen={isJoinTeamModalOpen}
        setIsModalOpen={setIsJoinTeamModalOpen}
      />
    </>
  );
};

export default UserProfileTab;
