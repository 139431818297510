import { http } from '../../core/services/http';

export const loadUsers = async () => {
  const res = await http.get('/account/get-all');
  return res.data;
};

export const deleteUser = async (userId) => {
  const res = await http.delete(`/account/delete/${userId}`);
  return res.data;
};

export const deleteUserProfile = (userProfileId) =>
  http.delete(`/account/delete-profile/${userProfileId}`);
