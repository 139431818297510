import styled, { css } from 'styled-components';

const withBorders = css`
  border: 1px solid #74a3fe;
  border-radius: 10px;
  padding: 20px;
`;

export const PaymentsPageContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 82vh;
  padding: 40px;
`;

export const PaymentsSegment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
  min-height: 120px;
  ${(props) => props.withBorders && withBorders}
`;

export const TransactionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${withBorders}
`;

export const SubscriptionsSegmentBox = styled.div`
  width: 48%;
  border: 1px solid #74a3fe;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  min-height: 120px;
`;

export const UserDetailsSegmentBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex-grow: 1;
`;

export const UserDetailsButtonsBox = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-evenly;
  flex-grow: 1;
  padding: 30px 0;
`;
