import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DeleteButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import { PageContainer } from '../../common/components/styled';
import GenericTableComponent from '../../common/components/GenericTableComponent';
import GenericEmptyTable from '../../common/components/GenericEmptyTable';
import { deleteHypothesis, loadHypotheses } from '../../hypotheses/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { currentTeamIdSelector } from '../../common/ducks';
import { BMC_OPTIONS } from '../../canvas/constants';

const HypothesisComponent = () => {
  const dispatch = useDispatch();

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses) || [];

  const handleToggleAddHypothesisModal = () => {};
  const handleSelectedActivity = () => {};

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: 'Denumire', key: 'description' },
    { type: 'string', content: 'BMC', key: 'columnBMC' },
    { type: 'string', content: 'Importanta', key: 'importance' },
    // { type: 'string', content: 'Cost', key: 'cost' }, ??????
    // {
    //   type: 'component',
    //   content: <Box>icon</Box>,
    //   hideSortIcon: true,
    //   key: 'ceva'
    // },
    // { type: 'string', content: 'Concluzie', key: 'conclusion' },
    ...(hypotheses.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const handleDeleteHypothesis = async (id) => {
    await dispatch(deleteHypothesis(id));
    await dispatch(loadHypotheses(teamId));
  };

  const createRows = () =>
    hypotheses.map(
      (
        {
          _id,
          description,
          columnBMC,
          createdAt,
          importance,
          // activityType,
          // problemSeverity,
          // levelOfInterest,
          // contactDisponibility,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        description,
        columnBMC: BMC_OPTIONS.find((option) => option.value === columnBMC)
          .label,
        importance,
        // activityType: searchLabel(activityType, activityTypeOptions),
        // category: searchLabel(category, categoryOptions),
        // problemSeverity:
        //   activityType === 'minutaInterviuClienti' && problemSeverity
        //     ? searchLabel(+problemSeverity, problemSeverityOptions)
        //     : '-',
        // levelOfInterest: determineLevelOfInterest(
        //   activityType,
        //   levelOfInterest
        // ),
        // contactDisponibility: determineContactDisponibility(
        //   activityType,
        //   contactDisponibility
        // ),
        // createdAt: formatDate(createdAt),
        // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
        action: (
          <DeleteButtonComponent
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteHypothesis(_id);
            }}
          />
        ),
      })
    );

  useEffect(() => {
    if (teamId) {
      dispatch(loadHypotheses(teamId));
    }
  }, []);
  return (
    <PageContainer className="scrollbar">
      {hypotheses && !!hypotheses.length ? (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ margin: '0 auto' }}
        >
          <GenericTableComponent
            rows={createRows()}
            headerCells={createTableHeaderCells()}
            handleSelectedRow={handleSelectedActivity}
          />
        </Grid>
      ) : (
        <GenericEmptyTable
          textColor="#74A3FE"
          headers={createTableHeaderCells()}
          onAddClick={handleToggleAddHypothesisModal}
          detailMessage="Nicio ipoteza nu a fost adaugata..."
        />
      )}
    </PageContainer>
  );
};

export default HypothesisComponent;
