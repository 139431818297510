import React, { useState } from 'react';
import * as Yup from 'yup';
import { filter, map, includes, find, isObject, size } from 'lodash-es';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import {
  colorOptions,
  categoryOptions,
  importanceOptions,
} from '../../constants';
import { BMC_OPTIONS } from '../../../canvas/constants';
import HypothesisForm from './HypothesisForm';

const AddHypothesisForm = ({ onSubmit, isEditMode, hypothesis }) => {
  const [addColorProperty, setAddColorProperty] = useState(false);
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [showSegmentField, setShowSegmentField] = useState(false);

  const hypotheses = useSelector((state) => state.hypotheses.hypotheses);
  const defaultColumnBMC = useSelector(
    (state) =>
      isObject(state.common.modal.props) &&
      state.common.modal.props.initialValues.columnBMC
  );

  const segmentHypothesis = filter(hypotheses, {
    columnBMC: 'customerSegments',
  });
  const segmentOptions = map(segmentHypothesis, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
    color: `${el.color}`,
  }));

  const defaultHypothesis = {
    ...hypothesis,
    segment:
      hypothesis &&
      filter(segmentOptions, (elem) =>
        includes(map(hypothesis.segment), elem.value)
      ),
    hypothesisCategory:
      hypothesis &&
      find(categoryOptions, {
        value: hypothesis.hypothesisCategory,
      }),
    importance:
      hypothesis &&
      find(importanceOptions, {
        value: hypothesis.importance,
      }),
    KPIIndicators:
      hypothesis &&
      hypothesis.KPIIndicators &&
      size(hypothesis.KPIIndicators) !== 0
        ? hypothesis.KPIIndicators
        : [{ title: undefined, targetValue: undefined }],
    columnBMC: hypothesis && find(BMC_OPTIONS, { value: hypothesis.columnBMC }),
  };

  const getRandomColor = (currentColors) => {
    const randomColors = filter(
      colorOptions,
      (el) => !includes(currentColors, el)
    );
    const randomColor =
      size(randomColors) === 0
        ? colorOptions[Math.floor(Math.random() * colorOptions.length)]
        : randomColors[Math.floor(Math.random() * randomColors.length)];

    return randomColor;
  };

  const currentColors = map(hypotheses, 'color');

  const handleSubmit = (values, { resetForm }) => {
    const newValues = {
      ...values,
      color: addColorProperty ? getRandomColor(currentColors) : '',
      segment: values.segment ? values.segment : '',
    };
    const valuesToSubmit = isEditMode ? values : newValues;
    onSubmit(valuesToSubmit);
  };

  const schema = Yup.object({
    title: Yup.string().required('Câmp obligatoriu'),
    description: Yup.string().required('Câmp obligatoriu'),
    hypothesisCategory: Yup.string().required('Câmp obligatoriu'),
    importance: Yup.string().required('Câmp obligatoriu'),
    columnBMC: Yup.string().required('Câmp obligatoriu'),
    segment: showSegmentField
      ? Yup.string().required('Camp Obligatoriu')
      : Yup.string(),
    KPIIndicators: Yup.array().of(
      Yup.object({
        title: Yup.string(),
        targetValue: Yup.string(),
      })
    ),
  });

  const defaultValues = {
    title: '',
    hypothesisCategory: '',
    description: '',
    columnBMC: defaultColumnBMC
      ? find(BMC_OPTIONS, { value: defaultColumnBMC })
      : '',
    segment: '',
    importance: '',
    KPIIndicators: [{ title: undefined, targetValue: undefined }],
  };

  const initialValues = isEditMode ? defaultHypothesis : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <HypothesisForm
          setAddColorProperty={setAddColorProperty}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
          segmentOptions={segmentOptions}
          isEditMode={isEditMode}
          showSegmentField={showSegmentField}
          setShowSegmentField={setShowSegmentField}
          {...props}
        />
      )}
    </Formik>
  );
};

export default AddHypothesisForm;
