import React, { useState } from 'react';
import { Box, Checkbox, Divider } from '@mui/material';
import {
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { BmcStyledFiltersDropdown } from './styled/BusinessModelCavas';

const importanceFilterOptions = [
  { name: 'Mare', value: 'high' },
  { name: 'Medie', value: 'medium' },
  { name: 'Mica', value: 'low' },
];

const statusFilterOptions = [
  { name: 'Validata', value: 'valid' },
  { name: 'Invalida', value: 'invalid' },
  { name: 'In curs de validare', value: 'no_status' },
];

const BmcFilterDropdown = ({
  close,
  importanceValues,
  statusValues,
  setStatusValues,
  setImportanceValues,
  setSelectedFilters,
}) => {
  const [localImportance, setLocalImportance] = useState(importanceValues);
  const [localStatus, setLocalStatus] = useState(statusValues);

  const handleCheckImportance = (importance) => {
    setLocalImportance({
      ...localImportance,
      [importance.value]: !localImportance[importance.value],
    });
  };

  const handleCheckStatus = (status) => {
    setLocalStatus({
      ...localStatus,
      [status.value]: !localStatus[status.value],
    });
  };

  const handleSave = () => {
    const validFilters = [];
    importanceFilterOptions.forEach((filter) => {
      if (localImportance[filter.value]) {
        validFilters.push(filter.name);
      }

      setSelectedFilters(validFilters);
    });

    setImportanceValues(localImportance);
    setStatusValues(localStatus);

    close();
  };

  return (
    <BmcStyledFiltersDropdown className="display-flex flex-column">
      <P>{`Importanta (${Object.values(localImportance).reduce(
        (acc, cur) => acc + cur,
        0
      )})`}</P>

      {importanceFilterOptions.map((element) => (
        <Box key={element.value} className="d-flex align-items-center">
          <Checkbox
            onClick={() => handleCheckImportance(element)}
            checked={localImportance[element.value]}
          />
          <P faded>{element.name}</P>
        </Box>
      ))}

      <P>{`Status ipoteza (${Object.values(localStatus).reduce(
        (acc, cur) => acc + cur,
        0
      )})`}</P>

      {statusFilterOptions.map((element) => (
        <Box key={element.value} className="d-flex align-items-center">
          <Checkbox
            onClick={() => handleCheckStatus(element)}
            checked={localStatus[element.value]}
          />
          <P faded>{element.name}</P>
        </Box>
      ))}

      <Divider sx={{ backgroundColor: '#74A3FE' }} />

      <Box
        sx={{ padding: '0 5px', marginTop: '10px' }}
        className="display-flex justify-content-space-between"
      >
        <PrimaryButton onClick={close} inverted>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={handleSave} marginLeft="10px">
          Salveaza
        </PrimaryButton>
      </Box>
    </BmcStyledFiltersDropdown>
  );
};

export default BmcFilterDropdown;
