import React from 'react';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { get } from 'lodash-es';
import { ButtonDivStart } from '../../../common/components/styled/Buttons';
import {
  SubmitButton,
  SaveIcon,
  Input,
} from '../../../common/components/styled';
import {
  DivFlexTabs,
  RoleTabs,
  RoleTabsActive,
  RoleTabsText,
  RoleTabsTextActive,
} from '../../../common/components/styled/Inputs';

const RoleRenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  initialValues,
  setFieldValue,
  isValid,
  role,
  hacker,
  ...rest
}) => (
  <Form noValidate>
    <DivFlexTabs>
      {hacker !== null ? (
        <RoleTabsActive>
          <RoleTabsTextActive>Hacker</RoleTabsTextActive>
        </RoleTabsActive>
      ) : null}
      <RoleTabs>
        <RoleTabsText>Hipster</RoleTabsText>
      </RoleTabs>
      <RoleTabs>
        <RoleTabsText>Hustler</RoleTabsText>
      </RoleTabs>
    </DivFlexTabs>
    <RenderTextField
      placeholder="Equity %"
      name="firstName"
      values={values}
      handleChange={handleChange}
      controlId="validationFormik01"
      label="Nume*"
    />
    <ButtonDivStart>
      <SubmitButton type="submit" onClick={handleSubmit}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDivStart>
  </Form>
);

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RoleForm = ({ role }) => (
  <Formik>{(props) => <RoleRenderForm {...props} role={role} />}</Formik>
);

export default RoleForm;
