import React, { useEffect, useState } from 'react';
import { SideGuideContainer } from './styled/ResourcesStyled';
import { useApi } from '../../hooks/useApi';
import { getAllChapters } from '../../requests/chapters';
import { getAllArticles } from '../../requests/articles';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import ChaptersSideGuide from './ChaptersSideGuide';
import ArticlesSideGuide from './ArticlesSideGuide';

const SideGuide = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const isAdminPage = pathname.includes('admin');

  const [tab, setTab] = useState('');
  const [expandedAccordions, setExpandedAccordions] = useState({
    panel0: true,
  });
  const queryParams = new URLSearchParams(window.location?.search) || '';

  const subchapterIdFromUrl = queryParams.get('id');
  const chapterIdFromUrl = queryParams.get('chapterId');

  const [{ result: chaptersListResult = [] }, fetchAllChapters] =
    useApi(getAllChapters);

  const [{ result: articlesListResult = [] }, fetchAllArticles] =
    useApi(getAllArticles);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions((prevExpandedAccordions) => {
      // Copy the current state
      const newExpandedAccordions = { ...prevExpandedAccordions };

      // If the panel is expanded, add it to the state, otherwise remove it
      if (isExpanded) {
        newExpandedAccordions[panel] = true;
      } else {
        delete newExpandedAccordions[panel];
      }

      return newExpandedAccordions;
    });
  };

  const addIdToUrl = (key, id) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(key, id);
    history.push(currentUrl.pathname + currentUrl.search);
  };

  const handleChaptersFetch = async (payload) => {
    await fetchAllChapters(payload);
  };

  const handleArticlesFetch = async () => {
    const payload = { isVisible: true };
    await fetchAllArticles(payload);
  };

  const handleSubchapterClick = (id, chapterId) => {
    addIdToUrl('id', id);
    addIdToUrl('chapterId', chapterId);
  };

  const handleArticleClick = (id) => {
    addIdToUrl('id', id);
  };

  useEffect(() => {
    const tabParam = queryParams.get('tab');
    setTab(tabParam);
  }, [queryParams]);

  useEffect(() => {
    if (tab === 'chapters') {
      isAdminPage
        ? handleChaptersFetch({})
        : handleChaptersFetch({ isVisible: true });
    }
    if (tab === 'articles') {
      handleArticlesFetch();
    }
  }, [tab]);

  useEffect(() => {
    if (!!chaptersListResult.length && tab === 'chapters') {
      addIdToUrl('id', chaptersListResult[0].subchapters[0]._id);
      addIdToUrl('chapterId', chaptersListResult[0]._id);
    }

    if (!!articlesListResult.length && tab === 'articles') {
      addIdToUrl('id', articlesListResult[0]._id);
    }
  }, [chaptersListResult, articlesListResult]);

  useEffect(() => {
    if (chaptersListResult.length && chapterIdFromUrl) {
      const chapterIndex = chaptersListResult.findIndex(
        (chapter) => chapter._id === chapterIdFromUrl
      );
      setExpandedAccordions((prevExpandedAccordions) => {
        const newExpandedAccordions = { ...prevExpandedAccordions };
        newExpandedAccordions[`panel${chapterIndex}`] = true;
        return newExpandedAccordions;
      });
    }
  }, [chapterIdFromUrl]);

  const subchapterId =
    subchapterIdFromUrl || chaptersListResult[0]?.subchapters[0]?._id;

  return (
    <SideGuideContainer>
      <Box
        sx={{
          padding: '40px 1px 40px 15px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {tab === 'chapters' && (
          <ChaptersSideGuide
            chaptersListResult={chaptersListResult}
            expandedAccordions={expandedAccordions}
            handleAccordionChange={handleAccordionChange}
            subchapterId={subchapterId}
            isAdminPage={isAdminPage}
            handleSubchapterClick={handleSubchapterClick}
          />
        )}
        {tab === 'articles' && (
          <ArticlesSideGuide
            handleArticleClick={handleArticleClick}
            articlesListResult={articlesListResult}
          />
        )}
      </Box>
    </SideGuideContainer>
  );
};

export default SideGuide;
