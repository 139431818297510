import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, TextField } from '@mui/material';
import {
  NavigationBarLink,
  ButtonDiv,
} from '../../../common/components/styled';

import ModalComponent from '../../../common/components/ModalComponent';
import {
  P,
  PrimaryButton,
  SecondaryButton,
} from '../../../common/components/styled/ReusableTextComponents';

const renderUnableToDeleteAccount = () => (
  <>
    <P>Nu poti sterge contul</P>
    <P marginBottom="10px" marginTop="10px">
      Pentru a putea sterge contul trebuie sa nu mai faci parte din nici un
      proiect.
    </P>
    <ButtonDiv>
      <NavigationBarLink to="/idea">
        <PrimaryButton outlined> Vezi proiectele mele </PrimaryButton>
      </NavigationBarLink>
    </ButtonDiv>
  </>
);

const DeleteProfilModal = (props) => {
  const { isModalOpen, handleClose, userTeams } = props;

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleClose}
      title="Sterge profil"
    >
      {userTeams && userTeams.length === 2 ? (
        <Box>
          <P marginBottom="10px" light>
            Stergand profilul, continutul nu poate fi recuperat.
          </P>
          <P marginBottom="10px" light>
            Confirma actiunea definitiva scriind emailul tau mai jos:
          </P>

          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <SecondaryButton>Sterge</SecondaryButton>
        </Box>
      ) : (
        renderUnableToDeleteAccount()
      )}
    </ModalComponent>
  );
};
const mapStateToProps = (state) => ({
  userTeams: state.common.userTeams.userTeams,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteProfilModal)
);
