import styled from 'styled-components';

export const ActivitiesDrawerButtonsContainer = styled.div`
  margin-top: 10px;
  padding-top: 25px;
  border-top: ${(props) => `1px solid ${props.theme.accentLight}`};
  margin-bottom: 10px;
  padding-bottom: 25px;
  border-bottom: ${(props) => `1px solid ${props.theme.accentLight}`};
  display: flex;
`;

export const ScrollableDrawerContent = styled.div`
  width: 400px;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
`;

export const DrawerHeader = styled.div``;
