import { Col, Form } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import { find, get } from 'lodash-es';
import {
  CustomLabel,
  Dropdown,
  InlineDropdown,
} from '../../../core/components';
import {
  ButtonDiv,
  Input,
  PositionParent,
  SaveIcon,
  SubmitButton,
} from '../../../common/components/styled';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import { categoryOptions, importanceOptions } from '../../constants';
import { BMC_OPTIONS } from '../../../canvas/constants';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderTitleField = (props) => {
  const { values, handleChange, name, label, controlId, type = 'text' } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12">
      <Input
        ref={fieldRef}
        id={controlId}
        type={type}
        name={name}
        height="56px"
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderDescriptionFieldField = (props) => {
  const { values, handleChange, name, controlId, label, type } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12">
      <Input
        height="100px"
        as={type}
        id={controlId}
        ref={fieldRef}
        paddingTop="30px"
        name={name}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderImportanceField = (props) => {
  const { values, setFieldValue, controlId, name, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Input
        as={InlineDropdown}
        height="56px"
        fieldRef={fieldRef}
        name={name}
        options={importanceOptions}
        value={fieldValue}
        label={label}
        marginBottom="3px"
        classes="disabled-input"
        tooltipDescription="Importance"
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('importance', v);
        }}
        isInvalid={meta.touched && meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderColumnBmcField = (props) => {
  const {
    values,
    setFieldValue,
    controlId,
    name,
    setAddColorProperty,
    setShowSegmentField,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  const handleCategoryFieldValue = (type) => {
    let value;
    switch (type) {
      case 'customerSegments':
      case 'customerRelationships':
      case 'valuePropositions':
      case 'channels':
      case 'revenueStreams':
        value = 'dezirabila';
        break;
      case 'keyResources':
      case 'keyActivities':
      case 'keyPartners':
        value = 'viabila';
        break;
      case 'costStructure':
        value = 'fezabila';
        break;
      default:
        value = '';
    }
    const returnedValue = find(categoryOptions, {
      value,
    });

    return returnedValue;
  };

  useEffect(() => {
    setFieldValue(
      'hypothesisCategory',
      handleCategoryFieldValue(fieldValue.value)
    );
  }, [fieldValue, setFieldValue]);

  useEffect(() => {
    if (fieldValue.value === 'customerSegments') {
      setAddColorProperty(true);
      setShowSegmentField(false);
    } else {
      setAddColorProperty(false);
      setShowSegmentField(true);
    }
  }, [fieldValue, setAddColorProperty, setShowSegmentField]);

  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Input
        as={Dropdown}
        fieldRef={fieldRef}
        placeholder={placeholder}
        name={name}
        height="60px"
        tooltipDescription="BMC Desc"
        options={BMC_OPTIONS}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('columnBMC', v);
          setFieldValue(
            'hypothesisCategory',
            handleCategoryFieldValue(v.value)
          );
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderHypothesisCategoryField = (props) => {
  const { values, setFieldValue, controlId, name, label } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });
  const fieldValue = get(values, name);

  return (
    <Form.Group
      style={{ display: 'none' }}
      as={Col}
      md="6"
      controlId={controlId}
    >
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        height="60px"
        tooltipDescription="ChapterAccordion"
        placeholder={label}
        name={name}
        disabled
        options={categoryOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('hypothesisCategory', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderSegmentField = (props) => {
  const {
    values,
    setFieldValue,
    controlId,
    name,
    segmentOptions,
    placeholder,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  const fieldValue = get(values, name);

  const handleNullValue = (value) => {
    if (value === null) {
      setFieldValue('segment', []);
    }
  };
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        height="60px"
        placeholder={placeholder}
        tooltipDescription="Segment"
        name={name}
        isMulti
        showInfo
        showSegmentColor
        options={segmentOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('segment', v);
          handleNullValue(v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const targetIndicatorsOptions = [
  { value: 'activeUsers', label: 'Active users' },
  { value: 'payingClients', label: 'Clienti plătitori' },
  { value: 'dailyActiveUsers', label: 'Daily active users' },
  { value: 'financiersDiscussions', label: 'Discuții finanțatori' },
  {
    value: 'potentialClientsInterviews',
    label: 'Interviuri clienți potențiali',
  },
  { value: 'invalidatedHypotheses', label: 'Ipoteze invalidate' },
  { value: 'validatedHypotheses', label: 'Ipoteze validate' },
  { value: 'pivots', label: 'Pivotări' },
  { value: 'sam', label: 'Servisable addressable market (SAM)' },
  { value: 'som', label: 'Servisable obtainable market (SOM)' },
  { value: 'tam', label: 'Total addressable market (TAM)' },
  { value: 'workingCapitalRO', label: '$ Capital de lucru' },
  { value: 'cac', label: '$ Customer aquizition cost (CAC)' },
  { value: 'mrr', label: '$ Monthly reccuring revenue (MRR)' },
  { value: 'totalInvestmentsAttracted', label: '$ Total investiții atrase' },
  { value: 'wrr', label: '$ Weekly recurring revenue (WRR)' },
  { value: 'burnRate', label: '% Burn rate' },
  { value: 'viralityCoefficient', label: '% Coeficientul de viralitate' },
  { value: 'momGrowth', label: '% Month-on-month (MoM) growth' },
  { value: 'abandonRate', label: '% Rata de abandon (churn rate)' },
  { value: 'conversionRate', label: '% Rata de conversie' },
  {
    value: 'conversionRateToPayingClients',
    label: '% Rata de conversie in clienti platitori',
  },
  {
    value: 'salesGrowthRate',
    label: '% Rata de creștere a vânzărilor',
  },
  { value: 'viralCoefficient', label: '% Viral coefficient' },
  { value: 'costs', label: '∑ Cheltuieli' },
  { value: 'customerAquizitionCost', label: '∑ Customer aquizition cost' },
  { value: 'pipelineValue', label: '∑ Pipeline value' },
  { value: 'clientsValue', label: '∑ Valoarea clientilor (LTV)' },
  { value: 'totalIncome', label: '∑ Venituri totale' },
  { value: 'mrl', label: 'Market readiness level (MRL)' },
  { value: 'nps', label: 'Net promoter score (NPS)' },
  { value: 'momGrowthAlt', label: 'Month-on-month (MoM) growth' },
  { value: 'pipelineValueDollar', label: '$ Pipeline value' },
  { value: 'workingCapital', label: '$ Working capital' },
];

const complete = (array) => {
  while (array.length < 3) {
    array.push({ title: '', targetValue: '' });
  }
  return array;
};
const RenderKPIsField = (props) => {
  const { values, handleChange, name, type, label } = props;

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {complete(values.KPIIndicators).map((KPI, index) => (
              <Form.Row
                key={index}
                style={{ width: '100%', margin: '0 0 10px 0' }}
              >
                <PositionParent style={{ display: 'flex' }} width="100%">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={{
                      display: 'flex',
                      paddingLeft: '0',
                      flexDirection: 'column',
                    }}
                  >
                    {/* <Input */}
                    {/*  link */}
                    {/*  id={`kpiindicatortitle_${index}`} */}
                    {/*  type={type} */}
                    {/*  height="60px" */}
                    {/*  name={`KPIIndicators.${index}.title`} */}
                    {/*  onChange={(e) => { */}
                    {/*    handleChange(e); */}
                    {/*  }} */}
                    {/*  value={get(values.KPIIndicators[index], 'title')} */}
                    {/* /> */}

                    {/* <CustomLabel */}
                    {/*  label="Indicatori si valoare tinta" */}
                    {/*  tooltipDescription={label} */}
                    {/*  classes="kpi" */}
                    {/*  labelFor={`kpiindicatortitle_${index}`} */}
                    {/* /> */}
                    <CustomSelectField
                      id={`kpiindicatortitle_${index}`}
                      placeholder="Alege tipul indicatorului"
                      label="Indicatori si valoare tinta"
                      options={targetIndicatorsOptions}
                    />
                  </Form.Group>

                  <Form.Group
                    style={{ display: 'flex', paddingRight: '0' }}
                    as={Col}
                    md="6"
                  >
                    {/* <Input */}
                    {/*  id={`kpiindicatortargetvalue_${index}`} */}
                    {/*  type={type} */}
                    {/*  height="56px" */}
                    {/*  name={`KPIIndicators.${index}.targetValue`} */}
                    {/*  onChange={(e) => { */}
                    {/*    handleChange(e); */}
                    {/*  }} */}
                    {/*  value={get(values.KPIIndicators[index], 'targetValue')} */}
                    {/* /> */}
                    {/* <CustomLabel */}
                    {/*  label="Valoare indicatori" */}
                    {/*  tooltipDescription={label} */}
                    {/*  classes="kpi" */}
                    {/*  labelFor={`kpiindicatortargetvalue_${index}`} */}
                    {/* /> */}
                    <CustomTextField
                      id={`kpiindicatortargetvalue_${index}`}
                      label="Valoare indicatori"
                      value={get(values.KPIIndicators[index], 'targetValue')}
                    />
                  </Form.Group>
                </PositionParent>
              </Form.Row>
            ))}
          </>
        )}
      />
    </>
  );
};

const HypothesisForm = ({
  handleSubmit,
  handleChange,
  values,
  setFieldValue,
  setAddColorProperty,
  segmentOptions,
  showSegmentField,
  setShowSegmentField,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTitleField
        placeholder="Titlul ipotezei"
        name="title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul ipotezei*"
      />
      <RenderHypothesisCategoryField
        values={values}
        setFieldValue={setFieldValue}
        name="hypothesisCategory"
        label="Categorie ipoteză"
      />
    </Form.Row>
    <Form.Row>
      <RenderDescriptionFieldField
        placeholder="Descrie pe scurt ipoteza pe care vrei să o testezi"
        name="description"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Descriere*"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderColumnBmcField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="columnBMC"
        label="Se referă la*"
        placeholder="Alege minim o secțiune din BMC"
        setAddColorProperty={setAddColorProperty}
        setShowSegmentField={setShowSegmentField}
      />

      <RenderImportanceField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik04"
        name="importance"
        label="Importanța"
      />
    </Form.Row>
    <Form.Row>
      {showSegmentField && (
        <RenderSegmentField
          values={values}
          setFieldValue={setFieldValue}
          controlId="validationFormik05"
          name="segment"
          label="Segmente vizate*"
          placeholder={
            <CustomLabel
              label="Segmente vizate"
              tooltipDescription="Segmente vizate"
              marginLeft="0"
            />
          }
          segmentOptions={segmentOptions}
        />
      )}
    </Form.Row>
    <Form.Row>
      <Col md="12">
        <RenderKPIsField
          values={values}
          md="12"
          name="KPIIndicators"
          label="Cum putem măsura valoarea și succesul?"
          handleChange={handleChange}
          controlId={`validationFormik0${showSegmentField ? '6' : '5'}`}
        />
      </Col>
    </Form.Row>
    <ButtonDiv justifyLeft>
      <SubmitButton type="submit" style={{ padding: '20px' }}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

export default HypothesisForm;
