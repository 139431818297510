import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import store from './store';
import { theme } from './core/styles';
import history from './core/services/history';

import AppRouter from './AppRouter';
import { muiTheme } from './core/styles/theme';
import { TourProvider } from './context/tourContext';
import JoyRideTour from './onboarding/components/JoyRideTour';

const App = () => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <TourProvider>
          <Router history={history}>
            <JoyRideTour />
            <Switch>
              <Route component={AppRouter} />
            </Switch>
          </Router>
        </TourProvider>
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>
);

export default App;
