import React from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';

// COMPONENTS
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { PrimaryButton } from '../../../common/components/styled/ReusableTextComponents';
import { GridIcon } from '../../../common/assets/icons';

const RenderNameField = (props) => {
  const { label, placeholder, type = 'text' } = props;
  const [field, meta] = useField(props);
  return (
    <Grid item xs={12}>
      <TextField
        {...field}
        fullWidth
        label={label}
        type={type}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
      />
    </Grid>
  );
};

const RenderForm = ({ values, handleSubmit }) => {
  const isSaveDisabled = !values.teamCode;
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <RenderNameField
          name="teamCode"
          label="Cod Idee"
          placeholder="Cod Idee"
        />
      </Grid>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <PrimaryButton disabled={isSaveDisabled} type="submit" icon={GridIcon}>
          Salveaza
        </PrimaryButton>
      </Box>
    </form>
  );
};

const JoinTeamForm = ({ onSubmit }) => {
  const handleSubmit = (values) => {
    onSubmit(values.teamCode);
  };

  const schema = Yup.object({
    teamCode: Yup.string().required('Câmp obligatoriu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        teamCode: '',
      }}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default JoinTeamForm;
