import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Nav } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import Payments from './Payments';
import Conditions from './Conditions';
import Settings from '../Settings';
import UserProfileTab from '../UserProfileTab';
import SubscriptionProfile from '../SubscriptionProfile';

import { ProfileModal } from '../modals';

// Styled
import { Loading, TitleCounterDiv } from '../../../common/components/styled';
import { ProfileMenu } from '../styled/ProfilePage';
import { ProfileTabIcon } from '../../../common/components/styled/LinkIcon';
import {
  ProfilePageContainer,
  StyledNavLink,
  StyledProfileNav,
} from '../styled/ProfileTabItem';
import { H1 } from '../../../common/components/styled/ReusableTextComponents';

// Constants and utils
import { uploadProfilePicture } from '../../ducks';
import { loadMrl } from '../../../mrl/ducks';
import {
  subscribeUserToMailchimp,
  unsubscribeUserFromMailchimp,
} from '../../ducks/userProfile';
import { PROFILE_PAGE_TABS } from '../../constants';
import { useApi } from '../../../hooks/useApi';
import { getLoggedUserNextPayment } from '../../../requests/payments';

const ProfilePage = (props) => {
  const {
    userProfile,
    isLoading,
    userTeams,
    subscribeUserToMailchimp,
    unsubscribeUserFromMailchimp,
  } = props;

  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'profile';
  const history = useHistory();

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const [{ result: nextPaymentData }, runGetNextPayment] = useApi(
    getLoggedUserNextPayment,
    null
  );

  useEffect(() => {
    runGetNextPayment();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/profile?tab=${tab}`);
  };

  const pageComponents = {
    profile: {
      title: 'Profilul meu',
      component: (
        <UserProfileTab userProfile={userProfile} userTeams={userTeams} />
      ),
    },
    settings: {
      title: 'Setari',
      component: (
        <Settings
          userProfile={userProfile}
          subscribeUserToMailchimp={subscribeUserToMailchimp}
          unsubscribeUserFromMailchimp={unsubscribeUserFromMailchimp}
        />
      ),
    },
    subscriptions: {
      title: 'Abonament',
      component: <SubscriptionProfile nextPaymentData={nextPaymentData} />,
    },
    payments: {
      title: 'Plati',
      component: <Payments nextPaymentData={nextPaymentData} />,
    },
    conditions: {
      title: 'Conditii',
      component: <Conditions />,
    },
  };

  return (
    <>
      <TitleCounterDiv smallMargin>
        <H1>{pageComponents[activeTab]?.title}</H1>
      </TitleCounterDiv>

      <ProfileMenu>
        <StyledProfileNav variant="tabs" activeKey={activeTab}>
          {PROFILE_PAGE_TABS.map((tab, index) => (
            <Nav.Item key={tab.key} style={{ position: 'relative' }}>
              <StyledNavLink
                data-isactive={activeTab === tab.key}
                data-islast={index === PROFILE_PAGE_TABS.length - 1}
                data-isfirst={index === 0}
                eventKey={tab.key}
                onClick={() => handleTabChange(tab.key)}
              >
                <ProfileTabIcon icon={tab.icon} />
                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
              </StyledNavLink>
            </Nav.Item>
          ))}
        </StyledProfileNav>
      </ProfileMenu>
      <ProfilePageContainer fullBorder={activeTab !== 'profile'}>
        {pageComponents[activeTab]?.component}
      </ProfilePageContainer>
      <ProfileModal
        isModalOpen={isProfileModalOpen}
        setIsModalOpen={setIsProfileModalOpen}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesesPercentage: state.userProfile.hypothesesPercentage,
  experimentsPercentage: state.userProfile.experimentsPercentage,
  userProfile: state.userProfile.userProfile,
  isLoading: state.userProfile.isLoading,
  role: state.account.login.user.role,
  userTeams: state.common.userTeams.userTeams,
  mrlLoading: state.mrl.isLoading,
  mrlData: state.mrl.mrls,
  isEditing: state.mrl.isEditing,
});

const mapDispatchToProps = {
  uploadProfilePicture,
  loadMrl,
  subscribeUserToMailchimp,
  unsubscribeUserFromMailchimp,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
);
