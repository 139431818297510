import {
  BOOLEAN_ANSWER_TYPE,
  MULTIPLE_ANSWER_TYPE,
  SINGLE_ANSWER_TYPE,
} from '../constants/questions';

export const generateQuestionData = ({
  currentDisplayedQuestion,
  currentSelectedAnswers,
  step,
}) => {
  let predefinedResponse;
  if (currentDisplayedQuestion?.answerWithQuestion) {
    predefinedResponse = currentDisplayedQuestion?.question;
  }

  return {
    questionId: currentDisplayedQuestion.questionCode,
    questionTitle: currentDisplayedQuestion.question,
    step: step + 1,
    questionType: currentDisplayedQuestion.questionAnswerType,
    ...(currentDisplayedQuestion.questionAnswerType === SINGLE_ANSWER_TYPE && {
      singleAnswer: predefinedResponse || currentSelectedAnswers[0],
    }),
    ...(currentDisplayedQuestion.questionAnswerType ===
      MULTIPLE_ANSWER_TYPE && {
      multipleAnswers: currentSelectedAnswers,
    }),
    ...(currentDisplayedQuestion.questionAnswerType === BOOLEAN_ANSWER_TYPE && {
      booleanAnswer: currentSelectedAnswers[0],
    }),
  };
};
