import React, { useContext, useEffect } from 'react';
import {
  OnboardingDashboardContainer,
  OnboardingLoaderContainer,
  OnboardingOrderContainer,
  OnboardingProgress,
  OnboardingSegmentContainer,
} from '../styled/onboardingDashboardBricks';
import {
  H3,
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { Box } from '@mui/material';
import TourContext from '../../context/tourContext';
import {
  BMC_ONBOARDING_STEPS,
  CONTEXT_ANALYSIS_ONBOARDING_STEPS,
  MRL_ONBOARDING_STEPS,
  ONBOARDING_PATH_NAMES,
  PLATFORM_FUNDAMENTALS_ONBOARDING_STEPS,
} from '../constants';
import { useApi } from '../../hooks/useApi';
import { resetOnboardingProgress } from '../../requests/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { loadProfile } from '../../userProfile/ducks';

const OnboardingDashboard = () => {
  const { startTour } = useContext(TourContext);
  const [, runResetProgress] = useApi(resetOnboardingProgress);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const onboardingProgress = useSelector(
    (state) => state?.userProfile?.userProfile?.onboardings
  );

  const handleStartTour = (tourName) => {
    const currStep = onboardingProgress.find(
      (entry) => entry?.code === tourName
    )?.step;

    startTour(tourName, currStep);
  };

  const getPercentage = (teamName, totalSteps) => {
    const savedEntry = onboardingProgress.find(
      (entry) => entry?.code === teamName
    );

    if (savedEntry) {
      return Math.floor((savedEntry?.step * 100) / totalSteps);
    }

    return 0;
  };

  const handleResetProgress = async () => {
    await runResetProgress();
    dispatch(loadProfile());
  };

  return (
    <OnboardingDashboardContainer>
      <H3 marginBottom="10px">Ghid LaunchPad</H3>

      <OnboardingSegmentContainer
        onClick={() =>
          handleStartTour(ONBOARDING_PATH_NAMES.platformFundamentals)
        }
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', background: 'white' }}
        >
          <OnboardingOrderContainer>1</OnboardingOrderContainer>
          <P>Funcționalitățile de bază</P>
        </Box>

        <OnboardingLoaderContainer>
          <OnboardingProgress
            progress={getPercentage(
              ONBOARDING_PATH_NAMES.platformFundamentals,
              PLATFORM_FUNDAMENTALS_ONBOARDING_STEPS.length
            )}
          />
        </OnboardingLoaderContainer>
      </OnboardingSegmentContainer>
      <OnboardingSegmentContainer
        onClick={() => handleStartTour(ONBOARDING_PATH_NAMES.contextAnalysis)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <OnboardingOrderContainer>2</OnboardingOrderContainer>
          <P>Analiza contextului</P>
        </Box>
        <OnboardingLoaderContainer>
          <OnboardingProgress
            progress={getPercentage(
              ONBOARDING_PATH_NAMES.contextAnalysis,
              CONTEXT_ANALYSIS_ONBOARDING_STEPS.length
            )}
          />
        </OnboardingLoaderContainer>
      </OnboardingSegmentContainer>
      <OnboardingSegmentContainer
        onClick={() => handleStartTour(ONBOARDING_PATH_NAMES.mrl)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <OnboardingOrderContainer>3</OnboardingOrderContainer>
          <P>MRL</P>
        </Box>
        <OnboardingLoaderContainer>
          <OnboardingProgress
            progress={getPercentage(
              ONBOARDING_PATH_NAMES.mrl,
              MRL_ONBOARDING_STEPS.length
            )}
          />
        </OnboardingLoaderContainer>
      </OnboardingSegmentContainer>
      <OnboardingSegmentContainer
        onClick={() => handleStartTour(ONBOARDING_PATH_NAMES.bmc)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <OnboardingOrderContainer>4</OnboardingOrderContainer>
          <P>Business Model Canvas</P>
        </Box>
        <OnboardingLoaderContainer>
          <OnboardingProgress
            progress={getPercentage(
              ONBOARDING_PATH_NAMES.bmc,
              BMC_ONBOARDING_STEPS.length
            )}
          />
        </OnboardingLoaderContainer>
      </OnboardingSegmentContainer>

      <PrimaryButton
        onClick={handleResetProgress}
        inverted
        style={{ width: '100%' }}
      >
        Reseteaza
      </PrimaryButton>
    </OnboardingDashboardContainer>
  );
};

export default OnboardingDashboard;
