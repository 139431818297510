import { useField } from 'formik';
import { Grid, TextField } from '@mui/material';
import React from 'react';

const CustomTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
  value,
  placeholder,
  disabled = false,
  type,
  autoFocus = false,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
        defaultValue={value}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...(type && { type })}
      />
    </Grid>
  );
};

export default CustomTextField;
