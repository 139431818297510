import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InvitationContainer = styled.div`
  width: 49%;
  border-radius: 10px;
  background: ${(props) => props.theme.white};
  padding: 20px;
  margin-right: auto;
  margin-bottom: 30px;
`;

export const MentorCard = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 10px;
  padding: 20px;
  padding-top: 11px;
  margin-bottom: 30px;
  width: calc(25% - 20px);
  margin-right: 20px;
  height: 435px;

  &:nth-child(4n + 4) {
    width: 25%;
    margin-right: 0;
  }
`;
export const AddIconText = styled.img`
  height: 20px;
  width: 20px;
  font-size: 20px;
`;
export const DivBorderBottom = styled.div`
  border-bottom: 1px solid #cccccc;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '20px'};
  margin-bottom: ${(props) =>
    props.paddingBottom ? props.marginBottom : '20px'};
`;

export const TitleCategoriesDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '15px')};
  height: 30px;
`;
export const LabelFieldDiv = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '10px')};
  align-items: center;

  ${(props) =>
    props.flexStart &&
    css`
      align-items: flex-start;
    `}
`;
export const MentorMarginDiv = styled.div`
  display: flex;
  min-height: 20px;
  text-align: justify;
  margin-bottom: ${(props) => (props.small ? '10px' : '20px')};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${(props) =>
    props.fixedHeight &&
    css`
      height: 170px;
      overflow-y: auto;
    `}
`;

export const IdeeaDescription = styled.div`
  line-height: 19px;
  text-align: justify;
  height: ${(props) => (props.height ? props.height : '76px')};
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 4px;
`;
export const AcceptButton = styled.div`
  margin-left: ${(props) => props.marginLeft};
  height: 32px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100px')};
  border-radius: 4px;
  padding: ${(props) => (props.small ? '5px' : '15px')};
  background: ${(props) => props.theme.blueAccent};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;

  ${(props) =>
    props.myTeamsButton &&
    css`
      padding: 8px;
    `}

  ${(props) =>
    props.invitationButton &&
    css`
      padding: 5px 5px 5px 2px;
    `}
  > svg > path {
    fill: ${(props) => props.theme.white};
  }
  > svg > g > path {
    fill: ${(props) => props.theme.white};
  }

  :hover {
    background: ${(props) => props.theme.accentHover};
  }

  ${(props) =>
    props.dashed &&
    css`
      background: ${(props) => props.theme.white};
      border: 1px dashed #8e8eb5;
      border-radius: 3px;

      :hover {
        background: ${(props) => props.theme.white};
      }
    `}

  ${(props) =>
    props.backgroundNone &&
    css`
      background: none;
      border: 1px solid ${(props) => props.theme.blueAccent};
      :hover {
        background: none;
        border: 1px solid ${(props) => props.theme.accentHover};
      }
      :hover > span {
        color: ${(props) => props.theme.accentHover};
      }
      > svg > path {
        fill: ${(props) => props.theme.blueAccent};
      }

      :hover > svg > path {
        fill: ${(props) => props.theme.accentHover};
      }
    `}
`;
export const ImageFieldsDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
export const FieldsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const BMCHistorySpan = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 10px;
  color: ${(props) => props.theme.blueAccent};
  height: 3vh;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  width: 23vw;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #74a3fe;
  border-radius: 5px;
  color: #74a3fe;
  height: 3vh;
`;

export const InputWeek = styled.input`
  color: ${(props) => props.theme.blueAccent};
  border: none;
  width: 2vw;
  padding: 0;
  text-align: center;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TitleButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.alignItems &&
    css`
      align-items: flex-end;
    `}

  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.borderGray};
      padding-bottom: 5px;
    `}

  ${(props) =>
    props.paddingRight &&
    css`
      padding-right: 40px;
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
    `}
`;

export const ButtonText = styled.span`
  font-style: normal;
  font-weight: ${(props) => (props.isInvited ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color};
  text-align: center;
  margin-left: 5px;

  ${(props) =>
    props.noMargin &&
    css`
      margin-left: 0;
    `}

  ${(props) =>
    props.backgroundNone &&
    css`
      color: ${(props) => props.theme.blueAccent};
    `}

    ${(props) =>
    props.white &&
    css`
      color: ${(props) => props.theme.white};
    `}


      ${(props) =>
    props.ellipsis &&
    css`
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    `}
`;
export const ImageIconRoundDiv = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
export const ImageUserNameDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const DenyButton = styled.div`
  height: 32px;
  width: 76px;
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  cursor: pointer;
  padding: 5px;
  border: 1px solid ${(props) => props.theme.danger};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${(props) => props.theme.dangerHover};
    > span {
      color: ${(props) => props.theme.dangerHover};
    }
  }
`;

export const IndustryText = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  width: 100%;
`;
export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const UserIcon = styled(FontAwesomeIcon)`
  font-size: 50px;
  margin-bottom: 15px;
`;

export const ButtonsDiv = styled.div`
  width: ${(props) => props.widthCustom};
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}
`;
export const Container = styled.div`
  padding: 20px;
`;
