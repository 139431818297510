// eslint-disable-next-line import/no-mutable-exports
let baseURL = '';

switch (process.env.REACT_APP_ENV) {
  case 'local':
    baseURL = process.env.REACT_APP_API_LOCAL;
    break;
  case 'staging':
    baseURL = process.env.REACT_APP_API_DEVELOP;
    break;
  case 'production':
    baseURL = process.env.REACT_APP_API_PRODUCTION;
    break;
  default:
    baseURL = process.env.REACT_APP_API_LOCAL;
}

export { baseURL };
