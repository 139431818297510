import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import { FormControl, TextField, Box, Switch, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ModalComponent from '../../common/components/ModalComponent';
import {
  H3,
  P,
  PrimaryButton,
} from '../../common/components/styled/ReusableTextComponents';
import { SaveIcon } from '../../common/components/styled';
import { editProfile, loadProfile } from '../ducks';
import { INDIVIDUAL_CUSTOMER_TYPE, LEGAL_CUSTOMER_TYPE } from '../constants';

const RenderTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Grid>
  );
};

const BillingDetailsModal = ({ isModalOpen, setIsModalOpen, editProfile }) => {
  const [isJuridic, setIsJuridic] = useState(true);

  const handleSubmit = (values) => {
    const formData = isJuridic
      ? { legalPerson: { ...values } }
      : { individual: { ...values } };
    const payload = {
      invoiceDetails: {
        customerType: isJuridic
          ? LEGAL_CUSTOMER_TYPE
          : INDIVIDUAL_CUSTOMER_TYPE,
        ...formData,
      },
    };
    editProfile(payload);
    setIsModalOpen(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    cnp: '',
    mobilePhone: '',
    companyName: '',
    registerNumber: '',
    cui: '',
    bankName: '',
    bankNumber: '',
  };

  const schema = Yup.object().shape({
    lastName: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleToggle = () => {
    setIsJuridic(!isJuridic);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title="Adauga detalii facturare"
    >
      <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <P faded={isJuridic}>Persoana fizica</P>
        <Switch checked={isJuridic} onChange={handleToggle} />
        <P faded={!isJuridic}>Persoana juridica</P>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormControl style={{ width: '100%' }}>
              <Grid container spacing={2}>
                {isJuridic ? (
                  <>
                    <RenderTextField id="companyName" label="Denumire" />
                    <RenderTextField
                      id="registerNumber"
                      label="Nr. Reg. Comert"
                    />
                    <RenderTextField
                      id="address"
                      label="Adresa sediu"
                      multiline
                      rows={3}
                    />
                    <RenderTextField id="cui" label="CUI" />
                    <RenderTextField id="bankName" label="Banca" />
                    <RenderTextField id="bankNumber" label="IBAN" />

                    <Box ml={2}>
                      <H3>Reprezentant legal</H3>
                    </Box>
                    <RenderTextField id="lastName" label="Nume" />
                    <RenderTextField id="email" label="Email" />
                    <RenderTextField id="firstName" label="Prenume" />
                    <RenderTextField id="mobilePhone" label="Numar mobil" />
                  </>
                ) : (
                  <>
                    <RenderTextField id="lastName" label="Nume" />
                    <RenderTextField id="firstName" label="Prenume" />
                    <RenderTextField
                      id="address"
                      label="Adresa completa"
                      multiline
                      rows={3}
                    />
                    <RenderTextField id="cnp" label="CNP" />
                    <RenderTextField id="mobilePhone" label="Numar mobil" />
                    <RenderTextField id="email" label="Email" />
                  </>
                )}
              </Grid>
            </FormControl>
            <PrimaryButton icon={SaveIcon} type="submit">
              Salveaza
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </ModalComponent>
  );
};

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(connect('', mapDispatchToProps)(BillingDetailsModal));
