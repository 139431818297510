import React, { useState } from 'react';
import { Box } from '@mui/material';
import MemberContainer from './MemberContainer';
import { PrimaryButton } from '../../common/components/styled/ReusableTextComponents';
import { PeopleIcon } from '../../common/assets/icons';
import {
  AddUserToIdeaModal,
  JoinCohortModal,
  ManageTeamModal,
} from './MyIdeaModals';

const MembersBreakdownSection = ({
  members = [],
  mentors = [],
  cohorts = [],
  teamId = null,
  runUpdateTeamMembers = () => {},
  runUpdateTeamMentors = () => {},
}) => {
  const [modalsState, setModalsState] = useState({
    isAddMemberModalOpen: false,
    isAddMentorModalOpen: false,
    isJoinCohortModalOpen: false,
    isManageTeamModalOpen: false,
  });

  const openModal = (modalName) => {
    setModalsState((prev) => ({ ...prev, [modalName]: true }));
  };
  const closeAllModals = () => {
    setModalsState({
      isAddMemberModalOpen: false,
      isAddMentorModalOpen: false,
      isJoinCohortModalOpen: false,
      isManageTeamModalOpen: false,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <MemberContainer
        title="Membrii"
        placeholder="Fara membrii."
        userList={members}
        openModal={() => openModal('isAddMemberModalOpen')}
      >
        {/* <PrimaryButton */}
        {/*   icon={PeopleIcon} */}
        {/*   marginRight="10px" */}
        {/*   padding="3px 8px" */}
        {/*   inverted */}
        {/*   onClick={() => openModal('isManageTeamModalOpen')} */}
        {/* > */}
        {/*   Administreaza echipa */}
        {/* </PrimaryButton> */}
      </MemberContainer>
      <MemberContainer
        title="Mentori și experți"
        placeholder="Fara mentori."
        userList={mentors}
        openModal={() => openModal('isAddMentorModalOpen')}
      />
      <MemberContainer
        title="Program / Cohortă"
        placeholder="Fara cohorta."
        userList={cohorts}
        openModal={() => openModal('isJoinCohortModalOpen')}
      />
      <AddUserToIdeaModal
        isMentor={modalsState.isAddMentorModalOpen}
        isOpen={
          modalsState.isAddMemberModalOpen || modalsState.isAddMentorModalOpen
        }
        runUpdateTeamMembers={runUpdateTeamMembers}
        runUpdateTeamMentors={runUpdateTeamMentors}
        handleClose={closeAllModals}
        teamId={teamId}
      />
      <JoinCohortModal
        isOpen={modalsState.isJoinCohortModalOpen}
        handleClose={closeAllModals}
        teamId={teamId}
      />
      <ManageTeamModal
        isOpen={modalsState.isManageTeamModalOpen}
        handleClose={closeAllModals}
        teamId={teamId}
        members={members}
      />
    </Box>
  );
};

export default MembersBreakdownSection;
