import React from 'react';
import { Box } from '@mui/material';

const GenericFormWrapper = ({ children, maxHeight }) => (
  <Box
    className="scrollbar"
    sx={{
      maxHeight: maxHeight || '600px',
      overflowY: 'scroll',
      padding: '5px 10px',
    }}
  >
    {children}
  </Box>
);

export default GenericFormWrapper;
