import React from 'react';
import { useField } from 'formik';
import { Grid, TextField, Box } from '@mui/material';
import { PrimaryButton } from '../../styled/ReusableTextComponents';

const CustomTextFieldWithButton = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
  value,
  buttonText,
}) => {
  const [field, meta] = useField(id);

  return (
    <Grid
      item
      xs={12}
      md={md}
      sx={{ marginBottom: '15px', position: 'relative' }}
    >
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
        defaultValue={value}
        classes={{ root: 'input-with-button' }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '5px',
          right: '5px',
          display: 'Flex',
        }}
      >
        <PrimaryButton type="submit"> {buttonText} </PrimaryButton>
      </Box>
    </Grid>
  );
};

export default CustomTextFieldWithButton;
