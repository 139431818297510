import { runDeleteApiRequest, runGetApiRequest } from '../api/Api';
import {
  DELETE_DETAIL_BY_ID,
  GET_ALL_DETAILS,
} from '../common/constants/apiRoutes';

export function getAllDetails({ payload = {} }) {
  const defaultQueryParams = {
    filters: {
      ...(payload.filters || {}),
    },
  };

  return runGetApiRequest({
    endpoint: GET_ALL_DETAILS,
    params: payload.params,
    queryParams: { ...defaultQueryParams, ...payload.queryParams },
    onFailMessage: 'Something went wrong while fetching details',
    // serializer: getChaptersSerializer,
  });
}

export function deleteDetailById({ payload = {} }) {
  return runDeleteApiRequest({
    endpoint: DELETE_DETAIL_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while deleting detail',
  });
}
