import Cookie from 'js-cookie';
import { map, get, size } from 'lodash-es';
import { http } from '../../core/services/http';

export const loadCohorts = (userId) =>
  http.get('/cohort/get-all').then(({ data }) => {
    let cohorts;
    const selectedCohort = get(
      Cookie.getJSON(`selectedCohort-${userId}`),
      'cohortId'
    );
    if (!selectedCohort) {
      if (size(data)) {
        Cookie.set(`selectedCohort-${userId}`, { cohortId: data[0]._id });
        cohorts = map(data, (cohort, index) =>
          index === 0 ? { ...cohort, selected: true } : cohort
        );
      }
      return cohorts;
    }
    cohorts = map(data, (cohort) =>
      cohort._id === selectedCohort ? { ...cohort, selected: true } : cohort
    );
    return cohorts;
  });

export const isCohortExpired = (cohortCode) =>
  http.get(`/cohort/is-cohort-expired?cohortCode=${cohortCode}`);

export const addCohort = (values) => http.post('/cohort/add', values);

export const getCohortById = (cohortId) =>
  http.get(`/cohort/get-by-id/${cohortId}`);

export const editCohort = (cohortId, values) =>
  http.put(`/cohort/edit/${cohortId}`, values);

export const deleteCohort = (cohortId) =>
  http.delete(`/cohort/delete/${cohortId}`);

export const setCurrentUserCohort = (userId, cohortId) => {
  Cookie.set(`selectedCohort-${userId}`, { cohortId });
};
