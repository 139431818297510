import { map } from 'lodash-es';
import { http } from '../../core/services/http';
import {
  computeLeaderboardPosition,
  computeGeneralLeaderboard,
} from './computeLeaderboardPosition';

export const loadTeams = (cohortId, leaderboardData = false) =>
  http.get(`/team/get-all/${cohortId}`).then(async ({ data }) => {
    const computedData = map(data, (team) => ({ ...team, irl: team.irl || 0 }));

    if (leaderboardData) {
      if (cohortId !== null) {
        const allTeams = await http.get('/team/get-all');
        const generalLeaderboard = computeGeneralLeaderboard(
          map(allTeams.data, (team) => ({ ...team, irl: team.irl || 0 }))
        );
        return computeLeaderboardPosition(computedData, generalLeaderboard);
      }
      return computeGeneralLeaderboard(computedData);
    }
    return data;
  });
