import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { get, find, size } from 'lodash-es';
import { Formik, useField } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faBusinessTime,
  faCloud,
  faGift,
  faGifts,
  faPeopleGroup,
  faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import { CustomLabel, Dropdown } from '../../../core/components';
import { dataOptions, actionOptions } from '../../constants';

import {
  Input,
  SubmitButton,
  ButtonDiv,
  SaveIcon,
  ExperimentGoodButton,
  ExperimentBadButton,
} from '../../../common/components/styled';
import {
  ButtonContainer,
  InputContainer,
} from './styled/ResolutiomModalStyled';
import { MarginContainer } from '../../../mrl/components/styled/MrlStyled';
import { theme } from '../../../core/styles';

const RenderConclusionTitle = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="12">
      <Input
        height="100px"
        as={type}
        id={controlId}
        name={name}
        paddingTop="30px"
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderConclusionsField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="12">
      <Input
        height="100px"
        as={type}
        id={controlId}
        name={name}
        paddingTop="30px"
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderObservationsField = (props) => {
  const { values, handleChange, name, placeholder, controlId, label, type } =
    props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} md="12">
      <Input
        height="93px"
        paddingTop="30px"
        as={type}
        id={controlId}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <CustomLabel
        label={label}
        tooltipDescription={label}
        labelFor={controlId}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderActionRequiredField = (props) => {
  const { values, setFieldValue, controlId, name, placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  return (
    <Form.Group as={Col} md="12" controlId={controlId}>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        height="60px"
        id={controlId}
        placeholder={placeholder}
        tooltipDescription="Segment"
        name={name}
        options={actionOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('resolution.actionRequired', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
const RenderDataReliabilityField = (props) => {
  const { values, setFieldValue, controlId, name, placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);

  return (
    <Form.Group as={Col} md="12">
      <Input
        as={Dropdown}
        placeholder={placeholder}
        name={name}
        id={controlId}
        options={dataOptions}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue('resolution.dataReliability', v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({
  handleSubmit,
  handleChange,
  values,
  setFieldValue,
  errors,
  showPlusButton,
  setShowPlusButton,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderConclusionTitle
        name="resolution.title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul concluzie"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderConclusionsField
        name="resolution.conclusion"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Sumar concluzie*"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderObservationsField
        name="resolution.observations"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik02"
        label="Observații*"
        type="textarea"
      />
    </Form.Row>
    <Form.Row>
      <RenderDataReliabilityField
        values={values}
        setFieldValue={setFieldValue}
        controlId="validationFormik03"
        name="resolution.dataReliability"
        label="Considerăm datele obținute ca fiind:"
        placeholder={
          <CustomLabel
            label="Alege datele"
            tooltipDescription="Alege datele"
            marginLeft="0"
          />
        }
      />
    </Form.Row>
    <Form.Row>
      <RenderActionRequiredField
        values={values}
        setFieldValue={setFieldValue}
        placeholder={
          <CustomLabel
            label="Alege actiunea"
            tooltipDescription="Alege actiunea"
            marginLeft="0"
          />
        }
        controlId="validationFormik04"
        name="resolution.actionRequired"
        label="În baza concluziei estimăm o acțiune:"
      />
    </Form.Row>

    <InputContainer>
      <div>
        <CustomLabel
          label="Ce elemente iti confirma experimentul"
          tooltipDescription="Ce elemente iti confirma experimentul"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Profil</span> <FontAwesomeIcon icon={faPeopleGroup} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.profileOutcome === 'like' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.profileOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={values.resolution.profileOutcome === 'like' && 'white'}
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.profileOutcome === 'dislike' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.profileOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.outcome === 'dislike'}
              />
              <MarginContainer
                color={
                  values.resolution.profileOutcome === 'dislike' && 'white'
                }
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Problema</span>
            <FontAwesomeIcon icon={faPieChart} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.problemOutcome === 'like' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.problemOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={values.resolution.problemOutcome === 'like' && 'white'}
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.problemOutcome === 'dislike' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.problemOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.outcome === 'dislike'}
              />
              <MarginContainer
                color={
                  values.resolution.problemOutcome === 'dislike' && 'white'
                }
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Propozitie de valoare</span>
            <FontAwesomeIcon icon={faCloud} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.propositionOutcome === 'like' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.propositionOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={
                  values.resolution.propositionOutcome === 'like' && 'white'
                }
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.propositionOutcome === 'dislike' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.propositionOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.outcome === 'dislike'}
              />
              <MarginContainer
                color={
                  values.resolution.propositionOutcome === 'dislike' && 'white'
                }
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>MVP</span>
            <FontAwesomeIcon icon={faGift} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.mvpOutcome === 'like' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.mvpOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={values.resolution.mvpOutcome === 'like' && 'white'}
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.mvpOutcome === 'dislike' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.mvpOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.mvpOutcome === 'dislike'}
              />
              <MarginContainer
                color={values.resolution.mvpOutcome === 'dislike' && 'white'}
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Achiziția clienților</span>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.acquisitionOutcome === 'like' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.acquisitionOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={
                  values.resolution.acquisitionOutcome === 'like' && 'white'
                }
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.acquisitionOutcome === 'dislike' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.acquisitionOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.acquisitionOutcome === 'dislike'}
              />
              <MarginContainer
                color={
                  values.resolution.acquisitionOutcome === 'dislike' && 'white'
                }
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Modelul de pret</span>
            <FontAwesomeIcon icon={faGifts} />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.priceOutcome === 'like' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.priceOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={values.resolution.priceOutcome === 'like' && 'white'}
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.priceOutcome === 'dislike' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.priceOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.priceOutcome === 'dislike'}
              />
              <MarginContainer
                color={values.resolution.priceOutcome === 'dislike' && 'white'}
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '1vh', fontSize: '16px' }}>
            <span>Modelul de business</span>
            <FontAwesomeIcon
              icon={faBusinessTime}
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          </div>
          <MarginContainer display="flex">
            <ButtonContainer
              background={
                values.resolution.businessOutcome === 'like' && theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.businessOutcome', 'like');
              }}
            >
              <ExperimentGoodButton
                pressed={values.resolution.outcome === 'like'}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
              <MarginContainer
                color={values.resolution.businessOutcome === 'like' && 'white'}
              >
                Confirmat
              </MarginContainer>
            </ButtonContainer>
            <ButtonContainer
              background={
                values.resolution.businessOutcome === 'dislike' &&
                theme.blueAccent
              }
              onClick={() => {
                setFieldValue('resolution.businessOutcome', 'dislike');
              }}
            >
              <ExperimentBadButton
                style={{ marginLeft: '10px' }}
                pressed={values.resolution.outcome === 'dislike'}
              />
              <MarginContainer
                color={
                  values.resolution.businessOutcome === 'dislike' && 'white'
                }
              >
                Infirmat
              </MarginContainer>
            </ButtonContainer>
          </MarginContainer>
        </div>
      </div>
    </InputContainer>

    <ButtonDiv justifyLeft>
      <SubmitButton type="submit" style={{ padding: '20px' }}>
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddResolutionFormExperiment = (props) => {
  const { onSubmit, resolution } = props;

  const [showPlusButton, setShowPlusButton] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    resolution: Yup.object({
      title: Yup.string().required('Câmp obligatoriu'),
      conclusion: Yup.string().required('Câmp obligatoriu'),
      observations: Yup.string().required('Câmp obligatoriu'),
      nextSteps: Yup.array().of(
        Yup.string().url(
          'URL Invalid (linkul trebuie sa contina http:// sau https://)'
        )
      ),
      dataReliability: Yup.string(),
      actionRequired: Yup.string(),
      outcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      profileOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      problemOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      propositionOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      mvpOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      acquisitionOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      priceOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
      businessOutcome: Yup.string().test({
        message: 'Câmp obligatoriu',
        test: (value) => value !== 'inProgress',
      }),
    }),
  });

  const defaultValues = {
    resolution: {
      title: '',
      conclusion: '',
      observations: '',
      nextSteps:
        resolution && resolution.nextSteps && size(resolution.nextSteps) !== 0
          ? resolution.nextSteps
          : [''],
      dataReliability: '',
      actionRequired: '',
    },
  };
  const defaultResolution = {
    resolution: {
      ...resolution,
      nextSteps:
        resolution && resolution.nextSteps && size(resolution.nextSteps) !== 0
          ? resolution.nextSteps
          : [''],
      dataReliability:
        resolution &&
        find(dataOptions, {
          value: resolution.dataReliability,
        }),
      actionRequired:
        resolution &&
        find(actionOptions, {
          value: resolution.actionRequired,
        }),
    },
  };

  const initialValues = resolution ? defaultResolution : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          showPlusButton={showPlusButton}
          setShowPlusButton={setShowPlusButton}
        />
      )}
    </Formik>
  );
};

export default AddResolutionFormExperiment;
