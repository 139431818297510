import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadTeams } from '../../ducks';
import { Resolver } from '../../../core/components';
import { Loading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { currentTeamCohortIdSelector } from '../../../common/ducks';
import LeaderboardPage from './LeaderboardPage';

const LeaderboardPageResolver = (props) => {
  const { cohortId } = props;
  const loadDependencies = () =>
    cohortId !== undefined
      ? props.loadTeams(cohortId, true)
      : Promise.resolve();

  return (
    <>
      <DocumentTitle>Echipe</DocumentTitle>
      <Resolver
        successComponent={LeaderboardPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

LeaderboardPageResolver.propTypes = {
  loadTeams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cohortId: currentTeamCohortIdSelector(state.common.userTeams),
});
const mapDispatchToProps = { loadTeams };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeaderboardPageResolver)
);
