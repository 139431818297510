import { Box, Grid } from '@mui/material';
import React from 'react';
import { H2 } from '../../common/components/styled/ReusableTextComponents';
import { AddButtonComponent } from '../../opportunityAnalysis/components/Buttons';
import CustomSelectField from '../../common/components/forms/formComponents/CustomSelectField';
import { Formik } from 'formik';

const formStyles = {
  '& > div': {
    marginBottom: 0,
  },
};

const headerStyles = {
  borderBottom: '1px solid #74A3FE',
  paddingBottom: '10px',
  gap: '10px',
  alignItems: 'flex-end',
  marginTop: '20px',
  width: '70%',
};

const customSelectStyles = {
  autocompleteStyles: { width: '200px' },
  textFieldStyles: {
    '& .MuiInputBase-root': { height: '32px', display: 'flex' },
    '& .MuiAutocomplete-input': {
      padding: '0 !important',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
};

const coursesFiltersOptions = [
  { value: 'all', label: 'Toate' },
  { value: 'hidden', label: 'Ascunse' },
  { value: 'visible', label: 'Vizibile' },
];

const CoursesPageHeaderSection = ({
  formValues,
  handleSubmitFilters,
  headerTitle,
  handleFiltersModalStatus,
  filtersNumber,
  toggleValue,
  handleToggleValue,
  onAddClick,
  fetchChapters,
}) => {
  const initialValues = formValues || { coursesFilters: 'all' };

  return (
    <Grid container justifyContent="space-between" sx={headerStyles}>
      <H2>{headerTitle}</H2>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
        <Formik initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Box sx={formStyles}>
                <CustomSelectField
                  customStyles={customSelectStyles}
                  id="coursesFilters"
                  options={coursesFiltersOptions}
                  onSelect={handleSubmitFilters}
                />
              </Box>
            </form>
          )}
        </Formik>
        <AddButtonComponent onClick={onAddClick} />
      </Box>
    </Grid>
  );
};

export default CoursesPageHeaderSection;
