import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { checkEmailRegistered } from '../../ducks';
import {
  P,
  PrimaryButton,
} from '../../../common/components/styled/ReusableTextComponents';
import { Grid } from '@mui/material';
import CustomTextField from '../../../common/components/forms/formComponents/CustomTextField';
import CustomSelectField from '../../../common/components/forms/formComponents/CustomSelectField';
import CustomFormCheckbox from '../../../common/components/forms/formComponents/CustomFormCheckbox';
import { useApi } from '../../../hooks/useApi';
import { postRegisterInvitedMember } from '../../../requests/registration';
import { useHistory } from 'react-router';
import { notifyError, notifySuccess } from '../../../core/ducks';

const roleOptions = [
  {
    value: 'FOUNDER',
    label: 'Fondator sau Partener',
  },
  {
    value: 'MENTOR',
    label: 'Mentor',
  },

  {
    value: 'ACCELERATOR',
    label: 'Accelerator',
  },
];

const RegisterForm = ({
  onSubmit,
  checkEmailRegistered,
  invitedUserData = null,
  memberToken,
}) => {
  const [, runPostSignUpInvitedMember] = useApi(postRegisterInvitedMember);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNavigateToLogin = () => history.push('/account/login');

  const handleSubmit = async (values, { resetForm }) => {
    if (invitedUserData) {
      await runPostSignUpInvitedMember({
        userData: {
          ...values,
          memberToken,
        },
      });

      dispatch(
        notifySuccess({
          message: 'Contul a fost creeat cu succes',
        })
      );

      return history.push('/account/login');
    }

    const isEmailUsedAlready = await checkEmailRegistered(values?.email);

    if (isEmailUsedAlready) {
      dispatch(
        notifyError({
          message: 'Adresa de email este deja asociata cu un cont',
        })
      );

      return resetForm();
    }

    onSubmit(values);
  };

  const schema = Yup.object({
    email: Yup.string()
      .required('Câmp obligatoriu')
      .email('Adresa de email invalida'),
    password: Yup.string().required('Câmp obligatoriu'),
    confirmPassword: Yup.string()
      .required('Câmp obligatoriu')
      .oneOf([Yup.ref('password'), null], 'Parolele nu coincid'),
    firstName: Yup.string().required('Câmp obligatoriu'),
    lastName: Yup.string().required('Câmp obligatoriu'),
    role: Yup.string().required('Câmp obligatoriu'),
    gdpr: Yup.bool().oneOf([true], 'Câmp obligatoriu'),
    // recaptcha: Yup.string().required('Câmp obligatoriu'),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: invitedUserData?.email || '',
    password: '',
    confirmPassword: '',
    role: invitedUserData?.userRole || '',
    howFindUs: '',
    gdpr: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <CustomTextField id="firstName" label="Nume" md={6} />
            <CustomTextField id="lastName" label="Prenume" md={6} />
            <CustomTextField
              type="password"
              id="password"
              label="Password"
              md={6}
            />
            <CustomTextField
              type="password"
              id="confirmPassword"
              label="Confirm password"
              md={6}
            />
            <CustomTextField
              disabled={invitedUserData || false}
              id="email"
              label="Email"
              md={12}
            />
            <CustomSelectField
              disabled={invitedUserData || false}
              id="role"
              label="Alege tipul de cont pe care doresti sa-l activezi"
              options={roleOptions}
            />
            <CustomTextField
              id="howFindUs"
              label="Cum ai aflat de noi?"
              placeholder="Google search, Facebook, Presa scrisa"
              md={12}
            />

            <CustomFormCheckbox
              name="gdpr"
              md={12}
              label={
                <a
                  href="https://ptic.ro/confidentialitate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accept termenii, condițiile și GDPR
                </a>
              }
            />
          </Grid>

          <div>
            <P onClick={handleNavigateToLogin} style={{ cursor: 'pointer' }}>
              Ai deja cont?
            </P>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PrimaryButton marginTop="30px" type="submit">
              Creaza Cont
            </PrimaryButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  isEmailRegistered: state.account.register.isEmailRegistered,
});

const mapDispatchToProps = { checkEmailRegistered };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
