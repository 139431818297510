import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { Route, Redirect, withRouter } from 'react-router';

const getRedirectPathBasedOnRole = (role) => {
  switch (role) {
    case 'ACCELERATOR':
      return '/home';
    case 'MENTOR':
    case 'FOUNDER': {
      return '/home';
    }
    case 'ADMIN': {
      return '/admin/users';
    }
    default:
      return '/home';
  }
};

const GuestRoute = ({ isLoggedIn, component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: getRedirectPathBasedOnRole(role) }} />
      )
    }
  />
);

GuestRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.account.login.isLoggedIn,
  role: get(state.account.login, 'user.role'),
});

export default withRouter(connect(mapStateToProps)(GuestRoute));
