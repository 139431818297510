import { reduce, size } from 'lodash-es';

export const sizeComments = (comments) =>
  reduce(
    comments,
    (sum, curr) => {
      // eslint-disable-next-line no-param-reassign
      sum += 1;
      // eslint-disable-next-line no-param-reassign
      sum += size(curr.replys);

      return sum;
    },
    0
  );

export const truncateText = (text, length) => {
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }

  return text;
};
