import { useField } from 'formik';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { ACTIVITY_CONCLUSION_SEGMENT_OPTIONS } from '../../../../hypotheses/constants/activities';
import { P, PrimaryButton } from '../../styled/ReusableTextComponents';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../assets/icons';

const CustomMultipleOptionRows = ({ id, label, md = 12, customStyles }) => {
  const [field, meta, helpers] = useField(id);
  const { value, onChange } = field;

  const handleChange = (option, status) =>
    helpers.setValue({
      ...option,
      status,
    });

  return (
    <Grid item xs={12} md={md}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #74A3FE',
          color: '#74A3FE',
          borderRadius: '4px',
          padding: '18.5px 14px',
          marginBottom: '15px',
          ...customStyles,
        }}
      >
        <Box sx={{ marginBottom: '20px', textAlign: 'start' }}>
          <P faded>{label}</P>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {ACTIVITY_CONCLUSION_SEGMENT_OPTIONS.map((option) => (
            <Box
              key={option?.value}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <P>{option?.label}</P>
              <Box sx={{ display: 'flex' }}>
                <PrimaryButton
                  icon={ThumbsDownIcon}
                  iconColor="#FF3D00"
                  enforceColor
                  inverted
                  type="button"
                  marginRight="10px"
                  onClick={() => handleChange(option, false)}
                  disabled={option.value === value?.value && !value?.status}
                >
                  Infirmat
                </PrimaryButton>
                <PrimaryButton
                  icon={ThumbsUpIcon}
                  iconColor="#15BB89"
                  enforceColor
                  inverted
                  type="button"
                  onClick={() => handleChange(option, true)}
                  disabled={option.value === value?.value && value?.status}
                >
                  Confirmat
                </PrimaryButton>
              </Box>
            </Box>
          ))}
        </Box>
        {meta.touched && meta.error && (
          <Box sx={{ color: 'red' }}>{meta.error}</Box>
        )}
      </Box>
    </Grid>
  );
};

export default CustomMultipleOptionRows;
