import React, { useState, useEffect } from 'react';

import { map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import { AddCohortModal } from '../modals';
import {
  TableRow,
  TableHeadCell,
  Loading,
  TableBodyCell,
  PlusButton,
  EditButtonSmall,
  Heading2,
  Heading4Regular,
  Title,
  CounterDiv,
  CounterText,
  TitleCounterDiv,
  DeleteCommentButton,
} from '../../../common/components/styled';
import {
  AcceptButton,
  ButtonText,
  DivBorderBottom,
} from '../../../mentors/components/styled';

import { SortTableHead } from '../../../common/components';
import { deleteCohort, loadCohorts } from '../../ducks';

const AlertMessage = ({ show, setShow, onSubmit, id }) => {
  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    onSubmit(id);
    setShow(false);
  };
  return (
    <Modal centered size="md" show={show} onHide={handleClose}>
      <Modal.Body>
        <DivBorderBottom paddingBottom="25px" marginBottom="25px">
          <div style={{ display: 'flex' }}>
            <DeleteCommentButton style={{ marginRight: '10px' }} />
            <Heading2>Ștergere cohortă</Heading2>
          </div>
        </DivBorderBottom>
        <Heading4Regular marginBottom="25px">
          Ești sigur/ă că dorești ștergerea cohortei?
        </Heading4Regular>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <AcceptButton
            maxWidth="100%"
            backgroundNone
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <ButtonText backgroundNone color="#FFFFFF">
              Nu, anulare
            </ButtonText>
          </AcceptButton>
          <AcceptButton onClick={handleSubmit}>
            <Heading4Regular white>Da, șterge</Heading4Regular>
          </AcceptButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CohortsPage = (props) => {
  const {
    cohorts,
    isLoading,
    isCreating,
    isEditing,
    deleteCohort,
    loadCohorts,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [cohort, setCohort] = useState(undefined);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [sortedCohorts, setSortedCohorts] = useState(cohorts);

  useEffect(() => {
    setSortedCohorts(cohorts);
  }, [cohorts]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const resetCohort = () => setCohort(undefined);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCohort(undefined);
  };

  const handleEditModal = (cohort) => {
    setCohort(cohort);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleDeleteCohort = async (cohortId) => {
    await deleteCohort(cohortId);
    setCohort(undefined);
    await loadCohorts();
  };

  return (
    <>
      <TitleCounterDiv width="100%">
        <Title>Cohorte</Title>
        <CounterDiv>
          <CounterText>{size(sortedCohorts)}</CounterText>
        </CounterDiv>
        <PlusButton onClick={handleOpenModal} />
      </TitleCounterDiv>

      <SortTableHead
        array={sortedCohorts}
        setArray={setSortedCohorts}
        cellSortKeys={[
          null,
          ['name'],
          (el) =>
            el.startDate ? moment(el.startDate).format('DD/MM/YYYY') : '-',
          (el) => (el.endDate ? moment(el.endDate).format('DD/MM/YYYY') : '-'),
          ['cohortCode'],
          null,
        ]}
        defaultSortKey={['name']}
      >
        <TableHeadCell alignLeft cellWidth="2%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="35%">
          <Heading2>Nume</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="14%">
          <Heading2 center>Început perioadă înscrieri</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="14%">
          <Heading2 center>Sfârșit perioadă înscrieri</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="30%">
          <Heading2>Cod</Heading2>
        </TableHeadCell>
        <TableHeadCell alignRight cellWidth="5%">
          <Heading2>Acțiuni</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(sortedCohorts, (cohort, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="2%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="35%">
            <Heading4Regular>{cohort.name}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="14%">
            <Heading4Regular>
              {cohort.startDate
                ? moment(cohort.startDate).format('DD/MM/YYYY')
                : '-'}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="14%">
            <Heading4Regular>
              {cohort.endDate
                ? moment(cohort.endDate).format('DD/MM/YYYY')
                : '-'}
            </Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="30%">
            <Heading4Regular>{cohort.cohortCode}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignRight cellWidth="5%">
            <EditButtonSmall onClick={() => handleEditModal(cohort)} />
            <DeleteCommentButton
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setCohort(cohort._id);
                setOpenAlertMessage(true);
              }}
            />
          </TableBodyCell>
        </TableRow>
      ))}
      <AddCohortModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModal={handleCloseModal}
        cohort={cohort}
        isEditMode={isEditMode}
        isLoading={isEditMode ? isEditing : isCreating}
      />
      <AlertMessage
        show={openAlertMessage}
        setShow={setOpenAlertMessage}
        onSubmit={handleDeleteCohort}
        resetResource={resetCohort}
        id={cohort}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  cohorts: state.cohorts.cohorts,
  isLoading: state.cohorts.isLoading,
  isCreating: state.cohorts.isCreating,
  isEditing: state.cohorts.isEditing,
});

const mapDispatchToProps = {
  deleteCohort,
  loadCohorts,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CohortsPage)
);
